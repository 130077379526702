import { Trans } from '@lingui/macro'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { datetimeFormat } from 'app/appSettings'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useFormikContext } from 'formik'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formComponentTypes } from '../../components/formComponentTypes'
import { endEditingField, grpcGetFieldHistory } from '../grpcMultiuserEdit'

/**
 * Renders an icon, that on click opens a panel containg all of previous values saved for this field in multiuser. Historic values can be restored from the panel.
 * @category Multiuser
 * @component
 * @returns {JSX.Element}
 * @property {object} muBag Object containing multiuser data needef for making the queries.
 * @property {object} muBag.token Current multiuser session token.
 * @property {object} muBag.formId Id of a realm the component is used in.
 * @property {string} fieldId Id of a field.
 * @property {boolean} disabled If true, icon opening the panel will be disabled.
 * @property {string} [type] Type of form element using the panel.
 * @property {object} [item] Form element data object.
 * @property {object} [connectedObject] Data about salesforce object that is connected to this form element.
 * @property {boolean} [useMultiuser] If false, null will be returned from the component.
 */
export const MUPreviouslySavedValuesPanel = ({
  disabled,
  muBag,
  fieldId,
  type,
  connectedObject,
  item,
  useMultiuser
}) => {
  const { setFieldValue, values } = useFormikContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [backups, setBackups] = useState()
  const userLanguage = useSelector(state => state.user.language)
  const langVersion = userLanguage?.split('_')[0] || 'en'
  const renderValue = type && formComponentTypes[type]?.valueToText
  const { muInfo } = values

  useEffect(() => {
    if (!dialogOpen) {
      setBackups(null)
    }
  }, [dialogOpen])

  const muState = muInfo?.[fieldId]
  if (muState) {
    const { locked } = muState
    if (locked) {
      disabled = true
    }
  }

  if (!useMultiuser) {
    return null
  }

  return (
    <>
      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Trans>Field history</Trans>
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ minHeight: 600 }}>
          {backups
            ? (
                backups.length === 0
                  ? (
                    <div>
                      <Trans>This field has no history yet</Trans>
                    </div>
                    )
                  : (
                      backups.map((backup, index) => {
                        let showValue = backup.value
                        if (renderValue) {
                          showValue = renderValue(backup.value, item, connectedObject)[
                            langVersion
                          ]
                        }
                        return (
                          <Grid
                            container
                            key={index}
                            alignItems='flex-start'
                            justifyContent='flex-start'
                            style={{ marginTop: 10 }}
                          >
                            <Grid item style={{ padding: 5 }}>
                              <div style={{ fontWeight: 400, fontSize: 11 }}>
                                <Trans>Date</Trans>
                              </div>
                              <div>
                                {moment.utc(backup.date).local().format(datetimeFormat)}
                              </div>
                            </Grid>

                            <Grid item xs style={{ padding: 5, paddingLeft: 15 }}>
                              <div style={{ fontWeight: 400, fontSize: 11 }}>
                                <Trans>Value</Trans>
                              </div>
                              <div>{showValue}</div>
                            </Grid>
                            <Grid item style={{ padding: 5 }}>
                              <IconButton
                                disabled={disabled}
                                onClick={e => {
                                  setDialogOpen(false)
                                  if (
                                    useMultiuser &&
                            backup.value !== values[fieldId]
                                  ) {
                                    setFieldValue(fieldId, backup.value)
                                    endEditingField({
                                      ...muBag,
                                      fieldId,
                                      fieldValue: backup.value
                                    })
                                  }
                                }}
                              >
                                <Icon>settings_backup_restore</Icon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })
                    )
              )
            : (
              <Loading />
              )}
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Browse saved field values</Trans>}>
        <span>
          <IconButton
            style={{ marginLeft: 5 }}
            size='small'
            onClick={e => {
              setDialogOpen(true)
              grpcGetFieldHistory({
                ...muBag,
                fieldId,
                onSuccess: e => {
                  setBackups(e.sort((a, b) => b.date.diff(a.date, 'seconds')))
                }
              })
            }}
          >
            <Icon>settings_backup_restore</Icon>
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

export const SET_TESTING_OPTIONS = 'SET_TESTING_OPTIONS'

export function setTestingOptions (options) {
  return dispatch => {
    dispatch({
      type: SET_TESTING_OPTIONS,
      data: options
    })
  }
}

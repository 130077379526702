import { t, Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography
} from '@material-ui/core'
import { portalLanguagesData } from 'app/appSettings'
import {
  getActionTemplate,
  saveActionTemplate
} from 'app/services/sfAuth/sfData/sfActionTemplates'
import {
  createActionText,
  getOtherTemplates,
  getSurveyTemplateDetails,
  getTranslatedContent
} from 'app/services/sfAuth/sfData/sfSurvey'
import { updateTexts } from 'app/services/sfAuth/sfData/sfTranslations'
import * as Promise from 'bluebird'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import { FormikRadioGroupField } from 'formik-material-fields'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField/FormikCheckboxGroupField'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import MUIDataTable from 'mui-datatables'
import { withSnackbar } from 'notistack'
import React, { Component, useEffect } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { getEmptyLangValues, isPortalId } from 'utils'
import { getTitleTranslation } from '../common/TranslationsCommon'
import ConfigureMultilanguageTextField from '../internal/ConfigureMultilanguageTextField'
import { NumericFormat } from '../page-layouts/FormElement'
import { questionTypes } from '../surveys/QuestionWidget'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'

Promise.config({
  cancellation: true
})

export const scoringCategoryNameToKey = {
  'Property Management': 'property',
  'Financial Managment': 'financial',
  Governance: 'governance',
  'Social and community relations': 'social'
}

const types = [
  { trans: <Trans>Category</Trans>, value: 'category' },
  { trans: <Trans>Question</Trans>, value: 'question' },
  { trans: <Trans>Logical</Trans>, value: 'logical' },
  { trans: <Trans>Template</Trans>, value: 'template' }
]

const templateCategories = [
  { trans: <Trans>None</Trans>, value: 'None' },
  { trans: <Trans>Property Management</Trans>, value: 'Property Management' },
  { trans: <Trans>Financial Managment</Trans>, value: 'Financial Managment' },
  { trans: <Trans>Governance</Trans>, value: 'Governance' },
  {
    trans: <Trans>Social and community relations</Trans>,
    value: 'Social and community relations'
  }
]

const categories = [
  { trans: <Trans>Property Management</Trans>, value: 'property' },
  { trans: <Trans>Financial Managment</Trans>, value: 'financial' },
  { trans: <Trans>Governance</Trans>, value: 'governance' },
  { trans: <Trans>Social and community relations</Trans>, value: 'social' }
]

const operators = [
  { value: 'gt', label: '>' },
  { value: 'lt', label: '<' },
  { value: 'eq', label: '==' },
  { value: 'gte', label: '>=' },
  { value: 'lte', label: '<=' }
]

class ActionTemplate extends Component {
  state = {}

  constructor (props) {
    super(props)
    const templateId = props.match.params.id
    this.state.templateId = templateId
    this.saveTemplate = this.saveTemplate.bind(this)
    this.getInitialValue = this.getInitialValue.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.fetchData(templateId)
  }

  fetchData (id) {
    Promise.all([getActionTemplate(id), getOtherTemplates()]).then(
      ([template, surveyTemplates]) => {
        console.log('got template', template)
        console.log('got survey templates', surveyTemplates)
        this.setState({
          data: template,
          surveyTemplates
        })
      }
    )
  }

  getTranslationTextId (field, language) {
    const userLang = language || this.props.user.language
    let toRet = ''

    const langData = portalLanguagesData[userLang]
    if (!langData) {
      return toRet
    }

    field?.Survey_Texts__r?.records.some(text => {
      if (text.Language__c === langData.surveyTextKey) {
        toRet = text.Id
        return true
      }
      return false
    })
    return toRet
  }

  getLabel (field) {
    let toRet = ''
    field?.Survey_Texts__r?.records.some(text => {
      if (text.Id) {
        toRet = text.Text__c
        return true
      }
      return false
    })
    return toRet
  }

  saveTemplate (values) {
    console.log('saving', values)
    const saving = this.props.enqueueSnackbar(
      <Trans>Saving action template</Trans>,
      {
        variant: 'info'
      }
    )
    const data = this.state.data
    const translationFiels = [
      { id: 'Title__c', value: 'name' },
      { id: 'Description__c', value: 'description' },
      { id: 'Expected_Outcome__c', value: 'outcome' },
      { id: 'Reason__c', value: 'reason' }
    ]

    const toUpdate = []
    const toCreate = []
    translationFiels.forEach(field => {
      Object.entries(portalLanguagesData).forEach(([key, value]) => {
        const tranlationTextId = this.getTranslationTextId(data[field.id], key)
        const text = getTranslatedContent(values[field.value], value.editKey)
        if (tranlationTextId) {
          toUpdate.push({
            Id: tranlationTextId,
            Text__c: text.value,
            Text_Is_Portal_Translation_Id__c: text.isPortalId
          })
        } else {
          const fieldId = data[field.id].Id
          const { surveyTextKey } = value
          toCreate.push(
            createActionText(
              fieldId,
              surveyTextKey,
              text.value,
              text.isPortalId
            )
          )
        }
      })
    })
    return Promise.all([
      saveActionTemplate({
        id: this.state.templateId,
        fields: {
          // Name: values.name,
          // Description__c: values.description,
          Base_Priority__c: values.priority,
          Category__c: values.category !== 'None' ? values.category : null,
          Timeline__c: values.timeline,
          Priority_Conditions__c: JSON.stringify(values.priorityConditions),
          JSON__c: JSON.stringify(values.conditions),
          Trigger__c: values.trigger.join(';')
        }
      }),
      updateTexts(toUpdate),
      Promise.all(toCreate)
    ]).then(
      ([template, update, create = []]) => {
        let isError = false
        isError = [...update, ...create].some(update => {
          return !update.success
        })
        if (!isError) {
          isError = !template.success
        }
        this.props.closeSnackbar(saving)
        if (!isError) {
          this.props.enqueueSnackbar(
            <Trans>Template saved successfuly!</Trans>,
            {
              variant: 'success'
            }
          )
        } else {
          this.props.enqueueSnackbar(
            <Trans>Error while saving template!</Trans>,
            {
              variant: 'error'
            }
          )
        }
        return template
      },
      reject => {
        this.props.closeSnackbar(saving)
        this.props.enqueueSnackbar(
          <Trans>Error while saving template!</Trans>,
          {
            variant: 'error'
          }
        )
        return reject
      }
    )
  }

  getInitialValue (field) {
    let toRet = {}
    if (
      isPortalId('Text_Is_Portal_Translation_Id__c', this.state.data[field])
    ) {
      toRet = getEmptyLangValues()
      toRet.portalTranslationId = this.getLabel(this.state.data[field])
    } else {
      Object.values(portalLanguagesData).map(obj => {
        toRet[obj.editKey] = getTitleTranslation(
          obj.editKey,
          this.state.data[field]
        )
      })
    }
    return toRet
  }

  render () {
    const { data, surveyTemplates } = this.state
    const { user } = this.props
    if (!data) {
      return <Loading />
    }
    return (
      <div style={{ padding: 20 }}>
        <Formik
          initialValues={{
            name: {
              ...this.getInitialValue('Title__c')
            },
            description: {
              ...this.getInitialValue('Description__c')
            },
            outcome: {
              ...this.getInitialValue('Expected_Outcome__c')
            },
            reason: {
              ...this.getInitialValue('Reason__c')
            },
            priority: data.Base_Priority__c || 0,
            category: data.Category__c,
            timeline: data.Timeline__c,
            conditions: data.JSON__c ? JSON.parse(data.JSON__c) : [],
            priorityConditions: data.Priority_Conditions__c
              ? JSON.parse(data.Priority_Conditions__c)
              : [],
            trigger: data.Trigger__c ? data.Trigger__c.split(';') : []
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.saveTemplate(values).then(result => {
              setSubmitting(false)
            })
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
            const { conditions, priorityConditions } = values
            return (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    this.props.history.goBack()
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 5 }}>arrow_back</Icon>
                    <Trans>Back</Trans>
                  </Grid>
                </Button>
                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                >
                  <Typography variant='h5' style={{ margin: 20 }}>
                    <b>
                      <Trans>Edit Action Template</Trans>
                    </b>
                  </Typography>

                  <ConfigureMultilanguageTextField
                    value={values.name}
                    handleChange={e => {
                      setFieldValue('name', e)
                    }}
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 80 }}
                    label={<Trans>Name</Trans>}
                  />
                  <ConfigureMultilanguageTextField
                    value={values.description}
                    handleChange={e => {
                      setFieldValue('description', e)
                    }}
                    style={{ marginTop: 20 }}
                    inputProps={{ maxLength: 1000 }}
                    label={<Trans>Description</Trans>}
                    rows={4}
                    multiline
                  />

                  <Grid item xs={12} style={{ width: '100%', marginTop: 20 }}>
                    <I18n>
                      {({ i18n }) => (
                        <>
                          <ConditionsTable
                            handleDelete={index => {
                              const toSet = conditions
                              toSet.splice(index, 1)
                              this.setState({
                                conditions: toSet
                              })
                            }}
                            setFieldValue={setFieldValue}
                            conditions={conditions}
                            i18n={i18n}
                            surveyTemplates={surveyTemplates}
                            id='conditions'
                          />
                          <ConditionsTable
                            handleDelete={index => {
                              const toSet = priorityConditions
                              toSet.splice(index, 1)
                              this.setState({
                                priorityConditions: toSet
                              })
                            }}
                            setFieldValue={setFieldValue}
                            conditions={priorityConditions}
                            i18n={i18n}
                            surveyTemplates={surveyTemplates}
                            id='priorityConditions'
                          />
                        </>
                      )}
                    </I18n>
                  </Grid>

                  <InputLabel
                    style={{
                      marginBottom: 5,
                      marginTop: 25,
                      textAlign: 'left',
                      width: '100%'
                    }}
                  >
                    <Trans>Categories</Trans>
                  </InputLabel>
                  <Select
                    style={{
                      flexGrow: 1
                    }}
                    fullWidth
                    variant='outlined'
                    value={values.category || ''}
                    onChange={e => {
                      setFieldValue('category', e.target.value)
                    }}
                  >
                    {templateCategories.map((type, index) => (
                      <MenuItem value={type.value}>{type.trans}</MenuItem>
                    ))}
                  </Select>

                  <FormikTextField
                    style={{ marginTop: 20 }}
                    helperText={
                      <Trans>
                        Only numerical values are permitted in this field
                      </Trans>
                    }
                    defaultValue={0}
                    InputProps={{ inputComponent: NumericFormat }}
                    name='priority'
                    variant='outlined'
                    label={<Trans>Priority</Trans>}
                    fullWidth
                  />

                  <FormikTextField
                    style={{ marginTop: 20 }}
                    helperText={
                      <Trans>
                        Only numerical values are permitted in this field
                      </Trans>
                    }
                    defaultValue={0}
                    InputProps={{ inputComponent: NumericFormat }}
                    name='timeline'
                    variant='outlined'
                    label={<Trans>Timeline (days)</Trans>}
                    fullWidth
                  />

                  <ConfigureMultilanguageTextField
                    value={values.outcome}
                    handleChange={e => {
                      setFieldValue('outcome', e)
                    }}
                    style={{ marginTop: 20 }}
                    inputProps={{ maxLength: 1000 }}
                    label={<Trans>Expected Outcome</Trans>}
                    rows={2}
                    multiline
                  />

                  <FormikCheckboxGroupField
                    label={<Trans>Trigger</Trans>}
                    style={{ marginTop: 20, width: '100%' }}
                    name='trigger'
                    multiple
                    options={[
                      { label: <Trans>SAT</Trans>, value: 'SAT' },
                      {
                        label: <Trans>Report</Trans>,
                        value: 'Report'
                      }
                    ]}
                  />

                  <ConfigureMultilanguageTextField
                    value={values.reason}
                    handleChange={e => {
                      setFieldValue('reason', e)
                    }}
                    style={{ marginTop: 20 }}
                    inputProps={{ maxLength: 1000 }}
                    label={<Trans>Reason</Trans>}
                    rows={2}
                    multiline
                  />

                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    variant='contained'
                    color='primary'
                    style={{
                      marginTop: 20,
                      width: '100%'
                    }}
                  >
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justify='center'
                    >
                      <Trans>Save</Trans>
                      <Icon style={{ marginLeft: 5 }}>save</Icon>
                    </Grid>
                  </Button>
                </Grid>
              </>
            )
          }}
        </Formik>
      </div>
    )
  }
}

const ConditionsTable = ({
  setFieldValue,
  handleDelete,
  conditions,
  i18n,
  id,
  surveyTemplates
}) => {
  return (
    <MUIDataTable
      title={
        <Grid container direction='row' alignItems='center'>
          <h1 style={{ margin: 10 }}>
            {id === 'priorityConditions'
              ? (
                <Trans>Priority conditions</Trans>
                )
              : (
                <Trans>Conditions</Trans>
                )}
          </h1>
          <Button
            variant='contained'
            color='primary'
            onClick={e => {
              setFieldValue(id, conditions.concat({}))
            }}
          >
            <Grid container direction='row' alignItems='center'>
              <Trans>Add new condition</Trans>
              <Icon style={{ marginLeft: 5 }}>add</Icon>
            </Grid>
          </Button>
        </Grid>
      }
      data={conditions}
      options={{
        textLabels: muiTextLabels(i18n),
        filter: false,
        selectableRows: 'none',
        print: false,
        download: false,
        viewColumns: false,
        rowsPerPage: 100,
        expandableRows: false,
        search: false,
        sort: false
      }}
      columns={[
        {
          name: 'condition',
          label: i18n._(t`Condition`),
          options: {
            customHeadLabelRender: props => {
              return (
                <div style={{ marginLeft: 20 }}>
                  <Trans>Condition</Trans>
                </div>
              )
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const condition = conditions[tableMeta.rowIndex]
              const main = { ...condition }
              const sub = condition.subconditions || []
              delete main.subconditions
              return (
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <div> {JSON.stringify(main)}</div>
                  {sub.map(con => (
                    <div style={{ marginLeft: 10 }}>{JSON.stringify(con)}</div>
                  ))}
                </div>
              )
            }
          }
        },
        {
          name: 'actions',
          label: i18n._(t`Actions`),
          options: {
            sort: false,
            customHeadLabelRender: props => {
              return (
                <Grid
                  container
                  direction='row'
                  justify='flex-end'
                  style={{ paddingRight: 40 }}
                >
                  <Trans>Actions</Trans>
                </Grid>
              )
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const index = tableMeta.rowIndex
              const condition = conditions[index]
              return (
                <div
                  style={{
                    flex: 1,
                    marginRight: 20,
                    minWidth: '120px'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    justify='flex-end'
                    alignItems='center'
                  >
                    <EditConditionButton
                      priority={id === 'priorityConditions'}
                      updateCondition={values => {
                        setFieldValue(
                          id === 'priorityConditions'
                            ? `priorityConditions.${index}`
                            : `conditions.${index}`,
                          values
                        )
                      }}
                      surveyTemplates={surveyTemplates}
                      condition={condition}
                    />
                    <IconButton
                      onClick={() => {
                        handleDelete(index)
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                </div>
              )
            }
          }
        }
      ]}
    />
  )
}

const EditConditionButton = ({
  condition,
  surveyTemplates,
  updateCondition,
  priority
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [templates, setTemplates] = React.useState([])
  const user = useSelector(state => state.user)

  useEffect(() => {
    console.log('got templates', surveyTemplates)
    const toSet = []
    Object.keys(surveyTemplates).forEach(key => {
      const template = surveyTemplates[key]
      const title = template.titleValue[user.language] || '' // template.template.Name
      if (title) {
        toSet.push({
          id: key,
          title
        })
        template.childTemplates.forEach(child => {
          const childTile = child.titleValue[user.language] || ''
          toSet.push({
            id: child.template.Id,
            title: '[' + title + '] ' + childTile
          })
        })
      }
    })
    setTemplates(toSet)
  }, [surveyTemplates, user.language])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: 'category',
        subconditions: [],
        ...condition
      }}
    >
      {({ values, setFieldValue, setValues, resetForm }) => {
        const category = values.type === 'category'
        const question = values.type === 'question'
        const logical = values.type === 'logical'
        const template = values.type === 'template'
        return (
          <>
            {dialogOpen && (
              <Dialog fullWidth open={dialogOpen} maxWidth='md'>
                <div>
                  <DialogTitle>
                    <Typography variant='h6'>
                      <Trans>Edit condition</Trans>
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <div>
                      <InputLabel style={{ marginBottom: 5 }}>
                        <Trans>Condition type</Trans>
                      </InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        variant='outlined'
                        value={values.type}
                        onChange={e => {
                          setValues({
                            type: e.target.value,
                            subconditions:
                              e.target.value === 'logical' ? [] : null
                          })
                        }}
                      >
                        {types.map((type, index) => (
                          <MenuItem value={type.value}>{type.trans}</MenuItem>
                        ))}
                      </Select>
                      <Grid
                        container
                        direction='column'
                        style={{ marginTop: 30 }}
                      >
                        <Typography variant='h5' style={{ marginBottom: 30 }}>
                          {category && <Trans>Category</Trans>}
                          {question && <Trans>Question</Trans>}
                          {logical && <Trans>Logical</Trans>}
                          {template && <Trans>Template</Trans>}
                        </Typography>

                        <ConditionEditField
                          priority={priority}
                          setValues={setValues}
                          setFieldValue={setFieldValue}
                          condition={values}
                          templates={templates}
                        />
                        {logical && (
                          <>
                            <Button
                              color='primary'
                              variant='contained'
                              fullWidth
                              style={{
                                marginTop: 5
                              }}
                              onClick={() => {
                                setFieldValue(
                                  'subconditions',
                                  values.subconditions.concat({
                                    type: 'category'
                                  })
                                )
                              }}
                            >
                              <Trans>Add condition</Trans>
                            </Button>
                            <Scrollbar
                              className='position-relative pb-16 w-100'
                              style={{ maxHeight: '500px', padding: 10 }}
                              // style={{ maxHeight: '300px' }}
                              options={{
                                minScrollbarLength: 4,
                                scrollYMarginOffset: 8
                              }}
                            >
                              <div>
                                {values.subconditions.map((con, index) => {
                                  return (
                                    <div>
                                      <Grid
                                        container
                                        direction='row'
                                        justify='flex-start'
                                        alignItems='center'
                                      >
                                        <Typography
                                          style={{
                                            marginTop: 10,
                                            marginBottom: 10
                                          }}
                                        >
                                          <b>
                                            <Trans>Condition</Trans> {index + 1}
                                          </b>
                                        </Typography>
                                        <IconButton
                                          onClick={() => {
                                            const toSet = [
                                              ...values.subconditions
                                            ]
                                            toSet.splice(index, 1)
                                            setFieldValue(
                                              'subconditions',
                                              toSet
                                            )
                                          }}
                                        >
                                          <Icon>delete</Icon>
                                        </IconButton>
                                      </Grid>
                                      <ConditionEditField
                                        priority={priority}
                                        templates={templates}
                                        condition={con}
                                        index={index}
                                        setValues={setValues}
                                        setFieldValue={setFieldValue}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </Scrollbar>
                          </>
                        )}
                      </Grid>
                      <Grid
                        container
                        direction='row'
                        justify='space-evenly'
                        alignItems='center'
                        style={{ marginTop: 20 }}
                      >
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            setDialogOpen(false)
                            updateCondition(values)
                          }}
                        >
                          <Trans>Save</Trans>
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            setDialogOpen(false)
                            resetForm({ ...condition })
                          }}
                        >
                          <Trans>Cancel</Trans>
                        </Button>
                      </Grid>
                    </div>
                  </DialogContent>
                </div>
              </Dialog>
            )}
            <IconButton
              onClick={() => {
                setDialogOpen(true)
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
          </>
        )
      }}
    </Formik>
  )
}

const ConditionEditField = ({
  condition,
  index,
  setFieldValue,
  setValues,
  templates,
  priority
}) => {
  const [questions, setQuestions] = React.useState([])
  const [selectedQuestion, selectQuestion] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const user = useSelector(state => state.user)
  const [promise, setPromise] = React.useState(null)

  const validQuestionTypes = [
    questionTypes.INPUT_NUMBER.id,
    questionTypes.SELECT_ONE.id,
    questionTypes.SELECT_YESNO.id,
    questionTypes.SELECT_MULTIPLE.id,
    questionTypes.INPUT_TEXT.id,
    questionTypes.INPUT_TEXTAREA.id
  ]

  useEffect(() => {
    if (promise) {
      promise.cancel()
      setPromise(null)
    }
    setLoading(false)
    selectQuestion(null)
  }, [condition.type])

  useEffect(() => {
    if (condition.template) {
      setLoading(true)
      const query = new Promise(function (resolve) {
        resolve(getSurveyTemplateDetails(condition.template))
      }).then(result => {
        const matchObj = {}
        result.questionsDetails.forEach(q => {
          const options = JSON.parse(
            q.item.Survey_Joins_Template_and_Question__r.records[0]
              .Item_Options__c
          )
          const sfId = options.createdId
          matchObj[sfId] = {
            title: q.itemTranslation.titleValue[user.language] || '',
            ...matchObj[sfId]
          }
          if (options.parentQuestionId) {
            if (matchObj[options.parentQuestionId]) {
              const toCopy = matchObj[options.parentQuestionId].children || []
              matchObj[options.parentQuestionId].children = [sfId, ...toCopy]
            } else {
              matchObj[options.parentQuestionId] = {
                children: [sfId],
                ...matchObj[options.parentQuestionId]
              }
            }
          }
        })
        setQuestions(
          result.questionsDetails
            .map(question => {
              const options = JSON.parse(
                question.item.Survey_Joins_Template_and_Question__r.records[0]
                  .Item_Options__c
              )
              const sfId = options.createdId
              let order =
                question.item.Survey_Joins_Template_and_Question__r.records[0]
                  .Order__c + 1
              let qTitle = question.itemTranslation.titleValue[user.language]
              if (question.itemOptions.parentQuestionId) {
                order = Number(order + 0.1)
                qTitle = (
                  <span>
                    <b>
                      [{matchObj[question.itemOptions.parentQuestionId].title}]
                    </b>{' '}
                    {qTitle}
                  </span>
                )
              } else {
                qTitle = <>{order + '. ' + qTitle}</>
              }
              if (
                question.itemOptions.parentQuestionId &&
                !matchObj[question.itemOptions.parentQuestionId]
              ) {
                return null
              }
              if (
                !question.itemOptions.parentQuestionId &&
                question.itemOptions.parentQuestionIndex
              ) {
                return null
              }
              const toRet = {
                id: sfId,
                order,
                options: question.itemDetails || [],
                parentQuestion: question.itemOptions.parentQuestionId,
                childIndex: question.itemOptions.index,
                children: matchObj[sfId].children,
                isGroup: question.itemOptions.isGroup,
                type: question.item.Type__c,
                title: qTitle
              }
              if (toRet.id === condition.question) {
                selectQuestion(toRet)
              }
              return toRet
            })
            .filter(q => q && validQuestionTypes.includes(q.type))
            .sort((a, b) => a.order - b.order)
        )
        setLoading(false)
      })
      setPromise(query)
    }
  }, [condition.template, user.language])

  const formikFieldName = id => {
    if (isSub) {
      return `subconditions.${index}.` + id
    } else {
      return id
    }
  }

  const category = condition.type === 'category'
  const question = condition.type === 'question'
  const logical = condition.type === 'logical'
  const template = condition.type === 'template'

  const isSub = Boolean(index || index == 0)
  const isNumericType =
    selectedQuestion && selectedQuestion.type === questionTypes.INPUT_NUMBER.id
  const isYesNoType =
    selectedQuestion && selectedQuestion.type === questionTypes.SELECT_YESNO.id
  const isMultiselect =
    selectedQuestion &&
    selectedQuestion.type === questionTypes.SELECT_MULTIPLE.id
  const isText =
    selectedQuestion &&
    Boolean(
      selectedQuestion.type === questionTypes.INPUT_TEXT.id ||
        selectedQuestion.type === questionTypes.INPUT_TEXTAREA.id
    )
  const showOperator =
    logical ||
    (!isMultiselect && !isText && Boolean(selectedQuestion)) ||
    category

  if (condition.type !== 'question' && selectedQuestion) {
    return null
  }

  return (
    <Grid container direction='column'>
      {isSub && (
        <>
          <InputLabel style={{ marginBottom: 5 }}>
            <Trans>Condition type</Trans>
          </InputLabel>
          <Select
            style={{
              flexGrow: 1
            }}
            variant='outlined'
            value={condition.type || ''}
            onChange={e => {
              setFieldValue(`subconditions.${index}`, { type: e.target.value })
            }}
          >
            {types.map((type, index) => {
              if (type.value === 'logical') {
                return null
              }
              return <MenuItem value={type.value}>{type.trans}</MenuItem>
            })}
          </Select>
        </>
      )}

      {category && (
        <>
          <InputLabel
            style={{
              marginBottom: 5,
              marginTop: 5
            }}
          >
            <Trans>Categories</Trans>
          </InputLabel>
          <Select
            style={{
              flexGrow: 1
            }}
            variant='outlined'
            value={condition.category || ''}
            onChange={e => {
              setFieldValue(formikFieldName('category'), e.target.value)
            }}
          >
            {categories.map((type, index) => (
              <MenuItem value={type.value}>{type.trans}</MenuItem>
            ))}
          </Select>
        </>
      )}

      {(question || template) && (
        <>
          <InputLabel style={{ marginBottom: 5, marginTop: isSub && 5 }}>
            <Trans>Template</Trans>
          </InputLabel>
          <Select
            style={{
              flexGrow: 1,
              maxWidth: '912px'
            }}
            variant='outlined'
            value={condition.template || ''}
            onChange={e => {
              const newValues = {
                type: question ? 'question' : 'template',
                template: e.target.value
              }
              selectQuestion(null)
              if (!isSub) {
                setValues(newValues)
              } else {
                setFieldValue(`subconditions.${index}`, newValues)
              }
            }}
          >
            {templates.map((temp, index) => (
              <MenuItem value={temp.id}>{temp.title}</MenuItem>
            ))}
          </Select>
        </>
      )}

      {loading ? (
        <div style={{ marginBottom: 20 }}>
          <Loading isNotFixed />
        </div>
      ) : (
        <>
          {question && (
            <>
              <InputLabel style={{ marginBottom: 5, marginTop: 5 }}>
                <Trans>Question</Trans>
              </InputLabel>
              <Select
                disabled={questions.length === 0}
                style={{
                  flexGrow: 1,
                  maxWidth: '912px'
                }}
                // multiline={true}
                variant='outlined'
                value={condition.question || ''}
                onChange={e => {
                  let toSet = {}
                  questions.some(q => {
                    if (q.id === e.target.value) {
                      toSet = q
                    }
                    return q.id === e.target.value
                  })
                  const isMultiselect =
                    toSet.type === questionTypes.SELECT_MULTIPLE.id

                  const obj = {
                    ...condition,
                    options: [],
                    parentQuestion: toSet.parentQuestion,
                    childIndex: toSet.childIndex,
                    question: e.target.value,
                    childQuestions: toSet.children,
                    questionType: toSet.type,
                    value: 0,
                    operator: null
                  }
                  if (!isMultiselect) {
                    delete obj.options
                  } else {
                    delete obj.value
                    delete obj.operator
                  }
                  if (!isSub) {
                    setValues(obj)
                  } else {
                    setFieldValue(`subconditions.${index}`, obj)
                  }
                  selectQuestion(toSet)
                }}
              >
                {questions.map(q => (
                  <MenuItem value={q.id}>{q.title}</MenuItem>
                ))}
              </Select>
            </>
          )}

          {(selectedQuestion || category) && (
            <>
              {!logical && !isMultiselect && (
                <FormControl style={{ marginTop: 20 }}>
                  {isNumericType
                    ? (
                      <FormikTextField
                        style={{ marginTop: 20 }}
                        helperText={
                          <Trans>
                            Only numerical values are permitted in this field
                          </Trans>
                      }
                        InputProps={{ inputComponent: NumericFormat }}
                        name={formikFieldName('value')}
                        variant='outlined'
                        label={<Trans>Value</Trans>}
                        fullWidth
                      />
                      )
                    : (
                        !isText && (
                          <>
                            {!isYesNoType && (
                              <InputLabel>
                                <Trans>Value</Trans>
                              </InputLabel>
                            )}
                            <Grid container direction='row'>
                              <Grid
                                item
                                xs={isYesNoType ? 12 : 11}
                                style={
                              isYesNoType
                                ? { paddingRight: 15, paddingLeft: 15 }
                                : {}
                            }
                              >
                                <Slider
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      formikFieldName('value'),
                                      newValue
                                    )
                                  }}
                                  value={condition.value || 0}
                                  defaultValue={0}
                                  step={isYesNoType ? 100 : 1}
                                  marks={
                                isYesNoType && [
                                  { value: 0, label: <Trans>No</Trans> },
                                  { value: 100, label: <Trans>Yes</Trans> }
                                ]
                              }
                                  min={0}
                                  max={100}
                                  valueLabelDisplay={isYesNoType ? 'off' : 'auto'}
                                />
                              </Grid>
                              {!isYesNoType && (
                                <Grid item xs={1}>
                                  <Typography
                                    style={{
                                      textAlign: 'center',
                                      paddingLeft: 5,
                                      paddingRight: 5
                                    }}
                                  >
                                    {condition.value}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        )
                      )}
                </FormControl>
              )}
              {isMultiselect && (
                <>
                  <FormikCheckboxGroupField
                    name={formikFieldName('options')}
                    label={<Trans>Options</Trans>}
                    multiple
                    margin='normal'
                    options={selectedQuestion.options.map((option, index) => {
                      const text = option.titleValue[user.language] || ''
                      return {
                        label: text,
                        value: String(index)
                      }
                    })}
                  />
                  <FormikRadioGroupField
                    label={<Trans>Condition operator</Trans>}
                    name={formikFieldName('operator')}
                    margin='normal'
                    options={[
                      { label: <Trans>All</Trans>, value: 'all' },
                      { label: <Trans>Any</Trans>, value: 'any' }
                    ]}
                  />
                </>
              )}
              {isText && (
                <>
                  <FormikRadioGroupField
                    style={{ marginLeft: 10 }}
                    label={<Trans>Condition operator</Trans>}
                    name={formikFieldName('operator')}
                    margin='normal'
                    options={[
                      { label: <Trans>Not filled</Trans>, value: 'empty' },
                      { label: <Trans>Filled</Trans>, value: 'notEmpty' }
                    ]}
                  />
                </>
              )}
            </>
          )}

          {showOperator && (
            <>
              <InputLabel
                style={{
                  marginBottom: 5,
                  marginTop: !logical && 30
                }}
              >
                <Trans>Condition operator</Trans>
              </InputLabel>
              <Select
                style={{
                  flexGrow: 1
                }}
                variant='outlined'
                value={condition.operator || ''}
                onChange={e => {
                  setFieldValue(formikFieldName('operator'), e.target.value)
                }}
              >
                {logical
                  ? (
                    <MenuItem value='or'>||</MenuItem>
                    )
                  : isYesNoType
                    ? (
                      <MenuItem value='eq'>==</MenuItem>
                      )
                    : (
                        operators.map((operator, index) => (
                          <MenuItem value={operator.value}>{operator.label}</MenuItem>
                        ))
                      )}
              </Select>
            </>
          )}

          {Boolean(priority && !isSub) && (
            <>
              <FormikRadioGroupField
                style={{ marginLeft: 10 }}
                label={<Trans>Priority calculation</Trans>}
                name={formikFieldName('priorityCalculation')}
                margin='normal'
                options={[
                  { label: <Trans>100 - score</Trans>, value: 'fromScore' },
                  { label: <Trans>Flat value</Trans>, value: 'flatValue' }
                ]}
                onChange={e => {
                  setFieldValue(formikFieldName('flatValue'), null)
                }}
              />
              {condition.priorityCalculation === 'flatValue' && (
                <FormikTextField
                  style={{ marginTop: 20 }}
                  helperText={
                    <Trans>
                      Only numerical values are permitted in this field
                    </Trans>
                  }
                  InputProps={{ inputComponent: NumericFormat }}
                  name={formikFieldName('flatValue')}
                  variant='outlined'
                  label={<Trans>Flat value</Trans>}
                  fullWidth
                />
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  user: state.user
})

export default withSnackbar(
  withRouter(connect(mapStateToProps)(ActionTemplate))
)

import { Trans } from '@lingui/macro'
import { Button, Grid, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PDFDownloadLink } from '@react-pdf/renderer'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { getFormPage } from 'app/services/sfAuth/sfData/sfForms'
import { getMomentDateFormatForLanguage } from 'app/views/common/TranslationsCommon'
import { CollapseElement } from 'app/views/forms/form-page/CollapseElement'
import { ConfirmationDialog } from 'app/views/surveys/SurveyTab'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from '../../../../egret/components/EgretLoadable/Loading'
import {
  MILESTONE_TRANSLATIONS,
  STF_LP_RECORD_TYPE,
  STF_SIP_RECORD_TYPE,
  getBaseFields,
  getOpportunity,
  oppToStepper,
  otherFields
} from '../../../services/sfAuth/sfData/sfOpportunity'
import {
  getReportParsed,
  getReportsForOpportunity
} from '../../../services/sfAuth/sfData/sfReports'
import { globalValues } from '../../../utils/GlobalValues'
import { CurrencyFormated } from '../../common/Formats'
import Form from '../../forms/Form'
import { constructFormAddressString } from '../../forms/common/Common'
import { fieldsFromList } from '../../opportunity/OpportunityView'
import {
  DATA_LIST,
  DATA_ON_BOTTOM,
  DATA_ON_TOP,
  FUND_IMAGE,
  GRID,
  HEADER,
  LIST_TO_OBJECTS,
  PAGE_BREAK,
  PAPER
} from '../../opportunity/opportunityViewComponents'
import { getProperFormForOpportunity } from '../applications/ApplicationUtils'
import { GRANTEE_REPORT_COMPLETED_STAGES, GRANTEE_REPORT_STATUSES } from './GranteeReportData'

export const granteeReportConfiguration = ({
  files,
  classes,
  pmView,
  applicationList,
  granteeReview
}) => {
  return {
    fields: [
      {
        type: HEADER,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Application Details</Trans>
          </span>
        )
      },
      {
        type: GRID,
        fields: [
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              padding: 15,
              flexWrap: 'nowrap'
            },
            paperClass: classes.titleCard,
            fields: [
              {
                type: GRID,
                // flexWrap: '',
                justify: 'center',
                gridClass: classes.titleGrid,
                fields: [
                  {
                    type: FUND_IMAGE,
                    imageClass: classes.imageLogo,
                    fundTypeName: (opp) => opp.info.fundTypeName
                  },
                  {
                    type: GRID,
                    direction: 'column',
                    style: {
                      width: '300px',
                      marginLeft: '20px',
                      height: 'auto'
                    },
                    fields: [
                      {
                        type: DATA_ON_TOP,
                        gridClass: classes.nameGrid,
                        data: (opp) => opp.projectDetails.projectName,
                        subtitle: (opp) => (
                          <span className={classes.subtitle}>
                            <Trans id={opp.info.fundTypeName} />
                          </span>
                        )
                      },
                      {
                        type: GRID,
                        alignItems: 'center',
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            disabled: (opp) => !pmView,
                            data: (opp) => (
                              <CurrencyFormated
                                value={opp.projectDetails.amountRequested}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount Requested</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: (opp) => (
                              <CurrencyFormated
                                value={opp.projectDetails.grantAwarded}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount awarded</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: (opp) => (
                              <CurrencyFormated value={opp.info.budgetTotal} />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Overall Project Budget</Trans>
                              </span>
                            )
                          },
                          {
                            type: DATA_ON_TOP,
                            style: { width: 150 },
                            data: (opp) => (
                              <CurrencyFormated
                                value={opp.projectDetails.amountRecieved}
                              />
                            ),
                            subtitle: (
                              <span className={classes.subtitle}>
                                <Trans>Amount received so far</Trans>
                              </span>
                            )
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: PAPER,
            style: {
              width: '100%',
              marginLeft: 20,
              marginRight: 20,
              padding: 15
            },
            paperClass: classes.programManagerCard,
            fields: [
              {
                type: GRID,
                // gridClass: classes.gridContainerStretch,
                alignItems: 'center',
                justify: 'flex-start',
                fields: [
                  {
                    type: DATA_ON_TOP,
                    lg: 6,
                    data: (opp) => opp.info.pmName,
                    subtitle: <Trans>Program Manager Name</Trans>
                  },
                  {
                    type: DATA_ON_TOP,
                    lg: 6,
                    data: (opp) =>
                      opp.info.pmContact
                        ? opp.info.pmContact.split(';')[0]
                        : '',
                    subtitle: <Trans>Program Manager Email</Trans>
                  }
                  // {
                  //   type: DATA_ON_TOP,
                  //   lg: 4,
                  //   data: opp =>
                  //     opp.info.pmContact ? opp.info.pmContact.split(';')[1] : '',
                  //   subtitle: <Trans>Program Manager Phone</Trans>
                  // }
                ]
              }
            ]
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: (opp) => opp.projectDetails.description,
        subtitle: <Trans>Description</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretchNoBreak,
        paperClass: classes.dataContainer,
        data: (opp) => (
          <div
            dangerouslySetInnerHTML={{
              __html: opp.projectDetails.detailedDescription
            }}
          />
        ),
        subtitle: <Trans>Detailed Description</Trans>
      },
      {
        type: PAGE_BREAK
      },
      {
        type: HEADER,
        style: { fontSize: 14, fonWeight: 'bold' },
        disabled: (opp) => opp.projectDetails.grants.length === 0,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Other Grants</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: (opp) => opp.projectDetails.grants,
        conf: {
          fields: [
            {
              type: GRID,
              style: { marginBottom: 20, paddingLeft: 20, paddingRight: 20 },
              gridClass: classes.gridContainer,
              justify: 'center',
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: (item) => (item ? item.name : ''),
                  subtitle: <Trans>Name</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: (item) =>
                    item ? <CurrencyFormated value={item.value} /> : '',
                  subtitle: <Trans>Value</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.otherGrantsGrid,
                  paperStyle: { minHeight: 90 },
                  lg: 4,
                  data: (item) => (item ? <Trans id={item.state} /> : ''),
                  subtitle: <Trans>Grants: State</Trans>
                }
              ]
            }
          ]
        }
      },
      // ...fieldsFromList(
      //   [
      //     {
      //       field: 'projectDetails.grantEssential',
      //       paperClassName: classes.dataContainer,
      //       subtitle: <Trans>How will the Centre's grant be essential</Trans>
      //     },
      //     {
      //       field: 'projectDetails.partners',
      //       subtitle: <Trans>Partners</Trans>
      //     },
      //     {
      //       field: 'projectDetails.challenges',
      //       subtitle: <Trans>Challenges</Trans>
      //     }
      //   ],
      //   classes.dataContainer
      // ),
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: (opp) => opp.projectDetails.grantEssential,
        subtitle: <Trans>How will the Centre's grant be essential</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: (opp) => opp.projectDetails.partners,
        subtitle: <Trans>Partners</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        // style: { padding: 20 },
        data: (opp) => opp.projectDetails.challenges,
        subtitle: <Trans>Challenges</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainer,
        justify: 'center',
        fields: [
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Centre's priority</Trans>,
            data: (opp) => opp.projectDetails.priorityAreas
          },
          {
            type: DATA_ON_BOTTOM,
            gridClass: classes.gridContainerStretch,
            paperClass: classes.dataContainer,
            // style: { padding: 20 },
            data: (opp) => opp.projectDetails.priorityAreasDescription,
            subtitle: <Trans>Priority Areas Description</Trans>
          },
          {
            type: DATA_LIST,
            bulletIcon: 'public',
            paperClass: classes.dataList,
            subtitle: <Trans>Scope</Trans>,
            data: (opp) => opp.projectDetails.geographicalScopeType
          },
          {
            type: DATA_ON_BOTTOM,
            gridClass: classes.gridContainerStretch,
            paperClass: classes.dataContainer,
            // style: { padding: 20 },
            data: (opp) => opp.projectDetails.geographicalScopeDetails,
            subtitle: <Trans>Geographical Location</Trans>
          },
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            // style: { padding: '15' },
            bulletIcon: 'accessibility_new',
            subtitle: <Trans>Housing providers type beneficiaries</Trans>,
            data: (opp) => opp.projectDetails.housingProviders
          },
          // old demographic if filled
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            disabled: (opp) =>
              !opp.projectDetails.demographic ||
              opp.projectDetails.demographic.length === 0,
            bulletIcon: 'supervised_user_circle',
            subtitle: <Trans>Demographic</Trans>,
            data: (opp) => opp.projectDetails.demographic
          }
        ]
      },
      // new demographic if old is empty
      {
        type: GRID,
        gridClass: classes.gridContainer,
        disabled: (opp) =>
          !(
            !opp.projectDetails.demographic ||
            opp.projectDetails.demographic.length === 0
          ),
        justify: 'center',
        fields: [
          // check hasTargetDemographic
          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'supervised_user_circle',
            style: { width: '100%' },
            subtitle: <Trans>Target demographic (application)</Trans>,
            data: (opp) =>
              pmView || applicationList
                ? opp.projectDetails.targetDemographic
                : opp.projectDetails.targetDemographicPM
          },
          {
            type: DATA_ON_BOTTOM,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            // style: { padding: 20 },
            dataClass: 'largeText',
            disabled: (opp) =>
              Boolean(!applicationList && !pmView) ||
              !opp.projectDetails.otherTargetDemographic,
            data: (opp) => opp.projectDetails.otherTargetDemographic,
            subtitle: <Trans>Other - target demographic</Trans>
          },
          {
            type: HEADER,
            style: { fontSize: 18, fonWeight: 'bold', width: '100%' },
            text: (item) => <Trans>Units identified for each group:</Trans>
          },
          {
            type: GRID,
            disabled: (opp) => granteeReview,
            gridClass: classes.gridContainerStretch,
            justify: 'center',
            fields: [
              ...fieldsFromList(
                [
                  {
                    field: 'projectDetails.unitsWomen',
                    subtitle: <Trans>Units - women and children</Trans>
                  },
                  {
                    field: 'projectDetails.unitsFleeingDomesticViolence',
                    subtitle: <Trans>Units - fleeing domestic violence</Trans>
                  },
                  {
                    field: 'projectDetails.unitsSingleParent',
                    subtitle: <Trans>Units - single parent</Trans>
                  },
                  {
                    field: 'projectDetails.unitsIndigenous',
                    subtitle: <Trans>Units - Indigenous</Trans>
                  },
                  {
                    field: 'projectDetails.unitsRacializedPersons',
                    subtitle: <Trans>Units - racialized persons</Trans>
                  }
                ],
                classes.gridChild,
                { paddingLeft: 10 }
              )
            ]
          },

          {
            type: DATA_LIST,
            paperClass: classes.dataList,
            bulletIcon: 'supervised_user_circle',
            subtitle: <Trans>Supported demographics</Trans>,
            style: { width: '100%' },
            data: (opp) => opp.projectDetails.supportedDemographic
          },
          {
            // check hasSupportedDemographic
            type: DATA_ON_BOTTOM,
            paperClass: classes.dataContainer,
            gridClass: classes.gridContainerStretch,
            dataClass: 'largeText',
            data: (opp) => opp.projectDetails.otherSupportedDemographic,
            disabled: (opp) => !opp.projectDetails.otherSupportedDemographic,
            subtitle: <Trans>Other - supported demographic</Trans>
          }
        ]
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        disabled: (opp) =>
          opp.info.fundTypeName === STF_SIP_RECORD_TYPE ||
          opp.info.fundTypeName === STF_LP_RECORD_TYPE,
        data: (opp) => opp.projectDetails.tenantsDescription,
        subtitle: <Trans>Tenant Questions</Trans>
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        disabled: (opp) =>
          opp.info.fundTypeName === STF_SIP_RECORD_TYPE ||
          opp.info.fundTypeName === STF_LP_RECORD_TYPE,
        data: (opp) => opp.projectDetails.tenantsInvolvement,
        subtitle: <Trans>Social Inclusion</Trans>
      },
      {
        type: GRID,
        gridClass: classes.gridContainerStretch,
        justify: 'center',
        fields: [
          ...fieldsFromList(
            [
              {
                field: 'projectDetails.impactOrganization',
                subtitle: <Trans>Numbers of organization affected</Trans>
              },
              {
                field: 'projectDetails.impactBuildings',
                subtitle: <Trans>Number of buildings affected</Trans>
              },
              {
                field: 'projectDetails.impactUnits',
                subtitle: <Trans>Number of units affected</Trans>
              },
              {
                field: 'projectDetails.impactStaff',
                subtitle: <Trans>Number of staff affected</Trans>
              },
              {
                field: 'projectDetails.tenantsAffected',
                subtitle: <Trans>Number of Tenants affected</Trans>
              }
            ],
            classes.gridChild,
            { padding: 10 }
          )
        ]
      },
      ...fieldsFromList(
        [
          {
            field: 'projectDetails.impactSustainable',
            gridClassName: classes.gridContainerStretch,
            subtitle: <Trans>Transformational impact description</Trans>
          },
          {
            field: 'projectDetails.sharingDescription',
            gridClassName: classes.gridContainerStretch,
            subtitle: <Trans>Knowledge transfer</Trans>
          }
        ],
        classes.dataContainer
      ),
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: (opp) => opp.objectives.objectives.length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Objectives</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: (opp) => opp.objectives.objectives,
        conf: {
          type: PAPER,
          fields: [
            {
              type: GRID,
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              justify: 'center',
              // style: { padding: 20 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  gridClass: classes.gridObjectives,
                  paperClass: classes.paperContainerStretch,
                  data: (item) => (item ? <Trans id={item.objective} /> : ''),
                  subtitle: <Trans>Objective</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  gridClass: classes.gridObjectives,
                  paperClass: classes.paperContainerStretch,
                  data: (item) => (item ? item.needs : ''),
                  subtitle: <Trans>Needs</Trans>
                },
                {
                  type: DATA_LIST,
                  style: { margin: 0 },
                  paperClass: classes.paperContainerStretch,
                  bulletIcon: 'fiber_manual_record',
                  data: (item) => (item ? item.outcomes : []),
                  subtitle: <Trans>Outcomes</Trans>
                }
              ]
            }
          ]
        }
      },
      {
        type: PAGE_BREAK
      },
      {
        type: HEADER,
        style: { fontSize: 24 },
        text: (item) => (
          <span className={classes.mainTitle}>
            <Trans>Benchmarks</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: (opp) => opp.milestones.milestones,
        conf: {
          type: PAPER,
          fields: [
            {
              type: GRID,
              // style: { width: 'auto', margin: 0 },
              direction: 'column',
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              style: { marginBottom: 0 },
              // style: { width: 'auto' },
              fields: [
                {
                  type: HEADER,
                  style: { fontSize: 18, fonWeight: 'bold' },
                  // text: <Trans>Budget Revenues</Trans>
                  text: (item) => (
                    <Trans id={MILESTONE_TRANSLATIONS[item.stage]} />
                  )
                },
                {
                  type: GRID,
                  gridClass: classes.gridContainerStretch,
                  paperClass: classes.dataContainer,
                  style: { margin: 0, padding: 0 },
                  fields: [
                    {
                      type: DATA_ON_BOTTOM,
                      lg: 6,
                      dataClass: 'largeText',
                      paperClass: classes.gridChildHalfWidth,
                      data: (opp) => moment(opp.startDate).format('DD/MM/YYYY'),
                      subtitle: <Trans>Start Date</Trans>
                    },
                    {
                      type: DATA_ON_BOTTOM,
                      lg: 6,
                      dataClass: 'largeText',
                      paperClass: classes.gridChildHalfWidth,
                      data: (opp) => moment(opp.endDate).format('DD/MM/YYYY'),
                      subtitle: <Trans>End Date</Trans>
                    }
                  ]
                }
              ]
            },
            {
              type: GRID,
              style: { width: 'auto', margin: 0 },
              gridClass: classes.gridContainerStretch,
              paperClass: classes.dataContainer,
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  data: (item) => item.primaryActivities,
                  subtitle: <Trans>Primary Activities</Trans>
                },
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  data: (item) => item.comments,
                  subtitle: <Trans>Comments</Trans>
                }
              ]
            }
          ]
        }
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: (opp) => Object.entries(opp.budget.revenues).length === 0,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: (
          <span className={classes.mainTitle}>
            <Trans>Budget Revenues</Trans>
          </span>
        )
      },
      {
        type: LIST_TO_OBJECTS,
        list: (opp) =>
          Object.entries(opp.budget.revenues).filter((revenue) => {
            const milestones = opp.milestones.milestones
            const endMilestone = milestones[milestones.length - 1]
            if (!endMilestone) {
              return false
            }
            const year = revenue[0]
            const endYear = moment(endMilestone.endDate).year()
            return year <= endYear
          }),
        conf: {
          fields: [
            {
              type: PAPER,
              style: { width: 'auto', margin: 0, padding: 10 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  dataClass: 'largeText',
                  paperClass: classes.paperContainerStretch,
                  data: ([year, budget]) => year,
                  subtitle: <Trans>Year</Trans>
                },
                {
                  type: LIST_TO_OBJECTS,
                  list: ([year, budget]) => Object.entries(budget).reverse(),
                  conf: {
                    fields: [
                      {
                        type: GRID,
                        gridClass: classes.gridContainer,
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable4,
                            lg: 4,
                            data: ([name, item]) => <Trans id={name} />,
                            subtitle: <Trans>Budget Item</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable2,
                            lg: 8,
                            data: ([name, item]) => (
                              <CurrencyFormated value={item.value} />
                            ),
                            subtitle: <Trans>Revenue</Trans>
                          }
                          // {
                          //   type: DATA_ON_TOP,
                          //   lg: 4,
                          //   data: ([name, item]) => item.comment,
                          //   subtitle: <Trans>Comment</Trans>
                          // },
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          ]
        }
      },
      {
        type: HEADER,
        pageBreakBefore: true,
        disabled: (opp) => Object.entries(opp.budget.expenses).length === 0,
        text: (
          <span className={classes.mainTitle}>
            <Trans>Budget Expenses</Trans>
          </span>
        ),
        style: { fontSize: 24, fonWeight: 'bold' }
      },
      {
        type: LIST_TO_OBJECTS,
        list: (opp) => {
          return Object.entries(opp.budget.expenses).filter((expense) => {
            const milestones = opp.milestones.milestones
            const endMilestone = milestones[milestones.length - 1]
            if (!endMilestone) {
              return false
            }
            const year = expense[0]
            const endYear = moment(endMilestone.endDate).year()
            return year <= endYear
          })
        },
        conf: {
          fields: [
            {
              type: PAPER,
              // paperClass: classes.paperContainerStretch,
              style: { width: 'auto', margin: 0, padding: 10 },
              fields: [
                {
                  type: DATA_ON_BOTTOM,
                  paperClass: classes.paperContainerStretch,
                  dataClass: 'largeText',
                  data: ([year, budget]) => year,
                  subtitle: <Trans>Year</Trans>
                },
                {
                  type: LIST_TO_OBJECTS,
                  list: ([year, budget]) => Object.entries(budget).reverse(),
                  conf: {
                    fields: [
                      {
                        type: GRID,
                        gridClass: classes.gridContainer,
                        fields: [
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable1,
                            lg: 4,
                            data: ([name, item]) => <Trans id={name} />,
                            subtitle: <Trans>Budget Item</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable2,
                            lg: 4,
                            data: ([name, item]) => (
                              <CurrencyFormated value={item.value} />
                            ),
                            subtitle: <Trans>Expense</Trans>
                          },
                          {
                            type: DATA_ON_TOP,
                            gridClass: classes.gridChildTable3,
                            lg: 4,
                            data: ([name, item]) => item.comment,
                            subtitle: <Trans>Comment</Trans>
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          ]
        }
      }
    ]
  }
}

const GranteeReport = ({ ...props }) => {
  const [application, setApplication] = useState(null)
  const [report, setReport] = useState(null)
  const [isInvalid, setIsInvalid] = useState(false)
  const [opportunityPreviewForm, setOpportunityPreviewForm] = useState(null)
  const [reportForm, setReportForm] = useState(null)
  const fundingStreams = useSelector((state) => state.fundingStreams.streams)
  const user = useSelector((state) => state.user)
  const { enqueueSnackbar } = useSnackbar()
  const reportId = props.match.params.id
  const [previousReport, setPreviousReport] = useState(null)
  const [collapseApplication, setCollapseApplicaiton] = useState(true)

  useEffect(() => {
    if (reportId) {
      enqueueSnackbar(<Trans>Loading Report</Trans>, {
        variant: 'info'
      })
      getReportParsed(reportId)
        .then((reportObj) => {
          console.log('got report', reportObj, globalValues.reportRecordTypes)
          const templateName = reportObj.type
          console.log('template Name', templateName)
          if (!templateName || templateName === 'Narrative and Financial') {
            console.error('Invalid record type')
            setIsInvalid(true)
            return Promise.resolve()
          }

          return getOpportunity(reportObj.opportunity, sfOauthConfig.isInternal ? { fields: getBaseFields().concat(otherFields({ withStar: false })) } : {}).then((opportunity) => {
            let reportFormId
            const config = fundingStreams.find(
              (fs) => fs.id === opportunity.Funding_Stream__c
            )
            const formId = getProperFormForOpportunity({
              config,
              grantForm: opportunity.Grant_Form__c,
              wasHardCoded: opportunity.Hardcoded_form__c,
              oldFormVersionField: opportunity.Form_Version__c,
              stage: opportunity.StageName,
              printView: true
            })

            if (config) {
              reportFormId = config.granteeReportForm
            }

            return getReportsForOpportunity(reportObj.opportunity).then((reportsForOpportunity) => {
              console.log(
                'got reports for opportunity',
                reportsForOpportunity,
                opportunity
              )
              opportunity = oppToStepper(opportunity)

              const sortedReports = reportsForOpportunity
                .filter(
                  (report) =>
                    report.dueDate &&
                    report.recordTypeId === reportObj.recordTypeId
                )
                .sort((a, b) => {
                  const val1Date = moment.utc(a.dueDate)
                  const val2Date = moment.utc(b.dueDate)
                  return val1Date.diff(val2Date, 'minutes')
                })
              let reportIndex
              reportObj.firstReportDate =
              sortedReports[0]?.id === reportId
                ? opportunity.milestones
                  ? opportunity.milestones.milestones[0]?.startDate
                  : null
                : null

              reportObj.reportPeriodStart = reportObj.firstReportDate
                ? moment.utc(reportObj.firstReportDate)
                : moment.utc(
                  opportunity.info.contractSigned || reportObj.submissionDate
                )

              sortedReports.some((report, index) => {
                if (sortedReports.length <= 1) {
                  return true
                }
                if (report.id === reportId) {
                  reportIndex = index + 1
                }
                return report.id === reportId
              })
              reportObj.reportIndex = reportIndex

              const submittedReports = reportsForOpportunity
                .filter(
                  (obj) =>
                    obj.id !== reportId &&
                    obj?.status !== GRANTEE_REPORT_STATUSES.OBSOLETE &&
                    moment(obj.dueDate).isBefore(
                      reportObj.dueDate
                    )
                )
                .sort((a, b) => {
                  return moment(b.submissionDate).diff(
                    a.submissionDate
                  )
                })

              if (submittedReports.length > 0) {
                setPreviousReport(submittedReports[0])
              }
              console.log('loaded report', reportObj)
              console.log('loaded opportunity', opportunity)

              if (!reportFormId) {
                enqueueSnackbar(
                  <Trans>GRANTEE_REPORT_ERROR_LOADING_NO_REPORT_FORM</Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              if (!formId) {
                enqueueSnackbar(
                  <Trans>GRANTEE_REPORT_ERROR_LOADING_NO_PREVIEW_FORM</Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              return Promise.all([
                formId ? getFormPage(formId) : Promise.resolve(null),
                reportFormId
                  ? getFormPage(reportFormId)
                  : Promise.resolve(null)
              ]).then(([previewForm, surveyForm]) => {
                setOpportunityPreviewForm(previewForm)
                setReportForm(surveyForm)
                setApplication(opportunity)
                setReport(reportObj)
              })
            })
          })
        })
        .catch((error) => {
          console.error('errorloading report', error)
          enqueueSnackbar(<Trans>Error loading Report</Trans>, {
            variant: 'error'
          })
        })
    }
  }, [reportId])

  if (isInvalid) {
    return (
      <div style={{ padding: 20, textAlign: 'center' }}>
        <h3 style={{ color: 'red' }}>
          <Trans>This report's record type in invalid!</Trans>
        </h3>
      </div>
    )
  }

  if (!report || !application || !reportForm || !opportunityPreviewForm) {
    return <Loading />
  }

  const isEditableStage = [GRANTEE_REPORT_STATUSES.MORE_INFO_REQUIED, GRANTEE_REPORT_STATUSES.REQUESTED, GRANTEE_REPORT_STATUSES.SCHEDULED].includes(report.status)

  const periodStart = moment
    .utc(report.reportPeriodStart)
    .format(getMomentDateFormatForLanguage(user.language))
  const periodEnd = moment.utc().format(getMomentDateFormatForLanguage(user.language))

  return (
    <>
      <Grid
        container
        style={{
          top: 30,
          paddingLeft: 30,
          position: 'absolute'
        }}
      >
        <Button
          color='primary'
          variant='outlined'
          onClick={(e) => {
            setCollapseApplicaiton(!collapseApplication)
          }}
        >
          {collapseApplication
            ? (
              <Trans>GRANTEE_REPORT_SHOW_APPLICATION_BUTTON</Trans>
              )
            : (
              <Trans>GRANTEE_REPORT_HIDE_APPLICATION_BUTTON</Trans>
              )}
        </Button>

        <Link
          to={`/grants/grant-application/${application.info.id}/preview`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button color='primary' variant='outlined'>
            <Trans>GRANTEE_REPORT_OPEN_APPLICATION_IN_NEW_WINDOW</Trans>
          </Button>
        </Link>
      </Grid>
      {previousReport && (
        <div
          style={{
            top: 30,
            right: 30,
            position: 'absolute'
          }}
        >
          <Form
            formId={previousReport.form || reportForm.id}
            defaultFormType='pdf'
            fetchString={constructFormAddressString({
              ids: {
                User: props.user,
                FGM_Base__Grantee_Report__c: previousReport.id,
                Opportunity: application.info.id,
                Account: application.info.account,
                Funding_Stream__c: application.info.fundingStream
              },
              objectsConnected: reportForm.objectsConnected
            })}
            hideLoading
            returnPdf={(doc, formTitle, fetchData) => {
              return (
                <PDFDownloadLink
                  fileName={formTitle}
                  document={doc}
                >
                  {({ blob, url, loading, error }) => {
                    return (
                      <Button
                        color='primary'
                        variant='outlined'
                        onClick={(e) => {}}
                      >
                        <Trans>
                          GRANTEE_REPORT_DOWNLOAD_PREVIOUS_REPORT_BUTTON
                        </Trans>
                      </Button>
                    )
                  }}
                </PDFDownloadLink>
              )
            }}
          />
        </div>
      )}
      <Grid
        container
        wrap='nowrap'
        style={{
          flexGrow: 1,
          height: '100%'
        }}
      >
        <CollapseElement
          orientation='horizontal'
          title='formTitle'
          open={!collapseApplication ? 'show' : 'hide'}
          noUI
        >
          <Form
            style={{ paddingTop: 100 }}
            formId={opportunityPreviewForm.id}
            defaultFormType='printable'
            mainObject='Opportunity'
            fetchString={constructFormAddressString({
              ids: {
                User: props.user,
                FGM_Base__Grantee_Report__c: report.id,
                Opportunity: application.info.id,
                Account: application.info.account,
                Funding_Stream__c: application.info.fundingStream
              },
              objectsConnected: opportunityPreviewForm.objectsConnected
            })}
            fixedDisplay
            disablePDF
          />
        </CollapseElement>

        <Grid xs item container>
          <>
            <Form
              confirmationDialog={
                <ConfirmationDialog opportunity={application} />
              }
              customTitleRender={title => {
                return (
                  <>
                    <Typography
                      variant='h6'
                      style={{
                        textAlign: 'center',
                        marginBottom: 20,
                        margintTop: 20
                      }}
                    >
                      {title} {report.reportIndex}
                    </Typography>
                    <Typography style={{ textAlign: 'center' }} variant='h6'>
                      <Trans>
                        Report covers time period from <b>{periodStart}</b> to{' '}
                        <b>{periodEnd}</b>
                      </Trans>
                    </Typography>
                  </>
                )
              }}
              infoBanner={report.status === GRANTEE_REPORT_STATUSES.SCHEDULED && (
                <Alert variant='standard' severity='info'>
                  <AlertTitle>
                    <Trans>
                      This report is scheduled. You cannot fill the survey
                      yet!
                    </Trans>
                  </AlertTitle>
                </Alert>
              )}
              style={{ paddingTop: 100 }}
              containerViewType='none'
              formId={reportForm.id}
              forceDisabled={sfOauthConfig.isInternal}
              defaultFormType={GRANTEE_REPORT_COMPLETED_STAGES.includes(report.status) ? 'printable' : 'editable'}
              mainObject='FGM_Base__Grantee_Report__c'
              fetchString={constructFormAddressString({
                ids: {
                  User: props.user,
                  FGM_Base__Grantee_Report__c: report.id,
                  Opportunity: application.info.id,
                  Account: application.info.account,
                  Funding_Stream__c: application.info.fundingStream
                },
                objectsConnected: reportForm.objectsConnected
              })}
              fixedDisplay
            />
          </>
        </Grid>
      </Grid>
    </>
  )
}

export default GranteeReport

import React from 'react'
import PropTypes from 'prop-types'
import './styles/PageWrapper.css'

const PageWrapper = ({ children }) => {
  return (
    <div className='resources grid'>{children}</div>
  )
}

export default PageWrapper

PageWrapper.propTypes = {
  children: PropTypes.any
}

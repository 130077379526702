import { Objective } from './FormObjectives'

export const FormObjectivesPrint = ({ title, value }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title'>{title}</div>
      {value?.map((data, index) => {
        const { objective, needs, outcomes = [] } = data
        return <Objective data={data} index={index} />
      })}
    </div>
  )
}

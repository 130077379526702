import { globalValues } from 'app/utils/GlobalValues'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { parseBudgetUpdateSFObject } from './reports/sfBudgetUpdate'
import { parseMilestoneUpdateSFObject } from './sfMilestoneUpdate'
import { parseObjectiveUpdateSFObject } from './sfObjectivesUpdate'
import { SF_API_VERSION } from './sfOpportunity'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Type__c: 'type', // ['Final report', 'Interim', 'Phone call'] // Interim should be translate as Progress report
  FGM_Base__Due_Date__c: 'dueDate',
  Survey__c: 'surveyId',
  RecordTypeId: 'recordTypeId',
  Budget_changes__c: 'hasBudgetChanged',
  RecordType: {
    // only flat object
    fieldName: 'recordType',
    Name: 'name',
    DeveloperName: 'developerName'
  },
  Form__c: 'form',
  FGM_Base__Request__c: 'opportunity',
  FGM_Base__Type__c: 'scope', // ['Financial', 'Narrative and Financial', 'Narrative']
  FGM_Base__Status__c: 'status', // ['Requested', 'Received', 'Approved', 'Rejected', 'Submitted', 'Scheduled']
  FGM_Base__Submission_Date__c: 'submissionDate',
  FGM_Base__Submitted_By__c: 'submittedBy'
  // Benchmark_Updates__r: {
  //   key: 'milestonesUpdates',
  //   in: obj => {
  //     return obj.Full_name__r.records
  //       ? obj.Full_name__r.records.map(milestoneObj => )
  //       : []
  //   }
  // }
}

interface GranteeReportPermissionsType {
  NONE?: boolean
  FULL?: boolean
}

/**
 * Sanitizes a report type by converting 'Interim' to 'Progress report'.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {string} type The report type to sanitize.
 * @returns {string} The sanitized report type.
 */
export const sanitizeReportType = (type: string) => {
  if (type === 'Interim') {
    // TODO handle this better
    return 'Progress report'
  }
  return type
}

const reverseMapFields = reverseMap(mapFields)

/**
 * Checks if a report is editable based on its status.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {string} reportStatus The status of the report.
 * @returns {boolean} True if the report is editable, otherwise false.
 */
export const reportIsEditable = (reportStatus: string) => {
  return (
    ['Rejected', 'Requested', 'Scheduled', 'More info required'].includes(
      reportStatus
    )
  )
}

/**
 * Retrieves reports associated with a specific opportunity.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c and Opportunity__c
 * @param {string} opportunityId The id of the opportunity.
 * @returns {object[]} An array of report objects.
 */
export const getReportsForOpportunity = (opportunityId: string, permissions: GranteeReportPermissionsType = {}, parsed: boolean = true): any => {
  const conn = SFAuthService.getConnection()
  if (conn === null) {
    return Promise.reject(NO_USER)
  }

  let selectString = ''
  if (permissions.FULL) {
    selectString += ', (SELECT Id, Benchmark__c, Benchmark_Name__c, Name, CreatedById, CreatedDate, Grantee_Report__c, LastModifiedById, Phase_end_date__c, Phase_start_date__c, Primary_activities_planned__c, Primary_activities_progress__c FROM Benchmark_Updates__r ORDER BY CreatedDate DESC)'
    selectString += ', (SELECT Id, Objective__c, Identified_need_changes__c, Actual_outcomes__c, Desired_outcome_changes__c, CreatedDate FROM Objective_Updates__r ORDER BY CreatedDate DESC)'
    selectString += ', (SELECT Id, Name, CreatedDate, Budget_Category__c, Actual_amount__c, Actual_Amount_Comments__c, Amount_changes__c, Change_details__c, Grantee_Report__c FROM Budget_Updates__r ORDER BY CreatedDate DESC)'
  }

  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(
      'Id, Name, Type__c, RecordTypeId, Form__c, FGM_Base__Status__c, FGM_Base__Is_Overdue__c, FGM_Base__Due_Date__c, FGM_Base__Submission_Date__c' + selectString
    ).then(result => {
      if (parsed) {
        return result.map(reportObj => {
          return {
            ...mapSFToForm(mapFields, reportObj),
            milestonesUpdates: reportObj?.Benchmark_Updates__r?.records.map(budgetUpdateObj => parseMilestoneUpdateSFObject(budgetUpdateObj)) || [],
            budgetUpdates: reportObj?.Budget_Updates__r?.records.map(budgetUpdateObj => parseBudgetUpdateSFObject(budgetUpdateObj)) || [],
            objectiveUpdates: reportObj?.Objective_Updates__r?.records.map(objectiveUpdateObj => parseObjectiveUpdateSFObject(objectiveUpdateObj)) || []
          }
        })
      } else {
        return result
      }
    })
}

export const getReportsWithMilestoneUpdatesForOpportunity = (opportunityId) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(
      'Id, Name, Type__c, RecordTypeId, FGM_Base__Status__c, FGM_Base__Is_Overdue__c, FGM_Base__Due_Date__c, FGM_Base__Submission_Date__c, (SELECT Id, Benchmark__c, Benchmark_Name__c, Name, CreatedById, CreatedDate, Grantee_Report__c, LastModifiedById, Phase_end_date__c, Phase_start_date__c, Primary_activities_planned__c, Primary_activities_progress__c FROM Benchmark_Updates__r ORDER BY CreatedDate DESC)'
    )
}

/**
 * Retrieves reports associated with a specific opportunity along with objective updates.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {string} opportunityId The id of the opportunity.
 * @returns {object[]} An array of report objects with associated objective updates.
 */
export const getReportsForOpportunityWithObjectiveUpdates = (
  opportunityId: string
) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(
      'Id, Name, Type__c, RecordTypeId, FGM_Base__Status__c, FGM_Base__Is_Overdue__c, FGM_Base__Due_Date__c, FGM_Base__Submission_Date__c, (SELECT Id, Objective__c, Identified_need_changes__c, Actual_outcomes__c, Desired_outcome_changes__c, CreatedDate FROM Objective_Updates__r ORDER BY CreatedDate DESC)'
    )
}

/**
 * Retrieves a single report by its ID.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {string} id The id of the report to retrieve.
 * @returns {object|null} The report object if found, or null if not found.
 */
export const getReport = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Grantee_Report__c')
    .findOne({
      Id: id
    })
    .select('*, RecordType.Name')
}

/**
 * Retrieves a single report by its id and parses it.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {string} id The id of the report to retrieve and parse.
 * @returns {object|null} The parsed report object if found, or null if not found.
 */
export const getReportParsed = (id: string) => {
  // console.log('getSurveyParsed', id)
  return getReport(id).then((obj) => mapSFToForm(mapFields, obj))
}

interface ReportCreationObject {
  opportunityId: string
  recordType?: string
  reportType?: string
  dueDate?: Date
}

/**
 * Creates multiple reports based on the provided array of objects.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {ReportCreationObject[]} array An array of objects containing the details of reports to create.
 * @returns {object[]} An array of created report objects.
 */
export const createReports = (array: ReportCreationObject[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const reportRecordTypeId =
    globalValues.reportRecordTypes.Report &&
    globalValues.reportRecordTypes.Report.recordTypeId
  return conn.sobject('FGM_Base__Grantee_Report__c').create(
    array.map((ob) => {
      return {
        FGM_Base__Request__c: ob.opportunityId,
        RecordTypeId: ob.recordType || reportRecordTypeId,
        Type__c: ob.reportType || 'Interim',
        FGM_Base__Due_Date__c: ob.dueDate
      }
    })
  )
}

/**
 * Creates a report associated with a given opportunity.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {ReportCreationObject} options Options for creating the report.
 * @param {string} options.opportunityId The id of the opportunity associated with the report.
 * @param {string} [options.recordType] The record type id of the report. If not provided, the default record type id will be used.
 * @param {string} [options.reportType] The type of the report. Defaults to 'Interim' if not provided.
 * @param {Date} [options.dueDate] The due date of the report.
 * @returns {JSForceResult}
 */
export const createReport = ({
  opportunityId,
  recordType,
  reportType,
  dueDate
}: ReportCreationObject) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const reportRecordTypeId =
    globalValues.reportRecordTypes.Report &&
    globalValues.reportRecordTypes.Report.recordTypeId
  return conn.sobject('FGM_Base__Grantee_Report__c').create({
    FGM_Base__Request__c: opportunityId,
    RecordTypeId: recordType || reportRecordTypeId,
    Type__c: reportType || 'Interim',
    FGM_Base__Due_Date__c: dueDate
  })
}

/**
 * Clears scheduled objects from Salesforce based on their IDs.
 * @function
 * @category Salesforce
 * @param {string[]} toDelete An array containing the ids of the objects to be deleted.
 * @returns {JSForceResult}
 */
export const clearSchedule = (toDelete: string[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  let request
  if (toDelete.length < 200) {
    request = [
      {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          toDelete.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects'
      }
    ]
  } else {
    const subArrays = new Array(Math.ceil(toDelete.length / 200))
      .fill(0)
      .map((_) => toDelete.splice(0, 200))
    request = subArrays.map((sub, index) => {
      return {
        method: 'DELETE',
        url:
          `/services/data/${SF_API_VERSION}/composite/sobjects?` +
          'ids=' +
          sub.join(',') +
          '&allOrNone=true',
        referenceId: 'refDeleteObjects' + index
      }
    })
  }

  return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
    compositeRequest: request
  })
}

/**
 * Saves a report in Salesforce using a specified flow.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {object} values The values to be saved.
 * @returns {FlowResult}
 */
export const saveReportByFlow = (values: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  } else {
    return conn.requestPost('/actions/custom/flow/App_Update_Grantee_Report', {
      inputs: [
        {
          updateRecord: { ...values }
        }
      ]
    })
  }
}

/**
 * Saves a report with the provided values.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {object} values The values to update the report with.
 * @returns {JSForceResult}
 */
export const saveReport = (values: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Grantee_Report__c').update(values)
}

/**
 * Parses the provided values and then saves a report with the values.
 * @function
 * @category Salesforce - FGM_Base__Grantee_Report__c
 * @param {object} values The values to be parsed and used to update the report with.
 * @returns {object} Updated report object.
 */
export const saveReportParsed = (values: object) => {
  // console.log('getSurveyParsed', id)(mapSFToForm(reverseMapFields, value))
  return saveReport(mapSFToForm(reverseMapFields, values))
}

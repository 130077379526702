import { Trans } from '@lingui/macro'
import { Typography } from '@material-ui/core'

export const FieldValuePlaceholder = ({ typeProps, mainConnected }) => {
  try {
    const length = typeProps.connectedTo?.length
    const obj = mainConnected?.name
    const name = typeProps.connectedTo?.[length - 1]?.connectedField?.name
    if (!obj) return null
    if (!name && obj) {
      return (
        <>
          Field Value Placeholder from <i>{obj}</i>
        </>
      )
    }
    return (
      <>
        Field Value Placeholder for <i>{name}</i> from <i>{obj}</i>
      </>
    )
  } catch (e) {
    return <>FIELD VALUE PLACEHOLDER</>
  }
}

export const ConnectedField = ({ typeProps, mainConnected, prop }) => {
  try {
    const obj = mainConnected?.name
    const name = typeProps.connectedTo?.[0]?.[prop]?.name
    if (!obj) return null
    if (!name && obj) {
      return (
        <Trans>
          from <i>{obj}</i>
        </Trans>
      )
    }
    return (
      <Trans>
        {name} from <i>{obj}</i>
      </Trans>
    )
  } catch (e) {
    return null
  }
}

export const CommonPlaceholder = ({
  typeProps,
  mainConnected,
  showPdfComponent,
  title,
  objectsConnected,
  ...props
}) => {
  mainConnected =
    mainConnected ||
    objectsConnected?.find(
      obj => obj.identId === typeProps?.connectedTo?.[0]?.connectedObject
    )

  return (
    <Typography style={{ whiteSpace: 'pre-line' }}>
      <FieldValuePlaceholder
        typeProps={typeProps}
        mainConnected={mainConnected}
      />
    </Typography>
  )
}

import { t, Trans } from '@lingui/macro'
import { inuktitutFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

const styles = {
  ...pdfTableStyles,
  tableRow: {
    ...pdfTableStyles.tableRow,
    display: 'flex',
    minHeight: 16
  },
  firstCol: {
    width: '33%'
  },
  secondCol: {
    width: '33%'
  },
  thirdCol: {
    width: '34%'
  },
  headerCell: {
    ...pdfTableStyles.tableCell,
    fontSize: 14,
    padding: '10px 20px',
    fontWeight: 'bold'
  },
  tableCell: {
    ...pdfTableStyles.tableCell,
    fontSize: 14,
    padding: '10px 20px',
    wordBreak: 'break-all'
  }
}

export const FormOtherGrantsPdfEditor = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <div>
      {title ? <div style={pdfTableStyles.title}>{title}</div> : null}
      <div style={styles.table}>
        <div style={styles.tableRow}>
          <div style={{ ...styles.tableCol, ...styles.firstCol }}>
            <div style={styles.headerCell}><Trans>Name</Trans></div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.secondCol }}>
            <div style={styles.headerCell}><Trans>Value</Trans></div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.thirdCol }}>
            <div style={styles.headerCell}>
              <Trans>Status</Trans>
            </div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div style={{ ...styles.tableCol, ...styles.firstCol }}>
            <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_NAME_PLACEHOLDER</Trans></div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.secondCol }}>
            <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_VALUE_PLACEHOLDER</Trans></div>
          </div>
          <div style={{ ...styles.tableCol, ...styles.thirdCol }}>
            <div style={styles.tableCell}><Trans>FORM_OTHER_GRANTS_PDF_EDITOR_STATE_PLACEHOLDER</Trans></div>
          </div>
        </div>
      </div>
    </div>
  )
}

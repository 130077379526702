import { Trans } from '@lingui/macro'
import { Grid } from '@material-ui/core'
import FormikCheckboxGroupField from 'formik-material-fields/lib/FormikCheckboxGroupField'
import FormikSelectField from 'formik-material-fields/lib/FormikSelectField'
import FormikTextField from 'formik-material-fields/lib/FormikTextField'
import NumberFormat from 'react-number-format'
import { CurrencyFormat } from '../common/Formats'
import { FormikCKEditor } from './CKEditor/FormikCKEditor'
import { TooltipLabelIcon } from './TooltipLabelIcon'

const FormElement = ({
  fieldDetails,
  spacing = 1,
  labelDetails,
  style,
  xs,
  lg,
  inline,
  direction
}) => {
  const baseStyle = { marginBottom: 5 }
  return (
    <Grid
      xs={xs}
      lg={lg}
      item
      direction={direction || 'column'}
      container
      spacing={spacing}
      style={style || baseStyle}
      justify='space-between'
    >
      {labelDetails && !labelDetails.dontRender ? getLabel() : null}
      {getField(inline)}
    </Grid>
  )

  function getLabel () {
    const { labelXs } = labelDetails
    // TODO add label Lg
    return (
      <Grid
        item
        xs={labelXs || xs || 12}
        style={{ marginTop: labelDetails.marginTop || 25 }}
      >
        <div className={fieldDetails.classes.FieldTitleContainer}>
          {labelDetails.bold ? <b>{fieldDetails.trans}</b> : fieldDetails.trans}
          {labelDetails.tooltip && (
            <TooltipLabelIcon
              className={fieldDetails.classes.button}
              tooltip={labelDetails.tooltip}
            />
          )}
        </div>
      </Grid>
    )
  }

  function getField (stretch = false) {
    const { fullWidth = true, fieldXs, fieldLg } = fieldDetails
    return (
      <Grid item xs={fieldXs || xs || 12} lg={stretch ? null : fieldLg || lg}>
        {fieldDetails.checkBoxes ? (
          <FormikCheckboxGroupField
            name={fieldDetails.name}
            margin='normal'
            variant='outlined'
            onChange={fieldDetails.handleChange}
            onBlur={fieldDetails.handleBlur}
            options={fieldDetails.checkBoxes}
            fullWidth={fullWidth}
            disabled={fieldDetails.disabled}
            multiple={!fieldDetails.onlyOne}
            row={fieldDetails.isRow}
            style={{ marginLeft: 10, marginTop: 10 }}
          />
        ) : fieldDetails.selectFieldOptions ? (
          <FormikSelectField
            name={fieldDetails.name}
            label={labelDetails.label}
            helperText={fieldDetails.helperText}
            margin='normal'
            variant='outlined'
            onChange={fieldDetails.handleChange}
            options={fieldDetails.selectFieldOptions}
            disabled={fieldDetails.disabled}
            fullWidth={fullWidth}
          />
        ) : fieldDetails.htmlFieldOptions ? (
          <FormikCKEditor
            disabled={fieldDetails.disabled}
            name={fieldDetails.name}
            label={labelDetails.label}
            maxCharacters={fieldDetails.maxLength}
            {...fieldDetails.htmlFieldOptions}
          />
        ) : (
          <FormikTextField
            defaultValue=''
            name={fieldDetails.name}
            id={fieldDetails.name}
            value={fieldDetails.value}
            onChange={fieldDetails.handleChange}
            label={labelDetails ? labelDetails.label : fieldDetails.label}
            margin='normal'
            variant='outlined'
            // TODO replace with ...fieldsDetails
            helperText={fieldDetails.helperText}
            inputProps={fieldDetails.nativeInputProps}
            InputProps={
              fieldDetails.customFormat
                ? Object.assign({}, fieldDetails.inputProps, {
                  inputComponent: CurrencyFormat
                })
                : fieldDetails.numericFormat
                  ? Object.assign({}, fieldDetails.inputProps, {
                    inputComponent: NumericFormat
                  })
                  : fieldDetails.inputProps
            }
            fullWidth={fullWidth}
            multiline={fieldDetails.multiline}
            rows={fieldDetails.rows}
            disabled={fieldDetails.disabled}
            type={fieldDetails.type}
            onBlur={fieldDetails.onBlur}
            onFocus={fieldDetails.onFocus}
          />
        )}
      </Grid>
    )
  }
}

export const countHelperText = (value, limit, required = false) => {
  let curr = 0
  if (value) {
    curr = value.length
  }
  return (
    <span>
      {required && (
        <>
          <Trans>Required</Trans>.{' '}
        </>
      )}
      {curr + ' '}
      <Trans>chars</Trans>
      {' ('}
      <Trans>max</Trans>: {limit} <Trans>chars</Trans>
      )
    </span>
  )
}

export function NumericFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
      isNumericString={false}
    />
  )
}

export function ZipCodeFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      format='### ###'
      mask='_'
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      allowNegative={false}
      isNumericString={false}
    />
  )
}

export function PhoneFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      format='(+1) ###-###-####'
      mask='_'
      isNumericString
      type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export function PercentageFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      isNumericString
      suffix='%'
      allowLeadingZeros={false}
      getInputRef={inputRef}
      allowNegative={false}
      maxLength={3}
      // onValueChange={values => {
      //   console.log(values)
      //   let toPass = values.floatValue
      //   if (toPass > 100) {
      //     toPass = toPass.substring(0, 2)
      //   }
      //   onChange({
      //     target: {
      //       name: props.name,
      //       value: toPass
      //     }
      //   })
      // }}
    />
  )
}

export function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      prefix='$'
      allowNegative={false}
      isNumericString={false}
    />
  )
}

export default FormElement

import { Trans } from '@lingui/macro'
import { Button, Grid, Icon } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { defaultDocTitle } from 'app/appSettings'
import ReactToPrint from 'react-to-print'
import { FormBackupsPanel } from '../multiuser/components/FormBackupsPanel'
import { downloadOrphanedPDF } from '../pdf-components/formPdfUtils'

export const StepperButtons = ({
  activeStep,
  noStepper,
  handleBack,
  handleSave,
  handleNext,
  generatePDF,
  showPDFButton,
  formTitle,
  saving,
  disableSave,
  muBag,
  hideSaveButton,
  steps,
  printRef,
  elementsMap,
  disablePDFDownload,
  useMultiuser
}) => {
  const downloadPdf = () => {
    document.body.style.cursor = 'wait'
    const pdfDocument = generatePDF({ shouldCalculateFixedElementsInHook: false })
    downloadOrphanedPDF(pdfDocument, formTitle)
  }

  return (
    <Grid
      container
      style={{ marginLeft: 'auto' }}
      wrap='nowrap'
      justifyContent='flex-end'
    >
      {!noStepper && (
        <Button
          variant='contained'
          color='secondary'
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          <Trans>Previous</Trans>
        </Button>
      )}
      {!hideSaveButton && (
        <Button
          className='ml-4'
          variant='outlined'
          color='primary'
          disabled={saving || disableSave}
          onClick={handleSave}
        >
          <Trans>Save</Trans>
          <Save style={{ marginLeft: 5 }} />
        </Button>
      )}
      {printRef && (
        <ReactToPrint
          trigger={() => (
            <Button
              className='ml-4'
              variant='outlined'
              color='primary'
              onClick={handleSave}
            >
              <Trans>Print</Trans>
              <Icon style={{ marginLeft: 5 }}>print</Icon>
            </Button>
          )}
          onAfterPrint={() => (document.title = defaultDocTitle)}
          onBeforePrint={() => (document.title = formTitle)}
          content={() => printRef.current}
        />
      )}
      {/* {pdfDocument && (
        <PDFDownloadLink fileName={formTitle} document={pdfDocument}>
          {({ blob, url, loading, error }) => {
            return (
              <Button
                disabled={loading}
                className="ml-4"
                variant="outlined"
                color="primary"
              >
                <Trans>FORM_DOWNLOAD_PDF</Trans>
                <Icon style={{ marginLeft: 5 }}>download</Icon>
              </Button>
            );
          }}
        </PDFDownloadLink>
      )} */}

      {showPDFButton && (
        <Button
          className='ml-4'
          variant='outlined'
          color='primary'
          onClick={downloadPdf}
          disabled={disablePDFDownload}
        >
          <Trans>FORM_DOWNLOAD_PDF</Trans>
          <Icon style={{ marginLeft: 5 }}>download</Icon>
        </Button>
      )}

      {useMultiuser && (
        <FormBackupsPanel
          elementsMap={elementsMap}
          saving={saving}
          muBag={muBag}
        />
      )}
      {!noStepper && (
        <Button
          className='ml-4'
          variant='contained'
          color='primary'
          disabled={activeStep === steps.length - 1}
          onClick={handleNext}
        >
          <Trans>Next</Trans>
        </Button>
      )}
    </Grid>
  )
}

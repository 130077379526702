import { getMainConnected, getSFObjectFieldValue } from './Form'
import { formComponentTypes } from './components/formComponentTypes'
import { formElementsWithoutInput } from './editor/GroupElement'
import { baseMuInfo } from './multiuser/FormMultiuser'
import { purgeInvalidPDFSignFromString } from './pdf-components/formPdfUtils'

export const getInitialValues = ({ data, files, ...props }) => {
  const toRet = { muInfo: { ...baseMuInfo }, other: {} }
  const errors = {}
  if (!data?.sections) {
    return toRet
  }
  data?.sections.forEach(section => {
    section.elements.forEach(element => {
      try {
        initialValuesForElement({
          item: element,
          initObj: toRet,
          files,
          formType: data.formType,
          ...props
        })
      } catch (e) {
        errors[element.id] = {
          message: e.message,
          type: element.elementType
        }
      }
    })
  })
  if (Object.keys(errors).length > 0) {
    return { errors }
  }
  return toRet
}

const initialValuesForElement = ({
  item,
  formType,
  initObj,
  connectedMap = {},
  contactsMap = {},
  accountsMap = {},
  enqueueSnackbar,
  ...props
}) => {
  formType = formType || ''
  if (item.elements) {
    item.elements.forEach(element => {
      initialValuesForElement({
        item: element,
        formType,
        initObj,
        connectedMap,
        contactsMap,
        accountsMap,
        enqueueSnackbar,
        ...props
      })
    })
  } else {
    const type = item.elementType
    if (type && !formElementsWithoutInput.includes(type)) {
      const { typeProps = {} } = item
      const {
        options = [],
        picklistType,
        displayAllFiles,
        connectedTo = [],
        validTypes = []
      } = typeProps
      let sfObject = {}
      let additionalSFInfo

      if (formComponentTypes[type].connectsToMultipleObjects) {
        const objectsMap = {}
        connectedTo.forEach(connection => {
          const { forceType, connectedObject } = connection
          objectsMap[forceType] = connectedMap[connectedObject]?.sfObject
        })
        if (formComponentTypes[type].defaultValue) {
          initObj[item.id] = formComponentTypes[type].defaultValue(
            objectsMap,
            {
              ...additionalSFInfo,
              contactsMap,
              accountsMap,
              enqueueSnackbar,
              initObj
            },
            item
          )
        }
        return
      }

      const { connectedObject, connectedField } = getMainConnected(item)

      const connectedObjects = connectedTo.map(connection => connection.connectedObject)

      if (connectedObject && connectedMap[connectedObject]) {
        sfObject = connectedMap[connectedObject].sfObject
        const files = connectedMap[connectedObject].files
        additionalSFInfo = connectedMap[connectedObject].additionalInfo
      }

      if (type === 'uploadFiles') {
        const files = []
        // find all files that exist in each of connected objects
        const firstConnectedObjectFiles = connectedMap?.[connectedObjects?.[0]]?.files || []
        firstConnectedObjectFiles.forEach(file => {
          const fileExistsInAllConnectedObjects = connectedObjects.every(connectedObject => {
            return connectedMap?.[connectedObject]?.files?.some(connectedFile => connectedFile.id === file.id)
          })
          if (fileExistsInAllConnectedObjects) {
            files.push(file)
          }
        })

        initObj[item.id] = displayAllFiles
          ? [...files]
          : [...files.filter(file => {
              if (validTypes.length > 0) {
                if (!file.type) {
                  return false
                }
                /** if there are valid document types, return only files that match the provided types */
                return validTypes.some(type => file.type === type)
              } else {
              /** if there are no valid document types, return only files that have id in their tags */
                return file.tags && file.tags.includes(item.id)
              }
            })
            ]
        return
      }

      if (connectedObject && connectedMap[connectedObject]) {
        sfObject = connectedMap[connectedObject].sfObject
        const files = connectedMap[connectedObject].files
        additionalSFInfo = connectedMap[connectedObject].additionalInfo
        if (options) {
          options.forEach((option, index) => {
            const { requireDetails, connectedField } = option
            if (requireDetails && connectedField) {
              if (picklistType === 'multiselect') {
                if (!initObj.other[item.id]) {
                  initObj.other[item.id] = []
                }
                initObj.other[item.id][index] = sfObject[connectedField.name]
              } else {
                initObj.other[item.id] = sfObject[connectedField.name]
              }
            }
          })
        }
        let sfValue = getSFObjectFieldValue(sfObject, connectedField)
        if (formType.includes('pdf') && typeof sfValue === 'string') {
          sfValue = purgeInvalidPDFSignFromString(sfValue)
        }
        if (connectedField && (sfValue || sfValue === 0)) {
          if (type === 'picklist') {
            const validOptions = options.map(opt => opt.apiValue)
            const normalizedValidOptions = validOptions.map(option =>
              option.replace(/\s/g, '')
            )

            if (picklistType === 'multiselect' || sfValue.includes(';')) {
              initObj[item.id] = sfValue
                .split(';')
                .filter(option => {
                  const isValid =
                    item.typeProps.dynamic ||
                    validOptions.includes(option) ||
                    normalizedValidOptions.includes(option.replace(/\s/g, ''))
                  return isValid
                })
                .map(string => String(string).replace(/\s/g, ' ').trim())
            } else {
              initObj[item.id] =
                item.typeProps.dynamic || validOptions.includes(sfValue)
                  ? sfValue.replace(/\s/g, ' ')
                  : ''
            }
            return
          } else if (
            typeof formComponentTypes[type].defaultValue === 'function'
          ) {
            initObj[item.id] = formComponentTypes[type].defaultValue(
              sfObject,
              {
                ...additionalSFInfo,
                contactsMap,
                accountsMap,
                initObj,
                enqueueSnackbar,
                connectedMap
              },
              item
            )
            return
          } else {
            initObj[item.id] = sfValue
            return
          }
        }
      }
      if (type === 'picklist') {
        initObj[item.id] = picklistType === 'multiselect' ? [] : ''
        return
      }
      if (!formComponentTypes[type]) {
        return
      }
      if (typeof formComponentTypes[type].defaultValue === 'function') {
        initObj[item.id] = formComponentTypes[type].defaultValue(
          sfObject,
          {
            ...additionalSFInfo,
            contactsMap,
            accountsMap,
            initObj,
            enqueueSnackbar,
            connectedMap
          },
          item
        )
      } else {
        initObj[item.id] = formComponentTypes[type].defaultValue
      }
    }
  }
}

import { Trans } from '@lingui/macro'
import { dateFormat } from 'app/appSettings'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { Field } from 'formik'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { languages } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { getMainConnected, getSFObjectFieldValue } from '../../Form'
import MUDatePicker from '../../multiuser/components/MUDatePicker'

/**
 * @typedef FormElementReference
 * @type {string}
 */

/**
 * Form element which renders a Material UI date picker.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.clearable=false] If date picker should render a clear button inside.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {Date} [typeProps.minDate] Minimum date that can be set in the input.
 * @param  {Date}  [typeProps.maxDate] Maximum date that can be set in the input.
 * @param  {FormElementReference} [typeProps.minDateElement] Another form element which value will be used as minimum date that can be set in the input.
 * @param  {FormElementReference}  [typeProps.maxDateElement] Another form element which value will be used as maximum date that can be set in the input.
 */

const getElementTitle = (sections, id, langVersion) => {
  if (!sections) return ''
  for (const section of sections) {
    if (!section?.elements) continue
    for (const element of section.elements) {
      if (element.id == id) {
        return getLabelFromTranslationData({
          data: element.title,
          langVersion
        })
      }
    }
  }
  return ''
}

const shouldDatePickerBeDisable = (values, typeProps) => {
  const { maxDateElement, minDateElement } = typeProps

  return (
    (maxDateElement &&
      values.hasOwnProperty(maxDateElement) &&
      values[maxDateElement] === null) ||
    (minDateElement &&
      values.hasOwnProperty(minDateElement) &&
      values[minDateElement] === null)
  )
}

const getMinMaxDates = ({ typeProps, values, data }) => {
  let {
    minDate,
    maxDate,
    minDateElement,
    maxDateElement,
    startDateDaysAfterCurrentDate
  } = typeProps || {}
  const sections = data.sections

  let maxMessage
  let minMessage

  if (
    startDateDaysAfterCurrentDate &&
    Number(startDateDaysAfterCurrentDate) < 0
  ) {
    startDateDaysAfterCurrentDate = null
  }

  const dynamicMinDate = values?.[minDateElement]
    ? moment.utc(values[minDateElement])
    : null
  const dynamicMaxDate = values?.[maxDateElement]
    ? moment.utc(values[maxDateElement])
    : null

  let minDateToUse = dynamicMinDate || (minDate ? moment.utc(minDate) : null)
  const maxDateToUse = dynamicMaxDate || (maxDate ? moment.utc(maxDate) : null)

  if (minDateToUse) {
    let date
    if (dynamicMinDate) {
      date = getElementTitle(sections, minDateElement, data.langVersion)
    } else {
      date = minDateToUse.format(dateFormat)
    }
    minMessage = <Trans>FORM_DATE_PICKER_DATE_ON_OR_AFTER_ERROR {date}</Trans>
  }

  if (maxDateToUse) {
    let date
    if (dynamicMaxDate) {
      date = getElementTitle(sections, maxDateElement, data.langVersion)
    } else {
      date = maxDateToUse.format(dateFormat)
    }
    maxMessage = <Trans>FORM_DATE_PICKER_DATE_ON_OR_BEFORE_ERROR {date}</Trans>
  }

  const startDateOffsetDate =
    startDateDaysAfterCurrentDate && startDateDaysAfterCurrentDate >= 0
      ? moment.utc().add(startDateDaysAfterCurrentDate, 'days')
      : null

  if (startDateOffsetDate) {
    const formattedStartDateOffsetDate = startDateOffsetDate.format(dateFormat)
    const formattedMinDate = minDateToUse
      ? minDateToUse.utc().format(dateFormat)
      : null

    if (!minDateToUse || formattedStartDateOffsetDate > formattedMinDate) {
      minDateToUse = formattedStartDateOffsetDate
      minMessage = (
        <Trans>
          FORM_DATE_PICKER {startDateDaysAfterCurrentDate}{' '}
          DAYS_AFTER_CURRENT_DATE_ERROR
        </Trans>
      )
    }
  }

  return {
    minDate: minDateToUse,
    maxDate: maxDateToUse,
    minMessage,
    maxMessage
  }
}

const disabledDatePickerHelperText = (
  <Trans>To edit this field, please complete the required fields first.</Trans>
)

export const FormDatePicker = ({
  typeProps,
  title,
  id,
  useMultiuser,
  muBag,
  disabled,
  formikRef
}) => {
  const tree = useSelector((state) => state.formEditorTree)
  return (
    <Field name={id}>
      {({ form: { setFieldValue, setFieldTouched, values }, field, meta }) => {
        const { showFieldLabel, clearable, required } = typeProps

        const { minDate, maxDate, minMessage, maxMessage } = getMinMaxDates({
          typeProps,
          values,
          data: tree
        })

        const disableIfDateElementNull = shouldDatePickerBeDisable(
          values,
          typeProps
        )

        return (
          <MUDatePicker
            id={id}
            muBag={muBag}
            useMultiuser={useMultiuser}
            label={showFieldLabel && title}
            cancelLabel={<Trans>Cancel</Trans>}
            okLabel={<Trans>Ok</Trans>}
            format={dateFormat}
            disabled={disabled || disableIfDateElementNull}
            className='text-muted'
            inputVariant='outlined'
            fullWidth
            clearable={Boolean(clearable)}
            minDate={minDate}
            maxDate={maxDate}
            formikRef={formikRef}
            helperText={
              <>
                {required && <Trans>Required</Trans>}
                {required && disableIfDateElementNull && '. '}
                {disableIfDateElementNull
                  ? (
                      disabledDatePickerHelperText
                    )
                  : (
                    <>
                      {minMessage && <div>{minMessage}</div>}
                      {maxMessage && <div>{maxMessage}</div>}
                    </>
                    )}
              </>
            }
          />
        )
      }}
    </Field>
  )
}

Yup.addMethod(
  Yup.date,
  'checkMinDateMaxDate',
  function (item, data, formikRef) {
    return this.test('checkMinDateMaxDate', function (selectedDate) {
      const values = formikRef?.current?.values
      const formattedSelectedDate = values[item.id]
      const { path, createError } = this

      if (!selectedDate) {
        return true
      }
      const { minDate, maxDate, minMessage, maxMessage } = getMinMaxDates({
        typeProps: item?.typeProps,
        values,
        data
      })

      const disableIfDateElementNull = shouldDatePickerBeDisable(
        values,
        item?.typeProps
      )

      if (disableIfDateElementNull && selectedDate) {
        return createError({
          path,
          message: disabledDatePickerHelperText
        })
      }

      const formattedMinDate = minDate
        ? moment.utc(minDate).format(dateFormat)
        : null
      const formattedMaxDate = maxDate
        ? moment.utc(maxDate).format(dateFormat)
        : null

      if (
        formattedMinDate &&
        formattedMaxDate &&
        formattedMinDate > formattedMaxDate
      ) {
        return createError({
          path,
          message: (
            <>
              <Trans>FORM_DATE_PICKER_INVALID_MIN_MAX_ERROR</Trans>
              <div>{minMessage}</div>
              <div>{maxMessage}</div>
            </>
          )
        })
      }

      if (minDate && formattedSelectedDate < formattedMinDate) {
        return createError({
          path,
          message: minMessage
        })
      }

      if (formattedMaxDate && formattedSelectedDate > formattedMaxDate) {
        return createError({
          path,
          message: maxMessage
        })
      }

      return true
    })
  }
)

export const FormDatePickerValidation = (item, data, formikRef) => {
  const { required } = item.typeProps
  let validationSchema = Yup.date()
    .nullable()
    .checkMinDateMaxDate(item, data, formikRef)

  if (required) {
    validationSchema = validationSchema.required(<Trans>Required</Trans>)
  }

  return validationSchema
}

export const formDatePickerDefaultValue = (obj, info, item) => {
  if (!obj) {
    return null
  }
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField) {
    const sfValue = getSFObjectFieldValue(obj, connectedField)
    return sfValue || null
  } else {
    return null
  }
}

export const formDatePickerValueToText = (value) => {
  const toDate = moment(value)
  if (toDate.isValid()) {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = toDate.format(dateFormat)
      return acc
    }, {})
    return dateObj
  } else {
    const dateObj = languages.reduce((acc, lang) => {
      acc[lang] = value || ''
      return acc
    }, {})
    return dateObj
  }
}

/* global mainScript */
import React from 'react'
import PropTypes from 'prop-types'
// comps
import SidePanelDropDown from './SidePanelDropDown'
// style rules
import './styles/FiltersSidePanel.css'

const FiltersSidePanel = ({
  toggleSidePanel,
  filters,
  handleParentFilterSelect,
  selectedFilters,
  handleChildFilterSelect,
  clearAll,
  ApplyFilters,
  FiltersType
}) => {
  const isDesktopOrLaptop = window.innerWidth >= 1024

  const renderedDropdowns =
		filters &&
		filters.length > 0 &&
		filters.map((filter) => (
  <li
    className='animatedListItem'
    key={filter.slug}
  >
    <SidePanelDropDown
      topLevelCategory={filter}
      handleParentFilterSelect={handleParentFilterSelect}
      selectedFilters={selectedFilters}
      handleChildFilterSelect={handleChildFilterSelect}
    />
  </li>
		))
  return (
    <>
      <div
        className='overlay'
        initial='closed'
        animate='opened'
        exit='closed'
        onClick={toggleSidePanel}
      />
      <div
        className='aside'
        initial={{ width: 0 }}
        animate={{ width: isDesktopOrLaptop ? 524 : '100vw' }}
        exit={{
				  width: 0
        }}
      >
        <div className='header'>
          <h4 className='title'>Filter {FiltersType} by</h4>
          <svg
            className='closeIcon'
            icon={mainScript.assetPath + 'images/svg/icons/close.svg'}
            onClick={toggleSidePanel}
          />
        </div>
        <div
          className='dropDownListContainer'
          initial='closed'
          animate='open'
          exit='closed'
        >
          {renderedDropdowns}
        </div>
        <div className='buttonsContainer'>
          <button className='applyFiltersButton' disabled={selectedFilters.length === 0} onClick={ApplyFilters}>Apply filters</button>
          <a className='clearFilter' onClick={clearAll}>clear</a>
        </div>
      </div>
    </>
  )
}

export default FiltersSidePanel

FiltersSidePanel.propTypes = {
  toggleSidePanel: PropTypes.func,
  filters: PropTypes.array,
  handleParentFilterSelect: PropTypes.func,
  selectedFilters: PropTypes.array,
  handleChildFilterSelect: PropTypes.func,
  clearAll: PropTypes.func,
  ApplyFilters: PropTypes.func,
  FiltersType: PropTypes.string
}

import { CommonPlaceholder } from '../CommonPlaceholder'

export function FormTextInputPdfEditor ({ typeProps, mainConnected, ...props }) {
  return (
    <CommonPlaceholder
      mainConnected={mainConnected}
      showPdfComponent
      typeProps={typeProps}
      {...props}
    />
  )
}

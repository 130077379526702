import { Trans } from '@lingui/macro'
import { CheckBox } from '@material-ui/icons'
import { Text } from '@react-pdf/renderer'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from '../../pdf-components/FormPdf'

export const FormUploadFilesPdfEditor = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  condtionsText,
  ...props
}) => {
  const { minFiles, required } = typeProps
  const requiredFilesNumber = minFiles || required ? 1 : 0

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 4
        }}
      >
        <CheckBox />
        <p
          style={{
            fontFamily:
              langVersion === 'iu-cans'
                ? inuktitutFormPdfFontFamily
                : defaultFormPdfFontFamily,
            fontSize: 14,
            marginLeft: 8,
            marginTop: 0,
            marginBottom: 4
          }}
        >
          {requiredFilesNumber
            ? (
              <>
                (
                <Trans>
                  FORM_UPLOAD_FILES_PDF_EDITOR_REQUIRED_FILES_NUMBER_PLACEHOLDER
                </Trans>
                ) {' '}
              </>
              )
            : (
                ''
              )}
          {tooltip
            ? (
              <>
                [<Trans>FORM_UPLOAD_FILES_PDF_EDITOR_TOOLTIP_PLACEHOLDER</Trans>]{' '}
                {' '}
              </>
              )
            : (
                ''
              )}
          {title
            ? (
              <>
                <Trans>FORM_UPLOAD_FILES_PDF_EDITOR_TITLE_PLACEHOLDER</Trans>{' '}
                {' '}
              </>
              )
            : (
                ''
              )}
          {required
            ? (
              <>
                <Trans>FORM_UPLOAD_FILES_PDF_EDITOR_REQUIRED_PLACEHOLDER</Trans>{' '}
                {' '}
              </>
              )
            : (
                ''
              )}
          {condtionsText
            ? (
              <Trans>
                FORM_UPLOAD_FILES_PDF_EDITOR_CONDITION_TEXT_PLACEHOLDER
              </Trans>
              )
            : (
                ''
              )}
        </p>
      </div>
      {helpText && (
        <div style={{ marginTop: 4 }}>
          <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
            <Trans>FORM_UPLOAD_FILES_PDF_EDITOR_HELPTEXT_PLACEHOLDER</Trans>
          </Text>
        </div>
      )}
    </>
  )
}

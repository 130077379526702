import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  Typography
} from '@material-ui/core'
import { DialogTitleWithIconClose } from 'app/views/common-components/DialogTitleWithIconClose'
import { FormErrorsList } from 'app/views/forms/common/FormErrorsList'
import { useFormContext } from 'app/views/forms/form-page/FormContext'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useState } from 'react'
import { useHistory } from 'react-router'
import ReactToPrint from 'react-to-print'
import { opportunitiesStages } from '../../../../../services/sfAuth/sfData/sfOpportunity'

/**
 * Form element which renders a submit button that is starting the submission process of the object connected to the form.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.showPrintPromptAfterSubmit=false] If, after submission, the button show the pop up message pompting the user to print the submitted form.
 */
export const FormSubmitButton = ({
  id,
  value,
  configuration,
  handleSubmit,
  saving,
  disabled,
  saveDisabled,
  typeProps,
  errors,
  objectsFieldsMap,
  connectedMap,
  navigateToError,
  printRef,
  pdfDocument,
  langVersion,
  describeMap,
  ...props
}) => {
  const [downloading, setDownloading] = useState(false)

  const { generatePDF, formTitle } = useFormContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { values } = useFormikContext()
  const history = useHistory()
  const { showPrintPromptAfterSubmit } = typeProps
  const errorsPresent = Object.keys(errors).length > 0
  let disableButton = errorsPresent || saving || disabled || saveDisabled
  if (configuration && configuration.endTime) {
    console.log('submit button', value, props)
    if (
      moment.utc().isAfter(moment(configuration.endTime).utc()) &&
      !configuration.inTesting
    ) {
      disableButton = true
      for (const connected in connectedMap) {
        const connObj = connectedMap[connected].sfObject
        if (connObj.StageName === opportunitiesStages.MORE_INFO_REQUIERED) {
          disableButton = false
        }
      }
    }
  }

  return (
    <div>
      {/* TODO remove disabled when the field is configured to sav */}
      {errorsPresent && !disabled && (
        <FormErrorsList
          helperText={
            <>
              <Trans>You need to fix these error before You can submit</Trans>:
            </>
          }
          navigateToError={navigateToError}
          errors={errors}
          objectsFieldsMap={objectsFieldsMap}
          connectedMap={connectedMap}
        />
      )}
      <Dialog open={dialogOpen} maxWidth='sm' fullWidth>
        <DialogTitleWithIconClose
          label={<Trans>APPLICATION_SUBMITTED_DIALOG_TITLE</Trans>}
          handleClose={() => {
            setDialogOpen(false)
          }}
        />

        <DialogContent>
          <Typography style={{ paddingBottom: 20 }}>
            <Trans>APPLICATION_SUBMITTED_DIALOG_TEXT</Trans>
          </Typography>
          <Grid
            container
            wrap='nowrap'
            style={{ padding: 10 }}
            justifyContent='space-between'
            spacing={2}
          >
            {/* <Grid item>
              <Button
                color='primary'
                variant='contained'
                disabled={downloading}
                onClick={e => {
                  setDownloading(true)
                  document.body.style.cursor = 'wait'
                  const pdfDocument = generatePDF()
                  downloadOrphanedPDF(pdfDocument, formTitle).then(result => {
                    setDownloading(false)
                  })
                }}
              >
                <Icon style={{ marginRight: 5 }}>download</Icon>
                <Trans>APPLICATION_SUBMITTED_DIALOG_SHOW_PRINT_BUTTON</Trans>
              </Button>
            </Grid> */}

            <Grid item>
              <ReactToPrint
                trigger={() => (
                  <Button variant='contained' color='primary'>
                    <Icon style={{ marginRight: 5 }}>print</Icon>
                    <Trans>
                      APPLICATION_SUBMITTED_DIALOG_SHOW_PRINT_BUTTON
                    </Trans>
                  </Button>
                )}
                content={() => printRef.current}
              />
            </Grid>

            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={(e) => {
                  history.push('/grants/ApplicationsList')
                }}
              >
                <Icon style={{ marginRight: 5 }}>view_list</Icon>
                <Trans>
                  APPLICATION_SUBMITTED_DIALOG_SHOW_RETURN_TO_APPLICATIONS_LIST_BUTTON
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => {
          const pdfDocument = generatePDF()
          handleSubmit({ values, pdfDocument }).then((result) => {
            if (showPrintPromptAfterSubmit) {
              setDialogOpen(true)
            } else {
              history.push('/grants/ApplicationsList')
            }
          })
        }}
        disabled={disableButton}
        color='primary'
        variant='contained'
        fullWidth
      >
        <Trans>Submit</Trans>
      </Button>
    </div>
  )
}

import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { formViewTypes } from './FormWizard'

export function FormEditorElementHeaderBar ({
  dragRef,
  isDragging,
  index,
  sectionLength,
  selected,
  selectedParent,
  injectable,
  injectableName,
  depth,
  editMode,
  targetFormType,
  currentlyUsedForms,
  selectedTargetFormType,
  position, // element position in the form
  ...props
}) {
  const dispatch = useDispatch()

  if (!Array.isArray(targetFormType)) {
    targetFormType = [targetFormType]
  }

  return (
    <>
      <Grid
        container
        direction='row'
        justify='space-between'
        wrap='nowrap'
      >
        <Grid
          item
          container
          xs
          wrap='nowrap'
          style={{ paddingTop: 10, paddingLeft: 10 }}
          alignItems='center'
        >
          {dragRef && !injectable && (
            <div ref={dragRef || null}>
              <Icon
                style={{
                  display: 'inline-block',
                  opacity: isDragging && 0,
                  cursor: 'move',
                  paddingTop: 8
                }}
              >
                drag_indicator
              </Icon>
            </div>
          )}
          <div>{position}</div>
          {!injectable && (
            <Checkbox
              disabled={selectedParent}
              checked={Boolean(selected || selectedParent)}
              onChange={e => {
                dispatch({
                  type: 'SELECT_ITEM',
                  depth,
                  select: e.target.checked
                })
              }}
            />
          )}
          {injectable && depth.length === 1 && (
            <>
              {currentlyUsedForms && (
                <Tooltip
                  title={
                    <>
                      <Trans>REUSABLE_COMPONENT_WHERE_IS_USED_INFO_TOOLTIP</Trans>
                      :
                      {currentlyUsedForms &&
                      currentlyUsedForms.map(formName => (
                        <div key={formName}>{formName}</div>
                      ))}
                    </>
                }
                >
                  <Icon style={{ marginRight: 5 }}>info</Icon>
                </Tooltip>
              )}
              <TextField
                label={<Trans>Name</Trans>}
                fullWidth
                variant='outlined'
                value={injectableName}
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'injectableName',
                    fieldValue: e.currentTarget.value
                  })
                }}
              />
            </>
          )}
        </Grid>

        <Grid xs container direction='row' justify='flex-end' item wrap='nowrap'>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(editMode)}
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'editMode',
                    fieldValue: e.target.checked
                  })
                }}
              />
          }
            label={editMode ? <Trans>Edit</Trans> : <Trans>View</Trans>}
          />
          <IconButton
            onClick={() => {
              if (props.elements) {
                dispatch({
                  object: {
                    columns: props.columns,
                    elements: props.elements,
                    titleEN: props.titleEN,
                    titleFR: props.titleFR,
                    editMode: props.editMode,
                    padding: props.padding,
                    bold: props.bold,
                    headerFontSize: props.headerFontSize,
                    headerStyle: props.headerStyle,
                    italics: props.italics,
                    itemsSpacing: props.itemsSpacing,
                    labelsWidth: props.labelsWidth,
                    style: props.style,
                    conditions:
                    props.conditionsToPass && JSON.parse(props.conditionsToPass)
                  },
                  type: 'COPY'
                })
              } else {
                dispatch({
                  object: {
                    titleEN: props.titleEN,
                    titleFR: props.titleFR,
                    editMode: props.editMode,
                    showBasicElements: props.showBasicElements,
                    elementType: props.elementType,
                    typeProps: props.typeProps,
                    tooltipEN: props.tooltipEN,
                    tooltipFR: props.tooltipFR,
                    altLabelPlacement: props.altLabelPlacement,
                    labelAsMarkdown: props.labelAsMarkdown,
                    padding: props.padding,
                    conditions:
                    props.conditionsToPass && JSON.parse(props.conditionsToPass)
                  },
                  type: 'COPY'
                })
              }
            }}
          >
            <Icon>content_copy</Icon>
          </IconButton>
          {(depth.length === 2 || injectable) && (
            <>
              <IconButton
                size='small'
                onClick={e => {
                  dispatch({
                    type: 'MOVE_GROUP_DOWN',
                    injectable,
                    depth
                  })
                }}
                disabled={index === sectionLength - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  dispatch({
                    type: 'MOVE_GROUP_UP',
                    injectable,
                    depth
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
            </>
          )}
          <IconButton
            onClick={() => {
              dispatch({
                depth,
                injectable,
                type: 'REMOVE_ITEM'
              })
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      </Grid>
      {injectable && depth.length === 1 && (
        <>
          <div style={{ padding: 20, paddingBottom: 5 }}>
            <FormLabel component='legend'>
              <Trans>Target form type</Trans>
            </FormLabel>
            <FormGroup row>
              {formViewTypes.map((obj, index) => {
                let checked
                if (Array.isArray(targetFormType)) {
                  checked = targetFormType.includes(obj.value)
                } else {
                  checked = targetFormType
                }

                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={e => {
                          let toSet = [...targetFormType]
                          if (checked) {
                            if (toSet.length > 1) {
                              toSet = toSet.filter(v => v !== obj.value)
                            }
                          } else {
                            toSet.push(obj.value)
                          }
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth,
                            fieldName: 'targetFormType',
                            fieldValue: toSet
                          })
                        }}
                      />
                }
                    label={obj.label}
                  />
                )
              })}
            </FormGroup>
          </div>
          <div style={{ padding: 15, paddingBottom: 0 }}>
            <TextField
              select
              label={<Trans>FORM_TYPE_PREVIEW</Trans>}
              variant='outlined'
              value={selectedTargetFormType || 'editable'}
              style={{ width: 200 }}
              onChange={e => {
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth,
                  fieldName: 'selectedTargetFormType',
                  fieldValue: e.target.value
                })
              }}
            >
              {formViewTypes.map(obj => {
                return (
                  <MenuItem value={obj.value} key={obj.value}>
                    {obj.label}
                  </MenuItem>
                )
              })}
            </TextField>
          </div>
        </>
      )}
    </>
  )
}

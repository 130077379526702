import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, Input } from '@material-ui/core'
import './styles/Search.css'

const Search = ({
  setSearchQuery,
  searchQuery,
  searchInput,
  searchPlaceholder = '',
  setSearchInput,
  setUrlState,
  baseUrl,
  setUrlStateSearch,
  urlStateFilters,
  FiltersType
}) => {
  const ref = useRef(null)

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setSearchQuery(searchInput)
      if (FiltersType === 'news' || FiltersType === 'projects') {
        setUrlStateSearch('q=' + searchInput)
      }
    }
  }

  const handleClick = () => {
    setSearchQuery(searchInput)
    if (FiltersType === 'news' || FiltersType === 'projects') {
      setUrlStateSearch('q=' + searchInput)
    }
    console.log(ref.current)
    ref.current.querySelector('input').focus()
  }

  useEffect(() => {
    if (FiltersType === 'news' || FiltersType === 'projects') {
      if (searchQuery.length > 0) {
        // conditional to check if there were any filters present
        if (urlStateFilters.length > 0) {
          setUrlState(`${baseUrl}${urlStateFilters}&${searchQuery}`)
        } else {
          setUrlState(`${baseUrl}&${searchQuery}`)
        }
      }
    }
  }, [searchQuery])

  return (
    <div className='search inputContainer'>
      <Input
        className='search-input'
        onKeyDown={handleKeyDown}
        onChange={e => setSearchInput(e.target.value)}
        value={searchInput}
        type='text'
        ref={ref}
      />
      <div onClick={handleClick} className='searchIcon' style={{ display: 'flex', alignItems: ' ' }}>
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
          <path d='M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z' fill='black' fill-opacity='0.54' />
        </svg>
      </div>
    </div>
  )
}

export default Search

Search.propTypes = {
  setSearchQuery: PropTypes.func,
  setSearchInput: PropTypes.func,
  searchInput: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  baseUrl: PropTypes.string,
  searchQuery: PropTypes.string,
  urlState: PropTypes.string,
  urlStateFilters: PropTypes.string,
  setUrlState: PropTypes.func,
  setUrlStateSearch: PropTypes.func,
  FiltersType: PropTypes.string
}

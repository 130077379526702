import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import MUAvatar from './MUAvatar'

/**
 * Calculate the number of avatars that can fit in one row of panel and the number of rows needed to display all the avatars.
 * @function
 * @param {number} panelWidth - Width of the panel with avatars.
 * @param {Object} users - Object with users. The key is the user id and the value is the user object.
 * @returns {Object} The object containing the max number of avatars that can fit in one row and the number of rows needed to display all the avatars in the panel.
 */

const calcAvatarPanelParams = ({ panelWidth, users }) => {
  const avatarWidth = 48 // avatar width + margin

  /** Calculate the number of avatars in one row, even if the avatar with the largest text is expanded. */
  /** Do this calculation in a loop increasing the number of avatars in a row until the row width is greater than the panel width */

  /** initial values before loop calculation */
  let maxAvatars = 1
  let maxExpandedRowWidth = 0
  let rows = 1

  while (maxExpandedRowWidth < panelWidth) {
    /** Calculate the number of rows needed to display all the avatars */
    rows = Math.ceil(Object.keys(users).length / maxAvatars)

    /** Calculate the number of avatars that can fit in the last row */
    const avatarsInLastRow = Object.keys(users).length % maxAvatars

    /** Get max expanded width for each avatar */
    /** As the number of avatars in the last row is less than the number of avatars in other rows we need to calculate the max expanded width for last row separately */
    const expandedWidthsExceptLastRow = []
    const expandedWidthsLastRow = []
    Object.keys(users).forEach((id, index) => {
      const name = users[id].name
      const textWidth = name.length * 8 // 8px per character
      const expandedWidth = textWidth + 8 * 2 + 4 * 2 // 8px padding + 4px margin
      /** If the avatar is not in the last row, add its expanded width to the expandedWidthsExceptLastRow array
       * Otherwise, add it to the expandedWidthsLastRow array */
      if (index < maxAvatars * (rows - 1)) {
        expandedWidthsExceptLastRow.push(expandedWidth)
      } else {
        expandedWidthsLastRow.push(expandedWidth)
      }
    })

    /** Get the max width of avatars in all rows except the last one */
    const maxExpandedWidthExceptLastRow =
      expandedWidthsExceptLastRow.length !== 0
        ? Math.max(...expandedWidthsExceptLastRow)
        : 0

    /** Get the max width of avatars in the last row */
    const maxExpandedWidthLastRow =
      expandedWidthsLastRow.length !== 0
        ? Math.max(...expandedWidthsLastRow)
        : 0

    /** Calculate the row width when avatar with max width is extended in all rows except the last one */
    const rowWidthWithMaxExpandedWidthExceptLastRow =
      (maxAvatars - 1) * avatarWidth + maxExpandedWidthExceptLastRow

    /** Calculate the row width when avatar with max width is extended in the last row */
    const rowWidthWithMaxExpandedWidthLastRow =
      (avatarsInLastRow - 1) * avatarWidth + maxExpandedWidthLastRow

    /** Calculate the max row width */
    maxExpandedRowWidth = Math.max(
      rowWidthWithMaxExpandedWidthExceptLastRow,
      rowWidthWithMaxExpandedWidthLastRow
    )

    maxAvatars++
  }

  /** Decrease the number of avatars by 1 to get the max number of avatars that can fit in one row */
  return { maxAvatars: maxAvatars - 1, rows }
}

const UsersEditingInSection = ({ sections, stepperRef, scrollToY, index }) => {
  const user = useSelector(state => state.user)
  const { values } = useFormikContext()

  /** Get width of panel with avatars */
  const panelWidth =
    (stepperRef.current?.offsetWidth - 48 - 16 * sections.length) /
      sections.length || 10 // 48 is padding of stepper and 16 is padding of avatar

  /** Filter users editing current step without current user */
  const editingUsers = {}
  Object.entries(values.muUsers || {}).forEach(([key, value]) => {
    if (value.step === index && key !== user.userId) {
      editingUsers[key] = value
    }
  })

  /** Calculate max avatars per row and rows in panel with avatars */
  const { maxAvatars, rows } = calcAvatarPanelParams({
    panelWidth,
    users: editingUsers
  })

  /** Get array from 1 to rows.length. This array will be used to render panel rows with avatars */
  const rowsArray = [...Array(rows).keys()]

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: panelWidth
      }}
    >
      {maxAvatars !== 0 &&
        rowsArray.map((row, rowIndex) => {
          const rowAvatars = Object.values(editingUsers).slice(
            rowIndex * maxAvatars,
            rowIndex * maxAvatars + maxAvatars
          )
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px'
              }}
              key={rowIndex}
            >
              {rowAvatars.map((value, index) => {
                return (
                  <div key={value.id}>
                    <MUAvatar
                      {...value}
                      handleClick={e => {
                        if (value.coordinates) {
                          const { y, yPercent } = value.coordinates
                          scrollToY(y)
                        }
                      }}
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

export default UsersEditingInSection

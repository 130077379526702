import React, { useEffect } from 'react'
import sfAuthService from '../app/services/sfAuth/SFAuthService'
import { Dialog, DialogContent, Button } from '@material-ui/core'
import { Trans } from '@lingui/macro'

export default function InactivityDetector () {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [redirecting, setRedirecting] = React.useState(false)
  const timeoutInMiliseconds = 3600000
  let timeoutId

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer, false)
    document.addEventListener('mousedown', resetTimer, false)
    document.addEventListener('keypress', resetTimer, false)
    document.addEventListener('wheel', resetTimer, false)
    startTimer()
    return () => {
      document.removeEventListener('mousemove', resetTimer, false)
      document.removeEventListener('mousedown', resetTimer, false)
      document.removeEventListener('keypress', resetTimer, false)
      document.removeEventListener('wheel', resetTimer, false)
    }
  }, [])

  function resetTimer () {
    window.clearTimeout(timeoutId)
    if (!dialogOpen) {
      startTimer()
    }
  }

  function startTimer () {
    timeoutId = window.setTimeout(handleInactivity, timeoutInMiliseconds)
  }

  function handleInactivity () {
    setDialogOpen(true)
    sfAuthService.setUser(null)
    window.clearTimeout(timeoutId)
  }

  function logout () {
    setRedirecting(true)
    sfAuthService.logout().then(
      r => {
        // sfAuthService.doLogin({})
        window.location.reload(true)
      },
      reject => {
        window.location.reload(true)
      }
    )
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <span style={{ fontSize: 15 }}>
          <Trans>
            You have been inactive for 1 hour and automatically logged out
          </Trans>
        </span>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}
        >
          <Button
            disabled={redirecting}
            variant='contained'
            color='primary'
            onClick={logout}
          >
            <Trans>Ok</Trans>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { Trans } from '@lingui/macro'
import {
  Typography
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { dateFormat } from 'app/appSettings'
import { bilingualDateFormat } from 'app/views/common/Formats'
import { FieldArray } from 'formik'
import { useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import './FormMilestoneUpdate.css'

/**
 * Form element which allows adding and configuring project milestone updates.
 * Milestone Updates are saved as Benchmark_Update__c child objects on connected Grantee Report object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 * @param  {Object} connectedMap Map of all connected objects.
 */
export const FormMilestoneUpdatePrint = ({
  id,
  useMultiuser,
  muBag,
  title,
  helpText,
  connectedObject,
  editMode,
  disabled,
  typeProps,
  describeMap,
  langVersion,
  i18n,
  connectedMap,
  ...props
}) => {
  const language = useSelector((state) => state.user.language) || 'en'

  const granteeReportConnectedId = typeProps.connectedTo?.find(
    (item) =>
      connectedMap?.[item?.connectedObject]?.objectType ===
      'FGM_Base__Grantee_Report__c'
  )?.connectedObject

  const lastReportTitle = (
    <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_SUBTITLE</Trans>
  )
  const previousReportTitle = (
    <Trans>FORM_MILESTONE_UPDATE_PREVIOUS_REPORT_SUBTITLE</Trans>
  )

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or main connected object is not
          of "Opportunity" type!
        </Trans>
      </div>
    )
  }

  if (!granteeReportConnectedId && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>FORM_MILESTONE_UPDATE_GRANTEE_REPORT_CONNECTION_ERROR</Trans>
      </div>
    )
  }

  return (
    <div className="'form-update-root">
      <FieldArray
        name={id}
        render={({ push, form, name, remove, meta }) => {
          const milestones = form.values[name] || []

          return (
            <div className='form-update body'>
              <h2 className='main-title'>{title}</h2>

              <Card className='form-update card' elevation={6}>
                <div className='description'>
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_MILESTONE_UPDATE_VIEW_ONLY_TITLE</Trans>
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_MILESTONE_UPDATE_UPDATES_TITLE</Trans>
                    </h3>
                  </Grid>
                </div>
              </Card>

              {milestones.map((item, index) => {
                const areDatesChanged =
                  item.milestoneUpdate?.areDatesChanged || 'no'
                const startDate = !item.startDate
                  ? ''
                  : typeof item.startDate === 'string'
                    ? item.startDate
                    : item.startDate.startOf('day').format(dateFormat)
                const endDate = !item.endDate
                  ? ''
                  : typeof item.endDate === 'string'
                    ? item.endDate
                    : item.endDate.startOf('day').format(dateFormat)
                return (
                  <Card className='form-update card' elevation={6} key={item.id}>
                    <div
                      className={`title-section section ${item.id}`}
                      {...(item.id ? { id: item.id } : {})}
                    >
                      <h2>{myI18n._(item.stage)}</h2>
                    </div>

                    <div className='section'>
                      <div className='updates'>
                        <Grid item container xs={6} direction='row'>
                          <Grid item xs={2} />
                          <Grid
                            item
                            xs={10}
                            container
                            className='milestone-dates'
                          >
                            <div style={{ display: 'flex' }}>
                              <h4 className='subtitle'>
                                <Trans>Start Date</Trans>:
                              </h4>
                              <p>{startDate}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <h4 className='subtitle'>
                                <Trans>End Date</Trans>:
                              </h4>
                              <p>{endDate}</p>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          {index === 0 && (
                            <>
                              <div style={{ display: 'flex' }}>
                                <p className='date-question'>
                                  <Trans>
                                    FORM_MILESTONE_UPDATE_ARE_DATES_CHANGED_SUBTITLE
                                  </Trans>:&nbsp;
                                </p>
                                <div>
                                  {areDatesChanged === 'yes' && <Trans>Yes</Trans>}
                                  {areDatesChanged === 'no' && <Trans>No</Trans>}
                                </div>
                              </div>
                            </>
                          )}
                          {areDatesChanged === 'yes' && (
                            <div>
                              <Typography
                                variant='subtitle2'
                                style={{ marginBottom: 4 }}
                              >
                                <Trans>
                                  FORM_MILESTONE_UPDATE_DATES_UPDATE_PROMPT
                                </Trans>
                              </Typography>
                            </div>
                          )}
                          <Grid
                            container
                            wrap='nowrap'
                            className='date-pickers'
                          >
                            <Grid
                              item
                              xs
                              className={`bordered-date-picker ${
                                areDatesChanged === 'no' ? 'disabled' : ''
                              }`}
                            >
                              <Typography variant='subtitle2'>
                                <Trans>Start date</Trans>
                              </Typography>
                              <div>
                                {item.milestoneUpdate?.phaseStartDate}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs
                              className={`bordered-date-picker ${
                                areDatesChanged === 'no' ? 'disabled' : ''
                              }`}
                            >
                              <Typography variant='subtitle2'>
                                <Trans>End date</Trans>
                              </Typography>
                              <div>
                                {item.milestoneUpdate?.phaseEndDate}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className='section no-border'>
                      <div className='updates' />
                      <div className='questionAnswers'>
                        <Grid item container xs={6} direction='column'>
                          <Grid
                            item
                            container
                            direction='column'
                            style={{ marginBottom: 32 }}
                          >
                            <Grid item>
                              <h4>
                                <Trans>
                                  FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_TITLE
                                </Trans>
                              </h4>
                            </Grid>
                            <Grid item className='outcomes'>
                              {item.primaryActivities}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <div className='answers'>
                              {item.reportMilestoneUpdates.map(
                                (answer, idx) => {
                                  const title =
                                    idx === 0
                                      ? lastReportTitle
                                      : previousReportTitle
                                  const formattedDate = bilingualDateFormat(
                                    answer.submissionDate,
                                    language
                                  )
                                  return (
                                    <Grid direction='column' className='answer' key={idx}>
                                      <h4>
                                        {title}: {formattedDate}
                                      </h4>
                                      <p>
                                        {answer?.primaryActivities}
                                      </p>
                                    </Grid>
                                  )
                                }
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <p className='question'>
                            <Trans>
                              FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_QUESTION
                            </Trans>
                          </p>
                          <p>{item.milestoneUpdate?.primaryActivities}</p>
                        </Grid>
                      </div>
                    </div>

                    <div className='section'>
                      <div className='updates'>
                        <Grid item container xs={6} direction='column'>
                          <Grid item>
                            {/* <h4><Trans>Comments</Trans></h4> */}
                            <h4>
                              <Trans>
                                FORM_MILESTONE_UPDATE_COMMENTS_TITLE
                              </Trans>
                            </h4>
                          </Grid>
                          <Grid item className='outcomes'>
                            {item.comments}
                          </Grid>
                          <Grid item xs={6} />
                        </Grid>
                      </div>
                    </div>

                    <div className='section'>
                      <div className='updates'>
                        <Grid container xs={6} direction='column'>
                          <div style={{ marginBottom: '32px' }}>
                            <h4>
                              <Trans>
                                FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_TITLE
                              </Trans>
                            </h4>
                          </div>
                          <div className='outcomes'>
                            <div className='answers'>
                              {item.reportMilestoneUpdates.map(
                                (answer, idx) => {
                                  const title =
                                    idx === 0
                                      ? lastReportTitle
                                      : previousReportTitle
                                  const bilingualDate = bilingualDateFormat(
                                    answer.submissionDate,
                                    language
                                  )
                                  return (
                                    <Grid direction='column' className='answer' key={idx}>
                                      <h4>
                                        {title}: {bilingualDate}
                                      </h4>
                                      <p>
                                        {answer?.implementedActivities}
                                      </p>
                                    </Grid>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <p className='question'>
                            <Trans>
                              FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_QUESTION
                            </Trans>
                          </p>
                          <p>{item.milestoneUpdate?.implementedActivities}</p>
                        </Grid>
                      </div>
                    </div>
                  </Card>
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}

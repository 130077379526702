import { Trans } from '@lingui/macro'
import { Grid, TextField, Typography } from '@material-ui/core'
import {
  CurrencyFormated,
  bilingualNumberFormat
} from 'app/views/common/Formats'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useLocation } from 'react-router'
import { languages } from 'translation/I18nConnectedProvider'
import { getMainConnected, getSFObjectFieldValue } from '../../Form'
import { isConditionMet } from '../../FormHelpersConditions'
import MUTextField from '../../multiuser/components/MUTextField'

/**
 * Form element which renders a Material UI text input that accepts only numeric input.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {boolean}  [typeProps.currency=false] If input should be formatted as currency.
 * @param  {boolean}  [typeProps.readOnly=false] If field should be render in read only view.
 * @param  {boolean} [typeProps.isPhone=false] If text input should be formatted and validated as phone number.
 * @param  {boolean}  [typeProps.allowPhoneExtension=false] If value of the field shoud be saved separatly from the rest of the form and ask for permission to send saving request.
 * @param  {number}  [typeProps.max] Numeric value that is the upper limit of what can be input into the field.
 * @param  {number} [typeProps.min] Numeric value that is the lower limit of what can be input into the field.
 * @param  {number} [typeProps.showFieldLabel=false] If form element title should be rendered as label inside of text field.
 */
export const FormNumericInput = ({
  id,
  useMultiuser,
  muBag,
  value,
  title,
  preview,
  disabled,
  typeProps,
  connectedMap,
  elementsMap,
  langVersion,
  ...props
}) => {
  const { conditions = [] } = props
  const {
    required,
    currency,
    readOnly,
    isPhone,
    showFieldLabel,
    max,
    min,
    allowPhoneExtension,
    modifyMinSubstract,
    modifyMinAdd,
    modifyMaxSubstract,
    modifyMaxAdd
  } = typeProps
  const { values } = useFormikContext()
  let maxInput = max && Number(max)
  let minInput = min && Number(min)
  const location = useLocation()
  const pathname = location?.pathname

  conditions
    .filter(condition => ['maxMin', 'numMin'].includes(condition.state))
    .forEach(condition => {
      const { conditionMet, state } = isConditionMet({
        condition,
        connectedMap,
        values,
        elementsMap,
        pathname
      })
      if (conditionMet) {
        if (state === 'numMin' && condition.numMin) {
          minInput = Number(condition.numMin)
        } else if (state === 'maxMin' && condition.maxMin) {
          maxInput = Number(condition.maxMin)
        }
      }
    })

  if (minInput) {
    if (modifyMinSubstract) {
      const substractValue = values[modifyMinSubstract] || 0
      minInput -= +substractValue
    }
    if (modifyMinAdd) {
      const addValue = values[modifyMinAdd] || 0
      minInput += +addValue
    }
  }

  if (maxInput) {
    if (modifyMaxSubstract) {
      const substractValue = values[modifyMaxSubstract] || 0
      maxInput -= +substractValue
    }
    if (modifyMaxAdd) {
      const addValue = values[modifyMaxAdd] || 0
      maxInput += +addValue
    }
  }

  if (readOnly) {
    return (
      <Typography>
        {currency ? <CurrencyFormated value={value} /> : value}
      </Typography>
    )
  }

  if (isPhone) {
    return (
      <Grid container wrap='nowrap'>
        <Grid item>
          <MUTextField
            required={required}
            defaultValue='1'
            useMultiuser={useMultiuser}
            muBag={muBag}
            disabled={disabled}
            isCountryCode
            id={'other.' + id}
            type='number'
            variant='outlined'
          />
        </Grid>
        <Grid item xs>
          <MUTextField
            useMultiuser={useMultiuser}
            muBag={muBag}
            disabled={disabled}
            label={showFieldLabel && title}
            id={id}
            fullWidth
            variant='outlined'
            isPhone={isPhone}
            type='number'
            allowPhoneExtension={allowPhoneExtension}
            required={required}
            // helperText={helper}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <MUTextField
      required={required}
      id={id}
      useMultiuser={useMultiuser}
      muBag={muBag}
      disabled={disabled}
      label={showFieldLabel && title}
      fullWidth
      variant='outlined'
      maxInput={maxInput}
      minInput={minInput}
      isPhone={isPhone}
      langVersion={langVersion}
      currency={currency}
      type='number'
    />
  )
}

export const formTextFieldNumericDefaultValue = (obj, additional, item) => {
  const { initObj } = additional
  const { isPhone } = item.typeProps
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField && obj) {
    let sfValue = getSFObjectFieldValue(obj, connectedField)
    const stringV = String(sfValue)
    if (sfValue && stringV.indexOf('+(') !== -1) {
      const countryCode = stringV.substring(2, stringV.indexOf(')'))
      _.set(initObj, 'other.' + item.id, countryCode)
      sfValue = stringV.substring(stringV.indexOf(')'))
    }
    const parsed = String(sfValue).replace(/[^0-9]/g, '')
    if (!parsed) {
      return ''
    }
    return isPhone ? parsed : Number(parsed)
  } else {
    return ''
  }
}

export const formTextFieldNumericValueToText = value => {
  const toRet = languages.reduce((acc, lang) => {
    acc[lang] = value ? bilingualNumberFormat(value, lang) : ''
    return acc
  }, {})
  return toRet
}

export const formTextFieldNumericConditionsStates = {
  numMin: {
    label: <Trans>Set min input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Min input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  },
  maxMin: {
    label: <Trans>Set max input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Max input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

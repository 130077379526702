import { Trans, t } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { portalLanguagesData } from 'app/appSettings'
import { saveFormPage } from 'app/services/sfAuth/sfData/sfForms'
import {
  getSubtemplates,
  getSurveyTemplateDetails,
  saveImportedSurveyData
} from 'app/services/sfAuth/sfData/sfSurvey'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { checkIfnoRecords, getNextAlphabeticalLetter } from 'utils'
import { DialogTitleWithIconClose } from '../common-components/DialogTitleWithIconClose'
import { FileDropzone } from '../common-components/FileDropzone'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import {
  createSurveyTranslationMap,
  surveyDataToSurveyEditor
} from './CreateSurvey'

export const ExportImportSurveysTranslationsPanel = ({ templates = [] }) => {
  const user = useSelector(state => state.user)
  const configuration = useSelector(state => state.configuration)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [uploadedFile, setUploadedFile] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [importedForm, setImportedForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    setSelectedTemplate('')
    setUploadedFile(null)
  }, [dialogOpen])

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>SURVEY_TEMPLATES_EXPORT_IMPORT_TRANSLATIONS_BUTTON</Trans>
      </Button>

      <Dialog open={Boolean(importedForm)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Trans>
            SURVEY_TEMPLATES_EXPORT_IMPORT_TRANSLATIONS_DIALOG_ARE_YOU_SURE_LABEL
          </Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent='space-evenly'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  const savingSnackbar = enqueueSnackbar(null, {
                    persist: true,
                    content: key =>
                      ProgressSnackbar(<Trans>Saving form page</Trans>)
                  })
                  saveFormPage(importedForm).then(
                    result => {
                      setLoading(false)
                      setImportedForm(null)
                      closeSnackbar(savingSnackbar)
                      enqueueSnackbar(<Trans>Form page saved!</Trans>, {
                        variant: 'success'
                      })
                    },
                    reject => {
                      setLoading(false)
                      setImportedForm(null)
                      closeSnackbar(savingSnackbar)
                      enqueueSnackbar(
                        <Trans>Error ocurred while saving form page</Trans>,
                        {
                          variant: 'error'
                        }
                      )
                    }
                  )
                }}
              >
                <Icon style={{ marginRight: 5 }}>done</Icon>
                <Trans>Yes</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  setImportedForm(null)
                  setLoading(false)
                }}
              >
                <Icon style={{ marginRight: 5 }}>close</Icon>
                <Trans>No</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitleWithIconClose
          disabled={loading}
          label={
            <Trans>
              SURVEY_TEMPLATES_EXPORT_IMPORT_TRANSLATIONS_DIALOG_TITLE
            </Trans>
          }
          handleClose={e => {
            setDialogOpen(false)
          }}
        />
        <DialogContent style={{ padding: 20 }}>
          <Autocomplete
            disabled={loading}
            freeSolo={false}
            value={selectedTemplate}
            onChange={(e, value) => {
              setSelectedTemplate(value)
            }}
            style={{ marginTop: 15 }}
            fullWidth
            options={templates}
            getOptionLabel={template => template.name}
            renderInput={params => (
              <TextField
                variant='outlined'
                {...params}
                label={
                  <Trans>
                    SURVEY_TEMPLATES_EXPORT_IMPORT_TRANSLATIONS_TARGET_TEMPLATE
                  </Trans>
                }
              />
            )}
          />

          <Grid container>
            <Grid
              item
              xs
              container
              direction='column'
              style={{ padding: 15 }}
            />

            <Grid item style={{ paddingLeft: 30, paddingRight: 30 }}>
              <Divider orientation='vertical' />
            </Grid>

            <Grid
              item
              xs
              container
              direction='column'
              style={{ paddingBottom: 10 }}
            >
              <FileDropzone
                disabled={Boolean(uploadedFile)}
                accept='.xlsx'
                handleUpload={file => {
                  setUploadedFile(file)
                }}
              />
              {uploadedFile && (
                <Grid container alignItems='center'>
                  <Grid item xs style={{ padding: 8 }}>
                    {uploadedFile.path}
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={loading}
                      onClick={e => {
                        setUploadedFile(null)
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container justifyContent='space-around'>
            <Grid item>
              <Button
                disabled={!selectedTemplate || loading}
                variant='contained'
                color='primary'
                onClick={e => {
                  setLoading(true)
                  generateExcelFile({
                    selectedTemplate,
                    configuration,
                    user
                  }).then(reuslt => {
                    setLoading(false)
                  })
                }}
              >
                <Icon style={{ marginRight: 5 }}>download</Icon>
                <Trans>SURVEY_TEMPLATES_EXPORT_TRANSLATIONS_BUTTON</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!uploadedFile || !selectedTemplate || loading}
                variant='contained'
                color='primary'
                onClick={e => {
                  setLoading(true)
                  importTranslationsFromXMLS({
                    ...uploadedFile,
                    selectedTemplate
                  }).then(() => {
                    setLoading(false)
                  })
                }}
              >
                <Icon style={{ marginRight: 5 }}>upload</Icon>
                <Trans>SURVEY_TEMPLATES_IMPORT_TRANSLATIONS_BUTTON</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

const getSurveyTransToExcelObject = (
  location,
  titleTranslation,
  field = 'Text__c'
) => {
  let ret = {}
  if (checkIfnoRecords(titleTranslation)) {
    return ret
  }
  ret = {
    translationPath: field,
    id: titleTranslation.Survey_Texts__r.records[0].Survey_Translation__c,
    location
  }
  titleTranslation.Survey_Texts__r.records.forEach(obj => {
    ret = {
      ...ret,
      [obj.Language__c]: obj[field] || ''
    }
  })
  return ret
}

const importTranslationsFromXMLS = ({
  data,
  selectedTemplate,
  oldImport,
  language = 'en'
}) => {
  return Promise.all([getSurveyTemplateDetails(selectedTemplate.id)]).then(
    ([result]) => {
      const template = surveyDataToSurveyEditor({
        mainTemplate: result,
        templateId: selectedTemplate.id,
        language
      })
      const translationMap = createSurveyTranslationMap(template)
      const toUpdate = []
      const toCreate = []
      const languages = Object.values(portalLanguagesData)
      const XLSX = require('xlsx')
      const workbook = XLSX.read(data)
      const columnsData = {
        A: 'translationPath',
        B: 'id',
        C: 'location'
      }
      let startLetter = 'C'
      languages.forEach(obj => {
        startLetter = getNextAlphabeticalLetter(startLetter)
        columnsData[startLetter] = obj.surveyTextKey
      })

      Object.entries(workbook.Sheets).forEach(([key, sheet], index) => {
        let rowIndex = 2
        let rowExists = true

        while (rowExists) {
          Object.keys(columnsData).forEach(key => {
            if (['A', 'B', 'C'].includes(key)) {
              return
            }
            const cellValue = sheet[key + rowIndex]?.v
            if (cellValue) {
              const field = sheet['A' + rowIndex]?.v
              let editKey = null
              const surveyKey = columnsData[key]
              languages.some(obj => {
                if (surveyKey == obj.surveyTextKey) {
                  editKey = obj.editKey
                  return true
                }
                return false
              })
              if (sheet['B' + rowIndex]) {
                const transId = sheet['B' + rowIndex].v
                if (translationMap[transId]) {
                  const textId = translationMap[transId].id[editKey]
                  if (textId) {
                    toUpdate.push({
                      Id: textId,
                      [field]: cellValue,
                      Language__c: surveyKey,
                      type: 'Survey_Text__c'
                    })
                  } else {
                    toCreate.push({
                      Survey_Translation__c: transId,
                      [field]: cellValue,
                      Language__c: surveyKey
                    })
                  }
                }
              }
            }
          })
          if (!sheet['A' + (rowIndex + 1)]) {
            rowExists = false
            break
          } else {
            rowIndex++
          }
        }
      })
      return saveImportedSurveyData(toUpdate, toCreate)
    }
  )
}

const generateExcelFile = ({
  selectedTemplate,
  configuration,
  user,
  language = 'en'
}) => {
  return getSubtemplates(selectedTemplate.id).then(result => {
    const otherTemplatesIDs = []
    result.templates.forEach(dataObj => {
      otherTemplatesIDs.push(dataObj.id)
    })
    return Promise.all([
      getSurveyTemplateDetails(selectedTemplate.id),
      ...otherTemplatesIDs.map(id => getSurveyTemplateDetails(id))
    ])
      .then(([result, ...other]) => {
        const template = surveyDataToSurveyEditor({
          mainTemplate: result,
          templateId: selectedTemplate.id,
          language
        })
        const templateName = template.surveyName
        const workbook = new Excel.Workbook()
        const columns = [
          {
            header: myI18n._(t`Translation path`),
            width: 40,
            key: 'translationPath'
          },
          {
            header: myI18n._(t`Id`),
            width: 40,
            key: 'id'
          },
          {
            header: myI18n._(t`Location`),
            width: 40,
            key: 'location'
          },
          ...Object.values(portalLanguagesData).map(obj => {
            return {
              header: obj.label,
              width: 40,
              key: obj.surveyTextKey
            }
          })
        ]
        const sheet = workbook.addWorksheet('Sheet')
        sheet.columns = [...columns]
        const titleRow = getSurveyTransToExcelObject(
          `${templateName} - title`,
          template.titleData
        )
        sheet.addRow(titleRow)

        template.created.map((question, questionIndex) => {
          questionIndex += 1
          const questionRow = getSurveyTransToExcelObject(
            `${templateName} - question ${questionIndex}`,
            question.itemTranslation
          )
          sheet.addRow(questionRow)

          question.options.map((option, optionIndex) => {
            optionIndex += 1
            const optionRow = getSurveyTransToExcelObject(
              `${templateName} - question ${questionIndex}, option ${optionIndex}`,
              option
            )
            sheet.addRow(optionRow)
          })

          if (question.tooltipAtBottom) {
            const tooltipRow = getSurveyTransToExcelObject(
              `${templateName} - question ${questionIndex}, tooltip`,
              question.itemTranslation,
              'Tooltip__c'
            )
            sheet.addRow(tooltipRow)
          }
          if (question.isGroup) {
            question.groupQuestions.map((sub, subIndex) => {
              subIndex += 1
              const subQuestionRow = getSurveyTransToExcelObject(
                `${templateName} - question ${questionIndex}, subquestion ${subIndex}`,
                sub.itemTranslation
              )
              sheet.addRow(subQuestionRow)
            })
          }
        })
        return workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          saveAs(blob, template.surveyName + '.xlsx')
          return Promise.resolve()
        })
      })
      .catch(error => {
        console.error('Error:', error)
      })
  })
}

import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { FundToCaseType } from 'app/services/sfAuth/sfData/sfOpportunity'
import { Formik } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  HELP_CASE_TYPES,
  createCase
} from '../../../services/sfAuth/sfData/sfCase'

function ContactProgramManager ({
  recordType,
  active,
  opportunityId,
  prequalificationId,
  fundingStreamId
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const location = useLocation()
  const organization = useSelector(state => state.organization)
  const fundingStreams = useSelector(state => state.fundingStreams.streams)
  const [streamCaseType, setStreamCaseType] = React.useState(null)
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false)

  useEffect(() => {
    if (fundingStreams) {
      const matchingFundingStream = fundingStreams.find(
        stream => stream.id === fundingStreamId
      )

      if (matchingFundingStream && matchingFundingStream.caseType) {
        setStreamCaseType(matchingFundingStream.caseType)
      }
      setIsReadyToSubmit(true)
    }
  }, [fundingStreams, opportunityId])

  const submitData = (values, actions) => {
    enqueueSnackbar(<Trans>Submitting Case</Trans>, {
      variant: 'info'
    })
    createCase({
      ...values,
      comments:
        `url: ${location.pathname} \n env: v ${process.env.REACT_APP_VERSION}-` +
        `${process.env.REACT_APP_ENVIROMENT}`,
      type:
        streamCaseType ||
        FundToCaseType[recordType] ||
        HELP_CASE_TYPES.FUNDING_UNSURE,
      language: user.language,
      organization: organization.id,
      skipAssigment: true,
      opportunityId,
      prequalificationId,
      contact: user.userObject.contactId
    })
      .then(() => {
        enqueueSnackbar(<Trans>Submitted Case</Trans>, {
          variant: 'success'
        })
        setDialogOpen(false)
      })
      .catch(error => {
        console.error('Error submitting case', error)
        enqueueSnackbar(<Trans>Error Submitting Case</Trans>, {
          variant: 'error'
        })
        actions.setSubmitting(false)
      })
  }

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: '600px' }}>
          <DialogTitle>
            <Trans>Contact Program Manager</Trans>
          </DialogTitle>
          <DialogContent>
            <Formik initialValues={{}} onSubmit={submitData}>
              {({ values, handleSubmit, isSubmitting }) => {
                return (
                  <Grid
                    container
                    direction='column'
                    justifyContent='space-around'
                  >
                    <FormikTextField
                      style={{ marginBottom: 15 }}
                      name='title'
                      variant='outlined'
                      fullWidth
                      label={<Trans>Title</Trans>}
                    />
                    <FormikTextField
                      style={{ marginBottom: 15 }}
                      name='description'
                      variant='outlined'
                      fullWidth
                      multiline
                      rows={4}
                      label={<Trans>Description</Trans>}
                    />

                    <Grid container justifyContent='space-around'>
                      <Button
                        variant='contained'
                        disabled={
                          !values.title ||
                          !values.description ||
                          isSubmitting ||
                          !isReadyToSubmit
                        }
                        color='primary'
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        <Trans>Submit</Trans>
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          setDialogOpen(false)
                        }}
                      >
                        <Trans>Cancel</Trans>
                      </Button>
                    </Grid>
                  </Grid>
                )
              }}
            </Formik>
          </DialogContent>
        </div>
      </Dialog>
      <Tooltip
        title={
          active
            ? (
              <Trans>Contact Program Manager</Trans>
              )
            : (
              <Trans>Your opportunity has no Programe assigne yet</Trans>
              )
        }
      >
        <IconButton
          style={{ color: !active && '#BDBDBD' }}
          onClick={() => {
            if (!active) {
              return
            }
            setDialogOpen(true)
          }}
        >
          <Icon>contact_mail</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}

export default ContactProgramManager

import { createTheme } from '@material-ui/core'
import { forEach, merge } from 'lodash'
import sfOauthConfig from '../services/sfAuth/sfAuthConfig'
import { muiTableOverrides } from '../views/surveys/SurveyTemplates'
import { themeColors } from './EgretTheme/themeColors'
import themeOptions from './EgretTheme/themeOptions'
import layout1Settings from './Layout1/Layout1SettingsChtc'
import layout1SettingsIcce from './Layout1/Layout1SettingsIcce'

function createEgretThemes () {
  const themes = {}

  forEach(themeColors, (value, key) => {
    themes[key] = createTheme(
      merge({}, themeOptions, value, muiTableOverrides, muiOverrides)
    )
  })
  return themes
}

const muiOverrides = {
  datePickerToolbar: {
    paddingLeft: 24,
    paddingRight: 24,
    height: 100,
    color: 'primary'
  },
  overrides: {
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: '#585858'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.26)'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        '&.Mui-disabled': {
          color: '#585858'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-disabled': {
          color: '#585858'
        }
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        minWidth: 550
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: '100%'
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: 70
      }
    },
    MuiPickersDay: {
      day: {
        width: 40,
        marginLeft: 17,
        marginRight: 17
      }
    }
  }
}

const themes = createEgretThemes()

export const settingsToSidebarTheme = settings => {
  return settings.sidebarThemes[settings.activeTheme] || {}
}

export const settingsToTheme = settings => {
  console.log('settings to theme', settings)
  return settings.themes[settings.activeTheme] || {}
}

export const EgretLayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: sfOauthConfig.isIcce ? 'icceWhite' : 'chtcWhite', // View all valid theme colors inside EgretTheme/themeColors.js
  perfectScrollbar: false,

  themes,
  sidebarThemes: {
    chtcWhite: layout1Settings,
    icceWhite: layout1SettingsIcce
  },
  layout1Settings, // open Layout1/Layout1Settings.js
  layout1SettingsIcce, // open Layout1/Layout2Settings.js

  secondarySidebar: {
    show: false,
    theme: 'slateDark1' // View all valid theme colors inside EgretTheme/themeColors.js
  },
  // Footer options
  footer: {
    show: true,
    fixed: false,
    theme: 'slateDark1' // View all valid theme colors inside EgretTheme/themeColors.js
  }
}

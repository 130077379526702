import { pdfTableStyles } from '../../pdf-components/PDFCommon'

export const FormTablePdfEditor = ({ title, langFR, typeProps }) => {
  const { columns = [] } = typeProps

  return (
    <div className='pdf-mode'>
      <div className='pdfProps form-print-subtitle'>{title}</div>
      <div style={{ ...pdfTableStyles.table, display: 'flex' }}>
        {columns.map((column) => (
          <div style={{ flex: 1 }}>
            <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>{langFR ? column?.labelFR : column?.labelEN}</div>
            {/* <div className='value'>{langFR ? `${column?.labelFR} Placeholder` : `${column?.labelEN} Placeholder`}</div> */}
            <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}><i>{column?.field?.name}</i> Placeholder</div>
          </div>
        ))}
      </div>
    </div>
  )
}

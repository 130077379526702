import { t } from '@lingui/macro'
import { Typography } from '@material-ui/core'
import { myI18n } from 'translation/I18nConnectedProvider'

export function FormNumericSliderReadOnly ({ value, title, typeProps }) {
  const { max = 100, min = 0 } = typeProps
  const valueString =
    value +
    ' (' +
    myI18n?._(t`min`) +
    '. ' +
    String(min) +
    ', ' +
    myI18n?._(t`max`) +
    '. ' +
    String(max) +
    ')'

  return <Typography>{value && valueString}</Typography>
}

import { Trans } from '@lingui/macro'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getSurveyTemplateDetails } from 'app/services/sfAuth/sfData/sfSurvey'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'
import { questionTypes } from './QuestionWidget'

export default function ExternalQuestionDialog ({
  dialogOpened,
  userLanguage,
  setValues,
  saveSurvey,
  questionIndex,
  values,
  setDialogOpened,
  newQuestion = true
}) {
  const [currentExternalTemplate, setCurrentExternalTemplate] = useState(null)
  const [currentFound, setCurrentFound] = useState([])
  const [loading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setCurrentFound([])
    setCurrentExternalTemplate(null)
  }, [dialogOpened])

  if (!dialogOpened) {
    return null
  }

  return (
    <Dialog open={dialogOpened} maxWidth='false'>
      <div style={{ width: '900px' }}>
        <DialogTitle>
          <Trans>Choose question from another tempate</Trans>
        </DialogTitle>
        <DialogContent style={{ marginTop: 5 }}>
          <Grid
            container
            direction='row'
            justify='center'
            spacing={2}
            wrap='nowrap'
          >
            <Autocomplete
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField fullWidth {...params} variant='outlined' />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCurrentExternalTemplate(newValue.id)
                } else {
                  setCurrentExternalTemplate(null)
                }
              }}
              getOptionLabel={option => option.name}
              options={values.otherTemplates}
            />
            <Button
              color='primary'
              disabled={Boolean(!currentExternalTemplate)}
              variant='contained'
              style={{ width: '140px', marginLeft: '10px' }}
              onClick={() => {
                setLoading(true)
                getSurveyTemplateDetails(currentExternalTemplate).then(
                  result => {
                    setLoading(false)
                    setCurrentFound(result.questionsDetails)
                  }
                )
              }}
            >
              <Trans>Search</Trans>
            </Button>
          </Grid>
          {loading && (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '40px'
                }}
              >
                <CircularProgress />
              </div>
            </div>
          )}

          {currentFound.length > 0 && (
            <div style={{ marginTop: 20 }}>
              {!newQuestion && (
                <Typography style={{ color: 'red', marginBottom: 10 }}>
                  <Trans>
                    Be careful! Overriding selected question with question from
                    another template will save your current changes!
                  </Trans>
                </Typography>
              )}
              <MUIDataTable
                options={{
                  // textLabels: muiTextLabels(i18n),
                  expandableRows: false,
                  selectableRows: 'none',
                  filter: false,
                  print: false,
                  download: false,
                  sort: false,
                  search: false,
                  viewColumns: false
                }}
                data={parseFound(currentFound)}
                columns={[
                  {
                    name: 'name',
                    options: {
                      customHeadRender: ({ index, ...column }) => {
                        return (
                          <TableCell key={index}>
                            <Typography style={{ textAlign: 'center' }}>
                              <b>
                                <Trans>Question</Trans>
                              </b>
                            </Typography>
                          </TableCell>
                        )
                      },
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return <div style={{ marginLeft: 10 }}>{value}</div>
                      }
                    }
                  },
                  {
                    name: 'type',
                    options: {
                      customHeadRender: ({ index, ...column }) => {
                        return (
                          <TableCell key={index} style={{ width: '350px' }}>
                            <Typography style={{ textAlign: 'center' }}>
                              <b>
                                <Trans>Question type</Trans>
                              </b>
                            </Typography>
                          </TableCell>
                        )
                      },
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <Grid
                            container
                            direction='row'
                            alignItems='center'
                            justify='center'
                          >
                            {questionTypes[value].text}
                            <Icon style={{ marginLeft: 10 }}>
                              {questionTypes[value].icon}
                            </Icon>
                          </Grid>
                        )
                      }
                    }
                  },
                  {
                    name: 'chooseQuestion',
                    options: {
                      customHeadRender: ({ index, ...column }) => {
                        return (
                          <TableCell key={index} style={{ width: '60px' }} />
                        )
                      },
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <div>
                            <IconButton
                              disabled={isSaving}
                              onClick={() => {
                                const questionsNew = [...values.created]
                                const toSet = {
                                  ...questionsNew[questionIndex]
                                }
                                const toDelete = [...values.toDelete]
                                const toUpdate = [...values.toUpdate]
                                // if (toSet.sfId === value.item.Id) {
                                //   setDialogOpened(false)
                                //   return
                                // }
                                if (toSet.groupQuestions && toSet.sfId) {
                                  toSet.groupQuestions.forEach(sub => {
                                    toDelete.push({
                                      type: 'Survey_Join_Template_and_Item__c',
                                      id: sub.joinId
                                    })
                                    if (sub.joins.length === 1) {
                                      toDelete.push(
                                        ...Object.values(
                                          sub.itemTranslation.idValue
                                        ).map(id => {
                                          return {
                                            type: 'Survey_Text__c',
                                            id
                                          }
                                        }),
                                        {
                                          type: 'SurveyTranslation__c',
                                          id: sub.itemTranslation.parentId
                                        },
                                        {
                                          type: 'Survey_Items__c',
                                          id: sub.sfId
                                        }
                                      )
                                    }
                                  })
                                }
                                if (toSet.sfId && toSet.joins.length === 1) {
                                  toSet.options.forEach(deletedOption => {
                                    if (!deletedOption.parentId) {
                                      return
                                    }
                                    toDelete.push(
                                      {
                                        type: 'SurveyTranslation__c',
                                        id: deletedOption.parentId
                                      },
                                      {
                                        type: 'SurveyItemDetail__c',
                                        id: deletedOption.detailId
                                      },
                                      ...Object.values(
                                        deletedOption.idValue
                                      ).map(id => {
                                        return {
                                          type: 'Survey_Text__c',
                                          id
                                        }
                                      })
                                    )
                                  })
                                  toDelete.push(
                                    {
                                      type: 'SurveyTranslation__c',
                                      id: toSet.itemTranslation.parentId
                                    },
                                    ...Object.values(
                                      toSet.itemTranslation.idValue
                                    ).map(id => {
                                      return {
                                        type: 'Survey_Text__c',
                                        id
                                      }
                                    }),
                                    {
                                      type: 'Survey_Items__c',
                                      id: toSet.sfId
                                    }
                                  )
                                }
                                console.log('QUESTION FROM ANOTHER', value)
                                if (toSet.joinId && value.item.Id) {
                                  toUpdate.push({
                                    type: 'Survey_Join_Template_and_Item__c',
                                    Id: toSet.joinId,
                                    Survey_Item__c: value.item.Id
                                  })
                                }
                                if (questionsNew && toSet.joins.length > 0) {
                                  toSet.joins.forEach(join => {
                                    if (
                                      join.Survey_Template__c ===
                                      values.templateId
                                    ) {
                                      toDelete.push({
                                        type: 'Survey_Join_Template_and_Item__c',
                                        id: join.Id
                                      })
                                    }
                                  })
                                  toSet.joins = []
                                }
                                // chooseExternalQuestion(
                                //   value.item.Id,
                                //   toSet.joinId,
                                //   toSet.sfId
                                // ).then(result => {
                                toSet.sfId = newQuestion ? null : value.item.Id
                                toSet.conditions = []
                                toSet.isGroup = value.itemOptions.isGroup
                                toSet.rows = value.itemOptions.rows
                                toSet.min = value.itemOptions.min
                                toSet.max = value.itemOptions.max
                                toSet.conditionForObjectField =
                                  value.itemOptions.conditionForObjectField
                                toSet.conditionalObject =
                                  value.itemOptions.conditionalObject
                                toSet.conditionalObjectField =
                                  value.itemOptions.conditionalObjectField
                                toSet.conditionalObjectName =
                                  value.itemOptions.conditionalObjectName
                                toSet.conditionalObjectSubfield =
                                  value.itemOptions.conditionalObjectSubfield
                                toSet.isLinked = value.itemOptions.isLinked
                                toSet.linkedField =
                                  value.itemOptions.linkedField
                                toSet.linkedObject =
                                  value.itemOptions.linkedObject
                                toSet.hasTooltip = value.itemOptions.hasTooltip
                                toSet.isRequired = value.itemOptions.isRequired
                                toSet.tooltipAtBottom =
                                  value.itemOptions.tooltipAtBottom
                                toSet.groupQuestions =
                                  value.itemOptions.subquestions || []
                                toSet.groupQuestions.forEach(sub => {
                                  const crypto = require('crypto')
                                  if (newQuestion) {
                                    delete sub.joinId
                                    delete sub.sfId
                                    if (sub.itemTranslation) {
                                      delete sub.itemTranslation.idValue
                                      delete sub.itemTranslation.itemId
                                      delete sub.itemTranslation.parentId
                                    }
                                  }
                                  sub.id = crypto
                                    .randomBytes(20)
                                    .toString('hex')
                                  sub.joins = []
                                })
                                toSet.groupQuestions.sort(
                                  (a, b) => a.index - b.index
                                )
                                toSet.type = value.item.Type__c
                                toSet.validationRules = [
                                  ...value.itemOptions.validationRules
                                ]
                                // toSet.id = value.itemOptions.createdId
                                toSet.titleValue =
                                  value.itemTranslation.titleValue
                                toSet.itemTranslation = {
                                  ...value.itemTranslation
                                }
                                toSet.tooltipValue =
                                  value.itemTranslation.tooltipValue
                                toSet.options = []
                                value.itemDetails.forEach(option => {
                                  if (!newQuestion) {
                                    toSet.options.push({
                                      ...option
                                    })
                                  } else {
                                    toSet.options.push({
                                      titleValue: option.titleValue,
                                      text: ''
                                    })
                                  }
                                })
                                toSet.options.sort((a, b) => a.order - b.order)
                                value.itemOptions.optionsWithRequiredDetails.forEach(
                                  req => {
                                    if (req.index) {
                                      toSet.options[req.index].requireDetails =
                                        req.type
                                    } else {
                                      toSet.options[req].requireDetails = 'text'
                                    }
                                  }
                                )
                                questionsNew[questionIndex] = toSet
                                const newValues = { ...values }
                                newValues.toUpdate = toUpdate
                                newValues.toDelete = toDelete
                                newValues.created = questionsNew
                                setValues(newValues)
                                if (newQuestion) {
                                  setDialogOpened(false)
                                } else {
                                  setIsSaving(true)
                                  saveSurvey(newValues).then(result => {
                                    setDialogOpened(false)
                                    setIsSaving(false)
                                  })
                                }
                              }}
                            >
                              <Icon>done</Icon>
                            </IconButton>
                          </div>
                        )
                      }
                    }
                  }
                ]}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            disabled={isSaving}
            onClick={() => {
              setDialogOpened(false)
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )

  function isSubquestion (dataObj) {
    return (
      dataObj.itemOptions.parentQuestionIndex ||
      dataObj.itemOptions.parentQuestionIndex === 0 ||
      dataObj.itemOptions.parentQuestionId
    )
  }

  function parseFound (data) {
    const toReturn = []
    const matchObj = {}
    data.forEach(item => {
      if (isSubquestion(item)) {
        const toPush = {
          titleValue: item.itemTranslation.titleValue,
          sfId: item.item.Id,
          itemTranslation: item.itemTranslation,
          joinId: item.joinId,
          joins: item.item.Survey_Joins_Template_and_Question__r.records,
          id: item.itemOptions.createdId,
          index: item.itemOptions.index,
          order: item.order
        }
        const parentId =
          item.itemOptions.parentQuestionId ||
          item.itemOptions.parentQuestionIndex
        if (matchObj[parentId]) {
          matchObj[parentId].push(toPush)
        } else {
          matchObj[parentId] = [toPush]
        }
        return
      }
      const nameDisplay = getLabelFromTranslationData({
        langVersion: userLanguage,
        data: item.itemTranslation.titleValue
      })
      toReturn.push({
        name: (item.order + 1).toString() + '. ' + nameDisplay,
        type: item.item.Type__c,
        order: item.order,
        chooseQuestion: item
      })
    })
    toReturn.forEach(obj => {
      if (obj.chooseQuestion.itemOptions.isGroup) {
        obj.chooseQuestion.itemOptions.subquestions =
          matchObj[obj.chooseQuestion.itemOptions.createdId] ||
          matchObj[obj.chooseQuestion.order]
      }
    })
    toReturn.sort((a, b) => a.order - b.order)
    return toReturn
  }
}

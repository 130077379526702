// package: Mo_multiuser
// file: MultiuserSF.proto

const MultiuserSF_pb = require('./MultiuserSF_pb')
const google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb')
const Multiuser_pb = require('./Multiuser_pb')
const grpc = require('@improbable-eng/grpc-web').grpc

const MultiuserSF = (function () {
  function MultiuserSF () {}
  MultiuserSF.serviceName = 'Mo_multiuser.MultiuserSF'
  return MultiuserSF
}())

MultiuserSF.UserRequestedSFSave = {
  methodName: 'UserRequestedSFSave',
  service: MultiuserSF,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: MultiuserSF_pb.RequestSFSaveReceive
}

MultiuserSF.UserCompletedSaveToSF = {
  methodName: 'UserCompletedSaveToSF',
  service: MultiuserSF,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: MultiuserSF_pb.SaveToSFCompleted
}

MultiuserSF.ServerRequestSubmitToSF = {
  methodName: 'ServerRequestSubmitToSF',
  service: MultiuserSF,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserID
}

MultiuserSF.UserEndedToSubmitToSF = {
  methodName: 'UserEndedToSubmitToSF',
  service: MultiuserSF,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: MultiuserSF_pb.UserOperation
}

MultiuserSF.ReportSubmitToSF = {
  methodName: 'ReportSubmitToSF',
  service: MultiuserSF,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.DocumentOperation,
  responseType: google_protobuf_empty_pb.Empty
}

MultiuserSF.ReportSaveToSFCompleted = {
  methodName: 'ReportSaveToSFCompleted',
  service: MultiuserSF,
  requestStream: false,
  responseStream: false,
  requestType: MultiuserSF_pb.SaveToSFCompletedReport,
  responseType: google_protobuf_empty_pb.Empty
}

MultiuserSF.RequestSFSave = {
  methodName: 'RequestSFSave',
  service: MultiuserSF,
  requestStream: false,
  responseStream: false,
  requestType: MultiuserSF_pb.RequestSFSaveMessage,
  responseType: MultiuserSF_pb.RequestSFSaveResponde
}

exports.MultiuserSF = MultiuserSF

function MultiuserSFClient (serviceHost, options) {
  this.serviceHost = serviceHost
  this.options = options || {}
}

MultiuserSFClient.prototype.userRequestedSFSave = function userRequestedSFSave (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(MultiuserSF.UserRequestedSFSave, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.userCompletedSaveToSF = function userCompletedSaveToSF (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(MultiuserSF.UserCompletedSaveToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.serverRequestSubmitToSF = function serverRequestSubmitToSF (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(MultiuserSF.ServerRequestSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.userEndedToSubmitToSF = function userEndedToSubmitToSF (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(MultiuserSF.UserEndedToSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.reportSubmitToSF = function reportSubmitToSF (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(MultiuserSF.ReportSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.reportSaveToSFCompleted = function reportSaveToSFCompleted (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(MultiuserSF.ReportSaveToSFCompleted, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserSFClient.prototype.requestSFSave = function requestSFSave (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(MultiuserSF.RequestSFSave, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

exports.MultiuserSFClient = MultiuserSFClient

import { Trans } from '@lingui/macro'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { PdfPropsForm } from 'app/views/forms/common/PdfPropsForm'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { useDispatch } from 'react-redux'
import { FormBanner } from './FormBanner'

const bannerVariants = [
  {
    value: 'standard',
    trans: <Trans>FORM_EDITOR_BANNER_STANDARD_VARIANT</Trans>
  },
  { value: 'filled', trans: <Trans>FORM_EDITOR_BANNER_FILLED_VARIANT</Trans> },
  {
    value: 'outlined',
    trans: <Trans>FORM_EDITOR_BANNER_OUTLINED_VARIANT</Trans>
  }
]

const bannerStates = [
  { value: 'error', trans: <Trans>FORM_EDITOR_BANNER_ERROR_STATE</Trans> },
  { value: 'warning', trans: <Trans>FORM_EDITOR_BANNER_WARNING_STATE</Trans> },
  { value: 'info', trans: <Trans>FORM_EDITOR_BANNER_INFO_STATE</Trans> },
  { value: 'success', trans: <Trans>FORM_EDITOR_BANNER_SUCCESS_STATE</Trans> }
]

export const FormEditorBanner = ({
  editMode,
  depth,
  typeProps,
  injectable,
  showPdfProps,
  ...props
}) => {
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormBanner typeProps={typeProps} {...props} />
  }
  const { variant, state, text } = typeProps

  return (
    <div>
      <ConfigureMultilanguageTextField
        variant='outlined'
        style={{ marginTop: 25 }}
        label={<Trans>FORM_EDITOR_BANNER_TEXT_LABEL</Trans>}
        value={text || ''}
        multiline
        handleChange={(value, e, lang) => {
          const toSet = { ...typeProps }
          toSet.text = value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
        fullWidth
        useDebounce
      />

      <InputLabel
        style={{
          marginBottom: 5,
          marginTop: 25,
          textAlign: 'left',
          width: '100%'
        }}
      >
        <Trans>FORM_EDITOR_BANNER_VARIANT_LABEL</Trans>
      </InputLabel>

      <Select
        style={{
          flexGrow: 1
        }}
        fullWidth
        variant='outlined'
        value={variant || 'standard'}
        onChange={(e) => {
          const toSet = { ...typeProps }
          toSet.variant = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      >
        {bannerVariants.map((type, index) => (
          <MenuItem value={type.value} key={type.value}>
            {type.trans}
          </MenuItem>
        ))}
      </Select>

      <InputLabel
        style={{
          marginBottom: 5,
          marginTop: 25,
          textAlign: 'left',
          width: '100%'
        }}
      >
        <Trans>FORM_EDITOR_BANNER_STATE_LABEL</Trans>
      </InputLabel>

      <Select
        style={{
          flexGrow: 1
        }}
        fullWidth
        variant='outlined'
        value={state || 'info'}
        onChange={(e) => {
          const toSet = { ...typeProps }
          toSet.state = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      >
        {bannerStates.map((type, index) => (
          <MenuItem value={type.value} key={type.value}>
            {type.trans}
          </MenuItem>
        ))}
      </Select>
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
    </div>
  )
}

import { t, Trans } from '@lingui/macro'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { dateFormat } from 'app/appSettings'
import {
  addSurveyByFlow,
  changeSurveyLockByFlow,
  getSATsByFlow,
  SATsIdsList,
  surveyMapping,
  updateSurvey
} from 'app/services/sfAuth/sfData/sfSurvey'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { withSnackbar } from 'notistack'
import React, { Component, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { muiDateCompare } from '../../../utils'
import { accountRoles } from '../grants/organization/UserOrganizations'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import { countHelperText } from '../page-layouts/FormElement'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'

const styles = theme => ({
  headerCard: {
    background: 'rgba(0, 0, 0, 0.08)',
    maxWidth: 900,
    margin: '20px auto',
    padding: 20,
    borderRadius: 8
  },
  headerPart: {
    marginTop: 20
  },
  headerP: {
    marginTop: 10
  }
})
const useStylesHeaders = makeStyles(styles)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 345,
    // minWidth: 345,
    // padding: 10,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: props =>
      props.disabled
        ? theme.palette.action.disabledBackground
        : props.highlight
          ? theme.palette.secondary.light
          : 'inherit',
    filter: props => (props.disabled ? 'grayscale(1)' : '')
  },
  selectedCard: {
    maxWidth: 450,
    padding: 10,
    height: '100%',
    display: 'flex'
  },
  actions: {
    flexDirection: 'column',
    flexGrow: 1,
    display: 'flex'
  },
  media: {
    backgroundSize: 'contain',
    height: 140
  }
}))

const SATSurveyCard = () => {
  const classes = useStylesHeaders()
  const lang = useSelector(state => state.user.language)

  return (
    <Card className={classes.headerCard}>
      <Typography variant='h5'>
        <Trans>Self-Assessment Tool for Community Housing Providers</Trans>
      </Typography>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            Is your non-profit or cooperative housing 10, 20, 40 years old? How
            is it doing? What is the condition of your building(s), units and
            finances? How is your governance, your board of directors, and
            member or tenant involvement? What are your ties with the
            surrounding community and residents?
          </Trans>
        </Typography>
      </div>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            All these elements are essential to the vitality of your
            organization and its future. This questionnaire will allow you to
            assess its health via four different and interdependent themes:
          </Trans>
        </Typography>
        <ul>
          <li>
            <Trans>Governance</Trans>
          </li>
          <li>
            <Trans>Property Management</Trans>
          </li>
          <li>
            <Trans>Financial Management</Trans>
          </li>
          <li>
            <Trans>Social/Community Relations</Trans>
          </li>
        </ul>
        <Typography className={classes.headerP}>
          <Trans>
            For each of these themes, based on your answers, this questionnaire
            will generate an assessment of the state of your organization and
            suggest possible courses of action to take regarding the challenges
            identified. It will allow you to develop a plan of action to
            consolidate your organization by targeting its needs, whether they
            be member training, management support or ways to improve your
            units.
          </Trans>
        </Typography>
      </div>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            If you’d like, the Centre will comment on the results of your
            assessment. You will also be able to access your completed
            questionnaire to use it, for example, within your board of
            directors, with funders or others. The Centre is committed to
            protecting the strict confidentiality of the information entered in
            this questionnaire.
          </Trans>
        </Typography>
      </div>
      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <b>
            <Trans>To complete the assessment:</Trans>
          </b>
        </Typography>
        <Typography className={classes.headerP}>
          <Trans>
            This questionnaire should be completed by a member of the board of
            directors or the manager your housing organization. It will take
            about 30 minutes to complete the questionnaire, time we are
            convinced will represent a useful investment for your housing
            organization.
          </Trans>
        </Typography>

        <Typography className={classes.headerP}>
          <Trans>
            To complete a previously started assessment, click on the pencil
            icon on the left of the assessment you wish to complete.
          </Trans>
        </Typography>
        <Typography style={{ marginTop: 5 }}>
          <Trans>
            Please refer to{' '}
            <Link href={myI18n._(t`SAT_TUTORIAL_LINK`)} target='_blank' rel='noreferrer'>
              <Trans>our tutorial</Trans>
            </Link>{' '}
            if you need more guidance.
          </Trans>
        </Typography>
      </div>
    </Card>
  )
}

export const SurveyCard = ({
  value,
  checked,
  onChange,
  disabled,
  disabledText,
  title,
  image = '/assets/images/self-assessment-icon.png',
  subtitle
}) => {
  // const disabled = cardData[value].disabled
  const [disabledPopUpOpen, setDisabledPopUpOpen] = useState(false)
  const classes = useStyles({ highlight: checked, disabled })
  const history = useHistory()

  return (
    <>
      <Dialog open={disabledPopUpOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid container justify='space-between' alignItems='center'>
            <Trans>You cannot start this survey!</Trans>
            <IconButton
              onClick={e => {
                setDisabledPopUpOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>{disabledText}</DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 15,
            paddingTop: 10
          }}
        >
          <Button
            color='primary'
            variant='contained'
            onClick={e => {
              history.push('/grants/organization-details/')
            }}
          >
            <Trans>Go to organization details</Trans>
          </Button>
        </div>
      </Dialog>
      <Card className={classes.root}>
        <CardActionArea
          // disabled={disabled}
          style={{ padding: 10, height: '100%' }}
          onDoubleClick={() => {
            if (disabled) {
              setDisabledPopUpOpen(true)
            }
          }}
          onClick={() => {
            if (!disabled) {
              onChange(value)
            }
          }}
        >
          <Grid container direction='column' style={{ height: '100%' }}>
            <CardMedia className={classes.media} image={image} title='logo' />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                {title}
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                {subtitle}
              </Typography>
            </CardContent>
            <div
              style={{
                display: 'flex',
                flexGrow: 1
              }}
            />
            <Button
              color={disabled ? 'disabled' : 'primary'}
              fullWidth
              disabled={disabled}
              variant='contained'
              className={classes.button}
              onClick={() => onChange(value)}
            >
              <Trans>Select</Trans>
            </Button>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  )
}

class AvaliableSurveys extends Component {
  state = {
    data: null,
    open: false,
    anchorEl: null,
    startSATMenuOpen: false
  }

  constructor (props) {
    super(props)
    this.anchorRef = React.createRef()
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleNameChanged = this.handleNameChanged.bind(this)
    // this.getMenuItems = this.getMenuItems.bind(this)
    this.handleAddSAT = this.handleAddSAT.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.fetchData()
    // if there is only one sat preselect it
    if (props.surveyCardData.length === 1) {
      this.state.selectedSurvey = props.surveyCardData[0].value
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.organization) {
      if (prevProps.organization !== this.props.organization) {
        this.setState({ data: null })
        this.fetchData()
      }
    }
  }

  fetchData () {
    if (!this.props.organization.id) {
      this.state = {
        data: []
      }
      return
    }
    return getSATsByFlow({
      accountId: this.props.organization.id,
      templateIDs: this.props.satIds
    }).then(surveys => {
      console.log('got surveys', surveys)
      const toSet = []
      if (surveys) {
        surveys.forEach(survey => {
          const name = survey.Name
          const dataField = {}
          const lockedByMe =
            survey.OwnerId === this.props.user.userId && survey.Locked__c
          dataField.name = {
            name,
            id: survey.Id,
            locked: survey.Locked__c,
            lockedBy: survey.Locked__c ? survey.Owner_Info__c : ''
          }
          dataField.accountId = survey.Account__c
          dataField.template = {
            templateId: survey.Template__c,
            locked: survey.Locked__c,
            lockedBy: survey.Locked__c ? survey.Owner_Info__c : '',
            lockedByMe,
            lockedTooLong:
              moment.utc().diff(moment.utc(survey.LastModifiedDate), 'hours') >
              24,
            surveyId: survey.Id,
            name
          }
          dataField.surveyState = survey.SurveyState__c
          dataField.editedBy = {
            editedBy: survey.Locked__c ? survey.Owner_Info__c : '',
            editedByMe: lockedByMe
          }
          dataField.validity = {
            end: survey.ValidityPeriodEnd__c,
            start: survey.ValidityPeriodStart__c,
            createdDate: survey.CreatedDate,
            modifiedDate: survey.LastModifiedDate
          }
          toSet.push(dataField)
        })
      }
      console.log('data to set', toSet)
      this.setState({
        data: toSet
      })
    })
  }

  handleMenuOpen (e) {
    this.setState({ anchorEl: e.currentTarget, open: !this.state.open })
  }

  handleSurveyAdded (item) {
    const newData = [...this.state.data]
    newData.push(item)
    this.setState({ data: newData, open: false })
  }

  handleNameChanged (id, newName) {
    updateSurvey({
      Id: id,
      Name: newName
    }).then(
      result => {
        let toChangeIndex
        this.state.data.some((template, index) => {
          if (template.name.id === id) {
            toChangeIndex = index
          }
          return template.name.id === id
        })
        const newData = [...this.state.data]
        newData[toChangeIndex].name.name = newName
        this.setState({
          data: newData
        })
        this.props.enqueueSnackbar(<Trans>Name updated!</Trans>, {
          variant: 'success'
        })
      },
      reject => {
        this.props.enqueueSnackbar(<Trans>Error while updating name</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  handleAddSAT (surveyTemplate, name) {
    const { enqueueSnackbar, user, texts } = this.props
    addSurveyByFlow({
      accountId: this.props.organization.id,
      userId: this.props.user.userId,
      surveyTemplate,
      locked: true,
      name:
        name ||
        myI18n._(surveyTemplate) + ', ' + moment.utc().format(dateFormat)
    })
      .then(result => {
        enqueueSnackbar(texts.creationSnackbarSuccess, { variant: 'success' })
        try {
          const surveyId = result[0].outputValues.Survey.Id
          this.props.history.push(
            `/surveys/SurveyTab/${
              surveyMapping[surveyTemplate].prod
            }/${surveyId}/${'edit'}`,
            {
              id: surveyMapping[surveyTemplate].prod,
              disabled: false,
              surveyId
            }
          )
        } catch (error) {
          enqueueSnackbar(<Trans>Error occured while loading survey!</Trans>, {
            variant: 'error'
          })
          // this.fetchData()
        }
      })
      .catch(error => {
        enqueueSnackbar(texts.creationSnackbarError, {
          variant: 'error'
        })
        console.error('handleAddSAT', error)
      })
  }

  render () {
    const {
      classes,
      user,
      organization,
      history,
      texts,
      surveyCardData,
      enqueueSnackbar,
      closeSnackbar
    } = this.props
    const {
      startSATMenuOpen,
      selectedSurvey,
      editedName,
      createdName,
      data,
      locking,
      unlocking,
      newName
    } = this.state
    const oldSAT = false
    let roleNoPermission = true
    if (user.organizationMember) {
      const role = user.organizationMember.TeamMemberRole
      if (
        role === accountRoles.MANAGER.apiName ||
        role === accountRoles.CONTRIBUTOR.apiName
      ) {
        roleNoPermission = false
      }
    }
    const isCoOp = true
    const isNonProfit = true

    return data ? (
      <div>
        <Dialog
          open={startSATMenuOpen}
          onClose={() => {
            this.setState({ startSATMenuOpen: false })
          }}
          // disableBackdropClick
        >
          <DialogTitle>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Trans>Begin a new S.A.T.</Trans>
              <IconButton
                onClick={() => {
                  this.setState({
                    startSATMenuOpen: false,
                    createdName: '',
                    selectedSurvey: null
                  })
                }}
                style={{ marginLeft: 10 }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid
              container
              direction='row'
              alignItems='stretch'
              justify='center'
            >
              {!oldSAT &&
                surveyCardData.map(cardData => (
                  <Grid item xs={6} style={{ padding: 5 }} key={cardData.value}>
                    <SurveyCard
                      title={cardData.title}
                      value={cardData.value}
                      image={cardData.image}
                      disabled={cardData.disabled({
                        isCoOp,
                        isNonProfit
                      })}
                      checked={selectedSurvey === cardData.value}
                      disabledText={cardData.disabledText}
                      onChange={value => {
                        this.setState({
                          selectedSurvey: value,
                          createdName: String(
                            myI18n._(value) +
                              ', ' +
                              moment.utc().format(dateFormat)
                          ).substring(0, 80)
                        })
                      }}
                    />
                  </Grid>
                ))}
              {oldSAT && (
                <Grid item xs={6} style={{ padding: 5 }}>
                  <SurveyCard
                    title={<Trans>S.A.T.</Trans>}
                    value='S.A.T.'
                    disabled={!isCoOp}
                    checked={selectedSurvey === 'S.A.T.'}
                    onChange={value => {
                      this.setState({
                        selectedSurvey: value
                      })
                    }}
                  />
                </Grid>
              )}
            </Grid>

            {/* {!isNonProfit && !isCoOp && ( */}
            <Typography style={{ margin: 10 }}>
              <Trans>
                If your organization is a non-profit that provides some housing
                services but is not the owner, please contact the Centre for
                information about our services. Thank you for your interest.{' '}
              </Trans>
            </Typography>
            {/* )} */}
            <div style={{ width: '100%', paddingTop: 10 }}>
              <TextField
                label={<Trans>SAT_NEW_ASSESMENT_NAME_INPUT</Trans>}
                disabled={!selectedSurvey}
                variant='outlined'
                fullWidth
                value={createdName || ''}
                helperText={
                  <span>
                    {countHelperText(createdName, 80)}
                    {'. '}
                  </span>
                }
                inputProps={{
                  maxLength: 80
                }}
                onChange={e => {
                  this.setState({
                    createdName: e.target.value
                  })
                }}
              />
            </div>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              disabled={!selectedSurvey || !createdName}
              style={{
                marginBottom: 15,
                marginTop: 15
              }}
              onClick={() => {
                this.handleAddSAT(selectedSurvey, createdName)
                this.setState({ startSATMenuOpen: false })
              }}
            >
              <Trans>Begin a new S.A.T. survey</Trans>
            </Button>
          </DialogContent>
        </Dialog>
        {texts.surveyIntro}
        <div style={{ padding: 20 }}>
          {!organization.id && (
            <Alert severity='warning' style={{ marginBottom: 15 }}>
              <Trans>
                Before accessing the Self-Assessment Tool, you must first
                associate your account with an organization.
              </Trans>{' '}
              <Link href='/grants/Organizations'>
                <Trans>You can connect to an organization here</Trans>
              </Link>
            </Alert>
          )}
          {roleNoPermission && organization.id && (
            <Card className='p-24 mb-24 bg-light-error box-shadow-none mt-24'>
              <Typography
                style={{
                  color: 'var(--text-muted) !important',
                  textAlign: 'center',
                  marginLeft: 10
                }}
              >
                <Trans>
                  You have to be a contributor or manager of this organization
                  to create a new self-assessment.
                </Trans>
              </Typography>
            </Card>
          )}
          <MUIDataTable
            title={
              <Grid container direction='row' alignItems='center'>
                <Typography style={{ fontSize: 21 }}>
                  <b>
                    <Trans>S.A.T. In progress</Trans>
                  </b>
                </Typography>
                <Button
                  disabled={roleNoPermission || !organization.id}
                  ref={this.anchorRef}
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  variant='contained'
                  color='primary'
                  style={{
                    marginLeft: 20
                  }}
                  onClick={() => {
                    this.setState({ startSATMenuOpen: true })
                  }}
                >
                  <Grid container direction='row' alignItems='center'>
                    <Typography>
                      <Trans>Begin a new S.A.T.</Trans>
                    </Typography>
                    <Icon>add</Icon>
                  </Grid>
                </Button>
              </Grid>
            }
            data={data.filter(item => item.surveyState === 'Saved')}
            columns={[
              {
                name: 'name',
                label: myI18n._(t`Assessment name`),
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div style={{ marginLeft: 20 }}>
                        <Trans>Assessment name</Trans>
                      </div>
                    )
                  },
                  sortCompare:
                    order =>
                      ({ data: val1 }, { data: val2 }) => {
                        const v = val1.name.localeCompare(val2.name)
                        return order === 'asc' ? v : v * -1
                      },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    const { id, locked, lockedByMe } = value
                    const noEditRights = Boolean(locked && !lockedByMe)
                    return (
                      <div style={{ flex: 1, marginLeft: 20, marginRight: 20 }}>
                        {value.id === editedName
                          ? (
                            <Grid container direction='row' wrap='nowrap'>
                              <Grid item style={{ width: 540 }}>
                                <TextField
                                  style={{ marginTop: 8, marginRight: 5 }}
                                  fullWidth
                                  value={newName}
                                  inputProps={{
                                    maxLength: 80
                                  }}
                                  onChange={e => {
                                    this.setState({
                                      newName: e.target.value
                                    })
                                  }}
                                />
                              </Grid>

                              <IconButton
                                onClick={() => {
                                  this.handleNameChanged(value.id, newName)
                                  this.setState({
                                    newName: null,
                                    editedName: null
                                  })
                                }}
                              >
                                <Icon>done</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    newName: null,
                                    editedName: null
                                  })
                                }}
                              >
                                <Icon>close</Icon>
                              </IconButton>
                            </Grid>
                            )
                          : (
                            <>
                              {value.name}
                              <Tooltip title={<Trans>Edit survey name</Trans>}>
                                <IconButton
                                  disabled={roleNoPermission}
                                  onClick={() => {
                                    this.setState({
                                      newName: value.name,
                                      editedName: id
                                    })
                                  }}
                                >
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Tooltip>
                            </>
                            )}
                      </div>
                    )
                  }
                }
              },
              {
                name: 'validity',
                label: myI18n._(t`Created Date`),
                options: {
                  sortCompare: muiDateCompare('createdDate'),
                  customHeadLabelRender: props => myI18n._(t`Created Date`),
                  customBodyRender: (value, tableMeta, updateValue) =>
                    moment(value.createdDate).format('YYYY/MM/DD')
                }
              },
              {
                name: 'editedBy',
                label: myI18n._(t`Edited by`),
                options: {
                  customHeadLabelRender: props => myI18n._(t`Edited by`),
                  customBodyRender: (value, tableMeta, updateValue) =>
                    value.editedBy
                      ? (
                        <span>
                          {value.editedBy}
                          {!value.editedByMe && (
                            <TooltipLabelIcon
                              tooltip={
                                <>
                                  <Trans>
                                    Request permission to edit this survey from
                                  </Trans>{' '}
                                  {value.editedBy}.
                                </>
                            }
                            />
                          )}
                        </span>
                        )
                      : (
                          '-'
                        )
                }
              },
              {
                name: 'template',
                options: {
                  sort: false,
                  customHeadLabelRender: props => null,
                  customBodyRender: value => {
                    const {
                      surveyId,
                      locked,
                      lockedBy,
                      lockedByMe,
                      lockedTooLong,
                      templateId
                    } = value
                    const noEditRights = Boolean(locked && !lockedByMe)

                    const handleError = () => {
                      this.setState({
                        unlocking: null
                      })
                      enqueueSnackbar(
                        <Trans>
                          Error ocurred while trying to unlock this survey!
                        </Trans>,
                        {
                          variant: 'error'
                        }
                      )
                    }

                    return roleNoPermission
                      ? (
                        <div />
                        )
                      : (
                        <div
                          style={{
                            justifyContent: 'flex-end',
                            display: 'flex',
                            marginRight: 20
                          }}
                        >
                          {noEditRights
                            ? (
                                lockedTooLong
                                  ? (
                                    <Tooltip
                                      title={
                                        <Trans>Unlock survey from {lockedBy}</Trans>
                              }
                                    >
                                      <IconButton
                                        disabled={
                                  unlocking === surveyId ||
                                  locking ||
                                  !organization.id
                                }
                                        onClick={e => {
                                          this.setState({
                                            unlocking: surveyId
                                          })
                                          const unlockingKey = enqueueSnackbar(null, {
                                            persist: true,
                                            variant: 'info',
                                            content: key =>
                                              ProgressSnackbar(
                                                <Trans>Unlocking survey</Trans>
                                              )
                                          })
                                          changeSurveyLockByFlow({
                                            surveyId,
                                            lockState: true,
                                            userId: user.userId,
                                            lockedTooLong
                                          }).then(
                                            output => {
                                              const { ownerInfo, result } = output
                                              if (result === 'RESULT_LOCKED') {
                                                this.fetchData().then(r => {
                                                  this.setState({
                                                    unlocking: null
                                                  })
                                                  closeSnackbar(unlockingKey)
                                                  enqueueSnackbar(
                                                    <Trans>Survey unlocked!</Trans>,
                                                    {
                                                      variant: 'success'
                                                    }
                                                  )
                                                })
                                              } else {
                                                closeSnackbar(unlockingKey)
                                                handleError()
                                              }
                                            },
                                            reject => {
                                              closeSnackbar(unlockingKey)
                                              handleError()
                                            }
                                          )
                                        }}
                                      >
                                        <Icon>lock_reset</Icon>
                                      </IconButton>
                                    </Tooltip>
                                    )
                                  : (
                                    <IconButton disabled>
                                      <Icon>lock</Icon>
                                    </IconButton>
                                    )
                              )
                            : (
                              <>
                                <Tooltip title={<Trans>Complete the survey</Trans>}>
                                  <IconButton
                                    disabled={
                                  roleNoPermission ||
                                  locking ||
                                  !organization.id
                                }
                                    onClick={() => {
                                      this.setState({
                                        locking: true
                                      })
                                      enqueueSnackbar(
                                        <Trans>Opening survey</Trans>,
                                        {
                                          variant: 'success'
                                        }
                                      )
                                      changeSurveyLockByFlow({
                                        surveyId,
                                        lockState: true,
                                        userId: user.userId
                                      }).then(
                                        output => {
                                          const { ownerInfo, result } = output
                                          if (result === 'RESULT_LOCKED') {
                                            history.push(
                                          `/surveys/SurveyTab/${templateId}/${surveyId}/${'edit'}`,
                                          {
                                            id: templateId,
                                            disabled: false,
                                            surveyId
                                          }
                                            )
                                          } else {
                                            this.setState({
                                              locking: false
                                            })
                                            enqueueSnackbar(
                                              <>
                                                <Trans>Can't open, in use by</Trans>{' '}
                                                {ownerInfo}
                                              </>,
                                              {
                                                variant: 'error'
                                              }
                                            )
                                            this.fetchData()
                                          }
                                        },
                                        reject => {
                                          handleError()
                                        }
                                      )
                                    }}
                                  >
                                    <Icon>description</Icon>
                                  </IconButton>
                                </Tooltip>
                                {lockedByMe && (
                                  <Tooltip title={<Trans>Unlock survey</Trans>}>
                                    <IconButton
                                      disabled={
                                    unlocking === surveyId ||
                                    locking ||
                                    !organization.id
                                  }
                                      onClick={e => {
                                        this.setState({
                                          unlocking: surveyId
                                        })
                                        const unlockingKey = enqueueSnackbar(null, {
                                          persist: true,
                                          variant: 'info',
                                          content: key =>
                                            ProgressSnackbar(
                                              <Trans>Unlocking survey</Trans>
                                            )
                                        })
                                        changeSurveyLockByFlow({
                                          surveyId,
                                          lockState: false,
                                          userId: user.userId
                                        }).then(
                                          output => {
                                            const { ownerInfo, result } = output
                                            if (result === 'RESULT_UNLOCKED') {
                                              this.fetchData().then(r => {
                                                this.setState({
                                                  unlocking: null
                                                })
                                                closeSnackbar(unlockingKey)
                                                enqueueSnackbar(
                                                  <Trans>Survey unlocked!</Trans>,
                                                  {
                                                    variant: 'success'
                                                  }
                                                )
                                              })
                                            } else {
                                              enqueueSnackbar(
                                                <>
                                                  <Trans>
                                                    Can't unlock, in use by
                                                  </Trans>{' '}
                                                  {ownerInfo}
                                                </>,
                                                {
                                                  variant: 'error'
                                                }
                                              )
                                              this.setState({
                                                unlocking: null
                                              })
                                              this.fetchData()
                                            }
                                          },
                                          reject => {
                                            this.setState({
                                              unlocking: null
                                            })
                                            enqueueSnackbar(
                                              <Trans>
                                                Error ocurred while trying to unlock
                                                this survey!
                                              </Trans>,
                                              {
                                                variant: 'error'
                                              }
                                            )
                                          }
                                        )
                                      }}
                                    >
                                      <Icon>lock_open</Icon>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                              )}
                        </div>
                        )
                  }
                }
              }
            ]}
            options={{
              onColumnSortChange: e => {
                if (!this.state.sorted) {
                  this.setState({
                    sorted: true
                  })
                }
              },
              sortOrder: this.state.sorted
                ? {}
                : {
                    name: 'validity',
                    direction: 'desc'
                  },
              textLabels: muiTextLabels(myI18n),
              filter: false,
              selectableRows: 'none',
              print: false,
              download: false,
              viewColumns: false
            }}
          />
        </div>
        <div style={{ padding: 20 }}>
          <MUIDataTable
            title={
              <Typography style={{ fontSize: 21 }}>
                <b>{texts.tableTitle}</b>
              </Typography>
            }
            data={data.filter(item => item.surveyState === 'Submitted')}
            columns={[
              {
                name: 'name',
                options: {
                  customHeadLabelRender: props => {
                    return (
                      <div style={{ marginLeft: 20 }}>
                        <Trans>Survey Name</Trans>
                      </div>
                    )
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <div style={{ flex: 1, marginLeft: 20 }}>
                        {/* {' '}
                      <Trans id={value}>{value}</Trans> */}
                        {value.name}
                      </div>
                    )
                  }
                }
              },
              {
                name: 'validity',
                label: myI18n._(t`Completed Date`),
                options: {
                  sortOrder: 'dsc',
                  sortCompare: muiDateCompare('modifiedDate'),
                  customHeadLabelRender: props => {
                    return myI18n._(t`Completed Date`)
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <div>
                        {moment(value.modifiedDate).format('YYYY/MM/DD')}
                      </div>
                    )
                  }
                }
              },
              {
                name: 'template',
                options: {
                  sort: false,
                  customHeadLabelRender: props => {
                    return null
                  },
                  customBodyRender: value => {
                    return (
                      <div
                        style={{
                          justifyContent: 'flex-end',
                          display: 'flex',
                          marginRight: 20
                        }}
                      >
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          justify='flex-end'
                        >
                          <Tooltip title={<Trans>View completed survey</Trans>}>
                            <IconButton
                              disabled={!organization.id}
                              onClick={() => {
                                history.push(
                                  `/surveys/SurveyTab/${value.templateId}/${
                                    value.surveyId
                                  }/${'view'}`,
                                  {
                                    id: value.templateId,
                                    disabled: true,
                                    surveyId: value.surveyId
                                  }
                                )
                              }}
                            >
                              <Icon>remove_red_eye</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Trans>Display survey score</Trans>}>
                            <IconButton
                              onClick={() => {
                                history.push(
                                  `/surveys/SurveyScore/${value.surveyId}/${value.templateId}`
                                )
                              }}
                            >
                              <Icon>bar_chart</Icon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </div>
                    )
                  }
                }
              }
            ]}
            options={{
              sortOrder: {
                name: 'validity',
                direction: 'desc'
              },
              textLabels: muiTextLabels(myI18n),
              filter: false,
              selectableRows: 'none',
              print: false,
              download: false,
              viewColumns: false
            }}
          />
        </div>
      </div>
    ) : (
      <Loading />
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export const ConfigurableSurveys = withSnackbar(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    withRouter(withStyles(styles)(AvaliableSurveys))
  )
)

export default props => (
  <ConfigurableSurveys
    satIds={SATsIdsList}
    texts={{
      creationSnackbarSuccess: <Trans>Created S.A.T.</Trans>,
      creationSnackbarError: <Trans>Error creating S.A.T.</Trans>,
      tableTitle: <Trans>S.A.T. Completed</Trans>,
      surveyIntro: <SATSurveyCard />
    }}
    surveyCardData={[
      {
        title: <Trans>S.A.T. - Co-ops</Trans>,
        value: 'S.A.T. - Co-ops',
        image: '/assets/images/self-assessment-icon.png',
        disabled: ({ isCoOp }) => !isCoOp,
        disabledText: (
          <Trans>
            Your organization must be of 'Co-op Housing' type to begin this type
            of S.A.T.
          </Trans>
        )
      },
      {
        title: <Trans>S.A.T. - OSBL</Trans>,
        value: 'S.A.T. - OSBL',
        image: '/assets/images/self-assessment-icon.png',
        disabled: ({ isCoOp, isNonProfit }) => !isNonProfit,
        disabledText: (
          <Trans>
            Your organization must be of 'Non-Profit Housing' or 'Non-profit
            Organization' type to begin this type of S.A.T.
          </Trans>
        )
      }
    ]}
    {...props}
  />
)

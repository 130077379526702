import { Grid } from '@material-ui/core'
import { animated, useSpring } from '@react-spring/web'
import React from 'react'

/**
 * Cursor icon that is used in multiuser editing to indicate current position of the editing user.
 * @category Multiuser
 * @component
 * @returns {JSX.Element}
 * @property {number} [width=25] Width of a cursor icon.
 * @property {number} [height=25] Height of a cursor icon.
 * @property {object} coordinates Current position of cursor on the page.
 * @property {number} coordinates.y Y coordinate of cursor. Measured in pixels from top side of the screen.
 * @property {number} coordinates.x X coordinate of cursor. Measured in pixels from left side of the screen.
 * @property {number} coordinates.yPercent Y coordinate of cursor in percentage of whole screen size.
 * @property {number} coordinates.xPercent X coordinate of cursor in percentage of whole screen size.
 * @property {color} color Color of the cursor icon.
 * @property {object} dimensions Dimensions of the page the cursor is rendered in.
 * @property {number} dimensions.width Width of the page.
 * @property {number} dimensions.height Height of the page.
 * @property {number} index If cursor is rendered in array - index of rendered element. Required to fix default margin being applied due to other cursors being rendered.
 * @property {string} name Name of the user cursor belongs to. It will be rendered at the bottom of the icon.
 */
function CursorIcon ({ coordinates, color, dimensions, name, ...props }) {
  const { width = 25, height = 25, index = 0 } = props
  const pageWidth = dimensions.width || 1
  const { xPercent, yPercent } = coordinates
  const x = xPercent * pageWidth
  const y = coordinates.y - index * height
  const animStyles = useSpring({
    left: x,
    top: y,
    config: {
      precision: 5,
      duration: 700,
      round: true
    }
  })

  return (
    <animated.div
      style={{
        ...animStyles,
        position: 'relative',
        pointerEvents: 'none',
        zIndex: 150
      }}
    >
      <Grid container>
        <CursorSVG width={width} height={height} color={color} />
        <span style={{ fontSize: 12, userSelect: 'none', color }}>
          {name}
        </span>
      </Grid>
    </animated.div>
  )
}

const CursorSVG = React.memo(({ width, height, color }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      color={color}
      fill={color}
      x='0px'
      y='0px'
      viewBox='1064.7701 445.5539 419.8101 717.0565'
      enable-background='new 1064.7701 445.5539 419.8101 717.0565'
    >
      <polygon
        points='1283.1857,1127.3097 1406.1421,1077.6322 1314.2406,850.1678 1463.913,852.7823 1093.4828,480.8547
1085.4374,1005.6964 1191.2842,899.8454 '
      />
    </svg>
  )
})

export default CursorIcon

// package: Mo_multiuser
// file: Multiuser.proto

const Multiuser_pb = require('./Multiuser_pb')
const google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb')
const grpc = require('@improbable-eng/grpc-web').grpc

const Multiuser = (function () {
  function Multiuser () {}
  Multiuser.serviceName = 'Mo_multiuser.Multiuser'
  return Multiuser
}())

Multiuser.UserHasLoggedInToRealm = {
  methodName: 'UserHasLoggedInToRealm',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserInfo
}

Multiuser.UserHasLoggedOutFromRealm = {
  methodName: 'UserHasLoggedOutFromRealm',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserInfo
}

Multiuser.UserUpdatedInfoAboutSelf = {
  methodName: 'UserUpdatedInfoAboutSelf',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserInfo
}

Multiuser.UserSessionEvent = {
  methodName: 'UserSessionEvent',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.Realm,
  responseType: Multiuser_pb.RealmSessionInfoEvent
}

Multiuser.UserChangedLockFieldStatus = {
  methodName: 'UserChangedLockFieldStatus',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.FieldLockOperation
}

Multiuser.UserCommitedFieldAsynchronously = {
  methodName: 'UserCommitedFieldAsynchronously',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.LockID
}

Multiuser.UserSubmitedDocumentCache = {
  methodName: 'UserSubmitedDocumentCache',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.DocumentCache
}

Multiuser.ServerRequestSubmitCache = {
  methodName: 'ServerRequestSubmitCache',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserID
}

Multiuser.UserChangedRealmMetadata = {
  methodName: 'UserChangedRealmMetadata',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.RealmMetadata
}

Multiuser.UserSentCursorEvent = {
  methodName: 'UserSentCursorEvent',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.CursorEventReceive
}

Multiuser.UserSentMultiuserEvent = {
  methodName: 'UserSentMultiuserEvent',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.MultiuserEventReceive
}

Multiuser.UserChangeFieldComment = {
  methodName: 'UserChangeFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.FieldCommentOperation
}

Multiuser.UserSentChatMessage = {
  methodName: 'UserSentChatMessage',
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.ChatMessage
}

Multiuser.GetUserInfo = {
  methodName: 'GetUserInfo',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.User,
  responseType: Multiuser_pb.UserInfo
}

Multiuser.GetUserNames = {
  methodName: 'GetUserNames',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.UsersList,
  responseType: Multiuser_pb.UserNamesMap
}

Multiuser.GetUserNamesViaThisUser = {
  methodName: 'GetUserNamesViaThisUser',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.User,
  responseType: Multiuser_pb.UserNamesMap
}

Multiuser.GetUsersCount = {
  methodName: 'GetUsersCount',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UsersCount
}

Multiuser.GetAllConnectedUsers = {
  methodName: 'GetAllConnectedUsers',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UsersList
}

Multiuser.GetAllConnectedUsersWithInfo = {
  methodName: 'GetAllConnectedUsersWithInfo',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.UserInfoList
}

Multiuser.IsRealmInited = {
  methodName: 'IsRealmInited',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Realm,
  responseType: Multiuser_pb.IsTrue
}

Multiuser.SetRealmMetadata = {
  methodName: 'SetRealmMetadata',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.RealmMetadata,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.GetRealmMetadata = {
  methodName: 'GetRealmMetadata',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.RealmMetadata
}

Multiuser.SetRealmMetadataOnTarget = {
  methodName: 'SetRealmMetadataOnTarget',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.RealmMetadataWithTarget,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.GetRealmMetadataFromTarget = {
  methodName: 'GetRealmMetadataFromTarget',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Realm,
  responseType: Multiuser_pb.RealmMetadata
}

Multiuser.InitRealm = {
  methodName: 'InitRealm',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.RealmInitRequest,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.LogIn = {
  methodName: 'LogIn',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.GlobalUserSessionRequest,
  responseType: Multiuser_pb.SessionStartResponde
}

Multiuser.LogOut = {
  methodName: 'LogOut',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.StartSession = {
  methodName: 'StartSession',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.UserSessionRequest,
  responseType: Multiuser_pb.SessionStartResponde
}

Multiuser.EndSession = {
  methodName: 'EndSession',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.UpdateUserInfo = {
  methodName: 'UpdateUserInfo',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.UserSessionRequest,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.Ping = {
  methodName: 'Ping',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.Time
}

Multiuser.GetUserNameByID = {
  methodName: 'GetUserNameByID',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.UserNameRequest,
  responseType: Multiuser_pb.UserName
}

Multiuser.GetCurrentSessions = {
  methodName: 'GetCurrentSessions',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Realm,
  responseType: Multiuser_pb.RealmSessionsInfos
}

Multiuser.StartReadonlySession = {
  methodName: 'StartReadonlySession',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.UserSessionRequest,
  responseType: Multiuser_pb.SessionStartResponde
}

Multiuser.IsFieldLocked = {
  methodName: 'IsFieldLocked',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Field,
  responseType: Multiuser_pb.IsTrue
}

Multiuser.IsLockCommited = {
  methodName: 'IsLockCommited',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.LockPointer,
  responseType: Multiuser_pb.LockStateType
}

Multiuser.GetUserLockingField = {
  methodName: 'GetUserLockingField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Field,
  responseType: Multiuser_pb.UserID
}

Multiuser.GetIDsOfLocksNotSubmitedToCache = {
  methodName: 'GetIDsOfLocksNotSubmitedToCache',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.LocksListLite
}

Multiuser.GetLocksNotSubmitedToCache = {
  methodName: 'GetLocksNotSubmitedToCache',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.LocksList
}

Multiuser.GetIDsOfLocksForField = {
  methodName: 'GetIDsOfLocksForField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Field,
  responseType: Multiuser_pb.LocksListLite
}

Multiuser.GetLocksForField = {
  methodName: 'GetLocksForField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Field,
  responseType: Multiuser_pb.LocksList
}

Multiuser.GetIDsOfLockedFields = {
  methodName: 'GetIDsOfLockedFields',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.LocksListLite
}

Multiuser.GetLockedFields = {
  methodName: 'GetLockedFields',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.LocksList
}

Multiuser.LockField = {
  methodName: 'LockField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Field,
  responseType: Multiuser_pb.LockID
}

Multiuser.UpdateLockedFieldValue = {
  methodName: 'UpdateLockedFieldValue',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldAndLockIDAndContent,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.CommitLockedField = {
  methodName: 'CommitLockedField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldAndLockIDAndContent,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.CancelLockedField = {
  methodName: 'CancelLockedField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldAndLockID,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.ReclaimLockedField = {
  methodName: 'ReclaimLockedField',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldAndLockID,
  responseType: Multiuser_pb.LockID
}

Multiuser.CommitFieldImmediately = {
  methodName: 'CommitFieldImmediately',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldAndFieldValue,
  responseType: Multiuser_pb.LockID
}

Multiuser.CreateAsynchronousLock = {
  methodName: 'CreateAsynchronousLock',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.AsyncLock,
  responseType: Multiuser_pb.LockID
}

Multiuser.MassiveCommitFieldsImmediately = {
  methodName: 'MassiveCommitFieldsImmediately',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldsAndValues,
  responseType: Multiuser_pb.FieldsAndLocks
}

Multiuser.GetListOfCachedDocumentVersions = {
  methodName: 'GetListOfCachedDocumentVersions',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.DocumentCacheListLite
}

Multiuser.GetCachedDocumentVersions = {
  methodName: 'GetCachedDocumentVersions',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.DocumentCacheList
}

Multiuser.GetDocumentCacheByID = {
  methodName: 'GetDocumentCacheByID',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.DocumentCacheID,
  responseType: Multiuser_pb.DocumentCache
}

Multiuser.GetNewestDocumentCache = {
  methodName: 'GetNewestDocumentCache',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: Multiuser_pb.DocumentCache
}

Multiuser.SubmitDocumentCache = {
  methodName: 'SubmitDocumentCache',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.DocumentToSubmit,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.SendCursorEvent = {
  methodName: 'SendCursorEvent',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.CursorEvent,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.SendMultiuserEvent = {
  methodName: 'SendMultiuserEvent',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.MultiuserEvent,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.GetFieldComment = {
  methodName: 'GetFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentID,
  responseType: Multiuser_pb.FieldComment
}

Multiuser.WhoIsLockingFieldComment = {
  methodName: 'WhoIsLockingFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentID,
  responseType: Multiuser_pb.UserID
}

Multiuser.LockFieldComment = {
  methodName: 'LockFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.UpdateFieldComment = {
  methodName: 'UpdateFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.CommitFieldComment = {
  methodName: 'CommitFieldComment',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.GetChatMessages = {
  methodName: 'GetChatMessages',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageCollectionRequest,
  responseType: Multiuser_pb.ChatMessageCollection
}

Multiuser.GetChatMessagesCount = {
  methodName: 'GetChatMessagesCount',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageCollectionRequest,
  responseType: Multiuser_pb.MessageCount
}

Multiuser.SendChatMessage = {
  methodName: 'SendChatMessage',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageSend,
  responseType: google_protobuf_empty_pb.Empty
}

Multiuser.ReturnError = {
  methodName: 'ReturnError',
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: google_protobuf_empty_pb.Empty
}

exports.Multiuser = Multiuser

function MultiuserClient (serviceHost, options) {
  this.serviceHost = serviceHost
  this.options = options || {}
}

MultiuserClient.prototype.userHasLoggedInToRealm = function userHasLoggedInToRealm (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserHasLoggedInToRealm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userHasLoggedOutFromRealm = function userHasLoggedOutFromRealm (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserHasLoggedOutFromRealm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userUpdatedInfoAboutSelf = function userUpdatedInfoAboutSelf (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserUpdatedInfoAboutSelf, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userSessionEvent = function userSessionEvent (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserSessionEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userChangedLockFieldStatus = function userChangedLockFieldStatus (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserChangedLockFieldStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userCommitedFieldAsynchronously = function userCommitedFieldAsynchronously (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserCommitedFieldAsynchronously, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userSubmitedDocumentCache = function userSubmitedDocumentCache (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserSubmitedDocumentCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.serverRequestSubmitCache = function serverRequestSubmitCache (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.ServerRequestSubmitCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userChangedRealmMetadata = function userChangedRealmMetadata (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserChangedRealmMetadata, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userSentCursorEvent = function userSentCursorEvent (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserSentCursorEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userSentMultiuserEvent = function userSentMultiuserEvent (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserSentMultiuserEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userChangeFieldComment = function userChangeFieldComment (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserChangeFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.userSentChatMessage = function userSentChatMessage (requestMessage, metadata) {
  let listeners = {
    data: [],
    end: [],
    status: []
  }
  const client = grpc.invoke(Multiuser.UserSentChatMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    }
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUserInfo = function getUserInfo (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUserNames = function getUserNames (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUserNames, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUserNamesViaThisUser = function getUserNamesViaThisUser (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUserNamesViaThisUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUsersCount = function getUsersCount (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUsersCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getAllConnectedUsers = function getAllConnectedUsers (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetAllConnectedUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getAllConnectedUsersWithInfo = function getAllConnectedUsersWithInfo (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetAllConnectedUsersWithInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.isRealmInited = function isRealmInited (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.IsRealmInited, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.setRealmMetadata = function setRealmMetadata (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SetRealmMetadata, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getRealmMetadata = function getRealmMetadata (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetRealmMetadata, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.setRealmMetadataOnTarget = function setRealmMetadataOnTarget (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SetRealmMetadataOnTarget, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getRealmMetadataFromTarget = function getRealmMetadataFromTarget (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetRealmMetadataFromTarget, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.initRealm = function initRealm (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.InitRealm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.logIn = function logIn (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.LogIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.logOut = function logOut (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.LogOut, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.startSession = function startSession (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.StartSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.endSession = function endSession (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.EndSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.updateUserInfo = function updateUserInfo (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.UpdateUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.ping = function ping (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.Ping, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUserNameByID = function getUserNameByID (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUserNameByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getCurrentSessions = function getCurrentSessions (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetCurrentSessions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.startReadonlySession = function startReadonlySession (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.StartReadonlySession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.isFieldLocked = function isFieldLocked (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.IsFieldLocked, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.isLockCommited = function isLockCommited (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.IsLockCommited, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getUserLockingField = function getUserLockingField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetUserLockingField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getIDsOfLocksNotSubmitedToCache = function getIDsOfLocksNotSubmitedToCache (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetIDsOfLocksNotSubmitedToCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getLocksNotSubmitedToCache = function getLocksNotSubmitedToCache (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetLocksNotSubmitedToCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getIDsOfLocksForField = function getIDsOfLocksForField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetIDsOfLocksForField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getLocksForField = function getLocksForField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetLocksForField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getIDsOfLockedFields = function getIDsOfLockedFields (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetIDsOfLockedFields, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getLockedFields = function getLockedFields (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetLockedFields, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.lockField = function lockField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.LockField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.updateLockedFieldValue = function updateLockedFieldValue (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.UpdateLockedFieldValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.commitLockedField = function commitLockedField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.CommitLockedField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.cancelLockedField = function cancelLockedField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.CancelLockedField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.reclaimLockedField = function reclaimLockedField (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.ReclaimLockedField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.commitFieldImmediately = function commitFieldImmediately (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.CommitFieldImmediately, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.createAsynchronousLock = function createAsynchronousLock (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.CreateAsynchronousLock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.massiveCommitFieldsImmediately = function massiveCommitFieldsImmediately (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.MassiveCommitFieldsImmediately, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getListOfCachedDocumentVersions = function getListOfCachedDocumentVersions (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetListOfCachedDocumentVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getCachedDocumentVersions = function getCachedDocumentVersions (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetCachedDocumentVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getDocumentCacheByID = function getDocumentCacheByID (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetDocumentCacheByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getNewestDocumentCache = function getNewestDocumentCache (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetNewestDocumentCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.submitDocumentCache = function submitDocumentCache (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SubmitDocumentCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.sendCursorEvent = function sendCursorEvent (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SendCursorEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.sendMultiuserEvent = function sendMultiuserEvent (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SendMultiuserEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getFieldComment = function getFieldComment (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.whoIsLockingFieldComment = function whoIsLockingFieldComment (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.WhoIsLockingFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.lockFieldComment = function lockFieldComment (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.LockFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.updateFieldComment = function updateFieldComment (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.UpdateFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.commitFieldComment = function commitFieldComment (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.CommitFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getChatMessages = function getChatMessages (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetChatMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.getChatMessagesCount = function getChatMessagesCount (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.GetChatMessagesCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.sendChatMessage = function sendChatMessage (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.SendChatMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

MultiuserClient.prototype.returnError = function returnError (requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Multiuser.ReturnError, {
    request: requestMessage,
    host: this.serviceHost,
    metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    }
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    }
  }
}

exports.MultiuserClient = MultiuserClient

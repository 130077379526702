import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm, reverseMap } from '../sfDataService'
import { readOnlyFieldMTF } from './jsConnectionUtils'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Name: 'name',
  Primary_activities_progress__c: 'implementedActivities',
  Primary_activities_planned__c: 'primaryActivities',
  CreatedDate: readOnlyFieldMTF('CreatedDate', 'createdDate'),
  CreatedById: readOnlyFieldMTF('CreatedById', 'createdById'),
  LastModifiedById: readOnlyFieldMTF('LastModifiedById', 'lastModifiedById'),
  Grantee_Report__c: 'granteeReportId',
  Phase_start_date__c: 'phaseStartDate',
  Phase_end_date__c: 'phaseEndDate',
  Benchmark__c: 'benchmark'
}

const reverseMapFields = reverseMap(mapFields)
export const parseMilestoneUpdateSFObject = obj => mapSFToForm(mapFields, obj)

export const createMilestoneUpdate = (obj) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Benchmark_Update__c').create(obj)
}

export const updateMilestoneUpdate = (obj) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Benchmark_Update__c').update(obj)
}

export const getMilestoneUpdatesForReport = reportId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Benchmark_Update__c')
    .find({
      Grantee_Report__c: reportId
    })
}

import { purgeInvalidPDFSignFromString } from 'app/views/forms/pdf-components/formPdfUtils'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm } from '../sfDataService'
import { readOnlyFieldMTF } from './jsConnectionUtils'

const mapFields = {
  direction: 'in',
  Id: 'id',
  Outcomes__c: {
    key: 'outcomes',
    in: obj => obj.Outcomes__c
      ? purgeInvalidPDFSignFromString(obj.Outcomes__c).split(';')
      : [],
    out: (obj) => obj.outcomes.join(';')
  },
  Identified_need_changes__c: {
    key: 'identifiedNeedChanges',
    in: obj => purgeInvalidPDFSignFromString(obj.Identified_need_changes__c),
    out: (obj) => obj.identifiedNeedChanges
  },
  Objective__c: 'objectiveId',
  Actual_outcomes__c: 'actualOutcomes',
  Desired_outcome_changes__c: 'desiredOutcomeChanges',
  CreatedDate: readOnlyFieldMTF('CreatedDate', 'createdDate')
}

export const parseObjectiveUpdateSFObject = obj => mapSFToForm(mapFields, obj)

/**
 * Creates a new objective update in Salesforce.
 * @function
 * @category Salesforce - Objective_Update__c
 * @param {object} obj - The object representing the objective update to create.
 * @returns {JSForceResult}
 */
export const createObjectiveUpdate = (obj: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Objective_Update__c').create(obj)
}

/**
 * Updates an existing objective update in Salesforce.
 * @function
 * @category Salesforce - Objective_Update__c
 * @param {object} obj The object representing the updated data for the objective update.
 * @returns {JSForceResult}
 */
export const updateObjectiveUpdate = (obj: object) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Objective_Update__c').update(obj)
}

import { formComponentTypes } from '../../formComponentTypes'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { FormExcelFileImport } from './FormExcelFileImport'
import { FormEditorExcelFileImportConnectToObject } from './FormEditorExcelFileImportConnectToObject'

export const FormEditorExcelFileImport = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  ...props
}) => {
  typeProps = typeProps || {}

  if (!editMode) {
    return (
      <FormExcelFileImport editMode id={id} typeProps={typeProps} {...props} />
    )
  }

  return (
    <FormEditorExcelFileImportConnectToObject
      injectable={injectable}
      injectableId={injectableId}
      typeProps={typeProps}
      depth={depth}
      noField
      objectsConnections={
        formComponentTypes.excelFileImport.connectsToMultipleObjects
      }
    />
  )
}

import { ICCEBudget } from './FormICCEBudget'
import { Trans } from '@lingui/macro'

export const FormICCEBudgetPrintEditor = props => {
  const year = <Trans>Year Placeholder</Trans>
  const budgetLines = [
    {
      category: <Trans>Category Placeholder</Trans>,
      description: <Trans>Description Placeholder</Trans>,
      totalCost: <Trans>$ Total Requested to ICCE Placeholder</Trans>,
      otherDonors: <Trans>$ Other Donors Placeholder</Trans>
    }
  ]

  return (
    <>
      <div className='form-print-title-small'>{props.title}</div>
      <ICCEBudget year={year} budgetLines={budgetLines} isPrintEditor />
    </>
  )
}

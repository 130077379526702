import { checkIfOrganizationsAreStale } from 'app/services/sfAuth/sfData/sfAccount'
import {
  UPDATED_CATALOG
} from '../actions/I18nActions'

const initialState = {}

const i18nReducer = function (state = initialState, action) {
  switch (action.type) {
    case UPDATED_CATALOG: {
      return {
        ...state,
        [action.lang]: action.data
      }
    }
    default: {
      return state
    }
  }
}

export default i18nReducer

import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { PdfPropsForm } from 'app/views/forms/common/PdfPropsForm'
import { useDispatch } from 'react-redux'
import { formComponentTypes } from '../../formComponentTypes'
import { FormMilestoneUpdate } from './FormMilestoneUpdate'

export const FormEditorMilestoneUpdate = ({
  id,
  showPrintProps,
  showPdfProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  ...props
}) => {
  const dispatch = useDispatch()
  const _typeProps = typeProps || {}
  if (!editMode) {
    return (
      <FormMilestoneUpdate editMode id={id} typeProps={_typeProps} {...props} />
    )
  }

  return (
    <div>
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={_typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.milestoneUpdate.connectsToMultipleObjects
        }
      />
    </div>
  )
}

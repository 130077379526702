import { Trans } from '@lingui/macro'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { Alert } from '@material-ui/lab'
import { CurrencyFormated } from 'app/views/common/Formats'
import { sanitizeLineKey } from './FormBudget'

export const FormBudgetReadOnly = ({ title, value, typeProps }) => {
  const { expensesLines = [], revenuesLines = [] } = typeProps

  if (Object.keys(value.revenues).length === 0) {
    return (
      <>
        <div className='form-print-title'>{title}</div>
        <Alert severity='info'>
          <Trans>There are no lines added to budget of this application</Trans>
        </Alert>
      </>
    )
  }

  return (
    <>
      <div className='form-print-title'>{title}</div>
      {Object.keys(value.revenues).map(key => {
        const revenuesData = value.revenues[key] || {}
        const revenuesRender = revenuesLines.map(key => ({
          key,
          ...revenuesData[key]
        }))

        const expensesData = value.expenses[key] || {}
        const expensesRender = expensesLines.map(key => ({
          key: sanitizeLineKey(key),
          ...expensesData[sanitizeLineKey(key)]
        }))

        return (
          <div key={key}>
            <div style={{ padding: 8, textAlign: 'center' }}>
              <Typography className='form-print-subtitle'>
                <Trans>Year</Trans>
              </Typography>
              <Typography style={{ fontSize: 21 }}>{key}</Typography>
            </div>

            <TableContainer component={Paper} style={{ padding: '0 10px' }}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '300px' }}>
                      <Typography className='form-print-subtitle'>
                        <Trans>Revenue</Trans>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '170px' }}>
                      <Typography className='form-print-subtitle'>
                        <Trans>Value</Trans>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className='form-print-subtitle'>
                        <Trans>Comment</Trans>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revenuesRender.map((obj, index) => (
                    <TableRow key={index} className='form-table-row'>
                      <TableCell style={{ width: '300px' }}>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          <Trans id={obj.key} />
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: '170px' }}>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          <CurrencyFormated value={obj.value} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          {obj.comment}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} style={{ marginTop: '10px', padding: '0 10px' }}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '300px' }}>
                      <Typography className='form-print-subtitle'>
                        <Trans>Expense</Trans>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '170px' }}>
                      <Typography className='form-print-subtitle'>
                        <Trans>Value</Trans>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className='form-print-subtitle'>
                        <Trans>Comment</Trans>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expensesRender.map((obj, index) => (
                    <TableRow key={index} className='form-table-row'>
                      <TableCell style={{ width: '300px' }}>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          <Trans id={obj.key} />
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: '170px' }}>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          <CurrencyFormated value={obj.value} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ whiteSpace: 'pre-line', minHeight: 21 }}>
                          {obj.comment}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      })}
    </>
  )
}

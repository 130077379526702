import { Trans } from '@lingui/macro'
import { Button, Dialog, DialogTitle } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { authRoles, hasRole } from './auth/authRoles'
import { setUserData } from './redux/actions/UserActions'
import sfOauthConfig from './services/sfAuth/sfAuthConfig'
import { saveUser } from './services/sfAuth/sfData/sfUser'

export default function MissingUserInformationPopUp () {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const [showPopUp, setShowPopUp] = useState(false)
  const simulateInternal = useSelector(state =>
    state?.testingOptions?.simulateInternal
  )
  const isInternal = simulateInternal || sfOauthConfig.isInternal

  useLayoutEffect(() => {
    if (
      user &&
      user.hasOwnProperty('isUserProfileValid') &&
      hasRole(user.role, authRoles.grantee) &&
      !isInternal
    ) {
      if (!user.isUserProfileValid && !user.userObject.redirectedToProfile) {
        // setShowPopUp(true)
        history.push('/grants/user-profile')
        saveUser({
          Id: user.userId,
          Redirected_To_Profile__c: true
        }).then(result => {
          const newUser = { ...user }
          user.userObject.redirectedToProfile = true
          dispatch(setUserData(newUser))
        })
      } else if (
        user.isUserProfileValid &&
        !user.userObject.redirectedToProfile
      ) {
        saveUser({
          Id: user.userId,
          Redirected_To_Profile__c: true
        }).then(result => {
          const newUser = { ...user }
          user.userObject.redirectedToProfile = true
          dispatch(setUserData(newUser))
        })
      }
    }
  }, [user])

  if (!showPopUp) {
    return null
  }

  return (
    <Dialog open={showPopUp}>
      <DialogTitle style={{ textAlign: 'center', padding: 0 }}>
        <Alert severity='error' variant='outlined' style={{ fontSize: 17 }}>
          <Trans>
            Your user profile is incomplete. Please, fill out the missing
            information
          </Trans>
          <div style={{ alignItems: 'center', marginTop: 20 }}>
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                setShowPopUp(false)
                saveUser({
                  Id: user.userId,
                  Redirected_To_Profile__c: true
                })
                // history.push('/grants/ApplicantInformation')
              }}
            >
              <Trans>Ok</Trans>
            </Button>
            {/* <Button
              color='primary'
              variant='contained'
              onClick={e => {
                setShowPopUp(false)
                history.push('/grants/ApplicantInformation')
              }}
            >
              <Trans>Go to user profile</Trans>
            </Button> */}
          </div>
        </Alert>
      </DialogTitle>
    </Dialog>
  )
}

import { Trans, t } from '@lingui/macro'
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  setAvaliableOrganizations,
  setOrganization
} from 'app/redux/actions/OrganizationActions'
import {
  createOrganizationByFlow,
  getAccountByNameByFlow,
  getAccountParsedById,
  searchForAccountByFlow
} from 'app/services/sfAuth/sfData/sfAccount'
import { saveUser } from 'app/services/sfAuth/sfData/sfUser'
import classnames from 'classnames'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { requiredTrans } from '../forms/formTranslations'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const FindAccountButton = ({
  onSelect,
  onDialogClose,
  onDialogOpen,
  disabled,
  label,
  afterCreate,
  disableCreation,
  hidePhoneSearch = false,
  hideWebsiteSearch = false,
  allowCreation = false
}) => {
  const [dialogOpen, openDialog] = React.useState(false)
  const [searching, setSearching] = React.useState(false)
  const [creating, setCreating] = React.useState(false)
  const [searchResult, setSearchResult] = React.useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const organization = useSelector(state => state.organization)
  const avaliableOrganizations = useSelector(
    state => state.avaliableOrganizations
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setSearching(false)
    setSearchResult(null)
  }, [dialogOpen])

  const createOrganizationPromise = ({ name, email, key }) => {
    createOrganizationByFlow(user.userObject, {
      displayName: user.displayName,
      username: user.userInfo.username,
      email,
      organizationName: name
    })
      .then(orgResult => {
        if (orgResult === organization.id) {
          enqueueSnackbar(
            <Trans>An organization for this user already exits!</Trans>,
            {
              variant: 'error'
            }
          )
        } else {
          enqueueSnackbar(<Trans>New organization created!</Trans>, {
            variant: 'success'
          })
        }

        return Promise.all([
          getAccountParsedById(orgResult, {
            permissions: { TEAM_MEMBERS: true }
          }),
          saveUser({
            Id: user.userId,
            Associated_Organizations__c: orgResult
          })
        ]).then(([org]) => {
          const handleCreated = () => {
            setCreating(false)
            openDialog(false)
            if (onDialogClose) {
              onDialogClose()
            }
            dispatch(
              setAvaliableOrganizations([
                ...avaliableOrganizations,
                { id: org.id, name: org.organisationsName }
              ])
            )
            dispatch(setOrganization({ ...org }))
            closeSnackbar(key)
          }
          if (afterCreate) {
            afterCreate({
              Id: org.id
            }).then(r => {
              handleCreated()
            })
          } else {
            handleCreated()
          }
        })
      })
      .catch(error => {
        closeSnackbar(key)
        setCreating(false)
        console.error('could not create org', error)
        if (error.message === 'DUPLICATES_DETECTED') {
          enqueueSnackbar(
            <Trans>
              A organization for this user exits - try searching with your first
              and last name in name of organization{' '}
            </Trans>,
            {
              variant: 'error'
            }
          )
        } else if (error.message.includes('Email:')) {
          enqueueSnackbar(<Trans>Provided email adress is invalid!</Trans>, {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(<Trans>Error Contact Administrator</Trans>, {
            variant: 'error'
          })
        }
      })
  }

  return (
    <>
      <Dialog open={dialogOpen} maxWidth='md' fullWidth>
        <DialogTitle>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Trans>Find account</Trans>
            <IconButton
              onClick={e => {
                openDialog(false)
                if (onDialogClose) {
                  onDialogClose(e)
                }
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography
            style={{ padding: 20, textAlign: 'justify', marginBottom: 10 }}
          >
            <Trans>
              Search for your organization in our database using the
              organization name, phone number, primary email, or website.
            </Trans>
          </Typography>
          <Formik
            initialValues={{ name: '', website: '', email: '', phone: '' }}
            validationSchema={Yup.object().shape({
              newName: Yup.string().required(requiredTrans),
              newEmail: Yup.string()
                .required(requiredTrans)
                .email(<Trans>This must be a valid email</Trans>)
            })}
          >
            {({ values, setValues, isValid }) => {
              const handleAccountSearch = values => {
                setSearching(true)
                setSearchResult(null)
                searchForAccountByFlow({
                  ...values
                }).then(
                  result => {
                    setSearching(false)
                    setSearchResult(result)
                  },
                  reject => {
                    console.error(reject)
                    setSearching(false)
                    setSearchResult(
                      'Too many records found! Try to be more specific in your search'
                    )
                  }
                )
              }

              const { newName, newEmail } = values
              return (
                <Grid container direction='column' style={{ marginBottom: 20 }}>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <FormikTextField
                      variant='outlined'
                      label={<Trans>Organization's name</Trans>}
                      name='name'
                      style={{ width: '75%' }}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginLeft: 10 }}
                      disabled={creating || searching}
                      onClick={() => {
                        handleAccountSearch({ name: values.name.trim() })
                      }}
                    >
                      <Typography>
                        <Trans>Search</Trans>
                      </Typography>
                    </Button>
                  </Grid>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                      <Trans>Or</Trans>
                    </Typography>
                  </div>

                  {!hideWebsiteSearch && (
                    <>
                      <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='center'
                      >
                        <FormikTextField
                          variant='outlined'
                          label={<Trans>Organization's Website</Trans>}
                          name='website'
                          style={{ width: '75%' }}
                        />
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={creating || searching}
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            handleAccountSearch({
                              website: values.website.trim()
                            })
                          }}
                        >
                          <Typography>
                            <Trans>Search</Trans>
                          </Typography>
                        </Button>
                      </Grid>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                          <Trans>Or</Trans>
                        </Typography>
                      </div>
                    </>
                  )}

                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <FormikTextField
                      variant='outlined'
                      label={<Trans>Organization's E-mail</Trans>}
                      name='email'
                      style={{ width: '75%' }}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginLeft: 10 }}
                      disabled={creating || searching}
                      onClick={() => {
                        handleAccountSearch({ email: values.email.trim() })
                      }}
                    >
                      <Typography>
                        <Trans>Search</Trans>
                      </Typography>
                    </Button>
                  </Grid>

                  {!hidePhoneSearch && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                          <Trans>Or</Trans>
                        </Typography>
                      </div>
                      <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='center'
                      >
                        <FormikTextField
                          type='tel'
                          variant='outlined'
                          label={<Trans>Organization's Phone Number</Trans>}
                          name='phone'
                          style={{ width: '75%' }}
                          InputProps={{ inputComponent: PhoneFormat }}
                        />
                        <Button
                          variant='contained'
                          color='primary'
                          style={{ marginLeft: 10 }}
                          disabled={creating || searching}
                          onClick={() => {
                            handleAccountSearch({ phone: values.phone.trim() })
                          }}
                        >
                          <Typography>
                            <Trans>Search</Trans>
                          </Typography>
                        </Button>
                      </Grid>
                    </>
                  )}

                  {searching && (
                    <div style={{ marginTop: 15, height: 100 }}>
                      <Loading isNotFixed />
                    </div>
                  )}
                  {searchResult && (
                    <div>
                      <List>
                        {typeof searchResult === 'string'
                          ? (
                            <>
                              <Typography
                                style={{ color: 'red', textAlign: 'center' }}
                              >
                                {myI18n._(t`${searchResult}`)}
                              </Typography>
                              {Boolean(
                                allowCreation &&
                                searchResult === 'No organisation found!'
                              ) && (
                                <div style={{ marginTop: 20 }}>
                                  <span style={{ padding: 15, marginBottom: 10 }}>
                                    <Trans>
                                      Can't find desired organization? Maybe you
                                      want to create a new one?
                                    </Trans>
                                  </span>
                                  <div style={{ padding: 10 }}>
                                    <FormikTextField
                                      variant='outlined'
                                      label={
                                        <Trans>New Organization's Name</Trans>
                                    }
                                      name='newName'
                                      fullWidth
                                    />
                                  </div>
                                  <div style={{ padding: 10 }}>
                                    <FormikTextField
                                      variant='outlined'
                                      label={
                                        <Trans>New Organization's E-mail</Trans>
                                    }
                                      name='newEmail'
                                      fullWidth
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      disabled={
                                      creating || disableCreation || !isValid
                                    }
                                      onClick={() => {
                                        const progressKey = enqueueSnackbar(
                                          null,
                                          {
                                            persist: true,
                                            content: key =>
                                              ProgressSnackbar(
                                                <Trans>
                                                  Creating new organisation
                                                </Trans>
                                              )
                                          }
                                        )
                                        setCreating(true)
                                        getAccountByNameByFlow(newName).then(
                                          results => {
                                            if (results.length === 0) {
                                              createOrganizationPromise({
                                                name: newName,
                                                email: newEmail,
                                                key: progressKey
                                              })
                                            } else {
                                              closeSnackbar(progressKey)
                                              enqueueSnackbar(
                                                <Trans>
                                                  Organization with this name
                                                  already exists!
                                                </Trans>,
                                                {
                                                  variant: 'error'
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }}
                                    >
                                      <Trans>Create new organization</Trans>
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </>
                            )
                          : typeof searchResult === 'object'
                            ? (
                                searchResult.map((item, index) => (
                                  <SearchResult
                                    key={index}
                                    item={item}
                                    onSelect={props => {
                                      openDialog(false)
                                      onSelect(props)
                                    }}
                                  />
                                ))
                              )
                            : null}
                      </List>
                    </div>
                  )}
                </Grid>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button
        disabled={disabled}
        color='primary'
        variant='contained'
        onClick={e => {
          openDialog(true)
          if (onDialogOpen) {
            onDialogOpen(e)
          }
        }}
      >
        {label || <Trans>Find organization</Trans>}
      </Button>
    </>
  )
}

const SearchResult = props => {
  const { item, key, onSelect } = props
  const [expanded, setExpanded] = React.useState(true)
  const classes = useStyles()

  return (
    <ListItem key={key}>
      <Paper elevation={6} style={{ width: '100%' }}>
        <Button
          style={{ flexGrow: 1, width: '100%' }}
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          <Grid container direction='column'>
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='space-between'
            >
              <Typography style={{ margin: 10, fontSize: '16px' }}>
                {item.Name}
              </Typography>
              <Icon
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
              >
                expand_more
              </Icon>
            </Grid>
            <Collapse in={expanded}>
              <List>
                <ListItem>
                  <Typography>
                    <b>
                      <Trans>Name: </Trans>
                    </b>{' '}
                    {item.Name}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>
                      <Trans>E-mail: </Trans>
                    </b>{' '}
                    {item.Email__c}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>
                      <Trans>Website: </Trans>
                    </b>{' '}
                    {item.Website}
                  </Typography>
                </ListItem>
              </List>

              <Grid container justify='center' style={{ paddingBottom: 20 }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={e => {
                    e.stopPropagation()
                    onSelect(item)
                  }}
                >
                  <Trans>Select this organisation</Trans>
                </Button>
              </Grid>
            </Collapse>
          </Grid>
        </Button>
      </Paper>
    </ListItem>
  )
}

function PhoneFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      format='(+1) ###-###-####'
      mask='_'
      isNumericString
      type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export default FindAccountButton

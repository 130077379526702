import { t, Trans } from '@lingui/macro'
import { Checkbox } from '@material-ui/core'

export const FormBooleanPdfEditor = ({
  value = [],
  title,
  ...props
}) => {
  const connectedValues = props?.typeProps?.connectedTo?.[0]

  const connectedObjectId = connectedValues?.connectedObject
  const connectedObject = props?.objectsConnected?.find(obj => obj.identId === connectedObjectId)
  const connectedFieldName = connectedValues?.connectedField?.name
  const objName = connectedObject?.name

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Checkbox />
      {connectedFieldName && <div> for <i>{connectedFieldName}</i> from <i>{objName}</i> </div>}
      <div style={{ fontSize: 14, marginLeft: connectedFieldName ? 15 : 0 }}><Trans>FORM_BOOLEAN_TITLE_PLACEOLDER</Trans></div>
    </div>
  )
}

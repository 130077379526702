import SFAuthService, { NO_USER } from '..//SFAuthService'

import { mapSFToForm } from '../sfDataService'

export const mapFields = {
  direction: 'in',
  Id: 'id',
  Name: 'name',
  Full_name__r: {
    key: 'fullName',
    in: program => {
      return program.Full_name__r
        ? {
            name: program.Full_name__r.Name || '',
            translationId: program.Full_name__r.Translation_Id__c || '',
            en: program.Full_name__r.Translated_EN__c || '',
            fr: program.Full_name__r.Translated_FR__c || '',
            'iu-cans': program.Full_name__r.Translated_IU_Cans__c || '',
            'iu-latn': program.Full_name__r.Translated_IU_Latn__c || ''
          }
        : null
    },
    out: res => {
      return {
        Translation_Id__c: res.fullName.translationId || '',
        Translated_EN__c: res.fullName.en || '',
        Translated_FR__c: res.fullName.fr || '',
        Translated_IU_Cans__c: res.fullName['iu-cans'] || '',
        Translated_IU_Latn__c: res.fullName['iu-latn'] || ''
      }
    }
  }
}

export const parseFundingProgram = obj => mapSFToForm(mapFields, obj)

export const fetchFundingPrograms = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Funding_Program__c').find({})
}

export const fetchFundingProgramsParsed = () => {
  const conn = SFAuthService.getConnection()
  conn.queryMore()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Funding_Program__c')
    .find({})
    .select('Id, Full_name__r.*, Name')
    .then(result => result.map(obj => mapSFToForm(mapFields, obj)))
}

import { Trans } from '@lingui/macro'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import moment from 'moment'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'

const PreviouslyAnsweredField = ({
  setFieldValue,
  completedSurveys,
  item,
  version
}) => {
  const checkValue = ({ completedSurveys }) => {
    let lastValue = null
    let display = null
    let questionChanged = false
    for (let index = 0; index < completedSurveys.length; index++) {
      const survey = completedSurveys[index]
      if (survey) {
        let savedValue = survey[item.id]
        if (!savedValue) {
          item.otherTemplatesIDs.forEach(id => {
            if (survey[id]) {
              savedValue = survey[id]
            }
          })
        }
        if (typeof savedValue === 'object' && savedValue && !item.isGroup) {
          let atLeastOneFilled = false
          const questions = []
          savedValue.forEach((opt, optIndex) => {
            if (opt) {
              atLeastOneFilled = true
              if (!item.options[optIndex]) {
                questionChanged = true
                return
              }
              questions.push(
                <Typography key={optIndex}>
                  <b>{item.options[optIndex].text}</b>
                </Typography>
              )
            }
          })
          if (atLeastOneFilled) {
            lastValue = savedValue
            display = <div>{questions}</div>
            break
          }
        } else if (
          typeof savedValue === 'object' &&
          savedValue &&
          item.isGroup
        ) {
          let atLeastOneFilled = false
          const questions = []
          savedValue.forEach((opt, optIndex) => {
            if (opt) {
              const questionIndex = optIndex
              const optionIndex = opt.split('_')[1]
              const savedOption =
                optionIndex || optionIndex === 0
                  ? item.options[optionIndex].text
                  : opt
              atLeastOneFilled = true
              if (item.groupQuestions[questionIndex]) {
                questions.push(
                  <Typography key={questionIndex}>
                    <b>
                      {getLabelFromTranslationData({
                        langVersion: version,
                        data: item.groupQuestions[questionIndex].titleValue
                      })}
                    </b>{' '}
                    {savedOption}
                  </Typography>
                )
              }
            }
          })
          if (atLeastOneFilled) {
            lastValue = savedValue
            display = <div>{questions}</div>
            break
          }
        } else if (savedValue) {
          let text
          text =
            item.options.length > 0
              ? (item.options[savedValue.split('_')[1]] ? item.options[savedValue.split('_')[1]].text : null)
              : savedValue
          if (!text) {
            text = ''
          }
          if (moment(savedValue, 'YYYY-MM-DD', true).isValid()) {
            text = moment(savedValue).format('YYYY-MM-DD')
          }
          display = (
            <Typography>
              <b>{text.id ? <Trans id={text.id} /> : text}</b>
            </Typography>
          )
          lastValue = savedValue
          break
        }
      }
    }
    if (questionChanged) {
      return { lastValue: null, display: null }
    }
    return { lastValue, display }
  }

  const checkedValues = useMemo(() => checkValue({ completedSurveys }), [
    completedSurveys,
    item.sfId
  ])

  return (
    checkedValues.lastValue &&
    checkedValues.display && (
      <Paper style={{ padding: 15 }}>
        <Typography style={{ textAlign: 'center' }}>
          <Trans>Your last answer to this question was:</Trans>
        </Typography>
        <Grid container direction='column' alignItems='center'>
          {checkedValues.display}
          <Button
            color='primary'
            style={{ marginTop: 10 }}
            // disabled={disabled}
            variant='contained'
            onClick={() => {
              setFieldValue(`${item.id}`, checkedValues.lastValue)
            }}
          >
            <Typography style={{ textAlign: 'center' }}>
              <Trans>Fill with previous answer</Trans>
            </Typography>
          </Button>
        </Grid>
      </Paper>
    )
  )
}

export default React.memo(PreviouslyAnsweredField)

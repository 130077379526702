import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfDefaultFontSize } from '../../common/Common'
import { defaultFormPdfFontFamily, inuktitutFormPdfFontFamily } from '../../pdf-components/FormPdf'

const styles = {
  main: {
    flexDirection: 'row',
    padding: 6
  },
  left: {
    width: '25%',
    paddingRight: 8,
    fontWeight: 'bold'
  },
  maxOptions: {
    fontSize: 8,
    marginTop: 4
  },
  options: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 4,
    width: '48%',
    flexWrap: 'nowrap'
  },
  odd: {
    marginLeft: '4%'
  },
  checkbox: {
    width: 10,
    height: 10,
    marginRight: 8,
    marginTop: 2,
    borderWidth: 1,
    borderColor: 'black'
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 8,
    marginTop: 4,
    borderWidth: 2,
    borderColor: 'black',
    borderRadius: 5
  },
  optionText: {
    fontSize: pdfDefaultFontSize,
    width: '70%',
    lineHeight: 1.4
  },
  emptyDate: {
    fontSize: pdfDefaultFontSize,
    marginRight: 4
  }
}

export const FormDatePickerFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  altLabelPlacement,
  tooltip,
  ...props
}) => {
  // create a date object with a date that has 2-digit day and month and 4-digit year
  const date = new Date(2024, 11, 17)
  const formattedDate = new Intl.DateTimeFormat().format(date)
  // change all digits to empty spaces
  const emptyDate = formattedDate.replace(/\d/g, '_').replace(/\//g, ' /')

  const fontFamily = langVersion === 'iu-cans'
    ? inuktitutFormPdfFontFamily
    : defaultFormPdfFontFamily

  styles.optionText.fontFamily = fontFamily

  return (
    <View style={styles.main}>
      <View style={styles.options}>
        <View style={styles.listItem}>
          <Text style={styles.emptyDate}>{emptyDate} - </Text>
          <Text style={styles.optionText}>
            {myI18n._(t`FORM_DATEPICKER_FILLABLE_PDF_MIN_DATE`)}
          </Text>
        </View>
        <View style={[styles.listItem, styles.odd]}>
          <Text style={styles.emptyDate}>{emptyDate} - </Text>
          <Text style={styles.optionText}>
            {myI18n._(t`FORM_DATEPICKER_FILLABLE_PDF_MAX_DATE`)}
          </Text>
        </View>
      </View>
    </View>
  )
}

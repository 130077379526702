import { Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { globalValues } from 'app/utils/GlobalValues'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import DebouncedTextField from 'app/views/forms/common/DebouncedTextField'
import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { PdfPropsForm } from 'app/views/forms/common/PdfPropsForm'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { mapFormEditorElements } from '../../../editor/FormWizard'
import { FormBudget } from './FormBudget'

export const FormEditorBudget = ({
  id,
  showPdfProps,
  injectable,
  showPrintProps,
  editMode,
  depth,
  typeProps = {},
  langVersion,
  ...props
}) => {
  const {
    expensesCommentsValidation,
    requestedAmountId,
    requestedAmountIds = [],
    expensesMustMatchRevenues,
    commentsRows,
    commentsExpandable,
    revenuesHelpText,
    expensesHelpText
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector((state) => state.formEditorTree)
  const mapping = globalValues.budgetCategories.en_CA

  const avaliableElementsNumeric = mapFormEditorElements({
    data: tree,
    type: 'textInputNumeric',
    id,
    langVersion
  })
  const avaliableElementsNumericLength = avaliableElementsNumeric.length || 0

  // useEffect to update requestedAmountIds when avaliableElementsNumeric changes
  useEffect(() => {
    const toSet = { ...typeProps }
    const newRequestedAmountIds = requestedAmountIds.filter((id) =>
      avaliableElementsNumeric.map((item) => item.id).includes(id)
    )
    toSet.requestedAmountIds = newRequestedAmountIds
    dispatch({
      type: 'FIELD',
      depth: depth.split('.'),
      fieldName: 'typeProps',
      fieldValue: toSet
    })
  }, [avaliableElementsNumericLength])

  // useEffect to update requestedAmountIds when deprecated requestedAmountId is not there
  useEffect(() => {
    const toSet = { ...typeProps }
    if (requestedAmountId) {
      toSet.requestedAmountIds = requestedAmountIds.includes(requestedAmountId)
        ? requestedAmountIds
        : [requestedAmountId, ...requestedAmountIds]
      toSet.requestedAmountId = undefined
      dispatch({
        type: 'FIELD',
        depth: depth.split('.'),
        fieldName: 'typeProps',
        fieldValue: toSet
      })
    }
  }, [requestedAmountId])

  if (!editMode) {
    return <FormBudget editMode id={id} typeProps={typeProps} {...props} />
  }

  return (
    <div>
      {/* <TextField
        disabled={avaliableElementsMilestones.length === 0}
        error={avaliableElementsMilestones.length === 0}
        helperText={
          avaliableElementsMilestones.length === 0 && (
            <Trans>
              You need to add at least one elements of Milestones type to link
              to this element
            </Trans>
          )
        }
        style={{ marginTop: 10 }}
        value={milestonesId || ''}
        fullWidth
        variant='outlined'
        select
        label={<Trans>Milestones element</Trans>}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.milestonesId = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        {avaliableElementsMilestones.map((item, index) => {
          return (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          )
        })}
      </TextField> */}

      <Grid container alignItems='center' style={{ marginTop: 10 }}>
        <Grid item>
          <h6 style={{ marignTop: 5, marginBottom: 0 }}>
            <Trans>Requested amount element</Trans>
          </h6>
        </Grid>

        {avaliableElementsNumeric.length === 0 && (
          <Typography style={{ color: 'red', margin: 5 }}>
            <Trans>FORM_EDITOR_BUDGET_NO_NUMERIC_ELEMENTS_ERROR</Trans>
          </Typography>
        )}

        <Button
          disabled={
            requestedAmountIds.length === avaliableElementsNumeric.length
          }
          variant='contained'
          color='primary'
          style={{ marginLeft: 10 }}
          onClick={(e) => {
            const toSet = { ...typeProps }
            const newLines = [...requestedAmountIds]
            newLines.push('')
            toSet.requestedAmountIds = newLines
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <Trans>FORM_EDITOR_BUDGET_ADD_REQUESTED_AMOUNT_BTN</Trans>
          <Icon style={{ marginLeft: 5 }}>add</Icon>
        </Button>
      </Grid>

      <div style={{ marginTop: 5 }}>
        {requestedAmountIds.map((id, index) => {
          return (
            <div key={id || `index-${index}`} style={{ padding: 5 }}>
              <Grid container alignItems='center'>
                <Grid item style={{ marginRight: 5 }}>
                  <b>{Number(index + 1) + '. '}</b>
                </Grid>
                <Grid item xs>
                  <TextField
                    label={<Trans>Requested amount element</Trans>}
                    fullWidth
                    variant='outlined'
                    value={id || ''}
                    onChange={(e) => {
                      const toSet = { ...typeProps }
                      toSet.requestedAmountIds[index] = e.target.value
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    select
                  >
                    {avaliableElementsNumeric
                      .filter(
                        (item) =>
                          item.id === id ||
                          !requestedAmountIds.includes(item.id)
                      )
                      .map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.label}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </Grid>
                <IconButton
                  onClick={(e) => {
                    const toSet = { ...typeProps }
                    toSet.requestedAmountIds.splice(index, 1)
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </div>
          )
        })}
      </div>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(expensesMustMatchRevenues)}
              onChange={(e) => {
                const toSet = { ...typeProps }
                toSet.expensesMustMatchRevenues = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={
            <Trans>
              Require expenses to match revenues in element validation
            </Trans>
          }
        />
      </div>

      <h6 style={{ marginTop: 10, marginBottom: 5 }}>
        <Trans>Comments field</Trans>
      </h6>
      <Grid container alignItems='center'>
        <DebouncedTextField
          label={<Trans>Rows</Trans>}
          value={commentsRows || ''}
          InputProps={{ inputComponent: DefaultNumericFormat }}
          variant='outlined'
          onChange={(e) => {
            const toSet = { ...typeProps }
            toSet.commentsRows = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        />

        <FormControlLabel
          style={{ marginLeft: 10 }}
          control={
            <Checkbox
              checked={Boolean(commentsExpandable)}
              onChange={(e) => {
                const toSet = { ...typeProps }
                toSet.commentsExpandable = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Allow expanding rows?</Trans>}
        />
      </Grid>

      <h6 style={{ marginTop: 10, marginBottom: 10 }}>
        <Trans>Help texts</Trans>
      </h6>

      <ConfigureMultilanguageTextField
        value={revenuesHelpText}
        label={<Trans>Revenues help text</Trans>}
        handleChange={(value) => {
          const toSet = { ...typeProps }
          toSet.revenuesHelpText = value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
        useDebounce
      />

      <ConfigureMultilanguageTextField
        value={expensesHelpText}
        label={<Trans>Expenses help text</Trans>}
        style={{ paddingTop: 10 }}
        handleChange={(value) => {
          const toSet = { ...typeProps }
          toSet.expensesHelpText = value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
        useDebounce
      />

      {[
        {
          headerLabel: <Trans>Revenues lines</Trans>,
          key: 'revenuesLines',
          mapping: mapping.revenues
        },
        {
          headerLabel: <Trans>Expenses lines</Trans>,
          key: 'expensesLines',
          mapping: mapping.expenses
        }
      ].map((obj) => {
        const { headerLabel, key, mapping } = obj
        const array = typeProps[key] || []
        return (
          <>
            <Grid container alignItems='center' style={{ marginTop: 10 }}>
              <Grid item>
                <h6 style={{ marignTop: 5, marginBottom: 5 }}>{headerLabel}</h6>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ marginLeft: 10 }}
                  onClick={(e) => {
                    const toSet = { ...typeProps }
                    const newLines = [...array]
                    newLines.push('')
                    toSet[key] = newLines
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  <Trans>Add line</Trans>
                  <Icon style={{ marginLeft: 5 }}>add</Icon>
                </Button>
              </Grid>
              {key === 'expensesLines' && (
                <Grid item>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Checkbox
                        checked={Boolean(expensesCommentsValidation)}
                        onChange={(e) => {
                          const toSet = { ...typeProps }
                          toSet.expensesCommentsValidation = e.target.checked
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                      />
                    }
                    label={
                      <Trans>
                        Set comments as required if expense is filled?
                      </Trans>
                    }
                  />
                </Grid>
              )}
            </Grid>
            <div style={{ marginTop: 5 }}>
              {array.map((line, index) => {
                return (
                  <div key={index} style={{ padding: 5 }}>
                    <Grid container alignItems='center'>
                      <Grid item style={{ marginRight: 5 }}>
                        <b>{Number(index + 1) + '. '}</b>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          label={<Trans>Budget category</Trans>}
                          fullWidth
                          variant='outlined'
                          value={line || ''}
                          onChange={(e) => {
                            const toSet = { ...typeProps }
                            toSet[key][index] = e.target.value
                            dispatch({
                              type: 'FIELD',
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: toSet
                            })
                          }}
                          select
                        >
                          {Object.keys(mapping).map((key) => (
                            <MenuItem key={mapping[key]} value={key}>
                              {myI18n?._(key)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <IconButton
                        size='small'
                        onClick={(e) => {
                          const toSet = { ...typeProps }
                          const toMove = toSet[key][index]
                          const toReplace = toSet[key][index + 1]
                          toSet[key][index + 1] = toMove
                          toSet[key][index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                        disabled={index === array.length - 1}
                      >
                        <Icon>arrow_downward</Icon>
                      </IconButton>
                      <IconButton
                        size='small'
                        onClick={(e) => {
                          const toSet = { ...typeProps }
                          const toMove = toSet[key][index]
                          const toReplace = toSet[key][index - 1]
                          toSet[key][index - 1] = toMove
                          toSet[key][index] = toReplace
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                        disabled={index === 0}
                      >
                        <Icon>arrow_upward</Icon>
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          const toSet = { ...typeProps }
                          toSet[key].splice(index, 1)
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: { ...toSet }
                          })
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  </div>
                )
              })}
            </div>
          </>
        )
      })}

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
    </div>
  )
}

import { Trans, t } from '@lingui/macro'
import { parseObjectiveUpdateSFObject } from 'app/services/sfAuth/sfData/sfObjectivesUpdate'
import { requiredTrans } from 'app/views/forms/formTranslations'
import { purgeInvalidPDFSignFromString } from 'app/views/forms/pdf-components/formPdfUtils'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'

export const formObjectivesUpdateValueToText = (v) => {
  return languages.reduce((acc, lang) => {
    if (lang === 'en') {
      acc[lang] = (
        <div>
          <Trans>Data is too complex to show objectives!</Trans>
          <div style={{ color: '#e0a912' }}>
            <Trans>
              Overriding will ony save changes to already added objectives. It
              will not delete any newly added milestones. Manual cleanup may be
              required.
            </Trans>
          </div>
        </div>
      )
    } else {
      acc[lang] = <Trans>Data is too complex to show differences!</Trans>
    }
    return acc
  }, {})
}

export const FormObjectivesUpdateExtractKey = ({
  saveMap,
  value,
  connectedTo,
  sfObject
}) => {
  const opportunityId = connectedTo.find(
    (obj) => obj.forceType === 'FGM_Base__Grantee_Report__c'
  )?.connectedObject
  if (!opportunityId) {
    return
  }
  if (value.length) {
    saveMap[opportunityId].Objective_Updates__r = value.map((item) => ({
      Id: item?.objectivesUpdate?.id,
      Grantee_Report__c: sfObject?.Id,
      Objective__c: item?.id,
      Identified_need_changes__c:
        item?.objectivesUpdate?.identifiedNeedChanges || '',
      Actual_outcomes__c: item?.objectivesUpdate?.actualOutcomes || '',
      Desired_outcome_changes__c:
        item?.objectivesUpdate?.desiredOutcomeChanges || ''
    }))
  }
}

export const FormObjectivesUpdateDefaultValue = (
  obj,
  { contactsMap, accountsMap, initObj, connectedMap, ...additionalSFInfo } = {},
  item
) => {
  if(!obj?.Opportunity) {
    return []
  }

  if (!obj?.Opportunity?.Objectives__r 
    || (obj?.Opportunity?.Objectives__r?.records || []).length === 0) {
    throw {
      message: <Trans>FORM_OBJECTIVES_UPDATE_UTILS_NO_OBJECTIVES_ERROR_LABEL</Trans>
    }
  }

  const granteeReportObjectiveUpdates =
    obj?.FGM_Base__Grantee_Report__c?.Objective_Updates__r?.records || []

  const objectiveUpdates = granteeReportObjectiveUpdates
    .sort((a, b) => {
      return new Date(b.CreatedDate) - new Date(a.CreatedDate)
    })

  /** map of objective updates for each objective id */
  const objectiveIdToObjectivesUpdate = objectiveUpdates.reduce((acc, item) => {
    acc[item.Objective__c] = item
    return acc
  }, {})

  return obj.Opportunity.Objectives__r.records.map((objective) => ({
    outcomes: objective.Outcomes__c
      ? purgeInvalidPDFSignFromString(objective.Outcomes__c).split(';')
      : [],
    objective: objective.Objective__c,
    needs: purgeInvalidPDFSignFromString(objective.Identified_needs__c),
    objectivesUpdate: objectiveIdToObjectivesUpdate[objective.Id] ? parseObjectiveUpdateSFObject(objectiveIdToObjectivesUpdate[objective.Id]) : undefined,
    lastObjectivesUpdates: objectiveUpdates.filter(
      (item) => item.objectiveId !== objective.Id
    ),
    id: objective.Id
  }))
}

export const formObjectivesUpdateValidaiton = (item, data, describeMap) => {
  let actualOutcomesLimit,
    desiredOutcomeChangesLimit,
    identifiedNeedChangesLimit
  if (describeMap.Objective_Update__c) {
    describeMap.Objective_Update__c.fields.forEach((fieldObj) => {
      if (fieldObj.name === 'Actual_outcomes__c') {
        actualOutcomesLimit = fieldObj.length
      }
      if (fieldObj.name === 'Desired_outcome_changes__c') {
        desiredOutcomeChangesLimit = fieldObj.length
      }
      if (fieldObj.name === 'Identified_need_changes__c') {
        identifiedNeedChangesLimit = fieldObj.length
      }
    })
  }
  return Yup.array()
    .ensure()
    .of(
      Yup.object({
        objectivesUpdate: Yup.object({
          actualOutcomes: actualOutcomesLimit
            ? Yup.string()
              .nullable()
              .required(requiredTrans)
              .max(actualOutcomesLimit, ({ max }) =>
                myI18n?._(
                  t`This fields length cannot exceed ${max} characters!`
                )
              )
            : Yup.string().nullable().required(requiredTrans),
          desiredOutcomeChanges: desiredOutcomeChangesLimit
            ? Yup.string()
              .nullable()
            // .required(requiredTrans)
              .max(desiredOutcomeChangesLimit, ({ max }) =>
                myI18n?._(
                  t`This fields length cannot exceed ${max} characters!`
                )
              )
            : Yup.string().nullable(),
          identifiedNeedChanges: identifiedNeedChangesLimit
            ? Yup.string()
              .nullable()
            // .required(requiredTrans)
              .max(identifiedNeedChangesLimit, ({ max }) =>
                myI18n?._(
                  t`This fields length cannot exceed ${max} characters!`
                )
              )
            : Yup.string().nullable()
        }).nullable()
      })
    )
}

import { Slider } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormErrorLabel } from '../../../common/labels/FormErrorLabel'
import { MUEditedByLabel } from '../../multiuser/components/MUEditedByLabel'
import {
  endEditingField,
  startEditingField
} from '../../multiuser/grpcMultiuserEdit'

/**
 * Form element which renders a Material UI date picker.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {number} [typeProps.max=100] Maximum value of the slider.
 * @param  {number}  [typeProps.min=0] Minimum value of the slider.
 * @param  {number} [typeProps.step=1] By how much value should be incremented/decremented when dragging the slider.
 */
export function FormNumericSlider ({
  id,
  useMultiuser,
  muBag,
  disabled,
  typeProps
}) {
  const user = useSelector(state => state.user)
  const { values } = useFormikContext()
  const [tempValue, setTempValue] = useState(null)
  const [field, meta, helpers] = useField(id)
  const { required, max, min, step = 1 } = typeProps
  const minValue = min || 0
  const maxValue = max || 100
  const isValid = field.value <= maxValue && field.value >= minValue
  const { setValue } = helpers
  const isError = Boolean(meta.touched && meta.error)
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, locked
  if (muState) {
    const { user } = muState
    locked = muState.locked
    if (locked) {
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Slider
        className={!field.value && 'slider-muted'}
        style={{
          color:
            !field.value && field.value !== 0
              ? '#A5C9D3'
              : disabled
                ? 'rgba(0, 0, 0, 0.26)'
                : '#10708A'
        }}
        onChangeCommitted={(event, newValue) => {
          setValue(newValue)
          setTempValue(null)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }}
        onChange={(event, newValue) => {
          setTempValue(newValue)
          if (useMultiuser && !locked && !tempValue) {
            startEditingField({
              ...muBag,
              fieldId: id
            })
          }
        }}
        value={
          tempValue || tempValue === 0
            ? tempValue
            : isValid
              ? field.value
              : minValue
        }
        step={Number(step)}
        disabled={
          Boolean(muState && muState.locked && muState.user !== user.userId) ||
          disabled
        }
        min={Number(minValue)}
        max={Number(maxValue)}
        marks={[
          { value: minValue, label: minValue },
          { value: maxValue, label: maxValue }
        ]}
        valueLabelDisplay='auto'
      />
      <MUEditedByLabel color={userColor} userName={userName} />
      <FormErrorLabel error={isError} id={id} required={required} />
    </div>
  )
}

import { Trans } from '@lingui/macro'
import { Card, Icon, IconButton, Tooltip } from '@material-ui/core'

const GoToErrorButton = ({ error, navigateToError }) => {
  const id = error?.error?.id || error?.id

  return (
    <Tooltip title={<Trans>Go to error</Trans>}>
      <IconButton
        size='small'
        style={{ marginLeft: 5, marginBottom: 5 }}
        onClick={e => {
          navigateToError({
            id,
            section: error.sectionIndex
          })
        }}
      >
        <Icon>arrow_forward</Icon>
      </IconButton>
    </Tooltip>
  )
}

/**
 * Component displaying list of errors currently ocurring in the form.
 * @category Form
 * @component
 * @returns {JSX.Element}
 * @param  {(string|JSX.element)} [helperText] - Help text that will be displaying above errors list.
 * @param {object[]} errors - An array of current Formik errors
 * @param {Function} navigateToError - Function that navigates user in form to element related to target error.
 */
export const FormErrorsList = ({ errors, helperText, navigateToError }) => {
  if (errors.length === 0) {
    return null
  }
  const errorsToSections = {}
  errors.forEach(error => {
    if (errorsToSections[error.sectionName]) {
      errorsToSections[error.sectionName].push(error)
    } else {
      errorsToSections[error.sectionName] = [error]
    }
  })

  return (
    <Card className='p-24 mb-44 bg-light-error box-shadow-none'>
      <h5 className='m-0 mb-8 font-weight-500 text-error'>
        <Trans>Errors found!</Trans>
      </h5>
      {helperText && <p className='m-0 text-muted'>{helperText}</p>}
      {Object.keys(errorsToSections).map((key, index) => {
        const sectionErrors = errorsToSections[key]
        return (
          <div key={index} style={{ marginTop: 10 }}>
            <h6 className='m-0 mb-8 font-weight-500 text-error'>{key}</h6>
            <ul>
              {sectionErrors.map((errorObj, index) => {
                const { error, title } = errorObj
                if (error && error.toMap) {
                  return (
                    <li key={index}>
                      <b>{error?.title || title}</b>
                      <GoToErrorButton
                        navigateToError={navigateToError}
                        error={errorObj}
                      />
                      <ul>
                        {error.toMap.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </li>
                  )
                } else if (error && typeof error === 'object' && !error.props) {
                  return (
                    <div key={index}>
                      <b>{title}</b>{' '}
                      <GoToErrorButton
                        navigateToError={navigateToError}
                        error={errorObj}
                      />
                      <ul>
                        {Object.keys(error).map((key, index) => (
                          <li key={index}>
                            <u>{error[key].label}</u>: {error[key].error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                } else {
                  return (
                    <li key={index}>
                      {title
                        ? (
                          <>
                            <b>{title}:</b> {error}
                          </>
                          )
                        : (
                            error
                          )}
                      <GoToErrorButton
                        navigateToError={navigateToError}
                        error={errorObj}
                      />
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        )
      })}
    </Card>
  )
}

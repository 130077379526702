import { Trans } from '@lingui/macro'
import { Button, Grid, Icon, IconButton, Paper } from '@material-ui/core'
import {
  getConfigurationVariables,
  saveConfigurationsWithUpdateByFlow
} from 'app/services/sfAuth/sfData/sfVariables'
import Loading from 'egret/components/EgretLoadable/Loading'
import { FieldArray, Formik } from 'formik'
import { FormikTextField } from 'formik-material-fields'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { setConfigurationData } from 'app/redux/actions/ConfigurationActions'
import { getFormPages } from 'app/services/sfAuth/sfData/sfForms'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import { FormSelectField } from './funding-streams/ConfigureFundingStreams'

export const sfValidation = Yup.string()
  .nullable()
  .matches(/^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/, {
    message: <Trans>You must provide a valid Salesforce id</Trans>
  })

export const basePortalConfig = {
  AVAILABLE_APPLICATIONS: {
    type: 'object'
  },
  FORM_PREVIEW_CONFIG: {
    type: 'object'
  },
  FORM_USER_PROFILE: {
    type: 'formId',
    label: <Trans>User profile form</Trans>
  },
  FORM_ORGANIZATION_DETAILS: {
    type: 'formId',
    label: <Trans>Organization details form</Trans>
  },
  EXTERNAL_REVIEWER_PROFILE: {
    type: 'formId',
    label: <Trans>External reviewer profile form</Trans>
  },
  VIVRE_EN_VILLE_RENT_PREVIEW: {
    type: 'formId',
    label: <Trans>Vivre En Ville Rent preview form</Trans>
  },
  VIVRE_EN_VILLE_RENT_EDIT: {
    type: 'formId',
    label: <Trans>Vivre En Ville Rent edit form</Trans>
  },
  E_SAT_SURVEY: {
    type: 'id',
    label: <Trans>ESAT survey id</Trans>
  },
  SAT_COOPS_SURVEY: {
    type: 'id',
    label: <Trans>SAT (co-ops) survey id</Trans>
  },
  SAT_OSBL_SURVEY: {
    type: 'id',
    label: <Trans>SAT (osbl) survey id</Trans>
  },
  SAT_SURVEY: {
    type: 'id',
    label: <Trans>SAT survey id</Trans>
  },
  EXTERNAL_REVIEW_CBTI: {
    type: 'id',
    label: (
      <Trans>
        External review survey id for Community-Based Tenant Initiative Fund
      </Trans>
    )
  },
  EXTERNAL_REVIEW_FCHI: {
    type: 'id',
    label: <Trans>External review survey id for TRA</Trans>
  },
  EXTERNAL_REVIEW_STF_LP: {
    type: 'id',
    label: (
      <Trans>
        External review survey id for Sector Transformation Fund – Local Project
      </Trans>
    )
  },
  EXTERNAL_REVIEW_STF_SI: {
    type: 'id',
    label: (
      <Trans>
        External review survey id for Sector Transformation Fund – Sectoral
        Impact
      </Trans>
    )
  }
}

const editableConfigs = Object.entries(basePortalConfig).filter(
  ([key, value]) => value.type !== 'object'
)

export default function ConfigurePortalConfigurations () {
  const configuration = useSelector(state => state.configuration)
  const user = useSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [nameToSfId, setNameToSfId] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [formsData, setFormsData] = React.useState([])
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setLoading(true)
    Promise.all([getConfigurationVariables(), getFormPages()]).then(
      ([configurationObjects, forms]) => {
        const nameToSfId = {}
        configurationObjects.forEach(obj => {
          if (obj.Name) {
            nameToSfId[obj.Name] = obj.Id
          }
        })
        const initial = {}
        editableConfigs.forEach(([key, value]) => {
          initial[key] = configuration[key] || value.defaultValue || ''
        })
        setNameToSfId(nameToSfId)

        const formsCount = {}
        forms.forEach(form => {
          if (form.origin) {
            if (formsCount[form.origin]) {
              formsCount[form.origin].push(form)
            } else {
              formsCount[form.origin] = [form]
            }
          }
        })
        setFormsData(
          forms
            .map(obj => {
              let name = obj.name[user.language]
              if (obj.config.version && formsCount[obj.origin]) {
                name += ' [v.' + obj.config.version + ']'
              }
              if (obj.comments) {
                name += ' (' + obj.comments + ')'
              }
              return {
                name,
                id: obj.id
              }
            })
            .filter(name => name)
            .sort((a, b) => String(a.name).localeCompare(b.name))
        )
        setInitialValues(initial)
        setLoading(false)
      }
    )
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Paper style={{ padding: 15 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          FORM_ORGANIZATION_DETAILS: sfValidation,
          FORM_USER_PROFILE: sfValidation,
          EXECUTIVE_SUMMARY_FORM: Yup.object({
            firstReview: sfValidation,
            inDepth: sfValidation
          })
        })}
      >
        {({ isValid, values, setFieldValue }) => {
          return (
            <Grid container direction='column'>
              <Grid
                item
                container
                alignItems='flex-end'
                justifyContent='flex-end'
                disabled={!isValid || saving}
                style={{ paddingTop: 10 }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  disabled={saving}
                  onClick={e => {
                    setSaving(true)
                    saveConfigurationsWithUpdateByFlow(
                      editableConfigs.map(([key, value]) => ({
                        Id: nameToSfId[key],
                        Name: key,
                        Value__c:
                          typeof values[key] === 'string'
                            ? values[key]
                            : JSON.stringify(values[key])
                      }))
                    ).then(
                      result => {
                        setSaving(false)
                        enqueueSnackbar(<Trans>Configurations saved</Trans>, {
                          variant: 'success'
                        })
                        const toSet = { ...configuration }
                        editableConfigs.forEach(([key, value]) => {
                          toSet[key] = values[key]
                        })
                        dispatch(setConfigurationData(toSet))
                      },
                      reject => {
                        setSaving(false)
                        enqueueSnackbar(
                          <Trans>Error ocurred while saving</Trans>,
                          {
                            variant: 'error'
                          }
                        )
                      }
                    )
                  }}
                >
                  <Icon style={{ marginRight: 5 }}>save</Icon>
                  <Trans>Save</Trans>
                </Button>
              </Grid>

              <div
                style={{
                  padding: 5,
                  fontWeight: 400,
                  fontSize: 26,
                  textAlign: 'center'
                }}
              >
                <Trans>Portal forms</Trans>
              </div>

              {editableConfigs.map(([key, value]) => {
                if (value.type === 'id') {
                  return (
                    <>
                      {key === 'GRANTEE_REPORT_FINAL' && (
                        <div
                          style={{
                            padding: 5,
                            fontWeight: 400,
                            fontSize: 26,
                            textAlign: 'center'
                          }}
                        >
                          <Trans>Portal surveys</Trans>
                        </div>
                      )}
                      <Grid item style={{ paddingTop: 15 }}>
                        <div style={{ fontWeight: 'bold', paddingLeft: 10 }}>
                          {value.label}
                        </div>
                        <FormikTextField
                          name={key}
                          variant='outlined'
                          fullWidth
                          // label={value.label}
                        />
                      </Grid>
                    </>
                  )
                }

                if (value.type === 'formId') {
                  return (
                    <FormSelectField
                      formsData={formsData}
                      name={key}
                      label={value.label}
                    />
                  )
                }

                if (value.type === 'objectsOrder') {
                  const order = values[key] || value.defaultValue
                  return (
                    <Grid item style={{ paddingTop: 15 }}>
                      <div style={{ fontWeight: 'bold', paddingLeft: 10 }}>
                        {value.label}{' '}
                        <TooltipLabelIcon
                          tooltip={
                            <Trans>
                              Order of objects must match the one configured in
                              related form
                            </Trans>
                          }
                        />
                      </div>
                      {/* <FormikTextField
                        name={`${key}.id`}
                        variant='outlined'
                        fullWidth
                        // label={value.label}
                      /> */}
                      <div style={{ marginTop: 10, padding: 5 }}>
                        {/* <Trans>Form objects order</Trans>
                        <TooltipLabelIcon
                          tooltip={
                            <Trans>
                              Order of objects must match the one configured in
                              selected form
                            </Trans>
                          }
                        /> */}
                        {order.map((subKey, index) => (
                          <Grid container key={index} xs alignItems='center'>
                            {String(index + 1) + '. '}
                            {value.objects[subKey]?.label}
                            <IconButton
                              size='small'
                              disabled={index === 0}
                              onClick={e => {
                                const toSet = [...order]
                                const toMove = toSet[index]
                                const toReplace = toSet[index - 1]
                                toSet[index - 1] = toMove
                                toSet[index] = toReplace
                                setFieldValue(`${key}`, toSet)
                              }}
                            >
                              <Icon>arrow_upward</Icon>
                            </IconButton>
                            <IconButton
                              size='small'
                              disabled={index === order.length - 1}
                              onClick={e => {
                                const toSet = [...order]
                                const toMove = toSet[index]
                                const toReplace = toSet[index + 1]
                                toSet[index + 1] = toMove
                                toSet[index] = toReplace
                                setFieldValue(`${key}`, toSet)
                              }}
                            >
                              <Icon>arrow_downward</Icon>
                            </IconButton>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                  )
                }

                if (value.type === 'ids') {
                  return (
                    <FieldArray
                      name={key}
                      render={({ push, remove }) => {
                        const array = values[key] || []

                        return (
                          <Grid
                            item
                            container
                            style={{ paddingTop: 15 }}
                            direction='column'
                          >
                            <div
                              style={{ fontWeight: 'bold', paddingLeft: 10 }}
                            >
                              {value.label}{' '}
                              {value.tooltip && (
                                <TooltipLabelIcon tooltip={value.tooltip} />
                              )}
                              <IconButton
                                size='small'
                                onClick={e => {
                                  push('')
                                }}
                              >
                                <Icon>add</Icon>
                              </IconButton>
                            </div>

                            {array.map((id, index) => {
                              return (
                                <Grid
                                  item
                                  key={index}
                                  xs
                                  style={{ paddingTop: 15 }}
                                  container
                                  wrap='nowrap'
                                  alignItems='center'
                                >
                                  <span style={{ marginRight: 10 }}>
                                    {Number(index + 1) + '.'}
                                  </span>
                                  <FormikTextField
                                    name={`${key}.${index}`}
                                    variant='outlined'
                                    fullWidth
                                    // label={obj.label}
                                  />
                                  <IconButton
                                    onClick={e => {
                                      remove(index)
                                    }}
                                  >
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </Grid>
                              )
                            })}
                          </Grid>
                        )
                      }}
                    />
                  )
                }

                return null
              })}
            </Grid>
          )
        }}
      </Formik>
    </Paper>
  )
}

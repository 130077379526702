import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { datetimeFormat } from 'app/appSettings'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import {
  VersionsDifferences,
  getFormVersionDifference
} from '../../form-page/FormVersionDifferences'
import {
  MultiuserEventType,
  commitChangeToMultipleFields,
  formCacheTypeToLabel,
  grpGetLockedFieldsForForm,
  grpcGetFormBackups,
  grpcGetFormCache,
  sendMultiuserEvent
} from '../grpcMultiuserEdit'
import { EventType } from '../proto/generated/Multiuser_pb'

export const FormBackupsPanel = ({ saving, muBag, elementsMap = {} }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [backups, setBackups] = useState(null)
  const [versions, setVersions] = useState(null)
  const [restoring, setRestoring] = useState(false)
  const { values, setValues } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (dialogOpen) {
      grpcGetFormBackups({
        ...muBag,
        onSuccess: backups => {
          setBackups(
            backups.sort((a, b) =>
              moment(b.date).diff(moment(a.date), 'minutes')
            )
          )
        },
        onFail: response => {
          console.error(response)
        }
      })
    }
  }, [dialogOpen])

  return (
    <>
      <Dialog open={Boolean(versions)} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container direction='row'>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Current version</Trans>
            </Grid>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Backup</Trans>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {versions && (
            <VersionsDifferences {...versions} elementsMap={elementsMap} />
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent='space-evenly'
            style={{ paddingTop: 15, paddingBottom: 15 }}
          >
            <Button
              color='primary'
              variant='contained'
              disabled={
                restoring ||
                Boolean(
                  versions &&
                    getFormVersionDifference({
                      current: versions.cache,
                      cache: values,
                      elementsMap
                    }).length === 0
                )
              }
              onClick={e => {
                setRestoring(true)
                grpGetLockedFieldsForForm({
                  ...muBag,
                  onSuccess: locks => {
                    if (locks.length === 0) {
                      setRestoring(false)
                      setDialogOpen(false)
                      setVersions(null)
                      enqueueSnackbar(<Trans>Backup restored!</Trans>, {
                        variant: 'success'
                      })
                      const differences = getFormVersionDifference({
                        current: versions.cache,
                        cache: values,
                        elementsMap
                      })
                      commitChangeToMultipleFields({
                        ...muBag,
                        array: differences
                      })
                      sendMultiuserEvent({
                        ...muBag,
                        type: MultiuserEventType.BACKUP_RESTORE
                      })
                    } else {
                      setRestoring(false)
                      enqueueSnackbar(
                        <Trans>
                          You cannot restore the backup, some fields are
                          currently edited by other users!
                        </Trans>,
                        {
                          variant: 'error'
                        }
                      )
                      sendMultiuserEvent({
                        ...muBag,
                        eventVariant: EventType.EVENT_ALERT,
                        type: MultiuserEventType.BACKUP_RESTORE_BLOCKED
                      })
                    }
                  }
                })
              }}
            >
              <Trans>Restore backup</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={restoring}
              onClick={e => {
                setVersions(null)
                setRestoring(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Trans>Form backups</Trans>
            <IconButton
              disabled={restoring || !backups}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <div style={{ fontSize: 13, fontWeight: 400 }}>
            <Trans>
              Be careful! Restoring the backup will overrite your current
              changes
            </Trans>
          </div>
        </DialogTitle>
        <DialogContent>
          {Array.isArray(backups)
            ? (
                backups.length === 0
                  ? (
                    <Alert severity='info'>
                      <AlertTitle>
                        <Trans>There are no backups yet for this form!</Trans>
                      </AlertTitle>
                    </Alert>
                    )
                  : (
                      backups.map((backup, index) => (
                        <Grid
                          container
                          key={index}
                          alignItems='center'
                          style={{ marginTop: 10 }}
                        >
                          <Grid item xs>
                            <div style={{ fontWeight: 400, fontSize: 11 }}>
                              <Trans>Date</Trans>
                            </div>
                            <div>
                              {moment.utc(backup.date).local().format(datetimeFormat)}
                            </div>
                          </Grid>

                          <Grid item xs>
                            <div style={{ fontWeight: 400, fontSize: 11 }}>
                              <Trans>Backup Type</Trans>
                            </div>
                            <div>{formCacheTypeToLabel[backup.type]}</div>
                          </Grid>

                          <Grid item xs>
                            <div style={{ fontWeight: 400, fontSize: 11 }}>
                              <Trans>Editing users</Trans>
                            </div>
                            <div>{backup.users?.join(', ')}</div>
                          </Grid>

                          <Grid item>
                            <IconButton
                              disabled={restoring || saving}
                              onClick={e => {
                                setRestoring(true)
                                grpcGetFormCache({
                                  ...muBag,
                                  id: backup.id,
                                  onSuccess: cache => {
                                    setRestoring(false)
                                    setVersions({
                                      cache,
                                      current: values
                                    })
                                  }
                                })
                              }}
                            >
                              <Icon>settings_backup_restore</Icon>
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    )
              )
            : (
              <Loading isNotFixed style={{ marginBottom: 15 }} />
              )}
        </DialogContent>
      </Dialog>

      <Button
        style={{ marginLeft: 4 }}
        // disabled={!backups}
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>Form backups</Trans>
        <Icon style={{ marginLeft: 5 }}>backup</Icon>
      </Button>
    </>
  )
}

import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core'
import { dateFormat } from 'app/appSettings'
import CustomDatePicker from 'app/views/common-components/CustomDatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { mapEditorFormElements } from '../../common/Common'
import DebouncedTextField from '../../common/DebouncedTextField'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { FormDatePicker } from './FormDatePicker'

export const FormEditorDatePicker = ({
  typeProps,
  showPrintProps,
  title,
  depth,
  editMode,
  id,
  langVersion,
  injectable,
  ...props
}) => {
  const {
    required,
    showFieldLabel,
    clearable,
    minDate,
    minDateElement,
    maxDate,
    maxDateElement,
    startDateDaysAfterCurrentDate
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector((state) => state.formEditorTree)

  if (!editMode) {
    return (
      <FormDatePicker
        id={id}
        langVersion={langVersion}
        editMode
        typeProps={typeProps}
        title={title}
        {...props}
      />
    )
  }
  const avaliableElements = mapEditorFormElements({
    data: tree,
    langVersion,
    id,
    elementTypes: ['datePicker']
  })

  return (
    <Grid container direction='column'>
      <h6 style={{ marginLeft: 10 }}>
        <Trans>Min date</Trans>
      </h6>
      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <CustomDatePicker
            fullWidth
            clearable
            format={dateFormat}
            value={minDate || null}
            label={<Trans>Minimum input date</Trans>}
            inputVariant='outlined'
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.minDate = e
              delete toSet.minDateElement
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <DebouncedTextField
            label={
              <Trans>
                FORM_EDITOR_DATE_PICKER_DAYS_AFTER_CURRENT_DATE_LABEL
              </Trans>
            }
            fullWidth
            variant='outlined'
            InputProps={{
              inputProps: { min: 0 }
            }}
            value={startDateDaysAfterCurrentDate}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.startDateDaysAfterCurrentDate = e.target.value
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            select
            fullWidth
            variant='outlined'
            label={<Trans>Minimum date based on element</Trans>}
            value={minDateElement || ''}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.minDateElement = e.target.value
              delete toSet.minDate
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            {[
              <MenuItem key='none' value=''>
                <Trans>Clear</Trans>
              </MenuItem>,
              ...avaliableElements.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))
            ]}
          </TextField>
        </Grid>
      </Grid>
      <h6 style={{ marginLeft: 10 }}>
        <Trans>Max date</Trans>
      </h6>
      <Grid container>
        <Grid item xs style={{ padding: 5 }}>
          <CustomDatePicker
            fullWidth
            clearable
            format={dateFormat}
            value={maxDate || null}
            label={<Trans>Maximum input date</Trans>}
            inputVariant='outlined'
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.maxDate = e
              delete toSet.maxDateElement
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ padding: 5 }}>
          <TextField
            select
            fullWidth
            variant='outlined'
            label={<Trans>Maximum date based on element</Trans>}
            value={maxDateElement || ''}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.maxDateElement = e.target.value
              delete toSet.maxDate
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            {[
              <MenuItem key='none' value=''>
                <Trans>Clear</Trans>
              </MenuItem>,
              ...avaliableElements.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))
            ]}
          </TextField>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(required)}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.required = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Required</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(clearable)}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.clearable = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Input can be cleared?</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(showFieldLabel)}
            onChange={(e) => {
              const toSet = { ...typeProps }
              toSet.showFieldLabel = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Show label in field?</Trans>}
      />
      <FormConnectToObject
        typeProps={typeProps}
        injectable={injectable}
        depth={depth}
        validTypes={['date', 'datetime']}
      />
    </Grid>
  )
}

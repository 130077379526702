import { Grid, Icon, IconButton, Typography } from '@material-ui/core'

export const FormUploadFilesPrint = ({ value = [], title, ...props }) => {
  return (
    <div>
      {value.map((file, index) => {
        return (
          <Grid
            container
            direction='row'
            alignItems='center'
            key={index}
            style={{
              paddingLeft: 5,
              marginBottom: index === value.length - 1 ? 0 : 10
            }}
          >
            <Typography style={{ marginLeft: 5 }}>
              <a
                href={file.url}
                target='_blank'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: '#757575'
                }} rel='noreferrer'
              >
                <Icon
                  style={{
                    width: 28
                  }}
                >download
                </Icon>
                <b
                  style={{
                    borderBottom: '2px solid #757575',
                    lineHeight: '15px'
                  }}
                >{file.name}
                </b>
              </a>
              <div>{file.description}</div>
            </Typography>
          </Grid>
        )
      })}
    </div>
  )
}

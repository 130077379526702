import { Trans } from '@lingui/macro'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { FormHelptext } from 'app/views/forms/common/FormHelpText'
import { useField } from 'formik'
import { BUDGET_EXPENSES_LINES_IN_ORDER, BUDGET_REVENUES_LINES_IN_ORDER } from '../budget/FormBudgetUtils'
import { borderString, BudgetUpdateRow, isLatestUpdateValid, Sum } from './FormBudgetUpdate'

const FormBudgetUpdateReadOnly = ({
  id,
  langVersion,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  i18n,
  disabled,
  helpText,
  ...props
}) => {
  const [field] = useField(id)
  const { value } = field
  const isLatestUpdate =
    Boolean(value.latestReport) && isLatestUpdateValid(value)
  const editable =
    value.report &&
    (value.report.status === 'Requested' ||
      value.report.status === 'More info required')

  return (
    <TableContainer>
      <div>
        {title && <div className='form-print-title'>{title}</div>}
        {helpText && (
          <FormHelptext helpText={helpText[langVersion] || helpText?.en || ''} />
        )}
      </div>

      <Table style={{ marginTop: 8, tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                padding: 24,
                verticalAlign: 'top',
                // fontWeight: 'bold',
                // fontSize: 18,
                border: borderString
              }}
            >
              <Typography className='form-print-subtitle-medium'>
                <Trans>BUDGET_UPDATE_ORIGINAL_BUDGET</Trans>
              </Typography>
            </TableCell>
            {isLatestUpdate && (
              <TableCell
                style={{
                  padding: 24,
                  verticalAlign: 'top',
                  // fontWeight: 'bold',
                  // fontSize: 18,
                  border: borderString
                }}
              >
                <Typography className='form-print-subtitle-medium'>
                  <Trans>BUDGET_UPDATE_LATEST_UPDATE</Trans>
                </Typography>
              </TableCell>
            )}
            <TableCell
              style={{
                padding: 24,
                verticalAlign: 'top',
                fontWeight: 'bold',
                fontSize: 18,
                border: borderString
              }}
            >
              <Typography className='form-print-subtitle-medium'>
                <Trans>BUDGET_UPDATE_HAS_YOUR_BUDGET_CHANGE</Trans>
              </Typography>
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Typography>
                  {value.report?.hasBudgetChanged}
                </Typography>

              </div>

              {value.report?.hasBudgetChanged === 'Yes' && (
                <div style={{ fontSize: 12 }}>
                  <Typography className='form-print-subtitle'>
                    <Trans>BUDGET_UPDATE_PLEASE_INDICATE_CHANGE</Trans>
                  </Typography>
                </div>
              )}
            </TableCell>
            <TableCell
              style={{
                padding: 24,
                verticalAlign: 'top',
                // fontWeight: 'bold',
                // fontSize: 18,
                border: borderString
              }}
            >
              <Typography className='form-print-subtitle-medium'>
                <Trans>BUDGET_UPDATE_ACTUAL_EXPENSES_REVENUES</Trans>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                padding: 16,
                border: 'none',
                borderLeft: borderString,
                borderRight: borderString
              }}
            >
              <Typography className='form-print-subtitle-medium'>
                <Trans>BUDGET_UPDATE_REVENUES</Trans>
              </Typography>
            </TableCell>
            {[...Array(isLatestUpdate ? 3 : 2).keys()].map((index) => {
              return (
                <TableCell
                  key={index}
                  style={{
                    border: 'none',
                    borderLeft: borderString,
                    borderRight: borderString
                  }}
                />
              )
            })}
          </TableRow>
          {value.revenuesLines &&
            Object.keys(value.revenuesLines)
              .sort((a, b) => BUDGET_REVENUES_LINES_IN_ORDER.indexOf(a) - BUDGET_REVENUES_LINES_IN_ORDER.indexOf(b))
              .map((key, index) => {
                const dataObj = value.revenuesLines[key]

                return (
                  <BudgetUpdateRow
                    {...dataObj}
                    langVersion={langVersion}
                    lineKey={key}
                    key={key}
                    index={index}
                    disabled={disabled}
                    changeDisabled={value.report.hasBudgetChanged === 'No'}
                    id={id}
                    muBag={muBag}
                    useMultiuser={useMultiuser}
                    isExpense={false}
                    isLatestUpdate={isLatestUpdate}
                    editable={editable}
                    printable
                  />
                )
              })}
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                padding: 16,
                border: 'none',
                borderLeft: borderString,
                borderRight: borderString
              }}
            >
              <Typography className='form-print-subtitle-medium'>
                <Trans>BUDGET_UPDATE_EXPENSES</Trans>
              </Typography>
            </TableCell>
            {[...Array(isLatestUpdate ? 3 : 2).keys()].map((index) => {
              return (
                <TableCell
                  key={index}
                  style={{
                    border: 'none',
                    borderLeft: borderString,
                    borderRight: borderString
                  }}
                />
              )
            })}
          </TableRow>
          {value.expensesLines &&
            Object.keys(value.expensesLines)
              .sort((a, b) => BUDGET_EXPENSES_LINES_IN_ORDER.indexOf(a) - BUDGET_EXPENSES_LINES_IN_ORDER.indexOf(b))
              .map((key, index) => {
                const dataObj = value.expensesLines[key]
                return (
                  <BudgetUpdateRow
                    {...dataObj}
                    lineKey={key}
                    langVersion={langVersion}
                    key={key}
                    index={index}
                    disabled={disabled}
                    changeDisabled={value.report.hasBudgetChanged === 'No'}
                    id={id}
                    muBag={muBag}
                    useMultiuser={useMultiuser}
                    isExpense
                    isLatestUpdate={isLatestUpdate}
                    editable={editable}
                    printable
                  />
                )
              })}
          <TableRow>
            <TableCell
              style={{
                borderLeft: borderString
              }}
            >
              <Grid container>
                <Grid item xs />
                <Grid item xs>
                  <Sum
                    revenuesLines={value.revenuesLines}
                    expensesLines={value.expensesLines}
                    budgetKey='applicationBudget'
                    subKey='value'
                    langVersion={langVersion}
                    printable
                  />
                </Grid>
              </Grid>
            </TableCell>

            {isLatestUpdate && (
              <TableCell
                style={{
                  borderLeft: borderString,
                  borderRight: borderString
                }}
              >
                <Sum
                  revenuesLines={value.revenuesLines}
                  expensesLines={value.expensesLines}
                  budgetKey='latestUpdate'
                  subKey='change'
                  langVersion={langVersion}
                  printable
                />
              </TableCell>
            )}

            <TableCell
              style={{
                borderLeft: borderString,
                borderRight: borderString
              }}
            >
              <Sum
                revenuesLines={value.revenuesLines}
                expensesLines={value.expensesLines}
                budgetKey='budgetUpdateChange'
                subKey='change'
                langVersion={langVersion}
                printable
              />
            </TableCell>

            <TableCell
              style={{
                borderLeft: borderString,
                borderRight: borderString
              }}
            >
              <Sum
                revenuesLines={value.revenuesLines}
                expensesLines={value.expensesLines}
                budgetKey='budgetUpdateActual'
                subKey='actual'
                langVersion={langVersion}
                printable
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FormBudgetUpdateReadOnly

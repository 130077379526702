import { Trans, t } from '@lingui/macro'
import { defaultFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'

const styles = {
  text: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily
  },
  section: {
    marginBottom: 4
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2
  },
  dateCell: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 20
  },
  milestoneTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8
  }
}

/**
 * Form element which allows to display milestone updates in PDF Editor.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormMilestoneUpdatePdfEditor = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {
  return (
    <div>
      <div style={styles.title}>
        <Trans>FORM_MILESTONES_UPDATE_MILESTONE_TITLE_PLACEHOLDER</Trans>
      </div>

      <div style={styles.section}>
        <div>
          <div style={styles.milestoneTitle}>
            <Trans>FORM_MILESTONES_UPDATE_MILESTONE_STAGE_PLACEHOLDER</Trans>
          </div>
        </div>

        <div style={styles.dateRow}>
          <div style={styles.dateCell}>
            <div style={styles.text}>
              <Trans>Start date</Trans>
            </div>
            <div style={styles.text}>: <Trans>FORM_MILESTONES_UPDATE_START_DATE_PLACEHOLDER</Trans></div>
          </div>
          <div style={styles.dateCell}>
            <div style={styles.text}>
              <Trans>End date</Trans>
            </div>
            <div style={styles.text}>: <Trans>FORM_MILESTONES_UPDATE_END_DATE_PLACEHOLDER</Trans></div>
          </div>
        </div>

        <div>
          <div style={styles.text}><Trans>FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_TITLE</Trans></div>
        </div>
        <div>
          <div style={styles.text}>
            <Trans>FORM_MILESTONES_UPDATE_PRIMARY_ACTIVITIES_PLACEHOLDER</Trans>
          </div>
        </div>

        <div>
          <div style={styles.text}>
            <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_SUBTITLE</Trans>: <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_DATE_PLACEHOLDER</Trans>
          </div>
          <div style={styles.text}>
            <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_PRIMARY_ACTIVITIES_PLACEHOLDER</Trans>
          </div>
        </div>

        <div>
          <div>
            <div style={styles.text}><Trans>FORM_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_QUESTION</Trans></div>
          </div>
          <div>
            <div style={styles.text}>
              <Trans>FORM_MILESTONES_UPDATE_MILESTONE_UPDATE_PRIMARY_ACTIVITIES_PLACEHOLDER</Trans>
            </div>
          </div>
        </div>

        <div>
          <div style={styles.text}><Trans>FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_TITLE</Trans></div>
        </div>

        <div>
          <div style={styles.text}>
            <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_SUBTITLE</Trans>: <Trans>FORM_MILESTONE_UPDATE_LAST_REPORT_DATE_PLACEHOLDER</Trans>
          </div>
          <div style={styles.text}>
            <Trans>FORM_MILESTONES_UPDATE_IMPLEMENTED_ACTIVITIES_PLACEHOLDER</Trans>
          </div>
        </div>

        <div>
          <div>
            <div style={styles.text}><Trans>FORM_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_QUESTION</Trans></div>
          </div>
          <div>
            <div style={styles.text}>
              <Trans>FORM_MILESTONES_UPDATE_MILESTONE_UPDATE_IMPLEMENTED_ACTIVITIES_PLACEHOLDER</Trans>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

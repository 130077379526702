import { t, Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  contentDocumentDownloadUrl,
  deleteDocumentByFlow,
  uploadFileToMultipleObjects
} from 'app/services/sfAuth/sfData/sfFiles'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { useField, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { myI18n } from 'translation/I18nConnectedProvider'
import { FormErrorLabel } from '../../../common/labels/FormErrorLabel'
import { parseFormLabelText } from '../../common/Common'
import { useFormContext } from '../../form-page/FormContext'
import { endEditingField } from '../../multiuser/grpcMultiuserEdit'
const crypto = require('crypto')

/**
 * Form element which renders a dropzone field that allows uploading file to connected SF object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {string[]} [typeProps.tags] Tags that will be assigned to the uploaded file.
 */
export const FormUploadFiles = ({
  id,
  langVersion,
  muBag,
  value,
  disabled,
  useMultiuser,
  connectedObject,
  typeProps,
  formikRef,
  describeMap,
  i18n,
  ...props
}) => {
  const preview = false

  const { network } = useFormContext()
  const { required, tags = [], uploadedDocumentType, addDateIndex } = typeProps
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(id)
  const classes = formUploadComponentStyles()
  const invalid = Boolean(!connectedObject || !connectedObject.Id) && !preview
  const uploadPrefix = parseFormLabelText({
    text: typeProps.uploadPrefix,
    i18n,
    langVersion
  })

  const initialUploadedFileNames = field.value.map(file => file.name)
  const [uploadedFileNames, setUploadedFileNames] = useState(initialUploadedFileNames || [])

  // function to add date in format YYYY-MM-DD to file name
  const addDateToFileName = (fileName) => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const extension = fileName.split('.').pop()
    const fileNameWithoutExtension = fileName.replace(`.${extension}`, '')
    return `${fileNameWithoutExtension}_${year}-${month}-${day}.${extension}`
  }

  // function to add index to file name to avoid duplicate file names
  const addIndexToFileName = (fileName) => {
    const isFilenameExist = uploadedFileNames.includes(fileName)
    if (isFilenameExist) {
      const extension = fileName.split('.').pop()
      fileName = fileName.replace(`.${extension}`, '')
      const fileNameParts = fileName.split('_')
      const fileNameIndex = fileNameParts[fileNameParts.length - 1]
      const fileNameWithoutIndex = isNaN(fileNameIndex)
        ? fileName
        : (fileNameParts.slice(0, fileNameParts.length - 1).join('_') || fileNameParts[0])
      // check if uploadedFileNames contains fileNameWithoutIndex
      // find the max index of fileNameWithoutIndex
      let maxIndex = 0
      uploadedFileNames.forEach((uploadedFileName) => {
        if (uploadedFileName.includes(fileNameWithoutIndex)) {
          const uploadedFileExtension = uploadedFileName.split('.').pop()
          if (uploadedFileExtension === extension) {
            uploadedFileName = uploadedFileName.replace(`.${uploadedFileExtension}`, '')
            const uploadedFileNameParts = uploadedFileName.split('_')
            let uploadedFileNameIndex = uploadedFileNameParts[uploadedFileNameParts.length - 1]
            if (isNaN(uploadedFileNameIndex)) {
              uploadedFileNameIndex = 1
            }
            if (uploadedFileNameIndex > maxIndex) {
              maxIndex = Number(uploadedFileNameIndex)
            }
          }
        }
      })
      const updatedFileNameIndex = maxIndex + 1
      return `${fileNameWithoutIndex}_${updatedFileNameIndex}.${extension}`
    }
    return fileName
  }

  const handleOnDrop = ({ files, id, fieldValue }) => {
    console.log('handleOnDrop', files, network)
    const listCount = files.length
    const uploadId = listCount + 1
    const file = files[0]
    let uploadedName = file.name
    if (uploadPrefix) {
      uploadedName = uploadPrefix + ' ' + uploadedName
    }
    if (addDateIndex) {
      uploadedName = addDateToFileName(uploadedName)
      uploadedName = addIndexToFileName(uploadedName)
    }

    const typePropsConnectedObjectIds = typeProps.connectedTo.map((item) => {
      return item.connectedObject
    })

    const connectedObjectIds = Object.entries(props.connectedMap)
      .filter(([key, value]) => typePropsConnectedObjectIds.includes(key))
      .filter(([key, value]) => !value.files.some(file => file.name === uploadedName))
      .map(([key, value]) => {
        return {
          id: key,
          sfObjectId: value.sfObject.Id
        }
      })

    if (connectedObjectIds.length === 0) {
      return enqueueSnackbar(<Trans>FORM_UPLOAD_FILES_ALREADY_EXISTS_WARNING</Trans>, {
        variant: 'warning'
      })
    }

    setUploadedFileNames([...uploadedFileNames, uploadedName])
    const newValue = [...formikRef.current.values[id]]
    const fakeId = crypto.randomBytes(8).toString('hex')
    newValue.push({
      uploadId,
      name: uploadedName,
      progress: !preview,
      actionId: fakeId
    })
    setFieldValue(id, newValue)
    if (useMultiuser) {
      endEditingField({
        ...muBag,
        fieldId: id,
        fieldValue: newValue
      })
    }
    if (preview) {
      enqueueSnackbar(<Trans>Uploaded File</Trans>, {
        variant: 'success'
      })
      return
    }
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
      const newValue = [...formikRef.current.values[id]]
      newValue.some((file, index) => {
        if (file.actionId === fakeId) {
          newValue.splice(index, 1)
          return true
        }
        return false
      })
      setFieldValue(id, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId: id,
          fieldValue: newValue
        })
      }
    }
    reader.onload = () => {
      const binaryStr = reader.result
      console.log('binary', binaryStr, reader)
      const uploadingKey = enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        const newValue = [...formikRef.current.values[id]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(id, newValue)
        closeSnackbar(uploadingKey)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId: id,
            fieldValue: newValue
          })
        }
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
      let tagsString = id
      if (tags.length > 0) {
        tags.filter(tag => tag).forEach(tag => (tagsString += '&tag&' + tag))
      }

      uploadFileToMultipleObjects({
        name: uploadedName,
        fileData: binaryStr,
        mainId: connectedObjectIds[0].sfObjectId,
        ids: connectedObjectIds.map(item => item.sfObjectId).filter((item, idx) => idx !== 0),
        networkId: network.Id,
        tags: tagsString,
        type: uploadedDocumentType
      }).then(uploadedFiles => {
        console.log('uploadedFiles', uploadedFiles)
        closeSnackbar(uploadingKey)

        enqueueSnackbar(<Trans>Uploaded File</Trans>, {
          variant: 'success'
        })

        const uploadedFile = uploadedFiles[0]

        const newValue = [...formikRef.current.values[id]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue[index] = {
              id: uploadedFile.Id,
              url: contentDocumentDownloadUrl(uploadedFile.Id),
              tags: tagsString,
              uploadId,
              name: uploadedName
            }
            return true
          }
          return false
        })
        setFieldValue(id, newValue)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId: id,
            fieldValue: newValue
          })
        }
      }).catch(error => {
        console.warn('cant upload file', error)
        enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
          variant: 'error'
        })
        handleError()
      })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id, files, fieldId, index }) => {
    if (preview) {
      const newValue = [...files.filter(item => item.id !== id)]
      setFieldValue(fieldId, newValue)
      if (useMultiuser) {
        endEditingField({
          ...muBag,
          fieldId,
          fieldValue: newValue
        })
      }
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      return
    }
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })

    const fakeId = crypto.randomBytes(8).toString('hex')
    const newValue = [...formikRef.current.values[fieldId]]
    newValue[index].deleting = true
    newValue[index].actionId = fakeId
    setFieldValue(fieldId, newValue)

    deleteDocumentByFlow(id).then(
      result => {
        enqueueSnackbar(<Trans>Deleted File</Trans>, {
          variant: 'success'
        })
        const newValue = [...formikRef.current.values[field.name]]
        newValue.some((file, index) => {
          if (file.actionId === fakeId) {
            newValue.splice(index, 1)
            return true
          }
          return false
        })
        setFieldValue(fieldId, newValue)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      },
      reject => {
        enqueueSnackbar(<Trans>Error ocurred while deleting file!</Trans>, {
          variant: 'error'
        })
        const newFiles = [...formikRef.current.values[field.name]]
        newFiles.some((file, index) => {
          if (file.actionId === fakeId) {
            delete newFiles[index].actionId
            delete newFiles[index].deleting
            return true
          }
          return false
        })
        // delete toSet[index].deleting
        setFieldValue('files', newFiles)
        if (useMultiuser) {
          endEditingField({
            ...muBag,
            fieldId,
            fieldValue: newValue
          })
        }
      }
    )
  }

  const createTable = ({ files = [], fieldId }) => {
    const columns = [t`Name`, t`Remove`]
    return (
      <TableContainer>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n?._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => {
              const iconDisabled = file.progress || file.deleting || disabled
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={file.url}>
                        {file.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n?._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem({
                              id: file.id,
                              name: file.name,
                              fieldId,
                              files,
                              index
                            })
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                file.progress && (
                  <TableRow key={files.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const isError = Boolean(meta.error)
  return (
    <div>
      {createTable({ files: field.value, fieldId: id })}
      <Dropzone
        disabled={disabled || invalid}
        multiple={false}
        maxFiles={1}
        onDrop={files => {
          handleOnDrop({
            files,
            id,
            fieldValue: field.value
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={disabled || invalid ? classes.disabled : classes.dropbox}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 10 }}>upload</Icon>
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </Grid>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
      {invalid && (
        <div style={{ padding: 10, color: 'red' }}>
          <Trans>There is no object connected in editor!</Trans>
        </div>
      )}
      <FormErrorLabel
        error={isError}
        id={id}
        required={required}
        label={<Trans>Maximum file size is 52 MB</Trans>}
      />
    </div>
  )
}

const styles = theme => ({
  dropbox: {
    margin: '20px 0',
    border: '2px dashed #0085B8',
    padding: 35,
    backgroundColor: 'rgba(0, 133, 184, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '3px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  disabled: {
    margin: '20px 0',
    border: '2px dashed rgba(158, 158, 158)',
    padding: 35,
    backgroundColor: 'rgba(245, 245, 245, 0.05)'
  }
})
export const formUploadComponentStyles = makeStyles(styles)

export const formUploadFilesConditionsStates = {
  minFiles: {
    label: <Trans>Set minimum number of files to upload</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          InputProps={{ inputComponent: DefaultNumericFormat }}
          label={<Trans>Number of files</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

import { Trans } from '@lingui/macro'
import { ICCEWorkplan, getBudgetLines } from './FormICCEWorkplan'
import { Alert } from '@material-ui/lab'

export const FormICCEWorkplanPrint = ({ value, title, ...props }) => {
  const { budget } = props.typeProps
  const budgetValue = props?.values?.[budget]
  const budgetLines = getBudgetLines(budgetValue)

  const _value = value.map(obj => {
    const budgetLine = budgetLines.find(item => item.id === obj.budgetLineRef)
    return { ...obj, budgetLine: budgetLine?.value || '' }
  })

  return (
    <div style={{ flex: 1 }}>
      <div className='form-print-title'>{title}</div>
      {_value.length === 0 && <Alert severity='info'>
        <Trans>There are no lines added to ICCE Workplan of this application</Trans>
                              </Alert>}
      {_value.length > 0 && <ICCEWorkplan workplanLines={_value} />}
    </div>
  )
}

import { t, Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { myI18n } from 'translation/I18nConnectedProvider'
import { Alert, AlertTitle } from '@material-ui/lab'

const SavingFailedWarningDialog = ({ handleClose, open, data, fileName }) => {
  const [values, setValues] = useState([])
  const [exporting, setExporting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const userLang = (useSelector(state => state.user.language) || 'en').split('_')[0]
  const history = useHistory()

  useEffect(() => {
    if (data) {
      const { current, formData } = data
      enqueueSnackbar(<Trans>Error ocurred while saving your data</Trans>, {
        variant: 'error'
      })
      const toSet = []
      Object.keys(current).forEach(key => {
        const valueLabel = current[key][userLang]
        const fieldValue = current[key].value
        let labelKey = userLang
        if (formData.type === 'form') {
          labelKey = 'title'
        }
        if (valueLabel && formData[key][labelKey]) {
          toSet.push({
            fieldValue,
            valueLabel,
            fieldLabel: formData[key][labelKey]
          })
        }
      })
      setValues(toSet)
    }
  }, [open])

  return (
    <Dialog disableBackdropClick maxWidth='lg' fullWidth open={open}>
      <DialogTitle>
        <span>
          <Trans>An error ocurred while trying to save your data</Trans>
        </span>
        <Alert severity='error' style={{ marginTop: 8 }}>
          <AlertTitle>
            <Trans>
              An error ocurred while saving. Please refrain from further editing
              and contact us via "Contact Us" button in the topbar
            </Trans>
          </AlertTitle>
        </Alert>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell width='60%'>
                  <b>
                    <Trans>Field</Trans>
                  </b>
                </TableCell>
                <TableCell align='right' width='40%'>
                  <b>
                    <Trans>Value</Trans>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((obj, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      style={{ padding: 8 }}
                    >
                      {obj.fieldLabel}
                    </TableCell>
                    <TableCell align='right' style={{ padding: 8 }}>
                      {obj.valueLabel}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid container justify='space-evenly'>
          <Button
            color='primary'
            variant='contained'
            onClick={() => history.goBack()}
            disabled={exporting}
          >
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='center'
            >
              <Trans>Exit</Trans>
              <Icon style={{ marginLeft: 5 }}>exit_to_app</Icon>
            </Grid>
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={exporting}
            onClick={() => {
              handleClose()
            }}
          >
            <Trans>Ignore</Trans>
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={exporting}
            onClick={() => {
              const workbook = new Excel.Workbook()
              const sheet = workbook.addWorksheet(myI18n._(t`Extracted data`), {
                views: [{ state: 'frozen', ySplit: 1 }]
              })
              sheet.columns = [
                { header: 'Field', width: 45, key: 'field' },
                { header: 'Field value', width: 45, key: 'value' }
              ]
              values.forEach(obj => {
                const labelString = obj.fieldLabel?.props
                  ? myI18n._(obj.fieldLabel.props.id)
                  : obj.fieldLabel

                let valueString = obj.fieldValue
                if (Array.isArray(valueString)) {
                  if (valueString.length === 0) {
                    valueString = ''
                  } else if (
                    valueString[0] &&
                    typeof valueString[0] === 'object'
                  ) {
                    valueString = JSON.stringify(valueString)
                  } else {
                    valueString = valueString.join(', ')
                  }
                } else if (valueString && typeof valueString === 'object') {
                  valueString = JSON.stringify(valueString)
                } else if (typeof valueString === 'boolean') {
                  valueString = valueString ? myI18n._(t`Yes`) : myI18n._(t`No`)
                } else if (/<[a-z/][\s\S]*>/i.test(valueString)) {
                  const h2p = require('html2plaintext')
                  valueString = h2p(valueString)
                }
                sheet.addRow({
                  field: labelString,
                  value: valueString
                })
              })
              let rowIndex = 1
              for (rowIndex; rowIndex <= sheet.rowCount; rowIndex++) {
                sheet.getRow(rowIndex).alignment = {
                  wrapText: true,
                  vertical: 'top'
                }
              }
              setExporting(true)
              workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                  type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, fileName + '.xlsx')
                setExporting(false)
              })
            }}
          >
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='center'
            >
              <Trans>Export data</Trans>
              <Icon style={{ marginLeft: 5 }}>download</Icon>
            </Grid>
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default SavingFailedWarningDialog

import { Trans } from '@lingui/macro'
import { authRoles } from 'app/auth/authRoles'
import { EgretLoadable } from 'egret'

const dmahRoutes = [
  {
    path: '/dmah/applications',
    component: EgretLoadable({
      loader: () => import('./DMAHApplications')
    }),
    auth: authRoles.DMAH,
    isTranslatable: true,
    label: <Trans>DMAH_TAB</Trans>
  },
  {
    path: '/dmah/application/:id',
    component: EgretLoadable({
      loader: () => import('./DMAHView')
    }),
    auth: authRoles.DMAH,
    label: <Trans>DMAH_ROUTES_DMAH_APPLICATION_ROUTE_LABEL</Trans>,
    isConditionable: true,
    id: 'dmah-application'
  }
]

export default dmahRoutes

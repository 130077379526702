import { Trans, t } from '@lingui/macro'
import {
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import { ENTITY_IS_LOCKED } from 'app/services/sfAuth/sfData/jsConnectionUtils'
import { deleteObjective } from 'app/services/sfAuth/sfData/sfObjectives'
import {
  CBTI_OBJECTIVE_RECORD_TYPE,
  CBTI_RECORD_TYPE,
  CHGF_CB_OBJECTIVE_RECORD_TYPE,
  CHGF_CB_RECORD_TYPE,
  CHGF_PP_OBJECTIVE_RECORD_TYPE,
  CHGF_PP_RECORD_TYPE,
  CHGF_RI_OBJECTIVE_RECORD_TYPE,
  CHGF_RI_RECORD_TYPE,
  STF_LP_OBJECTIVE_RECORD_TYPE,
  STF_LP_RECORD_TYPE,
  STF_SIP_OBJECTIVE_RECORD_TYPE,
  STF_SIP_RECORD_TYPE
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { FormHelptext } from 'app/views/forms/common/FormHelpText'
import { requiredTrans } from 'app/views/forms/formTranslations'
import { MUPreviouslySavedValuesPanel } from 'app/views/forms/multiuser/components/MUPreviouslySavedValuesPanel'
import { purgeInvalidPDFSignFromString } from 'app/views/forms/pdf-components/formPdfUtils'
import { FieldArray, useFormikContext } from 'formik'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { parseFormLabelText } from '../../../common/Common'
import MUTextField from '../../../multiuser/components/MUTextField'
import { endEditingField } from '../../../multiuser/grpcMultiuserEdit'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  dataPicker: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    width: '100%',
    marginBottom: 16
  },
  tcSpan4: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 35
  },
  tr: {
    borderBottom: 'solid 1px #c1c1c1',
    borderTop: 'solid 0px #ffffff'
  },
  tHeaderText: {
    textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraph: {
    marginBottom: '1rem'
  }
})
const useStyles = makeStyles(styles)

export const formObjectivesValueToText = v => {
  return languages.reduce((acc, lang) => {
    if (lang === 'en') {
      acc[lang] = (
        <div>
          <Trans>Data is too complex to show objectives!</Trans>
          <div style={{ color: '#e0a912' }}>
            <Trans>
              Overriding will ony save changes to already added objectives. It
              will not delete any newly added milestones. Manual cleanup may be
              required.
            </Trans>
          </div>
        </div>
      )
    } else {
      acc[lang] = <Trans>Data is too complex to show differences!</Trans>
    }
    return acc
  }, {})
}

export const FormObjectivesExtractKey = ({
  saveMap,
  sfObject,
  value,
  connectedObjectId,
  additionalSFInfo,
  appConfigurations
}) => {
  const { recordTypes } = additionalSFInfo
  const fundType = sfObject.RecordType.Name

  //For objective report type (Needed for creating one) we use value selected on funding stream the opoprtunity was created under
  const config = appConfigurations.find(config => config.id === sfObject.Funding_Stream__r.Id)
  let objectiveRecordType = config?.objectiveRecordType

  if (!objectiveRecordType) {
    switch (fundType) {
      case CBTI_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[CBTI_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      case STF_LP_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[STF_LP_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      case STF_SIP_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[STF_SIP_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      case CHGF_CB_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[CHGF_CB_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      case CHGF_PP_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[CHGF_PP_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      case CHGF_RI_RECORD_TYPE:
        objectiveRecordType =
          recordTypes[CHGF_RI_OBJECTIVE_RECORD_TYPE].recordTypeId
        break
      default:
        objectiveRecordType = null
        break
    }
  }
  if (!objectiveRecordType) {
    console.error('No objective record type was linked for: ', fundType)
  }
  const inner = value.map(item => ({
    Outcomes__c: item.outcomes.join(';'),
    Objective__c: item.objective,
    Identified_needs__c: item.needs,
    Id: item.id,
    RecordTypeId: objectiveRecordType
  }))
  saveMap[connectedObjectId].Objectives__r = inner
}

export const FormObjectivesDefaultValue = obj => {
  if (!obj || !obj.Objectives__r) {
    return []
  }
  return obj.Objectives__r.records.map(objective => ({
    outcomes: objective.Outcomes__c
      ? purgeInvalidPDFSignFromString(objective.Outcomes__c).split(';')
      : [''],
    objective: objective.Objective__c,
    needs: purgeInvalidPDFSignFromString(objective.Identified_needs__c),
    id: objective.Id
  }))
}

const sizeTrans = <Trans>Must Have at least one Outcome</Trans>
const objectiveSizeTrans = <Trans>Must Have at least one Objective</Trans>
export const FormObjectivesValidation = ({ typeProps, ...props }) => {
  const { 
    outcomesRequired, 
    needsRequired, 
    outcomesMaxChar, 
    needsMaxChar 
  } = typeProps

  const objectiveShape = {
    objective: Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Objective</Trans>),
    needs: Yup.string().ensure(),
    outcomes: Yup.array()
      .ensure()
      .min(1, sizeTrans)
      .label(<Trans>Outcomes</Trans>)
  }

  if (outcomesRequired) {
    objectiveShape.outcomes = objectiveShape.outcomes.test(
      'at-least-one-required',
      sizeTrans,
      function (outcomes) {
        if (!outcomes || outcomes.length === 0) {
          return this.createError({
            path: `${this.path}[0]`,
            message: sizeTrans
          })
        }

        if (!outcomes[0] || outcomes[0].trim() === '') {
          return this.createError({
            path: `${this.path}[0]`,
            message: requiredTrans
          })
        }

        return true
      }
    )
  }

  if (needsRequired) {
    objectiveShape.needs = objectiveShape.needs
      .required(requiredTrans)
      .label(<Trans>Needs</Trans>)
  }

  if (needsMaxChar) {
    objectiveShape.needs = objectiveShape.needs
      .max(
        Number(needsMaxChar),
        ({ max }) =>
          myI18n?._(t`This fields length cannot exceed ${max} characters!`)
      );
  }

  if (outcomesMaxChar) {
    objectiveShape.outcomes = objectiveShape.outcomes.of(
      Yup.string()
        .ensure()
        .max(
          Number(outcomesMaxChar),
          ({ max }) =>
            myI18n?._(t`This fields length cannot exceed ${max} characters!`)
        )
    );
  }

  return Yup.array()
    .ensure()
    .min(1, objectiveSizeTrans)
    .label(<Trans>Objectives</Trans>)
    .of(Yup.object().shape(objectiveShape))
}

/**
 * Form element which allows adding and configuring project objectives. Each objective requiries selecting it type and providing objectives needs and list of outcomes. Objectives are saved as
 * FGM_Base__Objective__c child objects on connected Opportunity object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 * @param  {string[]} typeProps.options Array of phases defined in the form editor. For each one user will need to fill the required information.
 * @param  {number}  [typeProps.outcomesMaxChar] If provided, the number of characters that the outcomes text input fields will be restricted to.
 * @param  {number}  [typeProps.outcomesMaxWords] If provided, the number of words that the outcomes text input fields will be restricted to.
 * @param  {number}  [typeProps.outcomesRows=1] Number of rows which outcomes text input fields should occupy.
 * @param  {boolean} [typeProps.outcomesExpandable=false] If outcomes text fields should expand further below if new line is inserted.
 * @param  {number}  [typeProps.needsMaxChar] If provided, the number of characters that the needs text input field will be restricted to.
 * @param  {number}  [typeProps.needsMaxWords] If provided, the number of words that the needs text input field will be restricted to.
 * @param  {number}  [typeProps.needsRows=1] Number of rows which needs text input field should occupy.
 * @param  {boolean} [typeProps.needsExpandable=false] If needs text field should expand further below if new line is inserted.
 */
export const FormObjectives = ({
  id,
  useMultiuser,
  muBag,
  title,
  helpText,
  connectedObject,
  editMode,
  disabled,
  typeProps,
  langVersion,
  helptextAsMarkdown,
  i18n,
  ...item
}) => {
  const {
    outcomesMaxChar,
    outcomesMaxWords,
    outcomesRows,
    outcomesExpandable,
    needsMaxChar,
    needsMaxWords,
    needsRows,
    needsExpandable,
    outcomesRequired,
    needsRequired,
    options
  } = typeProps
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [t`Objectives`]
  const fundType = connectedObject
    ? connectedObject.RecordType.Id
    : STF_LP_RECORD_TYPE
  const [indexToDelete, setIndexToDelete] = React.useState(null)

  const { values, errors, setFieldTouched } = useFormikContext()

  const isElementLocked = Object.keys(values?.muInfo).find(key => {
    if (key.includes(id)) return true
    return false
  })

  const getOptions = () => {
    // const valueToLabel = {}
    // const sfData = describeMap?.Objective__c
    // sfData &&
    //   sfData.fields.some(field => {
    //     if (field.name === 'Objective__c') {
    //       field.picklistValues.forEach(option => {
    //         valueToLabel[option.value] = option.label
    //       })
    //     }
    //     return field.name === 'Objective__c'
    //   })
    const toReturn = []
    if (options) {
      options.forEach(option => {
        if (option.recordTypes && option.recordTypes.includes(fundType)) {
          toReturn.push({
            // label: valueToLabel[option.value] || myI18n?._(option.value),
            label: option.labels?.[user.language]
              ? option.labels?.[user.language]
              : myI18n?._(option.value),
            value: option.value
          })
        }
      })
    }
    return toReturn
  }

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  const needsHelpTextLabel = parseFormLabelText({
    text: typeProps.needsHelpText,
    i18n,
    langVersion
  })
  const outcomesHelpTextLabel = parseFormLabelText({
    text: typeProps.outcomesHelpText,
    i18n,
    langVersion
  })

  return (
    <Paper className={classes.root}>
      <h3>{title}</h3>
      <FormHelptext parseToHtml={helptextAsMarkdown} helpText={helpText} />
      <FieldArray
        name={id}
        render={({ push, form, name, remove }) => {
          const objectives = form.values[name] || []
          const alreadySelected = {}
          objectives.forEach((obj, oIndex) => {
            if (obj.objective) {
              if (alreadySelected[obj.objective]) {
                alreadySelected[obj.objective].push(oIndex)
              } else {
                alreadySelected[obj.objective] = [oIndex]
              }
            }
          })

          return (
            <div>
              <div style={{ paddingLeft: 15, paddingTop: 10 }}>
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  disabled={disabled}
                  onClick={() => {
                    push({
                      objective: '',
                      needs: '',
                      outcomes: ['']
                    })
                    setFieldTouched(id)
                    if (useMultiuser) {
                      endEditingField({
                        ...muBag,
                        fieldId: id,
                        fieldValue: [
                          ...objectives,
                          {
                            objective: '',
                            needs: '',
                            outcomes: ['']
                          }
                        ]
                      })
                    }
                  }}
                >
                  <Icon color='inherit'>add</Icon>
                  <span>
                    <Trans>New Objective</Trans>
                  </span>
                </Button>
              </div>
              <div className='w-100 overflow-auto' style={{ padding: 20 }}>
                <Table style={{ whiteSpace: 'pre' }}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                      {columns.map((item, i) => (
                        <TableCell key={i} style={{ paddingLeft: 35 }}>
                          <p
                            style={{
                              textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                              fontWeight: 'bold'
                            }}
                          >
                            <Trans id={item} />
                          </p>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectives.map((item, index) => [
                      <TableRow key={index}>
                        <TableCell style={{ paddingLeft: 20 }}>
                          <Grid
                            wrap='nowrap'
                            container
                            style={{
                              paddingLeft: 15,
                              paddingTop: 15,
                              paddingBottom: 15
                            }}
                          >
                            <MUTextField
                              select
                              actionIconButtona={
                                <Grid item>
                                  <IconButton
                                    disabled={disabled || isElementLocked}
                                    variant='filled'
                                    onClick={() => {
                                      setIndexToDelete(index)
                                    }}
                                  >
                                    <Icon
                                      color={
                                        disabled || isElementLocked
                                          ? 'inherit'
                                          : 'error'
                                      }
                                      className='material-icons MuiIcon-root'
                                      variant='filled'
                                    >
                                      delete
                                    </Icon>
                                  </IconButton>
                                </Grid>
                              }
                              disabled={disabled}
                              displayFieldHistoryIcon
                              label={myI18n?._(t`Objective`)}
                              muBag={muBag}
                              useMultiuser={useMultiuser}
                              id={id + `[${index}].objective`}
                              required
                            >
                              {options
                                ?.filter(option => {
                                  return (
                                    !alreadySelected[option.value] ||
                                    alreadySelected[option.value].includes(
                                      index
                                    )
                                  )
                                })
                                .map(item => {
                                  return (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.labels
                                        ? (
                                            getLabelFromTranslationData({
                                              data: item.labels,
                                              langVersion
                                            })
                                          )
                                        : (
                                          <Trans id={item.value} />
                                          )}
                                    </MenuItem>
                                  )
                                })}
                            </MUTextField>
                          </Grid>
                        </TableCell>
                      </TableRow>,
                      <TableRow key={index + objectives.length}>
                        <TableCell
                          style={{
                            paddingLeft: 75,
                            paddingTop: 25,
                            paddingBottom: 25,
                            paddingRight: 35
                          }}
                        >
                          <Grid container direction='row' alignItems='center'>
                            <div
                              style={{ marginBottom: 5, fontWeight: 'bold' }}
                            >
                              <Trans>Needs</Trans>
                            </div>
                            <MUPreviouslySavedValuesPanel
                              disabled={disabled}
                              muBag={muBag}
                              fieldId={id + `[${index}].needs`}
                              useMultiuser={useMultiuser}
                            />
                          </Grid>

                          <FormHelptext
                            parseToHtml
                            helpText={needsHelpTextLabel}
                          />
                          <MUTextField
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            id={id + `[${index}].needs`}
                            label=''
                            multiline
                            minRows={needsRows || 5}
                            maxRows={needsExpandable ? null : needsRows || 5}
                            disabled={disabled}
                            margin='normal'
                            required={needsRequired}
                            limit={needsMaxChar && Number(needsMaxChar)}
                            maxWords={needsMaxWords && Number(needsMaxWords)}
                          />
                        </TableCell>
                      </TableRow>,
                      <TableRow key={index + objectives.length * 2}>
                        <TableCell style={{ paddingLeft: 75 }}>
                          <FieldArray
                            name={id + `[${index}].outcomes`}
                            render={({ push, remove }) => (
                              <>
                                <div>
                                  <div key={-1}>
                                    <Grid
                                      container
                                      spacing={1}
                                      style={{ marginBottom: 5 }}
                                    >
                                      <Grid
                                        item
                                        style={{
                                          paddingTop: 25,
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        {myI18n?._(t`Outcomes`)}
                                      </Grid>
                                    </Grid>
                                    <FormHelptext
                                      parseToHtml
                                      helpText={outcomesHelpTextLabel}
                                    />
                                  </div>
                                  <FormErrorLabel
                                    error={Boolean(
                                      _.get(errors, `${id}[${index}].outcomes`)
                                    )}
                                    id={`${id}[${index}].outcomes`}
                                  />
                                  {item.outcomes.map(
                                    (outcome, outcomeIndex) => [
                                      <>
                                        <Grid
                                          container
                                          spacing={2}
                                          key={outcomeIndex}
                                          alignItems='center'
                                        >
                                          <Grid item xs>
                                            <MUTextField
                                              actionIconButton={
                                                outcomeIndex > 0 && (
                                                  <Grid item>
                                                    <IconButton
                                                      variant='contained'
                                                      color='secondary'
                                                      type='button'
                                                      disabled={
                                                        disabled ||
                                                        isElementLocked
                                                      }
                                                      onClick={() => {
                                                        remove(outcomeIndex)
                                                        if (useMultiuser) {
                                                          const newValue = [
                                                            ...item.outcomes
                                                          ]
                                                          newValue.splice(
                                                            outcomeIndex,
                                                            1
                                                          )
                                                          endEditingField({
                                                            ...muBag,
                                                            fieldId:
                                                              id +
                                                              `[${index}].outcomes`,
                                                            fieldValue: newValue
                                                          })
                                                        }
                                                      }}
                                                    >
                                                      <Icon
                                                        color={
                                                          disabled ||
                                                          isElementLocked
                                                            ? 'inherit'
                                                            : 'error'
                                                        }
                                                      >
                                                        delete
                                                      </Icon>
                                                    </IconButton>
                                                  </Grid>
                                                )
                                              }
                                              useMultiuser={useMultiuser}
                                              muBag={muBag}
                                              id={
                                                id +
                                                `[${index}].outcomes[${outcomeIndex}]`
                                              }
                                              label=''
                                              multiline
                                              minRows={outcomesRows || 2}
                                              maxRows={
                                                outcomesExpandable
                                                  ? null
                                                  : outcomesRows || 2
                                              }
                                              disabled={disabled}
                                              margin='normal'
                                              limit={
                                                outcomesMaxChar &&
                                                Number(outcomesMaxChar)
                                              }
                                              maxWords={
                                                outcomesMaxWords &&
                                                Number(outcomesMaxWords)
                                              }
                                              required={
                                                outcomesRequired &&
                                                outcomeIndex === 0
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <MUPreviouslySavedValuesPanel
                                              disabled={disabled}
                                              muBag={muBag}
                                              fieldId={
                                                id +
                                                `[${index}].outcomes[${outcomeIndex}]`
                                              }
                                              useMultiuser={useMultiuser}
                                            />
                                          </Grid>
                                        </Grid>
                                      </>
                                    ]
                                  )}
                                </div>
                                <Grid
                                  item
                                  style={{
                                    paddingTop: 18,
                                    paddingLeft: 10,
                                    paddingBottom: 10
                                  }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    type='button'
                                    size='small'
                                    disabled={disabled}
                                    onClick={() => {
                                      push('')
                                      setFieldTouched(id)
                                      if (useMultiuser) {
                                        endEditingField({
                                          userId: user.userId,
                                          ...muBag,
                                          fieldId: id + `[${index}].outcomes`,
                                          fieldValue: [...item.outcomes, '']
                                        })
                                      }
                                    }}
                                  >
                                    <Icon color='inherit'>add</Icon>
                                  </Button>
                                </Grid>
                              </>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ])}
                  </TableBody>
                </Table>
              </div>
              <FormErrorLabel error={Boolean(_.get(errors, `${id}`))} id={id} />
              <Dialog
                open={Boolean(indexToDelete) || indexToDelete === 0}
                onClose={() => {
                  setIndexToDelete(null)
                }}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogTitle id='alert-dialog-slide-title'>
                  <Trans>Are you sure you want to delete this objective?</Trans>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    <Trans>This process cannot be undone.</Trans>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setIndexToDelete(null)
                    }}
                    color='default'
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    onClick={() => {
                      const toDelete = objectives[indexToDelete]
                      const modalId = toDelete.id
                      if (modalId) {
                        enqueueSnackbar(<Trans>Deleting</Trans>, {
                          variant: 'info'
                        })
                        deleteObjective(modalId)
                          .then(() => {
                            enqueueSnackbar(<Trans>Deleted</Trans>, {
                              variant: 'success'
                            })
                            remove(indexToDelete)
                            if (useMultiuser) {
                              const newValue = [...objectives]
                              newValue.splice(indexToDelete, 1)
                              endEditingField({
                                ...muBag,
                                fieldId: id,
                                fieldValue: newValue
                              })
                            }
                          })
                          .catch(error => {
                            if (error && error.errorCode === ENTITY_IS_LOCKED) {
                              enqueueSnackbar(
                                <Trans>
                                  Application is Locked: Contact the
                                  Administrator
                                </Trans>,
                                { variant: 'error' }
                              )
                            } else {
                              enqueueSnackbar(<Trans>Can't Delete</Trans>, {
                                variant: 'error'
                              })
                            }
                          })
                      } else {
                        remove(indexToDelete)
                      }
                      setIndexToDelete(null)
                    }}
                    variant='contained'
                    color='error'
                  >
                    <Trans>Yes, delete it forever</Trans>
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        }}
      />
    </Paper>
  )
}

/** Helper component for printing objectives
 * @param {Object} data - Object containing objectives data.
 * @param {number} index - Index of the objective.
 * @returns {JSX.Element}
 */
export const Objective = ({ data, index }) => {
  const { objective, needs, outcomes = [] } = data
  return (
    <Paper
      elevation={6}
      key={index}
      style={{ width: '100%', padding: 10, marginTop: 15 }}
    >
      <div style={{ padding: 8 }}>
        <Typography className='form-print-subtitle'>
          <Trans>Objective</Trans>
        </Typography>
        <Typography>{objective}</Typography>
      </div>
      <Divider />
      <div style={{ padding: 8 }}>
        <Typography className='form-print-subtitle'>
          <Trans>Needs</Trans>
        </Typography>
        <Typography>{needs}</Typography>
      </div>
      <Divider />
      <div style={{ padding: 8 }}>
        <Typography className='form-print-subtitle'>
          <Trans>Outcomes</Trans>
        </Typography>
        <List>
          {Boolean(outcomes.length === 0) && (
            <ListItem style={{ display: 'flex' }}>
              <Trans>This list is empty!</Trans>
            </ListItem>
          )}
          {outcomes.map((item, index) => {
            return (
              <ListItem key={index} style={{ display: 'flex' }}>
                <ListItemIcon>
                  <Icon>fiber_manual_record</Icon>
                </ListItemIcon>

                <Typography>
                  <Trans id={item} />
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </div>
    </Paper>
  )
}

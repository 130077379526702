import { Trans } from '@lingui/macro'
import {
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core'
import { portalLanguagesData } from 'app/appSettings'
import { useState } from 'react'
import { getEmptyLangValues } from 'utils'
import { CKEditor } from '../page-layouts/CKEditor/CKEditor'
import TranslationConnectionsDialog from './TranslationConnectionsDialog'

const ConfigureMultilanguageTextFieldWithCKEditor = ({
  style = {},
  maxCharacters,
  fullWidth = true,
  value = {},
  handleChange,
  allowConnectingToPortalTranslation = true,
  disabled,
  customValueDisplay,
  inputProps,
  dialogOpen,
  setDialogOpen,
  langVersion,
  ...props
}) => {
  const [showInColumn, setShowInColumn] = useState(false)
  const { portalTranslationId } = value
  const [selectedLanguageKey, setSelectedLanguageKey] = useState('en')
  const [editorModes, setEditorModes] = useState(() => {
    const modes = {}
    Object.values(portalLanguagesData).forEach(language => {
      modes[language.editKey] = false
    })
    return modes
  })
  const isId = allowConnectingToPortalTranslation && portalTranslationId

  console.log('langVersion in CMTFWCKE: ', langVersion)

  // const ckeditorConfig = {
  //   toolbar: [
  //     { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
  //     { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
  //     { name: 'links', items: ['Link', 'Unlink'] },
  //     '/',
  //     { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
  //     { name: 'justify', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
  //   ],
  //   extraPlugins: 'justify, font, entities',
  // }

  const handleLanguageChange = e => {
    const newLanguageKey = e.target.value
    setEditorModes(prevModes => ({
      ...prevModes,
      [newLanguageKey]: prevModes[selectedLanguageKey]
    }))
    setSelectedLanguageKey(newLanguageKey)
  }

  const handleEditorChange = (editKey, v) => {
    const newValue = { ...value, [editKey]: v }
    handleChange(newValue, v, editKey)
  }

  const toggleViewMode = () => {
    setShowInColumn(!showInColumn)
  }

  const getDisplayedValue = editKey => {
    if (customValueDisplay) {
      return customValueDisplay(value, editKey)
    }
    return isId ? portalTranslationId : value[editKey] || ''
  }

  const saveTranslationConnection = id => {
    const newValue = getEmptyLangValues()
    newValue.portalTranslationId = id
    handleChange(newValue)
  }

  const clearTranslationConnection = () => {
    const newValue = { portalTranslationId: '' }
    handleChange(newValue)
  }

  return (
    <>
      <Grid
        fullWidth
        container
        alignItems='flex-start'
        direction='row'
        spacing={2}
        style={style}
      >
        {!allowConnectingToPortalTranslation && (
          <Grid item style={{ textAlign: 'center' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              {showInColumn
                ? (
                  <Trans>MULTILANGUAGE_EDIT_COLUMNS</Trans>
                  )
                : (
                  <Trans>MULTILANGUAGE_EDIT_DROPDOWN</Trans>
                  )}
              <Switch
                checked={showInColumn}
                onChange={toggleViewMode}
                color='primary'
              />
            </div>
          </Grid>
        )}
        {allowConnectingToPortalTranslation && (
          <Grid item>
            {isId
              ? (
                <Tooltip
                  title={<Trans>MULTILANGUAGE_EDIT_CLEAR_CONNECTION</Trans>}
                >
                  <IconButton
                    onClick={clearTranslationConnection}
                    size='small'
                    style={{ marginTop: 12 }}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Tooltip>
                )
              : (
                <Tooltip
                  title={
                    <Trans>
                      MULTILANGUAGE_EDIT_CONNECT_TO_PORTAL_TRANSLATION
                    </Trans>
                }
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 11,
                        marginRight: 10
                      }}
                    >
                      {showInColumn
                        ? (
                          <Trans>MULTILANGUAGE_EDIT_COLUMNS</Trans>
                          )
                        : (
                          <Trans>MULTILANGUAGE_EDIT_DROPDOWN</Trans>
                          )}
                      <div style={{ marginTop: 15 }}>
                        <Switch
                          checked={showInColumn}
                          onChange={toggleViewMode}
                          color='primary'
                        />
                      </div>
                    </div>
                    <IconButton onClick={() => setDialogOpen(true)} size='small'>
                      <Icon>language</Icon>
                    </IconButton>
                  </Grid>
                </Tooltip>
                )}
          </Grid>
        )}
        <Grid item xs>
          {showInColumn && !isId
            ? (
              <Grid
                container
                fullWidth
                direction='column'
                wrap='nowrap'
                style={{ paddingTop: 16 }}
                sx={{ direction: 'column', justifyContent: 'center' }}
              >
                {Object.values(portalLanguagesData).map(language => (
                  <Grid
                    item
                    xs
                    key={language.editKey}
                    style={{ marginBottom: 10, marginLeft: 6 }}
                  >
                    <h6 style={{ marginBottom: 14, marginLeft: 8 }}>
                      {language.labelTrans}
                    </h6>
                    <CKEditor
                      disabled={!selectedLanguageKey || isId || disabled}
                      value={getDisplayedValue(language.editKey) || ''}
                      setValue={v => handleEditorChange(selectedLanguageKey, v)}
                      langVersion={langVersion}
                    />
                  </Grid>
                ))}
              </Grid>
              )
            : (
              <Grid
                container
                direction='row'
                alignItems='flex-start'
                justifyContent='center'
                spacing={2}
              >
                <Grid item xs style={{ marginTop: 14 }}>
                  <CKEditor
                    disabled={!selectedLanguageKey || isId || disabled}
                    value={getDisplayedValue(selectedLanguageKey) || ''}
                    setValue={v => handleEditorChange(selectedLanguageKey, v)}
                    langVersion={langVersion}
                  />
                </Grid>
                {!isId && (
                  <Grid item style={{ width: 150, marginTop: 14 }}>
                    <TextField
                      select
                      variant='outlined'
                      fullWidth
                      value={selectedLanguageKey}
                      onChange={handleLanguageChange}
                      label={<Trans>Language</Trans>}
                    >
                      {Object.values(portalLanguagesData).map(obj => (
                        <MenuItem key={obj.editKey} value={obj.editKey}>
                          {obj.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              )}
        </Grid>
      </Grid>
      <TranslationConnectionsDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        saveTranslationConnection={saveTranslationConnection}
      />
    </>
  )
}

export default ConfigureMultilanguageTextFieldWithCKEditor

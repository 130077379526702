import { Trans } from '@lingui/macro'
import { Grid, Icon } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Dropzone from 'react-dropzone'
import { dropboxStyles } from './UploadFilesField'

/**
 * Styled file dropzone
 * @category Common components
 * @subcategory Inputs
 * @component
 * @returns {JSX.Element}
 * @property {boolean} disabled If dropzone should be disabled.
 * @property {function} handleUpload Function that handles file upload.
 * @property {boolean} [allowMultipleFiles=false] If dropzone should allow for uploading more than one file.
 * @property {boolean} [maxFiles=1] Maximum number of files that can be uploaded to the dropzone.
 */
export const FileDropzone = ({
  disabled,
  allowMultipleFiles,
  handleUpload,
  maxFiles,
  ...props
}) => {
  const classes = dropboxStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleOnDrop = (files, e) => {
    const file = files[0]
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
    }
    reader.onload = () => {
      const binaryStr = reader.result
      const data = reader.result
      if (handleUpload) {
        handleUpload({ ...file, data })
      }
      const uploadingKey = enqueueSnackbar(<Trans>FILE_UPLOAD_SUCCESS</Trans>, {
        variant: 'info'
      })
      const handleError = () => {
        closeSnackbar(uploadingKey)
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
    }
    reader.readAsArrayBuffer(file)
  }

  return (
    <Dropzone
      disabled={disabled}
      multiple={false}
      maxFiles={1}
      onDrop={handleOnDrop}
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={disabled ? classes.disabled : classes.dropbox}
        >
          <section>
            <input {...getInputProps()} />
            <div
              style={{
                textAlign: 'center'
              }}
            >
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='center'
              >
                <Icon style={{ marginRight: 10 }}>upload</Icon>
                <Trans>Drag 'n' drop file here, or click to select file</Trans>
              </Grid>
            </div>

            <div />
          </section>
        </div>
      )}
    </Dropzone>
  )
}

import { useHistory } from 'react-router'
import HomepageButton from '../../../../grants/HomepageButton'
import { parseFormLabelText } from 'app/views/forms/common/Common'

export const FormRedirectButton = ({
  title,
  typeProps: { url, color, openInNewTab },
  ...props
}) => {
  const history = useHistory()
  const parsedUrl = parseFormLabelText({ text: url, returnString: true, ...props })
  return (
    <div>
      <HomepageButton
        text={title}
        link
        url={parsedUrl}
        color={color}
        openInNewTab={openInNewTab}
        {...(!openInNewTab && {
          onClick: e => {
            e.preventDefault()
            if (url.startsWith('http')) {
              window.location.href = url
            } else {
              history.push(url)
            }
          }
        })}
      />
    </div>
  )
}

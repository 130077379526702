import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TextFieldWithClear } from 'app/views/common-components/SelectFieldWithClear'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import ConfigureMultilanguageTextField from 'app/views/internal/ConfigureMultilanguageTextField'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import DebouncedTextField from '../../common/DebouncedTextField'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { PdfPropsForm } from '../../common/PdfPropsForm'
import { FormEditorDividerWrapper } from '../../editor/FormEditorDividerWrapper'
import { getPicklistApiValuesFromObjectDefinition } from '../../editor/formEditorHelpers'
import { FormUploadFiles } from './FormUploadFiles'
import { FormUploadFilesReadOnly } from './FormUploadFilesReadOnly'

/** default tags shown in autocomplete */
const defaultTags = []

export const FormEditorUploadFiles = ({
  typeProps = {},
  describeMap,
  showEditableProps,
  showPrintProps,
  showPrintComponent,
  editMode,
  depth,
  injectable,
  showPdfProps,
  ...props
}) => {
  const {
    required,
    minFiles,
    tags = [],
    validTypes = [],
    uploadedDocumentType,
    uploadPrefix,
    displayAllFiles,
    addDateIndex // add date with index if not unique to file name
  } = typeProps
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { objects } = values
  const possibleTypes = getPicklistApiValuesFromObjectDefinition({
    objectType: 'ContentVersion',
    fieldName: 'Type__c',
    objectsDefinitions: objects
  })

  if (!editMode) {
    if (showPrintComponent) {
      return (
        <FormUploadFilesReadOnly
          typeProps={typeProps}
          editorPreview
          {...props}
        />
      )
    }

    return <FormUploadFiles typeProps={typeProps} preview {...props} />
  }

  return (
    <div>
      {showEditableProps && (
        <>
          <FormEditorDividerWrapper>
            <Grid container style={{ padding: 10 }}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={required}
                      onChange={(e) => {
                        const toSet = { ...typeProps }
                        toSet.required = e.target.checked
                        delete toSet.minFiles
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={<Trans>Required</Trans>}
                />
              </Grid>
              {required && (
                <Grid item>
                  <DebouncedTextField
                    variant='outlined'
                    label={<Trans>Minimum number of files</Trans>}
                    fullWidth
                    value={minFiles}
                    InputProps={{ inputComponent: DefaultNumericFormat }}
                    onChange={(e) => {
                      const toSet = { ...typeProps }
                      toSet.minFiles = e.target.value
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </FormEditorDividerWrapper>

          <FormEditorDividerWrapper>
            <ConfigureMultilanguageTextField
              value={uploadPrefix}
              label={<Trans>Uploaded file prefix</Trans>}
              handleChange={(value) => {
                const toSet = { ...typeProps }
                toSet.uploadPrefix = value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              useDebounce
            />
          </FormEditorDividerWrapper>

          <FormEditorDividerWrapper>
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ paddingBottom: 16 }}
            >
              <TooltipLabelIcon
                tooltip={
                  <span>
                    If document type is provided, it will be set in Type__c
                    field od Content Version on file upload
                  </span>
                }
              />
              <Typography>
                <Trans>FORM_EDITOR_UPLOAD_FILES_SET_DOCUMENT_TYPE</Trans>
              </Typography>
            </Grid>

            <TextFieldWithClear
              select
              variant='outlined'
              label={<Trans>FORM_EDITOR_UPLOAD_FILES_DOCUMENT_TYPE</Trans>}
              fullWidth
              value={uploadedDocumentType}
              onClear={(e) => {
                const toSet = { ...typeProps }
                delete toSet.uploadedDocumentType
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              onChange={(e) => {
                const toSet = { ...typeProps }
                toSet.uploadedDocumentType = e.target.value
                if (!validTypes.includes(e.target.value)) {
                  toSet.validTypes = [...validTypes, e.target.value]
                }
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              {possibleTypes.map((obj) => (
                <MenuItem key={obj.value} value={obj.value}>
                  {obj.label}
                </MenuItem>
              ))}
            </TextFieldWithClear>
          </FormEditorDividerWrapper>

          <FormEditorDividerWrapper>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addDateIndex}
                  onChange={(e) => {
                    const toSet = { ...typeProps }
                    toSet.addDateIndex = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={
                <Trans>
                  FORM_EDITOR_UPLOAD_FILES_ADD_DATE_WITH_INDEX_CHECKBOX
                </Trans>
              }
            />
          </FormEditorDividerWrapper>

          <FormEditorDividerWrapper>
            <Grid container direction='row' alignItems='center'>
              <TooltipLabelIcon
                tooltip={
                  <span>
                    All files uploaded to this element will be tagged with tags
                    provided. Providing tags does not influence what files will
                    be shown as uploaded to the connected object
                  </span>
                }
              />
              <Typography>
                <Trans>Tags</Trans>
              </Typography>
              <IconButton
                onClick={() => {
                  tags.push('')
                  typeProps.tags = tags
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...typeProps }
                  })
                }}
              >
                <Icon>add</Icon>
              </IconButton>
            </Grid>
            {tags.map((tag, index) => (
              <>
                <Grid
                  item
                  container
                  direction='row'
                  key={index}
                  justifyContent='space-between'
                  alignItems='center'
                  wrap='nowrap'
                >
                  {index + 1 + '. '}
                  <Grid item style={{ flex: 1, padding: 10 }}>
                    <Autocomplete
                      autoComplete
                      options={defaultTags
                        .filter((defaultTag) => !tags.includes(defaultTag))
                        .filter((defaultTag) => defaultTag.includes(tag))}
                      value={tag}
                      freeSolo
                      onChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.tags[index] = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      onInputChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.tags[index] = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant='outlined'
                          label={<Trans>Tag</Trans>}
                          fullWidth
                          value={tag}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <div style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        const toSet = { ...typeProps }
                        toSet.tags.splice(index, 1)
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </div>
                </Grid>
              </>
            ))}
          </FormEditorDividerWrapper>
        </>
      )}

      <FormEditorDividerWrapper>
        <Grid container direction='row' alignItems='center'>
          <TooltipLabelIcon
            tooltip={
              <span>
                If valid document types are provided, the form element will only
                display the fiels uploaded to the connected object matching the
                types selected
              </span>
            }
          />
          <Typography>
            <Trans>FORM_EDITOR_UPLOAD_FILES_VALID_DOCUMENT_TYPES</Trans>
          </Typography>
          <IconButton
            onClick={() => {
              typeProps.validTypes = [...validTypes, '']
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...typeProps }
              })
            }}
          >
            <Icon>add</Icon>
          </IconButton>
        </Grid>
        {validTypes.map((type, index) => (
          <Grid
            item
            container
            direction='row'
            key={index}
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            {index + 1 + '. '}
            <Grid item style={{ flex: 1, padding: 10 }}>
              <TextField
                select
                variant='outlined'
                label={<Trans>FORM_EDITOR_UPLOAD_FILES_DOCUMENT_TYPE</Trans>}
                fullWidth
                value={type}
                onChange={(e) => {
                  const toSet = { ...typeProps }
                  toSet.validTypes[index] = e.target.value
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                {possibleTypes.map((obj) => (
                  <MenuItem key={obj.value} value={obj.value}>
                    {obj.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <div style={{ width: 50 }}>
              <IconButton
                disabled={type === uploadedDocumentType}
                onClick={() => {
                  const toSet = { ...typeProps }
                  toSet.validTypes.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </div>
          </Grid>
        ))}
      </FormEditorDividerWrapper>

      <FormEditorDividerWrapper closing>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayAllFiles}
              onChange={(e) => {
                const toSet = { ...typeProps }
                toSet.displayAllFiles = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={
            <Trans>Display all files uploaded to object in this element</Trans>
          }
        />
      </FormEditorDividerWrapper>

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        // disableMultiple={true}
        injectable={injectable}
        depth={depth}
        typeProps={typeProps}
        noField
      />
    </div>
  )
}

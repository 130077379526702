import { Trans } from '@lingui/macro'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { datetimeFormat } from 'app/appSettings'
import { Field } from 'formik'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getLabelFromTranslationData } from '../../common/TranslationsCommon'
import {
  PrequalificationFundBanner,
  prequalificationStageToMessages
} from './old-application/PrequalificationFundBanner'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 10,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: (props) =>
      props.disabled
        ? theme.palette.action.disabledBackground
        : props.highlight
          ? theme.palette.secondary.light
          : 'inherit',
    filter: (props) => (props.disabled ? 'grayscale(1)' : '')
  },
  selectedCard: {
    maxWidth: 450,
    padding: 10,
    height: '100%',
    display: 'flex'
  },
  actions: {
    flexDirection: 'column'
  },
  button: {
    margin: 8
  },
  media: {
    backgroundSize: 'contain',
    height: 140
  }
}))

export const EligibilityCard = ({
  handleNext,
  selectApplicationType,
  rebuildInitialValues,
  resetForm,
  disabled,
  disabledMessage,
  id,
  image,
  logo,
  name,
  fullName,
  applicationRecordType,
  subtitle,
  description,
  moreInfoUrl,
  quiz,
  avaliableInLanguages,
  isPrequalification,
  currentStage = 'New',
  prequalificationId,
  endTime,
  hideProgramName,
  ...props
}) => {
  const history = useHistory()
  const classes = useStyles({ highlight: false })
  const langVersion = useSelector(
    (state) => state.user?.language?.split('_')[0] || 'en'
  )
  const langVersionToUse = avaliableInLanguages.includes(langVersion)
    ? langVersion
    : 'en'

  const nameLabel = getLabelFromTranslationData({
    langVersion: langVersionToUse,
    data: name
  })
  const subtitleLabel = getLabelFromTranslationData({
    langVersion: langVersionToUse,
    data: subtitle
  })
  const link = getLabelFromTranslationData({
    langVersion: langVersionToUse,
    data: moreInfoUrl
  })
  const descriptionLabel = getLabelFromTranslationData({
    langVersion: langVersionToUse,
    data: description
  })

  return (
    <Field name='applicationType'>
      {({ form: { setFieldValue, setValues, values }, field }) => {
        const checked = id === field.value
        return (
          <Card className={classes.root}>
            <CardActionArea
              disabled={disabled}
              onClick={() => {
                selectApplicationType({
                  formId: id,
                  quizId: quiz,
                  recordTypeId: applicationRecordType
                })
                handleNext(quiz)
              }}
            >
              <CardMedia className={classes.media} image={logo} title='logo' />
              <CardContent>
                {!hideProgramName && (
                  <Typography gutterBottom variant='h5' component='h2'>
                    {nameLabel}
                  </Typography>
                )}
                {(subtitleLabel && !hideProgramName)
                  ? (
                    <Typography gutterBottom variant='h6' component='h6'>
                      {subtitleLabel}
                    </Typography>
                    )
                  : (
                    <Typography gutterBottom variant='h6' component='h6'>
                      {nameLabel}
                    </Typography>
                    )}

                <Typography variant='body2' color='textSecondary' component='p'>
                  <Trans id={descriptionLabel} />
                  {checked}
                </Typography>
                {endTime && (
                  <Typography
                    style={{ marginTop: '0.35em' }}
                    variant='body2'
                    color='textSecondary'
                    component='p'
                  >
                    <b>
                      <Trans>FUNDING_STREAM_VALID_UNTIL</Trans>:{' '}
                    </b>
                    {moment(endTime).utc(true).format(datetimeFormat)}
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
            {isPrequalification && (
              <PrequalificationFundBanner currentStage={currentStage} />
            )}
            <CardActions className={classes.actions}>
              <Button
                disabled={disabled}
                color='primary'
                fullWidth
                variant='contained'
                className={classes.button}
                onClick={() => {
                  if (isPrequalification && currentStage) {
                    const shouldRedirect =
                      prequalificationStageToMessages[currentStage].redirect
                    if (shouldRedirect) {
                      history.push(
                        '/grants/prequalification/' + prequalificationId
                      )
                      return
                    }
                  }

                  selectApplicationType({
                    formId: id,
                    quizId: quiz,
                    recordTypeId: applicationRecordType
                  })
                  handleNext(quiz)
                }}
              >
                {isPrequalification
                  ? (
                      prequalificationStageToMessages[currentStage].button
                    )
                  : (
                    <Trans>START_APPLICATION</Trans>
                    )}
              </Button>
              {link && (
                <Button
                  disabled={disabled}
                  color='secondary'
                  variant='outlined'
                  className={classes.button}
                  fullWidth
                  href={link}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {/* <a href={link} target='_blank' rel='noreferrer noopener'> */}
                  <Trans>Learn More</Trans>
                  {/* </a> */}
                </Button>
              )}
              {disabledMessage && (
                <div style={{ color: '#f5543b' }}>{disabledMessage}</div>
              )}
            </CardActions>
          </Card>
        )
      }}
    </Field>
  )
}

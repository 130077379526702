import { Formik } from 'formik'
import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef
} from 'react'

export class BaseIncludedForm extends React.Component {
  state = {
    active: null
  }

  componentWillUnmount () {
    if (this.props.onUnmount) {
      if (this.props.dirty) {
        console.warn('BaseIncludedForm onUnmount')
        this.props.onUnmount(this.props.values, this.props)
      }
    } else {
      console.warn('no onUnmount', this.props.onUnmount)
    }
  }

  active () {
    return this.state.active
  }

  onFocusField (e) {
    // console.log('autosave email focus', e)
    this.setState({ active: e.target.id })
  }

  onBlurField (e) {
    // console.log('autosave email blur', e)
    this.setState({ active: null })
  }

  getActiveField () {
    return this.state.active
  }

  extractValue () {
    const extractedValues = {
      values: this.props.values,
      props: this.props,
      key: this.props.formId
    }
    console.log('extractedValues', extractedValues)
    return extractedValues
  }
}

export const withFormikIncluded =
  ({
    initialValues,
    validationSchema,
    validateOnBlur = true,
    validateOnChange = false,
    ...formikProps
  }) =>
    Component =>
      forwardRef(
        (
          {
            classes,
            onSubmit,
            onUnmount,
            suppliedValues,
            formId,
            keyValue,
            ...rest
          },
          ref
        ) => {
          const inputRef = useRef()
          console.log('inputref', inputRef)
          useImperativeHandle(ref, () => ({
            extractValue: () => {
              return inputRef.current.extractValue()
            },
            getActiveField: () => inputRef.current.getActiveField()
          }))

          const formRef = useRef()

          useLayoutEffect(() => {
            console.log('validateOnMount', formikProps.validateOnMount, formRef)
            formikProps.validateOnMount &&
            formRef &&
            formRef.current &&
            formRef.current.validateForm()
          }, [])

          return (
            <Formik
              initialValues={{ ...initialValues, ...suppliedValues }}
              validationSchema={validationSchema}
              enableReinitialize
              innerRef={formRef}
              validateOnBlur={validateOnBlur}
              validateOnChange={validateOnChange}
              {...formikProps}
              component={props => (
                <Component
                  key={keyValue}
                  keyValue={keyValue}
                  ref={inputRef}
                  onUnmount={onUnmount}
                  formId={formId}
                  suppliedValues={suppliedValues}
                  {...props}
                  {...rest}
                />
              )}
              classes={classes}
            />
          )
        }
      )

import { t } from '@lingui/macro'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import caCities from './canada_cities_data.json'
import divisionData from './census_divisions_by_id.json'

const styles = {
  text: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily,
    marginLeft: 8
  },
  question: {
    fontSize: 14,
    fontFamily: defaultFormPdfFontFamily,
    marginTop: 16,
    marginBottom: 8
  },
  description: {
    fontSize: 12,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginTop: 4,
    marginBottom: 4
  },
  required: {
    fontSize: 12,
    fontFamily: defaultFormPdfFontFamily,
    fontStyle: 'italic',
    marginLeft: 4,
    marginTop: 4
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 4
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    width: '48%',
    flexWrap: 'nowrap'
  },
  odd: {
    marginLeft: '4%'
  }
}

export const FormCensusDivisionFillablePdfEditor = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.description.fontFamily = inuktitutFormPdfFontFamily
    styles.question.fontFamily = inuktitutFormPdfFontFamily
    styles.text.fontFamily = inuktitutFormPdfFontFamily
    styles.required.fontFamily = inuktitutFormPdfFontFamily
  }

  const nuCities = caCities.filter(city => city.province_code === 'NU')

  const nuCityLabels = nuCities.map(city => {
    if (langVersion === 'iu-cans') {
      return city.nameIU
    } else {
      return getLabelFromTranslationData({
        data: { portalTranslationId: city.name }
      })
    }
  })

  const divisions = Object.values(divisionData).filter(division =>
    ['Kitikmeot', 'Kivalliq', 'Qikiqtaaluk'].includes(division.name)
  )

  const divisionLabels = divisions.map(division => {
    if (langVersion === 'iu-cans') {
      return division.nameIU
    } else {
      return getLabelFromTranslationData({
        data: { portalTranslationId: division.name }
      })
    }
  })

  return (
    <div>
      <div style={styles.question}>
        {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE`)}
        {` (${myI18n._(t`Required`)})`}
      </div>

      <div style={styles.row}>
        <input type='radio' checked={false} />
        <div style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_NEIGHBOURHOOD_OPTION`)}
        </div>
      </div>
      <div style={styles.row}>
        <input type='radio' checked={false} />
        <div style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_MUNCIPAL_OPTION`)}
        </div>
      </div>
      <div style={styles.row}>
        <input type='radio' checked={false} />
        <div style={styles.text}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_REGIONAL_OPTION`)}
        </div>
      </div>
      <div style={styles.row}>
        <input type='radio' checked={false} />
        <div style={styles.text}>
          {myI18n._(
            t`CENZUS_DIVISION_ELEMENT_SCOPE_PROVINCE_OR_TERRITORY_OPTION`
          )}
        </div>
      </div>

      <div style={styles.question}>
        {myI18n._(t`FORM_CENZUS_DIVISION_PDF_CENSUS_DIVISION_MUNICIPAL`)}
        {` (${myI18n._(t`Required`)})`}
      </div>

      <div style={styles.row}>
        {nuCityLabels.map((option, index) => {
          const isOdd = index % 2 === 1
          const _styles = isOdd
            ? { ...styles.listItem, ...styles.odd }
            : styles.listItem
          return (
            <div style={_styles} key={index} wrap={false}>
              <input type='checkbox' checked={false} />
              <div style={styles.text}>
                {option} [
                {getLabelFromTranslationData({
                  data: { portalTranslationId: 'NU' }
                })}
                ]
              </div>
            </div>
          )
        })}
      </div>

      <div style={styles.question}>
        {myI18n._(t`FORM_CENZUS_DIVISION_PDF_CENSUS_DIVISION_REGIONAL`)}
        {` (${myI18n._(t`Required`)})`}
      </div>

      {divisionLabels.map((division, index) => (
        <div style={styles.row} key={index}>
          <input type='checkbox' checked={false} />
          <div style={styles.text}>
            {division} [
            {getLabelFromTranslationData({
              data: { portalTranslationId: 'Region' }
            })}
            ]
          </div>
        </div>
      ))}
    </div>
  )
}

import { Trans } from '@lingui/macro'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MUTextField from 'app/views/forms/multiuser/components/MUTextField'
import { FieldArray } from 'formik'
import { useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import './FormObjectivesUpdate.css'

/**
 * Form element which allows adding and configuring project objectives updates.
 * Objective Updates are saved as Objective_Update__c child objects on connected Grantee Report object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 * @param  {Object} connectedMap Map of all connected objects.
 * @param  {string[]} typeProps.options Array of phases defined in the form editor. For each one user will need to fill the required information.
 * @param  {number}  [typeProps.maxChar] If provided, the number of characters that a text input field will be restricted to.
 * @param  {number}  [typeProps.maxWords] If provided, the number of words that a text input field will be restricted to.
 * @param  {number}  [typeProps.rowQuantity = 1] Number of rows which a text input field should occupy.
 * @param  {boolean} [typeProps.expandable=false] If a text field should expand further below if new line is inserted.
 */
export const FormObjectivesUpdate = ({
  id,
  useMultiuser,
  muBag,
  title,
  helpText,
  connectedObject,
  editMode,
  disabled,
  typeProps,
  describeMap = {},
  langVersion,
  i18n,
  connectedMap,
  ...props
}) => {
  const language = useSelector((state) => state.user.language) || 'en'

  let actualOutcomesLimit,
    desiredOutcomeChangesLimit,
    identifiedNeedChangesLimit
  if (describeMap.Objective_Update__c) {
    describeMap.Objective_Update__c.fields.forEach((fieldObj) => {
      if (fieldObj.name === 'Actual_outcomes__c') {
        actualOutcomesLimit = fieldObj.length
      }
      if (fieldObj.name === 'Desired_outcome_changes__c') {
        desiredOutcomeChangesLimit = fieldObj.length
      }
      if (fieldObj.name === 'Identified_need_changes__c') {
        identifiedNeedChangesLimit = fieldObj.length
      }
    })
  }

  const granteeReportConnectedId = typeProps.connectedTo?.find(
    (item) =>
      connectedMap?.[item?.connectedObject]?.objectType ===
      'FGM_Base__Grantee_Report__c'
  )?.connectedObject

  const latestObjectiveUpdates = connectedObject?.latestReport?.objectiveUpdates || []

  const lastReportTitle = (
    <Trans>FORM_OBJECTIVES_UPDATE_LAST_REPORT_SUBTITLE</Trans>
  )
  const previousReportTitle = (
    <Trans>FORM_OBJECTIVES_UPDATE_PREVIOUS_REPORT_SUBTITLE</Trans>
  )

  const getFormatedDate = (date) => {
    if (!date) {
      return ''
    }
    const _date = new Date(date)
    const month = _date.toLocaleString(language, { month: 'long' })
    const day = _date.getDate()
    const year = _date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or main connected object is not
          of "Opportunity" type!
        </Trans>
      </div>
    )
  }

  if (!granteeReportConnectedId && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>FORM_OBJECTIVES_UPDATE_GRANTEE_REPORT_CONNECTION_ERROR</Trans>
      </div>
    )
  }

  return (
    <Paper className="'form-objectives-update-root">
      <FieldArray
        name={id}
        render={({ push, form, name, remove }) => {
          const objectives = form.values[name] || []
          const alreadySelected = {}
          objectives.forEach((obj, oIndex) => {
            if (obj.objective) {
              if (alreadySelected[obj.objective]) {
                alreadySelected[obj.objective].push(oIndex)
              } else {
                alreadySelected[obj.objective] = [oIndex]
              }
            }
          })

          return (
            <div className='form-objectives-update body'>
              <h2 className='main-title'>{title}</h2>

              <Card className='form-objectives-update card' elevation={6}>
                <div className='description'>
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_OBJECTIVES_UPDATE_VIEW_ONLY_TITLE</Trans>
                    </h3>
                  </Grid>
                  <Grid item style={{ width: 80 }} />
                  <Grid item xs={6}>
                    <h3>
                      <Trans>FORM_OBJECTIVES_UPDATE_UPDATES_TITLE</Trans>
                    </h3>
                  </Grid>
                </div>
              </Card>

              {objectives.map((item, index) => {
                return (
                  <Card key={index} className='form-objectives-update card' elevation={6}>
                    <div className='title-section section'>
                      <h2>{myI18n._(item.objective)}</h2>
                    </div>
                    <div className='section'>
                      <div className='needsOutcomes'>
                        <Grid item style={{ width: 80 }}>
                          <h4>
                            <Trans>Needs</Trans>
                          </h4>
                        </Grid>

                        <Grid item container xs>
                          {item.needs}

                          <Grid item container>
                            <div className='answers'>
                              {latestObjectiveUpdates
                                .filter(
                                  (answer) =>
                                    answer.identifiedNeedChanges &&
                                    answer.objectiveId === item.id
                                )
                                .map((answer, idx) => {
                                  const title =
                                    idx === 0
                                      ? lastReportTitle
                                      : previousReportTitle
                                  const formattedDate = getFormatedDate(
                                    answer.reportSubmissionDate
                                  )
                                  return (
                                    <Grid key={idx} direction='column' className='answer'>
                                      <h4>
                                        {title}: {formattedDate}
                                      </h4>
                                      <p>{answer.identifiedNeedChanges}</p>
                                    </Grid>
                                  )
                                })}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid item xs>
                          <p className='question'>
                            <Trans>
                              FORM_OBJECTIVES_UPDATE_IDENTIFIED_NEEDS_CHANGES
                            </Trans>
                          </p>
                          <MUTextField
                            useMultiuser={useMultiuser}
                            // required
                            muBag={muBag}
                            id={
                              id +
                              `[${index}].objectivesUpdate.identifiedNeedChanges`
                            }
                            label=''
                            multiline
                            minRows={5}
                            maxRows={5}
                            disabled={disabled}
                            margin='normal'
                            limit={
                              identifiedNeedChangesLimit &&
                              Number(identifiedNeedChangesLimit)
                            }
                          />
                        </Grid>
                      </div>
                    </div>

                    <div className='section'>
                      <div className='needsOutcomes'>
                        <Grid item style={{ width: 80 }}>
                          <h4>
                            <Trans>Outcomes</Trans>
                          </h4>
                        </Grid>

                        <Grid item xs container className='outcomes'>
                          {item.outcomes.map((outcome, idx) => {
                            return (
                              <div key={idx} className='outcome'>
                                <p>
                                  {idx + 1}. {outcome}
                                </p>
                              </div>
                            )
                          })}
                          {latestObjectiveUpdates
                            .filter(
                              (answer) =>
                                answer.desiredOutcomeChanges &&
                                answer.objectiveId === item.id
                            )
                            .map((answer, idx) => {
                              const title =
                                idx === 0
                                  ? lastReportTitle
                                  : previousReportTitle
                              const formattedDate = getFormatedDate(
                                answer.reportSubmissionDate
                              )
                              return (
                                <Grid key={idx} direction='column' className='answer'>
                                  <h4>
                                    {title}: {formattedDate}
                                  </h4>
                                  <p>{answer.desiredOutcomeChanges}</p>
                                </Grid>
                              )
                            })}
                        </Grid>

                        <Grid item xs>
                          <p className='question'>
                            <Trans>
                              FORM_OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES
                            </Trans>
                          </p>
                          <MUTextField
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            id={
                              id + `[${index}].objectivesUpdate.desiredOutcomeChanges`
                            }
                            label=''
                            multiline
                            minRows={5}
                            maxRows={5}
                            disabled={disabled}
                            margin='normal'
                            limit={
                              desiredOutcomeChangesLimit &&
                              Number(desiredOutcomeChangesLimit)
                            }
                          />
                        </Grid>
                      </div>

                      {/* <div className='questionAnswers'>
                        <Grid item xs={1} />
                        <Grid item container xs className='answers'>
                          {latestObjectiveUpdates
                            .filter(
                              answer =>
                                answer.actualOutcomes &&
                                answer.objectiveId === item.id
                            )
                            .map((answer, idx) => {
                              const title =
                                idx === 0
                                  ? lastReportTitle
                                  : previousReportTitle
                              const formattedDate = getFormatedDate(
                                answer.reportSubmissionDate
                              )
                              return (
                                <Grid direction='column' className='answer'>
                                  <h4>
                                    {title}: {formattedDate}
                                  </h4>
                                  <p>{answer.actualOutcomes}</p>
                                </Grid>
                              )
                            })}
                        </Grid>

                        <Grid item xs>
                          <Grid item container xs>
                            <p className='question'>
                              <Trans>
                                FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES
                              </Trans>
                            </p>
                            <MUTextField
                              useMultiuser={useMultiuser}
                              required
                              muBag={muBag}
                              id={
                                id +
                                `[${index}].objectivesUpdate.actualOutcomes`
                              }
                              label=''
                              multiline
                              minRows={5}
                              maxRows={5}
                              disabled={disabled}
                              margin='normal'
                              limit={
                                actualOutcomesLimit &&
                                Number(actualOutcomesLimit)
                              }
                            />
                          </Grid>
                        </Grid>
                      </div> */}

                      <div className='questionAnswers'>
                        <Grid item style={{ width: 80 }} />

                        <Grid item container xs className='answers'>
                          {latestObjectiveUpdates
                            .filter(
                              (answer) =>
                                answer.actualOutcomes &&
                                answer.objectiveId === item.id
                            )
                            .map((answer, idx) => {
                              const title =
                                idx === 0
                                  ? lastReportTitle
                                  : previousReportTitle
                              const formattedDate = getFormatedDate(
                                answer.reportSubmissionDate
                              )

                              return (
                                <Grid key={idx} direction='column' className='answer'>
                                  <h4>
                                    {title}: {formattedDate}
                                  </h4>
                                  <p>{answer.actualOutcomes}</p>
                                </Grid>
                              )
                            })}
                        </Grid>

                        <Grid item container xs>
                          <p className='question'>
                            <Trans>
                              FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES
                            </Trans>
                          </p>
                          <MUTextField
                            useMultiuser={useMultiuser}
                            required
                            muBag={muBag}
                            id={
                              id + `[${index}].objectivesUpdate.actualOutcomes`
                            }
                            label=''
                            multiline
                            minRows={5}
                            maxRows={5}
                            disabled={disabled}
                            margin='normal'
                            limit={
                              actualOutcomesLimit && Number(actualOutcomesLimit)
                            }
                          />
                        </Grid>
                      </div>
                    </div>
                  </Card>
                )
              })}
            </div>
          )
        }}
      />
    </Paper>
  )
}

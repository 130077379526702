import { Trans } from '@lingui/macro'
import { myI18n } from 'translation/I18nConnectedProvider'

export const canadaProvincesData = [
  {
    label: <Trans>Alberta</Trans>,
    labelString: myI18n._('Alberta'),
    value: 'Alberta'
  },
  {
    label: <Trans>British Columbia</Trans>,
    labelString: myI18n._('British Columbia'),
    value: 'British Columbia'
  },
  {
    label: <Trans>Manitoba</Trans>,
    labelString: myI18n._('Manitoba'),
    value: 'Manitoba'
  },
  {
    label: <Trans>New Brunswick</Trans>,
    labelString: myI18n._('New Brunswick'),
    value: 'New Brunswick'
  },
  {
    label: <Trans>Newfoundland and Labrador</Trans>,
    labelString: myI18n._('Newfoundland and Labrador'),
    value: 'Newfoundland and Labrador'
  },
  {
    label: <Trans>Northwest Territories</Trans>,
    labelString: myI18n._('Northwest Territories'),
    value: 'Northwest Territories'
  },
  {
    label: <Trans>Nova Scotia</Trans>,
    labelString: myI18n._('Nova Scotia'),
    value: 'Nova Scotia'
  },
  {
    label: <Trans>Nunavut</Trans>,
    labelString: myI18n._('Nunavut'),
    value: 'Nunavut'
  },
  {
    label: <Trans>Ontario</Trans>,
    labelString: myI18n._('Ontario'),
    value: 'Ontario'
  },
  {
    label: <Trans>Prince Edward Island</Trans>,
    labelString: myI18n._('Prince Edward Island'),
    value: 'Prince Edward Island'
  },
  {
    label: <Trans>Québec</Trans>,
    labelString: myI18n._('Québec'),
    value: 'Québec'
  },
  {
    label: <Trans>Saskatchewan</Trans>,
    labelString: myI18n._('Saskatchewan'),
    value: 'Saskatchewan'
  },
  {
    label: <Trans>Yukon</Trans>,
    labelString: myI18n._('Yukon'),
    value: 'Yukon'
  }
]

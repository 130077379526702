import { Trans, t } from '@lingui/macro'
import { Dialog, DialogContent, Typography } from '@material-ui/core'
import {
  multiuserReduxReconnectedEvent,
  resetEditingUsers,
  setEditingUsers
} from 'app/redux/actions/MultiuserActions'
import { userNotAutenticatedForMultiuser } from 'app/redux/actions/UserActions'
import ProgressSnackbar from 'app/views/page-layouts/CustomSnackbars'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useFormikContext } from 'formik'
import _, { isNaN } from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import { isTrueDirty } from '../FormHelpersValidation'
import { getFormVersionDifference } from '../form-page/FormVersionDifferences'
import Chat from './components/Chat'
import UnsavedDataDetectedDialog from './components/UnsavedDataDetectedDialog'
import { grpcListenForFieldCommentChangedEvent } from './grpcFieldComment'
import {
  MultiuserEventType,
  commitFormCache,
  grpGetLockedFieldsForForm,
  grpcEndEditingForm,
  grpcReportSFSaveResult,
  grpcRequestSFSave,
  grpcStartSession,
  grpcUpdateUserInfo,
  mmuTryInitiatingForm,
  moveMouseCursor,
  muFetchAllUsersInfo,
  muGetCurrentFormState,
  pingServer,
  sendMultiuserEvent,
  unlockFieldWithoutChanges
} from './grpcMultiuserEdit'
import {
  muListenForEndEditingFormEvent,
  muListenForFieldLockEvent,
  muListenForMouseCursorEvent,
  muListenForMultiuserEvents,
  muListenForSFSaveRequest,
  muListenForSFSaveResult,
  muListenForStarEditingFormEvent,
  muListenForUserInfoUpdated
} from './grpcMultiuserListeners'
import { getMultiuserColor } from './multiuserHelpers'
import { RequestStatus } from './proto/generated/MultiuserSF_pb'
import {
  DocumentCacheType,
  EventType,
  LockOperation
} from './proto/generated/Multiuser_pb'

export const baseMuInfo = { comments: {}, sessionUsers: [] }

export const multiuserColors = [
  '#fca737',
  '#42c9c0',
  '#810bbc',
  '#a01313',
  '#232efc',
  '#f43f9a',
  '#1b9121',
  '#e03134',
  '#8e6d5e',
  '#5e595a',
  '#FFADBC'
]

export const handleMultiuserSaveRequest = ({
  saveType,
  token,
  handleBlockSave
}) => {
  return grpGetLockedFieldsForForm({
    token,
    onSuccess: locks => {
      if (locks.length === 0) {
        grpcRequestSFSave({
          token,
          type: saveType
        })
      } else {
        handleBlockSave()
        sendMultiuserEvent({
          token,
          eventVariant: EventType.EVENT_ALERT,
          type: MultiuserEventType.TRY_SAVE_BLOCKED
        })
      }
    }
  })
}

const FormMultiuser = ({
  realmId,
  elementsMap,
  mouseDetectRef,
  setMultiuserSessionToken,
  currentStep,
  multiuserSessionToken,
  formikRef,
  setSaving,
  children,
  handleSave,
  programManagerSession,
  formMetadata,
  initialValues,
  handleMultiuserLoadSuccess,
  fetchData
}) => {
  const [unsavedDataDetected, setUnsavedDataDetected] = useState(null)
  const [loading, setLoading] = useState(true)
  const { values } = useFormikContext()
  const user = useSelector(state => state.user)
  const { multiuserAuthenticated, multiuserLoginToken, tabId } = user
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const history = useHistory()
  const currentFormikRef = useRef()
  const tokenRef = useRef()
  const focusRef = useRef()
  currentFormikRef.current = formikRef.current
  tokenRef.current = multiuserSessionToken

  // This handles url redirects and closing browser tab
  useBeforeunload(event => {
    console.log('on before unload editing end')
    muEndEditingForm()
    dispatch(resetEditingUsers())
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      focusRef.current = document.visibilityState
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  const initializeMultiuser = () => {
    const userInfo = {
      id: user.userId,
      step: 0,
      startEditingTime: moment.utc(),
      tabId,
      name: user.displayName
    }
    mmuTryInitiatingForm({
      formId: realmId,
      metadata: formMetadata,
      userId: user.userId,
      multiuserLoginToken,
      accessToken: user.access_token,
      initialValues,
      mode: programManagerSession ? 'ProgramManager' : 'Testing',
      userInfo,
      onFail: e => {
        console.log('failed while initiating form', e)
        setLoading(false)
        enqueueSnackbar(
          <Trans>Could not connect to server for multiuser editing</Trans>,
          {
            variant: 'error'
          }
        )
      },
      onSuccess: ({
        formState,
        unsavedData,
        sessionToken,
        logIndex,
        users,
        isAnotherUser,
        sessionStartTime
      }) => {
        setMultiuserSessionToken(sessionToken)
        console.log(
          'session started succesfully',
          realmId,
          sessionToken,
          logIndex,
          formState,
          unsavedData,
          users,
          sessionStartTime
        )
        console.log('all users', users, isAnotherUser, sessionStartTime)
        if (
          unsavedData &&
          !isAnotherUser &&
          getFormVersionDifference({
            cache: unsavedData,
            current: initialValues,
            elementsMap
          }).length > 0
        ) {
          setUnsavedDataDetected(unsavedData)
        }
        grpGetLockedFieldsForForm({
          userId: user.userId,
          token: sessionToken,
          onSuccess: locksList => {
            const toSet = isAnotherUser
              ? { ...initialValues, ...formState }
              : initialValues
            const muInfo = toSet.muInfo || {}
            muInfo.sessionStartTime = sessionStartTime
            muInfo.sessionUsers = Object.keys(users)
            locksList.forEach(lock => {
              const isUserInForm =
                users[lock.lockedBy] && lock.lockedBy !== user.userId
              if (isUserInForm) {
                muInfo[lock.fieldId] = {
                  locked: true,
                  user: lock.lockedBy
                }
              } else {
                unlockFieldWithoutChanges({
                  token: sessionToken,
                  lockId: lock.lockId,
                  fieldId: lock.fieldId,
                  userId: user.userId
                })
              }
            })
            const colorIndex = logIndex || 0
            const color = getMultiuserColor(colorIndex)
            users[user.userId] = {
              ...userInfo,
              color
            }
            dispatch(setEditingUsers(users))
            let usersToSet = { ...users }
            if (formikRef.current) {
              const currentUsers = formikRef.current.values.muUsers
              usersToSet = {
                ...usersToSet,
                ...currentUsers
              }
            }
            setLoading(false)
            handleMultiuserLoadSuccess({
              initialValues: {
                ...toSet,
                muInfo,
                muUsers: usersToSet
              }
            })
          }
        })
      }
    })
  }

  const muEndEditingForm = () => {
    const ref = formikRef?.current || currentFormikRef.current
    if (ref) {
      const { values } = ref
      const { kickedOutOfForm } = values
      if (!kickedOutOfForm) {
        if (
          values.muUsers &&
          Object.keys(values.muUsers).length === 1 &&
          isTrueDirty(ref)
        ) {
          commitFormCache({
            values,
            userId: user.userId,
            formId: realmId,
            token: tokenRef.current,
            userIds: values.muInfo.sessionUsers,
            type: DocumentCacheType.CHANGES_NOT_SAVED
          })
        }
        grpcEndEditingForm({
          userId: user.userId,
          token: tokenRef.current,
          userIds: values.muInfo.sessionUsers,
          type: DocumentCacheType.CHANGES_NOT_SAVED
        })
      }
    }
  }

  const handleMultiuserReconnect = ({
    sessionToken,
    colorIndex,
    multiuserLoginToken
  }) => {
    const token = sessionToken || multiuserSessionToken
    console.log('reconnecting...', token)
    setMultiuserSessionToken(token)
    Promise.all([
      muGetCurrentFormState({
        userId: user.userId,
        token,
        onSuccess: formState => {}
      }),
      muFetchAllUsersInfo({
        realmId,
        userId: user.userId,
        token,
        multiuserLoginToken
      })
    ]).then(
      ([formState, { users }]) => {
        console.log('got current users', users)
        dispatch(multiuserReduxReconnectedEvent())
        grpGetLockedFieldsForForm({
          userId: user.userId,
          token,
          onSuccess: locksList => {
            const { values, setValues } = formikRef.current
            const muInfo = {
              ...baseMuInfo,
              sessionStartTime: values.muInfo.sessionStartTime
            }
            if (values.muInfo.sessionUsers) {
              muInfo.sessionUsers = [...values.muInfo.sessionUsers]
            }
            const myLock = values.muInfo.lockId
            locksList.forEach(lock => {
              muInfo[lock.fieldId] = {
                locked: true,
                user: lock.lockedBy
              }
              if (myLock && myLock === lock.lockId) {
                muInfo.lockId = lock.lockId
              }
            })
            // Handle color update if user is no longer logged
            if (!users[user.userId]) {
              if (colorIndex || colorIndex === 0) {
                const color = multiuserColors[colorIndex]
                users[user.userId] = {
                  ...users[user.userId],
                  color
                }
              }
            }
            dispatch(setEditingUsers(users))
            muInfo.disconnected = false
            setValues({
              ...values,
              ...formState,
              muInfo,
              muUsers: users
            })
          }
        })
      },
      reject => {
        enqueueSnackbar(
          <Trans>Could not connect to server for multiuser editing</Trans>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  const checkFormLocksForCommit = ({ handle, muSessionId }) => {
    const ref = formikRef.current
    if (!ref && handle) {
      clearInterval(handle)
    } else if (ref) {
      const { values, setFieldValue } = ref
      let isFieldLocked
      Object.values(values.muInfo).forEach(obj => {
        if (obj && obj.locked) {
          isFieldLocked = true
        }
      })
      if (!isFieldLocked) {
        if (handle) {
          clearInterval(handle)
        }
        setFieldValue('muInfo.waitingForCommit', false, false)
        commitFormCache({
          values,
          userId: user.userId,
          formId: realmId,
          token: muSessionId,
          userIds: values.muInfo.sessionUsers,
          type: DocumentCacheType.AUTO_SAVE
        })
        // commit revision
      } else if (!handle) {
        setFieldValue('muInfo.waitingForCommit', true, false)
        const handle = setInterval(() => {
          checkFormLocksForCommit({ handle, muSessionId })
        }, 1000)
      }
    }
  }

  const muHandleChangeStep = index => {
    if (!formikRef.current) {
      return
    }
    const toSet = formikRef.current.values
    const newMuUsers = { ...toSet.muUsers }
    if (newMuUsers[user.userId]) {
      grpcUpdateUserInfo({
        formId: realmId,
        token: multiuserSessionToken,
        userId: user.userId,
        userInfo: {
          ...newMuUsers[user.userId],
          step: index
        }
      })
    }
  }

  useEffect(() => {
    if (!tokenRef.current) {
      if (multiuserAuthenticated) {
        setLoading(true)
        initializeMultiuser()
      } else {
        setLoading(false)
      }
    }
  }, [multiuserAuthenticated])

  useEffect(() => {
    muHandleChangeStep(currentStep)
  }, [currentStep])

  useEffect(() => {
    if (multiuserSessionToken) {
      const minutes = 3
      const handle = setInterval(() => {
        const { values } = formikRef.current
        if (!values.muInfo.waitingForCommit && !values.muInfo.disconnected) {
          checkFormLocksForCommit({ muSessionId: multiuserSessionToken })
        }
      }, 60000 * minutes)
      return () => {
        clearInterval(handle)
      }
    }
  }, [multiuserSessionToken])

  // grpc listeners
  useEffect(() => {
    if (multiuserSessionToken) {
      const streamFieldLocked = muListenForFieldLockEvent({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ userId, changes = [], operation }) => {
          const { values, setValues, setFieldValue } = formikRef.current
          console.log('lock change event', operation, changes)
          if (
            operation === LockOperation.LOCK ||
            operation === LockOperation.RECLAIM
          ) {
            const toSet = { ...values.muInfo }
            changes.forEach(obj => {
              const { lockId, fieldId } = obj
              console.log('user locked field', user, fieldId, lockId)
              if (!toSet[fieldId]) {
                if (userId === user.userId) {
                  toSet.lockId = lockId
                }
                toSet[fieldId] = {
                  locked: true,
                  user: userId
                }
              }
            })
            setFieldValue('muInfo', toSet, false)
          } else if (operation === LockOperation.COMMIT) {
            const toSet = { ...values }
            changes.forEach(obj => {
              const { lockId, fieldId, fieldValue } = obj
              let newValue = fieldValue
              console.log('user unlocked field', fieldId, newValue)
              if (newValue) {
                newValue = JSON.parse(newValue)
                // * Parsed arrays or object containig numbers  can return a valid numer so we have to check if it's not an array or object
                if (!Array.isArray(newValue) && typeof newValue !== 'object') {
                  const testNum = Number(String(newValue).replace(/,|\$/g, ''))
                  if (
                    !isNaN(testNum) &&
                    !moment.utc(newValue).isValid() &&
                    (newValue || newValue === 0)
                  ) {
                    newValue = testNum
                  }
                }
              } else {
                newValue = null
              }
              delete toSet.muInfo[fieldId]
              _.set(toSet, fieldId, newValue)
            })
            setValues(toSet, userId !== user.userId)
          } else if (operation === LockOperation.UPDATE) {
            if (userId !== user.userId) {
              const toSet = { ...values }
              changes.forEach(obj => {
                const { lockId, fieldId, fieldValue } = obj
                let newValue = fieldValue
                if (newValue) {
                  newValue = JSON.parse(newValue)
                  // * Parsed arrays or object containig numbers can return a valid numer so we have to check if it's not an array or object
                  if (
                    !Array.isArray(newValue) &&
                    typeof newValue !== 'object'
                  ) {
                    const testNum = Number(
                      String(newValue).replace(/,|\$/g, '')
                    )
                    if (
                      !isNaN(testNum) &&
                      !moment.utc(newValue).isValid() &&
                      (newValue || newValue === 0)
                    ) {
                      newValue = testNum
                    }
                  }
                } else {
                  newValue = null
                }
                _.set(toSet, fieldId, newValue)
              })
              setValues(toSet)
            }
          } else if (operation === LockOperation.CANCEL) {
            const toSet = { ...values }
            changes.forEach(obj => {
              const { lockId, fieldId } = obj
              delete toSet.muInfo[fieldId]
            })
            setValues(toSet, false)
          }
        }
      })
      const streamMouseMoved = muListenForMouseCursorEvent({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ coordinates, userId }) => {
          const { values } = formikRef.current
          const toSet = { ...values }
          const newMuUsers = { ...toSet.muUsers }
          if (newMuUsers[userId]) {
            newMuUsers[userId].coordinates = coordinates
            dispatch(setEditingUsers(newMuUsers))
          }
        }
      })
      const streamStartEditing = muListenForStarEditingFormEvent({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: info => {
          const { values, setValues } = formikRef.current
          const toSet = { ...values }
          console.log('user started editing', info, toSet)
          if (
            info.id === user.userId &&
            toSet.muUsers[info.id] &&
            info.tabId !== tabId
          ) {
            toSet.kickedOutOfForm = true
            setValues(toSet, false)
            history.push('/grants/home')
            if (values.muInfo.lockId) {
              unlockFieldWithoutChanges({
                fieldId: values.muInfo.lockId,
                token: multiuserSessionToken,
                userId: user.userId
              })
            }
            enqueueSnackbar(
              <Trans>
                You logged to this form in different tab or browser
              </Trans>,
              {
                variant: 'info'
              }
            )
          } else {
            toSet.muUsers[info.id] = info
            if (
              toSet.muInfo.sessionUsers &&
              !toSet.muInfo.sessionUsers.includes(info.id)
            ) {
              toSet.muInfo.sessionUsers.push(info.id)
            }
            setValues(toSet, false)
            dispatch(setEditingUsers(toSet.muUsers))
          }
        }
      })
      const streamEndEditing = muListenForEndEditingFormEvent({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: id => {
          const { values, setValues } = formikRef.current
          console.log('user logout of form', id)
          const toSet = { ...values }
          const newMuUsers = { ...toSet.muUsers }
          // unlock fields for editor
          Object.keys(toSet.muInfo).forEach(key => {
            const value = toSet.muInfo[key]
            if (typeof value === 'object' && value && value.user === id) {
              delete toSet.muInfo[key]
              unlockFieldWithoutChanges({
                token: multiuserSessionToken,
                fieldId: key,
                userId: user.userId
              })
            }
          })
          if (newMuUsers[id]) {
            delete newMuUsers[id]
            toSet.muUsers = newMuUsers
          }
          setValues(toSet, false)
          dispatch(setEditingUsers(toSet.muUsers))
        }
      })
      const streamSFSaveRequested = muListenForSFSaveRequest({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ canSave, requestType, userRequesting }) => {
          const { values, setFieldValue } = formikRef.current
          const userInfo = values.muUsers[userRequesting]
          console.log(
            'save request recieved',
            canSave,
            requestType,
            userRequesting
          )
          if (userInfo && userRequesting !== user.userId) {
            if (requestType === 'Autosave') {
              enqueueSnackbar(<Trans>Autosaving</Trans>, { variant: 'info' })
            } else {
              enqueueSnackbar(
                <Trans>{userInfo.name} requested form save</Trans>,
                { variant: 'info' }
              )
            }
          }
          if (!canSave) {
            setSaving(false)
            enqueueSnackbar(
              <Trans>
                All fields must be unlocked for saving to commence!
              </Trans>,
              { variant: 'error' }
            )
          } else {
            setSaving(true)
            if (userRequesting === user.userId) {
              handleSave({ values }).then(
                result => {
                  grpcReportSFSaveResult({
                    type: requestType,
                    token: multiuserSessionToken,
                    userId: user.userId,
                    result: RequestStatus.ALLOWED
                  })
                  commitFormCache({
                    values,
                    token: multiuserSessionToken,
                    userId: user.userId,
                    formId: realmId,
                    userIds: values.muInfo.sessionUsers,
                    type: DocumentCacheType.MANUAL_SAVE
                  })
                },
                reject => {
                  grpcReportSFSaveResult({
                    type: requestType,
                    token: multiuserSessionToken,
                    userId: user.userId,
                    result: RequestStatus.BLOCKED
                  })
                }
              )
            } else {
              const muSnackbar = enqueueSnackbar(null, {
                persist: true,
                content: key =>
                  ProgressSnackbar(<Trans>{userInfo.name} is saving</Trans>)
              })
              setFieldValue('muInfo.muSnackbar', muSnackbar, false)
            }
          }
        }
      })
      const streamSFSaveResult = muListenForSFSaveResult({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ success, requestType, userSaving }) => {
          console.log('save request recieved', success, requestType, userSaving)
          if (userSaving !== user.userId) {
            const { values, setFieldValue } = formikRef.current
            if (success) {
              if (requestType === 'Save' || requestType === 'Autosave') {
                fetchData({ multiuserReload: true }).then(result => {
                  if (values.muInfo.muSnackbar) {
                    closeSnackbar(values.muInfo.muSnackbar)
                    setFieldValue('muInfo.muSnackbar', null, false)
                  }
                  enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
                    variant: 'success'
                  })
                  setSaving(false)
                })
              } else {
                if (values.muInfo.muSnackbar) {
                  closeSnackbar(values.muInfo.muSnackbar)
                  setFieldValue('muInfo.muSnackbar', null, false)
                }
                enqueueSnackbar(<Trans>Successfully submitted!</Trans>, {
                  variant: 'success'
                })
              }
            } else {
              if (values.muInfo.muSnackbar) {
                closeSnackbar(values.muInfo.muSnackbar)
                setFieldValue('muInfo.muSnackbar', null, false)
              }
              setSaving(false)
              if (requestType === 'Save') {
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while saving! Some fields were not saved!
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              } else {
                enqueueSnackbar(<Trans>Error Submitting</Trans>, {
                  variant: 'error'
                })
              }
            }
          }
        }
      })
      const streamMultiuserEvent = muListenForMultiuserEvents({
        token: multiuserSessionToken,
        onEventRecieved: ({ variant, type, userId }) => {
          const { values } = formikRef.current
          let snackbarVariant = 'info'
          let snackbarMessage = ''
          switch (variant) {
            case EventType.EVENT_INFO:
              snackbarVariant = 'info'
              break
            case EventType.EVENT_ALERT:
              snackbarVariant = 'warning'
              break
            case EventType.EVENT_ERROR:
              snackbarVariant = 'error'
              break
            case EventType.EVENT_SUCCESS:
              snackbarVariant = 'success'
              break
            default:
              break
          }
          const userInfo = values.muUsers?.[userId]
          if (
            type === MultiuserEventType.BACKUP_RESTORE &&
            userId !== user.userId
          ) {
            snackbarMessage = myI18n._(
              t`${userInfo.name} MULTIUSER_EVENT_RESTORED_FORM_BACKUP`
            )
          } else if (
            type === MultiuserEventType.BACKUP_RESTORE_BLOCKED &&
            userId !== user.userId
          ) {
            snackbarMessage = myI18n._(
              t`MULTIUSER_EVENT ${userInfo.name} TRYING_RESTORE_FORM_BACKUP`
            )
          } else if (
            type === MultiuserEventType.TRY_SAVE_BLOCKED &&
            userId !== user.userId
          ) {
            snackbarMessage = myI18n._(
              t`MULTIUSER_EVENT ${userInfo.name} TRYING_SAVING`
            )
          }
          if (snackbarMessage) {
            enqueueSnackbar(snackbarMessage, {
              variant: snackbarVariant
            })
          }
        }
      })
      const streamUserInfoUpdated = muListenForUserInfoUpdated({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ userId, info }) => {
          const { values, setFieldValue } = formikRef.current
          const toSet = { ...values.muUsers }
          toSet[userId] = info
          setFieldValue('muUsers', toSet, false)
        }
      })
      const streamFieldCommentChanged = grpcListenForFieldCommentChangedEvent({
        userId: user.userId,
        token: multiuserSessionToken,
        onEventRecieved: ({ comment, userId, fieldId, operation }) => {
          console.log('field comment changed', comment, fieldId, operation)
          const { setFieldValue, values } = formikRef.current
          const toSet = { ...values.muInfo }
          if (operation === LockOperation.LOCK) {
            const current = _.get(values.muInfo, `comments.${fieldId}`)
            _.set(toSet, `comments.${fieldId}`, {
              ...current,
              locked: true,
              user: userId
            })
            setFieldValue('muInfo', toSet, false)
          } else if (
            operation === LockOperation.COMMIT ||
            operation === LockOperation.CANCEL ||
            operation === LockOperation.UPDATE
          ) {
            _.set(toSet, `comments.${fieldId}`, {
              locked: operation === LockOperation.UPDATE,
              comment,
              user: userId
            })
            setFieldValue('muInfo', toSet, false)
          }
        }
      })

      const sendCursorEvent = () => {
        const ref = mouseDetectRef && mouseDetectRef.current
        if (ref) {
          const muUsers = formikRef.current.values.muUsers
          const { elementDimensions, isPositionOutside, position } = ref.state
          if (!isPositionOutside) {
            const { x, y } = position
            if (muUsers && muUsers[user.userId]) {
              const oldX = muUsers[user.userId].coordinates?.x
              const oldY = muUsers[user.userId].coordinates?.y
              if (Math.abs(oldX - x) < 10 && Math.abs(oldY - y) < 10) {
                return
              }
            }
            const xPercent = Number(
              Number(x / elementDimensions.width).toFixed(2)
            )
            const yPercent = Number(
              Number(y / elementDimensions.height).toFixed(2)
            )
            moveMouseCursor({
              userId: user.userId,
              token: multiuserSessionToken,
              x,
              y,
              xPercent,
              yPercent
            })
          }
        }
      }

      const resetCursorDetect = () => {
        if (mouseDetectRef && mouseDetectRef.current) {
          setTimeout(() => {
            mouseDetectRef.current.reset()
            sendCursorEvent()
          }, 400)
        }
      }
      window.addEventListener('wheel', resetCursorDetect)
      const mouseMoveInterval = setInterval(() => {
        sendCursorEvent()
      }, 500)

      return () => {
        streamMultiuserEvent.close()
        streamFieldLocked.close()
        streamMouseMoved.close()
        streamEndEditing.close()
        streamStartEditing.close()
        streamSFSaveRequested.close()
        streamSFSaveResult.close()
        streamUserInfoUpdated.close()
        streamFieldCommentChanged.close()
        clearInterval(mouseMoveInterval)
        window.removeEventListener('wheel', resetCursorDetect)
      }
    }
  }, [multiuserSessionToken])

  useEffect(() => {
    if (formikRef.current) {
      const { values } = formikRef.current
      if (multiuserAuthenticated && values.muInfo.disconnected) {
        console.log('start session login token', multiuserLoginToken)
        grpcStartSession({
          userId: user.userId,
          token: user.access_token,
          multiuserLoginToken,
          formId: realmId,
          onFail: e => {
            enqueueSnackbar(
              <Trans>Could not connect to server for multiuser editing</Trans>,
              {
                variant: 'error'
              }
            )
          },
          username: user.displayName,
          userInfo: {
            id: user.userId,
            step: currentStep,
            startEditingTime: moment.utc(),
            tabId,
            name: user.displayName
          },
          onSuccess: ({ sessionToken, colorIndex }) => {
            handleMultiuserReconnect({
              sessionToken,
              multiuserLoginToken,
              colorIndex
            })
          }
        })
      }
    }
  }, [multiuserLoginToken, multiuserAuthenticated])

  useEffect(() => {
    return () => {
      console.log('use effect editing end')
      muEndEditingForm()
      dispatch(resetEditingUsers())
    }
  }, [])

  useEffect(() => {
    if (multiuserSessionToken) {
      const handle = setInterval(() => {
        pingServer({
          userId: user.userId,
          token: multiuserSessionToken,
          onReject: err => {
            console.log('form ping fail', err, multiuserSessionToken)
            const { setFieldValue, values } = formikRef.current
            if (!values.muInfo.disconnected) {
              setFieldValue('muInfo.disconnected', true, false)
              dispatch(userNotAutenticatedForMultiuser())
            }
          },
          onSuccess: () => {
            const { values } = formikRef.current
            if (values.muInfo.disconnected) {
              console.log('connection restored')
              handleMultiuserReconnect({
                sessionToken: multiuserSessionToken,
                multiuserLoginToken
              })
            }
          }
        })
      }, 5000)
      return () => {
        clearInterval(handle)
      }
    }
  }, [multiuserSessionToken])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {multiuserSessionToken && (
        <Chat
          realmId={realmId}
          token={multiuserSessionToken}
          sessionStartTime={values.muInfo?.sessionStartTime}
          formikRef={formikRef}
        />
      )}
      <UnsavedDataDetectedDialog
        token={multiuserSessionToken}
        data={unsavedDataDetected}
        elementsMap={elementsMap}
      />
      {values.muInfo?.disconnected && (
        <Dialog open fullWidth maxWidth='sm'>
          <DialogContent>
            <Typography variant='h6' style={{ marginBottom: 10 }}>
              <Trans>Connection lost. Reconnecting...</Trans>
            </Typography>
            <div style={{ paddingBottom: 20 }}>
              <Loading isNotFixed />
            </div>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </>
  )
}

export default FormMultiuser

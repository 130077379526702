import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { currencyFormatedString } from 'app/views/common/Formats'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'
import { grantStatusOptions } from './FormOtherGrants'

export const FormOtherGrantsPdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      tableRow: {
        ...pdfTableStyles.tableRow,
        minHeight: 16
      },
      firstCol: {
        width: '33%'
      },
      secondCol: {
        width: '33%'
      },
      thirdCol: {
        width: '34%'
      },
      headerCell: {
        fontFamily: defaultFormPdfFontFamily,
        fontSize: 10,
        fontWeight: 'bold'
      }
    })
  }

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.headerCell.fontFamily = inuktitutFormPdfFontFamily
  }

  if (!value || value?.length === 0) {
    return (
      <Text style={styles.tableCell}>
        {myI18n._(t`PDF_ELEMENT_OTHER_GRANTS_NONE_LABEL`)}
      </Text>
    )
  }

  const formatedValue = value?.map((item) => {
    const state = grantStatusOptions.find(
      (option) => option.value === item.state
    )

    const newItem = {
      name: item.name,
      value: item.value,
      state: state ? state.labelPDF : ''
    }

    return newItem
  })

  return (
    <View>
      {/* {title? <View><Text style={commonPdfStyles.title}>{title}</Text></View>: <View/>} */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.headerCell}>{myI18n._(t`Name`)}</Text>
          </View>
          <View style={[styles.tableCol, styles.secondCol]}>
            <Text style={styles.headerCell}>{myI18n._(t`Value`)}</Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol]}>
            <Text style={styles.headerCell}>{myI18n._(t`Status`)}</Text>
          </View>
        </View>

        {formatedValue
          ? formatedValue.map((item, key) => (
            <View style={styles.tableRow} key={key}>
              <View style={[styles.tableCol, styles.firstCol]}>
                <Text style={styles.tableCell}>{item.name}</Text>
              </View>
              <View style={[styles.tableCol, styles.secondCol]}>
                <Text style={styles.tableCell}>
                  {currencyFormatedString(item.value, langVersion)}
                </Text>
              </View>
              <View style={[styles.tableCol, styles.thirdCol]}>
                <Text style={styles.tableCell}>{myI18n._(item.state)}</Text>
              </View>
            </View>
          ))
          : null}
      </View>
    </View>
  )
}

import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from 'app/views/forms/common/Common'
import {
  defaultFormPdfFontFamily,
  formPDFStyles,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { isArray } from 'lodash'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getValuesForCensusDivisionPrint } from './FormCensusDivisionPrint'

const styles = StyleSheet.create({
  text: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily
  },
  question: {
    fontSize: pdfDefaultFontSize,
    fontFamily: defaultFormPdfFontFamily,
    fontWeight: 'bold',
    marginTop: 8,
    marginBottom: 4
  },
  line: {
    marginBottom: 8
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioAnswer: {
    marginRight: 16,
    fontSize: pdfDefaultFontSize
  }
})

/**
 * Component to render FormCenzusDivision element in print preview
 * @param {object} value - value of FormCenzusDivision element
 * @param {string} title - title of FormCenzusDivision element
 * @param {object} props - props of FormCenzusDivision element
 */
export const FormCensusDivisionPdf = ({
  value,
  title,
  langVersion,
  ...props
}) => {
  value = value || {}

  if (langVersion === 'iu-cans') {
    styles.text.fontFamily = inuktitutFormPdfFontFamily
    styles.question.fontFamily = inuktitutFormPdfFontFamily
  }

  const census = getValuesForCensusDivisionPrint(value, langVersion)

  /** helper function to transform array element to element to display */
  const getTransformedElements = (item) =>
    isArray(item)
      ? item.map((item, index) => (
        <View style={styles.line} key={index}>
          <Text>{item}</Text>
        </View>
      ))
      : item

  census.provinces = getTransformedElements(census.provinces)
  census.selected = getTransformedElements(census.selected)
  census.pilotPhaseProvinces = getTransformedElements(
    census.pilotPhaseProvinces
  )
  census.pilotPhaseSelected = getTransformedElements(census.pilotPhaseSelected)

  return (
    <View>
      <View style={styles.line}>
        <Text style={styles.question}>
          {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE`)}
        </Text>
        <Text style={styles.text}>{census.scopeType}</Text>
      </View>
      {census.muncipality
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY`)}
            </Text>
            <Text style={styles.text}>{census.muncipality}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.provinces
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PROVINCES`)}
            </Text>
            <Text style={styles.text}>{census.provinces}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.selected
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_SELECTED_LABEL`)}
            </Text>
            <Text style={styles.text}>{census.selected}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.pilotPhase
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PILOT_PHASE_QUESTION`)}
            </Text>
            <Text style={styles.text}>{myI18n._(census.pilotPhase)}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.pilotPhaseScopeType
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_SCOPE_PILOT_PHASE`)}
            </Text>
            <Text style={styles.text}>{census.pilotPhaseScopeType}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.pilotPhaseMuncipality
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_WHICH_MUNCIPALITY_PILOT_PHASE`)}
            </Text>
            <Text style={styles.text}>{census.pilotPhaseMuncipality}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.pilotPhaseProvinces
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PROVINCES_PILOT_PHASE`)}
            </Text>
            <Text style={styles.text}>{census.pilotPhaseProvinces}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.pilotPhaseSelected
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_PILOT_PHASE_SELECTED_LABEL`)}
            </Text>
            <Text style={styles.text}>{census.pilotPhaseSelected}</Text>
          </View>
          )
        : (
          <View />
          )}
      {census.impactsProvincialNorth === 'Focus' ||
      census.impactsProvincialNorth === 'Impacts'
        ? (
          <View style={styles.line}>
            <Text style={styles.question}>
              {myI18n._(t`CENZUS_DIVISION_ELEMENT_NORTH_FOCUS_QUESTION`)}
            </Text>
            <View style={styles.row}>
              <View style={formPDFStyles.radioCheck}>
                {census.impactsProvincialNorth === 'Focus'
                  ? (
                    <View style={formPDFStyles.radioCheckSelected} />
                    )
                  : (
                    <View />
                    )}
              </View>
              <Text style={styles.radioAnswer}>{myI18n._(t`Yes`)}</Text>
              <View style={formPDFStyles.radioCheck}>
                {census.impactsProvincialNorth === 'Impacts'
                  ? (
                    <View style={formPDFStyles.radioCheckSelected} />
                    )
                  : (
                    <View />
                    )}
              </View>
              <Text style={styles.radioAnswer}>{myI18n._(t`No`)}</Text>
            </View>
          </View>
          )
        : (
          <View />
          )}
    </View>
  )
}

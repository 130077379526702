export const GRANTEE_REPORT_STATUSES = {
  SCHEDULED: 'Scheduled',
  REQUESTED: 'Requested',
  SUBMITTED: 'Submitted',
  MORE_INFO_REQUIED: 'More info required',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  OBSOLETE: 'Obsolete'
}

export const GRANTEE_REPORT_COMPLETED_STAGES = [
  GRANTEE_REPORT_STATUSES.SUBMITTED,
  GRANTEE_REPORT_STATUSES.APPROVED,
  GRANTEE_REPORT_STATUSES.REJECTED,
  GRANTEE_REPORT_STATUSES.OBSOLETE
]

import { Trans } from '@lingui/macro'
import { getObjNameById } from 'app/views/utilities/object-parser'
import { useFormikContext } from 'formik'
import { FormPicklist } from './FormPicklist'

export const FormPicklistPrintEditor = props => {
  const { typeProps } = props
  const { values } = useFormikContext()

  if (typeProps.connectedTo && typeProps.connectedTo.length > 0) {
    const printValue = typeProps.connectedTo.map(item => {
      const objName = getObjNameById({
        id: item.connectedObject,
        objects: values.objectsConnected
      })
      const field = item.connectedField?.name
      return (
        <span key={item.id}>
          <Trans>
            Field Value Placeholder for <i>{field}</i> from <i>{objName}</i>
          </Trans>
        </span>
      )
    })
    return printValue
  }

  return <FormPicklist {...props} disabled />
}

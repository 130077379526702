import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { PdfPropsForm } from 'app/views/forms/common/PdfPropsForm'
import { useDispatch } from 'react-redux'
import { formComponentTypes } from '../../formComponentTypes'
import { FormObjectivesUpdate } from './FormObjectivesUpdate'

export const FormEditorObjectivesUpdate = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps,
  injectable,
  injectableId,
  showPdfProps,
  ...props
}) => {
  const dispatch = useDispatch()
  const _typeProps = typeProps || {}
  if (!editMode) {
    return (
      <FormObjectivesUpdate editMode id={id} typeProps={_typeProps} {...props} />
    )
  }

  return (
    <div>

      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={_typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.objectivesUpdate.connectsToMultipleObjects
        }
      />

    </div>
  )
}

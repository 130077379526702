import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { inuktitutFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { parsePDFLabelToSupportWordBreaking } from 'app/views/forms/pdf-components/formPdfUtils'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

const styles = {
  ...pdfTableStyles,
  ...StyleSheet.create({
    tableCol: {
      ...pdfTableStyles.tableCol,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      minHeight: 35
    },
    headerRow: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      fontWeight: 'bold'
    },
    firstCol: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      hyphens: 'auto'
    }
  })
}

export const FormMilestonesFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  const phases = typeProps.phases || []
  const rowHeight = 100 / (phases.length + 1)

  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.headerRow, { width: '18%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`FORM_MILESTONES_PHASE_NAME`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '11%' }]}>
          <Text style={styles.tableCell}>{myI18n._(t`Start Date`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '11%' }]}>
          <Text style={styles.tableCell}>{myI18n._(t`End Date`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '40%' }]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Primary Activities`)}
          </Text>
        </View>
        <View style={[styles.tableCol, styles.headerRow, { width: '20%' }]}>
          <Text style={styles.tableCell}>{myI18n._(t`Comments`)}</Text>
        </View>
      </View>

      {phases.map((item, index) => {
        const apiValue = item.apiValue
        return (
          <View
            style={styles.tableRow}
            wrap={false}
            key={index}
          >
            <View style={[styles.tableCol, styles.firstCol, { width: '18%' }]}>
              {apiValue !== '' && apiValue !== undefined && (
                <Text style={styles.tableCell}>
                  {parsePDFLabelToSupportWordBreaking(
                    getLabelFromTranslationData({
                      data: item.label,
                      langVersion
                    }) || myI18n._(apiValue)
                  )}
                </Text>
              )}
            </View>
            <View style={[styles.tableCol, { width: '11%' }]} />
            <View style={[styles.tableCol, { width: '11%' }]} />
            <View style={[styles.tableCol, { width: '40%' }]} />
            <View style={[styles.tableCol, { width: '20%' }]} />
          </View>
        )
      })}
    </View>
  )
}

import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'

/**
 * Interface used to configure Salesforce object and field that will be connected to the form element.
 * @category Form
 * @subcategory Editor
 * @component
 * @returns {JSX.Element}
 * @param  {object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean} [fieldRender=false] - If inteface should display field for connecting Salesforce field to form element.
 * @param {boolean} [disableMultiple=false] - If element should be allowed to connect to only one field.
 * @param  {number[]} depth - Position in form editor tree represented by an array of indexes.
 * @param  {function} [filter] - Custom filter function that will be applied to avaliable fields.
 * @param  {string[]} validTypes - Field types that element can connect to.
 * @param  {string[]} [clearOnChange] - typeProps keys that should be cleared on connection change.
 * @param  {string[]} [clearOnReadOnlyField] - typeProps keys that should be cleared on connecting to read only field.
 * @param  {string[]} [clearOnDisconnect] - typeProps keys that should be cleared on clearing connection.
 * @param  {function} [handleDisconnect] - Custom callback that will be fired on clearing connection.
 * @param {boolean} [allowReadOnlyFields=false] - If element should be allowed to connect to read only fields.
 * @param {boolean} [connectToCollection=false] - If instead of field, form element should be allowed to connect to child objects collection for connected object.
 * @param {boolean} [noField=false] - If true, no interface for connecting Salesforce field will be rendered
 * @param {boolean} [injectable=false] - If used with reusable form element.
 * @param {string} [injectableId] - If of source element, if interface is used on element that is a copy of reusable element.
 * @param {string[]} [objectsConnections] - If form element needs to be connected to multiple objects - an array of Salesforce object types the element have to be connected to.
 */
export const FormEditorExcelFileImportConnectToObject = ({
  typeProps,
  injectable,
  injectableId,
  disableMultiple,
  objectsConnections,
  depth,
  filter,
  validTypes,
  handleDisconnect,
  allowReadOnlyFields,
  ...props
}) => {
  const { clearOnDisconnect = [] } = props
  const injectedElement = !injectable && injectableId
  const dispatch = useDispatch()
  const { isConnected, connectedTo = [] } = typeProps
  const { values } = useFormikContext()
  const { objectsConnected, objects } = values
  const selectableObjects = injectable
    ? objects.map(obj => ({
      identId: obj.name,
      type: obj.name,
      name: obj.label,
      loadOrder: obj.loadOrder
    }))
    : objectsConnected
  const avaliableObjectsMap = {}
  selectableObjects.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  const alreadyConnected = {}
  connectedTo.forEach(obj => {
    if (obj.connectedObject && obj.connectedField) {
      const array = alreadyConnected[obj.connectedObject] || []
      alreadyConnected[obj.connectedObject] = [
        ...array,
        obj.connectedField.name
      ]
    }
  })

  return (
    <div>
      <FormControlLabel
        style={{ marginBottom: 5 }}
        disabled={injectedElement}
        control={
          <Checkbox
            checked={Boolean(isConnected)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.isConnected = e.target.checked
              toSet.connectedTo = []
              if (!toSet.isConnected) {
                clearOnDisconnect.forEach(key => delete toSet[key])
                if (handleDisconnect) {
                  handleDisconnect(toSet)
                }
                delete toSet.readOnly
                delete toSet.forceRequired
              }
              if (objectsConnections && e.target.checked) {
                toSet.connectedTo = []
              }
              if (!e.target.checked) {
                delete toSet.template
              }
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Is connected to object?</Trans>}
      />
      {isConnected && <div>
        <Typography variant='caption'>
          <Trans>FORM_EDITOR_EXCEL_FILE_IMPORT_DOWNLOAD_FILE_SELECT_TITLE</Trans>:
        </Typography>
        <TextField
          select
          style={{ width: '100%', marginBottom: 10, marginTop: 15 }}
          variant='outlined'
          id='select-label'
          label={<Trans>FORM_EDITOR_EXCEL_FILE_IMPORT_DOWNLOAD_FILE_SELECT_LABEL</Trans>}
          value={typeProps.template || ''}
          onChange={async (event) => {
            const template = event.target.value
            const toSet = { ...typeProps }
            toSet.template = template
            toSet.connectedTo = objectsConnections.filter(
              item => item.type === 'Opportunity' && template === 'property_information' ||
              item.type === 'Pre_Qualification__c' && template === 'prequalification_housing_portfolio'
            ).map(obj => ({ ...obj, connectedObject: obj.type, forceType: obj.type }))
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <MenuItem value='prequalification_housing_portfolio'>NS-CHCF Pre-qualification Housing Portfolio</MenuItem>
          <MenuItem value='property_information'>NS-CHCF Property information</MenuItem>
        </TextField>
      </div>}
      {isConnected && !objectsConnections && (
        <div style={{ marginBottom: 5 }}>
          <Grid container alignItems='center'>
            <Trans>Add connection</Trans>
            <IconButton
              size='small'
              disabled={
                (connectedTo.length === 1 && disableMultiple) || injectedElement
              }
              onClick={e => {
                const toSet = { ...typeProps }
                toSet.connectedTo = [...connectedTo]
                toSet.connectedTo.push({
                  connectedObject: ''
                })
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
          </Grid>
        </div>
      )}
      {connectedTo.map((obj, index) => {
        const {
          connectedObject,
          forceType,
          connectedObjectType,
          connectedField,
          connectedCollection
        } = obj
        let options = []
        let relatedCollections = []
        connectedObject &&
          objects.some(obj => {
            if (!avaliableObjectsMap[connectedObject] && !injectedElement) {
              return false
            }
            const objType = injectedElement
              ? connectedObjectType
              : avaliableObjectsMap[connectedObject].type
            const bool = obj.name === objType
            if (bool) {
              relatedCollections = obj.relatedCollections
              if (connectedCollection) {
                relatedCollections.some(collection => {
                  if (collection.key === connectedCollection) {
                    options = collection.fields
                  }
                  return collection.key === connectedCollection
                })
              }
              options = obj.fields.filter(field => {
                const connectedArray = alreadyConnected[connectedObject] || []
                return (
                  !connectedArray.includes(field.name) ||
                  field.name === connectedField?.name
                )
              })
            }
            return bool
          })
        if (validTypes) {
          options = options.filter(item => validTypes.includes(item.type))
        }
        if (filter) {
          options = options.filter(item => filter(item))
        }
        if (!allowReadOnlyFields) {
          options = options.filter(item => !item.readOnly)
        }
        // if (typeProps.picklistType === 'multiselect') {
        //   options = options.filter(item => item.type === 'multipicklist')
        // }
        const collectionsMap = {}
        const optionsMap = {}
        options.forEach(opt => {
          optionsMap[opt.name] = opt
        })
        relatedCollections.forEach(opt => {
          collectionsMap[opt.key] = opt.label
        })

        return (
          <>
            <div style={{ padding: 8 }}>
              {!forceType && (
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <span style={{ margin: 4 }}>
                    {String(index + 1)}
                    {index === 0 && (
                      <span style={{ marginLeft: 8 }}>
                        <b>
                          <Trans>[Main object]</Trans>
                        </b>
                        <TooltipLabelIcon
                          tooltip={
                            <Trans>
                              This field will be used to determine initial value
                              of an element, it's avaliable picklist options
                              etc.
                            </Trans>
                          }
                        />
                      </span>
                    )}
                    {connectedObjectType && ' (' + connectedObjectType + ')'}
                  </span>

                  <Grid item>
                    <IconButton
                      size='small'
                      onClick={e => {
                        const toSet = { ...typeProps }
                        const toMove = toSet.connectedTo[index]
                        const toReplace = toSet.connectedTo[index - 1]
                        toSet.connectedTo[index - 1] = toMove
                        toSet.connectedTo[index] = toReplace
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      disabled={index === 0}
                    >
                      <Icon>arrow_downward</Icon>
                    </IconButton>
                    <IconButton
                      size='small'
                      onClick={e => {
                        const toSet = { ...typeProps }
                        const toMove = toSet.connectedTo[index]
                        const toReplace = toSet.connectedTo[index + 1]
                        toSet.connectedTo[index + 1] = toMove
                        toSet.connectedTo[index] = toReplace
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      disabled={index === connectedTo.length - 1}
                    >
                      <Icon>arrow_upward</Icon>
                    </IconButton>
                    <IconButton
                      size='small'
                      onClick={e => {
                        const toSet = { ...typeProps }
                        toSet.connectedTo.splice(index, 1)
                        if (
                          toSet.forceRequired &&
                          !toSet.connectedTo.some(
                            obj =>
                              obj.connectedField && obj.connectedField.required
                          )
                        ) {
                          clearOnDisconnect.forEach(key => delete toSet[key])
                          delete toSet.forceRequired
                          delete toSet.required
                        }
                        if (toSet.connectedTo.length === 0) {
                          delete toSet.readOnly
                        }
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </div>
            <Connection
              {...obj}
              {...props}
              index={index}
              options={options}
              relatedCollections={relatedCollections}
              typeProps={typeProps}
              depth={depth}
              injectable={injectable}
              injectedElement={injectedElement}
              objectsConnections={objectsConnections}
              selectableObjects={selectableObjects}
              clearOnDisconnect={clearOnDisconnect}
              avaliableObjectsMap={avaliableObjectsMap}
            />
          </>
        )
      })}
    </div>
  )
}

const Connection = ({
  index,
  connectedObject,
  forceType,
  connectedObjectType,
  connectedField,
  connectedCollection,
  clearOnDisconnect = [],
  clearOnChange = [],
  clearOnReadOnlyField = [],
  objectsConnections,
  options,
  relatedCollections,
  connectToCollection,
  avaliableObjectsMap,
  selectableObjects,
  typeProps,
  depth,
  fieldRender,
  noField,
  injectedElement,
  injectable
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { objects } = values

  const collectionsMap = {}
  const optionsMap = {}
  options.forEach(opt => {
    optionsMap[opt.name] = opt
  })
  relatedCollections.forEach(opt => {
    collectionsMap[opt.key] = opt.label
  })
  return (
    <div key={index} style={{ paddingTop: 5 }}>
      <TextField
        select
        label={forceType || <Trans>Connected object</Trans>}
        fullWidth
        variant='outlined'
        defaultValue=''
        value={connectedObject || ''}
        onChange={e => {
          const toSet = { ...typeProps }
          if (injectedElement) {
            toSet.connectedTo[index].connectedObject = e.target.value
          } else {
            delete toSet.readOnly
            toSet.connectedTo[index].connectedObject = e.target.value
            if (injectable) {
              toSet.connectedTo[index].connectedObjectType = e.target.value
            }
            delete toSet.connectedTo[index].connectedField
            delete toSet.connectedTo[index].connectedCollection
            clearOnChange.forEach(key => delete toSet[key])
          }
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        {selectableObjects
          .filter(
            item =>
              (!injectedElement || item.type === connectedObjectType) &&
              (!forceType || forceType === item.type)
          )
          .map((item, index) => {
            return (
              <MenuItem key={index} value={item.identId}>
                {item.name}
              </MenuItem>
            )
          })}
      </TextField>
      {Boolean(
        connectedObject &&
          !noField &&
          !fieldRender &&
          !connectToCollection &&
          !objectsConnections
      ) && (
        <Autocomplete
          freeSolo={false}
          value={connectedField?.name || ''}
          disabled={injectedElement}
          onChange={(e, value) => {
            const toSet = { ...typeProps }
            let field
            options.some(item => {
              const bool = item.name === value
              if (bool) {
                field = item
              }
              return bool
            })
            toSet.connectedTo[index].connectedField = field
            clearOnChange.forEach(key => delete toSet[key])
            if (field) {
              if (field.type === 'multipicklist') {
                toSet.picklistType = 'multiselect'
              } else if (toSet.picklistType) {
                toSet.picklistType = 'singleselect'
              }
              if (field.required) {
                toSet.required = true
                toSet.forceRequired = true
              } else if (
                toSet.forceRequired &&
                !toSet.connectedTo.some(
                  obj => obj.connectedField && obj.connectedField.required
                )
              ) {
                clearOnDisconnect.forEach(key => delete toSet[key])
                delete toSet.forceRequired
                delete toSet.required
              }
              if (field.readOnly) {
                toSet.readOnly = true
                clearOnReadOnlyField.forEach(key => delete toSet[key])
              }
            }
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
          style={{ marginTop: 15 }}
          fullWidth
          getOptionLabel={option => optionsMap[option]?.label || ''}
          options={options.map(item => item.name)}
          renderInput={params => (
            <TextField
              variant='outlined'
              {...params}
              label={<Trans>Connected field</Trans>}
            />
          )}
        />
      )}
      {connectToCollection && (
        <Autocomplete
          freeSolo={false}
          style={{ marginTop: 10 }}
          value={connectedCollection || ''}
          disabled={injectedElement}
          onChange={(e, value) => {
            const toSet = { ...typeProps }
            toSet.connectedTo[index].connectedCollection = value
            clearOnChange.forEach(key => delete toSet[key])
            dispatch({
              type: 'FIELD',
              injectable,
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
          fullWidth
          options={relatedCollections.map(item => item.key)}
          getOptionLabel={o => collectionsMap[o] || ''}
          renderInput={params => (
            <TextField
              variant='outlined'
              {...params}
              label={<Trans>Connected collection</Trans>}
            />
          )}
        />
      )}
      {Boolean(fieldRender && connectedObject) && (
        <CustomConnectFieldRender
          disabled={injectedElement}
          fieldRender={fieldRender}
          objects={objects}
          connectedObject={connectedObject}
          index={index}
          avaliableObjectsMap={avaliableObjectsMap}
        />
      )}
    </div>
  )
}

const CustomConnectFieldRender = ({
  fieldRender,
  objects,
  connectedObject,
  avaliableObjectsMap,
  ...props
}) => {
  let fieldsToConnect = []
  objects.some(obj => {
    if (!avaliableObjectsMap?.[connectedObject]) {
      return false
    }
    if (obj.name === avaliableObjectsMap[connectedObject].type) {
      fieldsToConnect = obj.fields
      return true
    }
    return false
  })
  const fieldsToConnectMap = Object.fromEntries(
    fieldsToConnect.map(field => [field.label, field])
  )

  return fieldRender({
    ...props,
    fieldsToConnect,
    fieldsToConnectMap,
    connectedObject,
    objects,
    avaliableObjectsMap
  })
}

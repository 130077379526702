import MomentUtils from '@date-io/moment'
import { setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { portalLanguagesData } from 'app/appSettings'
import catalogEn from 'locales/en/messages.js'
import * as jsonCatalogEn from 'locales/en/messages.json'
import catalogFr from 'locales/fr/messages.js'
import * as jsonCatalogFr from 'locales/fr/messages.json'
import catalogIuCans from 'locales/iu-cans/messages.js'
import * as jsonCatalogIuCans from 'locales/iu-cans/messages.json'
import catalogIuLatn from 'locales/iu-latn/messages.js'
import * as jsonCatalogIuLatn from 'locales/iu-latn/messages.json'
import moment from 'moment'
import 'moment/locale/fr'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { updateCatalog } from '../app/redux/actions/I18nActions'

export const languages = Object.values(portalLanguagesData).map(
  lang => lang.editKey
)

export const languagesWithPortalTranslationId = [
  ...languages,
  'portalTranslationId'
]

export let catalogs = {
  en: catalogEn,
  en_US: catalogEn,
  fr: catalogFr,
  fr_CA: catalogFr,
  'iu-latn': catalogIuLatn,
  'iu-cans': catalogIuCans
}

export const jsonCatalogs = {
  en: jsonCatalogEn.default,
  fr: jsonCatalogFr.default,
  'iu-latn': jsonCatalogIuLatn.default,
  'iu-cans': jsonCatalogIuCans.default
}

export const myI18n = setupI18n()

export class UTCMomentUtils extends MomentUtils {
  parse (value, format) {
    return this.moment.utc(value, format, true)
  }

  date (value) {
    return this.moment.utc(value)
  }
}

export const userToFormat = user => {
  if (user && (user.language === 'fr' || user.language === 'fr_CA')) {
    return 'DD/MM/YYYY'
  } else {
    return 'YYYY-MM-DD'
  }
}

const mergeCatalogs = (catalogOld, catalogNew = {}) => {
  return {
    ...catalogOld,
    ...catalogNew,
    messages: {
      ...catalogOld?.messages,
      ...catalogNew?.messages,
      ...catalogOld?.messages,
      ...catalogNew?.messages
    }
  }
}

const I18nConnectedProvider = ({
  language,
  children,
  loadedCatalogs = {},
  updateCatalog
}) => {
  useEffect(() => {
    moment.locale(language)
  }, [language])

  useEffect(() => {
    console.log('load catalog')
    Promise.all([
      import(
        /* webpackChunkName: "messagesEn" */ '../../public/assets/js/locale/en/messages.js'
      ),
      import(
        /* webpackChunkName: "messagesFr" */ '../../public/assets/js/locale/fr/messages.js'
      ),
      import(
        /* webpackChunkName: "messagesIuCans" */ '../../public/assets/js/locale/iu-cans/messages.js'
      ),
      import(
        /* webpackChunkName: "messagesIuLatn" */ '../../public/assets/js/locale/iu-latn/messages.js'
      )
    ])
      .then(
        ([
          catalogEnDynamic,
          catalogFrDynamic,
          catalogIuCansDynamic,
          catalogIuLatnDynamic
        ]) => {
          console.log('localeSplit', catalogEnDynamic, catalogFrDynamic)
          const en = catalogEnDynamic.default
          en.info = { update: new Date() }
          const fr = catalogFrDynamic.default
          fr.info = { update: new Date() }
          updateCatalog('en', en)
          updateCatalog('fr', fr)
          updateCatalog('iu-cans', catalogIuCansDynamic.default)
          updateCatalog('iu-latn', catalogIuLatnDynamic.default)
          updateCatalog('en_US', en)
          updateCatalog('fr_CA', fr)
        }
      )
      .catch(e => {
        console.warn('localeSplit failed loading messages', e)
      })

    // .then(function() {
    //   console.log('Loadedcata');
    // });
  }, [])
  const mergedCatalogs = {
    en: mergeCatalogs(catalogs.en, loadedCatalogs.en),
    fr: mergeCatalogs(catalogs.fr, loadedCatalogs.fr),
    en_US: mergeCatalogs(catalogs.en, loadedCatalogs.en),
    fr_CA: mergeCatalogs(catalogs.fr, loadedCatalogs.fr),
    'iu-cans': mergeCatalogs(catalogs['iu-cans'], loadedCatalogs['iu-cans']),
    'iu-latn': mergeCatalogs(catalogs['iu-latn'], loadedCatalogs['iu-latn'])
  }
  Object.keys(catalogs).forEach(key => {
    const lang = key.split('_')[0]
    mergedCatalogs[key] = mergeCatalogs(catalogs[lang], loadedCatalogs[lang])
  })
  catalogs = mergedCatalogs
  console.log('LANG', language, mergedCatalogs, loadedCatalogs)

  return (
    <I18nProvider language={language} catalogs={mergedCatalogs} i18n={myI18n}>
      {/* <I18nProvider i18n={i18n}> */}
      {/* <I18nProvider i18n={i18n}> */}
      <MuiPickersUtilsProvider
        utils={UTCMomentUtils}
        libInstance={moment}
        // locale?
        locale={language}
      >
        {children}
      </MuiPickersUtilsProvider>
    </I18nProvider>
  )
}

const mapStateToProps = state => ({
  language: state.user.language || 'en',
  loadedCatalogs: state.i18n
})
export default connect(mapStateToProps, { updateCatalog })(
  I18nConnectedProvider
)

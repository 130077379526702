import { Trans } from '@lingui/macro'
import {
  FormControlLabel,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { useDispatch } from 'react-redux'

/**
 * Interface used to configure props for read only view of form elements.
 * @category Form
 * @subcategory Editor
 * @component
 * @returns {JSX.Element}
 * @param  {object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {string} elementType - Type of form element being edited.
 * @param {boolean} disabled - If form element should be disabled
 * @param {boolean} [injectable=false] - If used with reusable form element.
 * @param  {number[]} depth - Position in form editor tree represented by an array of indexes.
 */
export const FormElementPrintProps = ({
  elementType,
  disabled,
  injectable,
  depth,
  typeProps,
  ...props
}) => {
  const {
    printPageBreakBefore,
    printAvoidBreak,
    printLabelPlacement,
    printIcon
  } = typeProps
  const dispatch = useDispatch()
  return (
    <div style={{ marginTop: 10 }}>
      <Typography variant='h6'>
        <Trans>Read only props</Trans>
      </Typography>

      {[
        'datePicker',
        'textInput',
        'textInputNumeric',
        'connectAccount',
        'connectContact'
      ].includes(elementType) && (
        <RadioGroup
          value={printLabelPlacement || 'top'}
          onChange={(e) => {
            const toSet = { ...typeProps }
            toSet.printLabelPlacement = e.currentTarget.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <FormControlLabel
            disabled={disabled}
            value='top'
            control={<Radio />}
            label={<Trans>Render label at top</Trans>}
          />

          <FormControlLabel
            disabled={disabled}
            value='bottom'
            control={<Radio />}
            label={<Trans>Render label at bottom</Trans>}
          />
        </RadioGroup>
      )}

      {['picklist'].includes(elementType) && (
        <TextField
          disabled={disabled}
          select
          variant='outlined'
          label={<Trans>Print list icon</Trans>}
          fullWidth
          value={printIcon || 'none'}
          style={{ marginTop: 10 }}
          onChange={(e) => {
            const toSet = { ...typeProps }
            toSet.printIcon = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <MenuItem value='none' key='none'>
            <Trans>None</Trans>
          </MenuItem>
          {[
            'supervised_user_circle',
            'accessibility_new',
            'public',
            'fiber_manual_record',
            'payments',
            'location_city',
            'paid',
            'work'
          ].map((item) => (
            <MenuItem value={item} key={item}>
              <Icon>{item}</Icon>
            </MenuItem>
          ))}
        </TextField>
      )}
      {/* <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={Boolean(printPageBreakBefore)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printPageBreakBefore = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Page break before?</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={Boolean(printAvoidBreak)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printAvoidBreak = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Avoid breaking element into separte pages?</Trans>}
      /> */}
    </div>
  )
}

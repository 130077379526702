import { t } from '@lingui/macro'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { Connection } from 'jsforce'
import moment, { Moment } from 'moment'
import { globalValues } from '../../../utils/GlobalValues'
import SFAuthService, { NO_USER } from '../SFAuthService'
import { mapSFToForm } from '../sfDataService'
import { readOnlyField, readOnlyFieldMTF } from './jsConnectionUtils'
import { getAccountJoinOpportunityListByFlow } from './sfAccountAndOpportunityJoin'
import { getContactsByFlow } from './sfContact'

export const otherFields = ({ withStar = true } = {}) => {
  let ret
  if (sfOauthConfig.isIcce) {
    ret = [
      'Campaign.Name',
      'Campaign.StartDate',
      'Campaign.EndDate',
      'RecordType.Name',
      'RecordType.Id',
      'UserRecordAccess.HasEditAccess',
      'UserRecordAccess.HasReadAccess',
      'Owner.Alias',
      'Account.*',
      'Budget__r.*'
    ]
  } else {
    ret = [
      'Campaign.Name',
      'Campaign.StartDate',
      'Campaign.EndDate',
      'Account.Name',
      'RecordType.Name',
      'RecordType.Id',
      'Account.Account_Type__c',
      'Account.BillingAddress',
      'Account.Geographic_area_of_Operation__c',
      'Account.Total_yearly_budget__c',
      'Account.Mission_Statement__c',
      'Account.Organisation_History__c',
      'Account.Indigenous_Organization__c',
      'Account.Focus_demographic__c',
      'Account.Services_provided__c',
      'Account.Number_of_tenants_living_in_your_buildin__c',
      'Account.Housing_units_managed_by_Organisation__c',
      'Account.Units_special_needs__c',
      'UserRecordAccess.HasEditAccess',
      'UserRecordAccess.HasReadAccess',
      'Account.NumberOfEmployees',
      'Owner.Alias',
      'Account.Type',
      'Budget__r.*'
    ]
  }
  if (withStar) {
    ret.unshift('*')
  }
  return ret
}

// using * in field list on internal ends up going over the header size
// because the query becomes to large

/**
 * Retrieves base fields for a grant application from Salesforce.
 * @function
 * @category Salesforce - Opportunity__c
 * @returns {string[]} An array containing the base fields for a grant application.
 */
export const getBaseFields = () => {
  const baseFields = [
    'Id',
    'Name',
    'Funding_Stream__c',
    'Form_Version__c',
    'Grant_Form__c',
    'AccountId',
    'LastModifiedDate',
    'CreatedDate',
    'Partners_summary__c',
    'RecordTypeId',
    'StageName',
    'Description',
    'Hardcoded_form__c'
  ]
  if (sfOauthConfig.isIcce) {
  } else {
    baseFields.push(
      'isvalidated__c',
      'Other_Demographic__c',
      'Assigned_Program_Manager__c',
      'External_Validation_Status__c',
      'Contract_Signed_Date__c',
      'FGM_Portal__Total_Project_Budget__c',
      'PM_Contact__c',
      'PM_Name__c',
      'PM_notes_to_reviewers__c',
      'Submit_Date__c',
      'PM_Recommendation__c',
      'PM_Recommendation_TXT__c',
      'FGM_Base__Recommended_Amount__c',
      'Impact_summary__c',
      'Detailed_description__c',
      'Target_demographic_application__c',
      'Summary__c',
      'Other_target_demographic__c',
      'Other_supported_demographic__c',
      'Supported_demographics__c',
      'Priority_areas__c',
      'Target_demographics__c',
      'Demographic__c',
      'Geographical_scope__c',
      'Number_of_tenants__c',
      'Accept_to_share_your_project__c',
      'Tenants_number__c',
      'Other_grant_requests_related_to_project__c',
      'Provider_the_project_intended_to_benefit__c',
      'terms_and_conditions_1__c',
      'terms_and_condition_2__c',
      'terms_and_condition_3__c',
      'terms_and_condition_4__c',
      'terms_and_condition_5__c',
      'Referred_by__c',
      'Referred_by_specifics__c',
      'Referred_by_more_details__c',
      'Signer_1_Name__c',
      'Signer_1_SurName__c',
      'Signer_1_EmailAddress__c',
      'Signer_1_JobTitle__c',
      'Signer_1_Phone__c',
      'Signer_2_Name__c',
      'Signer_2_SurName__c',
      'Signer_2_EmailAdrdess__c',
      'Signer_2_JobTitle__c',
      'Signer_2_Phone__c',
      'Accounting_First_Name__c',
      'Accounting_Last_Name__c',
      'Accounting_Email__c',
      'Accounting_Job_Title__c',
      'Accounting_Phone__c',
      'Secondary_Contact_Name__c',
      'Secondary_Contact_SurName__c',
      'Secondary_Contact_EmailAddress__c',
      'Secondary_Contact_JobTitle__c',
      'Secondary_Contact_Phone__c',
      'Reference_contact_1_Name__c',
      'Reference_contact_1_Surname__c',
      'Reference_contact_1_Email__c',
      'Reference_contact_1_Relationship__c',
      'Reference_contact_1_Phone__c',
      'Reference_contact_1_Organization__c',
      'Reference_contact_2_Name__c',
      'Reference_contact_2_Surname__c',
      'Reference_contact_2_Email__c',
      'Reference_contact_2_Relationship__c',
      'Reference_contact_2_Phone__c',
      'Reference_contact_2_Organization__c',
      'Units_women_and_children__c',
      'Units_single_parent__c',
      'Units_racialized_persons__c',
      'Units_Indigenous__c',
      'Units_fleeing_domestic_violence__c',
      'Partners__c',
      'Grant_Awarded__c',
      'FGM_Base__Payments_Made__c',
      'FGM_Base__Project_Budget__c',
      'FGM_Base__Amount_Requested__c',
      'How_Center_s_Grant_is_essential__c',
      'tenant_population__c',
      'Tenants__c',
      'Organizations_number__c',
      'The_project_and_transformational_impact__c',
      'Buildings__c',
      'Staff__c',
      'Units__c',
      'Geographical_Location__c',
      'Priority_areas_description__c',
      'Challenges__c',
      'Pre_Qualification__c'
    )
  }
  return baseFields
}

export const opportunityListFields = [
  'Name',
  'RecordType.Name',
  'AccountId',
  'Funding_Stream__c',
  'UserRecordAccess.HasEditAccess'
]

export const SF_API_VERSION = 'v49.0'

export const STF_LP = 'stf-lp'
export const STF_SIP = 'stf-sip'
export const CBTI = 'cbti'
export const FCHI2 = 'fchi-2'
export const GKF = 'gkf'
export const CHGF_PP = 'chgf-pp'
export const CHGF_CB = 'chgf-cb'
export const CHGF_RI = 'chgf-ri'
export const NPF_CB = 'npf-cb'
export const NPF_PPD = 'npf-ppd'
export const NPF_RI = 'npf-ri'
export const NLCHGF = 'nlchgf'

export const CHGF_PP_RECORD_TYPE =
  'Community Housing Growth Fund - Planning & Pre-Development'
export const CHGF_CB_RECORD_TYPE =
  'Community Housing Growth Fund – Capacity Building'
export const CHGF_RI_RECORD_TYPE =
  'Community Housing Growth Fund - Research & Innovation'
export const STF_LP_RECORD_TYPE = 'Sector Transformation Fund – Local Project'
export const STF_SIP_RECORD_TYPE =
  'Sector Transformation Fund – Sectoral Impact'
export const CBTI_RECORD_TYPE =
  'Community-Based Tenant Initiative Fund (CBTIF)'
export const FCHI2_RECORD_TYPE = 'TRA'
export const GREEN_KICKSTARTER_RECORD_TYPE = 'Green Kickstarter Fund'
export const NPF_CB_RECORD_TYPE =
  'Nunalingni Piruqpaalirut Fund - Capacity Building'
export const NPF_PPD_RECORD_TYPE =
  'Nunalingni Piruqpaalirut Fund - Planning & Pre-Development'
export const NPF_RI_RECORD_TYPE =
  'Nunalingni Piruqpaalirut Fund - Research & Innovation'
export const NLCHGF_RECORD_TYPE =
  'NL Community Housing GF - Planning & Pre-Development'
export const NSCHCF_RECORD_TYPE = 'Nova Scotia Community Housing Capital Fund'

export const STF_LP_OBJECTIVE_RECORD_TYPE = 'STF_Local_Project_objectives'
export const STF_SIP_OBJECTIVE_RECORD_TYPE =
  'STF_Sectoral_Impact_project_objectives'
export const CBTI_OBJECTIVE_RECORD_TYPE = 'CBTIF_project_objectives'
export const CHGF_CB_OBJECTIVE_RECORD_TYPE =
  'Community_Housing_Growth_Fund_Capacity_Building_project_objectives'
export const CHGF_RI_OBJECTIVE_RECORD_TYPE =
  'Community_Housing_Growth_Fund_Research_Innovation_project_objectives'
export const CHGF_PP_OBJECTIVE_RECORD_TYPE =
  'Community_Housing_Growth_Fund_Planning_Pre_Development_project_objectives'
export const FCHI2_OBJECTIVE_RECORD_TYPE = 'FCHI_2'
export const campaingsIDs = {
  'Community-Based Tenant Initiative Fund (CBTIF)': '7015X0000008wMsQAI',
  'Sector Transformation Fund – Local Project': '7015X0000008wO5QAI',
  'Sector Transformation Fund – Sectoral Impact': '7015X0000008wO0QAI'
}

/**
 * Converts a funding record type name to its corresponding funding stream full name.
 * @function
 * @category Salesforce - Funding_Stream__c and Opportunity__c
 * @param {string} name - The name of the funding record type.
 * @returns {string|null} The corresponding funding stream full name, or null if no match is found.
 */
export function fundToFundType (name: string) {
  let toRet: string | null = null
  switch (name) {
    case STF_SIP_RECORD_TYPE:
      toRet = STF_SIP
      break
    case STF_LP_RECORD_TYPE:
      toRet = STF_LP
      break
    case CBTI_RECORD_TYPE:
      toRet = CBTI
      break
    case FCHI2_RECORD_TYPE:
      toRet = FCHI2
      break
    case GREEN_KICKSTARTER_RECORD_TYPE:
      toRet = GKF
      break
    case CHGF_PP_RECORD_TYPE:
      toRet = CHGF_PP
      break
    case CHGF_CB_RECORD_TYPE:
      toRet = CHGF_CB
      break
    case CHGF_RI_RECORD_TYPE:
      toRet = CHGF_RI
      break
    case NPF_CB_RECORD_TYPE:
      toRet = NPF_CB
      break
    case NPF_PPD_RECORD_TYPE:
      toRet = NPF_PPD
      break
    case NPF_RI_RECORD_TYPE:
      toRet = NPF_RI
      break
    case NLCHGF_RECORD_TYPE:
      toRet = NLCHGF
      break
    default:
      break
  }
  return toRet
}

export const FundToCaseType = {
  [STF_SIP_RECORD_TYPE]: 'Funding - STFSI',
  [CBTI_RECORD_TYPE]: 'Funding - CBTI',
  [STF_LP_RECORD_TYPE]: 'Funding - STFLP',
  [FCHI2_RECORD_TYPE]: 'Funding - FCHI Interim',
  [GREEN_KICKSTARTER_RECORD_TYPE]: 'Green Kickstarter Fund',
  [CHGF_PP_RECORD_TYPE]: 'Funding - CHGF-PPD',
  [CHGF_CB_RECORD_TYPE]: 'Funding - CHGF-CB',
  [CHGF_RI_RECORD_TYPE]: 'Funding - CHGF-RI',
  [NPF_CB_RECORD_TYPE]: 'Funding - NPF-CB',
  [NPF_PPD_RECORD_TYPE]: 'Funding - NPF-PPD',
  [NPF_RI_RECORD_TYPE]: 'Funding - NPF-RI',
  [NLCHGF_RECORD_TYPE]: 'Funding - NLGF - PPD',
  [NSCHCF_RECORD_TYPE]: 'Funding - NSCHCF'
}

const MAP_FUND_TO_ABREV = {
  [CBTI]: 'CBTI',
  [STF_LP]: 'STFLP',
  [STF_SIP]: 'STFSI',
  [FCHI2]: 'TRA',
  [GKF]: 'GKF',
  [CHGF_PP]: 'CHGF - P&P',
  [CHGF_CB]: 'CHGF - CB',
  [CHGF_RI]: 'CHGF - R&I',
  [NPF_CB]: 'NPF - CB',
  [NPF_PPD]: 'NPF  - P&P',
  [NPF_RI]: 'NPF - R&I',
  [NLCHGF]: 'NLCHGF',
  null: 'Unknown'
}

/**
 * Maps a funding stream name to its corresponding abbreviation.
 * @function
 * @category - Salesforce - Funding_Stream__c and Opportunity__c
 * @param {string} name The name of the funding stream.
 * @returns {string} The abbreviation corresponding to the funding record, or an empty string if no abbreviation is found.
 */
export function mapFundToAbbreviation (name: string) {
  return MAP_FUND_TO_ABREV[fundToFundType(name) as string]
}

/**
 * Retrieves record types for a specified Salesforce object.
 * @function
 * @category Salesforce - various objects
 * @param {string} sobject The name of the Salesforce object.
 * @param {boolean} [byDeveloperName=false] - Optional. Indicates whether to retrieve record types by developer name. Default is false.
 * @returns {object} Object containing record types for the specified object.
 */
export const getRecordTypes = (
  sobject: string,
  byDeveloperName: boolean = false
) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject(sobject)
    .describe()
    .then((result) => {
      const ret = {}
      for (const item of result.recordTypeInfos) {
        ret[byDeveloperName ? item.developerName : item.name] = item
      }
      return ret
    })
}

export const opportunityFieldSets = {
  ALL: {
    OBJECTIVES: true,
    BUDGET: true,
    MILESTONES: true,
    HISTORIES: true,
    REPORTS: true,
    PROCESS_INSTANCES: true,
    SURVEYS: true
  },
  REPORTS: {
    REPORTS: true
  }
}

interface OpportunityPermissions {
  OBJECTIVES?: boolean
  PROCESS_INSTANCES?: boolean
  TEAM_MEMBERS?: boolean
  VOTE?: boolean
  BUDGET?: boolean
  MILESTONES?: boolean
  REPORTS?: boolean
  ICCE?: boolean
  fullICCE?: boolean
  SURVEYS?: boolean
  HISTORIES?: boolean
  CMHC?: boolean
  DMAH?: boolean
}

interface OpportunityProps {
  searchParams?: object
  permissions?: OpportunityPermissions
  fields?: string[]
}

/**
 * Retrieves opportunities from Salesforce based on specified parameters and permissions.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {object} [props] Optional. Properties for customizing the search and fields to retrieve.
 * @param {object} [props.searchParams] Optional. Parameters to filter the search.
 * @param {object} [props.permissions] Optional. Permissions to determine which fields to include.
 * @param {string[]} [props.fields] Optional. Fields to retrieve for the opportunities.
 * @returns {Opportunity[]}
 */
export const getOpportunities = (props: OpportunityProps = {}) => {
  const conn = SFAuthService.getConnection()
  const {
    searchParams,
    permissions = opportunityFieldSets.ALL as OpportunityPermissions,
    fields = otherFields({ withStar: true })
  } = props
  if (conn) {
    let retPromise = conn
      .sobject('Opportunity')
      .find({
        Community_Delete__c: false,
        ...searchParams
      })
      .select(fields.join(', '))

    retPromise = handlePermissions(permissions, retPromise)

    retPromise = retPromise.run({ autoFetch: true })
    return retPromise
  } else {
    return Promise.reject(NO_USER)
  }
}

interface Opportunity {
  Id: string
  Account: {
    fieldName: string
    Name: string
  } | null
  RecordTypeId: string
  'RecordType.Name'?: string
  Name: string
  Description?: string
  StageName?: string
  PM_Name__c?: string
  External_Validation_Status__c?: string
  PM_Contact__c?: string
  PM_notes_to_reviewers__c?: string
  CampaignId?: string
  CreatedDate: Date | moment.Moment
  LastModifiedDate: Date | moment.Moment
  FGM_Base__Amount_Requested__c?: number
  FGM_Base__Recommended_Amount__c?: number
  FGM_Portal__Total_Project_Budget__c?: number
  OpportunityTeamMembers?: any[] | null
  OpportunityHistories?: Array<{ stage: string, date: Date | moment.Moment }> | null
  Priority_areas__c?: string
  Objectives__c?: string
  Partners__c?: string
  Geographical_Location__c?: string
}

interface ParsedOpportunity {
  id: string
  account: {
    fieldName: string
    name: string
  }
  recordTypeId: string
  recordTypeName?: string
  name: string
  description?: string
  stageName?: string
  pmName?: string
  externalValidationStatus?: string
  pmContact?: string
  pmNotesToReviewers?: string
  campaignId?: string
  createdDate: Date | moment.Moment
  lastModifiedDate: Date | moment.Moment
  amountRequested?: number
  recommendedAmount?: number
  budgetTotal?: {
    key: string
    in: (opp: any) => any
  }
  teamMembers?: {
    nonField: boolean
    key: string
    in: (opp: any) => any[]
  }
  history: {
    nonField: boolean
    key: string
    in: (opp: any) => Array<{ stage: string, date: string }>
  }
  priorityAreas?: string
  objectives?: string
  partners?: string
  geographicalScopeDetails?: string
  recordType: {
    fieldName: string
    name?: string
  }
}

interface GetOpportunitiesParsedProps {
  [key: string]: any
}

/**
 * Retrieves opportunities based on specified properties (if specified), or all opportunities
 * (if properties are not provided), optionally filters them based on edit access, and parses their fields.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {GetOpportunitiesParsed} [props] - Optional. Properties for customizing the search and fields to retrieve.
 * @param {boolean} [onlyEditAccess=false] - Optional. Indicates whether to filter opportunities based on edit access. Default is false.
 * @returns {ParsedOpportunity[]}
 */
export const getOpportunitiesParsed = (
  props: GetOpportunitiesParsedProps = {},
  onlyEditAcess: boolean = false
): ParsedOpportunity[] => {
  return getOpportunities(props).then((result) => {
    console.log('mapFields', mapFields)
    console.log('opportunities mapped', result)
    if (onlyEditAcess) {
      result = result.filter(function (opp) {
        return opp.UserRecordAccess.HasEditAccess
      })
    }
    return result.map((item) => {
      return mapSFToForm({ ...mapFields, ...props.mapFields }, item)
    })
  })
}

/**
 * Retrieves the name of the stage of an opportunity identified by its id.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the opportunity to retrieve the stage name for.
 * @returns {StageName}
 */
export const getOpportunityStage = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Opportunity')
    .find({
      Id: id
    })
    .select('StageName')
    .then((found) => {
      return found[0].StageName
    })
}

/**
 * Deletes budget lines associated with the provided id(s).
 * @function
 * @category Salesforce - FGM_Portal__Grantee_Budget_Line_Item__c
 * @param {string|string[]} ids The id or ids of the budget lines to delete.
 * @returns {JSForceResult}
 */
export const deleteBudgetLines = (ids: string[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Portal__Grantee_Budget_Line_Item__c').delete(ids)
}

/**
 * Handles permissions for an opportunity based on specified permissions and returns the appropriate promise.
 * @function
 * @param {OpportunityPermissions} permissions The permissions to handle.
 * @param {any} retPromise The promise to return.
 * @returns {any} The promise with the appropriate permissions applied.
 */
function handlePermissions (
  permissions: OpportunityPermissions,
  retPromise: any
) {
  if (permissions.CMHC) {
    retPromise = retPromise
      .include('FGM_Base__Reviews__r')
      .select(
        'CMHC_Priority_areas_touched__c, RecordType.Name, FGM_Base__Review_Date__c'
      )
      .end()
  }

  if (permissions.DMAH) {
    retPromise = retPromise
      .include('FGM_Base__Reviews__r')
      .select('Id, FGM_Base__Status__c, RecordTypeId, RecordType.*')
      .end()
  }

  if (permissions.PROCESS_INSTANCES) {
    retPromise = retPromise.include('ProcessInstances').end()
  }

  if (permissions.fullICCE) {
    retPromise = retPromise
      .include('Work_Plan_Lines__r')
      .end()
      .include('Budget_Lines__r')
      .end()
  }

  if (permissions.OBJECTIVES) {
    retPromise = retPromise
      .include('Objectives__r')
      .select(
        'Objective__c.Outcomes__c, Objective__c.Identified_needs__c, Objective__c.Objective__c'
      )
      .end()
  }

  if (permissions.TEAM_MEMBERS) {
    retPromise = retPromise
      .include('OpportunityTeamMembers')
      .select('UserId, TeamMemberRole, OpportunityAccessLevel')
      .end()
  }
  if (permissions.VOTE) {
    retPromise = retPromise.include('External_Approvals__r').end()
  }
  if (permissions.BUDGET) {
    retPromise = retPromise
      .include('FGM_Portal__Grantee_Budget_Line_Items__r')
      .select(
        'Id, LastModifiedDate, FGM_Portal__Amount__c, FGM_Portal__Category__r.Name, FGM_Portal__Grantee_Budget__r.Name, ' +
          'FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name, ' +
          'FGM_Portal__Grantee_Budget_Line_Item__c.FGM_Portal__Note__c '
      )
      .end()
  }
  if (permissions.MILESTONES) {
    retPromise = retPromise
      .include('FGM_Base__Benchmarks__r')
      .select(
        'FGM_Base__Due_Date__c, FGM_Base__Status__c,  FGM_Base__Completion_Date__c, Id, FGM_Base__Description__c, Primary_activities__c'
      )
      .end()
  }
  if (permissions.REPORTS) {
    retPromise = retPromise
      .include('FGM_Base__Grantee_Reports__r')
      .select(
        'FGM_Base__Status__c, FGM_Base__Is_Overdue__c, FGM_Base__Due_Date__c, RecordTypeId, Id, Name, Type__c, Survey__c'
      )
      .end()
  }

  if (permissions.ICCE) {
    retPromise = retPromise
      .include('Work_Plan_Lines__r')
      .end()
      .include('Budget_Lines__r')
      .end()
  }

  if (permissions.SURVEYS) {
    retPromise = retPromise.include('Surveys__r').end()
  }

  if (permissions.HISTORIES) {
    retPromise = retPromise
      .include('OpportunityHistories')
      .select('CreatedDate, StageName')
      .end()
  }

  return retPromise
}

export const getOpportunityLite = (id, additionalFields = []) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Opportunity')
    .findOne({ Id: id })
    .select(
      [
        'Id',
        'Name',
        'Funding_Stream__c',
        'StageName',
        'Form_Version__c',
        'RecordTypeId',
        'RecordType.Name',
        'Grant_Form__c',
        'AccountId',
        ...additionalFields
      ].join(', ')
    )
}

/**
 * Retrieves detailed information about an opportunity identified by its id.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the opportunity to retrieve information for.
 * @param {object} [props={}] Additional properties for customizing the query (optional).
 * @param {object} [props.permissions={}] Permissions object specifying permissions for the query (optional).
 * @param {string[]} [props.fields] Fields to select for the query (optional).
 * @returns {Opportunity}
 */
export const getOpportunity = (id: string, props: OpportunityProps = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const { permissions = {}, fields = otherFields({ withStar: true }) } = props

  let retPromise
  if (sfOauthConfig.isIcce) {
    retPromise = conn
      .sobject('Opportunity')
      .find({ Id: id })
      .select(fields.join(', '))
      .include('OpportunityTeamMembers')
      .select('UserId')
      .end()
      .include('OpportunityHistories')
      .select('CreatedDate, StageName')
      .end()
  } else {
    retPromise = conn
      .sobject('Opportunity')
      .find({ Id: id })
      .select(fields.join(', '))
      .include('Objectives__r')
      .select(
        'Objective__c.Id, Objective__c.Outcomes__c, Objective__c.Identified_needs__c,' +
          ' Objective__c.Objective__c'
      )
      .end()
      .include('FGM_Base__Benchmarks__r')
      .end()
      .include('FGM_Portal__Grantee_Budget_Line_Items__r')
      .select(
        'Id, FGM_Portal__Amount__c, FGM_Portal__Category__r.Name, FGM_Portal__Grantee_Budget__r.Name, ' +
          'FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name, ' +
          'FGM_Portal__Grantee_Budget_Line_Item__c.FGM_Portal__Note__c '
      )
      .end()
      .include('OpportunityTeamMembers')
      .select('UserId, TeamMemberRole, OpportunityAccessLevel')
      .end()
      .include('OpportunityHistories')
      .select('CreatedDate, StageName')
      .end()
  }

  retPromise = handlePermissions(permissions, retPromise)

  retPromise = retPromise
    .run({ autoFetch: true, maxFetch: 500 })
    .then((result) => {
      if (result && result.length === 1) {
        return result[0]
      } else {
        return Promise.reject(new Error('NO OPPORTUNITY FOUND'))
      }
    })

  return retPromise
}

/**
 * Retrieves opportunity data and additional information required for a stepper.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the opportunity.
 * @param {object} [props={}] - Additional properties for fetching the opportunity.
 * @returns {object} An object with data of the opportunity as well as data of related account and contacts.
 */
export const getOpportunityToStepper = (
  id: string,
  props: OpportunityProps = {}
) => {
  if (sfOauthConfig.isIcce) {
    return getOpportunity(id, {
      permissions: {
        fullICCE: sfOauthConfig.isIcce
      },
      ...props
    }).then(async (app) => {
      return await Promise.all([
        getAccountJoinOpportunityListByFlow({
          opportunity: app.Id
        }),
        getContactsByFlow([
          app.npsp__Primary_Contact__c,
          app.Accounting_Contact__c,
          app.First_Signer_opp__c,
          app.Second_Signer_opp__c,
          app.Administrative_Contact__c
        ])
      ]).then(([joinsFlow, contactsFlow]) => {
        const contacts = contactsFlow[0].outputValues.contactsFound
        return {
          ...oppToStepper(app),
          contacts: {
            primaryContact: contacts[0] || {},
            accounting: contacts[1] || {},
            firstSinger: contacts[2] || {},
            secondSigner: contacts[3] || {},
            administrative: contacts[4] || {}
          },
          representedOrganization: joinsFlow[0].outputValues.result
        }
      })
    })
  } else {
    return getOpportunity(id, props).then((app) => {
      return {
        ...oppToStepper(app)
      }
    })
  }
}

/**
 * Fetches an Opportunity by id and parses it according to a specified mapping.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the Opportunity to fetch.
 * @param {object} props Additional properties for fetching the Opportunity.
 * @returns {ParsedOpportunity}
 */
export const getOpportunityParsed = (id: string, props: OpportunityProps) => {
  return getOpportunity(id, props).then((user) => {
    console.log('getOpportunity Parsed', user, mapFields)
    return mapSFToForm(mapFields, user)
  })
}

export const isFake = (name: string) => {
  if (name) {
    name = name.toUpperCase()
    return name.includes('FAKE') || name.includes('TEST')
  }
  return false
}

interface OpportunityValues {
  Objectives__r?: any[]
  Work_Plan_Lines__r?: any[]
  Budget_Lines__r?: any[]
  FGM_Base__Benchmarks__r?: any[]
  FGM_Portal__Grantee_Budget_Line_Items__r?: any[]
  Buildings__r?: any
  Managed_Units_Details__r?: any
  Id: string
  toDeleteUnits?: any[]
  toDeleteRows?: any[]
  Last_Modified_By_User_Date__c?: Moment
  Last_Modified_By__c?: string
}

interface RetObject {
  ObjectivesNew: any[]
  ObjectivesUpdated: any[]
  BenchmarksUpdated: any[]
  BenchmarksNew: any[]
  BudgetLinesNew: any[]
  BudgetLinesUpdated: any[]
  WorkPlanNew: any[]
  WorkPlanUpdated: any[]
  ICCEBudgetLinesNew: any[]
  ICCEBudgetLinesUpdated: any[]
  additionalPromises: Array<Promise<any>>
  UnitsNew?: any[]
  UnitsUpdated?: any[]
  BuildingsNew?: any[]
  BuildingsUpdated?: any[]
  referencePromises?: Array<Promise<any>>
  deleteIds?: string[]
  anyNew?: boolean
  anyUpdated?: boolean
  Opportunity?: OpportunityValues
}

interface ItemWithId {
  Id?: string
}

/**
 * Splits the provided opportunity values based on specified conditions.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {object} values The opportunity values to split.
 * @param {boolean} isFCHI Indicates whether the opportunity is related to FCHI.
 * @param {object} conn The connection object for Salesforce.
 * @returns {object} An object containing various arrays of split opportunity values.
 */
const splitOpportunity = (
  values: OpportunityValues,
  isFCHI: boolean,
  conn: Connection
) => {
  const ret: RetObject = {
    ObjectivesNew: [],
    ObjectivesUpdated: [],
    BenchmarksUpdated: [],
    BenchmarksNew: [],
    BudgetLinesNew: [],
    BudgetLinesUpdated: [],
    WorkPlanNew: [],
    WorkPlanUpdated: [],
    ICCEBudgetLinesNew: [],
    ICCEBudgetLinesUpdated: [],
    additionalPromises: []
  }

  if (!isFCHI) {
    console.log('split opp', { ...values })

    if (values.Objectives__r != null) {
      for (const objective of values.Objectives__r) {
        if (objective.Id) {
          ret.ObjectivesUpdated.push(objective)
          ret.anyUpdated = true
        } else {
          ret.ObjectivesNew.push(objective)
          ret.anyNew = true
        }
      }
      delete values.Objectives__r
    }

    const referencedBudgetLines = {}
    if (values.Work_Plan_Lines__r != null) {
      for (const workPlanLine of values.Work_Plan_Lines__r) {
        const fakeRef =
          workPlanLine.Budget_Line__c && isFake(workPlanLine.Budget_Line__c)
        if (workPlanLine.Id) {
          if (fakeRef) {
            if (referencedBudgetLines[workPlanLine.Budget_Line__c]) {
              referencedBudgetLines[workPlanLine.Budget_Line__c].push(
                workPlanLine
              )
            } else {
              referencedBudgetLines[workPlanLine.Budget_Line__c] = [
                workPlanLine
              ]
            }
          } else {
            ret.WorkPlanUpdated.push(workPlanLine)
            ret.anyUpdated = true
          }
        } else {
          delete workPlanLine.Id
          if (fakeRef) {
            if (referencedBudgetLines[workPlanLine.Budget_Line__c]) {
              referencedBudgetLines[workPlanLine.Budget_Line__c].push(
                workPlanLine
              )
            } else {
              referencedBudgetLines[workPlanLine.Budget_Line__c] = [
                workPlanLine
              ]
            }
          } else {
            ret.WorkPlanNew.push(workPlanLine)
            ret.anyNew = true
          }
        }
      }
      delete values.Work_Plan_Lines__r
    }

    const toCreateWithRef: ItemWithId[] = []
    if (values.Budget_Lines__r != null) {
      for (const budgetLine of values.Budget_Lines__r) {
        if (!budgetLine.Id) {
          ret.ICCEBudgetLinesNew.push(budgetLine)
          ret.anyNew = true
        } else if (
          isFake(budgetLine.Id) &&
          referencedBudgetLines[budgetLine.Id]
        ) {
          toCreateWithRef.push(budgetLine)
        } else {
          if (isFake(budgetLine.Id)) {
            delete budgetLine.Id
            ret.ICCEBudgetLinesNew.push(budgetLine)
            ret.anyNew = true
          } else {
            ret.ICCEBudgetLinesUpdated.push(budgetLine)
            ret.anyUpdated = true
          }
        }
      }
      delete values.Budget_Lines__r
    }

    const oppId = values.Id
    const indexMap = {}
    const toCreate = toCreateWithRef.map((item, index) => {
      const referenced = [
        ...referencedBudgetLines[(item)?.Id || '']
      ]
      delete item.Id
      indexMap[index] = referenced
      return { ...item, Opportunity__c: oppId }
    })
    if (toCreate.length > 0) {
      ret.additionalPromises.push(
        conn
          .sobject('Budget_Line__c')
          .create(toCreate)
          .then(async (created) => {
            const workLinesWithRefUpdated: object[] = []
            const workLinesWithRefCreated: object[] = []
            created.forEach((item, index) => {
              const refTargets = indexMap[index]
              if (refTargets) {
                refTargets.forEach((toCreate) => {
                  if (toCreate.Id) {
                    workLinesWithRefUpdated.push({
                      ...toCreate,
                      Budget_Line__c: item.id
                    })
                  } else {
                    workLinesWithRefCreated.push({
                      ...toCreate,
                      Budget_Line__c: item.id,
                      Opportunity__c: oppId
                    })
                  }
                })
              }
            })
            const toRet: Array<Promise<any>> = []
            if (workLinesWithRefCreated.length > 0) {
              toRet.push(
                conn
                  .sobject('Work_Plan_Line__c')
                  .create(workLinesWithRefCreated)
              )
            }
            if (workLinesWithRefUpdated.length > 0) {
              toRet.push(
                conn
                  .sobject('Work_Plan_Line__c')
                  .update(workLinesWithRefUpdated)
              )
            }
            return await Promise.all(toRet)
          })
      )
    }

    if (values.FGM_Base__Benchmarks__r != null) {
      for (const objective of values.FGM_Base__Benchmarks__r) {
        if (objective.Id) {
          ret.BenchmarksUpdated.push(objective)
          ret.anyUpdated = true
        }
      }
      delete values.FGM_Base__Benchmarks__r
    }

    if (values.FGM_Portal__Grantee_Budget_Line_Items__r != null) {
      for (const objective of values.FGM_Portal__Grantee_Budget_Line_Items__r) {
        if (objective.Id) {
          ret.BudgetLinesUpdated.push({
            Id: objective.Id,
            FGM_Portal__Amount__c: objective.FGM_Portal__Amount__c,
            FGM_Portal__Note__c: objective.FGM_Portal__Note__c
          })
          ret.anyUpdated = true
        } else {
          ret.BudgetLinesNew.push(objective)
          ret.anyNew = true
        }
      }
    }
  } else {
    console.log('split opp', values)
    ret.UnitsNew = []
    ret.UnitsUpdated = []
    ret.BuildingsNew = []
    ret.BuildingsUpdated = []
    ret.referencePromises = []
    ret.deleteIds = []
    const buildingsWithNoId = {}
    if (values.toDeleteUnits != null) {
      values.toDeleteUnits.forEach((id) => {
        ret.deleteIds?.push(id)
      })
      delete values.toDeleteUnits
    }
    if (values.toDeleteRows != null) {
      values.toDeleteRows.forEach((id) => {
        ret.deleteIds?.push(id)
      })
      delete values.toDeleteRows
    }
    values.Buildings__r?.values.forEach((building) => {
      if (!isFake(building.Id)) {
        ret.BuildingsUpdated?.push(building)
        ret.anyUpdated = true
      } else {
        building.referenceUnitsCreate = []
        building.referenceUnitsUpdate = []
        buildingsWithNoId[building.Id] = building
      }
    })
    values.Managed_Units_Details__r.values.forEach((unit, index) => {
      if (unit.Id) {
        if (buildingsWithNoId[unit.Building__c]) {
          buildingsWithNoId[unit.Building__c].referenceUnitsUpdate.push(unit)
        } else {
          ret.UnitsUpdated?.push(unit)
          ret.anyUpdated = true
        }
      } else {
        delete unit.Id
        if (buildingsWithNoId[unit.Building__c]) {
          buildingsWithNoId[unit.Building__c].referenceUnitsCreate.push(unit)
        } else {
          ret.UnitsNew?.push(unit)
          ret.anyNew = true
        }
      }
    })
    Object.keys(buildingsWithNoId).forEach((key) => {
      const building = buildingsWithNoId[key]
      const toUpdate = [...building.referenceUnitsUpdate]
      const toCreate = [...building.referenceUnitsCreate]
      delete building.referenceUnitsUpdate
      delete building.referenceUnitsCreate
      delete building.Id
      const opportunityId = values.Id
      ret.referencePromises?.push(
        conn
          .sobject('Building__c')
          .create({
            ...building,
            Opportunity__c: opportunityId
          })
          .then((result) => {
            const toReturn: Array<Promise<any>> = []
            toUpdate.forEach((unit) => {
              delete unit.Building__c
              toReturn.push(
                conn.sobject('Managed_Units_Details__c').update({
                  ...unit,
                  Building__c: result.id
                })
              )
            })
            toCreate.forEach((unit) => {
              delete unit.Building__c
              delete unit.id
              toReturn.push(
                conn
                  .sobject('Managed_Units_Details__c')
                  .create({
                    ...unit,
                    Opportunity__c: opportunityId,
                    Building__c: result.id
                  })
                  .then(
                    (result) => {},
                    (reject) => {
                      console.log(reject)
                    }
                  )
              )
            })
            return toReturn
          })
      )
    })
    delete values.Managed_Units_Details__r
    delete values.Buildings__r
  }

  delete values.FGM_Portal__Grantee_Budget_Line_Items__r

  ret.Opportunity = values
  return ret
}

interface OpportunityUpdateObject {
  Id: string
  Form_Version__c?: string
}

/**
 * Updates an Opportunity record with the provided values.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {object} values The values to update the Opportunity record with.
 * @returns {JSForceResult}
 */
export const updateOpportunity = (values: OpportunityUpdateObject) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Opportunity').update(values)
}

interface RequestObject {
  method: string
  url: string
  referenceId: string
  body?:
  | {
    allOrNone: boolean
    records: any[]
  }
  | OpportunityValues
}

/**
 * Saves an opportunity with the provided values.
 * If the user is not authenticated, rejects with NO_USER.
 * Otherwise, updates the opportunity using the Salesforce connection.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {OpportunityValues} values The values of the opportunity to be saved.
 * @param {boolean} [isFCHI=false] Indicates whether the opportunity is related to FCHI (optional).
 * @param {string} userId The id of the user.
 * @returns {JSForceResult}
 */
export const saveOpportunity = async ({
  values,
  isFCHI = false,
  userId,
  simulateInternal = false
}: {
  values: OpportunityValues
  isFCHI: boolean
  userId: string
  simulateInternal: boolean
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return await Promise.reject(NO_USER)
  }
  const isInternal = Boolean(simulateInternal || sfOauthConfig.isInternal)

  console.log('saveOpportunity', values)
  const subQueries = splitOpportunity(values, isFCHI, conn)
  console.log('saveOpportunitySplit', subQueries)
  console.log(subQueries.anyNew)
  const opportunityId = subQueries.Opportunity?.Id
  if (subQueries.Opportunity != null) {
    subQueries.Opportunity.Last_Modified_By_User_Date__c = moment.utc()
    if (userId !== '') {
      subQueries.Opportunity.Last_Modified_By__c = userId
    }
    if (isInternal) {
      delete subQueries.Opportunity.Last_Modified_By__c
    }
  }

  const anyNew = subQueries.anyNew
  const anyUpdated = subQueries.anyUpdated

  if (isFCHI) {
    const isUpdated =
      (subQueries.UnitsUpdated as any[]).length +
        (subQueries.BuildingsUpdated as any[]).length >
      0
    const isDeleted = (subQueries.deleteIds?.length as number) > 0

    const requestArray: RequestObject[] = [
      {
        method: 'PATCH',
        url: `/services/data/${SF_API_VERSION}/sobjects/Opportunity/${opportunityId}`,
        referenceId: 'refOpportunity',
        body: subQueries.Opportunity
      },
      ...(anyNew
        ? [
            {
              method: 'POST',
              url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
              referenceId: 'refNewObjects',
              body: {
                allOrNone: true,
                records: [
                  ...((subQueries.UnitsNew != null)
                    ? subQueries.UnitsNew.map((item) => {
                      return {
                        attributes: {
                          type: 'Managed_Units_Details__c',
                          referenceId: 'newUnit'
                        },
                        ...item,
                        Opportunity__c: opportunityId
                      }
                    })
                    : []),
                  ...((subQueries.BuildingsNew != null)
                    ? subQueries.BuildingsNew.map((item) => {
                      return {
                        attributes: {
                          type: 'Building__c',
                          referenceId: 'newBuilding'
                        },
                        ...item,
                        Opportunity__c: opportunityId
                      }
                    })
                    : [])
                ]
              }
            }
          ]
        : [])
    ]
    if (isUpdated) {
      requestArray.push({
        method: 'PATCH',
        url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
        referenceId: 'refUpdatedObjects',
        body: {
          allOrNone: true,
          records: [
            ...(subQueries.UnitsUpdated?.map((item) => ({
              attributes: { type: 'Managed_Units_Details__c' },
              ...item
            })) ?? []),
            ...(subQueries.BuildingsUpdated?.map((item) => ({
              attributes: { type: 'Building__c' },
              ...item
            })) ?? [])
          ]
        }
      })
    }
    if (isDeleted) {
      if (subQueries.deleteIds?.length && subQueries.deleteIds.length < 200) {
        requestArray.push({
          method: 'DELETE',
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            'ids=' +
            (subQueries.deleteIds?.join(',') ?? '') +
            '&allOrNone=true',
          referenceId: 'refDeleteObjects'
        })
      } else {
        const subArrays = new Array(
          Math.ceil((subQueries.deleteIds?.length ?? 0) / 200)
        )
          .fill(0)
          .map((_) => {
            if (subQueries.deleteIds != null) {
              subQueries.deleteIds.splice(0, 200)
            } else {
              return []
            }
          })
        subArrays.forEach((sub, index) => {
          if (sub != null) {
            requestArray.push({
              method: 'DELETE',
              url:
                `/services/data/${SF_API_VERSION}/composite/sobjects?` +
                'ids=' +
                sub.join(',') +
                '&allOrNone=true',
              referenceId: 'refDeleteObjects' + index
            })
          }
        })
      }
    }

    return await Promise.all([
      conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
        allOrNone: true,
        compositeRequest: requestArray
      }),
      subQueries.referencePromises
    ])
  } else {
    return await Promise.all([
      updateOpportunityByFlow(subQueries.Opportunity as OpportunityValues),
      conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
        allOrNone: true,
        compositeRequest: [
          ...(anyNew
            ? [
                {
                  method: 'POST',
                  url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
                  referenceId: 'refNewObjects',
                  body: {
                    allOrNone: true,
                    records: [
                      ...subQueries.ObjectivesNew.map((item) => ({
                        attributes: { type: 'Objective__c' },
                        ...item,
                        Opportunity__c: opportunityId
                      })),
                      ...subQueries.BudgetLinesNew.map((item) => ({
                        attributes: {
                          type: 'FGM_Portal__Grantee_Budget_Line_Item__c'
                        },
                        ...item,
                        FGM_Portal__Opportunity__c: opportunityId
                      })),
                      ...subQueries.WorkPlanNew.map((item) => ({
                        attributes: {
                          type: 'Work_Plan_Line__c'
                        },
                        ...item,
                        Opportunity__c: opportunityId
                      })),
                      ...subQueries.ICCEBudgetLinesNew.map((item) => ({
                        attributes: {
                          type: 'Budget_Line__c'
                        },
                        ...item,
                        Opportunity__c: opportunityId
                      }))
                    ]
                  }
                }
              ]
            : []),
          anyUpdated && {
            method: 'PATCH',
            url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
            referenceId: 'refUpdatedObjects',
            body: {
              records: [
                ...subQueries.ObjectivesUpdated.map((item) => ({
                  attributes: { type: 'Objective__c' },
                  ...item
                })),
                ...subQueries.BenchmarksUpdated.map((item) => ({
                  attributes: { type: 'FGM_Base__Benchmark__c' },
                  ...item
                })),
                ...subQueries.BudgetLinesUpdated.map((item) => ({
                  attributes: {
                    type: 'FGM_Portal__Grantee_Budget_Line_Item__c'
                  },
                  ...item
                })),
                ...subQueries.WorkPlanUpdated.map((item) => ({
                  attributes: {
                    type: 'Work_Plan_Line__c'
                  },
                  ...item
                })),
                ...subQueries.ICCEBudgetLinesUpdated.map((item) => ({
                  attributes: {
                    type: 'Budget_Line__c'
                  },
                  ...item
                }))
              ]
            }
          }
        ].filter((item) => item)
      }),
      ...subQueries.additionalPromises
    ])
  }
}

/**
 * Updates an Opportunity using a custom Flow in Salesforce.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {OpportunityUpdateObject} toUpdate The object containing the data to update the Opportunity.
 * @returns {FlowResult}
 */
export const updateOpportunityByFlow = (toUpdate: OpportunityUpdateObject) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Update_Opportunity', {
    inputs: [{ toUpdate }]
  })
}

/**
 * Deletes an Opportunity from Salesforce.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the Opportunity to delete.
 * @returns {JSForceResult}
 */
export const deleteOpportunity = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Opportunity').update({
    Id: id,
    Community_Delete__c: true
  })
}

/**
 * Submits an Opportunity with the specified id.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} id The id of the Opportunity to submit.
 * @returns {JSForceResult}
 */
export const submitOpportunity = (id: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (sfOauthConfig.isIcce) {
    return conn.sobject('Opportunity').update({
      Id: id,
      StageName: opportunitiesStages.SUBMITTED
    })
  }
  return conn.sobject('Opportunity').update({
    Id: id,
    StageName: opportunitiesStages.SUBMITTED,

    Are_you_a_Canadian_community_housing_pro__c: 'Yes',
    The_total_value_of_project_does_exceed__c: 'Yes',
    The_purpose_mentioned__c: 'Yes',
    Data_Collection_Permission__c: true,

    terms_and_conditions_1__c: true,
    terms_and_condition_2__c: true,
    terms_and_condition_3__c: true,
    terms_and_condition_4__c: true,
    terms_and_condition_5__c: true
  })
}
/*
let example = {
  'Id': '0064m0000027hTpAAI',
  'IsDeleted': false,
  'AccountId': null,
  'RecordTypeId': '0123j000001LByhAAG',
  'IsPrivate': false,
  'Name': 'Fake Opportunity newly created',
  'Description': null,
  'StageName': 'InProgress',
  'Amount': null,
  'Probability': 0,
  'ExpectedRevenue': null,
  'TotalOpportunityQuantity': null,
  'CloseDate': '2020-08-19',
  'Type': null,
  'NextStep': null,
  'LeadSource': null,
  'IsClosed': false,
  'IsWon': false,
  'ForecastCategory': 'Omitted',
  'ForecastCategoryName': 'Omitted',
  'CampaignId': null,
  'HasOpportunityLineItem': false,
  'OwnerId': '0054m000000KIPHAA4',
  'CreatedDate': '2020-08-19T15:07:40.000+0000',
  'CreatedById': '0054m000000KIPHAA4',
  'LastModifiedDate': '2020-08-19T15:07:49.000+0000',
  'LastModifiedById': '0054m000000KIPHAA4',
  'SystemModstamp': '2020-08-19T15:07:49.000+0000',
  'LastActivityDate': null,
  'FiscalQuarter': 3,
  'FiscalYear': 2020,
  'Fiscal': '2020 3',
  'LastViewedDate': '2020-08-19T15:07:49.000+0000',
  'LastReferencedDate': '2020-08-19T15:07:49.000+0000',
  'PartnerAccountId': '0014m000002ngJIAAY',
  'HasOpenActivity': false,
  'HasOverdueTask': false,
  'npe01__Amount_Outstanding__c': 0,
  'npe01__Contact_Id_for_Role__c': null,
  'npe01__Do_Not_Automatically_Create_Payment__c': false,
  'npe01__Is_Opp_From_Individual__c': 'false',
  'npe01__Member_Level__c': null,
  'npe01__Membership_End_Date__c': null,
  'npe01__Membership_Origin__c': null,
  'npe01__Membership_Start_Date__c': null,
  'npe01__Amount_Written_Off__c': 0,
  'npe01__Number_of_Payments__c': 0,
  'npe01__Payments_Made__c': 0,
  'npo02__CombinedRollupFieldset__c': '2020-08-19;|;;|;;|;;|;0064m0000027hTp',
  'npo02__systemHouseholdContactRoleProcessor__c': 'All Opportunities',
  'npsp__Acknowledgment_Date__c': null,
  'npsp__Acknowledgment_Status__c': null,
  'npsp__Primary_Contact__c': null,
  'npsp__Grant_Contract_Date__c': null,
  'npsp__Grant_Contract_Number__c': null,
  'npsp__Grant_Period_End_Date__c': null,
  'npsp__Grant_Period_Start_Date__c': null,
  'npsp__Grant_Program_Area_s__c': null,
  'npsp__Grant_Requirements_Website__c': null,
  'npsp__Is_Grant_Renewal__c': false,
  'npsp__Previous_Grant_Opportunity__c': null,
  'npsp__Requested_Amount__c': null,
  'npsp__Next_Grant_Deadline_Due_Date__c': null,
  'npsp__Primary_Contact_Campaign_Member_Status__c': null,
  'npsp__Honoree_Contact__c': null,
  'npsp__Honoree_Name__c': null,
  'npsp__Notification_Message__c': null,
  'npsp__Notification_Preference__c': null,
  'npsp__Notification_Recipient_Contact__c': null,
  'npsp__Notification_Recipient_Information__c': null,
  'npsp__Notification_Recipient_Name__c': null,
  'npsp__Tribute_Type__c': null,
  'npsp__Matching_Gift_Account__c': null,
  'npsp__Matching_Gift_Employer__c': null,
  'npsp__Matching_Gift_Status__c': null,
  'npsp__Matching_Gift__c': null,
  'npsp__Fair_Market_Value__c': null,
  'npsp__In_Kind_Description__c': null,
  'npsp__In_Kind_Donor_Declared_Value__c': false,
  'npsp__In_Kind_Type__c': null,
  'npsp__Ask_Date__c': null,
  'npsp__Closed_Lost_Reason__c': null,
  'npsp__Gift_Strategy__c': null,
  'FGM_Base__Amount_Requested__c': null,
  'FGM_Base__Award_Date__c': null,
  'FGM_Base__Benchmarks__c': null,
  'FGM_Base__Conflict_of_Interest__c': false,
  'FGM_Base__Duration_Months__c': null,
  'FGM_Base__EIN__c': null,
  'FGM_Base__End_Date__c': null,
  'FGM_Base__Extend_By_Days__c': null,
  'FGM_Base__Extend__c': false,
  'FGM_Base__Extended_End_Date__c': null,
  'FGM_Base__Fiscal_Sponsor_Comment__c': null,
  'FGM_Base__Fiscal_Sponsor__c': false,
  'FGM_Base__IRS_Letter__c': null,
  'FGM_Base__Lead_Program_Parent__c': null,
  'FGM_Base__Lead_Program__c': null,
  'FGM_Base__Multi_Year__c': 'No',
  'FGM_Base__OFAC__c': false,
  'FGM_Base__Organization_Address__c': null,
  'FGM_Base__Organization_Budget__c': null,
  'FGM_Base__Payee__c': null,
  'FGM_Base__Payments_Balance__c': null,
  'FGM_Base__Payments_To_Be_Scheduled__c': null,
  'FGM_Base__Previous_Request__c': null,
  'FGM_Base__Project_Budget__c': null,
  'FGM_Base__Recommended_Amount__c': null,
  'FGM_Base__Renewal__c': false,
  'FGM_Base__Request_Number_Apex__c': '20-00001',
  'FGM_Base__Request_Number_Legacy__c': null,
  'FGM_Base__Request_Number__c': '20-00001',
  'FGM_Base__Request_Type__c': null,
  'FGM_Base__Sponsored_Organization__c': null,
  'FGM_Base__Start_Date__c': null,
  'FGM_Base__Submitted_By__c': null,
  'FGM_Base__Contributions__c': 0,
  'FGM_Base__Payments_Made__c': 0,
  'FGM_Base__Payments_Scheduled__c': 0,
  'FGM_Portal__Campaign_End_Date__c': null,
  'FGM_Portal__ConsultantUser__c': null,
  'FGM_Portal__DeliveryInstallationStatus__c': null,
  'FGM_Portal__LOI__c': null,
  'FGM_Portal__MainCompetitors__c': 'default',
  'FGM_Portal__OrderNumber__c': null,
  'FGM_Portal__Quiz__c': null,
  'FGM_Portal__Test_Request_Campaign_Field__c': null,
  'FGM_Portal__TrackingNumber__c': null,
  'FGM_Portal__Total_Project_Budget__c': 0,
  'Secondary_Contact__c': null,
  'Priority_areas__c': null,
  'Priority_areas_description__c': null,
  'Objectives__c': null,
  'Needs_to_meet__c': null,
  'Gaps_to_fill__c': null,
  'Difference_to_make__c': null,
  'Successful_project__c': null,
  'Fiscal_year__c': null,
  'isvalidated__c': null,
  'The_total_value_of_project_does_exceed__c': null,
  'Number_of_Benchmark_without_date__c': 0,
  'Partners__c': null,
  'Tenants__c': null,
  'Demographic__c': null,
  'tenant_population__c': null,
  'Number_of_tenants__c': null,
  'Challenges__c': null,
  'Geographical_scope__c': null,
  'Project_Locations__c': null,
  'Reference_contact_1_Name__c': null,
  'Reference_contact_1_Surname__c': null,
  'Reference_contact_1_Phone__c': null,
  'Reference_contact_1_Email__c': null,
  'Type_of_housing__c': null,
  'Other_type_of_housing__c': null,
  'Secondary_ContactTXT__c': null,
  'How_will_project_be_transformational__c': null,
  'Initiation_phase_end_date__c': null,
  'Execution_phase_end_date__c': null,
  'Detailed_description__c': null,
  'Value_of_your_grant_request__c': null,
  'Are_you_a_Canadian_community_housing_pro__c': null,
  'The_purpose_mentioned__c': null,
  'Other_grant_requests_related_to_project__c': null,
  'Reference_contact_1_Organization__c': null,
  'Secondary_Contact_Name__c': null,
  'Secondary_Contact_SurName__c': null,
  'Secondary_Contact_EmailAddress__c': null,
  'Secondary_Contact_Phone__c': null,
  'Secondary_Contact_JobTitle__c': null,
  'Accept_to_share_your_project__c': null,
  'The_project_and_transformational_impact__c': null,
  'Tech_detail_textarea__c': false,
  'First_Review_count__c': 0,
  'Geographical_Location__c': null,
  'Other_Demographic__c': null,
  'Is_valide_phase_dates__c': false,
  'Number_of_access_stage__c': 0,
  'Number_of_build_stage__c': 0,
  'Number_of_participation_stage__c': 0,
  'Number_of_filled_objectives__c': 0,
  'Execution_phase_start_date__c': null,
  'Closure_phase_start_date__c': null,
  'The_total_value_does_exceed_5000__c': null,
  'Provider_the_project_intended_to_benefit__c': null,
  'How_Center_s_Grant_is_essential__c': null,
  'Reference_contact_1_Relationship__c': null,
  'Latest_Financial_Statement__c': null,
  'Reference_contact_2_Email__c': null,
  'Reference_contact_2_Organization__c': null,
  'Reference_contact_2_Phone__c': null,
  'Reference_contact_2_Relationship__c': null,
  'Reference_contact_2_Surname__c': null,
  'Reference_contact_2_Name__c': null,
  'Units__c': null,
  'Buildings__c': null,
  'Tenants_number__c': null,
  'Organizations_number__c': null,
  'Staff__c': null,
  'Owner_Language__c': 'en_US',
  'Depth_review_count__c': 0,
  'Program_manager_reviewer__c': null,
  'IsLocked__c': false,
  'GranteeFirstName__c': null,
  'GranteeLastName__c': 'ExternalUser1595852970992',
  'GranteeEmail__c': 'apicommunity+2@magnaro.com',
  'FirstSignerEmail__c': null,
  'First_Signer_First_Name__c': null,
  'First_Signer_Last_Name__c': null,
  'Second_Signer_Email__c': null,
  'Second_Signer_First_Name__c': null,
  'Second_Signer_Last_Name__c': null,
  'Primary_Contact_of_The_Account__c': null,
  'terms_and_conditions_1__c': false,
  'Level_risk__c': 'Low',
  'terms_and_condition_2__c': false,
  'terms_and_condition_3__c': false,
  'terms_and_condition_4__c': false,
  'terms_and_condition_5__c': false,
  'Signer_1_SurName__c': null,
  'Signer_1_Name__c': null,
  'Signer_1_EmailAddress__c': null,
  'Signer_1_Phone__c': null,
  'Signer_1_JobTitle__c': null,
  'Signer_2_SurName__c': null,
  'Signer_2_Name__c': null,
  'Signer_2_JobTitle__c': null,
  'Signer_2_Phone__c': null,
  'Signer_2_EmailAdrdess__c': null,
  'Accounting_First_Name__c': null,
  'Accounting_Last_Name__c': null,
  'Accounting_Phone__c': null,
  'Accounting_Email__c': null,
  'Accounting_Job_Title__c': null,
  'Communication_Summary__c': null,
  'Accounting_Contact__c': null,
  'First_Signer_acc__c': null,
  'Second_Signer__c': null,
  'Campaign': null,
  'Account': null,
  'RecordType': {
    'attributes': {
      'type': 'RecordType',
      'url': '/services/data/v42.0/sobjects/RecordType/0123j000001LByhAAG'
    }, 'Name': 'Grant'
  },
  'Owner': {
    'attributes': { 'type': 'User', 'url': '/services/data/v42.0/sobjects/User/0054m000000KIPHAA4' },
    'Alias': '52970992'
  },
  'Objectives__r': null,
  'FGM_Base__Benchmarks__r': null,
  'OpportunityHistories': {
    'totalSize': 1,
    'done': true,
    'records': [{
      'attributes': {
        'type': 'OpportunityHistory',
        'url': '/services/data/v42.0/sobjects/OpportunityHistory/0084m000003DulbAAC'
      }, 'CreatedDate': '2020-08-19T15:07:40.000+0000', 'StageName': 'InProgress'
    }]
  }
}
*/

export const mapFields = {
  direction: 'in',
  Id: 'id',
  Account: {
    // only flat object
    fieldName: 'account',
    Name: 'name'
  },
  RecordTypeId: 'recordTypeId',
  'RecordType.Name': 'recordTypeName',
  Name: 'name',
  Description: 'description',
  StageName: 'stageName',

  ...readOnlyFieldMTF('PM_Name__c', 'pmName'),
  ...readOnlyFieldMTF(
    'External_Validation_Status__c',
    'externalValidationStatus'
  ),
  ...readOnlyFieldMTF('PM_Contact__c', 'pmContact'),
  ...readOnlyFieldMTF('PM_notes_to_reviewers__c', 'pmNotesToReviewers'),
  ...readOnlyFieldMTF('Grant_Awarded__c', 'grantAwarded'),
  CampaignId: 'campaignId',
  CreatedDate: 'createdDate',
  LastModifiedDate: 'lastModifiedDate',
  FGM_Base__Amount_Requested__c: 'amountRequested',
  FGM_Base__Recommended_Amount__c: 'recommendedAmount',
  FGM_Portal__Total_Project_Budget__c: {
    key: 'budgetTotal',
    in: (opp) => opp.FGM_Portal__Total_Project_Budget__c / 2
  },
  OpportunityTeamMembers: {
    nonField: true,
    key: 'teamMembers',
    in: (opp) => opp.OpportunityTeamMembers?.records || []
  },
  FGM_Base__Reviews__r: {
    nonField: true,
    key: 'reviews',
    in: (opp) => {
      return (
        opp.FGM_Base__Reviews__r &&
        opp.FGM_Base__Reviews__r.records.map((obj) => {
          return {
            id: obj.Id,
            apiName: obj.RecordType?.DeveloperName,
            recordTypeId: obj.RecordTypeId,
            status: obj.FGM_Base__Status__c
          }
        })
      )
    }
  },
  PID__c: 'pid',
  Mortgage_underwritten_by_DMAH__c: 'mortgageUnderwrittenByDMAH',
  Funding_Stream__c: 'fundingStream',
  Anticipated_Closing_Date__c: 'anticipatedClosingDate',
  Manual_share_with_DMAH__c: 'sharedWithDMAH',
  OpportunityHistories: {
    nonField: true,
    key: 'history',
    in: (opp) =>
      opp.OpportunityHistories?.records.map((obj) => ({
        stage: obj.StageName,
        date: obj.CreatedDate
      })) || []
  },
  Priority_areas__c: 'priorityAreas',
  Objectives__c: 'objectives',
  Partners__c: 'partners',
  Geographical_Location__c: 'geographicalScopeDetails',
  Municipality__c: 'municipality',
  RecordType: {
    // only flat object
    fieldName: 'recordType',
    Name: 'name'
  }
}

/**
 * Checks if an Opportunity is editable based on its stage.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {object} opp The Opportunity object to check.
 * @param {string} [key='stageName'] The key in the Opportunity object containing the stage information.
 * @returns {boolean} Returns true if the Opportunity is in the IN_PROGRESS or MORE_INFO_REQUIRED stage, otherwise false.
 */
export const opportunityEditable = (
  opp: Opportunity,
  key: string = 'stageName'
) =>
  opp &&
  (opp[key] === opportunitiesStages.IN_PROGRESS ||
    opp[key] === opportunitiesStages.MORE_INFO_REQUIERED)

export const opportunitiesStages = {
  CONTRACTED: 'Contracted',
  AWARDED: 'Awarded',
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  MORE_INFO_REQUIERED: 'More info required',
  FIRST_REVIEW: 'First Review'
}
export const OPPORTUNITY_EDITABLE_STAGES = [
  opportunitiesStages.IN_PROGRESS,
  opportunitiesStages.MORE_INFO_REQUIERED
]
export const MILESTONE_PLANNING = 'Planning / initiation stage'
export const MILESTONE_WHOLE_PROJECT = 'Whole Project Phase'
export const MILESTONE_CLOSURE = 'Closure stage'
export const MILESTONE_EXECUTION = 'Execution stage'
export const MILESTONE_FEASIBILITY = 'Feasibility'
export const MILESTONE_PREDEVELOPMENT = 'Pre-Development'

export const MILESTONE_TRANSLATIONS = {
  [MILESTONE_PLANNING]: t`Planning / initiation stage`,
  [MILESTONE_CLOSURE]: t`Closure stage`,
  [MILESTONE_EXECUTION]: t`Execution stage`,
  [MILESTONE_WHOLE_PROJECT]: t`Whole Project Phase`,
  [MILESTONE_FEASIBILITY]: t`Feasibility`,
  [MILESTONE_PREDEVELOPMENT]: t`Pre-Development`
}

export const MILESTONE_ORDER = [
  MILESTONE_FEASIBILITY,
  MILESTONE_PREDEVELOPMENT,
  MILESTONE_PLANNING,
  MILESTONE_EXECUTION,
  MILESTONE_CLOSURE,
  MILESTONE_WHOLE_PROJECT
]

/**
 * Checks if the given name contains the word 'FAKE' (case-insensitive).
 * @function
 * @category Salesforce - Opportunity__c, Account__c, and Contact__c
 * @param {string} name - The name to check.
 * @returns {boolean} - True if the name contains 'FAKE', false otherwise.
 */

interface OpportunityCreateValues {
  name?: string
  recordTypeName?: string
  accountId?: string
  recordTypeId?: string
  [key: string]: any
}

/**
 * Creates a new Opportunity record in Salesforce.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {Connection} conn The Salesforce connection object.
 * @param {OpportunityValues} values The values to set for the Opportunity.
 * @param {object} [extraInfo={}] - Additional information to include in the request (optional).
 * @returns {FlowResult}
 */
export const createOpportunityByFlow = (
  conn: Connection,
  values: OpportunityCreateValues,
  extraInfo: object = {}
) => {
  if (conn) {
    if (sfOauthConfig.isIcce) {
      return conn.requestPost(
        `/services/data/${SF_API_VERSION}/composite/tree/Opportunity`,
        {
          records: [
            {
              attributes: {
                type: 'Opportunity',
                referenceId: 'opportunityReference'
              },
              StageName: 'In Progress',
              CloseDate: moment.utc(),
              ...values
            }
          ]
        }
      )
    }
    return conn
      .requestPost(
        `/services/data/${SF_API_VERSION}/composite/tree/Opportunity`,
        {
          records: [
            {
              attributes: {
                type: 'Opportunity',
                referenceId: 'opportunityReference'
              },
              Name: values.name,
              StageName: 'In Progress',
              CloseDate: moment.utc(),
              CampaignId: campaingsIDs[values.recordTypeName as string],
              AccountId: values.accountId,
              RecordTypeId: values.recordTypeId,
              Are_you_a_Canadian_community_housing_pro__c: 'Yes',
              The_total_value_of_project_does_exceed__c: 'Yes',
              The_purpose_mentioned__c: 'Yes',
              Data_Collection_Permission__c: true,
              ...values
            }
          ]
        }
      )
      .then((result) => {
        return conn
          .requestPost(
            '/actions/custom/flow/App_Add_Benchmarks_To_Opportunity',
            {
              inputs: [
                {
                  opportunityId: result.results[0].id
                }
              ]
            }
          )
          .then((flowResult) => {
            return result
          })
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

const mapOppToSurveyConditional = {
  info: {
    projectName: 'Name',
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    accountName: {
      in: (opp) => opp.Account.Name
    }
  },
  milestones: {
    milestones: {
      in: (opp) => {
        if (!opp.FGM_Base__Benchmarks__r) {
          return []
        }
        return opp.FGM_Base__Benchmarks__r.records
          .map((milestone) => {
            return {
              startDate: milestone.FGM_Base__Due_Date__c,
              endDate: milestone.FGM_Base__Completion_Date__c,
              primaryActivities: milestone.Primary_activities__c || '',
              comments: milestone.FGM_Base__Description__c || '',
              stage: milestone.FGM_Base__Status__c,
              id: milestone.Id
            }
          })
          .sort(
            (a, b) =>
              MILESTONE_ORDER.indexOf(a.stage) -
              MILESTONE_ORDER.indexOf(b.stage)
          )
      },
      out: (res) => {
        const inner = res.map((item) => ({
          FGM_Base__Due_Date__c: item.startDate,
          FGM_Base__Completion_Date__c: item.endDate,
          Primary_activities__c: item.primaryActivities,
          FGM_Base__Description__c: item.comments,
          Id: item.id
        }))

        return {
          key: 'FGM_Base__Benchmarks__r',
          value: inner
        }
      }
    }
  },
  objectives: {
    in: (opp) => {
      if (!opp.Objectives__r) {
        return []
      }
      return opp.Objectives__r.records.map(
        (objective) => objective.Objective__c
      )
    }
  }
}

interface InnerType {
  FGM_Portal__Amount__c: number
  FGM_Portal__Note__c?: string
  Id?: string
  FGM_Portal__Category__c?: string
  FGM_Portal__Grantee_Budget__r?: {
    Name: string
  }
}

interface FiscalYear {
  category: string
  isAdminLine: boolean
  year: Date | moment.Moment
  description: string
  otherDonors: number
  totalCost: number
  unitCost: number
  id: string
}

interface FiscalYears {
  [year: number]: FiscalYear[]
  adminLine?: FiscalYear
}

const mapOppToFormStructureICCE = {
  info: {
    id: 'Id',
    name: 'Name',
    validationDate: 'isvalidated__c',
    grantForm: 'Grant_Form__c',
    stageName: 'StageName',
    locked: {
      in: (opp) => {
        let bool = false
        if (opp.ProcessInstances && opp.ProcessInstances.records) {
          bool = opp.ProcessInstances.records.some(
            (process) => process.Status === 'Pending'
          )
        }
        return bool
      }
    },
    teamMembers: {
      in: (opp) => opp.OpportunityTeamMembers
    },
    hasEditAccess: {
      in: (opp) => opp.UserRecordAccess?.HasEditAccess
    },
    hasReadAccess: {
      in: (opp) => opp.UserRecordAccess?.HasReadAccess
    },
    editable: {
      in: (opp) => opportunityEditable(opp, 'StageName')
    },
    externalValidationStatus: readOnlyField('External_Validation_Status__c'),
    account: 'AccountId',
    contractSigned: readOnlyField('Contract_Signed_Date__c'),
    accountName: {
      in: (opp) => opp.Account.Name
    },
    externalReviews: {
      in: (opp) => opp.FGM_Base__Reviews__r?.records.length
    },
    fundType: {
      in: (opp) => opp.RecordTypeId
    },
    budgetTotal: {
      // Budget rollup adds all revenues and expenses so we need to divide it by two
      in: (opp) => opp.FGM_Portal__Total_Project_Budget__c / 2
    },
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    recordTypeId: {
      in: (opp) => {
        return opp.RecordType.Id
      }
    },
    recordType: {
      in: (opp) => {
        return opp.RecordType.Name
      }
    },
    lastModifiedDate: {
      in: (opp) => opp.LastModifiedDate
    },
    lastModifiedByUserDate: {
      in: (opp) => opp.Last_Modified_By_User_Date__c
    },
    createdDate: {
      in: (opp) => opp.CreatedDate
    },
    pmName: readOnlyField('PM_Name__c'),
    assignedManager: readOnlyField('Assigned_Program_Manager__c'),
    pmContact: readOnlyField('PM_Contact__c'),
    pmNotesToReviewers: readOnlyField('PM_notes_to_reviewers__c'),
    submitDate: readOnlyField('Submit_Date__c')
  },
  account: {
    accountType: { in: (opp) => opp.Account.Account_Type__c },
    geographicAreaOfOperation: {
      in: (opp) => opp.Account.Geographic_area_of_Operation__c
    },
    totalYearBudget: { in: (opp) => opp.Account.Total_yearly_budget__c },
    missionStatement: { in: (opp) => opp.Account.Mission_Statement__c },
    organizationHistory: { in: (opp) => opp.Account.Organisation_History__c },
    indigenousAffiliation: {
      in: (opp) => opp.Account.Indigenous_Organization__c
    },
    focusDemographic: { in: (opp) => opp.Account.Focus_demographic__c },
    servicesProvided: { in: (opp) => opp.Account.Services_provided__c },
    numberOfTenants: {
      in: (opp) => opp.Account.Number_of_tenants_living_in_your_buildin__c
    },
    housingUnits: {
      in: (opp) => opp.Account.Housing_units_managed_by_Organisation__c
    },
    unitsSpecialNeeds: { in: (opp) => opp.Account.Units_special_needs__c },
    numberOfEmployees: { in: (opp) => opp.Account.NumberOfEmployees }
  },
  teamMembers: {
    in: (opp) => {
      return opp.OpportunityTeamMembers
        ? opp.OpportunityTeamMembers.records
        : []
    }
  },
  projectDetails: {
    projectName: 'Name',
    editable: {
      in: (opp) => opportunityEditable(opp, 'StageName')
    },
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    fundType: {
      in: (opp) => opp.RecordTypeId
    },
    communityDistinction: {
      in: (opp) =>
        opp.For_Aboriginal_people__c
          ? opp.For_Aboriginal_people__c.split(';')
          : [],
      out: (res) => ({ key: 'For_Aboriginal_people__c', value: res.join(';') })
    },
    executiveSummary: 'GAP_Executive_Summary__c',
    objectives: 'GAP_Project_Objectives__c',
    description: 'GAP_Project_Description__c',
    deliverables: 'GAP_Evaluation_Plan__c',
    amountRequested: 'npsp__Requested_Amount__c'
  },
  workplan: {
    in: (opp) => {
      if (!opp.Work_Plan_Lines__r) {
        return []
      }
      return opp.Work_Plan_Lines__r.records.map((line) => {
        return {
          description: line.Description__c,
          budgetLineRef: line.Budget_Line__c,
          endDate: line.End_Date__c,
          startDate: line.Start_Date__c,
          id: line.Id,
          staff: line.Staff_Involved__c
        }
      })
    }
  },
  budget: {
    in: (opp) => {
      const fiscalYears: FiscalYears = {}
      if (!opp.Budget_Lines__r) {
        return {}
      }
      opp.Budget_Lines__r.records.forEach((line) => {
        const year = moment.utc(line.Fiscal_Year__c).year()
        const toRet = {
          category: line.Category__c,
          isAdminLine: line.Is_Admin_Line__c,
          year: line.Fiscal_Year__c,
          description: line.Description__c,
          otherDonors: line.Other_sources__c,
          totalCost: line.Total_Grant_Request__c,
          unitCost: line.Unit_Cost__c,
          id: line.Id
        }
        if (!toRet.isAdminLine) {
          if (!fiscalYears[year]) {
            fiscalYears[year] = [toRet]
          } else {
            fiscalYears[year].push(toRet)
          }
        } else {
          fiscalYears.adminLine = toRet
        }
      })
      return fiscalYears
    }
  },
  uploadFiles: {},
  submit: {
    eligible_1: 'terms_and_conditions_1__c',
    eligible_2: 'terms_and_condition_2__c',
    eligible_3: 'terms_and_condition_3__c',
    eligible_4: 'terms_and_condition_4__c',
    eligible_5: 'terms_and_condition_5__c',
    reference: 'Referred_by__c',
    specifics: 'Referred_by_specifics__c',
    elaborate: 'Referred_by_more_details__c'
  }
}

const mapOppToFormStructure = {
  info: {
    id: 'Id',
    name: 'Name',
    hardCoded: 'Hardcoded_form__c',
    validationDate: 'isvalidated__c',
    stageName: 'StageName',
    grantForm: 'Grant_Form__c',
    oldFormVersion: 'Form_Version__c',
    fundingStream: readOnlyField('Funding_Stream__c'),
    locked: {
      in: (opp) => {
        let bool = false
        if (opp.ProcessInstances && opp.ProcessInstances.records) {
          bool = opp.ProcessInstances.records.some(
            (process) => process.Status === 'Pending'
          )
        }
        return bool
      }
    },
    teamMembers: {
      in: (opp) => opp.OpportunityTeamMembers
    },
    hasEditAccess: {
      in: (opp) => opp.UserRecordAccess?.HasEditAccess
    },
    hasReadAccess: {
      in: (opp) => opp.UserRecordAccess?.HasReadAccess
    },
    editable: {
      in: (opp) => opportunityEditable(opp, 'StageName')
    },
    externalValidationStatus: readOnlyField('External_Validation_Status__c'),
    account: 'AccountId',
    contractSigned: readOnlyField('Contract_Signed_Date__c'),
    accountName: {
      in: (opp) => opp.Account.Name
    },
    externalReviews: {
      in: (opp) => opp.FGM_Base__Reviews__r?.records.length
    },
    fundType: {
      in: (opp) => opp.RecordTypeId
    },
    budgetTotal: {
      // Budget rollup adds all revenues and expenses so we need to divide it by two
      in: (opp) => opp.FGM_Portal__Total_Project_Budget__c / 2
    },
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    recordTypeId: {
      in: (opp) => {
        return opp.RecordType.Id
      }
    },
    recordType: {
      in: (opp) => {
        return opp.RecordType.Name
      }
    },
    lastModifiedDate: {
      in: (opp) => opp.LastModifiedDate
    },
    lastModifiedByUserDate: {
      in: (opp) => opp.Last_Modified_By_User_Date__c
    },
    createdDate: {
      in: (opp) => opp.CreatedDate
    },
    pmName: readOnlyField('PM_Name__c'),
    assignedManager: readOnlyField('Assigned_Program_Manager__c'),
    pmContact: readOnlyField('PM_Contact__c'),
    pmNotesToReviewers: readOnlyField('PM_notes_to_reviewers__c'),
    submitDate: readOnlyField('Submit_Date__c')
  },
  account: {
    accountType: { in: (opp) => opp.Account.Account_Type__c },
    geographicAreaOfOperation: {
      in: (opp) => opp.Account.Geographic_area_of_Operation__c
    },
    totalYearBudget: { in: (opp) => opp.Account.Total_yearly_budget__c },
    missionStatement: { in: (opp) => opp.Account.Mission_Statement__c },
    organizationHistory: { in: (opp) => opp.Account.Organisation_History__c },
    indigenousAffiliation: {
      in: (opp) => opp.Account.Indigenous_Organization__c
    },
    focusDemographic: { in: (opp) => opp.Account.Focus_demographic__c },
    servicesProvided: { in: (opp) => opp.Account.Services_provided__c },
    numberOfTenants: {
      in: (opp) => opp.Account.Number_of_tenants_living_in_your_buildin__c
    },
    housingUnits: {
      in: (opp) => opp.Account.Housing_units_managed_by_Organisation__c
    },
    unitsSpecialNeeds: { in: (opp) => opp.Account.Units_special_needs__c },
    numberOfEmployees: { in: (opp) => opp.Account.NumberOfEmployees }
  },
  teamMembers: {
    in: (opp) => {
      return opp.OpportunityTeamMembers
        ? opp.OpportunityTeamMembers.records
        : []
    }
  },
  recommendation: {
    recommendation: readOnlyField('PM_Recommendation__c'),
    recommendationText: readOnlyField('PM_Recommendation_TXT__c'),
    recommendedAmount: readOnlyField('FGM_Base__Recommended_Amount__c'),
    impactSummary: readOnlyField('Impact_summary__c'),
    partnersSummary: readOnlyField('Partners_summary__c')
  },
  projectDetails: {
    projectName: 'Name',
    otherDemographicSpecify: 'Other_Demographic__c',
    stageName: 'StageName',
    prequalification: 'Pre_Qualification__c',
    editable: {
      in: (opp) => opportunityEditable(opp, 'StageName')
    },
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    fundType: {
      in: (opp) => opp.RecordTypeId
    },
    detailedDescription: 'Detailed_description__c',
    description: 'Description',
    summary: {
      in: (opp) => opp.Summary__c
    },
    hasTargetDemographic: {
      in: (opp) => {
        if (opp.Target_demographic_application__c) {
          const value = opp.Target_demographic_application__c.split(';')
          return value.length > 0 && !value.includes('None') ? 'Yes' : 'No'
        } else {
          return null
        }
      }
    },
    hasSupportedDemographic: {
      in: (opp) => {
        if (opp.Supported_demographics__c) {
          const value = opp.Supported_demographics__c.split(';')
          return value.length > 0 && !value.includes('None') ? 'Yes' : 'No'
        } else {
          return null
        }
      }
    },
    otherTargetDemographic: 'Other_target_demographic__c',
    otherSupportedDemographic: 'Other_supported_demographic__c',
    supportedDemographic: {
      out: (res) => ({
        key: 'Supported_demographics__c',
        value: res.join(';')
      }),
      in: (opp) =>
        opp.Supported_demographics__c
          ? opp.Supported_demographics__c.split(';')
          : []
    },
    targetDemographicPM: {
      in: (opp) =>
        opp.Target_demographics__c ? opp.Target_demographics__c.split(';') : []
    },
    targetDemographic: {
      out: (res) => ({
        key: 'Target_demographic_application__c',
        value: res.join(';')
      }),
      in: (opp) =>
        opp.Target_demographic_application__c
          ? opp.Target_demographic_application__c.split(';')
          : []
    },
    unitsWomen: 'Units_women_and_children__c',
    unitsSingleParent: 'Units_single_parent__c',
    unitsRacializedPersons: 'Units_racialized_persons__c',
    unitsIndigenous: 'Units_Indigenous__c',
    unitsFleeingDomesticViolence: 'Units_fleeing_domestic_violence__c',
    partners: 'Partners__c',
    grantAwarded: readOnlyField('Grant_Awarded__c'),
    amountRecieved: readOnlyField('FGM_Base__Payments_Made__c'),
    valueOfProject: {
      in: (opp) => opp.FGM_Base__Project_Budget__c,
      out: (res) => ({
        key: 'FGM_Base__Project_Budget__c',
        value: res ? String(res) : ''
      })
    },
    amountRequested: {
      in: (opp) => opp.FGM_Base__Amount_Requested__c,
      out: (res) => ({
        key: 'FGM_Base__Amount_Requested__c',
        value: res ? String(res) : ''
      })
    },
    grantEssential: 'How_Center_s_Grant_is_essential__c',
    priorityAreas: {
      in: (opp) =>
        opp.Priority_areas__c ? opp.Priority_areas__c.split(';') : [],
      out: (res) => ({ key: 'Priority_areas__c', value: res.join(';') })
    },
    cmhcPriorityAreas: {
      in: (res) => {
        const priorities = {}
        const unrecognized = {}
        const ret: string[] = []
        if (res.FGM_Base__Reviews__r) {
          for (const review of res.FGM_Base__Reviews__r.records) {
            if (
              review.RecordType.Name === 'Depth Review STF - Sectoral' ||
              review.RecordType.Name === 'Depth Review STF - Local' ||
              review.RecordType.Name === 'Depth Review CBTIF'
            ) {
              if (review.CMHC_Priority_areas_touched__c) {
                for (const area of review.CMHC_Priority_areas_touched__c.split(
                  ';'
                )) {
                  priorities[area] = true
                }
              } else {
                console.warn(
                  res,
                  `review: ${review.id} ha no CMHC priority areas`
                )
              }
            } else {
              unrecognized[review.RecordType.Name] = true
            }
          }
        }
        for (const area in priorities) {
          ret.push(area)
        }
        return ret
      }
    },
    geographicalScopeType: {
      in: (opp) =>
        opp.Geographical_scope__c ? opp.Geographical_scope__c.split(';') : [],
      out: (res) => ({ key: 'Geographical_scope__c', value: res.join(';') })
    },
    geographicalScopeDetails: 'Geographical_Location__c',
    priorityAreasDescription: 'Priority_areas_description__c',
    challenges: 'Challenges__c',
    demographic: {
      in: (opp) => (opp.Demographic__c ? opp.Demographic__c.split(';') : []),
      out: (res) => ({ key: 'Demographic__c', value: res.join(';') })
    },
    housingProviders: {
      in: (opp) =>
        opp.Provider_the_project_intended_to_benefit__c
          ? opp.Provider_the_project_intended_to_benefit__c.split(';')
          : [],
      out: (res) => ({
        key: 'Provider_the_project_intended_to_benefit__c',
        value: res.join(';')
      })
    },
    sharingDescription: 'Accept_to_share_your_project__c',
    grants: {
      in: (opp) =>
        opp.Other_grant_requests_related_to_project__c
          ? opp.Other_grant_requests_related_to_project__c.split(';;;').map(
            (i) => {
              const grant = i.split(';')
              return { name: grant[0], value: grant[1], state: grant[2] }
            }
          )
          : [],
      out: (res) => ({
        key: 'Other_grant_requests_related_to_project__c',
        value: res.map((i) => `${i.name};${i.value};${i.state}`).join(';;;')
      })
    },

    tenantsAffected: {
      in: (opp) => {
        console.log(
          'tenantsAffected',
          opp.RecordType.Name === CBTI_RECORD_TYPE,
          opp.Number_of_tenants__c,
          opp.Tenants_number__c
        )
        if (opp.RecordType.Name === CBTI_RECORD_TYPE) {
          return opp.Number_of_tenants__c
        } else {
          return opp.Tenants_number__c
        }
      },
      out: (res, opp) => {
        console.log(
          'tenantsAffected',
          opp.info.fundTypeName === CBTI_RECORD_TYPE,
          res
        )
        let ret
        if (opp.info.fundTypeName === CBTI_RECORD_TYPE) {
          ret = { key: 'Number_of_tenants__c', value: res }
        } else {
          ret = { key: 'Tenants_number__c', value: res }
        }
        console.log('tenantsAffected ret', ret)
        return ret
      }
    },

    tenantsInvolvement: 'tenant_population__c',
    tenantsDescription: 'Tenants__c',
    impactOrganization: 'Organizations_number__c',
    impactSustainable: 'The_project_and_transformational_impact__c',
    impactBuildings: 'Buildings__c',
    impactStaff: 'Staff__c',
    impactUnits: 'Units__c'
  },
  contacts: {
    //    firstSignerSalutation: // ASK
    firstSignerFirstName: 'Signer_1_Name__c', // ASK 'Signer_1_Name__c' or First_Signer_First_Name__c
    firstSignerLastName: 'Signer_1_SurName__c', // ASK 'Signer_1_SurName__c' or  First_Signer_Last_Name__c
    firstSignerEmail: 'Signer_1_EmailAddress__c',
    firstSignerJobTitle: 'Signer_1_JobTitle__c',
    firstSignerPhone: 'Signer_1_Phone__c',

    // secondSignerSalutation: //ASK
    secondSignerFirstName: 'Signer_2_Name__c',
    secondSignerLastName: 'Signer_2_SurName__c',
    secondSignerEmail: 'Signer_2_EmailAdrdess__c',
    secondSignerJobTitle: 'Signer_2_JobTitle__c',
    secondSignerPhone: 'Signer_2_Phone__c',

    accountingSignerFirstName: 'Accounting_First_Name__c',
    accountingSignerLastName: 'Accounting_Last_Name__c',
    accountingSignerEmail: 'Accounting_Email__c',
    accountingSignerJobTitle: 'Accounting_Job_Title__c',
    accountingSignerPhone: 'Accounting_Phone__c',

    secondaryContactFirstName: 'Secondary_Contact_Name__c',
    secondaryContactLastName: 'Secondary_Contact_SurName__c',
    secondaryContactEmail: 'Secondary_Contact_EmailAddress__c',
    secondaryContactJobTitle: 'Secondary_Contact_JobTitle__c',
    secondaryContactPhone: 'Secondary_Contact_Phone__c',

    referenceContactFirstName: 'Reference_contact_1_Name__c',
    referenceContactLastName: 'Reference_contact_1_Surname__c',
    referenceContactEmail: 'Reference_contact_1_Email__c',
    referenceContactJobTitle: 'Reference_contact_1_Relationship__c',
    referenceContactPhone: 'Reference_contact_1_Phone__c',
    referenceContactOrganization: 'Reference_contact_1_Organization__c',

    referenceContact2FirstName: 'Reference_contact_2_Name__c',
    referenceContact2LastName: 'Reference_contact_2_Surname__c',
    referenceContact2Email: 'Reference_contact_2_Email__c',
    referenceContact2JobTitle: 'Reference_contact_2_Relationship__c',
    referenceContact2Phone: 'Reference_contact_2_Phone__c',
    referenceContact2Organization: 'Reference_contact_2_Organization__c'
  },
  objectives: {
    objectives: {
      in: (opp) => {
        if (!opp.Objectives__r) {
          return []
        }
        return opp.Objectives__r.records.map((objective) => ({
          outcomes: objective.Outcomes__c
            ? objective.Outcomes__c.split(';')
            : [],
          objective: objective.Objective__c,
          needs: objective.Identified_needs__c,
          id: objective.Id
        }))
      },
      out: (res, opp) => {
        let objectiveRecordType
        switch (opp.info.fundTypeName) {
          case CBTI_RECORD_TYPE:
            objectiveRecordType = CBTI_OBJECTIVE_RECORD_TYPE
            break
          case STF_LP_RECORD_TYPE:
            objectiveRecordType = STF_LP_OBJECTIVE_RECORD_TYPE
            break
          case STF_SIP_RECORD_TYPE:
            objectiveRecordType = STF_SIP_OBJECTIVE_RECORD_TYPE
            break
        }
        const inner = res.map((item) => ({
          Outcomes__c: item.outcomes.join(';'),
          Objective__c: item.objective,
          Identified_needs__c: item.needs,
          Id: item.id,
          RecordTypeId:
            opp.info.objectivesRecordTypes[objectiveRecordType].recordTypeId
        }))

        return {
          key: 'Objectives__r',
          value: inner
        }
      }
    }
  },
  milestones: {
    milestones: {
      in: (opp) => {
        if (!opp.FGM_Base__Benchmarks__r) {
          return []
        }
        return opp.FGM_Base__Benchmarks__r.records
          .map((milestone) => {
            return {
              startDate: milestone.FGM_Base__Due_Date__c,
              endDate: milestone.FGM_Base__Completion_Date__c,
              primaryActivities: milestone.Primary_activities__c || '',
              comments: milestone.FGM_Base__Description__c || '',
              stage: milestone.FGM_Base__Status__c,
              id: milestone.Id
            }
          })
          .sort(
            (a, b) =>
              MILESTONE_ORDER.indexOf(a.stage) -
              MILESTONE_ORDER.indexOf(b.stage)
          )
      },
      out: (res) => {
        const inner = res.map((item) => ({
          FGM_Base__Due_Date__c: item.startDate,
          FGM_Base__Completion_Date__c: item.endDate,
          Primary_activities__c: item.primaryActivities,
          FGM_Base__Description__c: item.comments,
          Id: item.id
        }))

        return {
          key: 'FGM_Base__Benchmarks__r',
          value: inner
        }
      }
    }
  },
  budget: {
    in: (opp) => {
      const ret = {
        revenues: {},
        expenses: {},
        invalid: [] as any[],
        budgetTotal: 0
      }
      if (!opp.FGM_Portal__Grantee_Budget_Line_Items__r) {
        return ret
      }
      let year
      for (const line of opp.FGM_Portal__Grantee_Budget_Line_Items__r.records) {
        if (
          !line.FGM_Portal__Category__r ||
          !line.FGM_Portal__Category__r.FGM_Portal__Parent_Category__r
        ) {
          continue
        }
        year = parseInt(line.FGM_Portal__Grantee_Budget__r.Name, 10)
        if (
          line.FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name ===
          'Revenue'
        ) {
          if (!ret.revenues[year]) {
            ret.revenues[year] = {}
          }
          const toSet = {
            value: line.FGM_Portal__Amount__c,
            id: line.Id,
            comment: line.FGM_Portal__Note__c || '',
            modifiedDate: line.LastModifiedDate
          }
          const alreadySet =
            ret.revenues[year][line.FGM_Portal__Category__r.Name]
          if (alreadySet) {
            if (
              moment(toSet.modifiedDate).isAfter(
                moment(alreadySet.modifiedDate)
              )
            ) {
              ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
              ret.invalid.push(alreadySet)
            } else {
              ret.invalid.push(toSet)
            }
          } else {
            ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
          }
        } else {
          if (!ret.expenses[year]) {
            ret.expenses[year] = {}
          }
          const toSet = {
            value: line.FGM_Portal__Amount__c,
            modifiedDate: line.LastModifiedDate,
            id: line.Id,
            comment: line.FGM_Portal__Note__c || ''
          }
          const alreadySet =
            ret.expenses[year][line.FGM_Portal__Category__r.Name]
          if (alreadySet) {
            if (
              moment(toSet.modifiedDate).isAfter(
                moment(alreadySet.modifiedDate)
              )
            ) {
              ret.expenses[year][line.FGM_Portal__Category__r.Name] = toSet
              ret.invalid.push(alreadySet)
            } else {
              ret.invalid.push(toSet)
            }
          } else {
            ret.expenses[year][line.FGM_Portal__Category__r.Name] = toSet
          }
        }
      }
      const calcBudgetTotal = () => {
        let sum = 0
        for (const yearKey in ret.revenues) {
          for (const section in ret.revenues[yearKey]) {
            sum += ret.revenues[yearKey][section].value
          }
        }
        for (const yearKey in ret.expenses) {
          for (const section in ret.expenses[yearKey]) {
            sum -= ret.expenses[yearKey][section].value
          }
        }
        return sum
      }
      ret.budgetTotal = calcBudgetTotal()
      return ret
    },
    out: (res) => {
      const inner: InnerType[] = []
      const mapping = globalValues.budgetCategories.en_CA
      for (const year in res.revenues) {
        for (const category in res.revenues[year]) {
          inner.push({
            FGM_Portal__Amount__c: res.revenues[year][category].value || 0,
            FGM_Portal__Note__c: res.revenues[year][category].comment,
            Id: res.revenues[year][category].id,
            FGM_Portal__Category__c: mapping.revenues[category],
            FGM_Portal__Grantee_Budget__r: {
              Name: year
            }
          })
        }
        for (const category in res.expenses[year]) {
          inner.push({
            FGM_Portal__Amount__c: res.expenses[year][category].value || 0,
            FGM_Portal__Note__c: res.expenses[year][category].comment,
            Id: res.expenses[year][category].id,
            FGM_Portal__Category__c: mapping.expenses[category],
            FGM_Portal__Grantee_Budget__r: {
              Name: year
            }
          })
        }
      }
      return { key: 'FGM_Portal__Grantee_Budget_Line_Items__r', value: inner }
    }
  },
  uploadFiles: {},
  submit: {
    eligible_1: 'terms_and_conditions_1__c',
    eligible_2: 'terms_and_condition_2__c',
    eligible_3: 'terms_and_condition_3__c',
    eligible_4: 'terms_and_condition_4__c',
    eligible_5: 'terms_and_condition_5__c',
    reference: 'Referred_by__c',
    specifics: 'Referred_by_specifics__c',
    elaborate: 'Referred_by_more_details__c'
  }
}

/**
 * Generates a pair of functions for converting boolean values to 'Yes' or 'No' strings and vice versa.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} key The key to operate on.
 * @returns {string | null}
 */
const yesNoRadioValues = (key: string) => {
  return {
    in: (opp) => {
      if (opp[key] === true) {
        return 'Yes'
      } else if (opp[key] === false) {
        return 'No'
      } else {
        return null
      }
    },
    out: (value) => {
      return { key, value: value === 'Yes' }
    }
  }
}

interface ToPassObject {
  Id?: string
  Order__c?: number
  Name__c?: string
  Sources_and_providers__c?: string
  Vulnerable_Groups__c?: string
  Province__c?: string
  Zip__c?: string
  Street__c?: string
  City__c?: string
  Street_Number__c?: string
  Units_Eligible__c?: number
  Units_Total__c?: number
  Households_with_rental__c?: number
  Expiry_Date__c?: Date | moment.Moment
  FCHI_accommodations_offered__c?: boolean
  Gross_Monthly_Household_Income__c?: number
  Receiving_subsidy__c?: number
  Household_Composition_Dependents__c?: number
  Household_Composition_Adults__c?: number
  Building__c?: string
  Rent__c?: number
  Subsidy_End_Date__c?: Date | moment.Moment
  Unit_Type__c?: string
}

const mapOppToFormStructureFCHI = {
  info: {
    id: 'Id',
    name: {
      in: (opp) => opp.Name
    },
    teamMembers: {
      in: (opp) => opp.OpportunityTeamMembers
    },
    validationDate: 'isvalidated__c',
    lastModifiedDate: {
      in: (opp) => opp.LastModifiedDate
    },
    createdDate: {
      in: (opp) => opp.CreatedDate
    },
    stageName: 'StageName',
    editable: {
      in: (opp) => opportunityEditable(opp, 'StageName')
    },
    externalValidationStatus: readOnlyField('External_Validation_Status__c'),
    account: 'AccountId',
    accountName: {
      in: (opp) => opp.Account.Name
    },
    accountType: {
      in: (opp) => opp.Account.Account_Type__c
    },
    fundType: {
      in: (opp) => opp.RecordTypeId
    },
    fundTypeName: {
      in: (opp) => opp.RecordType.Name
    },
    pmName: readOnlyField('PM_Name__c'),
    assignedManager: readOnlyField('Assigned_Program_Manager__c'),
    pmContact: readOnlyField('PM_Contact__c'),
    pmNotesToReviewers: readOnlyField('PM_notes_to_reviewers__c')
  },
  contacts: {
    primaryContactFirstName: 'Primary_Contact_Name__c',
    primaryContactLastName: 'Primary_Contact_SurName__c',
    primaryContactEmail: 'Primary_Contact_EmailAddress__c',
    primaryContactJobTitle: 'Primary_Contact_JobTitle__c',
    primaryContactPhone: 'Primary_Contact_Phone__c',
    //    firstSignerSalutation: // ASK
    firstSignerFirstName: 'Signer_1_Name__c', // ASK 'Signer_1_Name__c' or First_Signer_First_Name__c
    firstSignerLastName: 'Signer_1_SurName__c', // ASK 'Signer_1_SurName__c' or  First_Signer_Last_Name__c
    firstSignerEmail: 'Signer_1_EmailAddress__c',
    firstSignerJobTitle: 'Signer_1_JobTitle__c',
    firstSignerPhone: 'Signer_1_Phone__c',

    // secondSignerSalutation: //ASK
    secondSignerFirstName: 'Signer_2_Name__c',
    secondSignerLastName: 'Signer_2_SurName__c',
    secondSignerEmail: 'Signer_2_EmailAdrdess__c',
    secondSignerJobTitle: 'Signer_2_JobTitle__c',
    secondSignerPhone: 'Signer_2_Phone__c'
  },
  teamMembers: {
    in: (opp) => {
      return opp.OpportunityTeamMembers
        ? opp.OpportunityTeamMembers.records
        : []
    }
  },
  banking: {
    bankingAccount: 'Banking_Account__c',
    bankingInstitution: 'Banking_Institution__c',
    bankingTransit: 'Banking_Transit__c'
  },
  buildings: {
    rowsDeleted: {
      in: (opp) => [],
      out: (values) => {
        return { key: 'toDeleteRows', value: values }
      }
    },
    buildings: {
      in: (opp) => [],
      out: (values) => {
        const toPass: ToPassObject[] = []
        values.forEach((item, itemIndex) => {
          let passString = ''
          item.providers.forEach((item) => {
            if (!item.name && !item.date) {
              return
            }
            passString += item.name + ';;;' + item.date + '/n'
          })
          toPass.push({
            Id: item.id,
            Order__c: itemIndex,
            Name__c: item.name,
            Sources_and_providers__c: passString,
            Vulnerable_Groups__c: item.vulnerableGroups.join(';'),
            Province__c: item.province !== '' ? item.province : null,
            Zip__c: item.zipCode,
            Street__c: item.street,
            City__c: item.city,
            Street_Number__c: item.streetNumber,
            Units_Eligible__c: item.unitsEligible,
            Units_Total__c: item.unitsTotal,
            Households_with_rental__c: item.otherSubsidyUnits,
            Expiry_Date__c: item.expiryDate,
            FCHI_accommodations_offered__c: item.accommodationsOffered
          })
        })
        return {
          key: 'Buildings__r',
          value: {
            values: toPass
          }
        }
      }
    }
  },
  units: {
    eligibleUnits: 'FCHI_eligible_housing_units__c',
    rowsDeleted: {
      in: (opp) => [],
      out: (values) => {
        return { key: 'toDeleteUnits', value: values }
      }
    },
    field: {
      in: (opp) => {
        return []
      },
      out: (values) => {
        const toPass: ToPassObject[] = []
        values.forEach((item, itemIndex) => {
          toPass.push({
            Id: item.id,
            Order__c: itemIndex,
            Gross_Monthly_Household_Income__c: item.income,
            Receiving_subsidy__c: item.subsidy,
            Household_Composition_Dependents__c: item.dependents,
            Household_Composition_Adults__c: item.adults,
            Building__c: item.building ? item.building.id : null,
            Rent__c: item.rent,
            Subsidy_End_Date__c: item.subsidy ? item.subsidyEndDate : null,
            Unit_Type__c: item.type
          })
        })
        return {
          key: 'Managed_Units_Details__r',
          value: {
            values: toPass
          }
        }
      }
    }
  },
  proofsOfIncome: {},
  uploadFiles: {},
  submit: {
    eligible_1: 'terms_and_conditions_1__c',
    eligible_2: 'terms_and_condition_2__c',
    confirm_1: yesNoRadioValues('terms_and_condition_3__c'),
    confirm_2: yesNoRadioValues('terms_and_condition_4__c'),
    confirm_3: yesNoRadioValues('terms_and_condition_5__c'),

    confirm_4: yesNoRadioValues('terms_and_condition_6__c'),
    confirm_5: yesNoRadioValues('terms_and_condition_7__c'),
    confirm_6: yesNoRadioValues('terms_and_condition_8__c'),
    confirm_7: yesNoRadioValues('terms_and_condition_9__c'),
    boardConfirmation: 'board_confirmation__c',
    boardConfirmationDate: 'board_confirmation_date__c'
  }
}

interface OpportunityData {
  RecordType: {
    Name: string
  }
  [key: string]: any
}

interface OpportunityFormStructure {
  info: {
    id?: string
    name?: string | { in: (opp: any) => any }
    validationDate?: string
    stageName?: string
    projectName?: string
    locked?: {
      in: (opp: any) => boolean
    }
    teamMembers?: {
      in: (opp: any) => any
    }
    hasEditAccess?: {
      in: (opp: any) => any
    }
    fundTypeName: {
      in: (opp: any) => any
    }
    accountName: {
      in: (opp: any) => any
    }
    milestones?: {
      milestones: {
        in: (opp: any) => any
        out: (res: any) => {
          key: string
          value: any
        }
      }
    }
    submitDate?: {}
  }
  submit?: {}
}

/**
 * Converts opportunity data to a structured format suitable for a stepper form.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {OpportunityData} opp The opportunity data.
 * @param {boolean} [toSurveysConditionals=false] - Optional. Indicates whether to convert for surveys conditionals.
 * @returns {object} - The structured data for the stepper form.
 */
export const oppToStepper = (
  opp: OpportunityData,
  toSurveysConditionals: boolean = false
) => {
  let ret: {
    loaded?: boolean
    info?: {}
    contacts?: {}
    recommendation?: {}
    units?: {}
    buildings?: {}
    proofsOfIncome?: {
      list: never[]
    }
    uploadFiles?: {
      list: never[]
    }
    banking?: {
      list: never[]
    }
    submit?: {}
    account?: {}
    projectDetails?: {}
    objectives?: {}
    milestones?: {}
    budget?: {}
  } =
    opp.RecordType.Name === FCHI2_RECORD_TYPE
      ? {
          loaded: false,
          info: {},
          contacts: {},
          recommendation: {},
          units: {},
          buildings: {},
          proofsOfIncome: {
            list: []
          },
          uploadFiles: {
            list: []
          },
          banking: {
            list: []
          },
          submit: {}
        }
      : {
          loaded: false,
          info: {},
          account: {},
          recommendation: {},
          projectDetails: {},
          contacts: {},
          objectives: {},
          milestones: {},
          budget: {},
          uploadFiles: {
            list: []
          },
          submit: {}
        }
  if (toSurveysConditionals) {
    ret = {
      objectives: [],
      info: {},
      milestones: {}
    }
  }
  if (opp) {
    let mapOpportunityToFormSctructure: OpportunityFormStructure =
      mapOppToFormStructure
    if (sfOauthConfig.isIcce) {
      mapOpportunityToFormSctructure = mapOppToFormStructureICCE
    }
    if (opp.RecordType.Name === FCHI2_RECORD_TYPE) {
      mapOpportunityToFormSctructure = mapOppToFormStructureFCHI
    } else if (toSurveysConditionals) {
      mapOpportunityToFormSctructure = mapOppToSurveyConditional
    }
    for (const form in mapOpportunityToFormSctructure) {
      if (
        typeof mapOpportunityToFormSctructure[form] === 'object' &&
        mapOpportunityToFormSctructure[form] &&
        typeof mapOpportunityToFormSctructure[form].in === 'function'
      ) {
        ret[form] = mapOpportunityToFormSctructure[form].in(opp)
        console.log('sector', form, ret[form])
      } else {
        for (const field in mapOpportunityToFormSctructure[form]) {
          if (
            typeof mapOpportunityToFormSctructure[form][field] === 'object' &&
            mapOpportunityToFormSctructure[form][field]
          ) {
            if (
              typeof mapOpportunityToFormSctructure[form][field].in ===
              'function'
            ) {
              ret[form][field] =
                mapOpportunityToFormSctructure[form][field].in(opp)
            }
          } else if (
            typeof mapOpportunityToFormSctructure[form][field] === 'string'
          ) {
            if (opp[mapOpportunityToFormSctructure[form][field]] !== null) {
              ret[form][field] =
                opp[mapOpportunityToFormSctructure[form][field]]
            } else {
              ret[form][field] = null
            }
          }
        }
      }
    }
    ret.loaded = true
  }
  return ret
}

/**
 * Converts values from a stepper form to an Opportunity object based on specified sectors.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {object} values The values from the stepper form.
 * @param {boolean} [isFCHI=false] Indicates whether the form is for FCHI (Funding for Community Housing Initiatives) (optional).
 * @returns {Opportunity} - An object representing the converted Opportunity.
 */
export const stepperToOpp = (values: object, isFCHI: boolean = false) => {
  const formSectors = isFCHI
    ? [
        'info',
        'contacts',
        'units',
        'buildings',
        'uploadFiles',
        'submit',
        'banking'
      ]
    : [
        'info',
        'projectDetails',
        'contacts',
        'objectives',
        'milestones',
        'budget',
        'uploadFiles',
        'submit'
      ]
  let data
  const ret = {
    attributes: { type: 'Opportunity', referenceId: 'Opportunity_ref' }
  }
  const mapOpportunityToFormSctructure = isFCHI
    ? mapOppToFormStructureFCHI
    : mapOppToFormStructure
  for (const sector of formSectors) {
    if (typeof mapOpportunityToFormSctructure[sector].out === 'function') {
      data = mapOpportunityToFormSctructure[sector].out(values[sector], values)
      ret[data.key] = data.value
      console.log('sector function', values[sector], data)
    } else {
      for (const field in mapOpportunityToFormSctructure[sector]) {
        if (
          typeof mapOpportunityToFormSctructure[sector][field] === 'object' &&
          mapOpportunityToFormSctructure[sector][field]
        ) {
          if (
            typeof mapOpportunityToFormSctructure[sector][field].out ===
            'function'
          ) {
            data = mapOpportunityToFormSctructure[sector][field].out(
              values[sector][field],
              values
            )
            ret[data.key] = data.value
          } else {
            if (
              typeof mapOpportunityToFormSctructure[sector][field].out ===
              'undefined'
            ) {
              // no out function, this can be ok
            } else {
              console.log(
                'should be function',
                sector,
                field,
                mapOpportunityToFormSctructure[sector][field]
              )
            }
          }
        } else {
          ret[mapOpportunityToFormSctructure[sector][field]] =
            values[sector][field]
        }
      }
    }
  }
  return ret
}

export const pmRecommendationTranslations = {
  recommended: t`PM recommendation: recommended`,
  'Recommended with conditions': t`PM recommendation: recommended with conditions`,
  'recommended with concerns': t`PM recommendation: recommended with concerns`,
  concerns: t`PM recommendation: concerns`,
  'not recommended': t`PM recommendation: not recommended`
}

export const validationStatusMapping = {
  None: 'None required',
  AC: 'Allocation Committee',
  Board: 'Board',
  CMHC: 'CMHC',
  Done: 'Done'
}

export const detailedValidatedStages = ['AC', 'Board', 'CMHC', 'SC']

/**
 * Checks if a given stage name is included in the array of detailed validated stages.
 * @function
 * @category Salesforce - Opportunity__c
 * @param {string} StageName The name of the stage to check.
 * @returns {boolean} - `true` if the stage is included in the array of detailed validated stages, otherwise `false`.
 */
export const isDetailedValidatedStages = (StageName: string) => {
  return detailedValidatedStages.includes(StageName)
}

export const opportunitiesStagesInOrder = [
  'In Progress',
  'Inactive',
  'Submitted',
  'Hold',
  'First Review',
  'More info required',
  'In Depth Review',
  'External Review',
  'Executive Director Review',
  'Director Approval',
  'AC',
  'Board',
  'CMHC',
  'Validated',
  'Awarded',
  'Declined',
  'Contracted',
  'Operational',
  'Special scrutiny',
  'Deadline passed',
  'Finished',
  'Withdrawn',
  'Terminated'
]

export const closedStages = [
  'Awarded',
  'Contracted',
  'Operational',
  'Closed',
  'Declined',
  'Special scrutiny',
  'Deadline passed',
  'Finished',
  'Terminated',
  'Withdrawn'
]

export const afterValidatedStages = [
  'AC',
  'SC',
  'Board',
  'CMHC',
  'Validated',
  'Awarded',
  'Declined',
  'Contracted',
  'Operational',
  'Special scrutiny',
  'Finished',
  'Terminated'
]

export const afterValidatedStagesSuccess = [
  'AC',
  'SC',
  'Board',
  'CMHC',
  'Validated',
  'Awarded',
  'Contracted',
  'Operational',
  'Special scrutiny',
  'Finished',
  'Terminated'
]

import React, { useEffect, useState } from 'react'
import ResourcesCard from './ResourcesCard'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import './styles/OtherResourcesGrid.css'

export default function OtherResourcesGrid ({
  resources,
  onClickHandler,
  currentResourceId
}) {
  const [randomElements, setRandomElements] = useState([])

  useEffect(() => {
    const shuffled = [...resources].sort(() => 0.5 - Math.random())
    setRandomElements(shuffled.slice(0, 6))
  }, [currentResourceId])

  return (
    <div className='otherResourcesGrid'>
      <div className='otherResourcesGrid_title'>
        <Trans>RESOURCES_SIMILAR_RESOURCES_TITLE</Trans>
      </div>
      <div className='otherResourcesGrid_card'>
        {randomElements.map(resource => {
				  return (
  <ResourcesCard
    resource={resource}
    key={resource.Resource_Entry_ID}
    onClickHandler={resource => {
							  onClickHandler(resource)
    }}
  />
				  )
        })}
      </div>
    </div>
  )
}

OtherResourcesGrid.propTypes = {
  resources: PropTypes.array,
  onClickHandler: PropTypes.func,
  currentResourceId: PropTypes.string
}

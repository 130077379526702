import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { inuktitutFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormProjectAffiliationsPdf = ({
  typeProps = {},
  title,
  value = [],
  langFR,
  ...props
}) => {
  const { roles } = typeProps
  const currentLanguage = myI18n.language

  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      firstCol: {
        width: '35%'
      },
      secondCol: {
        width: '30%'
      },
      thirdCol: {
        width: '35%'
      }
    })
  }

  if (currentLanguage === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  const roleToTrans = {}
  roles.forEach((role) => {
    if (role?.apiValue && role?.label) {
      roleToTrans[role.apiValue] = role?.label?.portalTranslationId
        ? myI18n._(role.label.portalTranslationId)
        : role?.label?.[currentLanguage]
    }
  })

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, styles.firstCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact name`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.secondCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact e-mail`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.thirdCol]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact role`)}
            </Text>
          </View>
        </View>

        {value.map((contact, index) => (
          <View style={styles.tableRow} wrap={false} key={index}>
            <View style={[styles.tableCol, styles.firstCol]}>
              <Text style={styles.tableCell}>{contact.name}</Text>
            </View>
            <View style={[styles.tableCol, styles.secondCol]}>
              <Text style={styles.tableCell}>{contact.email}</Text>
            </View>
            <View style={[styles.tableCol, styles.thirdCol]}>
              <Text style={styles.tableCell}>{roleToTrans[contact.role]}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

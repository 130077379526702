import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  MenuItem,
  Slider,
  TextField
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { dateFormat } from 'app/appSettings'
import CustomDatePicker from 'app/views/common-components/CustomDatePicker'
import { Component } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'

class ActionPlanDialog extends Component {
  windowResizeListener
  state = {
    title: '',
    description: '',
    reason: '',
    column: '',
    failReason: '',
    expectedOutcome: '',
    actualOutcome: '',
    success: null,
    dueDate: Date.now(),
    fullScreen: false,
    updateFromProps: true
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!prevState.updateFromProps && prevState.updateFromProps !== undefined) { return {} }

    let { card, user, create } = nextProps

    if (create) {
      card = {}
    }

    return {
      ...card,
      user,
      updateFromProps: false
    }
  }

  closeDialog = () => {
    if (this.windowResizeListener) { window.removeEventListener('resize', this.windowResizeListener) }
    this.props.handleClose()
  }

  handleChange = event => {
    const target = event.target
    const id = target.value
    this.setState({ updateFromProps: false })
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  componentDidMount () {
    if (window.innerWidth < 768) {
      this.setState({ fullScreen: true })
    }
    if (window) {
      this.windowResizeListener = window.addEventListener('resize', event => {
        if (event.target.innerWidth < 768) {
          this.setState({ fullScreen: true })
        } else this.setState({ fullScreen: false })
      })
    }
  }

  componentWillUnmount () {
    if (this.windowResizeListener) { window.removeEventListener('resize', this.windowResizeListener) }
  }

  renderField = (title, icon, value, name, options) => {
    return (
      <div className='px-sm-24 pt-16'>
        <div className='flex flex-middle mb-8'>
          <Icon className='text-muted'>{icon}</Icon>
          <h6 className='m-0 ml-16 uppercase text-muted'>{title}</h6>
        </div>
        <div className='ml-40 mb-16 flex'>
          {options
            ? (
              <TextField
                className='text-muted'
                onChange={this.handleChange}
                name={name}
                value={value}
                variant='outlined'
                fullWidth
                select
              >
                {options.map((option, index) => (
                  <MenuItem value={option.value} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              )
            : (
              <TextField
                className='text-muted'
                onChange={this.handleChange}
                name={name}
                value={value}
                variant='outlined'
                fullWidth
                multiline
              />
              )}
        </div>
      </div>
    )
  }

  render () {
    let {
      open,
      create,
      handleSave,
      handleAddNewCard,
      card = {},
      theme
    } = this.props
    const {
      fullScreen,
      title,
      failReason,
      description,
      category,
      reason,
      dueDate,
      expectedOutcome,
      actualOutcome,
      success
    } = this.state
    if (!card && create) {
      card = {}
    }

    console.log('action card', card)
    return (
      <Dialog
        onClose={this.closeDialog}
        open={open}
        fullScreen={fullScreen}
        fullWidth
        scroll='body'
      >
        <div className='scrum-board'>
          <div
            className='flex flex-space-between flex-middle pl-16 pr-8 py-8'
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            <h4 className='m-0 text-white'>
              <Trans>Action</Trans>
            </h4>
            <IconButton onClick={this.closeDialog}>
              <Icon className='text-white'>clear</Icon>
            </IconButton>
          </div>

          <Scrollbar
            className='position-relative pt-16 mb-16'
            style={{ maxHeight: '45vh' }}
          >
            {/* {this.renderField(<Trans>reason</Trans>, 'info', reason, 'reason')} */}

            {/* <Icon className='text-muted'>assignment</Icon> */}
            {card.column !== 'On hold' && (
              <div className='px-sm-24 pt-16'>
                <div className='flex flex-middle mb-8'>
                  <Icon className='text-muted'>assignment</Icon>
                  <h6 className='m-0 ml-16 uppercase text-muted'>
                    <Trans>Title</Trans>
                  </h6>
                </div>
                <div className='ml-40 mb-16 flex'>
                  <TextField
                    style={{
                      fontSize: '1rem',
                      fontWeight: 500
                    }}
                    // className="flex-grow-1 px-8 ml-8 "
                    type='text'
                    name='title'
                    // disabled={failed}
                    variant='outlined'
                    fullWidth
                    onChange={this.handleChange}
                    disableUnderline
                    value={title}
                  />
                </div>
              </div>
            )}

            {card.column !== 'On hold' &&
              this.renderField(
                <Trans>description</Trans>,
                'description',
                description,
                'description'
              )}

            {card.column == 'On hold' &&
              this.renderField(
                <Trans>reason for hold</Trans>,
                '',
                failReason,
                'failReason'
              )}

            {this.renderField(
              <Trans>expected outcome</Trans>,
              '',
              expectedOutcome,
              'expectedOutcome'
            )}

            {!card.fromTemplate &&
              this.renderField(
                <Trans>Category</Trans>,
                '',
                category,
                'category',
                [
                  {
                    label: <Trans>Property Management</Trans>,
                    value: 'Property Management'
                  },
                  {
                    label: <Trans>Financial Managment</Trans>,
                    value: 'Financial Managment'
                  },
                  {
                    label: <Trans>Governance</Trans>,
                    value: 'Governance'
                  },
                  {
                    label: <Trans>Social and community relations</Trans>,
                    value: 'Social and community relations'
                  }
                ]
              )}

            {card.column !== 'On hold' && (
              <div className='px-sm-24 pt-16'>
                <div className='flex flex-middle mb-8'>
                  <Icon className='text-muted'>calendar_today</Icon>
                  <h6 className='m-0 ml-16 uppercase text-muted'>
                    <Trans>due date</Trans>
                  </h6>
                </div>
                <div className='ml-40 mb-16 flex'>
                  <CustomDatePicker
                    cancelLabel={<Trans>Cancel</Trans>}
                    okLabel={<Trans>Ok</Trans>}
                    format={dateFormat}
                    className='text-muted'
                    onChange={e => {
                      this.setState({ dueDate: e.toDate() })
                    }}
                    name='dueDate'
                    value={dueDate}
                    variant='outlined'
                    fullWidth
                  />
                </div>
              </div>
            )}

            {!create
              ? card.column === 'Done' && (
                <div>
                  {this.renderField(
                    <Trans>actual outcome</Trans>,
                    '',
                    actualOutcome,
                    'actualOutcome'
                  )}

                  <div
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                      paddingTop: 20,
                      paddingBottom: 20,
                      width: '100%'
                    }}
                  >
                    <h6 className='mb-10 ml-16 uppercase text-muted'>
                      success measure
                    </h6>

                    <Slider
                      color='primary'
                      onChange={(event, newValue) => {
                        this.setState({ success: newValue })
                      }}
                      name='success'
                      value={success}
                      step={1}
                      min={1}
                      max={5}
                      marks={[
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' }
                      ]}
                      valueLabelDisplay='auto'
                    />
                  </div>
                </div>
              )
              : null}
          </Scrollbar>

          <div className='px-sm-24 mb-12 flex flex-end'>
            <Button className='mr-12' onClick={this.closeDialog}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              onClick={() => {
                create
                  ? handleAddNewCard(this.state)
                  : handleSave(this.state, card.columnIndex, card.cardIndex)
                this.props.handleClose()
              }}
              disabled={!this.state.title.length > 0}
              variant='contained'
              color='primary'
            >
              {create ? <Trans>Save</Trans> : <Trans>Update</Trans>}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(
  withStyles({}, { withTheme: true })(ActionPlanDialog)
)

import { Trans } from '@lingui/macro'
import {
  ButtonBase,
  Card,
  Collapse,
  Grid,
  Icon,
  Slide
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Component } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import ActionPlanBoardCard from './ActionPlanBoardCard'

class ActionPlanColumn extends Component {
  state = {
    shouldOpenTitleEditor: false,
    shouldOpenAddCard: false,
    minimized: false,
    cardTitleText: '',
    columnTitleText: ''
  }

  componentDidMount () {
    if (this.props.column.title === 'On hold') {
      this.setState({ minimized: true })
    }
  }

  handleChange = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.props.handleAddNewCard(
        this.state.cardTitleText,
        this.props.columnIndex
      )
      this.openTitleEditor(false)
      this.openAddCard(false)
    } else this.setState({ [event.target.name]: event.target.value })
  }

  openTitleEditor = value => {
    this.setState({ shouldOpenTitleEditor: value })
  }

  openAddCard = value => {
    this.setState({ shouldOpenAddCard: value })
  }

  titleToIcon = {
    'In Progress': 'hourglass_empty',
    Done: 'check',
    'On hold': 'close'
  }

  render () {
    const { minimized } = this.state
    const {
      cardActions,
      columns,
      column,
      deleting,
      columnIndex,
      minimizedFull,
      handleColumnMinimize,
      theme
    } = this.props

    const iconString = this.titleToIcon[column.title]
    return (
      <Card
        style={{
          maxWidth: minimized && !minimizedFull ? '55px' : '100%',
          flexGrow: 1,
          flexBasis: '20%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: columnIndex === 0 ? '1px solid' : null,
          borderRight: '1px solid',
          borderBottom: '1px solid',
          borderTop: '1px solid',
          borderRadius: 3,
          borderColor: '#e2e2e2'
        }}
      >
        <ButtonBase
          onClick={() => {
            handleColumnMinimize(!minimized, columnIndex)
            this.setState({ minimized: !minimized })
          }}
          style={{ padding: 0 }}
        >
          <Grid
            container
            justify='center'
            alignItems='center'
            style={{ height: '70px' }}
          >
            {!minimized
              ? (
                <h4
                  style={{
                    textAlign: 'center',
                    marginBottom: 15,
                    marginTop: 15
                  }}
                >
                  <Trans id={column.title} />
                </h4>
                )
              : (
                <Icon>expand_more</Icon>
                )}
            {Boolean(!minimized && iconString) && (
              <Icon style={{ marginLeft: 10 }}>{iconString}</Icon>
            )}
          </Grid>
        </ButtonBase>

        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div
            variant='contained'
            style={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              height: '20px',
              width: '100%'
            }}
          />
          <Droppable
            droppableId={column.title}
            direction='vertical'
            type='card'
            isDropDisabled={this.state.minimized}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={{ flexGrow: 1 }}>
                <Collapse in={!this.state.minimized}>
                  {!minimized &&
                    column.actions.map((card, index) => (
                      <Draggable
                        key={card.id}
                        draggableId={'draggableId' + card.id}
                        index={index}
                        type='card'
                      >
                        {(provided, snapshot) => (
                          <Card
                            onClick={() => {
                              console.log('e')
                              cardActions.openDialog({
                                ...card,
                                columnIndex: column.index,
                                cardIndex: index
                              })
                            }}
                            className='list-column__card borderborder-radius-4 bg-light-gray'
                            elevation={snapshot.isDragging ? 10 : 3}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              margin: 4
                            }}
                          >
                            <ActionPlanBoardCard
                              deleting={deleting}
                              columns={columns}
                              card={card}
                              index={index}
                              cardActions={cardActions}
                              column={column}
                            />
                          </Card>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                </Collapse>

                {minimized && (
                  <Slide in={minimized} direction='up'>
                    <div>
                      {iconString && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 10
                          }}
                        >
                          <Icon>{iconString}</Icon>
                        </div>
                      )}
                      <div style={{ paddingTop: minimizedFull ? 65 : 50 }}>
                        <h4
                          className='nowrap'
                          style={{
                            textAlign: 'center',
                            transform: 'rotate(90deg)',
                            marginBottom: 0
                          }}
                        >
                          <Trans id={column.title} /> {column.actions.length}
                        </h4>
                      </div>
                    </div>
                  </Slide>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </Card>
    )
  }
}

export default withStyles({}, { withTheme: true })(ActionPlanColumn)

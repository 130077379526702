import { portalLanguagesData } from "app/appSettings";
import { getTitleTranslation } from "app/views/common/TranslationsCommon";
import moment from "moment";
import { isValidJSON } from "utils";
import { transformOldSfProps } from "../../../utils/transformOldSfProps";
import SFAuthService, { NO_USER } from "../SFAuthService";
import { textFields } from "./sfSurvey";
import { getUsersByFlow } from "./sfUser";

export const FORM_VERSION_ERROR = "VERSION_ALREADY_EXISTS";
export const FORM_PREVIEW_CONFIG = "FORM_PREVIEW_CONFIG";

export const configurationTypes = {
  Contract: {
    name: "CONTRACT",
  },
  "Application Type": {
    name: "AVAILABLE_APPLICATIONS",
  },
};

/**
 * Saves the preview configuration to Salesforce.
 * @function
 * @category Salesforce - Configuration__c
 * @param {object} config The preview configuration to save.
 * @returns {JSForceResult}
 */
export const savePreviewConfig = (config) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Configuration__c")
    .find({ Name: FORM_PREVIEW_CONFIG })
    .then((result) => {
      if (result.length > 0) {
        const id = result[0].Id;
        return conn.sobject("Configuration__c").update({
          Id: id,
          Value__c: JSON.stringify(config),
        });
      } else {
        return conn.sobject("Configuration__c").create({
          Name: FORM_PREVIEW_CONFIG,
          Value__c: JSON.stringify(config),
        });
      }
    });
};

/**
 * Fetches a form page from Salesforce based on the provided id.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {string} id The id of the form page to fetch.
 * @returns {FormPage | null}
 */
export const getFormPage = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  if (!id) {
    return null;
  }
  return conn
    .sobject("Form_Page__c")
    .find({ Id: id })
    .then((result) => {
      const form = result[0];
      if (!form) {
        return null;
      }
      return conn
        .sobject("SurveyTranslation__c")
        .find({
          Id: form.Title_Translation__c,
        })
        .select("Id")
        .include("Survey_Texts__r")
        .select(textFields.join(", "))
        .end()
        .then((translations) => {
          const title =
            translations.length === 0
              ? Object.values(portalLanguagesData).reduce((acc, langObj) => {
                  const { editKey } = langObj;
                  acc[editKey] = {
                    id: null,
                    text: "",
                  };
                  return acc;
                }, {} as { portalTranslationId?: { id: string | null; text: string } })
              : Object.values(portalLanguagesData).reduce((acc, langObj) => {
                  const { editKey, surveyTextKey } = langObj;
                  const translation =
                    translations[0].Survey_Texts__r.records.find((rec) =>
                      rec.Language__c.includes(surveyTextKey)
                    );
                  acc[editKey] = {
                    id: translation?.Id || null,
                    text: translation?.Text__c || "",
                  };
                  return acc;
                }, {} as { portalTranslationId?: { id: string | null; text: string } });
          const portalTranslation =
            translations[0]?.Survey_Texts__r.records.find(
              (rec) => rec.Text_Is_Portal_Translation_Id__c
            );
          if (portalTranslation) {
            title.portalTranslationId = {
              id: portalTranslation?.Id || null,
              text: portalTranslation?.Text__c || "",
            };
          }
          let toPass;
          if (isValidJSON(form.Data__c)) {
            toPass = JSON.parse(form.Data__c);
          } else {
            const pako = require("pako");
            const gzip = Buffer.from(form.Data__c, "base64");
            toPass = pako.ungzip(gzip, { to: "string" });
            toPass = JSON.parse(toPass);
            toPass = transformOldSfProps(toPass);
          }
          return {
            ...toPass,
            title,
            translations,
            holdsReusableElements: form.Holds_Reusable_Elements__c,
            comments: form.Comments__c,
            origin: form.Origin__c,
            type: form.Type__c,
            status: form.Status__c,
            id: form.Id,
          };
        });
    });
};

/**
 * Updates a form page in Salesforce with the provided data.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {object} toUpdate The data to update the form page with.
 * @returns {JSForceResult}
 */
export const updateFormPage = (toUpdate: object) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Form_Page__c").update({
    ...toUpdate,
  });
};

interface Data {
  sections?: any[];
  injectableComponents?: any[];
  autosave?: boolean;
  version?: number;
  type?: string;
  status?: string;
  enableMultiuser?: boolean;
  pdfProps?: any;
  style?: any;
  formType?: string;
  showSectionTitlesAsHeaders?: boolean;
  showFormTitleInPdf?: boolean;
  renderElementsWithTitles?: boolean;
  showPrintButton?: boolean;
  objectsConnected?: any[];
  displaySaveFailedDialog?: boolean;
  displayUnsavedWarning?: boolean;
  displayesSavedInMeantimeWarning?: boolean;
  restrictAccessForRoles?: boolean;
  restrictAccessType?: string;
  translatedFor?: string;
  overrideFormLanguage?: boolean;
  title?: Record<string, { id: string | null; text: string }>;
  translations?: any[];
  id?: string | undefined;
  comments?: string | undefined;
  origin?: string | undefined;
  supportedFormType: string[];
}

/**
 * Compresses form data into a compressed string representation.
 * @function
 * @category - Salesforce - Form_Page__c
 * @param {Data} data - The form data to compress.
 * @returns {string} The compressed form data encoded as a base64 string.
 */
export const compressFormData = (data: Data) => {
  const toSave = {
    sections: data.sections,
    injectableComponents: data.injectableComponents,
    autosave: data.autosave,
    version: data.version,
    enableMultiuser: data.enableMultiuser,
    pdfProps: data.pdfProps,
    style: data.style,
    formType: data.formType,
    showSectionTitlesAsHeaders: data.showSectionTitlesAsHeaders,
    showFormTitleInPdf: data.showFormTitleInPdf,
    renderElementsWithTitles: data.renderElementsWithTitles,
    showPrintButton: data.showPrintButton,
    objectsConnected: data.objectsConnected,
    displaySaveFailedDialog: data.displaySaveFailedDialog,
    displayUnsavedWarning: data.displayUnsavedWarning,
    displayesSavedInMeantimeWarning: data.displayesSavedInMeantimeWarning,
    restrictAccessForRoles: data.restrictAccessForRoles,
    restrictAccessType: data.restrictAccessType,
    translatedFor: data.translatedFor,
    overrideFormLanguage: data.overrideFormLanguage,
    supportedFormType: data.supportedFormType,
  };
  const json = JSON.stringify(toSave);
  const pako = require("pako");
  const gzip = pako.gzip(json);
  var b64 = Buffer.from(gzip).toString("base64");
  return b64;
};

/**
 * Uncompresses compressed form data into its original JSON representation.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {string} data The compressed form data encoded as a base64 string.
 * @returns {Data} The uncompressed form data as a JavaScript object.
 */
export const uncompressFormData = (data: string) => {
  const pako = require("pako");
  const gzip = Buffer.from(data, "base64");
  const uncompressed = pako.ungzip(gzip, { to: "string" });
  return JSON.parse(uncompressed);
};

interface UpdateObject {
  Id: string | undefined;
  Comments__c: string | undefined;
  Data__c: string;
  Name?: string;
  Status__c: string | undefined;
  Type__c: string | undefined;
}

/**
 * Saves a form page with the provided data in Salesforce.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {Data} data The data of the form page to be saved.
 * @returns {JSForceResult}
 */
export const saveFormPage = (data: Data) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  console.log("data: ", data);
  const updatePromise = () => {
    const saveTitle: object[] = [];
    const translation = data.translations ? data.translations[0] : null;
    if (!translation) {
      return conn
        .sobject("SurveyTranslation__c")
        .create({})
        .then((translation) => {
          const updates: any[] = [];
          Object.values(portalLanguagesData).forEach((langObj) => {
            const { editKey, surveyTextKey } = langObj;
            saveTitle.push(
              conn.sobject("Survey_Text__c").create({
                Survey_Translation__c: translation.id,
                Text__c: data.title?.[editKey]?.text,
                Language__c: surveyTextKey,
              })
            );
          });
          updates.push(
            conn.sobject("Form_Page__c").update({
              Id: data.id,
              Title_Translation__c: translation.id,
              Status__c: data.status,
              Type__c: data.type,
              Comments__c: data.comments,
              Data__c: compressFormData(data),
            })
          );
          return Promise.all(updates);
        });
    } else {
      Object.values(portalLanguagesData).forEach((langObj) => {
        const { editKey, surveyTextKey } = langObj;
        if (data.title?.[editKey]?.id) {
          const update = conn.sobject("Survey_Text__c").update({
            Id: data.title?.[editKey]?.id,
            Text__c: data.title?.[editKey]?.text,
          });
          saveTitle.push(update);
        } else {
          const create = conn.sobject("Survey_Text__c").create({
            Survey_Translation__c: translation.Id,
            Text__c: data.title?.[editKey]?.text,
            Language__c: surveyTextKey,
          });
          saveTitle.push(create);
        }
      });
    }
    const updateObject: UpdateObject = {
      Id: data.id,
      Comments__c: data.comments,
      Data__c: compressFormData(data),
      Status__c: data.status,
      Type__c: data.type,
    };
    if (data.title?.en.text) {
      updateObject.Name = data.title?.en.text;
    }
    const saveForm = conn.sobject("Form_Page__c").update(updateObject);
    return Promise.all([saveForm, ...saveTitle]);
  };

  if (data.origin) {
    return checkIfFormVersionExists({
      origin: data.origin,
      id: data.id,
      version: data.version as number,
    }).then((result) => {
      return updatePromise();
    });
  } else {
    return updatePromise();
  }
};

/**
 * Checks the next available form version based on the origin, and current version.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {string} origin The origin of the form.
 * @param {string} [copyOf] The id of the form page that the new form is a copy of (optional).
 * @param {number} [version] The current version of the form (optional).
 * @returns {number} The next available form version.
 */
export const checkNextAvaliableFormVersion = ({
  origin,
  copyOf,
  version,
}: {
  origin: string;
  version?: number;
  copyOf?: string;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Form_Page__c")
    .find({
      $or: [
        { Origin__c: copyOf },
        { Copy_of__c: copyOf },
        { Origin__c: origin },
        { Copy_of__c: origin },
      ],
    })
    .then((forms) => {
      const versions = forms.map((form) => {
        let savedData;
        if (isValidJSON(form.Data__c)) {
          savedData = JSON.parse(form.Data__c);
        } else {
          const pako = require("pako");
          const gzip = Buffer.from(form.Data__c, "base64");
          savedData = pako.ungzip(gzip, { to: "string" });
          savedData = JSON.parse(savedData);
        }
        return savedData.version ? +savedData.version : 0;
      });
      const newVersion = Math.max(...versions, version || 0) + 1;
      return newVersion;
    });
};

/**
 * Checks if a form version already exists based on the origin, form id, and version number.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {string} origin The origin of the form.
 * @param {string} [id] The id of the form page. Optional, used to exclude the current form from the check.
 * @param {number} version The version number of the form.
 * @returns {Promise<void>} A Promise that resolves if the form version does not exist, and rejects with an error message if it does.
 */
export const checkIfFormVersionExists = ({
  origin,
  id,
  version,
}: {
  origin: string;
  id?: string;
  version: number;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Form_Page__c")
    .find({
      $or: [{ Origin__c: origin }, { Copy_of__c: origin }],
    })
    .then((forms) => {
      const versionAlready = forms.some((form) => {
        let savedData;
        if (isValidJSON(form.Data__c)) {
          savedData = JSON.parse(form.Data__c);
        } else {
          const pako = require("pako");
          const gzip = Buffer.from(form.Data__c, "base64");
          savedData = pako.ungzip(gzip, { to: "string" });
          savedData = JSON.parse(savedData);
        }
        if (id) {
          return +savedData.version === +version && form.Id !== id;
        } else {
          return +savedData.version === +version;
        }
      });
      if (versionAlready) {
        return Promise.reject(FORM_VERSION_ERROR);
      } else {
        return Promise.resolve();
      }
    });
};

/**
 * Retrieves a reusable form page from Salesforce (its id and configuration).
 * @function
 * @category Salesforce - Form_Page__c
 * @returns {Promise<{ id: string, config: any }>}
 */
export const getReusableFormPage = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Form_Page__c")
    .find({
      Holds_Reusable_Elements__c: true,
    })
    .select("Id, Data__c")
    .then((result) => {
      const form = result[0];
      let toPass;
      if (isValidJSON(form.Data__c)) {
        toPass = JSON.parse(form.Data__c);
      } else {
        const pako = require("pako");
        const gzip = Buffer.from(form.Data__c, "base64");
        toPass = pako.ungzip(gzip, { to: "string" });
        toPass = JSON.parse(toPass);
      }
      return {
        id: form.Id,
        config: toPass || {},
      };
    });
};

/**
 * Retrieves form pages from Salesforce.
 * @function
 * @category Salesforce - Form_Page__c
 * @returns {FormPage[]}
 */
export const getFormPages = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Form_Page__c")
    .find({
      Holds_Reusable_Elements__c: false,
    })
    .select(
      "Id, Title_Translation__c, Origin__c, Data__c, Comments__c, LastModifiedDate, LastModifiedById, Type__c, Status__c, Copy_of__c"
    )
    .then((result) => {
      const matchObj = {};
      const userMatchObj = {};
      const translationIds: string[] = [];
      const lastModifiedUsers: string[] = [];
      result.forEach((obj) => {
        translationIds.push(obj.Title_Translation__c);
        matchObj[obj.Title_Translation__c] = obj;
        if (!lastModifiedUsers.includes(obj.LastModifiedById)) {
          lastModifiedUsers.push(obj.LastModifiedById);
        }
      });
      const translationQuery = (translationIds: string[]) =>
        conn
          .sobject("SurveyTranslation__c")
          .find({
            Id: { $in: translationIds },
          })
          .select("Id")
          .include("Survey_Texts__r")
          .select(textFields.join(", "))
          .end()
          .run({ autoFetch: true, maxFetch: 10000 });
      return Promise.all([
        translationQuery(translationIds),
        getUsersByFlow(lastModifiedUsers),
      ]).then(([translations, users = []]) => {
        users.forEach((user) => {
          userMatchObj[user.Id] = user.Name;
        });

        const translationsMatchObject = {};
        const unhandledTranslationIds: string[] = [];

        translationIds.forEach((id) => {
          const translation = translations.find((trans) => trans.Id === id);
          if (translation) {
            translationsMatchObject[id] = translation;
          } else {
            unhandledTranslationIds.push(id);
          }
        });

        return (
          result
            // filter out forms with corrupted Data__c field
            .filter((form) => {
              try {
                if (isValidJSON(form.Data__c)) {
                  JSON.parse(form.Data__c);
                } else {
                  const pako = require("pako");
                  const gzip = Buffer.from(form.Data__c, "base64");
                  const unzipped = pako.ungzip(gzip, { to: "string" });
                  JSON.parse(unzipped);
                }
              } catch (e) {
                console.warn(
                  `The Data__c field of the form with id ${form.Id} is corrupted`
                );
                return false;
              }
              return true;
            })
            .map((form) => {
              const translation =
                translationsMatchObject[form.Title_Translation__c];
              let toPass;
              if (isValidJSON(form.Data__c)) {
                toPass = JSON.parse(form.Data__c);
              } else {
                const pako = require("pako");
                const gzip = Buffer.from(form.Data__c, "base64");
                toPass = pako.ungzip(gzip, { to: "string" });
                toPass = JSON.parse(toPass);
              }

              const titleTranslation = Object.keys(portalLanguagesData).reduce(
                (acc, lang) => {
                  acc.name[portalLanguagesData[lang].editKey] =
                    getTitleTranslation(lang, translation);
                  return acc;
                },
                { name: {} }
              );

              return {
                ...titleTranslation,
                lastModifiedBy: userMatchObj[form.LastModifiedById],
                lastModifiedDate: form.LastModifiedDate,
                comments: form.Comments__c,
                origin: form.Origin__c,
                copyOf: form.Copy_of__c,
                id: form.Id,
                config: toPass || {},
                status: form.Status__c,
                type: form.Type__c,
              };
            })
            .filter((item) => item)
        );
      });
    });
};

interface Title {
  [language: string]: string;
}

/**
 * Creates a new form page in Salesforce.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {Title} title The title of the form page in multiple languages.
 * @param {string} origin The origin of the form page.
 * @param {boolean} [isCopy] - Whether the form page is a copy of an existing form page (optional).
 * @param {object} [options.data={}] - Additional data for the form page (optional).
 * @returns {JSForceResult}
 */
export const createFormPage = ({
  title,
  origin,
  isCopy,
  data = {},
}: {
  title: Title;
  origin: string;
  isCopy?: boolean;
  data: object;
}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const json = JSON.stringify(data);
  const pako = require("pako");
  const gzip = pako.gzip(json);
  var b64 = Buffer.from(gzip).toString("base64");
  return conn
    .sobject("SurveyTranslation__c")
    .create({})
    .then((translation) => {
      const transText = Object.values(portalLanguagesData)
        .map((obj) => {
          const { editKey, surveyTextKey } = obj;
          if (title?.[editKey]) {
            return conn.sobject("Survey_Text__c").create({
              Survey_Translation__c: translation.id,
              Text__c: title?.[editKey],
              Language__c: surveyTextKey,
            });
          }
        })
        .filter((p) => p);
      return Promise.all([
        ...transText,
        conn.sobject("Form_Page__c").create({
          Title_Translation__c: translation.id,
          ...(isCopy ? { Copy_of__c: origin } : { Origin__c: origin }),
          Data__c: b64,
        }),
      ]);
    });
};

/**
 * Deletes a form page from Salesforce.
 * If the form page is associated with an origin, and deleting it leaves no other form pages with the same origin,
 * the origin field is also cleared for the remaining form page.
 * @function
 * @category Salesforce - Form_Page__c
 * @param {string} id The id of the form page to delete.
 * @param {string} [origin] The origin of the form page (optional). If provided, checks if the origin is associated with other form pages.
 * @returns {JSForceResult}
 */
export const deleteFormPage = (id: string, origin?: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Form_Page__c")
    .delete(id)
    .then((result) => {
      if (!origin) {
        return result;
      } else {
        return conn
          .sobject("Form_Page__c")
          .find({
            $or: [{ Origin__c: origin }, { Copy_of__c: origin }],
          })
          .then((forms) => {
            if (forms.length === 1) {
              return conn.sobject("Form_Page__c").update({
                Id: forms[0].Id,
                Origin__c: null,
                Copy_of__c: null,
              });
            } else {
              return result;
            }
          });
      }
    });
};

/**
 * Retrieves configurations from Salesforce.
 * @function
 * @category Salesforce - Configuration__c
 * @returns {Configuration[]}
 */
export const getConfigurations = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Configuration__c").find({});
};

/**
 * Retrieves a single Configuration from Salesforce based on the provided search properties.
 * @function
 * @category Salesforce - Configuration__c
 * @param {object} [searchProps={}] The search properties to filter the configuration. If it is not provided, it will default to an empty object and all Configuration objects will be fetched.
 * @returns {Configuration | Configuration[]}
 */
export const getConfiguration = (searchProps: object = {}) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Configuration__c")
    .find({ ...searchProps })
    .then((result) => result[0]);
};

/**
 * Retrieves application configurations from Salesforce.
 * @function
 * @category Salesforce - Configuration__c
 * @returns {Configuration[]}
 */
export const getApplicationConfigurations = () => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn
    .sobject("Configuration__c")
    .find({})
    .then((result) => {
      return result
        .filter((item) => item.Name === "AVAILABLE_APPLICATIONS")
        .map((item) => ({ ...JSON.parse(item.Value__c), id: item.Id }));
    });
};

/**
 * Deletes an application configuration from Salesforce.
 * @function
 * @category Salesforce - Configuration__c
 * @param {string} id The id of the application configuration to delete.
 * @returns {JSForceResult}
 */
export const deleteApplicationConfig = (id: string) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  return conn.sobject("Configuration__c").delete(id);
};

interface ConfigListItem {
  id?: string;
  expanded?: boolean;
  type?: string;
  name: string;
  subtitle: string;
  description?: string;
  notes?: string;
  form?: string | null;
  quiz?: string | null;
  printForm?: string | null;
  order?: number;
  startTime?: moment.Moment | null;
  endTime?: moment.Moment | null;
  reminderTime?: moment.Moment | null;
  disableInProvinces?: string[];
  avaliableInLanguages?: string[];
  inTesting?: boolean;
  obsolete?: boolean;
  moreInfoUrl?: string;
  logo?: string;
}

interface SaveApplicationConfigData {
  configList: ConfigListItem[];
}

/**
 * Saves application configurations to Salesforce.
 * @function
 * @category Salesforce - Configuration__c
 * @param {SaveApplicationConfigData} data The data containing the configurations to save.
 * @returns {Promise<Object[]>} A promise that resolves to an array of objects representing the saved configurations.
 */
export const saveApplicationConfigWithUpdateByFlow = (
  data: SaveApplicationConfigData
) => {
  const conn = SFAuthService.getConnection();
  if (!conn) {
    return Promise.reject(NO_USER);
  }
  const toUpdate: object[] = [];
  const toCreate: object[] = [];
  data.configList.forEach((dataToSave, index) => {
    const item = { ...dataToSave };
    delete item.expanded;
    item.order = index;
    if (item.startTime) {
      const date = moment.utc(item.startTime);
      const local = date.local(true);
      item.startTime = moment.utc(local);
    }
    if (item.endTime) {
      const date = moment.utc(item.endTime);
      const local = date.local(true);
      item.endTime = moment.utc(local);
    }
    if (item.reminderTime) {
      const date = moment.utc(item.reminderTime);
      const local = date.local(true);
      item.reminderTime = moment.utc(local);
    }
    const objName = item.type && configurationTypes[item.type].name;
    if (!item.id) {
      toCreate.push({
        Value__c: JSON.stringify(item),
        Name: objName,
      });
    } else {
      const sfId = item.id;
      delete item.id;
      toUpdate.push({
        Id: sfId,
        Value__c: JSON.stringify(item),
        Name: objName,
      });
    }
  });
  return Promise.all([
    conn.sobject("Configuration__c").create(toCreate),
    conn.requestPost("/actions/custom/flow/App_Update_Configurations", {
      inputs: [
        {
          toUpdate,
        },
      ],
    }),
  ]);
};

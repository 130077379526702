import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Slider,
  TextField,
  Typography
} from '@material-ui/core'
import {
  getSurveyTemplateDetails,
  surveyItemOptionsFields
} from 'app/services/sfAuth/sfData/sfSurvey'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Field, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import {
  QuestionWidget,
  fixedQuestionAnswers,
  questionTypes
} from './QuestionWidget'
import { questionsToInitial } from './SurveyTab'
import { NumericFormat } from '../page-layouts/FormElement'
import QuestionTitle from './QuestionTitle'
import { withStyles } from '@material-ui/styles'
import {
  SCORING_CATEGORIES_CONFIG,
  getScoreCardItemsParsed,
  saveScoreCards
} from 'app/services/sfAuth/sfData/sfScoreCard'
import { useSelector } from 'react-redux'
import { getLabelFromTranslationData } from '../common/TranslationsCommon'

const isSubquestion = dataObj => {
  return (
    dataObj.itemOptions.parentQuestionIndex ||
    dataObj.itemOptions.parentQuestionIndex === 0 ||
    dataObj.itemOptions.parentQuestionId
  )
}

const surveyTemplateToRenderData = (template, user) => {
  const toSet = []
  const section = { ...template.templateOptions }
  section.questions = []
  section.templateId = template.info.templateId
  section.header = template.info.surveyName
  if (template.titleDetails) {
    section.header = getLabelFromTranslationData({
      langVersion: user.language,
      data: template.titleDetails.titleValue
    })
    section.tooltip = getLabelFromTranslationData({
      langVersion: user.language,
      data: template.titleDetails.tooltipValue
    })
    section.tooltipValue = template.titleDetails.tooltipValue
    section.headerValue = template.titleDetails.titleValue
  }
  if (template.questionsDetails.length === 0) {
    toSet.push(section)
    return toSet
  }
  template.questionsDetails.sort((a, b) => {
    return a.order - b.order
  })
  const groupQuestions = {}
  template.questionsDetails.forEach((dataObj, index) => {
    if (!dataObj.itemOptions) {
      return
    }
    if (isSubquestion(dataObj)) {
      const toPush = {
        titleValue: dataObj.itemTranslation.titleValue,
        index: dataObj.itemOptions.index,
        id: dataObj.itemOptions.createdId
      }
      const parentId =
        dataObj.itemOptions.parentQuestionId ||
        dataObj.itemOptions.parentQuestionIndex
      if (groupQuestions[parentId]) {
        groupQuestions[parentId].push(toPush)
      } else {
        groupQuestions[parentId] = [toPush]
      }
    }
  })
  template.questionsDetails.forEach(question => {
    let isGroup = false
    const childQuestions = []
    const inTemplates = []
    const otherTemplatesIDs = []
    question.item.Survey_Joins_Template_and_Question__r.records.forEach(
      join => {
        const options = JSON.parse(join.Item_Options__c)
        if (join.Id !== question.joinId) {
          inTemplates.push(join.Survey_Template__c)
          if (options.mainTemplate) {
            inTemplates.push(options.mainTemplate)
          }
          otherTemplatesIDs.push(options.createdId)
        }
      }
    )
    const crypto = require('crypto')
    const assignId = crypto.randomBytes(20).toString('hex')
    if (!question.itemOptions) {
      question.itemOptions = {
        optionsWithRequiredDetails: [],
        conditions: [],
        conditionsToCheck: [],
        externalConditions: [],
        createdId: assignId
      }
    } else if (!question.itemOptions.createdId) {
      question.itemOptions.createdId = assignId
    }
    if (isSubquestion(question)) {
      return
    }
    const questionOptions = []
    if (question.itemOptions.isGroup === true) {
      isGroup = true
      const subQuestions =
        groupQuestions[question.itemOptions.createdId] ||
        groupQuestions[question.order]
      if (subQuestions) {
        subQuestions.sort((a, b) => {
          return a.index - b.index
        })
        subQuestions.forEach(sub => {
          childQuestions.push({
            titleValue: sub.titleValue,
            index: sub.index,
            id: sub.id
          })
        })
      }
    }
    const optionsWithRequiredDetails = {}
    question.itemOptions.optionsWithRequiredDetails.forEach(detailObj => {
      if (!detailObj) {
        return
      }
      const type = detailObj.type ? detailObj.type : 'text'
      const index =
        detailObj.index || detailObj.index === 0 ? detailObj.index : detailObj
      optionsWithRequiredDetails[index] = type
    })
    question.itemDetails.sort((a, b) => a.order - b.order)
    question.itemDetails.forEach(detail => {
      const requireDetails = optionsWithRequiredDetails[detail.order]
      questionOptions.push({
        text: getLabelFromTranslationData({
          langVersion: user.language,
          data: detail.titleValue
        }),
        titleValue: detail.titleValue,
        apiValue: detail.apiValue,
        requireDetails: requireDetails || null
      })
    })
    if (fixedQuestionAnswers[question.item.Type__c]) {
      fixedQuestionAnswers[question.item.Type__c].forEach(option => {
        questionOptions.push({
          text: option,
          requireDetails: null
        })
      })
    }
    const questionObj = {
      templateName: template.info.surveyName,
      type: question.item.Type__c,
      validationRules: question.itemOptions.validationRules || [],
      createdDate: question.item.CreatedDate,
      isGroup,
      otherTemplatesIDs,
      inTemplates,
      sfId: question.item.Id,
      groupQuestions: childQuestions,
      options: questionOptions,
      id: question.itemOptions.createdId,
      text: getLabelFromTranslationData({
        langVersion: user.language,
        data: question.itemTranslation.titleValue
      }),
      tooltip: getLabelFromTranslationData({
        langVersion: user.language,
        data: question.itemTranslation.tooltipValue
      }),
      tooltipValue: question.itemTranslation.tooltipValue,
      titleValue: question.itemTranslation.titleValue
    }
    surveyItemOptionsFields.forEach(field => {
      questionObj[field] = question.itemOptions[field]
    })
    section.questions.push(questionObj)
  })
  toSet.push(section)

  return toSet
}

const styles = theme => ({
  question: {
    padding: 10,
    margin: 8,
    textAlign: 'left',
    paddingLeft: 20
  }
})

export const scoringGroupModifiers = ['WEIGHTING', 'SATISFACTION']

const ScoreCardCreator = ({ classes }) => {
  const { templateId, scoreCardId } = useParams()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [surveyData, setSurveyData] = useState()
  const [scoreCardItemsMap, setScoreCardItemsMap] = useState({})
  const [scoringCategories, setScoringCategories] = useState([])
  const user = useSelector(state => state.user)
  const configurations = useSelector(state => state.configuration)
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = () => {
    setLoading(true)
    return Promise.all([
      getSurveyTemplateDetails(templateId),
      getScoreCardItemsParsed({
        Survey_Template__c: templateId,
        Score_Card__c: scoreCardId
      })
    ]).then(([template, scoreCardItems]) => {
      console.log('got template', template)
      console.log('got score card items', scoreCardItems)
      if (!configurations[SCORING_CATEGORIES_CONFIG]) {
        enqueueSnackbar(
          <Trans>
            There is no configuration object with scoring categories
          </Trans>,
          { variant: 'error' }
        )
      } else {
        const value = configurations[SCORING_CATEGORIES_CONFIG] || []
        setScoringCategories(value)
      }
      const surveyData = surveyTemplateToRenderData(template, user)[0]
      surveyData.questions = surveyData.questions.filter(
        q => q.type !== questionTypes.HEADER.id
      )
      const sfIdToIdent = {}
      surveyData.questions.forEach(question => {
        sfIdToIdent[question.sfId] = question.id
      })
      setSurveyData(surveyData)
      const scoreMap = {}
      scoreCardItems.forEach(item => {
        scoreMap[sfIdToIdent[item.surveyItem]] = item
      })
      setScoreCardItemsMap(scoreMap)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [user.language])

  if (loading) {
    return <Loading />
  }

  return (
    <div style={{ padding: 10 }}>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        {surveyData.header}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          ...questionsToInitial(surveyData.questions),
          scoreCard: { ...scoreCardItemsMap }
        }}
      >
        {({ values, setFieldValue, errors }) => {
          console.log('values', values)
          const noQuestions = surveyData.questions.length === 0
          return (
            <div>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                style={{ marginTop: 10 }}
                disabled={noQuestions || saving}
                onClick={() => {
                  const toPass = []
                  surveyData.questions.forEach(question => {
                    const cardValues = values.scoreCard[question.id]
                    const scoreCardItem = scoreCardItemsMap[question.id]
                    const baseDetails =
                      cardValues && cardValues.scoringDetails
                        ? {
                            ...cardValues.scoringDetails
                          }
                        : {}
                    if (question.type === questionTypes.SELECT_MULTIPLE.id) {
                      baseDetails.multipleAnswers = true
                    }
                    const vObj = cardValues
                      ? {
                          Modifier__c: cardValues.modifier,
                          Scoring_Group__c: cardValues.scoreGroup,
                          Scoring_Details__c: JSON.stringify(baseDetails)
                        }
                      : {}
                    if (!scoreCardItem) {
                      toPass.push({
                        ...vObj,
                        Score_Card__c: scoreCardId,
                        Survey_Template__c: templateId,
                        Survey_Item__c: question.sfId,
                        Survey_Item_Ident_Id__c: question.id
                      })
                    } else {
                      toPass.push({
                        ...vObj,
                        Id: scoreCardItem.id,
                        Survey_Item_Ident_Id__c: question.id
                      })
                    }
                  })
                  setSaving(true)
                  enqueueSnackbar(<Trans>Saving score card</Trans>, {
                    variant: 'info'
                  })
                  saveScoreCards(toPass).then(r => {
                    console.log('save score card result', r)
                    const isError = r.some(arr => arr.some(obj => !obj.success))
                    fetchData().then(data => {
                      setSaving(false)
                      if (isError) {
                        console.error('error saving scores', r)
                        enqueueSnackbar(
                          <Trans>
                            Error ocurred while saving some of score card items!
                          </Trans>,
                          {
                            variant: 'error'
                          }
                        )
                      } else {
                        enqueueSnackbar(<Trans>Score card saved!</Trans>, {
                          variant: 'success'
                        })
                      }
                    })
                  })
                }}
              >
                <Icon style={{ marginRight: 5 }}>save</Icon>
                <Trans>Save score card</Trans>
              </Button>
              {surveyData.questions.map((item, index) => {
                const ConfigComponent = questionTypes[item.type].scoreConfig
                return (
                  <div key={index}>
                    <div style={{ textAlign: 'center' }}>
                      <QuestionTitle
                        key={item.id + 'title'}
                        question={item}
                        errors={false}
                        displayIndex={index + 1}
                        version={user.language}
                      />
                    </div>
                    <Grid container direction='row' wrap='nowrap'>
                      <Grid item xs={6}>
                        <QuestionWidget
                          // surveyId={survey && survey.id}
                          // network={network}
                          // errorIsArray={errorIsArray}
                          // entityFiles={entityFiles}
                          // opportunity={opportunity}
                          // hideError={sectionSkipped}
                          // completedSurveys={completedSurveys}
                          version={user.language}
                          questionValue={values[item.id]}
                          setFieldValue={setFieldValue}
                          disabled
                          disableFields={[]}
                          question={item}
                          index={item.id}
                          key={item.id + 'question'}
                          className={classes.question}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ margin: 8 }}>
                        <Card
                          style={{ padding: 10, height: '100%' }}
                          elevation={6}
                        >
                          {!item.isGroup && (
                            <Field
                              name={'scoreCard.' + item.id + '.scoreGroup'}
                            >
                              {({ field, form: { setFieldValue } }) => (
                                <Grid
                                  style={{ padding: 5 }}
                                  container
                                  wrap='nowrap'
                                >
                                  <TextField
                                    disabled={saving}
                                    select
                                    label={<Trans>Scoring group</Trans>}
                                    fullWidth
                                    variant='outlined'
                                    {...field}
                                    value={field.value || ''}
                                  >
                                    {scoringCategories.map((obj, index) => (
                                      <MenuItem value={obj.value} key={index}>
                                        {obj[user.language] || obj.en}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <IconButton
                                    disabled={saving}
                                    style={{ marginLeft: 10 }}
                                    onClick={() =>
                                      setFieldValue(
                                        'scoreCard.' + item.id + '.scoreGroup',
                                        ''
                                      )}
                                  >
                                    <Icon>clear</Icon>
                                  </IconButton>
                                </Grid>
                              )}
                            </Field>
                          )}
                          <Field name={'scoreCard.' + item.id + '.modifier'}>
                            {({ field }) => (
                              <Grid
                                style={{ padding: 5 }}
                                container
                                wrap='nowrap'
                              >
                                <TextField
                                  disabled={saving}
                                  label={<Trans>Modifier</Trans>}
                                  select
                                  fullWidth
                                  variant='outlined'
                                  {...field}
                                  // InputProps={{ inputComponent: NumericFormat }}
                                  value={field.value || ''}
                                >
                                  {scoringGroupModifiers.map((obj, index) => (
                                    <MenuItem value={obj} key={index}>
                                      {obj}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                <IconButton
                                  disabled={saving}
                                  style={{ marginLeft: 10 }}
                                  onClick={() =>
                                    setFieldValue(
                                      'scoreCard.' + item.id + '.modifier',
                                      ''
                                    )}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Grid>
                            )}
                          </Field>
                          {ConfigComponent && (
                            <ConfigComponent
                              item={item}
                              disabled={saving}
                              scoringCategories={scoringCategories}
                            />
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
              {noQuestions && (
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                  <Typography variant='h6'>
                    <Trans>This survey template has no questions added!</Trans>
                  </Typography>
                </div>
              )}
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

const ScoringGroupSelect = ({ name, disabled, scoringCategories, user }) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <Grid style={{ padding: 5 }} container wrap='nowrap'>
          <TextField
            select
            disabled={disabled}
            label={<Trans>Scoring group</Trans>}
            fullWidth
            variant='outlined'
            {...field}
            value={field.value || ''}
          >
            {scoringCategories.map((obj, index) => (
              <MenuItem value={obj.value} key={index}>
                {obj[user.language] || obj.en}
              </MenuItem>
            ))}
          </TextField>
          <IconButton
            disabled={disabled}
            style={{ marginLeft: 10 }}
            onClick={() => setFieldValue(name, '')}
          >
            <Icon>clear</Icon>
          </IconButton>
        </Grid>
      )}
    </Field>
  )
}

const SlidersScoring = ({ min, max, name, item, setFieldValue, disabled }) => {
  const [ranges, setRanges] = useState(item.ranges || [])
  const isNumMax = Boolean(max)
  const isNumMin = Boolean(min)
  const numMin = +min
  const numMax = +max

  const checkDown = (toSet, index) => {
    const toRet = [...toSet]
    for (let i = index - 1; i >= 0; i--) {
      const value = toSet[i]
      if (toRet[i + 1]) {
        const max = toRet[i + 1][0]
        if (max < value[1]) {
          value[1] = max - 1
        }
        if (value[1] <= value[0]) {
          value[0] = value[1] - 1
        }
      }
    }
    return toRet
  }

  const checkUp = (toSet, index) => {
    const toRet = [...toSet]
    for (let i = index + 1; i < toSet.length; i++) {
      const value = toSet[i]
      if (toRet[i - 1]) {
        const min = toRet[i - 1][1]
        if (min > value[0]) {
          value[0] = min + 1
        }
        if (value[1] <= value[0]) {
          value[1] = value[0] + 1
        }
      }
    }
    return toRet
  }

  useEffect(() => {
    if (Boolean(!min && min !== 0) || Boolean(!max && max !== 0)) {
      if (ranges.length > 0) {
        setRanges([])
        setFieldValue(name + '.ranges', [])
      }
    }
  }, [min, max])

  useEffect(() => {
    if (ranges.length > 0 && isNumMin) {
      const toSet = [...ranges]
      toSet[0][0] = numMin
      if (toSet[0][1] < toSet[0][0]) {
        toSet[0][1] = toSet[0][0] + 1
      }
      checkUp(toSet, 0)
      setRanges(toSet)
      setFieldValue(name + '.ranges', toSet)
    }
  }, [min])

  useEffect(() => {
    if (ranges.length > 0 && isNumMax) {
      const toSet = [...ranges]
      const lastIndex = toSet.length - 1
      toSet[lastIndex][1] = numMax
      if (toSet[lastIndex][0] > toSet[lastIndex][1]) {
        toSet[lastIndex][0] = toSet[lastIndex][1] - 1
      }
      checkDown(toSet, lastIndex)
      setRanges(toSet)
      setFieldValue(name + '.ranges', toSet)
    }
  }, [max])

  const valuesProvided = isNumMax && isNumMin
  return (
    <Field name={name}>
      {({ form: { setFieldValue, setValues, values }, field: { value } }) => (
        <>
          <Button
            style={{ marginTop: 10, marginBottom: 10 }}
            color='primary'
            variant='contained'
            disabled={!valuesProvided || disabled}
            onClick={e => {
              const prev = ranges[ranges.length - 1]
              const toSet = [...ranges]
              if (prev) {
                prev[1] = numMax - 2
              }
              toSet.push(prev ? [numMax - 1, numMax] : [numMin, numMax])
              checkDown(toSet, toSet.length)
              setRanges(toSet)
              setFieldValue(name + '.ranges', toSet)
            }}
          >
            <Grid container justify='center' alignContent='center'>
              <Icon style={{ marginRight: 5 }}>add</Icon>
              <Trans>Add</Trans>
            </Grid>
          </Button>
          {Boolean(valuesProvided) &&
            ranges.map((item, index) => {
              return (
                <Grid
                  key={index}
                  style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}
                  container
                  wrap='nowrap'
                  direction='row'
                >
                  <Slider
                    disabled={disabled}
                    marks={[
                      { value: numMin, label: numMin.toString() },
                      { value: numMax, label: numMax.toString() }
                    ]}
                    min={numMin}
                    max={numMax}
                    value={item}
                    valueLabelDisplay='auto'
                    onChange={(event, newValue) => {
                      const value = [...newValue]
                      const toSet = [...ranges]
                      if (index === 0) {
                        value[0] = numMin
                      }
                      if (index === toSet.length - 1) {
                        value[1] = numMax
                      }
                      if (value[0] < index * 2) {
                        value[0] = index * 2
                      }
                      let above = toSet.length - (index + 1)
                      above *= 2
                      if (value[1] + above > numMax) {
                        value[1] = numMax - above
                      }
                      toSet[index] = value
                      toSet[index] = value
                      if (toSet[index + 1]) {
                        toSet[index + 1][0] = value[1] + 1
                      }
                      if (toSet[index - 1]) {
                        toSet[index - 1][1] = value[0] - 1
                      }
                      checkUp(toSet, index)
                      checkDown(toSet, index)
                      setRanges(toSet)
                    }}
                    onChangeCommitted={e => {
                      setFieldValue(name + '.ranges', ranges)
                    }}
                  />
                  <div style={{ width: 250, paddingLeft: 25 }}>
                    <Field name={name + '.rangeScoring.' + index}>
                      {({ field }) => (
                        <div style={{ padding: 5 }}>
                          <TextField
                            disabled={disabled}
                            label={<Trans>Scoring</Trans>}
                            fullWidth
                            InputProps={{ inputComponent: NumericFormat }}
                            variant='outlined'
                            {...field}
                            value={field.value || ''}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </Grid>
              )
            })}
          <Grid container justify='flex-end' style={{ marginTop: 10 }}>
            <Button
              color='primary'
              variant='contained'
              disabled={ranges.length === 0 || disabled}
              onClick={e => {
                const newValues = { ...value }
                const toSet = [...ranges]
                toSet.splice(-1, 1)
                if (toSet[toSet.length - 1]) {
                  toSet[toSet.length - 1][1] = numMax
                }
                setRanges(toSet)
                newValues.ranges = toSet
                if (newValues.rangeScoring) {
                  newValues.rangeScoring.splice(-1, 1)
                }
                setFieldValue(name, newValues)
              }}
            >
              <Grid container justify='center' alignContent='center'>
                <Icon style={{ marginRight: 5 }}>delete</Icon>
                <Trans>Delete</Trans>
              </Grid>
            </Button>
          </Grid>
        </>
      )}
    </Field>
  )
}

export const NumericFieldScoreConfig = React.memo(
  ({ item, scoringCategories, disabled }) => {
    const user = useSelector(state => state.user)
    const name = 'scoreCard.' + item.id + '.scoringDetails'
    return (
      <Field name={name}>
        {({ field: { value }, form: { setFieldValue } }) => {
          const valuesObj = value || {}
          const min = item.min || valuesObj.rangeMin
          const max = item.max || valuesObj.rangeMax
          return (
            <>
              <Typography
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                <Trans>Scoring ranges</Trans>
              </Typography>

              <div style={{ padding: 5 }}>
                <TextField
                  disabled={item.min || disabled}
                  label={<Trans>Range min</Trans>}
                  fullWidth
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  value={valuesObj.rangeMin || ''}
                  onChange={e => {
                    const toSet = { ...valuesObj }
                    toSet.rangeMin = e.target.value
                    const min = toSet.rangeMin ? Number(toSet.rangeMin) : 0
                    const max = toSet.rangeMax ? Number(toSet.rangeMax) : 0
                    if (max <= min) {
                      toSet.rangeMax = String(min + 1)
                    }
                    setFieldValue(name, toSet)
                  }}
                />
              </div>

              <div style={{ padding: 5 }}>
                <TextField
                  disabled={item.max || disabled}
                  label={<Trans>Range max</Trans>}
                  fullWidth
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  value={valuesObj.rangeMax || ''}
                  onChange={e => {
                    const toSet = { ...valuesObj }
                    toSet.rangeMax = e.target.value
                    const min = toSet.rangeMin ? Number(toSet.rangeMin) : 0
                    const max = toSet.rangeMax ? Number(toSet.rangeMax) : 0
                    if (min >= max) {
                      toSet.rangeMin = String(max - 1)
                    }
                    setFieldValue(name, toSet)
                  }}
                />
              </div>

              {item.isGroup
                ? (
                  <div>
                    {item.groupQuestions.map((question, qIndex) => {
                      const name =
                      'scoreCard.' +
                      item.id +
                      '.scoringDetails' +
                      '.group' +
                      qIndex
                      return (
                        <div key={qIndex}>
                          <div style={{ padding: 10 }}>
                            {getLabelFromTranslationData({
                              langVersion: user.language,
                              data: question?.titleValue
                            })}
                          </div>
                          <ScoringGroupSelect
                            disabled={disabled}
                            name={name + '.scoreGroup'}
                            scoringCategories={scoringCategories}
                            user={user}
                          />
                          <Field name={name}>
                            {({ field: { value } }) => {
                              const obj = value || {}
                              return (
                                <SlidersScoring
                                  disabled={disabled}
                                  setFieldValue={setFieldValue}
                                  name={name}
                                  item={obj}
                                  min={min}
                                  max={max}
                                />
                              )
                            }}
                          </Field>
                        </div>
                      )
                    })}
                  </div>
                  )
                : (
                  <SlidersScoring
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    item={valuesObj}
                    name={'scoreCard.' + item.id + '.scoringDetails'}
                    min={min}
                    max={max}
                  />
                  )}
            </>
          )
        }}
      </Field>
    )
  }
)

export const TextFieldScoreConfig = React.memo(
  ({ item, scoringCategories, disabled }) => {
    const user = useSelector(state => state.user)

    const scoringFields = ({ name }) => {
      return (
        <Grid container direction='column'>
          <Field name={name + '.isFilled'}>
            {({ field }) => (
              <div style={{ padding: 5 }}>
                <TextField
                  disabled={disabled}
                  label={<Trans>Question filled</Trans>}
                  fullWidth
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  {...field}
                  value={field.value || ''}
                />
              </div>
            )}
          </Field>
          <Field name={name + '.isNotFilled'}>
            {({ field }) => (
              <div style={{ padding: 5 }}>
                <TextField
                  disabled={disabled}
                  label={<Trans>Question not filled</Trans>}
                  fullWidth
                  InputProps={{ inputComponent: NumericFormat }}
                  variant='outlined'
                  {...field}
                  value={field.value || ''}
                />
              </div>
            )}
          </Field>
        </Grid>
      )
    }

    return (
      <div>
        <Typography
          style={{
            marginTop: 15,
            marginBottom: 15,
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          <Trans>Scoring</Trans>
        </Typography>
        {item.isGroup
          ? (
            <div>
              {item.groupQuestions.map((question, qIndex) => {
                const name =
                'scoreCard.' + item.id + '.scoringDetails' + '.group' + qIndex
                return (
                  <div key={qIndex}>
                    <div style={{ padding: 10 }}>
                      {getLabelFromTranslationData({
                        langVersion: user.language,
                        data: question?.titleValue
                      })}
                    </div>
                    <ScoringGroupSelect
                      disabled={disabled}
                      name={name + '.scoreGroup'}
                      scoringCategories={scoringCategories}
                      user={user}
                    />
                    {scoringFields({
                      name
                    })}
                  </div>
                )
              })}
            </div>
            )
          : (
            <div>
              {scoringFields({
                name: 'scoreCard.' + item.id + '.scoringDetails'
              })}
            </div>
            )}
      </div>
    )
  }
)

export const SelectOptionScoreConfig = React.memo(
  ({ item, disabled, scoringCategories }) => {
    const user = useSelector(state => state.user)
    return (
      <div>
        <Typography
          style={{
            marginTop: 15,
            marginBottom: 15,
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          <Trans>Options scores</Trans>
        </Typography>
        {item.isGroup
          ? (
            <div>
              {item.groupQuestions.map((question, qIndex) => {
                const name =
                'scoreCard.' + item.id + '.scoringDetails' + '.group' + qIndex
                return (
                  <div key={qIndex}>
                    <div style={{ padding: 10 }}>
                      {getLabelFromTranslationData({
                        langVersion: user.language,
                        data: question?.titleValue
                      })}
                    </div>
                    <ScoringGroupSelect
                      disabled={disabled}
                      name={name + '.scoreGroup'}
                      scoringCategories={scoringCategories}
                      user={user}
                    />
                    {item.options.map((option, index) => (
                      <Grid container key={index} direction='column'>
                        <Field name={name + '.option_' + index}>
                          {({ field }) => (
                            <div style={{ padding: 5 }}>
                              <TextField
                                disabled={disabled}
                                label={option.text}
                                fullWidth
                                InputProps={{ inputComponent: NumericFormat }}
                                variant='outlined'
                                {...field}
                                value={field.value || ''}
                              />
                            </div>
                          )}
                        </Field>
                      </Grid>
                    ))}
                  </div>
                )
              })}
            </div>
            )
          : (
              item.options.map((option, index) => (
                <Grid container key={index} direction='column'>
                  <Field
                    name={
                  'scoreCard.' +
                  item.id +
                  '.scoringDetails' +
                  '.option_' +
                  index
                }
                  >
                    {({ field }) => (
                      <div style={{ padding: 5 }}>
                        <TextField
                          disabled={disabled}
                          label={option.text}
                          fullWidth
                          InputProps={{ inputComponent: NumericFormat }}
                          variant='outlined'
                          {...field}
                          value={field.value || ''}
                        />
                      </div>
                    )}
                  </Field>
                </Grid>
              ))
            )}
      </div>
    )
  }
)

export default withStyles(styles)(ScoreCardCreator)

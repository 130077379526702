const Layout1SettingsChtc = {
  leftSidebar: {
    show: true,
    mode: 'full', // full, close, compact, mobile,
    theme: 'icceSidebar', // View all valid theme colors inside EgretTheme/themeColors.js
    bgOpacity: 0.1, // 0 ~ 1

    // bgImgURL: '/assets/images/sidebar/sidebar-bg-chtc.png',
    bgStyle: {
      backgroundSize: 'cover',
      color: '#ffffff',
      backgroundColor: '#000000',
      backgroundImage: 'unset'
    },
    headerIconButtonsStyle: {
      color: '#A43336'
    },
    headerStyle: {
      color: 'white',
      backgroundColor: '#231F20',
      iconColor: '#A43336',
      height: 70
    }
  },

  topbar: {
    show: true,
    fixed: true,
    theme: 'white' // View all valid theme colors inside EgretTheme/themeColors.js
  },
  secondarySidebar: {
    show: false
  }

}

export default Layout1SettingsChtc

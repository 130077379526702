import { Trans } from '@lingui/macro'
import { MILESTONE_ORDER } from 'app/services/sfAuth/sfData/sfOpportunity'
import { languages } from 'translation/I18nConnectedProvider'

export const formMilestoneUpdateValueToText = v => {
  return languages.reduce((acc, lang) => {
    if (lang === 'en') {
      acc[lang] = (
        <div>
          <Trans>Data is too complex to show objectives!</Trans>
          <div style={{ color: '#e0a912' }}>
            <Trans>
              Overriding will ony save changes to already added objectives. It
              will not delete any newly added milestones. Manual cleanup may be
              required.
            </Trans>
          </div>
        </div>
      )
    } else {
      acc[lang] = <Trans>Data is too complex to show differences!</Trans>
    }
    return acc
  }, {})
}

export const FormMilestoneUpdateExtractKey = ({
  saveMap,
  subObjectsMap,
  additionalSFInfo,
  value,
  values,
  item,
  connectedMap,
  connectedProps,
  connectedObjectId,
  sfObject,
  appConfigurations
}) => {
  const milestoneUpdates = []
  if (connectedProps.forceType !== 'FGM_Base__Grantee_Report__c') {
    return
  }

  value.forEach(item => {
    milestoneUpdates.push({
      Id: item.milestoneUpdate.id,
      Benchmark__c: item.id,
      Grantee_Report__c: sfObject.Id,
      Phase_start_date__c: item.milestoneUpdate.phaseStartDate,
      Phase_end_date__c: item.milestoneUpdate.phaseEndDate,
      Primary_activities_planned__c: item.milestoneUpdate.primaryActivities,
      Primary_activities_progress__c: item.milestoneUpdate.implementedActivities
    })
  })
  if (milestoneUpdates.length) {
    saveMap[connectedObjectId].Benchmark_Updates__r = milestoneUpdates
  }
}

export const FormMilestoneUpdateDefaultValue = (
  obj,
  { contactsMap, accountsMap, initObj, connectedMap, ...additionalSFInfo } = {},
  item
) => {

  if(!obj?.Opportunity){
    return []
  }

  if (!obj?.Opportunity?.FGM_Base__Benchmarks__r 
    || (obj?.Opportunity?.FGM_Base__Benchmarks__r?.records || []).length === 0){
      console.log('FORM_MILESTONE_UPDATE_UTILS_NO_MILESTONES_ERROR_LABEL', obj)
    throw {
      message: <Trans>FORM_MILESTONE_UPDATE_UTILS_NO_MILESTONES_ERROR_LABEL</Trans>,
    }
  }

  // reports for the connected opportunity without the current report
  const reports = obj.Opportunity.granteeReports || []

  // milestone updates for the connected report
  const granteeReportMilestoneUpdates =
    obj?.FGM_Base__Grantee_Report__c?.Benchmark_Updates__r?.records || []

  const latestUpdatedReport = obj.Opportunity.granteeReports.find(report => {
    const areAnyChanges = report.milestonesUpdates.some(update => {
      const sameBenchmark = granteeReportMilestoneUpdates.find(current => current.Benchmark__c === update.benchmark)
      return !sameBenchmark || (sameBenchmark.Phase_start_date__c !== update.phaseStartDate || sameBenchmark.Phase_end_date__c !== update.phaseEndDate)
    })
    return areAnyChanges
  })

  const toRet = obj.Opportunity.FGM_Base__Benchmarks__r.records
    .map(milestone => {
      const milestoneUpdate = granteeReportMilestoneUpdates.find(
        item => item.Benchmark__c === milestone.Id
      )

      // reports that have a milestone update for the milestone
      const milestoneUpdateReports = reports?.filter(report => {
        const milestoneReport = report.milestonesUpdates.find(
          item => item.benchmark === milestone.Id
        )
        return !!milestoneReport
      })

      // milestone update for milestone in the reports
      const reportMilestoneUpdates = milestoneUpdateReports?.map(report =>
        ({
          ...report.milestonesUpdates?.find(
            item => item.benchmark === milestone.Id
          ),
          submissionDate: report.submissionDate
        })
      )

      const latestUpdate = latestUpdatedReport?.milestonesUpdates.find(update => milestone.Id === update.benchmark)

      // dates for the milestone
      const startDate =
      latestUpdate?.phaseStartDate ||
        milestone.FGM_Base__Due_Date__c
      const endDate =
      latestUpdate?.phaseEndDate ||
        milestone.FGM_Base__Completion_Date__c

      // dates for the milestone update
      const phaseStartDate = milestoneUpdate?.Phase_start_date__c || startDate
      const phaseEndDate = milestoneUpdate?.Phase_end_date__c || endDate

      return {
        startDate,
        endDate,
        primaryActivities: milestone.Primary_activities__c || '',
        comments: milestone.FGM_Base__Description__c || '',
        stage: milestone.FGM_Base__Status__c,
        id: milestone.Id,
        milestoneUpdate: {
          id: milestoneUpdate?.Id,
          primaryActivities:
            milestoneUpdate?.Primary_activities_planned__c || '',
          implementedActivities:
            milestoneUpdate?.Primary_activities_progress__c || '',
          milestoneId: milestone.Id,
          mileStoneName: milestone.Name,
          createdDate: milestoneUpdate?.CreatedDate,
          createdById: milestoneUpdate?.CreatedById,
          lastModifiedById: milestoneUpdate?.LastModifiedById,
          name: milestoneUpdate?.name,
          granteeReportId: milestoneUpdate?.Grantee_Report__c,
          phaseStartDate: phaseStartDate || startDate,
          phaseEndDate: phaseEndDate || endDate
          // areDatesChanged
        },
        latestMilestoneUpdate: latestUpdate || {},
        reportMilestoneUpdates: reportMilestoneUpdates || [],
        reports
      }
    })
    .sort(
      (a, b) =>
        MILESTONE_ORDER.indexOf(a.stage) - MILESTONE_ORDER.indexOf(b.stage)
    )

  const areDatesChanged = toRet.some((item, idx) => {
    return (
      item.startDate !== item.milestoneUpdate.phaseStartDate ||
      item.endDate !== item.milestoneUpdate.phaseEndDate
    )
  })
  toRet.forEach(item => {
    item.milestoneUpdate.areDatesChanged = areDatesChanged ? 'yes' : 'no'
  })

  return toRet
}

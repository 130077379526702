export const BUDGET_REVENUES_LINES_IN_ORDER = [
  'Transformation Centre Grant',
  'Other Grants',
  'Fundraising/Donations',
  'Internal Revenue'
]

export const BUDGET_EXPENSES_LINES_IN_ORDER = [
  'Personnel - Internal Wages and Benefits',
  'Personnel - External Professional/consultant',
  'Direct costs (travel, materials, equipment, supplies, licensing, training costs)',
  'Indirect costs (Rental space, Insurance, Maintenance, etc)',
  'Indirect costs (Rental space, Insurance, Maintenance, etc...)',
  'Administrative costs',
  'Other'
]

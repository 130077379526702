import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Typography,
  FormGroup,
  RadioGroup,
  FormLabel,
  Collapse,
  Tooltip,
  Radio
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { scoringCategoryNameToKey } from '../ActionTemplate'
import { getTitleTranslation } from 'app/views/common/TranslationsCommon'
import moment from 'moment'
import { useSnackbar } from 'notistack'

const ViewAllActionsButton = ({
  style,
  color,
  surveysIdsMap,
  templates = [],
  columns,
  handleActionDelete,
  handleAddNewCard
}) => {
  const [openDialog, setDialogOpen] = useState(false)
  const [showForSurvey, setShowForSurvey] = useState('Non-profit')
  const user = useSelector(state => state.user)
  const organization = useSelector(state => state.organization)
  const configurations = useSelector(state => state.configuration)

  useEffect(() => {
    if (openDialog) {
      setShowForSurvey('Non-profit')
    }
  }, [openDialog])

  let categoriesConfiguration = configurations.SCORING_CATEGORY
  if (!Array.isArray(categoriesConfiguration)) {
    try {
      categoriesConfiguration = JSON.parse(categoriesConfiguration)
    } catch (error) {
      categoriesConfiguration = []
      console.error('No scoring configurations configured')
    }
  }

  const templatesToScoringCategory = {}
  templates.forEach(template => {
    const data = template.JSON__c ? JSON.parse(template.JSON__c)[0] : {}
    if (data) {
      const categoryKey = scoringCategoryNameToKey[template.Category__c]
      const surveyTemplate = data.template
      let surveyName
      if (surveysIdsMap['Co-op'].includes(surveyTemplate)) {
        surveyName = 'Co-op'
      } else if (surveysIdsMap.OSBL.includes(surveyTemplate)) {
        surveyName = 'Non-profit'
      }
      const toSet = {
        id: template.Id,
        surveyTemplate: surveyName,
        title: getTitleTranslation(user.language, template.Title__c),
        description: getTitleTranslation(
          user.language,
          template.Description__c
        ),
        category: template.Category__c,
        outcome: getTitleTranslation(
          user.language,
          template.Expected_Outcome__c
        ),
        timeline: template.Timeline__c,
        reason: getTitleTranslation(user.language, template.Reason__c)
      }
      if (categoryKey) {
        if (templatesToScoringCategory[categoryKey]) {
          templatesToScoringCategory[categoryKey].push(toSet)
        } else {
          templatesToScoringCategory[categoryKey] = [toSet]
        }
      }
    }
  })
  const alreadyCreated = []
  const idToIndexInColumn = {}
  const idToActionId = {}
  columns
    .filter(column => column.index === 0)
    .forEach(column => {
      column.actions.forEach((action, actionIndex) => {
        if (
          action.fromTemplate &&
          !alreadyCreated.includes(action.fromTemplate)
        ) {
          alreadyCreated.push(action.fromTemplate)
          idToIndexInColumn[action.fromTemplate] = actionIndex
          idToActionId[action.fromTemplate] = action.id
        }
      })
    })

  return (
    <>
      <Dialog open={openDialog} fullWidth maxWidth='lg'>
        <DialogTitle style={{ paddingBottom: 8 }}>
          <Grid container justifyContent='space-between'>
            <div style={{ fontWeight: 500, fontSize: 36 }}>
              <Trans>All actions</Trans>
            </div>
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <div style={{ marginTop: 10 }}>
            <FormLabel component='legend'>
              <Trans>Show actions</Trans>:
            </FormLabel>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <FormControlLabel
                control={<Radio />}
                checked={showForSurvey === 'Co-op'}
                onChange={e => {
                  setShowForSurvey('Co-op')
                }}
                label={<Trans>S.A.T. - Co-ops</Trans>}
              />
              <FormControlLabel
                control={<Radio />}
                checked={showForSurvey === 'Non-profit'}
                onChange={e => {
                  setShowForSurvey('Non-profit')
                }}
                label={<Trans>S.A.T. - OSBL</Trans>}
              />
            </RadioGroup>
          </div>
          <Grid container wrap='nowrap' style={{ marginTop: 10 }}>
            {categoriesConfiguration.map((obj, index) => {
              const templates = templatesToScoringCategory[obj.value] || []
              return (
                templates.length > 0 && (
                  <Grid
                    item
                    xs
                    container
                    direction='column'
                    key={index}
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Typography variant='h5' style={{ textAlign: 'center' }}>
                      {obj[user.language]}
                    </Typography>
                  </Grid>
                )
              )
            })}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container wrap='nowrap'>
            {categoriesConfiguration.map((obj, index) => {
              const templates = templatesToScoringCategory[obj.value] || []
              return (
                templates.length > 0 && (
                  <Grid
                    item
                    xs
                    container
                    direction='column'
                    key={index}
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    {templates
                      .filter(
                        action =>
                          !action.surveyTemplate ||
                          showForSurvey === action.surveyTemplate
                      )
                      .sort((a, b) => {
                        const aAdded = alreadyCreated.includes(a.id)
                        const bAdded = alreadyCreated.includes(b.id)
                        if (aAdded && !bAdded) {
                          return 1
                        } else if (bAdded && !aAdded) {
                          return -1
                        } else {
                          const titleA = a.title || ''
                          const titleB = b.title || ''
                          return titleA.localeCompare(titleB)
                        }
                      })
                      .map((template, index) => {
                        return (
                          <ActionCard
                            handleActionDelete={handleActionDelete}
                            handleAddNewCard={handleAddNewCard}
                            actionId={idToActionId[template.id]}
                            key={index}
                            template={template}
                            added={alreadyCreated.includes(template.id)}
                            currentIndex={idToIndexInColumn[template.id]}
                            index={index}
                          />
                        )
                      })}
                  </Grid>
                )
              )
            })}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button
        style={style}
        color={color}
        disabled={!categoriesConfiguration || !organization.id}
        variant='contained'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Icon style={{ marginRight: 5 }}>pending_actions</Icon>
        <Trans>View all actions</Trans>
      </Button>
    </>
  )
}

const ActionCard = ({
  added,
  actionId,
  index,
  template,
  currentIndex,
  handleActionDelete,
  handleAddNewCard
}) => {
  const [adding, setAdding] = useState(false)
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const organization = useSelector(state => state.organization)

  useEffect(() => {
    setAdding(false)
    setShowMoreInfo(false)
  }, [added, template.id, actionId])

  return (
    <div style={{ padding: 4, width: '100%' }} key={index}>
      <Card
        elevation={3}
        className='list-column__card borderborder-radius-4 bg-light-gray'
        style={{ margin: 4, width: '100%', minHeight: 80 }}
      >
        <div className='scrum-board-card'>
          <div className='px-16 py-12'>
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              wrap='nowrap'
            >
              <h6 className='m-0 font-weight-500'>{template.title}</h6>
              {showMoreInfo
                ? (
                  <Tooltip title={<Trans>Hide more info</Trans>}>
                    <IconButton
                      size='small'
                      onClick={e => {
                        setShowMoreInfo(false)
                      }}
                    >
                      <Icon>hide_source</Icon>
                    </IconButton>
                  </Tooltip>
                  )
                : (
                  <Tooltip title={<Trans>Show more info</Trans>}>
                    <IconButton
                      size='small'
                      onClick={e => {
                        setShowMoreInfo(true)
                      }}
                    >
                      <Icon>info</Icon>
                    </IconButton>
                  </Tooltip>
                  )}
            </Grid>
            <Collapse in={Boolean(showMoreInfo)}>
              <div style={{ marginBottom: 5 }}>
                <div style={{ fontWeight: 350 }}>
                  <Trans>Description</Trans>
                </div>
                <div style={{ fontSize: 12 }}>{template.description}</div>
              </div>
              <div>
                <div style={{ fontWeight: 350 }}>
                  <Trans>Expected outcome</Trans>
                </div>
                <div style={{ fontSize: 12 }}>{template.outcome}</div>
              </div>
            </Collapse>

            <FormControlLabel
              control={<Checkbox checked={Boolean(added || adding)} />}
              disabled={Boolean(adding)}
              onChange={e => {
                if (!added) {
                  setAdding(true)
                  enqueueSnackbar(<Trans>Creating action</Trans>, {
                    variant: 'info'
                  })
                  const toAdd = {
                    title: template.title,
                    orgId: organization.id,
                    category: template.category,
                    description: template.description,
                    expectedOutcome: template.outcome,
                    column: 'Selected',
                    fromTemplate: template.id,
                    reason: template.reason,
                    dueDate: moment.utc().add(template.timeline, 'days')
                  }
                  console.log('adding action', toAdd)
                  handleAddNewCard(toAdd)
                } else {
                  if (currentIndex || currentIndex === 0) {
                    setAdding(true)
                    enqueueSnackbar(<Trans>Deleting action</Trans>, {
                      variant: 'info'
                    })
                    handleActionDelete(+currentIndex, 0)
                  }
                }
              }}
              label={
                added
                  ? (
                    <Trans>Already added</Trans>
                    )
                  : (
                    <Trans>Add to action plan</Trans>
                    )
              }
            />
            <div className='flex flex-middle flex-space-between mt-12 button-group text-small'>
              <Grid className='flex' container direction='row' />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ViewAllActionsButton

import { Alert, AlertTitle } from '@material-ui/lab'
import { parseFormLabelText } from 'app/views/forms/common/Common'

export const FormBanner = ({
  title,
  typeProps,
  ...props
}) => {
  const { text } = typeProps

  const variant = typeProps?.variant || 'standard'
  const state = typeProps?.state || 'info'

  const parsedText = parseFormLabelText({ text, ...props })

  return (
    <div style={{ padding: 12 }}>
      <Alert variant={variant} severity={state}>
        <AlertTitle>{title}</AlertTitle>
        {parsedText}
      </Alert>
    </div>
  )
}

import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

export const FormObjectivesPdfEditor = ({ title }) => {
  return (
    <div className='pdf-mode'>
      <div className='pdfProps form-print-subtitle'>{title}</div>
      <div style={{ ...pdfTableStyles.table, display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Objective</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Objective Placeholder</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Outcomes</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Outcomes Placeholder</div>
        </div>
      </div>
    </div>
  )
}

import { TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'

const DebouncedTextField = ({
  value: initialValue,
  onChange,
  debounceTime = 500,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(initialValue || '')
  const [timer, setTimer] = useState(null)

  const handleChange = e => {
    // Clone the event's target properties
    const clonedEvent = {
      ...e,
      target: {
        name: e.target.name,
        value: e.target.value
      }
    }
    setInputValue(e.target.value)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      onChange(clonedEvent)
    }, debounceTime)
    setTimer(newTimer)
  }

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  return <TextField {...props} value={inputValue} onChange={handleChange} />
}

export default DebouncedTextField

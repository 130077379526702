import { Trans } from '@lingui/macro'
import { Checkbox, FormControlLabel, Grid, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'formik'
import ReactHtmlParser from 'react-html-parser'
import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'
import { languages } from '../../../../../translation/I18nConnectedProvider'
import { FormErrorLabel } from '../../../common/labels/FormErrorLabel'
import { handleSelectActions } from '../../common/Common'
import { MUPreviouslySavedValuesPanel } from '../../multiuser/components/MUPreviouslySavedValuesPanel'
import { commitChangeToMultipleFields } from '../../multiuser/grpcMultiuserEdit'

/**
 * Form element which renders an input allowing to set boolean value - either checkbox or switch.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {string}  [typeProps.fieldType='checkbox'] Type of input the form element should render.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {Object[]} [typeProps.selectActions] Actions configured in the editor that will be executed on this elements value change.
 */
export const FormBoolean = ({
  id,
  useMultiuser,
  muBag,
  disabled,
  typeProps,
  title,
  labelAsMarkdown,
  ...props
}) => {
  const { fieldType = 'checkbox', required, selectActions = [] } = typeProps
  const classes = useStyles()

  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString()
  }

  title = <div className={classes.root}>{ReactHtmlParser(title)}</div>

  return (
    <Field name={id}>
      {({ field, form: { setFieldValue, setValues, values }, meta }) => {
        return (
          <>
            <Grid container wrap='nowrap'>
              <FormControlLabel
                control={
                  fieldType === 'switch'
                    ? (
                      <Switch
                        disabled={disabled}
                        {...field}
                        checked={Boolean(field.value)}
                        onChange={e => {
                          const toSet = { ...values }
                          toSet[id] = Boolean(e.target.checked)
                          const modified = handleSelectActions({
                            toSet,
                            checked: e.target.checked,
                            selectActions
                          })
                          setValues(toSet)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [...modified, id].map(id => [id, toSet[id]]),
                              ...muBag
                            })
                          }
                        }}
                      />
                      )
                    : (
                      <Checkbox
                        disabled={disabled}
                        {...field}
                        checked={Boolean(field.value)}
                        onChange={e => {
                          const toSet = { ...values }
                          toSet[id] = e.target.checked
                          const modified = handleSelectActions({
                            toSet,
                            checked: e.target.checked,
                            selectActions
                          })
                          setValues(toSet)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [...modified, id].map(id => [id, toSet[id]]),
                              ...muBag
                            })
                          }
                        }}
                      />
                      )
                }
                label={
                  <div>
                    {title}
                    <FormErrorLabel
                      noMargin
                      required={required}
                      id={id}
                      error={meta.error && meta.touched}
                    />
                  </div>
                }
              />
              <MUPreviouslySavedValuesPanel
                disabled={disabled}
                muBag={muBag}
                fieldId={id}
                type={props.elementType}
                useMultiuser={useMultiuser}
              />
            </Grid>
          </>
        )
      }}
    </Field>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    },
    '& p': {
      margin: 0
    }
  }
}))

export const formBooleanValueToText = (value, question) => {
  const toRet = value ? <Trans>Yes</Trans> : <Trans>No</Trans>
  const retObj = languages.reduce((acc, lang) => {
    acc[lang] = toRet
    return acc
  }, {})
  return retObj
}

export const formBooleanParseValueToCompare = value => Boolean(value)

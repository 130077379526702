import { Trans } from '@lingui/macro'
import { Button } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { FormErrorsList } from '../../common/FormErrorsList'

/**
 * Form element which renders an additional save button that is used in saving the form.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.showAllErrors=false] Above the button, render the current list of error for the the whole form.
 * @param  {boolean}  [typeProps.showSectionErrors=false] Above the button, render the current list of error for the the section button is in.
 */
export const FormSaveButton = ({
  saving,
  connectedMap,
  objectsFieldsMap,
  describeMap,
  saveButtonClicked,
  saveDisabled,
  errors,
  disabled,
  sectionIndex,
  navigateToError,
  typeProps
}) => {
  const { showAllErrors, showSectionErrors } = typeProps
  const { values } = useFormikContext()
  let errorsToPass = errors || []
  if (showSectionErrors) {
    errorsToPass = errorsToPass.filter(obj => sectionIndex === obj.sectionIndex)
  }

  return (
    <Field>
      {({ form }) => (
        <>
          {!disabled && (showAllErrors || showSectionErrors) && (
            <FormErrorsList
              helperText={
                showSectionErrors
                  ? (
                    <Trans>
                      You have not completed all required fields on this page
                    </Trans>
                    )
                  : (
                    <Trans>You have not completed all required fields</Trans>
                    )
              }
              navigateToError={navigateToError}
              errors={errorsToPass}
              objectsFieldsMap={objectsFieldsMap}
              describeMap={describeMap}
              connectedMap={connectedMap}
            />
          )}
          <Button
            onClick={e => {
              saveButtonClicked({ values })
            }}
            disabled={saving || saveDisabled || disabled || !form.dirty}
            color='primary'
            variant='contained'
            fullWidth
          >
            <Trans>Save</Trans>
          </Button>
        </>
      )}
    </Field>
  )
}

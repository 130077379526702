import React, { useState } from 'react'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { t } from '@lingui/macro'
import { I18n } from '@lingui/react'

const useStyles = makeStyles(theme => ({
  button: {
    margin: '30px',
    borderRadius: 5,
    padding: '9x 18px',
    fontSize: '18px',
    fontColor: '#FFFFFF',
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    border: '3px solid',
    backgroundColor: props => props.color,
    '&:hover': {
      borderColor: props => props.color,
      backgroundColor: props => props.color
    }
  }
}))

export default function HomepageButton ({
  openInNewTab = false,
  text,
  onClick,
  link,
  url,
  dataTut,
  color = '#24355B'
}) {
  const classes = useStyles({ color })
  const [arrow, showArrow] = useState(false)
  return (
    <div>
      <I18n>
        {({ i18n }) => (
          <Button
            className={classes.button}
            data-tut={dataTut}
            variant='outlined'
            style={{ alignItems: 'center', justifyContent: 'center' }}
            onMouseOver={() => {
              showArrow(true)
            }}
            onMouseLeave={() => {
              showArrow(false)
            }}
            onClick={onClick}
          >
            <div
              style={{
                height: '100%'
              }}
            >
              {link
                ? (
                  <a
                    href={
                    url || i18n._(t`https://centre.support/our-grants/`)
                  }
                    {...(openInNewTab && { target: '_blank' })}
                    rel='noreferrer noopener'
                  >
                    {text}
                    {arrow
                      ? (
                        <Icon
                          style={{
                            fontSize: '27px',
                            marginLeft: 8,
                            verticalAlign: 'middle'
                          }}
                        >
                          keyboard_arrow_right
                        </Icon>
                        )
                      : null}
                  </a>
                  )
                : (
                  <div>
                    {text}
                    {arrow
                      ? (
                        <Icon
                          style={{
                            fontSize: '27px',
                            marginLeft: 8,
                            verticalAlign: 'middle'
                          }}
                        >
                          keyboard_arrow_right
                        </Icon>
                        )
                      : null}
                  </div>
                  )}
            </div>
          </Button>
        )}
      </I18n>
    </div>
  )
}

import { Divider, Grid, Typography } from '@material-ui/core'
import { Trans } from '@lingui/macro'
import { CurrencyFormated } from 'app/views/common/Formats'

export const FormOtherGrantsPrint = ({ title, value = [] }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title-small'>{title}</div>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={5} style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Name</Trans>
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Value</Trans>
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Grants: State</Trans>
          </Typography>
        </Grid>
      </Grid>
      {value.map((grant, index) => {
        return (
          <>
            <Divider />
            <Grid container key={index} style={{ width: '100%' }}>
              <Grid item xs={5} style={{ padding: 4 }}>
                <Typography>{grant.name}</Typography>
              </Grid>
              <Grid item xs={4} style={{ padding: 4 }}>
                <Typography>
                  <CurrencyFormated value={grant.value} />
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ padding: 4 }}>
                <Typography>
                  <Trans id={grant.state} />
                </Typography>
              </Grid>
            </Grid>
          </>
        )
      })}
    </div>
  )
}

import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

export const FormMilestonesPdfEditor = ({ title }) => {
  return (
    <div className='pdf-mode'>
      <div className='pdfProps form-print-subtitle'>{title}</div>
      <div style={{ ...pdfTableStyles.table, display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Stage</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Stage Value Placeholder</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Start date</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Start Date Placeholder</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>End date</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>End Date Placeholder</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Primary Activities</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Primary Activities Placeholder</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Comments</div>
          <div style={{ ...pdfTableStyles.tableCol, flex: 1 }}>Comments Placeholder</div>
        </div>
      </div>
    </div>
  )
}

export const muiDTNormalizedSearch = (searchQuery, currentRow, columns) => {
  let ret = false
  let searchNormalized = searchQuery.normalize().toLowerCase()
  searchNormalized = searchNormalized.replace(/\s+/g, ' ')
  for (let index = 0; index < columns.length; index++) {
    const column = columns[index]
    if (column.searchable) {
      let value = currentRow[index] ? currentRow[index].normalize() : null
      if (value) {
        value = value.replace(/\s+/g, ' ').toLowerCase()
        ret = ret || value.includes(searchNormalized)
      }
    }
  }
  return ret
}

import { StyleSheet, View } from '@react-pdf/renderer'

const shadowYOffset = 2

const styles = StyleSheet.create({
  shadow: {
    position: 'absolute',
    width: '99%',
    height: '100%',
    top: shadowYOffset,
    backgroundColor: '#F0F0F0',
    borderRadius: 5,
    alignSelf: 'center'
  },
  border: {
    padding: 6,
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.02)',
    borderRadius: 5
  },
  card: {
    padding: 6,
    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1pt solid #cbcccc',
    borderRadius: 2
    // marginBottom: shadowYOffset
  }
})

export const AdjustPdfProps = ({
  style = {},
  ref,
  disabled = false,
  typeProps,
  children,
  ...props
}) => {
  let styleToPass = {}
  const isBorder = Boolean(typeProps?.pdfElementStyle === 'border')
  const isCard = Boolean(typeProps?.pdfElementStyle === 'card')

  if (isBorder) {
    styleToPass = { ...styleToPass, ...styles.border }
  }

  if (isCard) {
    styleToPass = { ...styleToPass, ...styles.card }
  }

  return disabled ? (
    children
  ) : (
    <View style={style} ref={ref}>
      {/* {isCard && <View style={styles.shadow} />} */}
      <View style={styleToPass}>{children}</View>
    </View>
  )
}

import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import MUIDataTable from 'mui-datatables'
import { myI18n } from 'translation/I18nConnectedProvider'
import { muiDateCompare } from 'utils'

export const FormTablePrintEditor = ({ title, langFR, connectedMap, typeProps }) => {
  const { connectedTo = [], columns = [] } = typeProps

  function getPlaceholderValues () {
    const toRet = {}
    columns.forEach(column => {
      const fieldName = column?.field?.name
      if (fieldName) {
        // toRet[fieldName] = `${langFR? column?.labelEN: column.labelFR} Placeholder`
        toRet[fieldName] = <><i>{fieldName}</i> Placeholder</>
      }
    })
    return toRet
  }

  const placeHolderValues = [getPlaceholderValues()]

  let collection = []
  const collectionKey = connectedTo[0]?.connectedCollection
  const connectedObject = connectedTo[0]?.connectedObject
  if (collectionKey && connectedObject && connectedMap) {
    collection =
        connectedMap[connectedObject]?.additionalInfo[collectionKey] || []
  }

  const _title = <div className='form-print-title-small table-print'>{title}</div>

  return (
    <MUIDataTable
      title={_title}
      data={placeHolderValues}
      options={{
        textLabels: muiTextLabels(myI18n),
        selectableRows: 'none',
        // print: false,
        // download: false,
        viewColumns: false
      }}
      columns={columns
        .map((column, index) => {
          const { labelEN, labelFR, field } = column
          const label = langFR ? labelFR : labelEN
          return {
            name: field.name,
            label,
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>{label}</div>
                )
              },
              sortCompare: field.type === 'date' && muiDateCompare(),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>{value}</div>
                )
              }
            }
          }
        })
        .filter(column => column.name)}
    />
  )
}

import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from 'app/views/forms/common/Common'
import { defaultFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = {
  ...pdfTableStyles,
  ...StyleSheet.create({
    text: {
      fontSize: pdfDefaultFontSize,
      fontFamily: defaultFormPdfFontFamily
    },
    section: {
      marginBottom: 8
    },
    objectiveTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 4
    },
    subtitle: {
      fontSize: 10,
      fontWeight: 'bold',
      marginBottom: 4,
      marginTop: 4
    },
    headerCell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'left',
      flex: 1
    },
    cell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      flex: 1
    },
    tableRow: {
      ...pdfTableStyles.tableRow,
      flexWrap: 'nowrap',
      display: 'flex',
      width: '100%'
    }
  })
}

/**
 * Form element which allows to display objectives updates in PDF.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormObjectivesUpdatePdf = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {
  const objectives = value || []

  const headerStyle = { ...styles.headerCell }
  headerStyle.fontWeight = 'bold'

  return (
    <View style={styles.table}>
      {title
        ? (
          <Text style={[styles.title, styles.tableCol]}>{title}</Text>
          )
        : (
          <View />
          )}
      <View style={styles.tableRow}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Objective`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>
            {myI18n?._(t`FORM_OBJECTIVES_UPDATE_IDENTIFIED_NEEDS_CHANGES`)}
          </Text>
        </View>
        <View style={headerStyle}>
          <Text>
            {myI18n?._(t`FORM_OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES`)}
          </Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES`)}</Text>
        </View>
      </View>

      {objectives.map((item, index) => {
        const objUpdateIdentifiedNeedChanges =
          item?.objectivesUpdate?.identifiedNeedChanges || ' '
        const objUpdateDesiredOutcomeChanges =
          item?.objectivesUpdate?.desiredOutcomeChanges || ' '
        const objUpdateActualOutcomes =
          item?.objectivesUpdate?.actualOutcomes || ' '

        return (
          <View key={index} style={styles.tableRow}>
            <View style={headerStyle}>
              <Text>{myI18n._(item.objective)}</Text>
            </View>
            <View style={styles.cell}>
              <Text>{objUpdateIdentifiedNeedChanges}</Text>
            </View>
            <View style={styles.cell}>
              <Text>{objUpdateDesiredOutcomeChanges}</Text>
            </View>
            <View style={styles.cell}>
              <Text>{objUpdateActualOutcomes}</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

import { Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import { ImprovedHtml } from 'app/views/common-components/ImprovedHtml'
import moment from 'moment'
import { pdfDefaultFontSize } from '../../common/Common'

export const FormTextInputPdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  ...props
}) => {
  const { currency, isAdvancedTextEditor } = typeProps
  let valueToShow = value
  if (elementType === 'textInputNumeric' && valueToShow) {
    if (currency && !(isNaN(value) || Boolean(!value && value !== 0))) {
      valueToShow = String(
        langVersion !== 'fr'
          ? new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value)
          : new Intl.NumberFormat('fr-CA', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
            .format(value)
            .split('CA')[0]
      )
    }
  } else if (elementType === 'datePicker' && valueToShow) {
    valueToShow = moment(valueToShow).format(dateFormat)
  } else if (isAdvancedTextEditor || /<\/?[a-z][\s\S]*>/i.test(valueToShow)) {
    valueToShow = valueToShow
      .replace(/<figure class="table">/g, '')
      .replace(/<\/figure>/g, '')
    const tableStyle = `<style>
      td{border: 1px solid black; padding: 5px; font-size: ${pdfDefaultFontSize}}
      table{border: 1px solid black}
      p span{font-size: ${pdfDefaultFontSize} !important}
    </style>`
    const valueToShowWithStyle = tableStyle + valueToShow
    return (
      <View>
        <ImprovedHtml style={{ fontSize: pdfDefaultFontSize }}>
          {valueToShowWithStyle}
        </ImprovedHtml>
      </View>
    )
  }
  
  return (
    <Text style={{ whiteSpace: 'pre-line', fontSize: pdfDefaultFontSize }}>
      {valueToShow}
    </Text>
  )
}

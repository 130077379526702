import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { FastField, useFormikContext } from 'formik'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import { FormErrorLabel } from '../../../common/labels/FormErrorLabel'
import {
  checkShouldUpdateForMultiuserField,
  handleSelectActions,
  parseFormLabelText
} from '../../common/Common'
import MUTextField from '../../multiuser/components/MUTextField'
import { commitChangeToMultipleFields } from '../../multiuser/grpcMultiuserEdit'
import { FormPicklistReadOnly } from './FormPicklistReadOnly'

/**
 * Form element which renders a list of selecable options based on Material UI. Depending on variant it can render either a single-select Radio Group, single-select Dropdown or multi-select Checkbox Group
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {boolean}  [typeProps.readOnly=false] If field should be render in read only view.
 * @param  {string} [typeProps.picklistType='singleselect'] Type of picklist that should be rendered. Avaliable options are: 'singleselect', 'expandable' and 'multiselect'.
 * @param  {boolean}  [typeProps.dynamic] If true, instead of using options defined in editor, the options definitions will be fetched from the Salesforce from connected field.
 * @param  {Object[]} [typeProps.options] Options defined in editor, that will be rendered as avaliable to pick in the element.
 * @param  {number}  [typeProps.maxOptions] For 'multiselect' variant - how many options at max can be selected.
 * @param  {number}  [typeProps.componentWidth] For 'expandable' variant - width in pixels that the field shoud have.
 */
export const FormPicklist = ({
  id,
  tooltip,
  useMultiuser,
  muBag,
  typeProps,
  value,
  disabled,
  langVersion,
  connectedFieldDetails,
  i18n,
  ...props
}) => {
  const {
    options = [],
    picklistType,
    required,
    requiredAll,
    readOnly,
    isConnected,
    componentWidth,
    dynamic,
    maxOptions,
    isRow,
    numberOfColumns
  } = typeProps
  const otherId = 'other.' + id
  const hasTooltip = Boolean(tooltip)
  const { values, setValues } = useFormikContext()

  const selectableOptions =
    dynamic && connectedFieldDetails && connectedFieldDetails.picklistValues
      ? connectedFieldDetails.picklistValues
        .filter(item => item.active)
        .map((item, index) => {
          return {
            title: item.label,
            apiValue: item.value
          }
        })
      : options.map(item => {
        return {
          ...item,
          tooltip: parseFormLabelText({
            text: item.tooltip,
            i18n,
            langVersion
          }),
          title: parseFormLabelText({
            text: item.title,
            i18n,
            langVersion
          })
          // String(item.tooltip || '').replace(/\u00a0/g, ' ')
        }
      })

  const optionsMap = {}
  selectableOptions.forEach((option, index) => {
    const defaultOpt = 'option' + index
    const optionValue = isConnected ? option.apiValue || defaultOpt : defaultOpt
    selectableOptions[index].orgIndex = index
    optionsMap[optionValue] = option
  })
  const isLongOptionTitle = selectableOptions.some(
    option => option.title && option.title.length > 100
  )
  const areMoreColumnsAvailable = picklistType !== 'expandable' && !isRow
  let baseNumberOfColumns

  if (numberOfColumns && numberOfColumns !== 'default') {
    baseNumberOfColumns = Number(numberOfColumns)
  } else if (areMoreColumnsAvailable) {
    if (isLongOptionTitle) {
      baseNumberOfColumns = 1
    } else {
      baseNumberOfColumns = selectableOptions.length > 10 ? 2 : 1
    }
  } else {
    baseNumberOfColumns = 1
  }

  const [numberOfColumnsToUse, setNumberOfColumnsToUse] =
    useState(baseNumberOfColumns)
  const formGroupRef = useRef(null)
  useEffect(() => {
    if (
      numberOfColumns ||
      !areMoreColumnsAvailable ||
      !formGroupRef.current ||
      isLongOptionTitle
    ) { return }

    const resizeObserver = new ResizeObserver(() => {
      const width = formGroupRef.current.clientWidth
      if (width < 800) {
        setNumberOfColumnsToUse(1)
      } else {
        setNumberOfColumnsToUse(selectableOptions?.length > 10 ? 2 : 1)
      }
    })

    resizeObserver.observe(formGroupRef.current)

    return () => resizeObserver.disconnect()
  }, [areMoreColumnsAvailable, numberOfColumnsToUse])

  if (readOnly) {
    return (
      <FormPicklistReadOnly
        {...props}
        title={null}
        id={id}
        typeProps={typeProps}
        value={value}
        disabled={disabled}
        langVersion={langVersion}
        connectedFieldDetails={connectedFieldDetails}
      />
    )
  }

  if (picklistType === 'multiselect') {
    const listValue = value || []
    const clearOptionSelected =
      listValue.length === 1 &&
      selectableOptions.some(item => {
        const index = item.orgIndex
        const defaultOpt = 'option' + index
        const optionValue = isConnected
          ? item.apiValue || defaultOpt
          : defaultOpt
        if (optionValue === listValue[0] && item.clearOnSelect) {
          return true
        } else {
          return false
        }
      })

    return (
      <PicklistMultiselectMemoized
        id={id}
        options={selectableOptions}
        langVersion={langVersion}
        required={required}
        requiredAll={requiredAll}
        disabled={disabled}
        maxOptions={maxOptions}
        isConnected={isConnected}
        clearOptionSelected={clearOptionSelected}
        muBag={muBag}
        useMultiuser={useMultiuser}
        numberOfColumns={numberOfColumnsToUse}
        isRow={isRow}
        formGroupRef={formGroupRef}
        areMoreColumnsAvailable={areMoreColumnsAvailable}
      />
    )
  } else if (picklistType === 'expandable') {
    const requireDetails = optionsMap[value]?.requireDetails
    return (
      <div>
        <MUTextField
          useMultiuser={useMultiuser}
          select
          muBag={muBag}
          id={id}
          style={{
            marginTop: !hasTooltip && 5,
            width: componentWidth
          }}
          fullWidth={!componentWidth}
          variant='outlined'
          disabled={selectableOptions.length === 0 || disabled}
          required={required}
          multiuserEventOnChange
          onChange={e => {
            const toSet = { ...values }
            const oldValue = toSet[id]
            let lastOptionActions = []
            if (oldValue) {
              selectableOptions.some(option => {
                const index = option.orgIndex
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? option.apiValue || defaultOpt
                  : defaultOpt
                if (oldValue === optionValue) {
                  lastOptionActions = option.selectActions
                }
                return oldValue === optionValue
              })
            }
            toSet[id] = e.target.value
            toSet[otherId] = ''
            const modified = handleSelectActions({
              toSet,
              checked: true,
              lastOptionActions,
              selectActions: optionsMap[toSet[id]]?.selectActions
            })
            setValues(toSet)
            if (useMultiuser) {
              commitChangeToMultipleFields({
                array: [...modified, id, otherId].map(id => [id, toSet[id]]),
                ...muBag
              })
            }
          }}
        >
          {selectableOptions.map(item => {
            const index = item.orgIndex
            const defaultOpt = 'option' + index
            const optionValue = isConnected
              ? item.apiValue || defaultOpt
              : defaultOpt
            return (
              <MenuItem key={index} value={optionValue}>
                {item.title}
              </MenuItem>
            )
          })}
        </MUTextField>
        {requireDetails && (
          <div style={{ marginTop: 5 }}>
            <RequireDetailsField
              item={optionsMap[value]}
              useMultiuser={useMultiuser}
              muBag={muBag}
              id={otherId}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    )
  }
  return (
    <PicklistRadiogroupMemoized
      id={id}
      options={selectableOptions}
      langVersion={langVersion}
      required={required}
      disabled={disabled}
      isConnected={isConnected}
      muBag={muBag}
      useMultiuser={useMultiuser}
      otherId={otherId}
      numberOfColumns={numberOfColumnsToUse}
      isRow={isRow}
      formGroupRef={formGroupRef}
      areMoreColumnsAvailable={areMoreColumnsAvailable}
    />
  )
}

export const getPicklistRenderProps = ({
  connectedFieldDetails,
  langVersion,
  typeProps
}) => {
  const { options = [] } = typeProps
  const valueToTrans = {}
  const valueToIndex = {}
  const valueToSFDetails = {}
  options.forEach((opt, index) => {
    const value = opt.apiValue || `option${index}`
    valueToTrans[value] = parseFormLabelText({
      text: opt.title,
      langVersion
    })
    valueToIndex[value] = index
  })
  if (connectedFieldDetails && connectedFieldDetails.picklistValues) {
    connectedFieldDetails.picklistValues.forEach((option, index) => {
      valueToSFDetails[String(option.value).replace(/\s/g, ' ')] = {
        label: option.label,
        connectedField: option.connectedFieldDetails,
        index
      }
    })
  }
  options.forEach((opt, index) => {
    if (valueToSFDetails[opt.apiValue]) {
      valueToSFDetails[opt.apiValue].connectedField = opt.connectedFieldDetails
    }
  })
  return { valueToTrans, valueToIndex, valueToSFDetails }
}

export const formPicklistValueToText = (value = [], question, object = {}) => {
  const { connectedFieldDetails } = object
  const { dynamic } = question.typeProps
  const options =
    dynamic && connectedFieldDetails
      ? connectedFieldDetails.picklistValues
        .filter(item => item.active)
        .map((item, index) => {
          const title = languages.reduce((acc, lang) => {
            acc[lang] = myI18n?._(t`${item.label}`)
            return acc
          }, {})
          return {
            title,
            apiValue: item.value
          }
        })
      : question.typeProps.options

  const selected = languages.reduce((acc, lang) => {
    acc[lang] = []
    return acc
  }, {})
  if (Array.isArray(value)) {
    value.forEach(v => {
      options.some((option, index) => {
        const found = v === 'option' + index || v === option.apiValue
        if (found) {
          languages.forEach(lang => {
            selected[lang].push(option.title[lang])
          })
        }
        return found
      })
    })
    const _selected = languages.reduce((acc, lang) => {
      acc[lang] = (
        <div>
          {selected[lang].map(v => (
            <div>{v}</div>
          ))}
        </div>
      )
      return acc
    }, {})
    return _selected
  } else {
    const singleOption = languages.reduce((acc, lang) => {
      acc[lang] = ''
      return acc
    }, {})
    options.some((option, index) => {
      let found
      if (option.apiValue) {
        found = value === option.apiValue
      } else {
        found = value === 'option' + index
      }
      if (found) {
        languages.forEach(lang => {
          singleOption[lang] = option.title[lang]
        })
      }
      return found
    })
    const _singleOption = languages.reduce((acc, lang) => {
      acc[lang] = <div>{singleOption[lang]}</div>
      return acc
    }, {})
    return _singleOption
  }
}

const PicklistRadiogroupMemoized = ({
  id,
  options,
  langVersion,
  required,
  disabled,
  isConnected,
  otherId,
  muBag,
  useMultiuser,
  numberOfColumns,
  isRow,
  formGroupRef,
  areMoreColumnsAvailable
}) => {
  const manipulatesOtherFields = options.some(
    item => item.requireDetails || Array.isArray(item.selectActions)
  )
  const adjustForMoreColumns =
    areMoreColumnsAvailable && numberOfColumns && numberOfColumns > 1

  return (
    <FastField
      name={id}
      {...muBag}
      useMultiuser={useMultiuser}
      langVersion={langVersion}
      required={required}
      disabled={disabled}
      isConnected={isConnected}
      numberOfColumns={numberOfColumns}
      shouldUpdate={(nextProps, currentProps) => {
        let checkValuesBool = false
        if (manipulatesOtherFields) {
          checkValuesBool = !_.isEqual(
            nextProps.formik.values,
            currentProps.formik.values
          )
        }
        return (
          checkShouldUpdateForMultiuserField(nextProps, currentProps) ||
          nextProps.clearOptionSelected !== currentProps.clearOptionSelected ||
          nextProps.langVersion !== currentProps.langVersion ||
          checkValuesBool ||
          nextProps.numberOfColumns !== currentProps.numberOfColumns ||
          nextProps.name !== currentProps.name
        )
      }}
    >
      {({ field, meta, form }) => {
        const { setValues, setFieldValue, values } = form
        const isError = Boolean(meta.touched && meta.error)

        return (
          <FormControl component='fieldset' style={{ width: '100%' }}>
            <RadioGroup
              ref={formGroupRef}
              style={{
                width: '100%',
                ...(adjustForMoreColumns && {
                  flexDirection: 'row',
                  marginLeft: 25,
                  justifyContent: 'flex-start'
                }),
                ...(isRow && { flexDirection: 'row', flexWrap: 'wrap' })
              }}
              value={field.value || ''}
            >
              {options.map(item => {
                const { title, tooltip, orgIndex } = item
                const index = orgIndex
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? item.apiValue || defaultOpt
                  : defaultOpt
                const width = isRow
                  ? 'auto'
                  : adjustForMoreColumns
                    ? numberOfColumns === 2
                      ? '45%'
                      : numberOfColumns === 3
                        ? '30%'
                        : '100%'
                    : '100%'

                return (
                  <>
                    <FormControlLabel
                      style={{
                        width,
                        marginBottom: 10
                      }}
                      key={index}
                      disabled={disabled}
                      value={optionValue}
                      control={
                        <Radio
                          style={{
                            paddingLeft: 9,
                            paddingRight: 9,
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                          onChange={e => {
                            const toSet = { ...values }
                            const oldValue = toSet[id]

                            if (manipulatesOtherFields) {
                              let lastOptionActions = []
                              if (oldValue) {
                                options.some(option => {
                                  const index = option.orgIndex
                                  const defaultOpt = 'option' + index
                                  const optionValue = isConnected
                                    ? option.apiValue || defaultOpt
                                    : defaultOpt
                                  if (oldValue === optionValue) {
                                    lastOptionActions = option.selectActions
                                  }
                                  return oldValue === optionValue
                                })
                              }
                              toSet[id] = e.target.value
                              toSet[otherId] = ''
                              const modified = handleSelectActions({
                                toSet,
                                checked: true,
                                selectActions: item.selectActions,
                                lastOptionActions
                              })
                              setValues(toSet)
                              if (useMultiuser) {
                                commitChangeToMultipleFields({
                                  array: [...modified, id, otherId].map(id => [
                                    id,
                                    toSet[id]
                                  ]),
                                  ...muBag
                                })
                              }
                            } else {
                              setFieldValue(id, e.target.value)
                              if (useMultiuser) {
                                commitChangeToMultipleFields({
                                  array: [[id, e.target.value]],
                                  ...muBag
                                })
                              }
                            }
                          }}
                        />
                      }
                      label={
                        <div>
                          {title}
                          {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                        </div>
                      }
                    />
                    {Boolean(
                      field.value === optionValue && item.requireDetails
                    ) && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          width: adjustForMoreColumns && '100%'
                        }}
                      >
                        <RequireDetailsField
                          item={item}
                          useMultiuser={useMultiuser}
                          muBag={muBag}
                          id={'other.' + id}
                          disabled={disabled}
                        />
                      </div>
                    )}
                  </>
                )
              })}
            </RadioGroup>
            <FormErrorLabel error={isError} id={id} required={required} />
          </FormControl>
        )
      }}
    </FastField>
  )
}

const PicklistMultiselectMemoized = ({
  id,
  options,
  langVersion,
  required,
  requiredAll,
  disabled,
  maxOptions,
  isConnected,
  clearOptionSelected,
  muBag,
  useMultiuser,
  numberOfColumns,
  formGroupRef,
  isRow,
  areMoreColumnsAvailable
}) => {
  const manipulatesOtherFields = options.some(
    item => item.requireDetails || Array.isArray(item.selectActions)
  )

  const adjustForMoreColumns =
    areMoreColumnsAvailable && numberOfColumns && numberOfColumns > 1

  return (
    <FastField
      name={id}
      {...muBag}
      useMultiuser={useMultiuser}
      langVersion={langVersion}
      required={required}
      disabled={disabled}
      maxOptions={maxOptions}
      isConnected={isConnected}
      clearOptionSelected={clearOptionSelected}
      options={options}
      numberOfColumns={numberOfColumns}
      shouldUpdate={(nextProps, currentProps) => {
        let checkValuesBool = false
        if (manipulatesOtherFields) {
          checkValuesBool = !_.isEqual(
            nextProps.formik.values,
            currentProps.formik.values
          )
        }
        return (
          checkShouldUpdateForMultiuserField(nextProps, currentProps) ||
          nextProps.clearOptionSelected !== currentProps.clearOptionSelected ||
          nextProps.langVersion !== currentProps.langVersion ||
          nextProps.options !== currentProps.options ||
          checkValuesBool ||
          nextProps.numberOfColumns !== currentProps.numberOfColumns ||
          nextProps.name !== currentProps.name
        )
      }}
    >
      {({ field, meta, form }) => {
        const { setValues, setFieldTouched, values, setFieldValue } = form
        const isError = Boolean(meta.touched && meta.error)
        const listValue = field.value || []
        return (
          <FormControl component='fieldset' style={{ width: '100%' }}>
            <FormGroup
              ref={formGroupRef}
              style={{
                width: '100%',
                ...(adjustForMoreColumns && {
                  flexDirection: 'row',
                  marginLeft: 25,
                  justifyContent: 'flex-start'
                }),
                ...(isRow && { flexDirection: 'row', flexWrap: 'wrap' })
              }}
            >
              {options.map(item => {
                const { requireDetails, title, tooltip } = item
                const index = item.orgIndex
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? item.apiValue || defaultOpt
                  : defaultOpt
                let disableOption =
                  disabled ||
                  Boolean(clearOptionSelected && listValue[0] !== optionValue)
                const checked = listValue.includes(optionValue)
                if (!disableOption && maxOptions) {
                  disableOption =
                    listValue.length >= Number(maxOptions) && !checked
                }
                const width = isRow
                  ? 'auto'
                  : adjustForMoreColumns
                    ? numberOfColumns === 2
                      ? '45%'
                      : numberOfColumns === 3
                        ? '30%'
                        : '100%'
                    : '100%'

                return (
                  <Grid
                    container
                    direction='column'
                    key={index}
                    style={{ width }}
                  >
                    <FormControlLabel
                      style={{
                        marginBottom: 10
                      }}
                      disabled={disableOption}
                      key={index}
                      value={optionValue}
                      onChange={e => {
                        const { value } = e.currentTarget
                        if (!meta.touched) {
                          setFieldTouched(id)
                        }
                        const newValues = { ...values }
                        const oldValue = [...listValue]
                        let newList = [...listValue]
                        const otherId = 'other.' + id + '.' + index
                        let modified = []
                        if (newList.includes(value)) {
                          newList.splice(newList.indexOf(value), 1)
                          if (requireDetails) {
                            newValues[otherId] = ''
                          }
                        } else {
                          if (item.clearOnSelect) {
                            newList = [value]
                          } else {
                            newList.push(value)
                          }
                        }

                        if (manipulatesOtherFields) {
                          newValues[id] = newList
                          options.some(option => {
                            const index = option.orgIndex
                            const defaultOpt = 'option' + index
                            const optionValue = isConnected
                              ? option.apiValue || defaultOpt
                              : defaultOpt
                            const wasSelected =
                              oldValue.includes(optionValue) &&
                              !newValues[id].includes(optionValue)
                            if (wasSelected) {
                              modified = handleSelectActions({
                                toSet: newValues,
                                checked: newValues[id].includes(value),
                                lastOptionActions: option.selectActions,
                                selectActions: item.selectActions
                              })
                            }
                            return wasSelected
                          })
                          setValues(newValues)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [...modified, id, otherId].map(id => [
                                id,
                                newValues[id]
                              ]),
                              ...muBag
                            })
                          }
                        } else {
                          setFieldValue(id, newList)
                          if (useMultiuser) {
                            commitChangeToMultipleFields({
                              array: [[id, newList]],
                              ...muBag
                            })
                          }
                        }
                      }}
                      checked={checked}
                      control={
                        <Checkbox
                          style={{
                            paddingLeft: 9,
                            paddingRight: 9,
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                        />
                      }
                      label={
                        <div>
                          {title || ''}
                          {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                        </div>
                      }
                    />
                    {Boolean(
                      listValue.includes(optionValue) && requireDetails
                    ) && (
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5
                        }}
                      >
                        <RequireDetailsField
                          item={item}
                          useMultiuser={useMultiuser}
                          muBag={muBag}
                          id={'other.' + id + '.' + index}
                          disabled={disabled}
                          displayFieldHistoryIcon
                        />
                      </div>
                    )}
                  </Grid>
                )
              })}
            </FormGroup>
            <FormErrorLabel
              error={isError}
              id={id}
              required={required}
              label={
                requiredAll && <Trans>You must select all checkboxes!</Trans>
              }
            />
          </FormControl>
        )
      }}
    </FastField>
  )
}

const RequireDetailsField = ({ item, ...props }) => {
  let specifyLimit
  if (item && item.connectedFieldDetails) {
    specifyLimit = item.connectedFieldDetails.length
  }
  return (
    <>
      <Trans>Please, specify: </Trans>
      <MUTextField
        {...props}
        // inputProps={{
        //   maxLength: specifyLimit && Number(specifyLimit)
        // }}
        limit={specifyLimit}
      />
    </>
  )
}

import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import moment from 'moment'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormMilestonesPdf = ({
  value = [],
  title,
  langVersion,
  typeProps = {}
}) => {
  const fontFamily =
    langVersion === 'iu-cans'
      ? inuktitutFormPdfFontFamily
      : defaultFormPdfFontFamily

  const styles = StyleSheet.create({
    headerCell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      textAlign: 'center',
      flex: 1,
      fontFamily
    },
    cell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      flex: 1,
      fontFamily
    },
    row: {
      ...pdfTableStyles.tableRow,
      width: '100%'
    }
  })

  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    phases = []
  } = typeProps

  const headerStyle = { ...styles.headerCell }
  headerStyle.alignItems = 'flex-start'
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  headerStyle.fontWeight = 'bold'
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }

  return (
    <View style={pdfTableStyles.table}>
      <View style={styles.row}>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Stage`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`Start Date`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n?._(t`End Date`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2.25 }}>
          <Text>{myI18n?._(t`Primary Activities`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2.25 }}>
          <Text>{myI18n?._(t`Comments`)}</Text>
        </View>
      </View>
      {phases
        .filter((phase, index) => value[index])
        .map((phase, index) => {
          const milestone = value[index]

          return (
            <View style={styles.row} key={index}>
              <View style={[styles.cell, { fontWeight: 'bold' }]}>
                <Text>
                  {getLabelFromTranslationData({
                    data: phase.label,
                    langVersion
                  }) || myI18n._(phase.apiValue)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text>
                  {milestone.startDate &&
                    moment.utc(milestone.startDate).format(dateFormat)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text>
                  {milestone.endDate &&
                    moment.utc(milestone.endDate).format(dateFormat)}
                </Text>
              </View>
              <View style={{ ...styles.cell, flex: 2.25 }}>
                <Text>{milestone.primaryActivities}</Text>
              </View>
              <View style={{ ...styles.cell, flex: 2.25 }}>
                <Text>{milestone.comments}</Text>
              </View>
            </View>
          )
        })}
    </View>
  )
}

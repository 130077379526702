import { Trans, t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Link,
  TextField
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import {
  addNewAction,
  changeAction,
  moveToColumn,
  removeAction,
  removeAllActions,
  setInitialState
} from 'app/redux/actions/ActionPlanActions'
import {
  INTERNAL_USER,
  createAction,
  deleteAction,
  getActions,
  updateAction,
  updateActionsOrder
} from 'app/services/sfAuth/sfData/sfActionPlan'
import { getActionTemplatesFull } from 'app/services/sfAuth/sfData/sfActionTemplates'
import { findSurveyTemplatesWithChildren } from 'app/services/sfAuth/sfData/sfSurvey'
import Loading from 'egret/components/EgretLoadable/Loading'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
import { useSnackbar, withSnackbar } from 'notistack'
import React, { Component, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import sfOauthConfig from '../../../services/sfAuth/sfAuthConfig'
import ActionDoneDialog from './ActionDoneDialog'
import ActionPlanColumn from './ActionPlanColumn'
import ActionPlanDialog from './ActionPlanDialog'
import ActionPlanToPrint from './ActionPlanToPrint'
import ActionPlanToPrintPdf from './ActionPlanToPrintPdf'
import ViewAllActionsButton from './ViewAllActionsButtons'

export const columnsInOrder = ['Selected', 'In Progress', 'Done', 'On hold']
export const columnsTranslations = [
  t`Selected`,
  t`In Progress`,
  t`Done`,
  t`On hold`
]
export const INDEX_OF_SELECTED = 0
export const INDEX_OF_IN_PROGRESS = 1
export const INDEX_OF_DONE = 2
export const INDEX_OF_FAILED = 3

const exportActionPlan = ({ data, myI18n }) => {
  const workbook = new Excel.Workbook()
  const downloadDate = moment.utc().format(dateFormat)

  const filename = myI18n._(t`ActionPlan-${downloadDate}`)

  const sheet = workbook.addWorksheet(filename, {
    // views: [{ state: 'frozen', xSplit: 2, ySplit: 1 }]
  })
  console.log('exportActionPlan', data)
  const mapActionStatus = column => {
    if (column === 'Selected') {
      return t`[APExcel]Not Started`
    } else if (column === 'Done') {
      return t`[APExcel]Completed`
    } else {
      return '[APExcel]' + column
    }
  }

  const mapCategory = category => {
    if (category === 'Property Management') {
      return t`Asset Management`
    } else if (
      category === 'Financial Managment' ||
      category === 'Financial Management'
    ) {
      return t`Financial Viability`
    } else if (category === 'Governance') {
      return t`Governance`
    } else if (category === 'Social and community relations') {
      return t`Social Inclusion`
    } else {
      return t`Other`
    }
  }

  sheet.columns = [
    // { header: myI18n._(t`Client Program`), width: 25, key: 'clientProgram' }, // blank
    // { header: myI18n._(t`Organization`), width: 25, key: 'organization' }, // blank
    { header: myI18n._(t`Name`), width: 25, key: 'name' }, // Action Name (English version, or whatever the client enters if it’s a manually created action)
    {
      header: myI18n._(t`Observation Category`),
      width: 25,
      key: 'observationCategory'
    }, // Category - Property management should be exported as "Asset Management" , Financial management should be "Financial Viability", Social and Community Relations should be "Social Inclusion", None should be "Other", and Governance is fine written as Governance
    { header: myI18n._(t`Description`), width: 25, key: 'description' }, // Should be the actions's Description (EN) followed by ". Expected outcome:" then the action's Expected outcome (EN).
    { header: myI18n._(t`Observation`), width: 25, key: 'observation' }, // Actual Outcome, if there is one entered by the user on the action plan
    { header: myI18n._(t`Recommendations`), width: 25, key: 'recommendations' }, // blank
    // { header: myI18n._(t`Program`), width: 25, key: 'program' }, // blank
    { header: myI18n._(t`Start Date`), width: 25, key: 'startDate' }, // createdDate or better date of selected
    { header: myI18n._(t`End Date`), width: 25, key: 'endDate' }, // dueDate
    { header: myI18n._(t`Status Reason`), width: 25, key: 'status' }, // Status of the action - Selected should read as "Not started", Done should read "Completed", and On Hold and In progress should stay the same
    { header: myI18n._(t`Created On`), width: 25, key: 'createdOn' } // download date
  ]

  /*
             title: action.Title__c || '',
             id: action.Id,
             order: action.Order__c,
             reason: action.Reason__c,
             dueDate: action.Date__c,
             column: action.Status__c,
             createdDate: action.CreatedDate,
             description: action.Description__c,
             success: action.Success__c,
             expectedOutcome: action.ExpectedOutcome__c,
             actualOutcome: action.ActualOutcome__c,
             failReason: action.ReasonOfFail__c
   */
  for (let i = 0; i < data.columns.length; i++) {
    for (const action of data.columns[i].actions) {
      const { description, expectedOutcome } = action
      sheet.addRow({
        name: action.title,
        sheet,
        observationCategory: myI18n._(mapCategory(action.category)),
        description:
          description +
          '.\n ' +
          myI18n._(t`Expected Outcome`) +
          ': ' +
          expectedOutcome,
        observation: action.actualOutcome,
        startDate: moment.utc(action.createdDate).format(dateFormat),
        endDate: moment.utc(action.dueDate).format(dateFormat),
        status: myI18n._(mapActionStatus(action.column)),
        createdOn: downloadDate
      })
    }
  }
  let rowIndex = 1
  for (rowIndex; rowIndex <= sheet.rowCount; rowIndex++) {
    sheet.getRow(rowIndex).alignment = {
      wrapText: true,
      vertical: 'top'
    }
  }

  const buff = workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    saveAs(blob, filename + '.xlsx')
  })
  return buff
}

const columnsData = [
  {
    title: 'Selected',
    actions: []
  },
  {
    title: 'In Progress',
    actions: []
  },
  {
    title: 'Done',
    actions: []
  },
  {
    title: 'On hold',
    actions: []
  }
]

class ActionPlan extends Component {
  constructor (props) {
    super(props)
    this.handleActionDelete = this.handleActionDelete.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.printRef = React.createRef()
    this.fetchData()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.organization) {
      if (prevProps.organization !== this.props.organization) {
        this.props.setInitialState({ columns: columnsData })
        this.setState({ loaded: false })
        this.fetchData()
      }
    }
  }

  fetchData = () => {
    const { configuration, user, organization, simulateInternal } = this.props
    const isInternal = simulateInternal || sfOauthConfig.isInternal

    Promise.all([
      getActions(
        // if internal override user so the PM can download action Plan
        isInternal ? INTERNAL_USER : user.userId,
        organization.id
      ),
      getActionTemplatesFull(),
      findSurveyTemplatesWithChildren({
        language: user.language
      })
    ]).then(([result, actionTemplates, surveyTemplatesMap]) => {
      const surveysIdsMap = {
        'Co-op': [],
        OSBL: []
      }
      if (configuration.SAT_COOPS_SURVEY) {
        surveysIdsMap['Co-op'] = [
          ...surveyTemplatesMap[configuration.SAT_COOPS_SURVEY].children.map(
            obj => obj.id
          ),
          surveyTemplatesMap[configuration.SAT_COOPS_SURVEY].id
        ]
      }
      if (configuration.SAT_OSBL_SURVEY) {
        surveysIdsMap.OSBL = [
          ...surveyTemplatesMap[configuration.SAT_OSBL_SURVEY].children.map(
            obj => obj.id
          ),
          surveyTemplatesMap[configuration.SAT_OSBL_SURVEY].id
        ]
      }
      if (!this.props.organization.id) {
        this.props.setInitialState({ columns: columnsData })
        this.setState({ loaded: true })
        return
      }
      const toUpdate = []
      const toSet = _.cloneDeep(columnsData)
      result.forEach(action => {
        const savedAction = {
          title: action.Title__c || '',
          id: action.Id,
          order: action.Order__c,
          reason: action.Reason__c,
          dueDate: action.Date__c,
          column: action.Status__c,
          createdDate: action.CreatedDate,
          description: action.Description__c,
          success: action.Success__c,
          expectedOutcome: action.ExpectedOutcome__c,
          actualOutcome: action.ActualOutcome__c,
          fromTemplate: action.From_Template__c,
          // category: action && action.From_Template__r ? action.From_Template__r.Category__c : '',
          category: action.Category__c,
          failReason: action.ReasonOfFail__c
        }
        // if (!savedAction.category || !savedAction.fromTemplate) {
        //   // Fix for bug resulting in fromTemplate and category not being set
        //   const template =
        //     actionTemplatesMapEn[savedAction.title] ||
        //     actionTemplatesMapFr[savedAction.title]
        //   if (template) {
        //     savedAction.category = template.category
        //     savedAction.fromTemplate = template.id
        //     toUpdate.push({
        //       Id: savedAction.id,
        //       Category__c: savedAction.category,
        //       From_Template__c: savedAction.fromTemplate
        //     })
        //   }
        // }
        let columnIndex = columnsInOrder.indexOf(action.Status__c)
        if (columnIndex === -1) {
          columnIndex = 0
          savedAction.order = -1
          savedAction.column = 'Selected'
        }
        if (columnIndex === -1 && action.Status__c === 'Failed') {
          columnIndex = 3
          savedAction.order = -1
          savedAction.column = 'On hold'
        }
        toSet[columnIndex].actions.push(savedAction)
      })
      // if (toUpdate.length > 0) {
      //   updateActionBug(toUpdate).then(result => {
      //     console.log('invalid actions detected and fixed: ', result)
      //   })
      // }
      toSet.forEach(column => {
        column.actions.sort((a, b) => {
          return a.order - b.order
        })
      })
      this.props.setInitialState({ columns: toSet })
      this.setState({ loaded: true, actionTemplates, surveysIdsMap })
    })
  }

  state = {
    data: null,
    createCardColumnIndex: null,
    deletingActionProps: null,
    deletingActionIds: [],
    droppedOnFailedProps: null,
    failReason: '',
    minimizedColumns: [3],
    shouldOpenDialog: false
  }

  onDragEnd = result => {
    const { source, destination } = result
    const columns = [...this.props.actionPlan.columns]
    if (!destination) {
      return
    }
    let targetColumnIndex =
      result.targetColumnIndex || result.targetColumnIndex === 0
        ? result.targetColumnIndex
        : null
    let sourceColumnIndex =
      result.sourceColumnIndex || result.sourceColumnIndex === 0
        ? result.sourceColumnIndex
        : null

    columns.forEach((board, index) => {
      if (board.title === destination.droppableId) {
        targetColumnIndex = index
      }
      if (board.title == source.droppableId) {
        sourceColumnIndex = index
      }
    })

    if (
      source.index === destination.index &&
      sourceColumnIndex === targetColumnIndex
    ) {
      return
    }

    if (targetColumnIndex === INDEX_OF_FAILED) {
      this.setState({
        droppedOnFailedProps: {
          source: { ...source, sourceBoardIndex: sourceColumnIndex },
          destination: { ...destination, targetBoardIndex: targetColumnIndex }
        }
      })
      this.props.moveToColumn({
        movedFromColumn: sourceColumnIndex,
        movedToColumn: targetColumnIndex,
        movedIndex: source.index,
        moveToIndex: destination.index
      })
      return
    }

    if (targetColumnIndex === INDEX_OF_DONE) {
      this.setState({
        droppedOnDoneProps: {
          source: { ...source, sourceBoardIndex: sourceColumnIndex },
          action: columns[sourceColumnIndex].actions[source.index],
          destination: { ...destination, targetBoardIndex: targetColumnIndex }
        }
      })
      this.props.moveToColumn({
        movedFromColumn: sourceColumnIndex,
        movedToColumn: targetColumnIndex,
        movedIndex: source.index,
        moveToIndex: destination.index
      })
      return
    }
    const toMove = { ...columns[sourceColumnIndex].actions[source.index] }
    toMove.column = columnsInOrder[targetColumnIndex]
    toMove.failReason = null
    toMove.success = null
    toMove.actualOutcome = null
    delete toMove.order
    this.props.moveToColumn({
      movedFromColumn: sourceColumnIndex,
      movedToColumn: targetColumnIndex,
      movedIndex: source.index,
      moveToIndex: destination.index
    })
    Promise.all([
      this.reorderColumn(columns, sourceColumnIndex, targetColumnIndex),
      updateAction(toMove)
    ]).then(
      res => {
        this.props.enqueueSnackbar(
          <div>
            {toMove.title} <Trans>updated!</Trans>
          </div>,
          {
            variant: 'success'
          }
        )
        this.props.changeAction({
          actionToChange: toMove,
          columnIndex: targetColumnIndex,
          actionIndex: destination.index
        })
      },
      reject => {
        this.props.enqueueSnackbar(
          <div>
            {toMove.title} <Trans>couldn't be updated!</Trans>
          </div>,
          {
            variant: 'error'
          }
        )
        this.props.moveToColumn({
          movedFromColumn: targetColumnIndex,
          movedToColumn: sourceColumnIndex,
          movedIndex: destination.index,
          moveToIndex: source.index
        })
      }
    )
  }

  reorderColumn = (data, firstIndex, secondIndex = null) => {
    const toUpdate = []
    data[firstIndex].actions.forEach((action, index) => {
      toUpdate.push({ Id: action.id, Order__c: index })
    })
    if (secondIndex && firstIndex !== secondIndex) {
      data[secondIndex].actions.forEach((action, index) => {
        toUpdate.push({ Id: action.id, Order__c: index })
      })
    }
    return updateActionsOrder(toUpdate)
  }

  handleAddNewCard = createData => {
    const columns = [...this.props.actionPlan.columns]
    createAction({
      ...createData,
      column: columns[INDEX_OF_SELECTED].title,
      orgId: this.props.organization.id,
      order: columns[INDEX_OF_SELECTED].actions.length
    }).then(result => {
      const newAction = {
        title: createData.title,
        id: result.id,
        category: createData.category,
        order: columns[INDEX_OF_SELECTED].actions.length,
        column: columns[INDEX_OF_SELECTED].title,
        fromTemplate: createData.fromTemplate,
        reason: createData.reason,
        expectedOutcome: createData.expectedOutcome,
        dueDate: createData.dueDate ? createData.dueDate : moment.utc(),
        description: createData.description
      }
      this.props.addNewAction({ actionToAdd: newAction })
      this.props.enqueueSnackbar(<Trans>Action created!</Trans>, {
        variant: 'success'
      })
    })
  }

  handleOpenDialog = (card, create = false) => {
    this.setState({
      shouldOpenDialog: true,
      card,
      create
    })
  }

  handlePostponeAction = (days, columnIndex, actionIndex) => {
    const toPostpone = {
      ...this.props.actionPlan.columns[columnIndex].actions[actionIndex]
    }
    const toModify = moment.utc(toPostpone.dueDate)
    toPostpone.dueDate = toModify.add(days, 'days')
    updateAction(toPostpone).then(
      result => {
        this.props.enqueueSnackbar(
          <div>
            {toPostpone.title} <Trans>postponed!</Trans>
          </div>,
          {
            variant: 'success'
          }
        )
        this.props.changeAction({
          actionToChange: toPostpone,
          columnIndex,
          actionIndex
        })
      },
      reject => {
        this.props.enqueueSnackbar(
          <Trans>Error occured while postponing action!</Trans>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  handleDeleteDialogOpen = (card, actionIndex, column) => {
    this.setState({
      deletingActionProps: {
        title: card.title,
        index: actionIndex,
        columnIndex: column
      }
    })
  }

  handleActionDelete = (actionIndex, deleteFromColumn) => {
    const { deletingActionProps, deletingActionIds } = this.state
    const columnIndex = !isNaN(deleteFromColumn)
      ? deleteFromColumn
      : deletingActionProps.columnIndex
    const toDelete = {
      ...this.props.actionPlan.columns[columnIndex].actions[actionIndex]
    }
    this.setState({ deletingActionIds: [...deletingActionIds, toDelete.id] })
    deleteAction(toDelete.id).then(
      result => {
        this.setState(prevState => ({
          deletingActionProps: null,
          deletingActionIds: prevState.deletingActionIds.filter(
            id => id !== toDelete.id
          )
        }))
        this.props.enqueueSnackbar(
          <div>
            {toDelete.title} <Trans>deleted!</Trans>
          </div>,
          {
            variant: 'success'
          }
        )
        this.props.removeAction({
          columnIndex,
          actionIndex
        })
        this.reorderColumn(this.props.actionPlan.columns, columnIndex)
      },
      reject => {
        this.setState(prevState => ({
          deletingActionProps: null,
          deletingActionIds: prevState.deletingActionIds.filter(
            id => id !== toDelete.id
          )
        }))
        this.props.enqueueSnackbar(
          <div>
            <Trans>Error while deleting</Trans> {toDelete.title}!
          </div>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  handleActionSave = (
    action,
    columnIndex,
    actionIndex,
    reorderIndex = null
  ) => {
    const actionToSave = { ...action }
    const columns = { ...this.props.actionPlan.columns }
    const promises = [updateAction(actionToSave)]
    if (reorderIndex) {
      promises.push(this.reorderColumn(columns, columnIndex, reorderIndex))
    }
    Promise.all(promises).then(
      result => {
        this.props.changeAction({
          actionToChange: actionToSave,
          columnIndex,
          actionIndex
        })
        this.handleDialogClose()
        this.props.enqueueSnackbar(
          <div>
            {actionToSave.title} <Trans>updated!</Trans>
          </div>,
          {
            variant: 'success'
          }
        )
      },
      reject => {
        this.props.enqueueSnackbar(
          <Trans>Error occured while saving action!</Trans>,
          {
            variant: 'error'
          }
        )
      }
    )
  }

  handleReasonOnType = event => {
    this.setState({ failReason: event.target.value })
  }

  handleDialogClose = () => {
    this.setState({
      shouldOpenDialog: false,
      droppedOnDoneProps: null,
      droppedOnFailedProps: null
    })
  }

  handleColumnMinimize = (state, index) => {
    const newColumns = [...this.state.minimizedColumns]
    if (newColumns.indexOf(index) !== -1 && !state) {
      newColumns.splice(newColumns.indexOf(index), 1)
    } else {
      newColumns.push(index)
    }
    this.setState({ minimizedColumns: newColumns })
  }

  render () {
    const {
      shouldOpenDialog,
      surveysIdsMap,
      card,
      deletingActionIds,
      droppedOnFailedProps,
      droppedOnDoneProps,
      deletingActionProps,
      actionTemplates,
      loaded
    } = this.state
    const columns = this.props.actionPlan.columns
    console.log('columns', columns)

    const allActions = []
    columns.forEach(column => {
      column.actions.forEach(action => {
        allActions.push(action)
      })
    })

    return loaded ? (
      <div
        style={{
          height: '100%',
          padding: 20,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {droppedOnFailedProps && (
          <Dialog open={Boolean(droppedOnFailedProps)}>
            <h4 style={{ textAlign: 'center', padding: 20, minWidth: 400 }}>
              <Trans>Reason for task hold</Trans>
            </h4>
            <div style={{ margin: 15 }}>
              <TextField
                className='text-muted'
                onChange={this.handleReasonOnType}
                label={<Trans>Reason</Trans>}
                name='reason'
                variant='outlined'
                fullWidth
                multiline
              />
            </div>
            <Grid container direction='row' justify='space-around'>
              <Button
                variant='contained'
                disabled={this.state.failReason.length < 1}
                color='primary'
                style={{
                  marginBottom: 10
                }}
                onClick={() => {
                  const actionIndex = droppedOnFailedProps.destination.index
                  const toUpdate = {
                    ...this.props.actionPlan.columns[INDEX_OF_FAILED].actions[
                      actionIndex
                    ]
                  }
                  toUpdate.failReason = this.state.failReason
                  toUpdate.success = null
                  toUpdate.actualOutcome = null
                  toUpdate.column = 'On hold'
                  console.log(droppedOnFailedProps)
                  this.handleActionSave(
                    toUpdate,
                    INDEX_OF_FAILED,
                    actionIndex,
                    droppedOnFailedProps.source.sourceBoardIndex
                  )
                }}
              >
                <Icon style={{ marginRight: 5 }}>check</Icon>
                <Trans>Save</Trans>
              </Button>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginBottom: 10
                }}
                onClick={() => {
                  this.props.moveToColumn({
                    movedFromColumn: INDEX_OF_FAILED,
                    movedToColumn: droppedOnFailedProps.source.sourceBoardIndex,
                    movedIndex: droppedOnFailedProps.destination.index,
                    moveToIndex: droppedOnFailedProps.source.index
                  })
                  this.handleDialogClose()
                }}
              >
                <Icon style={{ marginRight: 5 }}>close</Icon>
                <Trans>Cancel</Trans>
              </Button>
            </Grid>
          </Dialog>
        )}
        {droppedOnDoneProps && (
          <Dialog open={Boolean(droppedOnDoneProps)}>
            <ActionDoneDialog
              {...droppedOnDoneProps}
              closeDialog={this.handleDialogClose}
              moveToColumn={this.props.moveToColumn}
              handleSave={this.handleActionSave}
              data={this.state.data}
            />
          </Dialog>
        )}
        {Boolean(deletingActionProps &&
        !deletingActionIds.includes(deletingActionProps.id)) && (
          <Dialog open={Boolean(deletingActionProps)}>
            <h4 style={{ padding: 20, textAlign: 'center' }}>
              <Trans>Are you sure you want to delete</Trans>
              <br />
              <b>{deletingActionProps.title}</b>?
            </h4>
            <Grid container direction='row' justify='space-around'>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginBottom: 10
                }}
                onClick={() => {
                  this.handleActionDelete(deletingActionProps.index)
                }}
              >
                <Icon style={{ marginRight: 5 }}>check</Icon>
                <Trans>Yes</Trans>
              </Button>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginBottom: 10
                }}
                onClick={() => {
                  this.setState({ deletingActionProps: null })
                }}
              >
                <Icon style={{ marginRight: 5 }}>close</Icon>
                <Trans>No</Trans>
              </Button>
            </Grid>
          </Dialog>
        )}
        {!this.props.organization.id && (
          <div>
            <Alert severity='warning' style={{ marginTop: 10 }}>
              <Trans>
                Before creating actions for an action plan you have to associate
                an organisation with your account
              </Trans>{' '}
              <Link href='/grants/Organizations'>
                <Trans>You can connect to an organization here</Trans>
              </Link>
            </Alert>
          </div>
        )}
        <Grid container direction='row' alignItems='center'>
          <Button
            disabled={!this.props.organization.id}
            variant='contained'
            color='primary'
            style={{
              zIndex: 10
            }}
            onClick={() => {
              this.handleOpenDialog(null, true)
            }}
          >
            <Icon style={{ marginRight: 5 }}>add</Icon>
            <Trans>Add new action</Trans>
          </Button>
          <ViewAllActionsButton
            handleActionDelete={this.handleActionDelete}
            handleAddNewCard={this.handleAddNewCard}
            surveysIdsMap={surveysIdsMap}
            templates={actionTemplates}
            columns={columns}
            color='primary'
            style={{
              marginLeft: 5
            }}
          />
          {allActions.length > 0 && (
            <DeleteAllActionsButton
              actions={allActions}
              orgId={this.props.organization.id}
              handleDeleted={() => {
                this.props.removeAllActions()
              }}
            />
          )}
          <h1
            style={{
              textAlign: 'center',
              marginBottom: 20,
              marginTop: 20,
              flexGrow: 1
            }}
          >
            <Trans>Action Plan</Trans>
          </h1>
          <I18n>
            {({ i18n }) => {
              columns.forEach(column => {
                column.title = i18n._(
                  columnsTranslations[columns.indexOf(column)]
                )
              })
              return (
                <>
                  {/* <ReactToPrint
                  onAfterPrint={() => (document.title = defaultDocTitle)}
                  onBeforePrint={() =>
                    (document.title = i18n._(t`Action Plan`))
                  }
                  trigger={() => (
                    <Button
                      variant='contained'
                      color='primary'
                    >
                      <Trans>Print</Trans>
                      <Icon
                        style={{
                          marginLeft: 5
                        }}
                      >
                        print
                      </Icon>
                    </Button>
                  )}
                  content={() => this.printRef.current}
                /> */}

                  <PDFDownloadLink
                    document={
                      <ActionPlanToPrintPdf columns={columns} i18n={i18n} />
                    }
                    fileName={`${i18n._(t`Action Plan`)}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button variant='contained' color='primary'>
                        <Trans>ACTION_PLAN_DOWNLOAD_PDF</Trans>
                        <Icon
                          style={{
                            marginLeft: 5
                          }}
                        >
                          download
                        </Icon>
                      </Button>
                    )}
                  </PDFDownloadLink>

                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      exportActionPlan({
                        data: this.props.actionPlan,
                        myI18n: i18n
                      })
                    }}
                  >
                    <Trans>ACTION_PLAN_DOWNLOAD_EXCEL_SHEET</Trans>
                    <Icon
                      style={{
                        marginLeft: 5
                      }}
                    >
                      download
                    </Icon>
                  </Button>
                </>
              )
            }}
          </I18n>
          <div
            ref={this.printRef}
            className='hideInPrint'
            style={{ flexGrow: 1, width: '100%' }}
          >
            <ActionPlanToPrint columns={columns} />
          </div>
        </Grid>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Grid
            container
            direction='row'
            justify='flex-start'
            style={{ flexGrow: 1 }}
          >
            {columns.map((column, index) => {
              column.index = index
              return (
                <ActionPlanColumn
                  key={index}
                  column={column}
                  columns={columns}
                  columnIndex={index}
                  deleting={Boolean(deletingActionProps)}
                  minimizedFull={this.state.minimizedColumns.length === 4}
                  cardActions={{
                    openDialog: this.handleOpenDialog,
                    postponeAction: this.handlePostponeAction,
                    moveAction: this.onDragEnd,
                    // actionDone: this.handleActionDone,
                    actionFail: this.handleActionFail,
                    deleteAction: this.handleDeleteDialogOpen
                  }}
                  handleColumnMinimize={this.handleColumnMinimize}
                  handleDialogClose={this.handleDialogClose}
                  handleAddNewCard={this.handleAddNewCard}
                />
              )
            })}
          </Grid>
        </DragDropContext>

        {shouldOpenDialog && (
          <ActionPlanDialog
            card={card}
            open={shouldOpenDialog}
            create={this.state.create}
            handleAddNewCard={this.handleAddNewCard}
            handleSave={this.handleActionSave}
            handleClose={this.handleDialogClose}
          />
        )}
      </div>
    ) : (
      <Loading />
    )
  }
}

const DeleteAllActionsButton = ({ actions = [], orgId, handleDeleted }) => {
  const [deleting, setDeleting] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const disabled = !orgId || actions.length === 0
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return (
    <>
      <Button
        disabled={disabled}
        variant='contained'
        color='primary'
        style={{
          zIndex: 10,
          marginLeft: 5
        }}
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        <Icon style={{ marginRight: 5 }}>delete</Icon>
        <Trans>ACTION_PLAN_DELETE_ALL_ACTIONS_BUTTON</Trans>
      </Button>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          <Trans>DELETE_ALL_ACTIONS_DIALOG_TITLE</Trans>
        </DialogTitle>
        <DialogContent>
          <Alert severity='warning'>
            <AlertTitle>
              <Trans>DELETE_ALL_ACTIONS_WARNING_TEXT</Trans>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <Grid container style={{ padding: 15 }} justifyContent='space-evenly'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={deleting}
              onClick={e => {
                setDeleting(true)
                const deletingKey = enqueueSnackbar(
                  <Trans>DELETING_ALL_ACTIONS_SNACKBAR_IN_PROGRESS</Trans>,
                  { persist: true, variant: 'info' }
                )
                deleteAction(actions.map(action => action.id)).then(
                  result => {
                    setDeleting(false)
                    setDialogOpen(false)
                    closeSnackbar(deletingKey)
                    handleDeleted()
                    enqueueSnackbar(
                      <Trans>DELETING_ALL_ACTIONS_SNACKBAR_SUCCESS</Trans>,
                      { persist: true, variant: 'success' }
                    )
                  },
                  reject => {
                    setDeleting(false)
                    setDialogOpen(false)
                    closeSnackbar(deletingKey)
                    enqueueSnackbar(
                      <Trans>DELETING_ALL_ACTIONS_SNACKBAR_ERROR</Trans>,
                      { persist: true, variant: 'error' }
                    )
                  }
                )
              }}
            >
              <Trans>Yes</Trans>
              <Icon style={{ marginLeft: 5 }}>done</Icon>
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={deleting}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Trans>No</Trans>
              <Icon style={{ marginLeft: 5 }}>close</Icon>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  configuration: state.configuration,
  organization: state.organization,
  actionPlan: state.actionPlan,
  simulateInternal: state?.testingOptions?.simulateInternal
})

const mapDispatchToProps = {
  setInitialState,
  removeAction,
  addNewAction,
  moveToColumn,
  changeAction,
  removeAllActions
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ActionPlan))

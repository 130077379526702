import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { DateTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import {
  dateFormat,
  datetimeFormat,
  portalLanguagesData
} from 'app/appSettings'
import { setFundingStreams } from 'app/redux/actions/FundingStreamsActions'
import {
  deleteApplicationConfig,
  getFormPages
} from 'app/services/sfAuth/sfData/sfForms'
import { fetchFundingProgramsParsed } from 'app/services/sfAuth/sfData/sfFundingProgram'
import {
  getCaseTypesForFundingStreams,
  saveFundingStreams
} from 'app/services/sfAuth/sfData/sfFundingStream'
import { getRecordTypes } from 'app/services/sfAuth/sfData/sfOpportunity'
import Loading from 'egret/components/EgretLoadable/Loading'
import { FastField, Field, Formik } from 'formik'
import {
  FormikCheckboxField,
  FormikCheckboxGroupField
} from 'formik-material-fields'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmptyLangValues } from 'utils'
import { FUNDING_STREAMS } from '../../../config/configurationMetadata'
import { checkPromiseAllSuccess } from '../../../services/sfAuth/sfData/jsConnectionUtils'
import { FormikFastTextField } from '../../common-components/FormikFastTextField'
import FormikFastTextFieldWithDebounce from '../../common-components/FormikFastTextFieldWithDebounce'
import ProgressSnackbar from '../../page-layouts/CustomSnackbars'
import MultilanguageTextFieldWithFormik from '../MultilanguageTextFieldWithFormik'

const useStyles = makeStyles((theme) => ({
  FormGroup_grid: {
    flexDirection: 'row',
    marginLeft: 25,
    justifyContent: 'space-between'
  },
  FormControlLabel_grid: {
    width: '45%'
  },
  HelperText_root: {
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 18,
    fontSize: 14,
    lineHeight: 1
  },
  MuiCheckbox_root: {
    padding: 3
  }
}))

export const applicationFundingStreamConfigurationVariables = {
  FUNDING_STREAMS: {
    key: 'FUNDING_STREAMS',
    type: FUNDING_STREAMS,
    sObject: 'Funding_Streams__c'
  }
}

export const matchOpportunityWithFundingStream = ({
  fundingStreams = [],
  fundingStreamId,
  recordTypeId
}) => {
  let toRet

  if (fundingStreamId) {
    toRet = fundingStreams.find(
      (fundingStream) => fundingStream.id === fundingStreamId
    )
    if (toRet) {
      return toRet
    }
  }
  if (!toRet) {
    fundingStreams.some((stream) => {
      if (stream.recordType === recordTypeId) {
        toRet = stream
        return toRet
      }
      return false
    })
  }
  return toRet
}

export const convertTranslationsIfNeeded = (stream) => {
  if (stream && !stream.hasOwnProperty('subtitle')) {
    const updatedStream = { ...stream }
    const translationTexts = [
      'fullName',
      'name',
      'description',
      'moreInfoUrl',
      'subtitle'
    ]
    const languages = ['en', 'fr']
    translationTexts.forEach((text) => {
      updatedStream[text] = getEmptyLangValues()
      languages.forEach((lang) => {
        const property = text + lang.toUpperCase()
        if (updatedStream.hasOwnProperty(property)) {
          const translation = updatedStream[property]
          if (translation) {
            updatedStream[text] = {
              ...updatedStream[text],
              [lang]: translation
            }
          }
        }
      })
    })
    return updatedStream
  }

  return stream
}

export const convertTranslationsForConfigurationsIfNeeded = (config) => {
  if (config && !config.hasOwnProperty('subtitle')) {
    const updatedConfig = { ...config }
    const translationTexts = ['name', 'description', 'moreInfoUrl', 'subtitle']
    const languages = ['en', 'fr']
    translationTexts.forEach((text) => {
      updatedConfig[text] = getEmptyLangValues()
      languages.forEach((lang) => {
        const property = text + lang.toUpperCase()
        if (updatedConfig.hasOwnProperty(property)) {
          const translation = updatedConfig[property]
          if (translation) {
            updatedConfig[text] = {
              ...updatedConfig[text],
              [lang]: translation
            }
          }
        }
      })
    })
    return updatedConfig
  }
  return config
}

const ConfigureFundingStreams = () => {
  const lang = useSelector((state) => state.user.language)
  const [formsData, setFormsData] = React.useState([])
  const [initialValues, setInitialValues] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [saving, setSaving] = React.useState(false)
  const [objectiveRecordTypes, setObjectiveRecordTypes] = React.useState([])
  const [opportunityRecordTypes, setOpportunityRecordTypes] = React.useState([])
  const [fundingPrograms, setFundingPrograms] = React.useState([])
  const [caseTypes, setCaseTypes] = React.useState([])
  const [prequalificationRecordTypes, setPrequalificationRecordTypes] =
    React.useState()
  const classes = useStyles()
  const fundingStreams = useSelector((state) => state.fundingStreams)
  const dispatch = useDispatch()

  console.log('fundingStreams from redux: ', fundingStreams)

  useEffect(() => {
    if (fundingStreams?.streams?.length > 0) {
      fetchData()
    }
  }, [fundingStreams])

  const fetchData = useCallback(() => {
    return Promise.all([
      getFormPages(),
      getRecordTypes('Objective__c'),
      fetchFundingProgramsParsed(),
      getCaseTypesForFundingStreams(),
      getRecordTypes('Pre_Qualification__c'),
      getRecordTypes('Opportunity')
    ])
      .then(
        ([
          forms,
          objectiveRecordTypes,
          fundingPrograms,
          fetchedCaseTypes,
          prequalificaitonRecordTypes,
          opportunityRecordTypes
        ]) => {
          console.log('got forms', forms)
          console.log(
            'got record types',
            objectiveRecordTypes,
            prequalificaitonRecordTypes
          )
          console.log('got funding programs', fundingPrograms)
          console.log('got case types', fetchedCaseTypes)

          setFundingPrograms(fundingPrograms)
          setPrequalificationRecordTypes(
            Object.values(prequalificaitonRecordTypes)
          )
          setOpportunityRecordTypes(
            Object.values(opportunityRecordTypes)
          )
          const updatedFundingStreams = fundingStreams.streams
          setObjectiveRecordTypes(
            Object.keys(objectiveRecordTypes).map((key) => {
              const item = objectiveRecordTypes[key]
              return {
                id: item.recordTypeId,
                name: item.name
              }
            })
          )
          const formsCount = {}
          forms.forEach((form) => {
            if (form.origin) {
              if (formsCount[form.origin]) {
                formsCount[form.origin].push(form)
              } else {
                formsCount[form.origin] = [form]
              }
            }
          })
          setFormsData(
            forms
              .map((obj) => {
                let name = obj.name[lang] || ''
                if (obj.config.version && formsCount[obj.origin]) {
                  name += ' [v.' + obj.config.version + ']'
                }
                if (obj.comments) {
                  name += ' (' + obj.comments + ')'
                }
                return {
                  name,
                  id: obj.id
                }
              })
              .filter((name) => name)
              .sort((a, b) => String(a.name).localeCompare(b.name))
          )
          setCaseTypes(fetchedCaseTypes)
          setInitialValues({
            streamList: updatedFundingStreams
              .map((item, index) => {
                let data = {}
                data = item
                data = convertTranslationsIfNeeded(data)
                if (data.startTime && !data.startTime?._isAMomentObject) {
                  const localValue = moment(item.startTime).utc(true)
                  data.startTime = localValue
                }
                if (data.endTime && !data.endTime?._isAMomentObject) {
                  const localValue = moment(item.endTime).utc(true)
                  data.endTime = localValue
                }
                delete data.expanded
                if (!data.disableInProvinces) {
                  data.disableInProvinces = []
                }
                if (!data.avaliableInLanguages) {
                  data.avaliableInLanguages = []
                }
                let order = data.order
                if (!order && order !== 0) {
                  order = index
                }

                return {
                  id: item.id,
                  ...data,
                  order
                }
              })
              .sort((a, b) => a.order - b.order)
          })
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error('Error in fetchData:', error)
        setLoading(false)
      })
  }, [fundingStreams, lang])

  const handleSave = (values) => {
    const savingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: (key) => ProgressSnackbar(<Trans>Saving</Trans>)
    })
    setSaving(true)
    return saveFundingStreams(values).then(
      (results) => {
        if (!checkPromiseAllSuccess(results)) {
          return fetchData().then(() => {
            setSaving(false)
            closeSnackbar(savingSnackbar)
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: 'success'
            })
            dispatch(
              setFundingStreams(
                values.streamList,
                fundingStreams.validCaseTypes
              )
            )
          })
        } else {
          enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
            variant: 'error'
          })
          setSaving(false)
          closeSnackbar(savingSnackbar)
          return Promise.reject()
        }
      },
      (reject) => {
        console.error('rejected funding streams save', reject)
        setSaving(false)
        closeSnackbar(savingSnackbar)
        enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  if (loading || !initialValues) {
    return <Loading />
  }

  const { formatToTimeZone } = require('date-fns-timezone')

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values, setFieldValue }) => {
        const { streamList } = values

        return (
          <div style={{ padding: 20 }}>
            <Grid container direction='row' justifyContent='flex-end'>
              <Button
                disabled={saving}
                variant='contained'
                color='primary'
                onClick={() => {
                  handleSave(values)
                }}
              >
                <Trans>Save</Trans>
                <Icon style={{ marginLeft: 5 }}>save</Icon>
              </Button>
            </Grid>
            <Alert severity='info' style={{ marginTop: 10 }}>
              <Trans>
                Funding streams in create funding stream dialog will appear in
                order in which they are sorted on this list
              </Trans>
            </Alert>
            <List>
              {streamList.map((item, index) => {
                const { expanded } = item

                return (
                  <ListItem key={index}>
                    <Card
                      style={{
                        flexGrow: 1,
                        padding: 20,
                        border: '1px solid #C4C4C4'
                      }}
                    >
                      <Grid
                        justifyContent='space-between'
                        container
                        wrap='nowrap'
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              `streamList.${index}.expanded`,
                              !expanded
                            )
                          }}
                        >
                          <Icon>
                            {expanded ? 'expand_less' : 'expand_more'}
                          </Icon>
                        </IconButton>

                        <Grid item>
                          <IconButton
                            disabled={index === 0}
                            onClick={() => {
                              const toSet = [...streamList]
                              const toMove = toSet[index]
                              const toReplace = toSet[index - 1]
                              toSet[index - 1] = toMove
                              toSet[index] = toReplace
                              setFieldValue('streamList', toSet)
                            }}
                          >
                            <Icon>arrow_upward</Icon>
                          </IconButton>
                          <IconButton
                            disabled={index + 1 === streamList.length}
                            onClick={() => {
                              const toSet = [...streamList]
                              const toMove = toSet[index]
                              const toReplace = toSet[index + 1]
                              toSet[index + 1] = toMove
                              toSet[index] = toReplace
                              setFieldValue('streamList', toSet)
                            }}
                          >
                            <Icon>arrow_downward</Icon>
                          </IconButton>
                          <DeleteFundingStreamButton
                            handleDelete={(e) => {
                              const toDelete = streamList[index]
                              const toSet = [...streamList]
                              if (!toDelete.id) {
                                toSet.splice(index, 1)
                                setFieldValue('streamList', toSet)
                                enqueueSnackbar(
                                  <Trans>Funding stream deleted!</Trans>,
                                  {
                                    variant: 'success'
                                  }
                                )
                                return Promise.resolve()
                              } else {
                                return deleteApplicationConfig(
                                  toDelete.id
                                ).then(
                                  (result) => {
                                    return fetchData().then(() => {
                                      enqueueSnackbar(
                                        <Trans>Funding stream deleted!</Trans>,
                                        {
                                          variant: 'success'
                                        }
                                      )
                                      toSet.splice(index, 1)
                                      setFieldValue('streamList', toSet)
                                    })
                                  },
                                  (reject) => {
                                    enqueueSnackbar(
                                      <Trans>
                                        Error occurred while deleting!
                                      </Trans>,
                                      {
                                        variant: 'error'
                                      }
                                    )
                                  }
                                )
                              }
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/*
                      <FormikFastTextField
                        name={`streamList.${index}.shortName`}
                        label={<Trans>Name</Trans>}
                        style={{ padding: 5 }}
                      /> */}

                      <div style={{ padding: 5 }}>
                        <MultilanguageTextFieldWithFormik
                          name={`streamList.${index}.fullName`}
                          lang={lang}
                          label={<Trans>Full name</Trans>}
                          inputDebounce
                        />
                      </div>

                      <div style={{ padding: 5 }}>
                        <MultilanguageTextFieldWithFormik
                          name={`streamList.${index}.name`}
                          lang={lang}
                          label={<Trans>Short name</Trans>}
                          inputDebounce
                          onlySelectTranslation
                        />
                      </div>

                      <div style={{ padding: 5 }}>
                        <MultilanguageTextFieldWithFormik
                          name={`streamList.${index}.description`}
                          lang={lang}
                          label={<Trans>Short description</Trans>}
                          inputDebounce
                          onlySelectTranslation
                        />
                      </div>

                      <MultilanguageFormSelectField
                        label={<Trans>Funding program</Trans>}
                        name={`streamList.${index}.fundingProgram`}
                        formsData={fundingPrograms}
                        lang={lang}
                      />

                      <FormikCheckboxField
                        name={`streamList.${index}.hideProgramName`}
                        style={{ marginLeft: 10 }}
                        FormControlLabelProps={{ labelPlacement: 'end' }}
                        controlLabel={
                          <Trans>FUNDING_STREAM_HIDE_PROGRAM_NAME_LABEL</Trans>
                        }
                      />

                      <div style={{ padding: 5 }}>
                        <FormikFastTextFieldWithDebounce
                          name={`streamList.${index}.notes`}
                          label={<Trans>Notes</Trans>}
                          fullWidth
                          inputDebounce
                        />
                      </div>

                      <Collapse in={expanded}>
                        <Grid container direction='column'>
                          <FormSelectField
                            label={<Trans>Application form</Trans>}
                            formsData={formsData}
                            name={`streamList.${index}.form`}
                          />

                          <div style={{ width: '100%' }}>
                            <FormSelectField
                              label={<Trans>Eligibility quiz</Trans>}
                              name={`streamList.${index}.quiz`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={<Trans>Application print view</Trans>}
                              name={`streamList.${index}.printForm`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={<Trans>FS_CONFIG_APPLICATION_VIEW_FOR_HARDCODED</Trans>}
                              name={`streamList.${index}.applicationViewForHardcoded`}
                              formsData={formsData}
                            />

                            <Typography
                              style={{ padding: 10, fontWeight: 'bold' }}
                            >
                              <Trans>PREQUALIFICATION</Trans>
                            </Typography>

                            <FormSelectField
                              label={<Trans>PREQUALIFICATION_FORM</Trans>}
                              name={`streamList.${index}.prequalificationForm`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={
                                <Trans>PREQUALIFICATION_ELIGIBILITY_QUIZ</Trans>
                              }
                              name={`streamList.${index}.prequalificationEligibilityQuiz`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={
                                <Trans>PREQUALIFICATION_RECORD_TYPE</Trans>
                              }
                              name={`streamList.${index}.prequalificationRecordType`}
                              formsData={prequalificationRecordTypes.map(
                                (obj) => ({
                                  id: obj.recordTypeId,
                                  name: obj.name
                                })
                              )}
                            />

                            <FormSelectField
                              label={<Trans>DMAH_FORM</Trans>}
                              name={`streamList.${index}.dmahView`}
                              formsData={formsData}
                            />

                            <Typography
                              style={{ padding: 10, fontWeight: 'bold' }}
                            >
                              <Trans>Grantee reports</Trans>
                            </Typography>

                            <FormSelectField
                              label={<Trans>Grantee report preview</Trans>}
                              name={`streamList.${index}.granteeReportPreviewForm`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={<Trans>Grantee report form</Trans>}
                              name={`streamList.${index}.granteeReportForm`}
                              formsData={formsData}
                            />

                            <Typography
                              style={{ padding: 10, fontWeight: 'bold' }}
                            >
                              <Trans>Program Manager</Trans>
                            </Typography>

                            <FormSelectField
                              label={
                                <Trans>Applications list print preview</Trans>
                              }
                              name={`streamList.${index}.internalPrintPreview`}
                              formsData={formsData}
                            />

                            <FormSelectField
                              label={<Trans>Funding agreement</Trans>}
                              name={`streamList.${index}.contract`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={<Trans>Selection Committee preview</Trans>}
                              name={`streamList.${index}.SC`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={
                                <Trans>Allocation Committee preview</Trans>
                              }
                              name={`streamList.${index}.AC`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={<Trans>Board summary preview</Trans>}
                              name={`streamList.${index}.Board`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={<Trans>CMHC summary preview</Trans>}
                              name={`streamList.${index}.CMHC`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={
                                <Trans>
                                  CONFIGURE_FUNDING_STREAMS_EXTERNAL_REVIEW_APPLICATION_VIEW_LABEL
                                </Trans>
                              }
                              name={`streamList.${index}.externalReviewApplicationView`}
                              formsData={formsData}
                            />
                            <FormSelectField
                              label={<Trans>Case type</Trans>}
                              name={`streamList.${index}.caseType`}
                              formsData={caseTypes}
                            />
                            <Typography
                              style={{ padding: 10, fontWeight: 'bold' }}
                            >
                              <Trans>Record Types</Trans>
                            </Typography>

                            <div style={{ padding: 5 }}>
                              <FormikFastTextField
                                name={`streamList.${index}.objectiveRecordType`}
                                label={<Trans>Objective record type</Trans>}
                                style={{ marginBottom: 10 }}
                                select
                              >
                                {objectiveRecordTypes.map((item, index) => (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </FormikFastTextField>
                            </div>

                            <div style={{ padding: 5 }}>
                              <FormikFastTextField
                                name={`streamList.${index}.applicationRecordType`}
                                label={<Trans>Application record type</Trans>}
                                style={{ marginBottom: 10 }}
                                select
                              >
                                {opportunityRecordTypes.map((item, index) => (
                                  <MenuItem key={index} value={item.recordTypeId}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </FormikFastTextField>
                            </div>
                          </div>

                          <div>
                            <FormikCheckboxGroupField
                              label={<Trans>Disable in provinces</Trans>}
                              style={{ padding: 10, width: '100%' }}
                              name={`streamList.${index}.disableInProvinces`}
                              multiple
                              FormGroupProps={{
                                classes: {
                                  root: classes.FormGroup_grid
                                }
                              }}
                              FormControlLabelProps={{
                                classes: {
                                  root: classes.FormControlLabel_grid
                                }
                              }}
                              CheckboxProps={{
                                classes: {
                                  root: classes.MuiCheckbox_root
                                }
                              }}
                              FormHelperTextProps={{
                                classes: {
                                  root: classes.HelperText_root
                                }
                              }}
                              options={[
                                {
                                  label: <Trans>Alberta</Trans>,
                                  value: 'Alberta'
                                },
                                {
                                  label: <Trans>British Columbia</Trans>,
                                  value: 'British Columbia'
                                },
                                {
                                  label: <Trans>Manitoba</Trans>,
                                  value: 'Manitoba'
                                },
                                {
                                  label: <Trans>New Brunswick</Trans>,
                                  value: 'New Brunswick'
                                },
                                {
                                  label: (
                                    <Trans>Newfoundland and Labrador</Trans>
                                  ),
                                  value: 'Newfoundland and Labrador'
                                },
                                {
                                  label: <Trans>Northwest Territories</Trans>,
                                  value: 'Northwest Territories'
                                },
                                {
                                  label: <Trans>Nova Scotia</Trans>,
                                  value: 'Nova Scotia'
                                },
                                {
                                  label: <Trans>Nunavut</Trans>,
                                  value: 'Nunavut'
                                },
                                {
                                  label: <Trans>Ontario</Trans>,
                                  value: 'Ontario'
                                },
                                {
                                  label: <Trans>Prince Edward Island</Trans>,
                                  value: 'Prince Edward Island'
                                },
                                {
                                  label: <Trans>Québec</Trans>,
                                  value: 'Quebec'
                                },
                                {
                                  label: <Trans>Saskatchewan</Trans>,
                                  value: 'Saskatchewan'
                                },
                                {
                                  label: <Trans>Yukon</Trans>,
                                  value: 'Yukon'
                                }
                              ]}
                            />
                          </div>

                          <div>
                            <FormikCheckboxGroupField
                              label={<Trans>Available in languages</Trans>}
                              style={{ padding: 10, width: '100%' }}
                              name={`streamList.${index}.avaliableInLanguages`}
                              multiple
                              row
                              options={Object.values(portalLanguagesData).map(
                                (obj) => {
                                  return {
                                    label: obj.labelTrans,
                                    value: obj.editKey
                                  }
                                }
                              )}
                            />
                          </div>

                          <div style={{ padding: 5 }}>
                            <b>
                              <Trans>Your time zone:</Trans>
                            </b>{' '}
                            {Intl.DateTimeFormat().resolvedOptions().timeZone}
                            {' ('}
                            {new Date().getTimezoneOffset()}
                            )
                          </div>

                          <Grid container>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`streamList.${index}.startTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction='row'
                                        wrap='nowrap'
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={datetimeFormat}
                                          value={field.value || null}
                                          onChange={(e) => {
                                            form.setFieldValue(
                                              `streamList.${index}.startTime`,
                                              e
                                            )
                                          }}
                                          fullWidth
                                          inputVariant='outlined'
                                          label={<Trans>Validity start</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={(e) => {
                                              form.setFieldValue(
                                                `streamList.${index}.startTime`,
                                                null
                                              )
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{' '}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: 'Canada/Pacific'
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )
                                }}
                              </Field>
                            </Grid>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`streamList.${index}.endTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction='row'
                                        wrap='nowrap'
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={dateFormat + ' h:mm a'}
                                          value={field.value || null}
                                          onChange={(e) => {
                                            form.setFieldValue(
                                              `streamList.${index}.endTime`,
                                              e
                                            )
                                          }}
                                          fullWidth
                                          inputVariant='outlined'
                                          label={<Trans>Validity end</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={(e) => {
                                              form.setFieldValue(
                                                `streamList.${index}.endTime`,
                                                null
                                              )
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{' '}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: 'Canada/Pacific'
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )
                                }}
                              </Field>
                            </Grid>
                          </Grid>

                          <div style={{ padding: 5 }}>
                            <Field name={`streamList.${index}.reminderTime`}>
                              {({ field, form }) => {
                                return (
                                  <>
                                    <Grid
                                      container
                                      direction='row'
                                      wrap='nowrap'
                                    >
                                      <DateTimePicker
                                        {...field}
                                        format={datetimeFormat}
                                        value={field.value || null}
                                        onChange={(e) => {
                                          form.setFieldValue(
                                            `streamList.${index}.reminderTime`,
                                            e
                                          )
                                        }}
                                        fullWidth
                                        inputVariant='outlined'
                                        label={
                                          <Trans>REMINDER_DATE_LABEL</Trans>
                                        }
                                      />
                                      <div style={{ padding: 5 }}>
                                        <IconButton
                                          onClick={(e) => {
                                            form.setFieldValue(
                                              `streamList.${index}.reminderTime`,
                                              null
                                            )
                                          }}
                                        >
                                          <Icon>close</Icon>
                                        </IconButton>
                                      </div>
                                    </Grid>
                                    {field.value && (
                                      <div style={{ padding: 5 }}>
                                        <b>
                                          <Trans>In Pacific time:</Trans>
                                        </b>{' '}
                                        {formatToTimeZone(
                                          moment.utc(field.value).local(true),
                                          datetimeFormat,
                                          {
                                            timeZone: 'Canada/Pacific'
                                          }
                                        )}
                                      </div>
                                    )}
                                  </>
                                )
                              }}
                            </Field>
                          </div>

                          <div>
                            <FormikCheckboxField
                              name={`streamList.${index}.inTesting`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: 'end' }}
                              controlLabel={<Trans>Is in testing?</Trans>}
                            />
                            <FormikCheckboxField
                              name={`streamList.${index}.obsolete`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: 'end' }}
                              controlLabel={<Trans>Is obsolete?</Trans>}
                            />
                          </div>

                          <div style={{ width: '100%' }}>
                            <MultilanguageTextFieldWithFormik
                              name={`streamList.${index}.moreInfoUrl`}
                              label={<Trans>More info url</Trans>}
                              lang={lang}
                              style={{ padding: 5 }}
                              inputDebounce
                              onlySelectTranslation
                            />
                            <Grid
                              container
                              direction='column'
                              style={{ padding: 5, flexGrow: 1 }}
                              justifyContent='center'
                              alignContent='center'
                              alignItems='center'
                            >
                              <FormikFastTextFieldWithDebounce
                                name={`streamList.${index}.logo`}
                                label={<Trans>Logo url</Trans>}
                                fullWidth
                                inputDebounce
                              />
                              <Typography
                                style={{ fontWeight: 'bold', margin: 5 }}
                              >
                                <Trans>Preview:</Trans>
                              </Typography>
                              <img
                                src={item.logo}
                                alt=''
                                style={{
                                  borderRadius: 10,
                                  maxHeight: 250,
                                  maxWidth: 250
                                }}
                              />
                            </Grid>
                          </div>
                        </Grid>
                      </Collapse>
                    </Card>
                  </ListItem>
                )
              })}
            </List>
          </div>
        )
      }}
    </Formik>
  )
}

export const FormSelectField = ({ name, formsData, label }) => {
  const idToLabel = {}
  formsData.forEach((obj) => {
    idToLabel[obj.id] = obj.name
  })

  return (
    <FastField name={name}>
      {({ field, form }) => {
        const { setFieldValue } = form

        return (
          <div style={{ padding: 5 }}>
            <Autocomplete
              {...field}
              freeSolo={false}
              value={field.value || ''}
              fullWidth
              getOptionLabel={(opt) => idToLabel[opt] || ''}
              options={[...formsData.map((item, index) => item.id), '']}
              onChange={(e, value) => {
                setFieldValue(name, value)
              }}
              renderInput={(params) => (
                <TextField variant='outlined' {...params} label={label} />
              )}
            />
          </div>
        )
      }}
    </FastField>
  )
}

export const MultilanguageFormSelectField = ({
  name,
  formsData,
  label,
  lang
}) => {
  const idToLabel = {}

  formsData.forEach((obj) => {
    idToLabel[obj.id] =
      obj.fullName && obj.fullName[lang] ? obj.fullName[lang] : obj.name
  })

  return (
    <FastField name={name}>
      {({ field, form }) => {
        const { setFieldValue } = form

        return (
          <div style={{ padding: 5 }}>
            <Autocomplete
              {...field}
              freeSolo={false}
              value={field.value || ''}
              fullWidth
              getOptionLabel={(opt) => idToLabel[opt] || ''}
              options={formsData.map((item) => item.id)}
              onChange={(e, value) => {
                setFieldValue(name, value)
              }}
              renderInput={(params) => (
                <TextField variant='outlined' {...params} label={label} />
              )}
            />
          </div>
        )
      }}
    </FastField>
  )
}

const DeleteFundingStreamButton = ({ handleDelete }) => {
  const [dialogOpen, openDialog] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setDeleting(false)
  }, [dialogOpen])

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Are you sure you want to delete that section?</Trans>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction='row'
              justify='space-evenly'
              alignItems='center'
            >
              <Button
                disabled={deleting}
                variant='contained'
                color='primary'
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleting(true)
                  enqueueSnackbar(<Trans>Deleting</Trans>, {
                    variant: 'info'
                  })
                  handleDelete().then((r) => {
                    openDialog(false)
                  })
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='space-evenly'
                >
                  <Trans>Yes</Trans>
                  <Icon style={{ marginLeft: 5 }}>done</Icon>
                </Grid>
              </Button>
              <Button
                disabled={deleting}
                variant='outlined'
                color='primary'
                onClick={(e) => {
                  openDialog(false)
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='space-evenly'
                >
                  <Trans>No</Trans>
                  <Icon style={{ marginLeft: 5 }}>close</Icon>
                </Grid>
              </Button>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
      <IconButton
        onClick={() => {
          openDialog(true)
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
    </>
  )
}

export default ConfigureFundingStreams

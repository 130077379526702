export const themeColors = {
  white: {
    palette: {
      type: 'light',
      primary: {
        main: '#ffffff',
        contrastText: 'rgba(0,0,0,.87)'
      },
      secondary: {
        main: '#ff9e43'
      }
    }
  },
  slateDark1: {
    palette: {
      type: 'dark',
      primary: {
        main: '#10708A',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }

    }
  },
  slateDark2: {
    palette: {
      type: 'dark',
      primary: {
        main: '#1a2038',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }
    }
  },
  purple1: {
    palette: {
      type: 'light',
      primary: {
        main: '#7467ef',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      }
    }
  },
  purple2: {
    palette: {
      type: 'light',
      primary: {
        main: '#6a75c9',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      }
    }
  },
  purpleDark1: {
    palette: {
      type: 'dark',
      primary: {
        main: '#7467ef',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }
    }
  },
  purpleDark2: {
    palette: {
      type: 'dark',
      primary: {
        main: '#6a75c9',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ff9e43'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }
    }
  },
  blue: {
    palette: {
      type: 'light',
      primary: {
        main: '#3366FF',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#FFAF38',
        contrastText: '#ffffff'
      }
    }
  },
  blueDark: {
    palette: {
      type: 'dark',
      primary: {
        main: '#3366FF',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#FF4F30',
        contrastText: '#ffffff'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }
    }
  },
  /*
  main: "#82b049",
  main: "#3c6276",
  main: "#73ad85",
  main: "#0079a5",
  main: "#24355b",
  */
  chtc: {
    palette: {
      type: 'dark',
      primary: {
        // main: "#82b049",
        // main: "#3c6276",
        // main: "#73ad85",
        // main: "#0079a5",
        // main: "#24355b",
        main: '#5dbd94',
        contrastText: '#ffffff',
        default: '#10708A'
      },
      secondary: {
        main: '#82b049',
        contrastText: '#ffffff'
      },
      background: {
        default: '#222831',
        paper: '#222A45'
      },
      error: {
        main: '#f5543b'
      }
    }
  },
  chtcWhite: {
    header: {
      image: '/assets/images/chtc-logo-circle.svg'
    },
    sidebar: {
      main: '#E3ECEF',
      sidebarIconsColor: '#ffffff'
    },
    palette: {
      type: 'light',
      primary: {
        // main: "#82b049",
        // main: "#3c6276",
        // main: "#73ad85",
        // main: "#0079a5",
        // main: "#24355b",
        main: '#5dbd94',
        default: '#10708A',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#82b049',
        contrastText: '#ffffff'
      },
      error: {
        main: '#f5543b'
      }
    }
  },
  icceSidebar: {
    palette: {
      type: 'dark',
      primary: {
        main: '#000000',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#fec42b'
      },
      background: {
        paper: '#222A45',
        default: '#1a2038'
      }

    }
  },
  icceWhite: {
    header: {
      image: '/assets/images/icce-logo-circle.svg'
    },
    sidebar: {
      main: '#ffffff',
      sidebarIconsColor: '#A43336'
    },
    palette: {
      type: 'light',
      primary: {
        // main: "#82b049",
        // main: "#3c6276",
        // main: "#73ad85",
        // main: "#0079a5",
        // main: "#24355b",
        main: '#A43336',
        default: '#231F20',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#fec42b',
        contrastText: '#ffffff'
      },
      error: {
        main: '#C75656'
      }
    }
  }
}

import { Trans, t } from '@lingui/macro'
import {
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import { dateFormat } from 'app/appSettings'
import SFAuthService, { NO_USER } from 'app/services/sfAuth/SFAuthService'
import { MILESTONE_ORDER } from 'app/services/sfAuth/sfData/sfOpportunity'
import { getCurrentWords } from 'app/views/common/Formats'
import { MUPreviouslySavedValuesPanel } from 'app/views/forms/multiuser/components/MUPreviouslySavedValuesPanel'
import { commitChangeToMultipleFields } from 'app/views/forms/multiuser/grpcMultiuserEdit'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import {
  completeMinMaxDates,
  endDateChange,
  getMinMaxDates,
  startDateChange
} from 'app/views/utilities/milestone-date-calcs'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { parseFormLabelText } from '../../../common/Common'
import MUDatePicker from '../../../multiuser/components/MUDatePicker'
import MUTextField from '../../../multiuser/components/MUTextField'

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  dataPicker: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    width: '80%',
    marginBottom: 16
  },
  tcSpan4: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 35
  },
  tr: {
    borderBottom: 'solid 1px #c1c1c1',
    borderTop: 'solid 0px #ffffff'
  },
  tHeaderText: {
    textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const formMilestonesValueToText = (v, question) => {
  const { phases } = question.typeProps
  const toRet = (
    <div>
      {phases.map((phase, index) => {
        const milestone = v[index]
        return (
          <div key={index} style={{ width: '100%', padding: 5 }}>
            <Typography
              style={{
                fontSize: 14,
                fonWeight: 'bold',
                textAlign: 'center',
                margin: 10
              }}
            >
              <Trans id={myI18n?._(t`${phase.apiValue}`)} />
            </Typography>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Start Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 17 }}>
                  {milestone?.startDate &&
                    moment.utc(milestone.startDate).format(dateFormat)}
                </Typography>
              </Grid>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>End Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 17 }}>
                  {milestone?.endDate &&
                    moment.utc(milestone.endDate).format(dateFormat)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: 5 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Primary Activities</Trans>
              </Typography>
              <Typography>{milestone.primaryActivities}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 5 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Comments</Trans>
              </Typography>
              <Typography>{milestone.comments}</Typography>
            </div>
          </div>
        )
      })}
      <div style={{ color: '#e0a912' }}>
        <Trans>
          Overriding will ony save changes to already added milestones. It will
          not delete any newly added milestones. Manual cleanup may be required.
        </Trans>
      </div>
    </div>
  )

  return languages.reduce((acc, lang) => {
    acc[lang] = toRet
    return acc
  }, {})
}

export const FormMilestonesDefaultValue = (obj, addInfo, item) => {
  const { phases = [] } = item.typeProps

  if (!obj || !obj.FGM_Base__Benchmarks__r) {
    return phases.map((phase, index) => ({
      startDate: moment().utc()
        .add(1 + 8 * index, 'day')
        .format('YYYY-MM-DD'),
      endDate: moment().utc()
        .add(8 + 8 * index, 'day')
        .format('YYYY-MM-DD'),
      isDisplayed: true,
      isActive: true
    }))
  }

  const toRet = obj.FGM_Base__Benchmarks__r.records
    .sort((a, b) => {
      const aIsActive = phases.some(
        (phase) => phase.apiValue === a.FGM_Base__Status__c
      )
      const bIsActive = phases.some(
        (phase) => phase.apiValue === b.FGM_Base__Status__c
      )
      if (aIsActive && !bIsActive) {
        return -1
      } else if (!aIsActive && bIsActive) {
        return 1
      } else {
        return (
          MILESTONE_ORDER.indexOf(a.stage) - MILESTONE_ORDER.indexOf(b.stage)
        )
      }
    })
    .map((milestone, index) => {
      const isDisplayed = index < phases.length
      return {
        isActive: phases.some(
          (phase) => phase.apiValue === milestone.FGM_Base__Status__c
        ),
        isDisplayed,
        startDate: milestone.FGM_Base__Due_Date__c
          ? moment.utc(milestone.FGM_Base__Due_Date__c).format('YYYY-MM-DD')
          : moment.utc().add(1, 'day').format('YYYY-MM-DD'),
        endDate: milestone.FGM_Base__Completion_Date__c
          ? moment.utc(milestone.FGM_Base__Completion_Date__c).format('YYYY-MM-DD')
          : moment.utc().add(1, 'day').format('YYYY-MM-DD'),
        primaryActivities: milestone.Primary_activities__c || '',
        comments: milestone.FGM_Base__Description__c || '',
        stage: milestone.FGM_Base__Status__c,
        id: milestone.Id
      }
    })
  return toRet
}

Yup.addMethod(Yup.array, 'checkChronology', function (message) {
  return this.test('checkChronology', message, function (milestones) {
    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    const activeMilestones = milestones.filter((m) => m.isDisplayed)
    console.log({ activeMilestones, milestones })
    const startDates = activeMilestones.map((item) => item.startDate)
    const endDates = activeMilestones.map((item) => item.endDate)
    let bool = false
    const errors = []
    for (let index = 1; index < startDates.length; index++) {
      if (startDates[index] && startDates[index] <= endDates[index - 1]) {
        bool = true
        errors.push(
          new Yup.ValidationError(
            message,
            startDates[index],
            path + `[${index}].startDate`
          )
        )
        errors.push(
          new Yup.ValidationError(
            message,
            endDates[index],
            path + `[${index - 1}].endDate`
          )
        )
      }
    }
    for (let index = 0; index < endDates.length; index++) {
      if (endDates[index] <= startDates[index]) {
        bool = true
        errors.push(
          new Yup.ValidationError(
            message,
            endDates[index],
            path + `[${index}].endDate`
          )
        )
        errors.push(
          new Yup.ValidationError(
            message,
            startDates[index],
            path + `[${index}].startDate`
          )
        )
      }
    }
    if (bool) {
      return createError({ message: () => errors })
      // return createError({ path, message })
    }
    return !bool
  })
})

// If days quantity after current date is set, check if start date is not before this date
Yup.addMethod(Yup.array, 'checkStartDateMinDate', function ({ phases }) {
  return this.test('checkStartDateMinDate', '', function (milestones) {
    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    const activeMilestones = milestones.filter((m) => m.isDisplayed)

    // startDate and endDate can be either Date object or moment object or string in format 'YYYY-MM-DD'. Bring them all to format 'YYYY-MM-DD' for comparison
    const startDates = activeMilestones.map(
      (item) =>
        item?.startDate?.format // is moment object with format method
          ? item?.startDate?.format('YYYY-MM-DD')
          : typeof item?.startDate === 'object' // is Date object
            ? item?.startDate?.toISOString().split('T')[0]
            : item?.startDate // is string in format 'YYYY-MM-DD'
    )
    const endDates = activeMilestones.map((item) =>
      item?.endDate?.format
        ? item?.endDate?.format('YYYY-MM-DD')
        : typeof item?.endDate === 'object'
          ? item?.endDate?.toISOString().split('T')[0]
          : item?.endDate
    )
    const startDateMinDates = phases.map((item) =>
      item.startDateDaysAfterCurrentDate
        ? moment
          .utc()
          .add(item.startDateDaysAfterCurrentDate, 'day')
          .format('YYYY-MM-DD')
        : moment.utc().format('YYYY-MM-DD')
    )

    let bool = false
    const errors = []
    for (let index = 0; index < startDates.length; index++) {
      if (
        startDates[index] &&
        startDateMinDates[index] &&
        startDates[index] < startDateMinDates[index]
      ) {
        bool = true
        const daysAfter = phases[index].startDateDaysAfterCurrentDate
        errors.push(
          new Yup.ValidationError(
            daysAfter
              ? (
                <div>
                  <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Trans>
                      FORM_MILESTONES {daysAfter} DAYS_AFTER_CURRENT_DATE
                    </Trans>
                  </div>
                </div>
                )
              : (
                <Trans>Past or current date is not allowed!</Trans>
                ),
            startDates[index],
            path + `[${index}].startDate`
          )
        )
      }
    }
    if (bool) {
      return createError({ message: () => errors })
      // return createError({ path, message })
    }
    return !bool
  })
})

export const FormMilestonesValidation = (item) => {
  const { commentsRequired, activitiesRequired, datesRequired } =
    item.typeProps
  const baseObject = {}
  const requiredTrans = <Trans>This field is required</Trans>
  const chronologicalTrans = (
    <Trans>Dates have to be in chronological order!</Trans>
  )
  if (commentsRequired) {
    baseObject.comments = Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Comments</Trans>)
  }
  if (activitiesRequired) {
    baseObject.primaryActivities = Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Primary Activities</Trans>)
  }
  if (datesRequired) {
    baseObject.startDate = Yup.date()
      .typeError(<Trans>Must be valid date</Trans>)
      // .min(moment.utc(), notAllowedTrans)
      .required(requiredTrans)
      .label(<Trans>Start Date</Trans>)
    baseObject.endDate = Yup.date()
      .typeError(<Trans>Must be valid date</Trans>)
      // .min(moment.utc(), notAllowedTrans)
      .required(requiredTrans)
      .label(<Trans>End Date</Trans>)
  }
  return Yup.array()
    .ensure()
    .compact(function (v, index) {
      return !v.isDisplayed
    })
    .of(Yup.object(baseObject))
    .checkStartDateMinDate({
      phases: item.typeProps.phases
    })
    .checkChronology(chronologicalTrans)
    .label(<Trans>Milestones</Trans>)
}

export const FormMilestonesExtractKey = ({
  saveMap,
  value,
  item,
  connectedObjectId
}) => {
  const { phases = [] } = item.typeProps
  const inner = value.map((item, index) => {
    const shouldSaveValues = Boolean(phases[index])
    if (!shouldSaveValues) {
      return {
        FGM_Base__Due_Date__c: null,
        FGM_Base__Completion_Date__c: null,
        Primary_activities__c: '',
        FGM_Base__Description__c: '',
        Id: item.id
      }
    }

    return {
      FGM_Base__Due_Date__c: item.startDate,
      FGM_Base__Completion_Date__c: item.endDate,
      Primary_activities__c: item.primaryActivities,
      FGM_Base__Description__c: item.comments,
      FGM_Base__Status__c: phases[index].apiValue,
      Id: item.id
    }
  })
  saveMap[connectedObjectId].FGM_Base__Benchmarks__r = inner
}

export const FormMilestonesSavePromise = ({ value }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate = value.map((item) => ({
    FGM_Base__Due_Date__c: item.startDate,
    FGM_Base__Completion_Date__c: item.endDate,
    Primary_activities__c: item.primaryActivities,
    FGM_Base__Description__c: item.comments,
    Id: item.id
  }))
  return conn.sobject('FGM_Base__Benchmark__c').update(toUpdate)
}

const useStyles = makeStyles(styles)

export const formMilestonesinitialTouchedIds = ({ id, typeProps = {} }) => {
  const toRet = []
  const { phases = [] } = typeProps
  phases.forEach((phase, index) => {
    toRet.push(id + `[${index}].startDate`)
    toRet.push(id + `[${index}].endDate`)
  })
  return toRet
}

/**
 * Form element which, for each project phase configured, renders an input that allows providing start date, end date, activities description and additional comments for each project phase. Milestones are saved as
 * FGM_Base__Benchmark__c child objects on connected Opportunity object.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection}  typeProps.connectedObject Connected Salesforce Opportunity object.
 * @param  {Object[]} typeProps.phases Array of phases defined in the form editor. For each one user will need to fill the required information.
 * @param  {number}  [typeProps.commentsMaxChar] If provided, the number of characters that the comments text input field will be restricted to.
 * @param  {number}  [typeProps.commentsMaxWords] If provided, the number of words that the comments text input field will be restricted to.
 * @param  {number}  [typeProps.commentsRows=1] Number of rows which comments text input field should occupy.
 * @param  {boolean} [typeProps.commentsExpandable=false] If comments text field should expand further below if new line is inserted.
 * @param  {number}  [typeProps.activitiesMaxChar] If provided, the number of characters that the activities text input field will be restricted to.
 * @param  {number}  [typeProps.activitiesMaxWords] If provided, the number of words that the activities text input field will be restricted to.
 * @param  {number}  [typeProps.activitiesRows=1] Number of rows which activities text input field should occupy.
 * @param  {boolean} [typeProps.activitiesExpandable=false] If activities text field should expand further below if new line is inserted.
 */
export const FormMilestones = ({
  id,
  useMultiuser,
  muBag,
  title,
  disabled,
  connectedObject,
  editMode,
  langVersion,
  helpText,
  typeProps,
  i18n,
  ...props
}) => {
  const value = props.value || []
  const {
    phases = [],
    commentsMaxChar,
    commentsMaxWords,
    activitiesMaxChar,
    activitiesMaxWords,
    commentsRequired,
    activitiesRequired,
    commentsExpandable,
    commentsRows,
    activitiesRows,
    activitiesExpandable
  } = typeProps
  const { values, setFieldValue } = useFormikContext()
  const columns = [t`Name`, t`Start Date`, t`End Date`]
  const classes = useStyles()
  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )

  // This effect is used to calculate all the min and max dates for start and end date of the phases
  useEffect(() => {
    if (!values?.[id]?.[0]?.init) {
      if (values[id][0]) {
        // set initial value to true to understand when to recalculate minMaxDays
        // because there is no this value after saving and reloading the form but we need to recalculate minMaxDays
        values[id][0].init = true
      }
      const _minMaxDays = getMinMaxDates({
        id,
        value,
        values,
        phasesProps: phases,
        typeProps
      })
      const minMaxDays = completeMinMaxDates(_minMaxDays)
      for (let i = 0; i < phases.length; i++) {
        if (
          (!minMaxDays[i].startDate || !minMaxDays[i].endDate) &&
          phases[i] &&
          i > 0
        ) {
          minMaxDays[i] = {
            ...minMaxDays[i],
            startDate:
              minMaxDays[i].startDate ||
              moment(minMaxDays[i - 1].endDate)
                .add(1, 'day')
                .format('YYYY-MM-DD'),
            endDate:
              minMaxDays[i].endDate ||
              moment(minMaxDays[i - 1].endDate)
                .add(8, 'day')
                .format('YYYY-MM-DD')
          }
        }
      }
      setFieldValue(id, minMaxDays)
    }
  }, [values?.[id]?.[0]?.init])

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  return (
    <Paper className={classes.root}>
      <h3>{title}</h3>
      <Typography>{ReactHtmlParser(helpText)}</Typography>
      <div className='w-100 overflow-auto' style={{ padding: 20 }}>
        <TableContainer component={Paper}>
          <Table style={{ whiteSpace: 'pre' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                {columns.map((item, i) => (
                  <TableCell key={i}>
                    <p className={classes.tHeaderText}>
                      <Trans id={item} />
                    </p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {phases.map((item, index) => {
                const {
                  startDateTooltip,
                  endDateTooltip,
                  startDateDaysAfterCurrentDate
                } = item

                const comments = value[index]?.comments || ''
                const activities = value[index]?.primaryActivities || ''
                // const error = moment(startDate).isAfter(moment(endDate)) && (
                //   <Trans>Start date cannot be set after end date</Trans>
                // )
                const startTooltip = parseFormLabelText({
                  text: startDateTooltip,
                  langVersion,
                  i18n
                })
                const endTooltip = parseFormLabelText({
                  text: endDateTooltip,
                  langVersion,
                  i18n
                })

                const commentsHelperArray = []
                if (commentsMaxWords) {
                  commentsHelperArray.push(
                    <span key='maxWords'>
                      {getCurrentWords(comments) + '/' + commentsMaxWords}{' '}
                      <Trans>words</Trans>
                      {'. '}
                    </span>
                  )
                }
                if (Number(commentsMaxChar)) {
                  commentsHelperArray.push(
                    <span key='maxCharacters'>
                      {countHelperText(comments, Number(commentsMaxChar))}
                      {'. '}
                    </span>
                  )
                }
                if (commentsRequired) {
                  commentsHelperArray.push(
                    <span key='commentsRequired'>
                      <Trans>Required</Trans>
                      {'. '}
                    </span>
                  )
                }

                const activitiesHelperArray = []
                if (activitiesMaxWords) {
                  activitiesHelperArray.push(
                    <span key='activitiesMaxWords'>
                      {getCurrentWords(activities) + '/' + activitiesMaxWords}{' '}
                      <Trans>words</Trans>
                      {'. '}
                    </span>
                  )
                }
                if (Number(activitiesMaxChar)) {
                  activitiesHelperArray.push(
                    <span key='activitiesMaxChar'>
                      {countHelperText(activities, Number(activitiesMaxChar))}
                      {'. '}
                    </span>
                  )
                }
                if (activitiesRequired) {
                  activitiesHelperArray.push(
                    <span key='activitiesRequired'>
                      <Trans>Required</Trans>
                      {'. '}
                    </span>
                  )
                }

                const activitiesId = id + `[${index}].primaryActivities`
                const commentsId = id + `[${index}].comments`

                return [
                  <TableRow
                    key={index}
                    style={{ borderBottom: 'solid 0px #ffffff' }}
                  >
                    <TableCell style={{ paddingLeft: 20 }}>
                      <div style={{ paddingLeft: 15 }}>
                        <b>
                          {parseFormLabelText({
                            text: item.label,
                            langVersion,
                            i18n
                          })}
                        </b>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: '40%' }}>
                      <Grid container wrap='nowrap'>
                        <MUDatePicker
                          id={id + `[${index}].startDate`}
                          muBag={muBag}
                          useMultiuser={useMultiuser}
                          displayFieldHistoryIcon
                          autoOk
                          // error={error}
                          minDate={
                            values?.[id]?.[index]?.startDateMinDate || null
                          }
                          maxDate={
                            values?.[id]?.[index]?.startDateMaxDate || null
                          }
                          style={{
                            width: '100%',
                            margin: 0,
                            paddingRight: 0,
                            paddingTop: 15,
                            paddingLeft: 15
                          }}
                          disabled={disabled}
                          format={dateFormat}
                          shouldUnlockFieldWithoutChangesAfterOnChangeEvent
                          /** property shouldUnlockFieldWithoutChangesAfterOnChangeEvent shows if field should be unlocked without changes
                           * after onChange event. */
                          onChange={(event) => {
                            const toSet = [...values[id]]
                            const item = { ...toSet[index] }
                            /** if date is not changed, unlock field without changes */
                            if (
                              moment(event).format('YYYY-MM-DD') ===
                              moment(item.startDate).format('YYYY-MM-DD')
                            ) {
                              return
                            }
                            /** Create array of fields to be changed by this event.
                             * All this fields will be handled by commitChangeToMultipleFields to save them in backend history properly */
                            const arrToUpd = []
                            if (!toSet[index]) {
                              toSet[index] = {}
                            }
                            toSet[index].startDate = event
                            startDateChange({
                              phases: toSet,
                              id,
                              idx: index,
                              arrToUpd,
                              fields: {
                                startDate: 'startDate',
                                endDate: 'endDate',
                                endDateMinDate: 'endDateMinDate',
                                endDateMaxDate: 'endDateMaxDate',
                                startDateMinDate: 'startDateMinDate',
                                startDateMaxDate: 'startDateMaxDate'
                              }
                            })
                            setFieldValue(id, toSet)
                            if (useMultiuser) {
                              commitChangeToMultipleFields({
                                array: arrToUpd,
                                onSuccess: (response) => {},
                                onFail: (error) => {
                                  console.log('error', { error })
                                },
                                token: muBag.token
                              })
                            }
                          }}
                          helperText={
                            startDateDaysAfterCurrentDate
                              ? (
                                <div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'nowrap'
                                    }}
                                  >
                                    <Trans>
                                      FORM_MILESTONES {startDateDaysAfterCurrentDate}{' '}
                                      DAYS_AFTER_CURRENT_DATE
                                    </Trans>
                                  </div>
                                </div>
                                )
                              : (
                                <div>{' '}</div>
                                )
                          }
                        />
                        {startTooltip && (
                          <TooltipLabelIcon tooltip={startTooltip} />
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell style={{ width: '40%' }}>
                      <Grid container wrap='nowrap'>
                        <MUDatePicker
                          id={id + `[${index}].endDate`}
                          muBag={muBag}
                          useMultiuser={useMultiuser}
                          displayFieldHistoryIcon
                          autoOk
                          // error={error}
                          minDate={
                            values?.[id]?.[index]?.endDateMinDate || null
                          }
                          maxDate={
                            values?.[id]?.[index]?.endDateMaxDate || null
                          }
                          style={{
                            width: '100%',
                            margin: 0,
                            paddingRight: 0,
                            paddingTop: 15,
                            paddingLeft: 15
                          }}
                          disabled={disabled}
                          format={dateFormat}
                          /** property shouldUnlockFieldWithoutChangesAfterOnChangeEvent shows if field should be unlocked without changes
                           * after onChange event. */
                          shouldUnlockFieldWithoutChangesAfterOnChangeEvent
                          onChange={(event) => {
                            const toSet = [...values[id]]
                            const item = { ...toSet[index] }
                            /** if date is not changed, unlock field without changes */
                            if (
                              moment(event).format('YYYY-MM-DD') ===
                              moment(item.endDate).format('YYYY-MM-DD')
                            ) {
                              return
                            }
                            /** Create array of fields to be changed by this event.
                             * All this fields will be handled by commitChangeToMultipleFields to save them in backend history properly */
                            const arrToUpd = []
                            if (!toSet[index]) {
                              toSet[index] = {}
                            }
                            toSet[index].endDate = event
                            endDateChange({
                              phases: toSet,
                              id,
                              idx: index,
                              arrToUpd,
                              fields: {
                                startDate: 'startDate',
                                endDate: 'endDate',
                                endDateMinDate: 'endDateMinDate',
                                endDateMaxDate: 'endDateMaxDate',
                                startDateMinDate: 'startDateMinDate',
                                startDateMaxDate: 'startDateMaxDate'
                              }
                            })
                            setFieldValue(id, toSet)
                            if (useMultiuser) {
                              commitChangeToMultipleFields({
                                array: arrToUpd,
                                onSuccess: (response) => {},
                                onFail: (error) => {
                                  console.log('error', { error })
                                },
                                token: muBag.token
                              })
                            }
                          }}
                          helperText={<div>{' '}</div>}
                        />
                        {endTooltip && (
                          <TooltipLabelIcon tooltip={endTooltip} />
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index + phases.length} className={classes.tr}>
                    <TableCell colSpan={3} className={classes.tcSpan4}>
                      <Grid container spacing={1}>
                        <Grid item container alignItems='center' xs={12} lg={2}>
                          <Trans>Primary Activities</Trans>
                          <MUPreviouslySavedValuesPanel
                            disabled={disabled}
                            muBag={muBag}
                            fieldId={commentsId}
                            useMultiuser={useMultiuser}
                          />
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <MUTextField
                            id={activitiesId}
                            muBag={muBag}
                            useMultiuser={useMultiuser}
                            multiline
                            minRows={activitiesRows || 5}
                            maxRows={
                              activitiesExpandable ? null : activitiesRows || 5
                            }
                            margin='normal'
                            disabled={disabled}
                            maxWords={
                              activitiesMaxWords && Number(activitiesMaxWords)
                            }
                            inputProps={{
                              maxLength:
                                activitiesMaxChar && Number(activitiesMaxChar)
                            }}
                            helperText={activitiesHelperArray}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>,
                  <TableRow
                    key={index + phases.length * 2}
                    className={classes.tr}
                  >
                    <TableCell colSpan={3} className={classes.tcSpan4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={2} container alignItems='center'>
                          <Trans>Comments</Trans>

                          <MUPreviouslySavedValuesPanel
                            disabled={disabled}
                            muBag={muBag}
                            fieldId={commentsId}
                            useMultiuser={useMultiuser}
                          />
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <MUTextField
                            id={commentsId}
                            muBag={muBag}
                            useMultiuser={useMultiuser}
                            multiline
                            minRows={commentsRows || 5}
                            maxRows={
                              commentsExpandable ? null : commentsRows || 5
                            }
                            margin='normal'
                            disabled={disabled}
                            maxWords={
                              commentsMaxWords && Number(commentsMaxWords)
                            }
                            inputProps={{
                              maxLength:
                                commentsMaxChar && Number(commentsMaxChar)
                            }}
                            helperText={commentsHelperArray}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ]
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  )
}

/** Milestone component for printing
 * @component
 * @returns {JSX.Element}
 * @param  {number} index - Index of the milestone.
 * @param  {string} phase - Phase of the milestone.
 * @param  {Object} milestone - Milestone object.
 */
export const Milestone = ({ index = 0, phase = null, milestone }) => {
  const startDate =
    moment.utc(milestone.startDate).format(dateFormat) === 'Invalid date'
      ? milestone.startDate
      : moment.utc(milestone.startDate).format(dateFormat)
  const endDate =
    moment.utc(milestone.endDate).format(dateFormat) === 'Invalid date'
      ? milestone.endDate
      : moment.utc(milestone.endDate).format(dateFormat)
  return (
    <Paper
      elevation={6}
      key={index}
      style={{ width: '100%', padding: 10 }}
      className='break-in-print'
    >
      <Typography
        style={{
          fontSize: 18,
          fonWeight: 'bold',
          textAlign: 'center',
          margin: 10
        }}
      >
        {phase
          ? (
            <Trans id={myI18n._(t`${phase.apiValue}`)} />
            )
          : (
              milestone.phase
            )}
      </Typography>
      <Grid container style={{ marginBottom: 15 }}>
        <Grid item xs style={{ textAlign: 'center' }}>
          <Typography className='form-print-subtitle'>
            <Trans>Start Date</Trans>
          </Typography>
          <Typography style={{ fontSize: 21 }}>{startDate}</Typography>
        </Grid>
        <Grid item xs style={{ textAlign: 'center' }}>
          <Typography className='form-print-subtitle'>
            <Trans>End Date</Trans>
          </Typography>
          <Typography style={{ fontSize: 21 }}>{endDate}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <div style={{ padding: 8 }}>
        <Typography className='form-print-subtitle'>
          <Trans>Primary Activities</Trans>
        </Typography>
        <Typography>{milestone.primaryActivities}</Typography>
      </div>
      <Divider />
      <div style={{ padding: 8 }}>
        <Typography className='form-print-subtitle'>
          <Trans>Comments</Trans>
        </Typography>
        <Typography>{milestone.comments}</Typography>
      </div>
    </Paper>
  )
}

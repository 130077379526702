import { Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Tooltip
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { languagesWithPortalTranslationId } from 'translation/I18nConnectedProvider'
import { hasEmptyValues } from 'utils'
import ConfigureMultilanguageTextField from '../../internal/ConfigureMultilanguageTextField'
import ConditionalElementEditor from './ConditionalElementEditor'

export const SectionActions = ({ section, index, supportedFormType }) => {
  const dispatch = useDispatch()
  const tree = useSelector((state) => state.formEditorTree)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [sectionTitleDialogOpen, setSectionTitleDialogOpen] =
    React.useState(false)

  const [sectionPDFEditDialogOpen, setSectionPDFEditDialogOpen] =
    React.useState(false)
  const isPdf =
    supportedFormType.includes('pdf') ||
    supportedFormType.includes('fillable-pdf')

  return (
    <>
      <Dialog open={dialogOpen} maxWidth='md' fullWidth>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Grid container justifyContent='space-between'>
            <Trans>Section conditions</Trans>
            <IconButton
              onClick={(e) => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ConditionalElementEditor
            conditions={section.conditions}
            depth={String(index)}
            elementType='section'
          />
        </DialogContent>
      </Dialog>

      <EditSectionTitle
        tree={tree}
        sectionIndex={index}
        open={sectionTitleDialogOpen}
        handleClose={(e) => {
          setAnchorEl(null)
          setSectionTitleDialogOpen(false)
        }}
      />

      <SetPdfPropsForSection
        tree={tree}
        sectionIndex={index}
        open={sectionPDFEditDialogOpen}
        handleClose={(e) => {
          setAnchorEl(null)
          setSectionPDFEditDialogOpen(false)
        }}
      />

      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.target)
        }}
      >
        <Icon fontSize='small'>menu</Icon>
      </IconButton>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        <Tooltip title={<Trans>Change section name</Trans>}>
          <MenuItem
            onClick={(e) => {
              setSectionTitleDialogOpen(true)
            }}
          >
            <Icon fontSize='small' style={{ padding: 12, color: '#757575' }}>
              edit
            </Icon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={<Trans>Copy</Trans>}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              dispatch({
                object: { ...cloneDeep(section), section: true },
                type: 'COPY'
              })
            }}
          >
            <Icon fontSize='small' style={{ padding: 12, color: '#757575' }}>
              content_copy
            </Icon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={<Trans>Configure section conditions</Trans>}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              setAnchorEl(null)
              setDialogOpen(true)
            }}
          >
            <Icon fontSize='small' style={{ padding: 12, color: '#757575' }}>
              flag
            </Icon>
          </MenuItem>
        </Tooltip>

        {isPdf && (
          <Tooltip title={<Trans>SECTION_ACTIONS_SET_PDF_PROPS_TITLE</Trans>}>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(null)
                setSectionPDFEditDialogOpen(true)
              }}
            >
              <Icon fontSize='small' style={{ padding: 12, color: '#757575' }}>
                picture_as_pdf
              </Icon>
            </MenuItem>
          </Tooltip>
        )}

        <DeleteSectionButton
          index={index}
          handleClose={() => {
            setAnchorEl(null)
          }}
        />
      </Menu>
    </>
  )
}

const DeleteSectionButton = ({ handleClose, index }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Are you sure you want to delete that section?</Trans>
          </DialogTitle>
          <DialogActions>
            <Button
              color='primary'
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation()
                setDialogOpen(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={(e) => {
                e.stopPropagation()
                setDialogOpen(false)
                // deleteSection(index)
                dispatch({
                  deleteIndex: index,
                  type: 'REMOVE_SECTION'
                })
                handleClose()
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Tooltip title={<Trans>Delete</Trans>}>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setDialogOpen(true)
          }}
        >
          <Icon fontSize='small' style={{ padding: 12, color: '#757575' }}>
            delete
          </Icon>
        </MenuItem>
      </Tooltip>
    </>
  )
}

const EditSectionTitle = ({ open, sectionIndex, handleClose, tree }) => {
  const initSectionName = languagesWithPortalTranslationId.reduce(
    (acc, lang) => {
      acc[lang] = ''
      return acc
    },
    {}
  )
  const [sectionName, setSectionName] = React.useState(initSectionName)
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      setSectionName({ ...tree.sections[sectionIndex]?.title })
    }
  }, [open])

  return (
    <Dialog open={open}>
      <div style={{ width: 600 }}>
        <DialogTitle>
          <Trans>Edit section title</Trans>
        </DialogTitle>
        <DialogContent>
          <ConfigureMultilanguageTextField
            label={<Trans>Section title</Trans>}
            resetToExisting
            value={sectionName}
            handleChange={(value) => {
              setSectionName(value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='outlined'
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={(e) => {
              e.stopPropagation()
              dispatch({
                type: 'FIELD',
                depth: [sectionIndex],
                fieldArray: [
                  {
                    fieldName: 'title',
                    fieldValue: sectionName
                  }
                ]
              })
              handleClose()
            }}
            disabled={hasEmptyValues(sectionName, ['en'])}
          >
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

const SetPdfPropsForSection = ({ open, sectionIndex, handleClose, tree }) => {
  const initialOrientation = tree.sections[sectionIndex]?.orientation
  const [orientation, setOrientation] = React.useState(initialOrientation)
  const dispatch = useDispatch()

  const handleChange = (event) => {
    setOrientation(event.target.value)
  }

  return (
    <Dialog open={open}>
      <div style={{ width: 600 }}>
        <DialogTitle>
          <Trans>SECTION_ACTIONS_SET_PDF_PROPS_TITLE</Trans>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id='orientation-select-label'>
              <Trans>SECTION_ACTIONS_ORIENTATION_DROPDOWN_LABEL</Trans>
            </InputLabel>
            <Select
              labelId='orientation-select-label'
              value={orientation || 'portrait'}
              onChange={handleChange}
            >
              <MenuItem value='landscape'>
                <Trans>SECTION_ACTIONS_LANDSCAPE_OPTION</Trans>
              </MenuItem>
              <MenuItem value='portrait'>
                <Trans>SECTION_ACTIONS_PORTRAIT_OPTION</Trans>
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='outlined'
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={(e) => {
              e.stopPropagation()
              dispatch({
                type: 'SET_SECTIONS',
                sections: [
                  ...tree.sections.slice(0, sectionIndex),
                  {
                    ...tree.sections[sectionIndex],
                    orientation
                  },
                  ...tree.sections.slice(sectionIndex + 1)
                ]
              })
              handleClose()
            }}
          >
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

import { t, Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Font, pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { dateFormat, defaultDocTitle } from 'app/appSettings'
import PigiarniqBold from 'app/assets/fonts/Pigiarniq-Bold.ttf'
import PigiarniqItalic from 'app/assets/fonts/Pigiarniq-Italic.ttf'
import PigiarniqRegular from 'app/assets/fonts/Pigiarniq.ttf'
import robotoBold from 'app/assets/fonts/Roboto-Bold.ttf'
import robotoBoldItalic from 'app/assets/fonts/Roboto-BoldItalic.ttf'
import robotoItalic from 'app/assets/fonts/Roboto-Italic.ttf'
import robotoRegular from 'app/assets/fonts/Roboto-Regular.ttf'

import { authRoles, checkAuth, hasRole } from 'app/auth/authRoles'
import { setUserData } from 'app/redux/actions/UserActions'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { getAccountsMap } from 'app/services/sfAuth/sfData/sfAccount'
import { createCaseByFlow } from 'app/services/sfAuth/sfData/sfCase'
import { getContactsMapByFlow } from 'app/services/sfAuth/sfData/sfContact'
import { uploadFile } from 'app/services/sfAuth/sfData/sfFiles'
import {
  getFormPage,
  getFormPages,
  getReusableFormPage
} from 'app/services/sfAuth/sfData/sfForms'
import {
  createOpportunityByFlow,
  opportunitiesStages,
  submitOpportunity
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { updatePrequalification } from 'app/services/sfAuth/sfData/sfPrequalification'
import { saveReportByFlow } from 'app/services/sfAuth/sfData/sfReports'
import { submitTechnicalAdvisory } from 'app/services/sfAuth/sfData/sfTechnicalAdvisories'
import { getNetwork, saveUser } from 'app/services/sfAuth/sfData/sfUser'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import _, { isError } from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position'
import { ErrorBoundary } from 'react-error-boundary'
import reactImageSize from 'react-image-size'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import sfOauthConfig from '../../services/sfAuth/sfAuthConfig'
import { parseExtensionPhoneToSF } from '../common/Formats'
import SaveWillOverrideWarningDialog from '../common/SaveWillOverrideWarningDialog'
import SavingFailedWarningDialog from '../common/SavingFailedWarningDialog'
import { FormTitle } from '../grants/FormTitle'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import RedirectWarning from '../page-layouts/RedirectWarning'
import {
  constructFormAddressString,
  getElementIdToFormPosition,
  parseFormLabelText
} from './common/Common'
import { formComponentTypes } from './components/formComponentTypes'
import { formObjectsToConnect } from './editor/FormWizard'
import { cloneInjectableElement } from './editor/InjectablesElementsPanel'
import { CollapseElement } from './form-page/CollapseElement'
import { FormContextProvider } from './form-page/FormContext'
import { FormDisplayContainer } from './form-page/FormDisplayContainer'
import FormElementGroup from './form-page/FormElementGroup'
import { StepperButtons } from './form-page/StepperButtons'
import {
  getDisabledIds,
  getValidCurrentStepIndex,
  isConditionMet,
  sectionConditionId
} from './FormHelpersConditions'
import { getInitialValues } from './FormHelpersFormik'
import {
  checkFormValidity,
  constructValidationSchema,
  correctableErrors,
  errorsToRender,
  getInitialTouched,
  isTrueDirty
} from './FormHelpersValidation'
import { connectedObjectQuery } from './FormsHelpersQueries'
import CursorIcon from './multiuser/components/CursorIcon'
import UsersEditingInSection from './multiuser/components/UsersEditingInSection'
import FormMultiuser, {
  handleMultiuserSaveRequest
} from './multiuser/FormMultiuser'
import {
  formRealmId,
  muFetchAllUsersInfo
} from './multiuser/grpcMultiuserEdit'
import FormPdfDocument from './pdf-components/FormPdf'

export const formItemPadding = 12
const DEFAULT_FORM_SAVE_REJECT = 'DEFAULT_FORM_SAVE_REJECT'
const baseLogoSize = 100

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    { src: robotoRegular }, // font-style: normal, font-weight: normal
    { src: robotoBold, fontWeight: 700 },
    { src: robotoItalic, fontStyle: 'italic' }
  ]
})
Font.register({
  family: 'Pigiarniq',
  fonts: [
    { src: PigiarniqRegular }, // font-style: normal, font-weight: normal
    { src: PigiarniqBold, fontWeight: 700 },
    { src: PigiarniqItalic, fontStyle: 'italic' },
    { src: PigiarniqBold, fontWeight: 700, fontStyle: 'italic' }
  ]
})

export const insertValueToSFObject = ({
  saveMap,
  value,
  fieldProps,
  sfObject,
  subObjectsMap,
  connectedObjectId,
  customConnectedField = false
}) => {
  const { name, subObject } = fieldProps
  if (subObject && name.indexOf('.') !== '-1') {
    const subObjectName = name.split('.')[0]
    const subFieldName = name.split('.')[1]
    if (subObjectName && subFieldName) {
      if (customConnectedField) {
        sfObject = sfObject[subObjectName]
      }
      subObjectsMap[sfObject.Id] = { type: subObject }
      if (!saveMap[sfObject.Id]) {
        saveMap[sfObject.Id] = {
          Id: sfObject.Id
        }
      } else {
        saveMap[sfObject.Id][subFieldName] = value
      }
    }
  } else {
    saveMap[connectedObjectId][name] = value
  }
}

export const getSFObjectFieldValue = (sfObject, props) => {
  if (!props) {
    return null
  }
  let sfValue = _.get(sfObject, props.name)
  if (props.subObject && sfValue) {
    const mainField = props.name.split('.')[0]
    const subField = props.name.split('.')[1]
    sfValue = sfObject[mainField][subField]
  }
  return sfValue
}

export const getMainConnected = (item) => {
  const connected = item.typeProps.connectedTo || []
  let toRet = {}
  connected.some((obj, index) => {
    const noField = formComponentTypes[item.elementType]?.noFieldConnect
    if (obj.connectedObject && (obj.connectedField || noField)) {
      toRet = obj
      return true
    }
    if (index === 0) {
      // console.error('Main connected object was empty!', item)
    } else {
      console.warn('Empty connection configured detected for:', item)
    }
    return false
  })
  return toRet
}

export const getConnectedObjectOfType = (item, type) => {
  const connected = item.typeProps.connectedTo || []
  let toRet = {}
  connected.some((obj, index) => {
    if (obj.connectedObject && (obj.forceType === type || obj.type === type)) {
      toRet = obj
      return true
    }
    return false
  })
  return toRet
}

export const mapFormElements = (data, langVersion, mapGroups = false) => {
  const returnObj = {}
  if (!data || !data?.sections) {
    return returnObj
  }
  const mapItem = ({
    item,
    returnObj,
    section,
    langVersion,
    sectionIndex,
    mapGroups
  }) => {
    if (item.elements) {
      item.elements.forEach((element) =>
        mapItem({
          item: element,
          returnObj,
          section,
          langVersion,
          sectionIndex,
          mapGroups
        })
      )
      if (mapGroups) {
        returnObj[item.id] = {
          ...item,
          title: parseFormLabelText({
            text: item.title,
            langVersion
          }),
          sectionIndex,
          sectionName: parseFormLabelText({
            text: section.title,
            langVersion
          })
        }
      }
    } else {
      returnObj[item.id] = {
        ...item,
        title: parseFormLabelText({
          text: item.title,
          langVersion
        }),
        sectionIndex,
        sectionName: section.title?.[langVersion]
      }
    }
  }

  data?.sections.forEach((section, sectionIndex) =>
    section.elements.forEach((item) =>
      mapItem({
        item,
        returnObj,
        section,
        langVersion,
        sectionIndex,
        mapGroups
      })
    )
  )
  return returnObj
}

function transactionName (lang, opportunity) {
  const requestNumber = opportunity.FGM_Base__Request_Number__c || ''
  const accountName = opportunity.Account?.Name || ''
  const opportunityName = opportunity.Name

  let result = ''

  if (requestNumber && accountName) {
    result += `${requestNumber} - ${accountName}`
  } else if (requestNumber) {
    result += requestNumber
  } else if (accountName) {
    result += accountName
  }

  if (result) {
    result += `, ${opportunityName}`
  } else {
    result = opportunityName
  }

  return result.substring(0, 76)
}

export const handleFormSave = ({
  values,
  appConfigurations,
  reduxBag,
  utilityBag = {},
  elementsMap,
  connectedMap,
  disabledIds = [],
  baseToSave = {},
  simulateInternal = false
}) => {
  const conn = SFAuthService.getConnection()
  const { enqueueSnackbar, reloadLastModifiedDates } = utilityBag
  let promises = []
  const afterMainSaveQueries = []
  const subObjectsMap = {}

  if (!connectedMap || Object.keys(connectedMap).length === 0) {
    return Promise.resolve()
  }
  console.log('form save values', values)
  const saveMap = { ...baseToSave }
  Object.keys(connectedMap).forEach((key) => {
    if (connectedMap[key].sfObject) {
      saveMap[key] = {
        ...saveMap[key],
        Id: saveMap[key]?.Id || connectedMap[key].sfObject.Id
      }
    }
  })
  Object.keys(values)
    .filter((key) => {
      const item = elementsMap[key]
      return !disabledIds.includes(key) || item?.forceSaveWhenDisabled
    })
    .forEach((key, index) => {
      const item = elementsMap[key]
      if (item) {
        const elementProps = formComponentTypes[item.elementType]
        const typeProps = item.typeProps
        const { connectsToMultipleObjects, savePromise } = elementProps
        const {
          isConnected,
          connectedTo = [],
          options,
          requiresRequest,
          isPhone,
          readOnly
        } = typeProps
        let sfObject, additionalSFInfo
        if (!readOnly) {
          let value = values[key]
          // Handle saving for form elements that should just save once, but have multiple objects connected
          if (connectsToMultipleObjects && savePromise) {
            const { promise, afterMainPromise } = savePromise({
              value,
              item,
              connectedObject: sfObject,
              additionalSFInfo,
              appConfigurations
            })
            if (afterMainPromise) {
              afterMainSaveQueries.push(promise)
            } else {
              promises.push(promise)
            }
          } else {
            // Handle saving for regular form elements which should save value to every object connecteds
            connectedTo.forEach((obj, index) => {
              let { connectedObject, connectedField } = obj
              if (
                isConnected &&
                connectedObject &&
                connectedMap[connectedObject]
              ) {
                sfObject = connectedMap[connectedObject].sfObject
                additionalSFInfo = connectedMap[connectedObject].additionalInfo
                let fieldName = connectedField?.name
                if (connectedField && fieldName.indexOf('.') !== '-1') {
                  const subObjectName = fieldName.split('.')[0]
                  const subFieldName = fieldName.split('.')[1]
                  if (subObjectName && subFieldName) {
                    fieldName = subFieldName
                    sfObject = sfObject[subObjectName]
                    connectedObject = sfObject.Id
                    subObjectsMap[sfObject.Id] = {
                      type: connectedField.subObject
                    }
                    if (!saveMap[sfObject.Id]) {
                      saveMap[sfObject.Id] = {
                        Id: sfObject.Id
                      }
                    }
                  }
                }
                if (options) {
                  options.forEach((option, index) => {
                    let isSelected = false
                    const defaultOpt = 'option' + index
                    const optionValue = isConnected
                      ? option.apiValue || defaultOpt
                      : defaultOpt
                    if (Array.isArray(value)) {
                      isSelected = value.includes(optionValue)
                    } else {
                      isSelected = value === optionValue
                    }
                    if (isSelected && option.requireDetails) {
                      if (option.connectedField) {
                        let fieldValue = values.other && values.other[key]
                        if (Array.isArray(fieldValue)) {
                          fieldValue = fieldValue[index]
                        }
                        saveMap[connectedObject][option.connectedField.name] =
                          fieldValue
                      }
                    }
                  })
                }
                if (typeProps.picklistType === 'multiselect' && value) {
                  value = value.join(';')
                }
                // If empty string is sent to SF instead of null  the field will be set to 0 instead of null
                if (
                  item.elementType === 'textInputNumeric' ||
                  item.elementType === 'numericSlider'
                ) {
                  if (!value && value !== 0) {
                    value = null
                  } else if (isPhone) {
                    value = parseExtensionPhoneToSF(value, values.other[key])
                  }
                }
                let saveHandled = false
                if (savePromise) {
                  saveHandled = true
                  const { promise, afterMainPromise } = savePromise({
                    value,
                    item,
                    connectedObject: sfObject,
                    additionalSFInfo,
                    appConfigurations
                  })
                  if (afterMainPromise) {
                    afterMainSaveQueries.push(promise)
                  } else {
                    promises.push(promise)
                  }
                }
                if (elementProps.extractSaveKey) {
                  saveHandled = true
                  elementProps.extractSaveKey({
                    saveMap,
                    subObjectsMap,
                    additionalSFInfo,
                    value,
                    values,
                    item,
                    connectedProps: obj,
                    connectedObjectId: connectedObject,
                    connectedTo,
                    sfObject,
                    appConfigurations
                  })
                }
                if (!saveHandled && !requiresRequest && fieldName) {
                  saveMap[connectedObject][fieldName] = value
                }
              }
            })
          }
        }
      }
    })

  Object.keys(saveMap).forEach((key) => {
    const objData = connectedMap[key]?.sfObject
    const type = objData ? objData.attributes.type : subObjectsMap[key].type
    const sfObjectData = formObjectsToConnect[type]
    if (saveMap[key].Id && Object.keys(saveMap[key]).length > 1) {
      if (sfObjectData && sfObjectData.saveFunction) {
        promises.push(
          sfObjectData.saveFunction(
            saveMap[key],
            reduxBag,
            utilityBag,
            simulateInternal
          )
        )
      } else {
        promises.push(conn.sobject(type).update(saveMap[key]))
      }
    } else if (!saveMap[key].Id) {
      if (type === 'Opportunity') {
        promises.push(createOpportunityByFlow(conn, saveMap[key]))
      } else {
        if (Object.keys(saveMap[key]).length > 0) {
          promises.push(conn.sobject(type).create(saveMap[key]))
        }
      }
    }
  })

  const hasError = (result) => {
    if (Array.isArray(result)) {
      return result.some((sub) => hasError(sub))
    } else {
      if (!result) {
        console.error(
          'No promise resolution configured or an unexpected error ocurred!'
        )
        return true
      }
      if (result.compositeResponse) {
        return result.compositeResponse.some((comp) => {
          if (!comp.body) {
            return false
          } else {
            return comp.body.some((sub) => !sub.success)
          }
        })
      }
      if ('isSuccess' in result) {
        if (result.outputValues && 'error' in result.outputValues) {
          return Boolean(result.outputValues.error)
        }
        return !result.isSuccess
      }
      if ('success' in result) {
        return !result.success
      }
      if ('hasErrors' in result) {
        return result.hasErrors
      }
      return false
    }
  }

  promises = promises.filter((f) => f)

  const handleReject = (reject) => {
    let correctableError, correctableErrorHandled

    if (Array.isArray(reject)) {
      if (reject.some((result) => !hasError(result))) {
        reloadLastModifiedDates()
      }
    }

    const showCorrectableError = (text) => {
      if (!correctableErrorHandled) {
        correctableErrorHandled = true
        enqueueSnackbar(text, {
          variant: 'error'
        })
      }
    }

    const checkErrorGravity = (error, handled = false) => {
      if (Array.isArray(error)) {
        error.forEach((error) => checkErrorGravity(error))
      } else if (error.compositeResponse) {
        error.compositeResponse.forEach((result) => {
          if (result.body) {
            result.body.forEach((result) => {
              checkErrorGravity(result)
            })
          }
        })
      } else if (error.errors) {
        error.errors.forEach((error) => {
          checkErrorGravity(error)
        })
      } else {
        let parsedError = error
        if (isError(error)) {
          parsedError = reject.toString()
        } else if (
          (error.errorCode ||
            error.statusCode === 'FIELD_CUSTOM_VALIDATION_EXCEPTION') &&
          error.message
        ) {
          parsedError = error.message
        }
        if (error.outputValues && error.outputValues.error) {
          parsedError = error.outputValues.error
        }
        if (typeof parsedError === 'string') {
          Object.keys(correctableErrors).forEach((key) => {
            const errorData = correctableErrors[key]
            if (errorData.logic(parsedError)) {
              correctableError = key
              if (errorData.text) {
                showCorrectableError(errorData.text)
              }
            }
          })
        }
      }
    }

    checkErrorGravity(reject)
    return Promise.reject({
      reject: correctableError || DEFAULT_FORM_SAVE_REJECT,
      errorStringified: reject
    })
  }

  return Promise.allSettled(promises).then(
    (result) => {
      const handleFinalResult = (combinedResult) => {
        let errorOcurred
        console.log('form saving result', combinedResult)
        combinedResult.forEach((promise) => {
          if (promise.status === 'rejected') {
            errorOcurred = true
          } else if (!errorOcurred) {
            errorOcurred =
              Array.isArray(promise.value) &&
              promise.value.some((res) => hasError(res))
          }
        })
        if (errorOcurred) {
          const rejectArray = combinedResult.map((promise) =>
            promise.status === 'rejected' ? promise.reason : promise.value
          )
          return handleReject(rejectArray)
        } else {
          return combinedResult.map((promise) =>
            promise.status === 'rejected' ? promise.reason : promise.value
          )
        }
      }
      if (afterMainSaveQueries.length > 0) {
        const postPromises = []
        afterMainSaveQueries.forEach((callback) => {
          if (Array.isArray(callback)) {
            return callback.forEach((chidCallback) => {
              postPromises.push(chidCallback())
            })
          } else {
            return callback()
          }
        })
        return Promise.allSettled(postPromises).then((postResult) => {
          return handleFinalResult([...result, ...postResult])
        })
      } else {
        return handleFinalResult(result)
      }
    },
    (reject) => {
      console.error('form error saving', reject)
      return handleReject(reject)
    }
  )
}

const getObjectsFieldsMap = ({ data, connectedMap = {}, describeMap }) => {
  const toReturn = {}
  if (!data) {
    return toReturn
  }
  data?.objectsConnected.forEach((object) => {
    const connData = connectedMap[object.identId]
    const toSet = {}
    if (connData) {
      const objName = connData.sfObject.attributes.type
      Object.keys(connData.fieldsMap).forEach((key) => {
        toSet[key] = {
          value: connData.sfObject[key],
          type: connData.fieldsMap[key].type
        }
      })
      Object.keys(connData.sfObject).forEach((key) => {
        const value = connData.sfObject[key]
        if (!toSet[key]) {
          toSet[key] = {
            value,
            type: 'object'
          }
        }
      })
      if (formObjectsToConnect[objName].additionalObjects) {
        formObjectsToConnect[objName].additionalObjects.forEach((obj) => {
          const { field } = obj
          const subObject = connData.sfObject[field]
          if (subObject) {
            const fields = describeMap[subObject.attributes?.type]?.fields
            const subObjectFieldsMap = {}
            if (fields) {
              fields.forEach((obj) => {
                subObjectFieldsMap[obj.name] = {
                  value: subObject[obj.name],
                  type: obj.type
                }
              })
            }
            toSet[field] = {
              value: subObjectFieldsMap,
              type: 'object'
            }
          }
        })
      }
      toReturn[object.name] = toSet
    }
  })
  return toReturn
}

const Form = ({ defaultFormType = 'editable', ...props }) => {
  const editingUsers = useSelector((state) => state.multiuser.editingUsers)
  const user = useSelector((state) => state.user)
  const multiuserEdit = editingUsers && Object.values(editingUsers).length > 0
  const mouseDetectRef = useRef()
  const [currentStep, setStep] = useState(0)

  return (
    <>
      {multiuserEdit && (
        <div style={{ height: 1 }}>
          {Object.values(editingUsers)
            .filter(
              (userObj) =>
                userObj.id !== user.userId && userObj.step === currentStep
            )
            .map((userObj, index) => {
              const { coordinates, color, name } = userObj
              return (
                coordinates &&
                mouseDetectRef?.current?.state?.elementDimensions && (
                  <CursorIcon
                    coordinates={coordinates}
                    dimensions={
                      mouseDetectRef?.current?.state?.elementDimensions
                    }
                    index={index}
                    color={color}
                    name={name}
                  />
                )
              )
            })}
        </div>
      )}
      <FormWrapped
        {...props}
        defaultFormType={defaultFormType}
        mouseDetectRef={mouseDetectRef}
        currentStep={currentStep}
        setStep={setStep}
      />
    </>
  )
}

const FormWrapped = React.memo(
  ({
    match = {},
    customTitleRender,
    forceFormType,
    saveCallback,
    fetchString,
    formId,
    scrollbarContentRef,
    disableTitle = false,
    pdfTitle,
    returnPdf,
    displayView,
    forceDisabled,
    disablePDF,
    inDialog,
    onDialogClose,
    mouseDetectRef,
    currentStep,
    setStep,
    renderData,
    showPdfComponent,
    showPrintComponent,
    showEditableComponent,
    defaultFormType,
    selectedFormType,
    style,
    containerViewType = 'paper',
    hideLoading,
    mainObject,
    infoBanner,
    banner,
    blockPDFDownload,
    shouldSavePdfOnSubmit, // if true, will save pdf in the Opportunity object on submit
    loadingComponent, // component to show when loading
    ...props
  }) => {
    const { params = {} } = match
    const isPreview = props.preview
    formId = formId || params.formId
    const [id, setId] = useState(fetchString || params.id)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [initialValues, setInitialValues] = useState()
    const [saving, setSaving] = useState(false)
    const [data, setData] = useState()
    const [network, setNetwork] = useState()
    const [validationSchema, setValidationSchema] = useState()
    const [validationSchemaRefs, setValidationSchemaRefs] = useState({})
    const [overrideWarningData, setOverrideWarningData] = useState()
    const [saveFailedData, setSaveFailedData] = useState()
    const [connectedMap, setConnectedMap] = useState({})
    const [describeMap, setDescribeMap] = useState({})
    const [configuration, setConfiguration] = useState()
    const [stage, setStage] = useState()
    const [readOnly, setReadOnly] = useState(false)
    const [pdfDisplay, setPdfDisplay] = useState(false)
    const [formViewType, setFormViewType] = useState(null)
    const [supportedFormViews, setSupportedFormViews] = useState([])
    const [insufficientAccess, setInsufficientAccess] = useState(false)
    const [wrongAccountRole, setWrongAccountRole] = useState(null)
    const [useMultiuser, setUseMultiuser] = useState(false)
    const [multiuserSessionToken, setMultiuserSessionToken] = useState(null)
    const [notSupportedError, setNotSupportedError] = useState(false)
    const [initialValuesErrors, setInitialValuesErrors] = useState(null)
    const settings = useSelector((state) => state.layout.settings)
    const theme = settings.themes[settings.activeTheme]
    const successColor = theme?.palette.success.main
    const errorColor = theme?.palette.error.main
    const user = useSelector((state) => state.user)
    const appConfigurations = useSelector((state) => state.configuration || {})
    const fundingStreams = useSelector((state) => state.fundingStreams.streams)
    const organization = useSelector((state) => state.organization || {})
    const avaliableOrganizations = useSelector(
      (state) => state.avaliableOrganizations
    )
    const simulateInternal = useSelector(
      (state) => state?.testingOptions?.simulateInternal
    )
    const isInternal = simulateInternal || sfOauthConfig.isInternal
    const collapsable = props.collapsable || 'none'
    const overrideFormLanguage = data?.overrideFormLanguage
    const { language } = user
    const langVersion_ = overrideFormLanguage || language?.split('_')[0] || 'en'
    const langVersion = overrideFormLanguage || (data?.translatedFor?.[langVersion_]?.value
      ? langVersion_
      : 'en')
    const organizationId = organization.id || params.organizationId
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const history = useHistory()
    const dispatch = useDispatch()
    const formikRef = useRef()
    const noConnectedObjects =
      connectedMap && Object.keys(connectedMap).length === 0
    /** Ref for the Stepper element. This is used to calculate width of panels with avatars in Stepper */
    const stepperRef = useRef(null)
    const { sections = [], title } = data || {}

    const showPrintButton = data?.showPrintButton

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    const objectsFieldsMap = getObjectsFieldsMap({
      data,
      connectedMap,
      describeMap
    })
    const formTitlePdf = parseFormLabelText({
      text: title?.[langVersion].text,
      langVersion,
      objectsFieldsMap,
      describeMap,
      returnString: true
    })

    const elementsMap = mapFormElements(data, langVersion)

    const scrollToTop = () => {
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          scrollbarContentRef.current.scrollToTop()
          scrollbarContentRef.current.handleWindowResize()
        }, 500)
      }
    }

    const scrollToY = (y) => {
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          scrollbarContentRef.current.scrollTop(y)
        }, 600)
      }
    }

    const navigateToError = ({ section, id }) => {
      setStep(section)
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          const domElement = document.getElementById(id)
          scrollbarContentRef.current.scrollTop(domElement.offsetTop)
        }, 300)
      } else {
        setTimeout(() => {
          const domElement = document.getElementById(id)
          domElement.scrollIntoView({ behavior: 'instant', block: 'start' })
        }, 300)
      }
    }

    const handleObjectMissing = () => {
      setInsufficientAccess(true)
      enqueueSnackbar(<Trans>Not all objects could be loaded!</Trans>, {
        variant: 'error'
      })
    }

    const checkIfFormValidationShouldRebuild = ({ sections, values }) => {
      let shouldRebuild = false
      const requiredFromConditions = []
      const nonRequiredFromConditions = []
      const validationInfoFromConditions = {}
      const elementIdToFormPosition = getElementIdToFormPosition(sections)

      const refsCopy = { ...validationSchemaRefs }
      const errors =
        formikRef && formikRef.current ? formikRef.current.errors : {}
      const checkRequiredConditions = (item) => {
        if (item.elements) {
          item.elements.forEach((element) => {
            checkRequiredConditions(element)
          })
        }
        if (item.conditions) {
          const activeConditions = []
          item.conditions
            .filter((condition) =>
              [
                'required',
                'notRequired',
                'minFiles',
                'maxMin',
                'numMin',
                'hide',
                'show'
              ].includes(condition.state)
            )
            .forEach((condition) => {
              const { conditionMet, state } = isConditionMet({
                condition,
                elementsMap,
                values,
                langVersion,
                connectedMap,
                errors,
                pathname
              })

              if (conditionMet) {
                activeConditions.push({ condition, state })
              }
              const requiredCondition = activeConditions.find(c => c.state === 'required')
              const notRequiredCondition = activeConditions.find(c => c.state === 'notRequired')
              const hideCondition = activeConditions.find(c => c.state === 'hide')
              const showCondition = activeConditions.find(c => c.state === 'show')
              const hasRequiredConflict = requiredCondition && notRequiredCondition
              const hasVisibilityConflict = hideCondition && showCondition

              const numericConditions = {
                maxMin: activeConditions.filter(c => c.state === 'maxMin'),
                numMin: activeConditions.filter(c => c.state === 'numMin'),
                minFiles: activeConditions.filter(c => c.state === 'minFiles')
              }

              // Check for numeric conflicts
              const numericConflicts = Object.entries(numericConditions)
                .filter(([_, conditions]) => conditions.length > 1)
                .map(([type, conditions]) => ({
                  type,
                  values: conditions.map(c => c.condition[type])
                }))

              const showRequiredError = hasRequiredConflict && ['required', 'notRequired'].includes(state)
              const showVisibilityError = hasVisibilityConflict && ['hide', 'show'].includes(state)
              const showNumericError = numericConflicts.length > 0 && Object.keys(numericConditions).includes(state)

              if (
                showRequiredError ||
                showVisibilityError ||
                showNumericError
              ) {
                const elementIndex = elementIdToFormPosition[item.id]
                if (showRequiredError) {
                  console.error(
                    `Required conflict detected for element ${elementIndex}.${item.title?.en}`
                  )
                }

                if (showVisibilityError) {
                  console.error(
                    `Visibility conflict detected for element ${elementIndex}.${item.title?.en}`
                  )
                }

                numericConflicts.forEach((conflict) => {
                  console.error(
                    `Conflicting ${conflict.type} conditions detected for element ${elementIndex}.${item.title?.en}: 
                    Multiple values [${conflict.values.join(', ')}] are active. Using first value.`
                  )
                })
                return
              }

              const yupField = validationSchema?.fields[item.id]
              const currentRefs = { ...refsCopy[item.id] }

              if (state === 'required' || state === 'notRequired') {
                if (conditionMet) {
                  if (state === 'notRequired') {
                    nonRequiredFromConditions.push(item.id)
                  } else {
                    requiredFromConditions.push(item.id)
                  }
                  if (yupField && state === 'notRequired') {
                    shouldRebuild = true
                  } else if (!yupField && state !== 'notRequired') {
                    shouldRebuild = true
                  }
                } else {
                  if (state === 'required') {
                    nonRequiredFromConditions.push(item.id)
                  }
                  if (
                    yupField &&
                    state === 'required' &&
                    !item.typeProps.required
                  ) {
                    shouldRebuild = true
                  } else if (!yupField && state === 'notRequired') {
                    // shouldRebuild = true
                  } else if (item.typeProps.required && !yupField) {
                    shouldRebuild = true
                  }
                }
              }

              const validationConditions = [
                { key: 'maxMin', typePropsKey: 'max' },
                { key: 'numMin', typePropsKey: 'min' },
                { key: 'minFiles', typePropsKey: 'minFiles' }
              ]
              validationConditions.forEach((obj) => {
                const { key, typePropsKey } = obj

                if (state === key) {
                  if (conditionMet) {
                    validationInfoFromConditions[item.id] = {
                      [typePropsKey]: +condition[key]
                    }
                  }

                  const parameter = +condition[key]

                  if (parameter) {
                    const currentParameter = currentRefs?.[typePropsKey]
                    const paramsMissmatch =
                      currentParameter &&
                      currentParameter !== parameter

                    const paramsAreTheSame =
                      currentParameter &&
                      currentParameter === parameter

                    if (
                      (!currentParameter || paramsMissmatch) &&
                      conditionMet
                    ) {
                      // shouldRebuild = true
                    } else if (paramsAreTheSame && !conditionMet) {
                      shouldRebuild = true
                      delete validationInfoFromConditions[item.id]
                    }
                  }
                }
              })
            })
        }
      }
      sections.forEach((section) => checkRequiredConditions(section))
      if (shouldRebuild) {
        console.log(
          'validation change from conditions detected. Form will rebuild validation schema',
          validationInfoFromConditions
        )
        setValidationSchemaRefs(validationInfoFromConditions)
        setValidationSchema(
          constructValidationSchema({
            data,
            describeMap,
            requiredFromConditions,
            nonRequiredFromConditions,
            validationInfoFromConditions,
            langVersion: user.language?.split('_')[0] || 'en',
            formikRef
          })
        )
      }
    }

    const fetchData = ({ multiuserReload = false, reloadId }) => {
      return Promise.all([
        renderData ? Promise.resolve(renderData) : getFormPage(formId),
        getNetwork(),
        getReusableFormPage()
      ])
        .then(async ([result, network, reusableFormPage]) => {
          console.log('form loaded', result, network, multiuserReload)

          const reusableComponents =
            reusableFormPage?.config.injectableComponents || []
          const reusableComponentsMap = {}
          reusableComponents.forEach((component) => {
            const addToMap = (item) => {
              reusableComponentsMap[item.id] = item
              if (item.elements) {
                item.elements.forEach((child) => {
                  addToMap(child)
                })
              }
            }
            addToMap(component)
          })

          const updateInjectedItem = (item) => {
            if (item.elements) {
              item.elements.forEach((element, index) => {
                if (element.injectableId) {
                  if (reusableComponentsMap[element.injectableId]) {
                    const updated = cloneInjectableElement({
                      item: element,
                      componentsMap: reusableComponentsMap
                    })
                    item.elements[index] = updated
                  } else {
                    delete element.injectableId
                    delete element.injectableName
                  }
                }
                updateInjectedItem(element)
              })
            }
          }
          result.sections.forEach((section) => {
            updateInjectedItem(section)
          })
          setNetwork(network)

          for (const item of ['header', 'footer']) {
            if (result?.pdfProps?.[item]?.logoUrl) {
              const logoUrl = result?.pdfProps?.[item]?.logoUrl
              const logoSize =
                result?.pdfProps?.[item]?.logoSize || baseLogoSize
              const { width, height } = await reactImageSize(logoUrl)
              if (width >= height) {
                const wRel = width / logoSize
                result.pdfProps[item].logoDimensions = {
                  width: width / wRel,
                  height: height / wRel
                }
              } else if (height > width) {
                const hRel = height / logoSize
                result.pdfProps[item].logoDimensions = {
                  width: width / hRel,
                  height: height / hRel
                }
              }
            }
          }

          if (result.objectsConnected && result.objectsConnected.length > 0) {
            const fetchUrl = reloadId || id

            if (fetchUrl) {
              let noId
              const objArray = fetchUrl.split(';')
              objArray.forEach((string, index) => {
                if (string.includes('NO_OBJECT_ID')) {
                  noId = true
                  console.error(string)
                }
                const ident = string.split('=')[0]
                let objInfo
                result.objectsConnected.some((obj) => {
                  if (obj.identId === ident) {
                    objInfo = obj
                  }
                  return obj.identId === ident
                })
                if (objInfo && objInfo.type === 'User' && !isPreview) {
                  objArray[index] = ident + '=' + user.userId
                }
              })
              if (noId) {
                enqueueSnackbar(
                  <Trans>
                    Ids were not provided for all objects used in this form.
                    Contact your administrator
                  </Trans>,
                  { variant: 'error' }
                )
                return Promise.reject()
              }
              if (objArray.join(';') !== fetchUrl) {
                // history.push(
                //   '/elasticform/' + formId + '/' + objArray.join(';')
                // )
                return Promise.reject()
              }
            }

            return connectedObjectQuery(result, {
              handleObjectMissing,
              langVersion,
              id: fetchUrl,
              enqueueSnackbar
            }).then(
              ({ connectedMap, describeMap }) => {
                let preventAccess, stage
                let shouldDisable = Boolean(forceDisabled)

                setValidationSchema(
                  constructValidationSchema({
                    data: result,
                    describeMap,
                    langVersion,
                    formikRef
                  })
                )

                const programManagerSession =
                  params.organizationId && checkAuth(authRoles.pm, user.role)
                if (programManagerSession) {
                  shouldDisable = true
                }

                Object.values(connectedMap).forEach((data) => {
                  const obj = data?.sfObject
                  const additionalInfo = data?.additionalInfo
                  const objName = obj.attributes.type
                  fundingStreams.forEach((config) => {
                    const sameForm = config.form && config.form === formId
                    const sameRecordType =
                      obj.RecordType && obj.RecordType.Id === config.recordType
                    if (sameForm && sameRecordType) {
                      setConfiguration(config)
                    }
                  })

                  if (
                    obj.UserRecordAccess &&
                    !obj.UserRecordAccess.HasEditAccess &&
                    !isPreview
                  ) {
                    shouldDisable = true
                  }

                  if (result.readOnly || returnPdf) {
                    shouldDisable = true
                  }
                  if (!isPreview) {
                    if (objName === 'Opportunity') {
                      if (mainObject === 'Opportunity') {
                        stage = obj.StageName
                        if (
                          obj.StageName !== opportunitiesStages.IN_PROGRESS &&
                          obj.StageName !==
                            opportunitiesStages.MORE_INFO_REQUIERED
                        ) {
                          shouldDisable = true
                        }
                      }

                      if (
                        obj.ProcessInstances &&
                        obj.ProcessInstances.records
                      ) {
                        if (
                          obj.ProcessInstances.records.some(
                            (process) => process.Status === 'Pending'
                          )
                        ) {
                          enqueueSnackbar(
                            <Trans>
                              Application is Locked: Contact the Administrator
                            </Trans>,
                            { variant: 'error' }
                          )
                          shouldDisable = true
                        }
                      }
                    } else if (objName === 'FGM_Base__Grantee_Report__c') {
                      stage = obj.FGM_Base__Status__c

                      if (
                        !['Requested', 'More info required'].includes(stage)
                      ) {
                        shouldDisable = true
                      }
                    } else if (objName === 'TechnicalAdvisoryAssignment__c') {
                      if (obj.Status__c === 'Submitted') {
                        shouldDisable = true
                      }
                    } else if (
                      objName === 'Account' &&
                      result.restrictAccessForRoles
                    ) {
                      let userRole
                      additionalInfo.accountMembers.some((member) => {
                        if (member.UserId === user.userId) {
                          userRole = member.TeamMemberRole
                          return true
                        }
                        return false
                      })
                      if (
                        result.restrictAccessForRoles[userRole] ||
                        !userRole
                      ) {
                        const restricType =
                          result.restrictAccessForRoles[userRole]

                        if (restricType === 'preventAccessBlock') {
                          enqueueSnackbar(
                            <Trans>
                              Your member role has insufficient access to view
                              this page!
                            </Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          history.push('/grants/home')
                          preventAccess = true
                        } else if (restricType === 'disable') {
                          shouldDisable = true
                          setWrongAccountRole('warning')
                        } else if (restricType === 'preventAccessMessage') {
                          setWrongAccountRole('prevent')
                        }
                      }
                    } else if (objName === 'Pre_Qualification__c') {
                      if (mainObject === 'Pre_Qualification__c') {
                        stage = obj.Stage__c
                        if (
                          stage !== opportunitiesStages.IN_PROGRESS &&
                          stage !== opportunitiesStages.MORE_INFO_REQUIERED
                        ) {
                          shouldDisable = true
                        }
                      }
                    }
                  }
                })

                setStage(stage)
                setDisabled(shouldDisable)
                const fakeInitialValues = getInitialValues({
                  enqueueSnackbar,
                  data: result,
                  connectedMap
                })
                if (fakeInitialValues.errors) {
                  setInitialValuesErrors(fakeInitialValues.errors)
                  setLoading(false)
                  return
                }
                const map = mapFormElements(result, langVersion)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach((key) => {
                  const question = map[key]
                  const value = fakeInitialValues[key]
                  if (question.elementType === 'connectContact') {
                    if (!contacts.includes(value) && value) {
                      contacts.push(value)
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (!accounts.includes(value) && value) {
                      accounts.push(value)
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                      getContactsMapByFlow(contacts),
                      getAccountsMap(accounts)
                    ])
                    : Promise.resolve().then((r) => [{}, {}])

                return mapPromise.then(([contactsMap, accountsMap]) => {
                  let formType =
                    selectedFormType || forceFormType || defaultFormType
                  if (!formType) {
                    if (result.readOnly) {
                      formType = 'printable'
                    } else if (result.showPdfDownload) {
                      formType = 'pdf'
                    } else {
                      formType = 'editable'
                    }
                  }
                  const supported = result.supportedFormType || [formType]
                  setSupportedFormViews(supported)
                  setNotSupportedError(!supported.includes(formType))
                  setPdfDisplay(formType.includes('pdf'))
                  setFormViewType(formType)
                  setReadOnly(formType === 'printable')
                  setConnectedMap(connectedMap)
                  setDescribeMap(describeMap)
                  setData(result)
                  const initialValues = getInitialValues({
                    data: result,
                    enqueueSnackbar,
                    connectedMap,
                    contactsMap,
                    accountsMap
                  })
                  if (initialValues.errors) {
                    setInitialValuesErrors(initialValues.errors)
                    setLoading(false)
                    return
                  }
                  const multiuser =
                    Boolean(result.enableMultiuser && id) &&
                    (checkAuth(authRoles.tester, user.role) ||
                      organization.additionalFeatures.includes(
                        'multiuser_testing'
                      )) &&
                    !result.readOnly &&
                    (!shouldDisable || programManagerSession) &&
                    !preventAccess

                  if (multiuser) {
                    if (multiuserReload) {
                      const { values } = formikRef.current
                      setInitialValues({
                        ...initialValues,
                        muUsers: values.muUsers,
                        muInfo: values.muInfo
                      })
                    } else {
                      setInitialValues(initialValues)
                    }
                  } else {
                    setInitialValues(initialValues)
                  }
                  setUseMultiuser(multiuser)
                  setLoading(preventAccess)
                })
              },
              (reject) => {
                console.error('no object found', reject, result, fetchUrl)
                enqueueSnackbar(<Trans>No object found!</Trans>, {
                  variant: 'error'
                })
              }
            )
          } else {
            const initialValues = getInitialValues({
              data: result,
              enqueueSnackbar,
              connectedMap: {}
            })
            if (initialValues.errors) {
              setInitialValuesErrors(initialValues.errors)
              setLoading(false)
              return
            }
            setInitialValues(initialValues)
            setLoading(false)
            setData(result)
          }
        })
        .catch((error) => {
          console.error('error loading form', error)
        })
    }

    const trySaving = useCallback(
      ({ values, type = 'Save' }) => {
        if (
          overrideWarningData ||
          !SFAuthService.user ||
          readOnly ||
          saving ||
          saveFailedData
        ) {
          return
        }
        setSaving(true)
        if (useMultiuser) {
          return handleMultiuserSaveRequest({
            token: multiuserSessionToken,
            saveType: type,
            handleBlockSave: () => {
              enqueueSnackbar(
                <Trans>
                  You cannot save the form, some fields are currently edited by
                  other users!
                </Trans>,
                {
                  variant: 'error'
                }
              )
              setSaving(false)
            }
          })
        } else {
          const savingSnackbar = enqueueSnackbar(null, {
            persist: true,
            content: (key) => ProgressSnackbar(<Trans>Saving</Trans>)
          })

          const handleError = (err) => {
            console.error('error saving form', err)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }

          if (!data?.displayesSavedInMeantimeWarning || useMultiuser) {
            return handleSave({ values, snackbar: savingSnackbar }).catch(
              (err) => {
                handleError(err)
              }
            )
          }

          return connectedObjectQuery(data, {
            id,
            langVersion,
            enqueueSnackbar,
            handleObjectMissing
          })
            .then((result) => {
              const currentConnectedMap = result.connectedMap
              if (Object.keys(currentConnectedMap).length === 0) {
                enqueueSnackbar(<Trans>You lost connection!</Trans>, {
                  variant: 'error'
                })
                return Promise.reject()
              }
              const wasSavedInMeantime = Object.keys(currentConnectedMap).some(
                (key) => {
                  const objectSaved = currentConnectedMap[key].sfObject
                  const objectNow = connectedMap[key].sfObject
                  const savedDate = moment.utc(objectSaved.LastModifiedDate)
                  const currentDate = moment.utc(objectNow.LastModifiedDate)
                  return savedDate.isAfter(currentDate)
                }
              )
              if (wasSavedInMeantime) {
                const dataToPass = {
                  type: 'form'
                }
                const savedValues = getInitialValues({
                  data,
                  enqueueSnackbar,
                  connectedMap: currentConnectedMap
                })

                const current = {}
                const saved = {}
                const map = mapFormElements(data, langVersion)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach((key) => {
                  const question = map[key]
                  if (question.elementType === 'connectContact') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      contacts.push(savedValues[key])
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      accounts.push(savedValues[key])
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                      getContactsMapByFlow(contacts),
                      getAccountsMap(accounts)
                    ])
                    : Promise.resolve().then((r) => [{}, {}])
                return mapPromise.then(([contactsMap, accountsMap]) => {
                  closeSnackbar(savingSnackbar)
                  Object.keys(values).forEach((key) => {
                    const question = map[key]
                    if (question) {
                      dataToPass[key] = question
                      const toText =
                        formComponentTypes[question.elementType].valueToText
                      const parseValue =
                        formComponentTypes[question.elementType]
                          .parseValueToCompare
                      const { isConnected } = question.typeProps
                      let connectedFieldDetails
                      const { connectedField, connectedObject } =
                        getMainConnected(question)
                      if (isConnected && connectedField) {
                        connectedFieldDetails = extractFormFieldDetails({
                          connectedField,
                          connectedMap,
                          describeMap,
                          connectedObject
                        })
                      }
                      if (toText) {
                        current[key] = {
                          value: parseValue
                            ? parseValue(values[key])
                            : values[key],
                          ...toText(values[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                        saved[key] = {
                          value: parseValue
                            ? parseValue(savedValues[key])
                            : savedValues[key],
                          ...toText(savedValues[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                      } else {
                        console.warn(
                          'No value to text function configured for: ',
                          question.elementType
                        )
                      }
                    }
                  })
                  setOverrideWarningData({
                    current,
                    saved,
                    formData: dataToPass
                  })
                  return Promise.resolve()
                })
              } else {
                handleSave({ values, snackbar: savingSnackbar })
              }
            })
            .catch((err) => {
              handleError(err)
            })
        }
      },
      [
        data,
        connectedMap,
        saving,
        saveFailedData,
        overrideWarningData,
        useMultiuser,
        multiuserSessionToken
      ]
    )

    /* Fetch data if form is loaded and url changes (So if the same form is used to load different object) */
    useEffect(() => {
      if (((formId && id) || !id) && !data) {
        setLoading(true)
        fetchData({})
      }
    }, [id, formId])

    useEffect(() => {
      if (selectedFormType) {
        setPdfDisplay(selectedFormType.includes('pdf'))
        setFormViewType(selectedFormType)
        setReadOnly(selectedFormType === 'printable')
      }
    }, [selectedFormType])

    useEffect(() => {
      if (
        renderData?.supportedFormType &&
        !renderData?.supportedFormType?.includes(
          selectedFormType || defaultFormType
        )
      ) {
        enqueueSnackbar(<Trans>FORM_WRONG_DEFAULT_FORMTYPE_ALERT</Trans>, {
          variant: 'error'
        })
      }
    }, [])

    useEffect(() => {
      if (id && Object.keys(connectedMap).length > 0 && !isPreview) {
        const objArray = id.split(';')
        objArray.forEach((string, index) => {
          const ident = string.split('=')[0]
          const obj = connectedMap[ident]
          const connectedObject = obj.sfObject
          if (
            connectedObject &&
            connectedObject.attributes.type === 'Account'
          ) {
            objArray[index] = ident + '=' + organization.id
          }
        })
        if (objArray.join(';') !== id) {
          setLoading(true)
          setId(objArray.join(';'))
          fetchData({
            reloadId: objArray.join(';')
          })
        }
      }
    }, [organization.id])

    useEffect(() => {
      // This fixes issues with bold font not being used in first render of pdf
      Font.load({ fontFamily: 'Roboto' })
      Font.load({ fontFamily: 'Roboto', fontStyle: 'italic' })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700 })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700, fontStyle: 'italic' })
      Font.load({ fontFamily: 'Pigiarniq' })
      Font.load({ fontFamily: 'Pigiarniq', fontWeight: 700 })
      Font.load({ fontFamily: 'Pigiarniq', fontStyle: 'italic' })
      Font.load({
        fontFamily: 'Pigiarniq',
        fontWeight: 700,
        fontStyle: 'italic'
      })
    }, [])

    useEffect(() => {
      document.title = formTitlePdf
      return () => {
        document.title = defaultDocTitle
      }
    }, [formTitlePdf])

    const location = useLocation()
    const pathname = location?.pathname

    useEffect(() => {
      // This checks if first section is disabled and sets the initial selected section to first non-disabled section
      if (initialValues) {
        const initialDisabledIds = getDisabledIds({
          sections,
          elementsMap,
          values: initialValues,
          langVersion,
          connectedMap,
          describeMap,
          errors: {},
          pathname
        })
        if (currentStep === 0) {
          let valid = currentStep
          while (initialDisabledIds.includes(sectionConditionId + valid)) {
            valid++
            if (valid >= sections.length) {
              valid = 0
              break
            }
          }
          if (valid > 0) {
            setStep(valid)
          }
        }
      }
    }, [initialValues])

    useEffect(() => {
      // Handle autosave
      const minutes = data && Number(data?.autosave)

      if (loading || !minutes || isPreview || disabled || readOnly) {
        return
      }
      if (minutes !== 0) {
        const handle = setInterval(() => {
          if (formikRef && isTrueDirty(formikRef.current)) {
            const { values } = formikRef.current
            if (useMultiuser) {
              // only first user should commence autosave
              muFetchAllUsersInfo({
                realmId: id,
                userId: user.userId
              }).then(({ users }) => {
                let myUserInfo
                Object.values(users).some((obj) => {
                  if (obj.id === user.userId) {
                    myUserInfo = obj
                    return true
                  }
                  return false
                })
                if (myUserInfo && myUserInfo.logOrder === 0) {
                  trySaving({ values, type: 'Autosave' })
                }
              })
            } else {
              trySaving({ values })
            }
          }
        }, 60000 * minutes)
        return () => {
          clearInterval(handle)
        }
      }
    }, [
      currentStep,
      loading,
      trySaving,
      initialValues,
      useMultiuser,
      id,
      user.userId,
      disabled,
      readOnly
    ])

    useEffect(() => {
      // Update SF fields language info on user language change
      if (data) {
        connectedObjectQuery(data, {
          id,
          returnOnlyDescribe: true,
          langVersion,
          enqueueSnackbar,
          handleObjectMissing
        }).then(({ describeMap }) => {
          const newMap = { ...connectedMap }
          Object.values(newMap).forEach((obj) => {
            const { objectType } = obj
            const sfData = describeMap[objectType]
            obj.fieldsMap = sfData.fieldsMap
          })
          setConnectedMap(newMap)
          setDescribeMap(describeMap)
          setValidationSchema(
            constructValidationSchema({
              data,
              describeMap,
              langVersion,
              formikRef
            })
          )
        })
      }
    }, [user.language])

    useEffect(() => {
      if (formikRef.current) {
        formikRef.current.validateForm()
      }
    }, [validationSchema])

    const addMethodToValidationSchema = (method, id) => {
      const yupObj = constructValidationSchema({
        data,
        describeMap,
        langVersion: user.language?.split('_')[0] || 'en',
        returnRaw: true,
        formikRef
      })
      if (!yupObj[id]) {
        yupObj[id] = method
      } else {
        yupObj[id].concat(method)
      }
      setValidationSchema(Yup.object().shape(yupObj))
    }

    const handleSave = ({ values, snackbar }) => {
      const savingSnackbar =
        snackbar ||
        enqueueSnackbar(null, {
          persist: true,
          content: (key) => ProgressSnackbar(<Trans>Saving</Trans>)
        })
      const disabledIds = getDisabledIds({
        sections,
        elementsMap,
        values,
        langVersion,
        connectedMap,
        describeMap,
        errors: formikRef.current.errors,
        pathname
      })
      const isValid = Object.keys(formikRef.current.errors).length === 0
      return handleFormSave({
        values,
        elementsMap,
        connectedMap,
        appConfigurations: fundingStreams,
        disabledIds,
        reduxBag: {
          dispatch,
          user,
          organization,
          avaliableOrganizations,
          appConfigurations: fundingStreams
        },
        utilityBag: {
          closeSnackbar,
          enqueueSnackbar,
          formId,
          stage,
          reloadLastModifiedDates
        },
        simulateInternal
      }).then(
        (result) => {
          fetchData({ multiuserReload: useMultiuser }).then((r) => {
            console.log('form saved', result)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: 'success'
            })
            dispatch(
              setUserData({ isUserProfileValid: Boolean(isValid) })
            )
            if (saveCallback) {
              const savedMap = {}
              Object.entries(elementsMap).forEach(([key, element]) => {
                const fieldValue = values[key]
                const { connectedField, connectedObject } =
                  getMainConnected(element)
                if (connectedField && connectedObject) {
                  const { objectType } = connectedMap[connectedObject]
                  if (!savedMap[objectType]) {
                    savedMap[objectType] = {}
                  }
                  savedMap[objectType][connectedField.name] = fieldValue
                }
              })
              saveCallback({
                isValid,
                savedMap
              })
            }
            if (
              formId === appConfigurations.FORM_USER_PROFILE &&
              !organization.id &&
              hasRole(user.role, authRoles.grantee) &&
              !user.userObject.redirectedToJoinOrganization &&
              !isInternal
            ) {
              history.push('/grants/JoinOrganization')
              saveUser({
                Id: user.userId,
                Redirected_To_Join_Organization__c: true
              })
            }
          })
        },
        (rejectObj) => {
          const { reject, errorStringified } = rejectObj
          console.error(reject)
          closeSnackbar(savingSnackbar)
          setSaving(false)
          const current = {}
          const map = mapFormElements(data, langVersion)
          const dataToPass = {
            type: 'form'
          }
          Object.keys(values).forEach((key) => {
            const question = map[key]
            if (question) {
              dataToPass[key] = question
              const toText =
                formComponentTypes[question.elementType].valueToText
              const parseValue =
                formComponentTypes[question.elementType].parseValueToCompare
              const { isConnected } = question.typeProps
              let connectedFieldDetails
              const { connectedField, connectedObject } =
                getMainConnected(question)
              if (isConnected && connectedField) {
                connectedFieldDetails = extractFormFieldDetails({
                  connectedField,
                  connectedMap,
                  describeMap,
                  connectedObject
                })
              }
              if (toText) {
                current[key] = {
                  title: question.title,
                  value: parseValue
                    ? parseValue(values[key], { saveFailed: true })
                    : values[key],
                  ...toText(values[key], question, {
                    connectedFieldDetails,
                    saveFailed: true
                  })
                }
              } else {
                console.warn(
                  'No value to text function configured for: ',
                  question.elementType
                )
              }
            }
          })
          let connectedAccount, connectedOpportunity
          Object.values(connectedMap).forEach((obj) => {
            if (obj.objectType === 'Opportunity') {
              connectedOpportunity = obj.sfObject.Id
            }
            if (obj.objectType === 'Account') {
              connectedAccount = obj.sfObject.Id
            }
          })
          const toPass = _.cloneDeep(values)
          delete toPass.muInfo
          delete toPass.muUsers

          if (
            data?.displaySaveFailedDialog &&
            reject === DEFAULT_FORM_SAVE_REJECT
          ) {
            // Don't create for my user
            if (user.userId !== '0055X000000KQ81QAG') {
              createCaseByFlow({
                title: 'Saving failed',
                type: 'Saving Failed',
                language: user.language,
                description: JSON.stringify({
                  values: toPass,
                  reject,
                  errorStringified,
                  path: history.location.pathname
                }),
                contact: user.userObject.contactId,
                opportunityId: connectedOpportunity,
                organization: connectedAccount || organization.id,
                skipAssigment: false,
                owner: '005Am000000Kas0IAC'
              })
            }
            setSaveFailedData({
              current,
              formData: dataToPass
            })
          } else {
            // Don't create for my user
            if (user.userId !== '0055X000000KQ81QAG') {
              createCaseByFlow({
                title: 'Saving failed',
                type: 'Saving Failed',
                language: user.language,
                description: JSON.stringify({
                  values: toPass,
                  reject,
                  errorStringified,
                  path: history.location.pathname
                }),
                contact: user.userObject.contactId,
                opportunityId: connectedOpportunity,
                organization: connectedAccount || organization.id,
                skipAssigment: false,
                owner: '005Am000000Kas0IAC'
              })
            }
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }
          return reject
        }
      )
    }

    const reloadLastModifiedDates = () => {
      return connectedObjectQuery(data, {
        enqueueSnackbar,
        langVersion,
        id,
        handleObjectMissing
      }).then((queryResult) => {
        const toSet = { ...connectedMap }
        Object.keys(toSet).forEach((key) => {
          const setObj = toSet[key].sfObject
          const nowObj = queryResult.connectedMap[key]?.sfObject
          if (nowObj && setObj && setObj.LastModifiedDate) {
            setObj.LastModifiedDate = nowObj.LastModifiedDate
          }
        })
        setConnectedMap(toSet)
      })
    }

    const _handleSubmit = ({ values, pdfDocument }) => {
      setSaving(true)
      const submitText = <Trans>Submitting</Trans>

      const snackKey = enqueueSnackbar(null, {
        variant: 'info',
        persist: true,
        content: (key) => ProgressSnackbar(submitText)
      })

      const baseHandleError = (error, snackbarText) => {
        console.log('error submitting', error)
        enqueueSnackbar(snackbarText || <Trans>Error Submitting</Trans>, {
          variant: 'error'
        })
      }

      const baseHandleSuccess = ({ result, successText }) => {
        console.log('submitted and got ', result)
        return fetchData({ multiuserReload: useMultiuser }).then(() => {
          enqueueSnackbar(successText, {
            variant: 'success'
          })
        })
      }
      const disabledIds = getDisabledIds({
        sections,
        elementsMap,
        values,
        langVersion,
        connectedMap,
        describeMap,
        errors: formikRef.current.errors,
        pathname
      })
      return Promise.all([
        handleFormSave({
          values,
          elementsMap,
          connectedMap,
          appConfigurations: fundingStreams,
          disabledIds,
          reduxBag: {
            dispatch,
            user,
            organization,
            avaliableOrganizations
          },
          utilityBag: {
            closeSnackbar,
            enqueueSnackbar,
            formId,
            stage,
            reloadLastModifiedDates
          },
          simulateInternal
        }),
        getFormPages()
      ]).then(([result, formPages]) => {
        if (noConnectedObjects) {
          enqueueSnackbar(<Trans>There is no object to submit!</Trans>, {
            variant: 'error'
          })
        } else {
          const promises = []
          Object.values(connectedMap).forEach((data) => {
            const connectedObject = data?.sfObject
            const type = connectedObject.attributes.type

            if (mainObject === type) {
              if (type === 'Opportunity') {
                const organizationDetailsFormId =
                  appConfigurations.FORM_ORGANIZATION_DETAILS
                let organizationDetailsForm
                organizationDetailsFormId &&
                  formPages.some((form) => {
                    if (organizationDetailsFormId === form.id) {
                      organizationDetailsForm = form.config
                    }
                    return organizationDetailsFormId === form.id
                  })
                let checkOrganizationValidity = Promise.resolve(true)
                if (organizationDetailsForm) {
                  checkOrganizationValidity = checkFormValidity({
                    formId: organizationDetailsFormId,
                    id: constructFormAddressString({
                      ids: {
                        Account: organization.id,
                        User: user.userId
                      },
                      configuration,
                      objectsConnected:
                        organizationDetailsForm.objectsConnected
                    }),
                    pathname
                  })
                }
                promises.push(
                  checkOrganizationValidity.then((isValid) => {
                    if (!isValid) {
                      baseHandleError(
                        'organization details form is not valid',
                        <Trans>
                          You cannot submit the application until you fill out
                          all field marked as required in the Organisation
                          Details
                        </Trans>
                      )
                      return Promise.reject(
                        new Error('organization details form is not valid')
                      )
                    } else {
                      return Promise.all([
                        submitOpportunity(connectedObject.Id),
                        pdfDocument
                          ? pdf(pdfDocument)
                            .toBlob()
                            .then((blob) => {
                              return blob.arrayBuffer().then((buffer) => {
                                return uploadFile(
                                  {
                                    name:
                                        transactionName(
                                          langVersion,
                                          connectedObject
                                        ) + '.pdf',
                                    tags: 'grant_application',
                                    opportunityId: connectedObject.Id
                                  },
                                  buffer,
                                  network.Id,
                                  true
                                )
                              })
                            })
                          : Promise.resolve()
                      ])
                        .then((res) => {
                          return baseHandleSuccess({
                            result,
                            successText: <Trans>Submitted Application</Trans>
                          })
                        })
                        .catch((error) => {
                          baseHandleError(error)
                        })
                    }
                  })
                )
              }
              if (type === 'TechnicalAdvisoryAssignment__c') {
                promises.push(
                  submitTechnicalAdvisory(connectedObject.Id).then(
                    (result) => {
                      return baseHandleSuccess({
                        result,
                        successText: (
                          <Trans>Submitted Technical Advisory Assigment</Trans>
                        )
                      })
                    },
                    (reject) => {
                      baseHandleError(reject)
                    }
                  )
                )
              }
              if (type === 'Pre_Qualification__c') {
                promises.push(
                  updatePrequalification({
                    Id: connectedObject.Id,
                    Stage__c: 'Submitted'
                  }).then(
                    (result) => {
                      return baseHandleSuccess({
                        result,
                        successText: (
                          <Trans>SUBMITTED_PREQUALIFICATION_SNACKBAR</Trans>
                        )
                      })
                    },
                    (reject) => {
                      baseHandleError(reject)
                    }
                  )
                )
              }
              if (
                type === 'FGM_Base__Grantee_Report__c' &&
                mainObject === type
              ) {
                promises.push(
                  saveReportByFlow({
                    Id: connectedObject.Id,
                    FGM_Base__Status__c: 'Submitted'
                  }).then(
                    (result) => {
                      if (!result[0].isSuccess) {
                        baseHandleError(
                          result,
                          <Trans>Error Submitting Report</Trans>
                        )
                      } else {
                        enqueueSnackbar(<Trans>Submitted Report</Trans>, {
                          variant: 'success'
                        })
                        history.push('/grants/Reports')
                      }
                    },
                    (reject) => {
                      baseHandleError(reject)
                    }
                  )
                )
              }
            }
          })
          return Promise.all(promises).then((result) => {
            setSaving(false)
            closeSnackbar(snackKey)
          })
        }
      })
    }

    const handleSubmit = props.confirmationDialog
      ? () => {
          setOpenConfirmationDialog(true)
        }
      : _handleSubmit

    const returnInDialog = ({
      component,
      loading,
      disableSave,
      pdfDocument
    }) => {
      if (loading) {
        return (
          <Dialog open maxWidth='lg' fullWidth>
            <DialogTitle>
              <Grid
                container
                wrap='nowrap'
                alignItems='flex-end'
                justifyContent='flex-end'
              >
                <Grid item>
                  <IconButton
                    onClick={() => {
                      onDialogClose()
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ width: '500px', minHeight: '60vh' }}>
              <Loading />
            </DialogContent>
          </Dialog>
        )
      }
      const formTitle = parseFormLabelText({
        text: data?.title[langVersion]?.text,
        langVersion,
        objectsFieldsMap,
        describeMap,
        returnString: true
      })
      return (
        <Dialog
          open
          maxWidth='lg'
          fullWidth
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle>
            <Grid
              container
              wrap='nowrap'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs>
                <Typography
                  variant='h6'
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {formTitle}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    onDialogClose()
                  }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ minHeight: '60vh' }}>
            {component}
          </DialogContent>
        </Dialog>
      )
    }

    if (initialValuesErrors) {
      return (
        <>
          <Typography variant='h6' style={{ marginBottom: 10 }}>
            <Trans>FORM_INITIAL_VALUES_ERRORS_LABEL</Trans>:
          </Typography>
          {Object.entries(initialValuesErrors).map(([key, value]) => {
            return (
              <Alert key={key} severity='error' style={{ marginBottom: 10 }}>
                <AlertTitle>
                  {value.type ? formComponentTypes[value.type].text : key}
                </AlertTitle>
                {value.message}
              </Alert>
            )
          })}
        </>
      )
    }

    if (loading || !initialValues) {
      if (hideLoading) {
        return null
      }
      if (inDialog) {
        return returnInDialog({
          loading: true,
          disableSave: true
        })
      }
      return <Loading atCenter />
    }

    if (insufficientAccess) {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>
                  You don't have access to all objects used in this form!
                </Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              <Trans>You cannot view or edit this page</Trans>
            </div>
          </Alert>
        </div>
      )
    }

    if (wrongAccountRole === 'prevent') {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>You cannot view or edit this page</Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              {/* <Trans>You cannot view or edit this page</Trans>
              {'. '} */}
              <Trans>You can request higher organization access</Trans>{' '}
              <Link href='/grants/Organizations'>
                <Trans>here</Trans>
              </Link>
            </div>
          </Alert>
        </div>
      )
    }

    const noStepper = sections.length < 2 || pdfDisplay

    const toReturn = (
      <ErrorBoundary
        fallback={
          <FormDisplayContainer
            styleJSON={data?.style}
            style={style}
            viewType={containerViewType}
          >
            <Alert severity='error'>
              <AlertTitle>
                <Trans>FORM_ERROR_LOADING</Trans>
              </AlertTitle>
            </Alert>
          </FormDisplayContainer>
        }
      >
        <Formik
          innerRef={formikRef}
          validateOnBlur={false}
          validateOnChange
          validationSchema={!forceDisabled && validationSchema}
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          initialTouched={getInitialTouched(data)}
        >
          {({
            values,
            initialValues,
            isValidating,
            validateForm,
            setValues,
            setFieldValue,
            errors,
            ...fProps
          }) => {
            console.log('values', values, connectedMap, formId)
            const dirty = isTrueDirty(formikRef.current)

            const disabledIds = getDisabledIds({
              sections,
              elementsMap,
              values,
              langVersion,
              connectedMap,
              describeMap,
              pdfView: returnPdf || pdfDisplay,
              errors,
              pathname
            })

            const formTitle = parseFormLabelText({
              text: data?.title?.[langVersion].text,
              langVersion,
              objectsFieldsMap,
              describeMap,
              returnString: true,
              renderProps: {
                connectedMap
              }
            })

            if (notSupportedError) {
              return (
                <Alert severity='error'>
                  <AlertTitle>
                    <Trans>FORM_USED_AND_SUPPORTED_FORM_TYPES_MISSMATCH</Trans>
                  </AlertTitle>
                </Alert>
              )
            }

            let pdfDocument
            // check if there is a submit button among the elements.
            // If there is and there is also at least one element with pdf view support, it means that we need a pdfDocument to be prepared and passed to the submit button

            const hasSupportedFormType =
              supportedFormViews.includes('pdf') ||
              supportedFormViews.includes('fillable-pdf')

            const generatePDF = (props) => {
              const shouldCalculateFixedElementsInHook =
                props?.shouldCalculateFixedElementsInHook === undefined
                  ? true
                  : props.shouldCalculateFixedElementsInHook
              let pdfType = formViewType
              if (pdfType !== 'pdf' && pdfType !== 'fillable-pdf') {
                pdfType = 'pdf'
              }

              return (
                <FormPdfDocument
                  sections={sections}
                  title={formTitlePdf}
                  data={data}
                  describeMap={describeMap}
                  objectsFieldsMap={objectsFieldsMap}
                  connectedMap={connectedMap}
                  langVersion={langVersion}
                  formViewType={pdfType}
                  values={values}
                  elementsMap={elementsMap}
                  formikRef={formikRef}
                  disabledIds={disabledIds}
                  shouldCalculateFixedElementsInHook={
                    shouldCalculateFixedElementsInHook
                  }
                />
              )
            }

            const confirmationDialog = props.confirmationDialog
              ? React.cloneElement(props.confirmationDialog, {
                open: openConfirmationDialog,
                setOpen: setOpenConfirmationDialog,
                submitCallback: () => {
                  const pdfDocument = generatePDF({
                    shouldCalculateFixedElementsInHook: false
                  })
                  _handleSubmit({ values, pdfDocument })
                }
              })
              : null

            if (hasSupportedFormType && (returnPdf || pdfDisplay)) {
              pdfDocument = generatePDF()

              if (returnPdf) {
                return returnPdf(pdfDocument, formTitlePdf, fetchData)
              }

              if (pdfDisplay) {
                return (
                  <FormDisplayContainer
                    styleJSON={data?.style}
                    style={{
                      ...style,
                      display: 'flex',
                      height: '100%',
                      minHeight: returnInDialog && '60vh'
                    }}
                    viewType={containerViewType}
                  >
                    {!returnInDialog && (
                      <Typography variant='h4' style={{ textAlign: 'center' }}>
                        {formTitle}
                      </Typography>
                    )}
                    <PDFDownloadLink
                      fileName={formTitle}
                      document={pdfDocument}
                    >
                      <Grid
                        container
                        justifyContent='flex-end'
                        style={{ padding: 20 }}
                      >
                        <Button
                          color='primary'
                          variant='contained'
                          disabled={loading}
                        >
                          <Trans>Download</Trans>
                          <Icon style={{ marginLeft: 5 }}>download</Icon>
                        </Button>
                      </Grid>
                    </PDFDownloadLink>
                    <PDFViewer showToolbar={false} style={{ flexGrow: 1 }}>
                      {pdfDocument}
                    </PDFViewer>
                  </FormDisplayContainer>
                )
              }
            }

            const sectionTitle = parseFormLabelText({
              text: sections[currentStep]?.title,
              langVersion,
              objectsFieldsMap,
              describeMap,
              renderProps: {
                connectedMap
              }
            })

            if (!isValidating && !forceDisabled && validationSchema) {
              checkIfFormValidationShouldRebuild({ sections, values })
            }

            const handleNext = () => {
              let toSet = currentStep + 1
              while (disabledIds.includes(sectionConditionId + toSet)) {
                toSet++
                if (toSet >= sections.length) {
                  toSet = currentStep
                  break
                }
              }
              scrollToTop()
              setStep(toSet)
            }

            const handleBack = () => {
              let toSet = currentStep - 1

              while (disabledIds.includes(sectionConditionId + toSet)) {
                toSet--
                if (toSet < 0) {
                  toSet = currentStep
                  break
                }
              }
              scrollToTop()
              setStep(toSet)
            }

            const noSaveButton =
              readOnly || pdfDisplay || forceDisabled || disabled
            const saveDisabled =
              disabled ||
              (isPreview &&
                !sfOauthConfig.isTesting &&
                !hasRole(user.role, authRoles.tester)) ||
              noConnectedObjects ||
              !dirty

            const StepperButtonsElement = (
              <StepperButtons
                formTitle={formTitlePdf}
                hideSaveButton={noSaveButton}
                pdfDocument={
                  showPrintButton &&
                  supportedFormViews.includes('pdf') &&
                  pdfDocument
                }
                noStepper={noStepper}
                saving={saving}
                disableSave={saveDisabled}
                elementsMap={elementsMap}
                muBag={{
                  formId: formRealmId(organizationId, id),
                  token: multiuserSessionToken,
                  userId: user.userId
                }}
                handleSave={() => trySaving({ values })}
                handleNext={handleNext}
                handleBack={handleBack}
                steps={sections.filter(
                  (section, index) =>
                    !disabledIds.includes(sectionConditionId + index)
                )}
                activeStep={getValidCurrentStepIndex({
                  realIndex: currentStep,
                  disabledIds
                })}
                useMultiuser={useMultiuser}
                generatePDF={generatePDF}
                showPDFButton={showPrintButton}
                disablePDFDownload={blockPDFDownload}
              />
            )
            const parsedErrors = errorsToRender({
              errors,
              objectsFieldsMap,
              describeMap,
              disabledIds,
              elementsMap,
              langVersion,
              values
            })

            const renderTitle = !noStepper &&
            !inDialog &&
            !['horizontal', 'vertical'].includes(collapsable)

            const formContent = (
              <FormContextProvider
                network={network}
                generatePDF={generatePDF}
                formTitle={formTitlePdf}
              >
                {confirmationDialog}
                <FormDisplayContainer
                  styleJSON={data?.style}
                  style={style}
                  viewType={containerViewType}
                >
                  <ReactCursorPosition
                    isEnabled={useMultiuser}
                    activationInteractionMouse={INTERACTIONS.HOVER}
                    ref={mouseDetectRef}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100%'
                    }}
                  >
                    <CollapseElement
                      orientation={collapsable}
                      title={formTitle}
                    >
                      {!data?.translatedFor?.[langVersion_]?.value && (
                        <Alert severity='info' style={{ marginBottom: 10 }}>
                          <Trans>NOT_SUPPORTED_FORM_LANGUAGE</Trans>
                        </Alert>
                      )}

                      {wrongAccountRole === 'warning' && (
                        <Alert severity='warning' style={{ marginBottom: 10 }}>
                          <AlertTitle>
                            <Typography variant='h6'>
                              <Trans>You cannot edit this page</Trans>
                            </Typography>
                          </AlertTitle>
                          <div style={{ marginTop: 5 }}>
                            {/* <Trans>You cannot edit this page</Trans>
                    {'. '} */}
                            <Trans>
                              You can request higher organization access
                            </Trans>{' '}
                            <Link href='/grants/Organizations'>
                              <Trans>here</Trans>
                            </Link>
                          </div>
                        </Alert>
                      )}
                      {renderTitle && <>
                        {customTitleRender
                          ? customTitleRender(formTitle)
                          : <Typography
                              variant='h6'
                              style={{
                                textAlign: 'center',
                                marginBottom: 20
                              }}
                            >
                            {formTitle}
                          </Typography>}
                      </>}
                      {infoBanner}
                      {data?.displaySaveFailedDialog && (
                        <SavingFailedWarningDialog
                          open={Boolean(saveFailedData)}
                          data={saveFailedData}
                          handleClose={() => {
                            setSaveFailedData(null)
                          }}
                          fileName={
                            myI18n?._(t`Extracted data`) +
                            ' - ' +
                            moment.utc().format(dateFormat)
                          }
                        />
                      )}
                      {data?.displayUnsavedWarning &&
                        !noSaveButton &&
                        !saveDisabled && (
                          <RedirectWarning
                            open={dirty && !values.kickedOutOfForm}
                            handleSave={() => {
                              handleSave({ values })
                            }}
                          />
                      )}
                      <SaveWillOverrideWarningDialog
                        handleSave={() => {
                          handleSave({ values })
                        }}
                        handleClose={() => {
                          setOverrideWarningData(null)
                          setSaving(false)
                        }}
                        open={Boolean(overrideWarningData)}
                        data={overrideWarningData}
                      />
                      {!noStepper && (
                        <Stepper
                          nonLinear
                          style={{
                            backgroundColor:
                              containerViewType === 'paper'
                                ? 'initial'
                                : 'unset'
                          }}
                          activeStep={getValidCurrentStepIndex({
                            realIndex: currentStep,
                            disabledIds
                          })}
                          orientation='horizontal'
                          alternativeLabel
                          ref={stepperRef}
                        >
                          {sections.map((section, index) => {
                            if (
                              disabledIds.includes(sectionConditionId + index)
                            ) {
                              return null
                            }
                            const hasErrors =
                              parsedErrors.filter(
                                (obj) => index === obj.sectionIndex
                              ).length > 0
                            return (
                              <Step
                                itemType='step'
                                id='step'
                                key={index}
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  scrollToTop()
                                  setStep(index)
                                }}
                              >
                                <StepLabel id='label'>
                                  <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                  >
                                    {parseFormLabelText({
                                      text: section.title?.[langVersion],
                                      langVersion,
                                      objectsFieldsMap,
                                      describeMap,
                                      renderProps: {
                                        connectedMap
                                      }
                                    })}
                                    {!readOnly && !disabled && (
                                      <Icon
                                        style={{
                                          color: hasErrors
                                            ? errorColor
                                            : successColor,
                                          paddingLeft: 6,
                                          fontSize: 16
                                        }}
                                      >
                                        {hasErrors ? 'close' : 'done'}
                                      </Icon>
                                    )}
                                  </Grid>
                                  {/* Render panel with avatars */}
                                  {useMultiuser && stepperRef.current && (
                                    <UsersEditingInSection
                                      sections={sections}
                                      stepperRef={stepperRef}
                                      scrollToY={scrollToY}
                                      index={index}
                                    />
                                  )}
                                </StepLabel>
                              </Step>
                            )
                          })}
                        </Stepper>
                      )}
                      {!disableTitle && (
                        <FormTitle title={noStepper ? formTitle : sectionTitle}>
                          {StepperButtonsElement}
                        </FormTitle>
                      )}
                      {banner &&
                        <Alert severity={banner.type || 'info'}>
                          <AlertTitle>
                            {banner.title}
                          </AlertTitle>
                        </Alert>}
                      {sections[currentStep]?.elements?.map((item, index) => {
                        // if there is a pdfDocument, we need to pass it to the submit button
                        // so we need to check if there is a submit button among the elements
                        const hasSubmitButton = checkSubmitBtnElement(item)

                        // Disable elements if the section state is "disable" and the section condition is met
                        const currentSection = sections[currentStep]
                        const currentSectionCondition =
                          currentSection?.conditions?.[0]
                        const checkCurrentSectionCondition =
                          currentSectionCondition
                            ? isConditionMet({
                              condition: currentSectionCondition,
                              elementsMap,
                              values,
                              langVersion,
                              describeMap,
                              connectedMap,
                              errors
                            })
                            : true
                        const sectionDisabled =
                          checkCurrentSectionCondition.conditionMet &&
                          checkCurrentSectionCondition?.state === 'disable'

                        return (
                          <div key={index}>
                            <FormElementGroup
                              item={{
                                ...item,
                                value: values[item.id]
                              }}
                              lastInSection={
                                sections[currentStep].elements.length ===
                                index + 1
                              }
                              formViewType={formViewType}
                              values={values}
                              disabledIds={disabledIds}
                              baseErrors={errors}
                              errors={parsedErrors}
                              objectsFieldsMap={objectsFieldsMap}
                              connectedMap={connectedMap}
                              elementsMap={elementsMap}
                              describeMap={describeMap}
                              formikRef={formikRef}
                              langVersion={langVersion}
                              key={index}
                              navigateToError={navigateToError}
                              useMultiuser={useMultiuser}
                              muBag={{
                                formId: formRealmId(organizationId, id),
                                token: multiuserSessionToken,
                                userId: user.userId
                              }}
                              sectionIndex={currentStep}
                              preview={isPreview}
                              reloadLastModifiedDates={reloadLastModifiedDates}
                              saveButtonClicked={trySaving}
                              handleSubmit={handleSubmit}
                              saving={saving}
                              configuration={configuration}
                              disabled={Boolean(
                                disabled ||
                                  saving ||
                                  readOnly ||
                                  sectionDisabled
                              )}
                              saveDisabled={Boolean(disabled || isPreview)}
                              renderPrint={readOnly}
                              pdfDocument={hasSubmitButton && pdfDocument}
                            />
                          </div>
                        )
                      })}
                      <div style={{ paddingTop: 15, paddingRight: 15 }}>
                        {Boolean(!noStepper) && StepperButtonsElement}
                      </div>
                    </CollapseElement>
                  </ReactCursorPosition>
                </FormDisplayContainer>
              </FormContextProvider>
            )

            if (useMultiuser) {
              return (
                <FormMultiuser
                  elementsMap={elementsMap}
                  realmId={formRealmId(organizationId, id)}
                  mouseDetectRef={mouseDetectRef}
                  multiuserSessionToken={multiuserSessionToken}
                  setMultiuserSessionToken={setMultiuserSessionToken}
                  currentStep={currentStep}
                  formikRef={formikRef}
                  setSaving={setSaving}
                  handleSave={handleSave}
                  fetchData={fetchData}
                  programManagerSession={
                    params.organizationId && checkAuth(authRoles.pm, user.role)
                  }
                  initialValues={initialValues}
                  formMetadata={{
                    sessionName: parseFormLabelText({
                      returnString: true,
                      text: data?.title[langVersion]?.text,
                      objectsFieldsMap: getObjectsFieldsMap({
                        data,
                        connectedMap,
                        describeMap
                      }),
                      describeMap
                    }),
                    organizationName: organization.organisationsName,
                    organizationId,
                    formId,
                    url: id
                  }}
                  handleMultiuserLoadSuccess={({ initialValues }) => {
                    setInitialValues(initialValues)
                    setLoading(false)
                  }}
                >
                  {formContent}
                </FormMultiuser>
              )
            } else {
              return formContent
            }
          }}
        </Formik>
      </ErrorBoundary>
    )
    return inDialog
      ? returnInDialog({
        component: toReturn
      })
      : toReturn
  }
)

export const extractFormFieldDetails = ({
  connectedField,
  connectedMap,
  describeMap,
  connectedObject
}) => {
  const { subObject, name } = connectedField
  if (!subObject) {
    if (!connectedMap[connectedObject]) {
      return {}
    }
    return connectedMap[connectedObject].fieldsMap[name]
  } else {
    const subFieldName = name.split('.')[1]
    const subObjectName = name.split('.')[0]
    if (!subFieldName || !subObjectName) {
      console.error('Could not find field details for: ', name)
      return null
    }
    const fields = describeMap[subObjectName]?.fields
    let details
    fields.some((field) => {
      if (field.name === subFieldName) {
        details = field
      }
      return field.name === subFieldName
    })
    return details
  }
}

export const FormPage = ({
  label,
  disabled,
  langVersion,
  errors,
  handleSubmit,
  connectedMap = {},
  values,
  elements,
  sections
}) => {
  const location = useLocation()
  const { pathname } = location

  const elementsMap = mapFormElements({ sections }, langVersion)
  const disabledIds = getDisabledIds({
    sections,
    elementsMap,
    values,
    pathname
  })

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant='h3' style={{ textAlign: 'center' }}>
        {label}
      </Typography>
      <div>
        {elements.map((item, index) => {
          if (disabledIds.includes(item.id)) {
            return null
          }

          return (
            <FormElementGroup
              item={{
                ...item,
                value: values[item.id]
              }}
              disabled={disabled}
              values={values}
              lastInSection={elements.length === index + 1}
              disabledIds={disabledIds}
              baseErrors={errors}
              errors={errorsToRender({
                errors,
                objectsFieldsMap: {},
                disabledIds,
                elementsMap,
                langVersion,
                values
              })}
              connectedMap={connectedMap}
              elementsMap={elementsMap}
              langVersion={langVersion}
              key={index}
              sectionIndex={0}
              handleSubmit={handleSubmit}
            />
          )
        })}
      </div>
    </Paper>
  )
}

export default Form

/** Function to check if there is a submit button among the elements
 * @param {object} element - element object
 * @returns {boolean} - true if there is a submit button among the elements
 */
export function checkSubmitBtnElement (element) {
  if (element.elements) {
    return element.elements.some(checkSubmitBtnElement)
  }
  return element.elementType === 'submitButton'
}

import { Milestone } from './FormMilestones'

export const FormMilestonesPrintEditor = ({ title }) => {
  const milestone = {
    phase: 'Phase Placeholder',
    startDate: 'Start Date Placeholder',
    endDate: 'End Date Placeholder',
    primaryActivities: 'Primary Activities Placeholder',
    comments: 'Comments Placeholder'
  }
  return (
    <>
      <div className='form-print-title-small'>{title}</div>
      <Milestone milestone={milestone} />
    </>
  )
}

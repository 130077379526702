import { formTranslationsStructure } from 'app/views/forms/editor/formTranslationsStructure'

function transformOldSfProps (obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      // Recurse if the property is an object
      transformOldSfProps(obj[key])
    } else if (Array.isArray(obj[key])) {
      // Recurse if the property is an array
      obj[key].forEach(element => transformOldSfProps(element))
    } else {
      const match = key.match(/(EN|FR)$/)
      if (match) {
        const language = match[1].toLowerCase()
        const newKey = key.slice(0, -2)

        // Create or update the property with the nested structure
        obj[newKey] =
          obj[newKey] && typeof obj[newKey] === 'object' ? obj[newKey] : {}
        // if (!obj[newKey]?.[language]) obj[newKey] = {}
        if (!obj[newKey][language]) {
          obj[newKey][language] = obj[key]
        }
        // delete obj[key]
      } else if (key === 'elementType') {
        const type = obj[key]
        const translationProps = formTranslationsStructure[type]
        if (translationProps) {
          Object.keys(translationProps).forEach(typePropsKey => {
            const struct = translationProps[typePropsKey]
            const { keyOverride, nonUppercase } = struct
            const shouldInsert =
              !obj.typeProps[typePropsKey] ||
              (obj.typeProps[typePropsKey] &&
                typeof obj.typeProps[typePropsKey] !== 'object')
            if (keyOverride) {
              const oldValueEN =
                obj.typeProps[typePropsKey + 'En'] ||
                obj.typeProps[typePropsKey + keyOverride.en]
              const oldValueFR = obj.typeProps[typePropsKey + keyOverride.fr]
              if (shouldInsert) {
                obj.typeProps[typePropsKey] = {
                  en: oldValueEN,
                  fr: oldValueFR
                }
              }
              // Lousy workaround to keep both values at the same time as html key woiuld be ovewritten
              obj.typeProps[typePropsKey + 'En'] = oldValueEN
            }
            if (nonUppercase) {
              const oldValueEN = obj.typeProps[typePropsKey + 'En']
              const oldValueFR = obj.typeProps[typePropsKey + 'Fr']
              if (shouldInsert) {
                obj.typeProps[typePropsKey] = {
                  en: oldValueEN,
                  fr: oldValueFR
                }
              }
            }
          })
        }
      }
    }
  }
  return obj
}

export { transformOldSfProps }

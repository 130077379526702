import { Path, Svg, Text, View } from '@react-pdf/renderer'
import { parseFormLabelText, pdfDefaultFontSize } from 'app/views/forms/common/Common'

const colors = {
  success: '#4caf50',
  error: '#f5543b',
  warning: '#ff9800',
  info: '#2196f3'
}

const standardBannerColors = {
  success: '#EDF7ED',
  error: '#FEEDEB',
  warning: '#FFF4E5',
  info: '#E8F4FD'
}

export const FormBannerPdf = ({
  title,
  typeProps: { variant, state, text },
  ...props
}) => {
  const parsedText = parseFormLabelText({ text, ...props })

  variant = variant || 'standard'
  state = state || 'info'

  const bannerColor = variant === 'standard'
    ? standardBannerColors[state]
    : variant === 'filled'
      ? colors[state]
      : variant === 'outlined'
        ? 'transparent'
        : colors[state]

  const iconColor = variant === 'standard'
    ? colors[state]
    : variant === 'filled'
      ? '#131212'
      : variant === 'outlined'
        ? colors[state]
        : colors[state]

  const BannerText = ({ text, top }) => {
    top = top || false
    return (
      <Text style={{
        fontSize: pdfDefaultFontSize + 2,
        fontWeight: variant === 'filled' ? 'semibold' : 'normal',
        marginLeft: top ? 10 : 30,
        marginTop: top ? 2 : 0,
        color: 'rgba(0, 0, 0, 0.87)'
      }}
      >
        {text}
      </Text>
    )
  }

  return (
    <View style={{
      flexDirection: 'column',
      paddingHorizontal: 15,
      paddingVertical: 8,
      borderRadius: 4,
      backgroundColor: bannerColor,
      borderColor: iconColor,
      borderWidth: variant === 'outlined' ? 1 : 0
    }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Svg viewBox='0 0 24 24' width={20} height={20}>
          {state === 'info' && <Path
            fill={iconColor}
            d='M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z'
                               />}
          {state === 'warning' && <Path
            fill={iconColor}
            d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'
                                  />}
          {state === 'error' && <Path
            fill={iconColor}
            d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
                                />}
          {state === 'success' && <Path
            fill={iconColor}
            d='M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z'
                                  />}
        </Svg>

        {title
          ? <Text style={{ fontSize: pdfDefaultFontSize + 2, marginLeft: 10, color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'semibold' }}>
            {title}
          </Text>
          : null}

        {!title ? <BannerText text={parsedText} top /> : null}
      </View>

      {title ? <BannerText text={parsedText} /> : null}

    </View>
  )
}

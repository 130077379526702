import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { inuktitutFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { myI18n } from 'translation/I18nConnectedProvider'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'

const styles = {
  ...pdfTableStyles,
  ...StyleSheet.create({
    tableRow: {
      ...pdfTableStyles.tableRow,
      minHeight: 16
    },
    firstRow: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontWeight: 'bold'
    },
    firstCol: {
      width: '33%'
    },
    secondCol: {
      width: '33%'
    },
    thirdCol: {
      width: '34%'
    },
    tableCol: {
      ...pdfTableStyles.tableCol,
      minHeight: 36
    }
  })
}

export const FormOtherGrantsFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  ...props
}) => {
  if (langVersion === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow]}>
        <View style={[styles.tableCol, styles.firstCol, styles.firstRow]}>
          <Text style={styles.tableCell}>{myI18n._(t`Name`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.secondCol, styles.firstRow]}>
          <Text style={styles.tableCell}>{myI18n._(t`Value`)}</Text>
        </View>
        <View style={[styles.tableCol, styles.thirdCol, styles.firstRow]}>
          <Text style={styles.tableCell}>
            {myI18n._(t`Status`)} ({myI18n._(t`Approved`)},{' '}
            {myI18n._(t`Pending`)}, {myI18n._(t`Not submitted yet`)},{' '}
            {myI18n._(t`Declined`)})
          </Text>
        </View>
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.firstCol]} />
        <View style={[styles.tableCol, styles.secondCol]} />
        <View style={[styles.tableCol, styles.thirdCol]} />
      </View>

      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.firstCol]} />
        <View style={[styles.tableCol, styles.secondCol]} />
        <View style={[styles.tableCol, styles.thirdCol]} />
      </View>
    </View>
  )
}

import { createBuildings, deleteBuildings } from 'app/services/sfAuth/sfData/sfBuildings'
import { parsedRowToSfBuilding, propertyInformationFields, sfBuildingToParsedRow } from './excelFileImportPdfParser'

// export const formExcelFileImportDefaultValue = (obj, info, item) => {
//   const rows = (obj?.Opportunity?.Buildings__r?.records || [])
//   const parsedRows = rows
//   .map(row => sfBuildingToParsedRow(row, propertyInformationFields))
//   return {
//     rows: parsedRows,
//   }
// }

export const formExcelFileImportSavePromise = (props) => {
  const {
    value,
    item
  } = props

  if (!value?.opportunityId && !value?.prequalificationId) {
    return Promise.resolve({ success: true })
  }

  const promise = deleteBuildings({
    opportunityId: value.opportunityId
  }).then(() => {
    const buildings = value.rows.map(row => parsedRowToSfBuilding(row, propertyInformationFields))

    return createBuildings({
      buildings,
      opportunityId: value?.opportunityId,
      prequalificationId: value?.prequalificationId
    })
  })

  return { promise }
}

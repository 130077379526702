import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { dateFormat } from 'app/appSettings'
import { pdfDefaultFontSize } from 'app/views/forms/common/Common'
import { defaultFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import moment from 'moment'
import { myI18n } from 'translation/I18nConnectedProvider'

const styles = {
  ...pdfTableStyles,
  ...StyleSheet.create({
    text: {
      fontSize: pdfDefaultFontSize,
      fontFamily: defaultFormPdfFontFamily
    },
    section: {
      marginBottom: 8
    },
    objectiveTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 4
    },
    subtitle: {
      fontSize: 10,
      fontWeight: 'bold',
      marginBottom: 4,
      marginTop: 4
    },
    headerCell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'left',
      fontWeight: 'bold',
      flex: 1
    },
    cell: {
      ...pdfTableStyles.tableCol,
      ...pdfTableStyles.tableCell,
      flex: 1
    },
    tableRow: {
      ...pdfTableStyles.tableRow,
      flexWrap: 'nowrap',
      display: 'flex',
      width: '100%'
    }
  })
}

/**
 * Form element which allows to display milestone updates in PDF.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {SalesforceObjectConnection} connectedObject Connected Salesforce object of type Opportunity.
 */
export const FormMilestoneUpdatePdf = ({
  id,
  title,
  helpText,
  connectedObject,
  value,
  ...props
}) => {
  const milestones = value || []

  const areDatesChanged = milestones.some(
    (item) => item.milestoneUpdate.areDatesChanged === 'yes'
  )
    ? 'yes'
    : 'no'

  return (
    <View>
      <View>
        <Text style={styles.elementTitle}>
          {myI18n?._(t`FORM_MILESTONE_UPDATE_ARE_DATES_CHANGED_SUBTITLE`)}:
        </Text>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 8
        }}
      >
        <Text
          style={{
            fontSize: pdfDefaultFontSize,
            lineHeight: 1.4,
            marginRight: 20,
            marginTop: 4
          }}
        >
          {areDatesChanged === 'yes' ? myI18n?._(t`Yes`) : myI18n?._(t`No`)}
        </Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.headerCell}>
            <Text>{myI18n?._(t`Stage`)}</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>{myI18n?._(t`Start date`)}</Text>
          </View>
          <View style={styles.headerCell}>
            <Text>{myI18n?._(t`End date`)}</Text>
          </View>
          <View style={{ ...styles.headerCell, flex: 2.25 }}>
            <Text>
              {myI18n?._(t`MILESTONES_UPDATE_PDF_PLANNED_ACTIVITIES_LABEL`)}{' '}
            </Text>
          </View>
          <View style={{ ...styles.headerCell, flex: 2.25 }}>
            <Text>
              {myI18n?._(t`MILESTONES_UPDATE_PDF_CARRIED_OUT_ACTIVITIES_LABEL`)}{' '}
            </Text>
          </View>
        </View>

        {milestones.map((item, index) => {
          const startDate = item?.milestoneUpdate?.phaseStartDate
          const endDate = item?.milestoneUpdate?.phaseEndDate

          return (
            <View key={index} style={styles.tableRow}>
              <View style={styles.headerCell}>
                <Text>{myI18n?._(item.stage)}</Text>
              </View>
              <View style={styles.cell}>
                <Text>
                  {startDate ? moment.utc(startDate).format(dateFormat) : ''}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text>
                  {endDate ? moment.utc(endDate).format(dateFormat) : ''}
                </Text>
              </View>
              <View style={{ ...styles.cell, flex: 2.25 }}>
                <Text>{item?.milestoneUpdate?.primaryActivities || ''}</Text>
              </View>
              <View style={{ ...styles.cell, flex: 2.25 }}>
                <Text>
                  {item?.milestoneUpdate?.implementedActivities || ''}
                </Text>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}

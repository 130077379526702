import { Objective } from './FormObjectives'

export const FormObjectivesPrintEditor = ({ title }) => {
  const data = {
    objective: 'Objective Placeholder',
    needs: 'Needs Placeholder',
    outcomes: [
      'Outcomes Placeholder'
    ]
  }
  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title-small'>{title}</div>
      <Objective data={data} index={0} />
    </div>
  )
}

import { getReportsForOpportunity } from 'app/services/sfAuth/sfData/sfReports'
import moment from 'moment'
import { getConnectedObjectOfType } from '../Form'

export const formElementAdditionalQueries = {
  previousReport: async (result, { item, idsMap }) => {
    const connectedReport = getConnectedObjectOfType(
      item,
      'FGM_Base__Grantee_Report__c'
    )
    let currentReportId
    if (connectedReport) {
      currentReportId = idsMap[connectedReport.connectedObject]
    }
    const opportunityConnectedObject = item.typeProps.connectedTo.find(
      (connected) => connected.forceType === 'Opportunity'
    ).connectedObject

    return getReportsForOpportunity(idsMap[opportunityConnectedObject], {
      FULL: true
    }).then(reportsResult => {
      const currentReportDate = reportsResult.find(
        (obj) => obj.id === currentReportId
      )?.dueDate

      const reportsSorted = reportsResult
        .filter(
          (obj) =>
            obj.id !== currentReportId &&
            obj.status !== 'Obsolete' &&
            moment(obj.dueDate).isBefore(currentReportDate)
        )
        .sort((a, b) => {
          return moment(b.dueDate).diff(
            moment(a.dueDate)
          )
        })

      const latestReport = reportsSorted[0]

      return result.map((resObj) => ({
        ...resObj,
        latestReport,
        granteeReports: reportsSorted
      }))
    })
  }
}

import { useTextWidth } from '@imagemarker/use-text-width'
import { animated, useSpring } from '@react-spring/web'
import { useState } from 'react'

/**
 * Avatar icon that represents user in the form.
 * @category Multiuser
 * @component
 * @returns {JSX.Element}
 * @property {color} color Color of user icon.
 * @property {string} name Username to be displayed inside the icon.
 * @property {function} handleClick Function fired on clicking on icon.
 */
function MUAvatar ({ color, name, handleClick, isHovered, setHoveredId, id }) {
  const [hovered, setHovered] = useState(false)
  const textWidth = useTextWidth({
    text: name,
    font: '14px Roboto'
  })
  const expandedWidth = textWidth + 24
  const animStyles = useSpring({
    width: hovered ? expandedWidth : 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    height: 40,
    padding: 8,
    borderRadius: 40,
    backgroundColor: color,
    marginLeft: 4,
    marginRight: 4,
    boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.2)'
  })
  const initials =
    name &&
    name
      .split(' ')
      .map(string => string[0].toUpperCase())
      .join('')

  return (
    <div style={{ width: 'fit-content', height: 'auto' }}>
      <animated.div
        style={animStyles}
        onClick={handleClick}
        onMouseOverCapture={e => {
          setHovered(true)
        }}
        onMouseOutCapture={e => {
          setHovered(false)
        }}
      >
        <AnimatedDisplayDiv
          width={animStyles.width}
          name={name}
          initials={initials}
          finalValue={expandedWidth}
        />
      </animated.div>
    </div>
  )
}

const DisplayDiv = ({ width, name, initials, finalValue }) => {
  return <div>{Math.abs(finalValue - width) < 5 ? name : initials}</div>
}
const AnimatedDisplayDiv = animated(DisplayDiv)

export default MUAvatar

import { t, Trans } from '@lingui/macro'
import {
  Button,
  createMuiTheme,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  MuiThemeProvider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  progressUploadFile,
  contentDocumentDownloadUrl,
  deleteDocumentByFlow,
  uploadFile
} from 'app/services/sfAuth/sfData/sfFiles'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { useField, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import Dropzone from 'react-dropzone'
import { myI18n } from 'translation/I18nConnectedProvider'
import { FormErrorLabel } from '../../../../common/labels/FormErrorLabel'
import { parseFormLabelText } from '../../../common/Common'
import { useFormContext } from '../../../form-page/FormContext'
import { endEditingField } from '../../../multiuser/grpcMultiuserEdit'
import { parsedRowToSfBuilding, parseExcelFileImportPdf, preQualificationHousingPortfolioFields, propertyInformationFields, uploadExcelFileImportPdf } from './excelFileImportPdfParser'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { createBuildings } from 'app/services/sfAuth/sfData/sfBuildings'

const crypto = require('crypto')

/**
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {boolean}  [typeProps.required=false] If providing input to this field should be required in the form.
 * @param  {string[]} [typeProps.tags] Tags that will be assigned to the uploaded file.
 */
export const FormExcelFileImport = ({
  id,
  langVersion,
  muBag,
  value,
  disabled,
  useMultiuser,
  connectedObject,
  typeProps,
  formikRef,
  describeMap,
  i18n,
  ...props
}) => {
  const preview = false

  const { network } = useFormContext()
  const { required, tags = [], uploadedDocumentType, template } = typeProps
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(id)
  const files = field.value
  const fieldId = id
  const [uploadedFileId, setUploadedFileId] = useState()
  const classes = formUploadComponentStyles()
  const invalid = Boolean(!connectedObject || !connectedObject.Id) && !preview
  const uploadPrefix = parseFormLabelText({
    text: typeProps.uploadPrefix,
    i18n,
    langVersion
  })

  const isConnectedToOpp = Boolean(typeProps?.connectedTo?.find(
    item => item?.forceType === 'Opportunity' && item?.connectedObject))
  const isConnectedToPrequal = Boolean(typeProps?.connectedTo?.find(
    item => item?.forceType === 'Pre_Qualification__c' && item?.connectedObject))
  const [oppFileDownloadUrl, setOppFileDownloadUrl] = useState()
  const [prequalFileDownloadUrl, setPrequalFileDownloadUrl] = useState()
  const organization = useSelector(state => state.organization)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [pdfParsedData, setPdfParsedData] = useState()
  const [progress, setProgress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileSize, setFileSize] = useState(0)
  const [onRemoveLoading, setOnRemoveLoading] = useState(false)

  useEffect(() => {
    if (organization) {
      const oppFilePath = '/assets/docs/NS-CHCF Property information.xlsx'
      const prequalFilePath = '/assets/docs/NS-CHCF Pre-qualification Housing Portfolio.xlsx'

      uploadExcelFileImportPdf({ filePath: oppFilePath, organization, connectedObject: 'Opportunity' }).then((url) => {
        setOppFileDownloadUrl(url)
      })
      uploadExcelFileImportPdf({ filePath: prequalFilePath, organization, connectedObject: 'Pre-Qualification' }).then((url) => {
        setPrequalFileDownloadUrl(url)
      })
    }
  }, [organization])

  const handleOnDrop = ({ files, id, fieldValue }) => {
    setProgress(0)
    setLoading(true)
    console.log('handleOnDrop', files, network)
    const listCount = files.length
    const uploadId = listCount + 1
    const file = files[0]
    let uploadedName = file.name
    if (uploadPrefix) {
      uploadedName = uploadPrefix + ' ' + uploadedName
    }
    if (preview) {
      enqueueSnackbar(<Trans>Uploaded File</Trans>, {
        variant: 'success'
      })
      return
    }
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = e => {
      console.log('file reading has failed', e)
      enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
        variant: 'error'
      })
      setFieldValue(id, {})
    }
    reader.onload = () => {
      const binaryStr = reader.result
      const buffer = Buffer.from(binaryStr)
      setFileSize(buffer.byteLength)
      parseExcelFileImportPdf({
        file: buffer,
        noOfHeaderRows: isConnectedToOpp ? 17 : 10,
        fields: isConnectedToOpp ? propertyInformationFields : preQualificationHousingPortfolioFields,
        organizationNameCell: isConnectedToOpp ? 'N1' : 'M1',
        organizationPhoneCell: isConnectedToOpp ? 'N2' : 'M2'
      })
        .then(result => {
          console.log('parseExcelFileImportPdf', { result })
          setFieldValue(id, {
            ...result,
            opportunityId: isConnectedToOpp ? connectedObject.Id : undefined,
            prequalificationId: isConnectedToPrequal ? connectedObject.Id : undefined
          })
          setDialogOpen(true)
          setPdfParsedData(result)
        })

      // const uploadingKey = enqueueSnackbar(<Trans>Uploading File</Trans>, {
      //   variant: 'info'
      // })
      const handleError = () => {
        setFieldValue(id, {})
      }
      if (binaryStr.byteLength > 52428800) {
        enqueueSnackbar(<Trans>Maximum file size is 52 MB</Trans>, {
          variant: 'error'
        })
        handleError()
      }
      let tagsString = id
      if (tags.length > 0) {
        tags.filter(tag => tag).forEach(tag => (tagsString += '&tag&' + tag))
      }
      const onProgress = progress => {
        setProgress(progress)
      }
      progressUploadFile(
        {
          name: uploadedName,
          type: uploadedDocumentType,
          tags: tagsString,
          uploadId: connectedObject.Id
        },
        binaryStr,
        network.Id,
        true,
        onProgress
      )
        .then(uploadedFile => {
          setUploadedFileId(uploadedFile.Id)
          // enqueueSnackbar(<Trans>Uploaded File</Trans>, {
          //   variant: 'success'
          // })
        })
        .catch(error => {
          console.warn('cant upload file', error)
          enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
            variant: 'error'
          })
          handleError()
        })
        .finally(() => {
          setLoading(false)
        })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id, files, fieldId, index }) => {
    if (preview) {
      setFieldValue(fieldId, {})
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      return
    }
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })
    setOnRemoveLoading(true)
    deleteDocumentByFlow(uploadedFileId).then(
      result => {
        enqueueSnackbar(<Trans>Deleting File</Trans>, {
          variant: 'success'
        })
        setUploadedFileId(null)
        setFieldValue(fieldId, {})
        setProgress(null)
        setFileSize(0)
        setOnRemoveLoading(false)
      },
      reject => {
        enqueueSnackbar(<Trans>Error ocurred while deleting file!</Trans>, {
          variant: 'error'
        })
        setOnRemoveLoading(false)
      }
    )
  }

  const createTable = ({ files = [], fieldId }) => {
    const columns = [t`Name`, t`Remove`]
    return (
      <TableContainer>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n?._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => {
              const iconDisabled = file.progress || file.deleting || disabled
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={file.url}>
                        {file.name}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n?._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem({
                              id: file.id,
                              fieldId,
                              files,
                              index
                            })
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                file.progress && (
                  <TableRow key={files.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  if (!isConnectedToOpp && !isConnectedToPrequal) {
    return (
      <div><Typography variant='caption'>
        <Trans>FORM_EXCEL_FILE_IMPORT_NO_CONNECTION_ERROR</Trans>
      </Typography>
      </div>
    )
  }

  const dialogColumns = isConnectedToOpp
    ? propertyInformationFields.map(field => { return { name: field.field, label: field.label, options: { filter: false, sort: false } } })
    : preQualificationHousingPortfolioFields.map(field => { return { name: field.field, label: field.label, options: { filter: false, sort: false } } })

  const confirmSave = async () => {
    setDialogOpen(false)
  }

  const downloadUrl = isConnectedToOpp ? oppFileDownloadUrl : prequalFileDownloadUrl
  const downloadTitle = isConnectedToOpp ? 'NS-CHCF Property information.xlsx' : 'NS-CHCF Pre-qualification Housing Portfolio.xlsx'

  const getMuiTheme = () => createTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: 'none'
        }
      },
      MuiTable: {
        root: {
          marginLeft: 16
        }
      },
      MuiTableCell: {
        footer: {
          borderBottom: 'none !important'
        }
      }
    }
  })

  const isError = Boolean(meta.error)
  return (
    <div>
      {/* {createTable({ files: field.value, fieldId: id })} */}
      <div className={classes.formExcelFileImport}>
        <div className={classes.fileUpload}>
          <div className={classes.fileUploadContent}>
            <div className={classes.fileUploadContentHeader}>
              <img src='/assets/images/icons/excel-icon.png' />
              <Typography variant='h4' className={classes.fileUploadContentHeaderTitle}>
                <Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_TITLE</Trans>
              </Typography>
            </div>
            <Typography variant='button' className={classes.fileUploadContentHeaderDescription}>
              <Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_DESCRIPTION</Trans>
            </Typography>
          </div>
          <div>
            <Button
              color='primary'
              variant='contained'
            >
              <a
                href={downloadUrl} target='_blank'
                download={downloadTitle}
                style={{ wordBreak: 'break-all' }} rel='noreferrer'
              >
                <Trans>FORM_EXCEL_FILE_IMPORT_DOWNLOAD_BTN</Trans>
              </a>
              <Icon>download</Icon>
            </Button>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          {!uploadedFileId && !loading && <Dropzone
            disabled={disabled || invalid}
            multiple={false}
            maxFiles={1}
            onDrop={files => {
              handleOnDrop({
                files,
                id,
                fieldValue: field.value
              })
            }}
                                          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={disabled || invalid ? classes.disabled : classes.dropbox}
              >
                <section>
                  <div>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='center'
                      >
                        <Trans>
                          Drag 'n' drop file here, or click to select file
                        </Trans>
                        <Icon style={{ marginLeft: 10 }}>upload</Icon>
                      </Grid>
                    </div>
                  </div>
                  <div />
                </section>
              </div>
            )}
                                          </Dropzone>}

          {loading && <div className={classes.fileUpload}>
            <div
              className={classes.fileUploadClose}
              onClick={e => {
                // openDialog(false)
              }}
            >
              <Icon>close</Icon>
            </div>
            <div className={classes.fileUploadContent} style={{ width: '100%' }}>
              <div className={classes.fileUploadContentHeader} style={{ width: '100%' }}>
                <img src='/assets/images/icons/excel-icon.png' />
                <div style={{ width: '100%' }}>
                  <div>
                    <Typography variant='h4' className={classes.fileUploadContentHeaderTitle}>
                      {downloadTitle}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='button' className={classes.fileUploadContentProgress}>
                      {Math.round(fileSize / 1024)}KB
                    </Typography>
                    <Typography variant='button' className={classes.fileUploadContentProgress}>
                      {progress}%
                    </Typography>
                  </div>
                </div>
              </div>
              <LinearProgress variant='determinate' value={progress} />
            </div>
          </div>}

          {progress === 100 && Boolean(uploadedFileId) && <div className={classes.fileUpload}>
            <div
              className={classes.fileUploadClose}
              onClick={e => {
                if (!onRemoveLoading) { onRemoveItem({ id, fieldId, files, index: 0 }) }
              }}
            >
              <Icon>close</Icon>
            </div>
            <div className={classes.fileUploadContent} style={{ width: '100%' }}>
              <div className={classes.fileUploadContentHeader} style={{ width: '100%' }}>
                <img src='/assets/images/icons/excel-icon.png' />
                <div style={{ width: '100%' }}>
                  <div>
                    <Typography variant='h4' className={classes.fileUploadContentHeaderTitle}>
                      {downloadTitle}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '79px', marginTop: '10px' }}>
                    <Typography variant='button' className={classes.fileUploadContentProgress} style={{ padding: '6px 0' }}>
                      {Math.round(fileSize / 1024)}KB
                    </Typography>
                    {!dialogOpen && <Button
                      color='primary'
                      variant='contained'
                      onClick={() => setDialogOpen(true)}
                                    >
                      <Trans>FORM_EXCEL_FILE_IMPORT_OPEN_TABLE_BTN</Trans>
                    </Button>}
                  </div>
                </div>
              </div>
            </div>
                                                          </div>}

        </div>
      </div>

      {invalid && (
        <div style={{ padding: 10, color: 'red' }}>
          <Trans>There is no object connected in editor!</Trans>
        </div>
      )}

      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth='lg'
        scroll='paper'
        onClose={() => setDialogOpen(false)}
      >
        {/* <DialogTitle>
          <Trans>FORM_EXCEL_FILE_IMPORT_CONFIRM_DATA_TITLE</Trans>
        </DialogTitle> */}
        <DialogContent style={{ padding: 0 }}>
          <Grid container className={classes.tableHeader} direction='column'>
            <div className={classes.tableHeaderTitle}><Typography variant='h4'>{pdfParsedData?.organization?.name}</Typography></div>
            <div className={classes.tableHeaderDescription}><Typography variant='caption'>{pdfParsedData?.organization?.phone}</Typography></div>
          </Grid>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              columns={dialogColumns}
              data={value?.rows}
              options={{
                filter: false,
                print: false,
                download: false,
                search: false,
                viewColumns: false,
                selectableRows: 'none',
                pagination: false
              }}
            />
          </MuiThemeProvider>
          <Grid container justifyContent='flex-end' style={{ marginBottom: 25, paddingRight: 20, marginTop: 23 }}>
            <Button
              color='primary'
              variant='contained'
              onClick={confirmSave}
            >
              <Trans>FORM_EXCEL_FILE_IMPORT_CONFIRM_BTN</Trans>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

    </div>
  )
}

const styles = theme => ({
  dropbox: {
    border: '2px dashed rgba(0, 0, 0, 0.54)',
    padding: 35,
    borderRadius: 6,
    height: 122,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '2px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  disabled: {
    margin: '20px 0',
    border: '2px dashed rgba(158, 158, 158)',
    padding: 35,
    backgroundColor: 'rgba(245, 245, 245, 0.05)'
  },
  formExcelFileImport: {
    display: 'flex',
    gap: '30px'
  },
  fileUpload: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 20,
    display: 'flex',
    flex: 1,
    gap: 18,
    padding: '22px 26px 26px 26px',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '122px'
  },
  fileUploadContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  fileUploadContentHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 11
  },
  fileUploadContentHeaderTitle: {
    wordBreak: 'break-all',
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
  fileUploadContentHeaderDescription: {
    wordBreak: 'break-all',
    fontWeight: 400
  },
  fileUploadContentProgress: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400
  },
  fileUploadClose: {
    position: 'absolute',
    top: 15,
    right: 26,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.4)'
    }
  },
  tableHeader: {
    padding: '20px 32px'
  },
  tableHeaderTitle: {
    wordBreak: 'break-all'
  },
  tableHeaderDescription: {
    wordBreak: 'break-all'
  }
})
export const formUploadComponentStyles = makeStyles(styles)

export const formUploadFilesConditionsStates = {
  minFiles: {
    label: <Trans>Set minimum number of files to upload</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          InputProps={{ inputComponent: DefaultNumericFormat }}
          label={<Trans>Number of files</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

import { Trans } from '@lingui/macro'
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import {
  addAccountContactAffiliation,
  setAcountAffiliationToFormer
} from 'app/services/sfAuth/sfData/sfAccount'
import {
  mapContact,
  saveContactFromLookupField
} from 'app/services/sfAuth/sfData/sfContact'
import { FormErrorLabel } from 'app/views/common/labels/FormErrorLabel'
import FindContactButton from 'app/views/grants/FindContactButton'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { Field, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMainConnected } from '../../Form'
import { getMuState } from '../../common/Common'
import { FormConnectToObject } from '../../common/FormConnectToObject'
import { MUEditedByLabel } from '../../multiuser/components/MUEditedByLabel'
import {
  endEditingField,
  startEditingField
} from '../../multiuser/grpcMultiuserEdit'
import { EditContactPanel } from './FormAssociatedContactsList'

// export const formContactPickerExtractSaveKey = ({ saveObj, value, item }) => {
//   const saveAt = item.typeProps.connectedField.name
//   saveObj[saveAt] = value.id
// }

export const formContactPickerSavePromise = ({
  value,
  item,
  connectedObject,
  additionalSFInfo
}) => {
  const objectName = connectedObject.attributes.type
  const objectId = connectedObject.Id
  const { affiliationRole } = item.typeProps
  const { connectedField } = getMainConnected(item)
  const fieldName = connectedField?.name
  if (!value || !fieldName) {
    return { promise: null }
  }
  const { id, affiliation } = value
  const fieldValue = value.id

  return {
    promise: saveContactFromLookupField({
      objectName,
      objectId,
      fieldName,
      fieldValue
    }).then(result => {
      if (affiliationRole && !affiliation) {
        return addAccountContactAffiliation({
          role: affiliationRole,
          contactId: fieldValue,
          accountId: objectId
        })
      } else if (
        affiliationRole &&
        affiliation &&
        affiliation.npe5__Contact__c.Id !== fieldValue
      ) {
        return Promise.all([
          addAccountContactAffiliation({
            role: affiliationRole,
            contactId: fieldValue,
            accountId: objectId
          }),
          setAcountAffiliationToFormer(affiliation.Id)
        ])
      } else {
        return result
      }
    }),
    afterMainPromise: false
  }
}

export const formContactParseValueToCompare = (value, props) => {
  if (props.saveFailed && value) {
    let toRet = value.firstName + ' ' + value.lastName
    if (value.email) {
      toRet += ', ' + value.email
    }
    return toRet
  }
  if (value && value.id) {
    return value.id
  } else {
    return value
  }
}

export const formContactPickerValueToText = (
  value,
  question,
  { contactsMap }
) => {
  if (typeof value === 'string') {
    const contact = contactsMap[value] || {}
    let toRet = contact.FirstName + ' ' + contact.LastName
    if (contact.Email) {
      toRet += ', ' + contact.Email
    }
    return {
      en: toRet,
      fr: toRet
    }
  } else if (!value) {
    return {
      en: '',
      fr: ''
    }
  }
  let toRet = value.firstName + ' ' + value.lastName
  if (value.email) {
    toRet += ', ' + value.email
  }
  if (value.jobTitle) {
    toRet += ', ' + value.jobTitle
  }
  return {
    en: toRet,
    fr: toRet
  }
}

export const formContactPickerDefaultValue = (obj, additionalInfo, item) => {
  const { contactsMap = {}, accountAffiliations = [] } = additionalInfo
  if (!obj) {
    return null
  } else {
    const { connectedField } = getMainConnected(item)
    const { affiliationRole } = item.typeProps
    const field = connectedField?.name
    if (obj[field] && contactsMap[obj[field]]) {
      const contact = contactsMap[obj[field]]
      let relatedAffiliation
      if (affiliationRole && accountAffiliations) {
        accountAffiliations.some(affiliation => {
          if (
            affiliation.npe5__Role__c === affiliationRole &&
            affiliation.npe5__Status__c === 'Current'
          ) {
            relatedAffiliation = affiliation
            return true
          } else {
            return false
          }
        })
      }
      return { ...mapContact(contact), affiliation: relatedAffiliation }
    } else {
      return obj[field]
    }
  }
}

export const FormContactPickerPrint = ({ value, title, typeProps }) => {
  const { printLabelPlacement } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'
  let valueToShow = value ? value.firstName + ' ' + value.lastName : ''
  if (valueToShow && value.jobTitle) {
    valueToShow += ', ' + value.jobTitle
  }
  if (valueToShow && value.email) {
    valueToShow += ', ' + value.email
  }
  return (
    <div>
      {top && <Typography className='form-print-subtitle'>{title}</Typography>}
      <Typography style={{ whiteSpace: 'pre-line' }}>{valueToShow}</Typography>
      {!top && <Typography className='form-print-subtitle'>{title}</Typography>}
    </div>
  )
}

export const FormContactPicker = ({
  id,
  title,
  editMode,
  disabled,
  saveDisabled,
  useMultiuser,
  muBag,
  connectedObject,
  formId,
  showPrintProps,
  reloadLastModifiedDates,
  typeProps
}) => {
  const user = useSelector(state => state.user)
  const { values, setFieldValue } = useFormikContext()
  const { isEdited, userColor, userName } = getMuState({ values, id })
  const {
    connectedTo = [],
    defaultOrganization,
    workEmailInContactCreation,
    printLabelPlacement
  } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'

  if (showPrintProps) {
    return (
      <div>
        {top && (
          <Typography className='form-print-subtitle'>{title}</Typography>
        )}
        <Typography style={{ whiteSpace: 'pre-line' }}>
          CONTACT INFO PLACEHOLDER
        </Typography>
        {!top && (
          <Typography className='form-print-subtitle'>{title}</Typography>
        )}
      </div>
    )
  }

  return (
    <Field name={id}>
      {({ field: { value }, meta }) => {
        const onContactFound = ({ ...contact }) => {
          const newValue = { ...value, ...mapContact(contact) }
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }

        const onContactUpdate = contact => {
          setFieldValue(id, { ...value, ...contact })
          if (useMultiuser) {
            endEditingField({
              ...muBag,
              fieldId: id,
              fieldValue: { ...value, ...contact },
              lockId: values.muInfo.lockId
            })
          }
        }

        return (
          <>
            <Card
              elevation={5}
              style={{
                padding: 20,
                border: '1px solid #C4C4C4'
              }}
            >
              <Grid container direction='column' justify='center'>
                <div style={{ marginBottom: 10 }}>
                  <FindContactButton
                    isContactFound={Boolean(value && typeof value === 'object')}
                    preview={saveDisabled}
                    onDialogClose={e => {
                      if (useMultiuser) {
                        endEditingField({
                          ...muBag,
                          fieldId: id,
                          fieldValue: value,
                          lockId: values.muInfo.lockId
                        })
                      }
                    }}
                    onDialogOpen={e => {
                      if (useMultiuser) {
                        startEditingField({
                          ...muBag,
                          fieldId: id
                        })
                      }
                    }}
                    useWorkEmailInNewContact={Boolean(
                      workEmailInContactCreation
                    )}
                    allowCreatingNewContact
                    disabled={
                      disabled ||
                      editMode ||
                      connectedTo.length === 0 ||
                      Boolean(isEdited && useMultiuser)
                    }
                    onSelect={onContactFound}
                    currentOrganizationAsDefault={
                      defaultOrganization === 'current'
                    }
                  />
                </div>

                {Boolean(value && typeof value === 'object') && (
                  <Grid container>
                    <Grid item>
                      <b>
                        <span>{value.firstName + ' ' + value.lastName}</span>{' '}
                      </b>
                      <div>
                        <span>{value.email}</span>
                      </div>
                    </Grid>
                    <EditContactPanel
                      disabled={disabled || saveDisabled}
                      onContactUpdate={onContactUpdate}
                      contactInfo={value}
                      reloadLastModifiedDates={reloadLastModifiedDates}
                      renderIconOnLeft
                      // programManager={connectedObject.Assigned_Program_Manager__c}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
            <FormErrorLabel id={id} error={meta.error} />
            {isEdited && (
              <div style={{ marginTop: 5 }}>
                <MUEditedByLabel color={userColor} userName={userName} />
              </div>
            )}
          </>
        )
      }}
    </Field>
  )
}

export const FormEditorContactPicker = ({
  editMode,
  showPrintProps,
  depth,
  typeProps,
  injectable,
  injectableId,
  disabled,
  ...props
}) => {
  const {
    required,
    affiliationRole,
    defaultOrganization,
    workEmailInContactCreation
  } = typeProps
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { connectedObject } = getMainConnected({
    typeProps,
    ...props
  })

  let connectedToAccount
  if (
    connectedObject &&
    values.objectsConnected.some(
      obj => obj.identId === connectedObject && obj.type === 'Account'
    )
  ) {
    connectedToAccount = true
  }

  useEffect(() => {
    if (!connectedToAccount && affiliationRole) {
      const toSet = { ...typeProps }
      delete toSet.affiliationRole
      dispatch({
        type: 'FIELD',
        depth: depth.split('.'),
        fieldName: 'typeProps',
        fieldValue: toSet
      })
    }
  }, [connectedToAccount])

  if (!editMode) {
    return (
      <FormContactPicker
        editMode
        typeProps={typeProps}
        showPrintProps={showPrintProps}
        {...props}
      />
    )
  }

  return (
    <div>
      {!showPrintProps && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(required)}
                disabled={disabled}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.required = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Required</Trans>}
          />
        </div>
      )}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(workEmailInContactCreation)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.workEmailInContactCreation = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Use work email in contact creation</Trans>}
        />
      </div>
      <TextField
        variant='outlined'
        label={<Trans>Default organization</Trans>}
        fullWidth
        select
        value={defaultOrganization || ''}
        style={{ marginTop: 5 }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.defaultOrganization = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
      >
        <MenuItem value='current' key='current'>
          <Trans>Current Organization</Trans>
        </MenuItem>
        <MenuItem value='household' key='household'>
          <Trans>Household</Trans>
        </MenuItem>
      </TextField>
      {connectedToAccount && (
        <Grid container>
          <Grid item xs style={{ paddingRight: 5 }}>
            <TextField
              variant='outlined'
              label={<Trans>Account affiliation role</Trans>}
              fullWidth
              value={affiliationRole || ''}
              style={{ marginTop: 10 }}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.affiliationRole = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          </Grid>
          <TooltipLabelIcon
            tooltip={
              <Trans>
                If account affiliation role is not empty, the affiliation object
                will be created and updated when contact is picked
              </Trans>
            }
          />
        </Grid>
      )}
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        filter={item =>
          item.type === 'reference' && item.referenceTo.includes('Contact')}
      />
    </div>
  )
}

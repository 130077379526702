import { FastField, useFormikContext } from 'formik'
import _ from 'lodash'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import ConfigureMultilanguageTextField from './ConfigureMultilanguageTextField'

const MultilanguageTextFieldWithFormik = ({
  name,
  inputDebounce = false,
  disabled = false,
  ...props
}) => {
  const { values } = useFormikContext()
  const fieldValue = _.get(values, name)
  const [inputValue, setInputValue] = useState(fieldValue)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    setInputValue(fieldValue)
  }, [fieldValue])

  return (
    <FastField
      name={name}
      inputValue={inputValue}
      shouldUpdate={(nextProps, currentProps) => {
        const currentValue = get(currentProps.formik.values, name)
        const nextValue = get(nextProps.formik.values, name)

        return (
          nextProps.inputValue !== currentProps.inputValue ||
          nextValue !== currentValue
        )
      }}
    >
      {({ field, form }) => {
        const { setFieldValue } = form
        return (
          <ConfigureMultilanguageTextField
            value={inputDebounce ? inputValue : field.value}
            disabled={disabled}
            handleChange={value => {
              if (!inputDebounce) {
                setFieldValue(name, value)
                return
              }
              setInputValue(value)
              clearTimeout(timer)
              const newTimer = setTimeout(() => {
                setFieldValue(name, value)
              }, 700)
              setTimer(newTimer)
            }}
            {...props}
          />
        )
      }}
    </FastField>
  )
}

export default React.memo(MultilanguageTextFieldWithFormik)
